import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback, useState } from 'react';
import {
  AngleIcon,
  Container,
  ContentContainer,
  HeaderContainer,
  Title,
} from './styles';
import { Props } from './types';

const HideComments: FC<Props> = ({ children }) => {
  const [showReplies, setShowReplies] = useState(true);

  const toggleAccordion = useCallback(() => {
    setShowReplies((value) => !value);
  }, [setShowReplies]);

  return (
    <Container>
      <HeaderContainer showReplies={showReplies} onClick={toggleAccordion}>
        <Title>{showReplies ? 'hide' : 'show'} replies</Title>
        <AngleIcon />
      </HeaderContainer>
      <AnimatePresence>
        {showReplies && (
          <ContentContainer role="list">{children}</ContentContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default HideComments;
