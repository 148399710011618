import styled from 'styled-components';

export const Path1 = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const ContinentPaths = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const LocationPin = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Path2 = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Path3 = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const LocationPinDot = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;
