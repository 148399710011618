import styled from 'styled-components';

export const LeftChain = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const MiddleChain = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const RightChain = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;
