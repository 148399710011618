import React, { FC } from 'react';
import { useConnect } from './connect';
import { ProfileActivity } from './styles';
import { Props } from './types';

const RecentActivity: FC<Props> = () => {
  useConnect();
  return <ProfileActivity />;
};

export default RecentActivity;
