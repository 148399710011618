import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const ImageContainer = styled.div<{ percentage: number }>`
  overflow: hidden;
  border-radius: 4px;
  position: relative;
  height: 206px;

  ${({ percentage, theme }) =>
    percentage > 0 &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background-color: ${theme.colors.sky};
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: ${percentage}%;
        height: 6px;
        background-color: ${theme.colors.accent};
      }
    `}

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const Overlay = styled.div`
  background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.05)};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PlayButton = motion.custom(styled.div`
  cursor: pointer;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.6)};
  position: absolute;
  top: 50%;
  left: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme }) => theme.colors.accent};
    position: relative;
    width: 18px;
    height: 18px;
    left: 2px;
  }
`);

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  margin: 0;
`;

export const Info = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 14px;
  font-variant-numeric: tabular-nums;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ImageContainer} {
    margin-bottom: 12px;
  }

  ${Title} {
    margin-bottom: 4px;
  }

  ${Info} {
    margin-bottom: 8px;
  }
`;

export const Category = styled.span`
  background-color: ${({ theme }) => theme.colors.basicGold};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.judge};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0.57px;
  line-height: 1;
  padding: 0.1875rem 0.5rem;
  position: absolute;
  right: ${({ theme }) => theme.spacing.md};
  text-transform: uppercase;
  top: ${({ theme }) => theme.spacing.md};
`;
