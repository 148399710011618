import isEqual from 'fast-deep-equal';
import { keysOf } from '../keysOf';

type Serializable = {
  // tslint:disable-next-line:no-any
  toJSON(): any;
};

// tslint:disable-next-line:no-any
function isSerializable(input: any): input is Serializable {
  return (
    input &&
    typeof input === 'object' &&
    'toJSON' in input &&
    typeof input.toJSON === 'function'
  );
}

/*
 * By default, redux-form will compare "values" and "defaultValues" and send the
 * result in the "pristine" options. However, when dealing with EditorState, we
 * need to serialize them first in order to compare them.
 *
 * This function will compare form values taking that into account.
 */
export const isPristine = <T extends object>(
  values: T | undefined,
  initialValues: Partial<T> | undefined,
) => {
  if (!values || !initialValues) {
    return false;
  }

  const allKeys = keysOf(values);

  if (!isEqual(allKeys, keysOf(initialValues))) {
    return false;
  }

  const editorStateValueKeys = allKeys.filter((key) =>
    isSerializable(values[key]),
  );

  // As soon as we focus the editor insert the current position in the state
  // and therefore the initialValue is not equals to the current.
  // lets compare the document instead
  const editorStateValuesEqual = editorStateValueKeys.map((key) => {
    if (!isSerializable(values[key]) || !isSerializable(initialValues[key])) {
      return false;
    }

    const value = (values[key] as unknown) as Serializable;
    const initialValue = (initialValues[key] as unknown) as Serializable;

    return isEqual(value.toJSON().doc, initialValue.toJSON().doc);
  });

  if (editorStateValuesEqual.some((value) => !value)) {
    return false;
  }

  const rawValueKeys = allKeys.filter(
    (key) => editorStateValueKeys.indexOf(key) === -1,
  );

  return isEqual(
    rawValueKeys.reduce((obj, key) => ({ ...obj, [key]: values[key] }), {}),
    rawValueKeys.reduce(
      (obj, key) => ({ ...obj, [key]: initialValues[key] }),
      {},
    ),
  );
};
