import DefaultAvatar from 'components/Avatar';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.li`
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  display: flex;
  padding: 0.875rem 1.125rem;
`;

export const Avatar = styled(DefaultAvatar)`
  margin-bottom: auto;
  margin-right: 0.625rem;
  height: 2.25rem;
  width: 2.25rem;
`;

export const Text = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  text-decoration: none;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

export const Action = styled.span`
  color: ${({ theme }) => theme.colors.black};
`;
