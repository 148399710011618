import { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { setSearchSectionPage } from 'redux/modules/search';
import { RootState } from 'redux/modules/types';
import {
  SEARCH_CHALLENGE_PER_PAGE_DETAIL,
  SEARCH_CHALLENGE_PER_PAGE_MASTER,
} from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const isDetail = matchPath(
    ownProps.location.pathname,
    '/search/challenges/proposals',
  );

  return {
    page: state.search.page.challenges,
    pageCount: getPageCount(
      state.search.count.challenges,
      isDetail
        ? SEARCH_CHALLENGE_PER_PAGE_DETAIL
        : SEARCH_CHALLENGE_PER_PAGE_MASTER,
    ),
    items: state.search.ids.challenges
      .map((id) => state.search.byId.challenges[id])
      .filter((it): it is NonNullable<typeof it> => !!it),
    loading: state.search.loading.challenges,
  };
};

const mapDispatchToProps = {
  setPage: (page: SetStateAction<number>) =>
    setSearchSectionPage({
      page,
      section: 'challenges',
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
