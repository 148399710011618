import React, { FC, MouseEvent, useCallback } from 'react';
import { useSelectionAnchor } from 'utils/prosemirror';
import Item from './Item';
import { Container, Section } from './styles';
import type { Props } from './types';

const CONTAINER_WIDTH = 80;

const Prompt: FC<Props> = ({ editorView, itemSections, state }) => {
  const onMouseDown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      editorView.focus();
    },
    [editorView],
  );

  const { left, top } = useSelectionAnchor({ editorView, state });

  return (
    <Container
      // Add the width of the container to the left positioning to prevent collapse with the border page
      $left={left > CONTAINER_WIDTH ? left : left + CONTAINER_WIDTH}
      $top={top}
      role="row"
      onMouseDown={onMouseDown}
      tabIndex={0}
    >
      {itemSections.map(({ id, items }) => (
        <Section key={id}>
          {items.map((item) =>
            item.type === 'icon' ? (
              <Item
                command={item.command}
                editorView={editorView}
                Icon={item.Icon}
                isActive={item.isActive}
                key={item.id}
                state={state}
              />
            ) : (
              <item.Component editorView={editorView} key={item.id} />
            ),
          )}
        </Section>
      ))}
    </Container>
  );
};

export default Prompt;
