import styled from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.5rem 1.25rem;

  ${from.mobile`
    border-radius: 0.25rem;
  `}

  ${from.mobileXL`
    padding: 2.5rem 3.75rem;
  `};
`;
