import { css } from 'styled-components';

export const placeholder = css`
  p.placeholder:first-child:before {
    content: attr(data-value);
    float: left;
    color: rgb(0, 0, 0, 0.5);
    cursor: text;
    height: 0;
  }
`;
