import { ChevronIcon as DefaultChevron } from 'components/icons';
import DefaultLink from 'components/Link';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const TaskListContainer = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
`;

export const Title = styled.h3`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.5rem;
  margin: 0 0 ${({ theme }) => theme.spacing.sm};
`;

export const ProcessBar = styled.div`
  position: relative;
  margin-left: 0.625rem;
  height: 0.94rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 0.25rem;
  overflow: hidden;
`;

export const ProcessBarComplete = styled.div<{ $completePercentage: number }>`
  position: absolute;
  height: 0.94rem;
  background-color: ${({ theme }) => theme.colors.accent};
  ${({ $completePercentage }) =>
    css`
      width: ${$completePercentage}%;
    `};
`;

export const ProfileProcess = styled.div`
  display: flex;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.accent};
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
`;

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
`;

export const HeaderLink = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.black};
`;

export const Task = styled.li`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
  font-size: 0.875rem;
  margin: 0;
  padding: 0.75rem 0;
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  display: flex;
  align-items: center;
`;

export const Check = styled.div<{ checked: boolean }>`
  position: relative;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  ${({ checked }) =>
    checked &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};
      border: solid 1px ${({ theme }) => theme.colors.accent};

      :after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E")
          center center no-repeat;
        content: '';
        background-size: 11px;
        position: absolute;
        width: 100%;
        height: 100%;
      }
    `}
`;

export const LinkArrowRight = styled(DefaultChevron)`
  color: ${({ theme }) => theme.colors.accent};
  width: 0.45rem;
  height: 1rem;
  margin-left: auto;
`;

export const LinkArrowRightHeader = styled(DefaultChevron)`
  color: ${({ theme }) => theme.colors.accent};
  width: 0.9rem;
  height: 1rem;
  margin-left: 1.2rem;
  ${from.mobileXL`
    margin-left: 1.875rem;
  `}
  ${from.laptop`
    display: none;
  `}
`;

export const List = styled.ul`
  display: none;
  ${from.tablet`
    display: block;
    border-top: solid 1px ${({ theme }) => theme.colors.greyBlue};
    list-style: none;
    margin: 0;
    padding: ${({ theme }) =>
      `${theme.spacing.xsm} ${theme.spacing.lg} ${theme.spacing.sm}`};
    > :last-child {
      border: none;
    }
  `}
`;
