import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectUserId } from 'redux/modules/auth';
import {
  getExerciseResponse,
  selectExerciseResponseBySlug,
  selectExerciseResponseLoading,
} from 'redux/modules/exerciseResponse';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { exerciseSlug, submissionSlug },
    path,
    url,
  } = useRouteMatch<{
    exerciseSlug: string;
    submissionSlug: string;
  }>();

  const userId = useSelector(selectUserId);
  const bySlug = useSelector(selectExerciseResponseBySlug);
  const loading = useSelector(selectExerciseResponseLoading);

  const submission = bySlug[submissionSlug];
  const userIsAuthor = userId === submission?.user.id;

  const actions = useActions({
    getSubmission: getExerciseResponse.request,
  });

  return {
    ...actions,
    exerciseSlug,
    loading,
    path,
    submission,
    submissionSlug,
    url,
    userIsAuthor,
  };
};
