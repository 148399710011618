import { RefObject, useEffect, useRef } from 'react';

/*
 * This function transforms a reactive value (a prop or a state) into a ref.
 * This is useful when you need to access a reactive value inside an effect but
 * you don't want to trigger it every time it changes.
 */
export function useReactiveValueRef<T>(reactiveValue: T): RefObject<T> {
  const ref = useRef(reactiveValue);

  useEffect(() => {
    ref.current = reactiveValue;
  }, [reactiveValue]);

  return ref;
}
