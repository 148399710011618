import produce from 'immer';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { Mutable } from 'utility-types';
import {
  createLiveEventBooking,
  editLiveEventBooking,
  getLiveEvents,
} from './actions';
import { LiveEventsAction, LiveEventsState } from './types';

export const INITIAL_STATE: LiveEventsState = {
  events: [],
  loading: true,
};

const reducer: Reducer<LiveEventsState, LiveEventsAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getLiveEvents.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getLiveEvents.success):
      return {
        ...state,
        loading: false,
        events: action.payload,
      };
    case getType(editLiveEventBooking.success):
      return produce(state, (next) => {
        const { eventId, ...eventBooking } = action.payload;

        const bookedEvent = next.events.find((event) => {
          return event.id === eventId;
        });
        if (bookedEvent) {
          (bookedEvent as Mutable<typeof bookedEvent>).booking = eventBooking;
        }
      });
    case getType(createLiveEventBooking.success):
      return produce(state, (next) => {
        const { eventId, ...eventBooking } = action.payload;

        const bookedEvent = next.events.find((event) => {
          return event.id === eventId;
        });
        if (bookedEvent) {
          (bookedEvent as Mutable<typeof bookedEvent>).booking = eventBooking;
        }
      });
    default:
      return state;
  }
};

export default reducer;
