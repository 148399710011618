import SurpriseMeImage from 'assets/images/surpriseMeImage.svg';
import {
  Card,
  CardGrid,
  Fold,
  Loader,
  NoResults,
  PageTitle,
  Pagination,
  SectionTitle,
} from 'components';
import ProtectedButton from 'containers/Common/ProtectedButton';
import SearchOptions from 'containers/Common/SearchOptions';
import { getFeedbackRequestStatusList, getUserName } from 'model';
import React, { FC, useEffect, useRef } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { imageToMedia } from 'utils/imageToMedia';
import connect from './connect';
import {
  AskFeedbackButton,
  Container,
  Content,
  ExternalLink,
  FeaturedLink,
  Heading,
  HeadingRow,
  Help,
  HelpSection,
  HelpText,
  HelpTitle,
  InfoCard,
  InfoCardButton,
  InfoCardImage,
  Link,
  Paragraph,
} from './styles';
import { Props } from './types';

const FeedbackRoot: FC<Props> = ({
  feedbackRequests,
  getFeedbackRequests,
  loading,
  location: { hash, search, state },
  match: { url },
  pageCount,
  wentBack,
}) => {
  const page = getPageFromQuery(search);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getFeedbackRequests({ search, silent: wentBack });
  }, [getFeedbackRequests, search, wentBack]);

  return (
    <Container>
      <PageTitle title="Feedback" />
      <Fold
        title="get & give feedback"
        description={`Feedback—both receiving it and sharing your feedback with 
        others—is critical for refining and perfecting data communications.`}
      />
      <Content ref={contentRef}>
        <HeadingRow>
          <Heading>
            <SectionTitle>Browse feedback requests</SectionTitle>
            <Paragraph>
              Giving feedback is a great way to solidify learnings—browse
              feedback requests and provide input to help the community.
            </Paragraph>
          </Heading>
          <AskFeedbackButton to={`${url}/ask`}>
            Ask for feedback
          </AskFeedbackButton>
        </HeadingRow>
        <SearchOptions
          contentType="feedback"
          searchPlaceholder="Search feedback..."
        />
        {loading ? (
          <Loader />
        ) : feedbackRequests.length > 0 ? (
          <CardGrid>
            {feedbackRequests.map((feedbackRequest) => (
              <Link
                key={feedbackRequest.id}
                to={`${url}/${feedbackRequest.id}`}
              >
                <Card
                  authorAvatar={feedbackRequest.user.avatar}
                  authorName={getUserName(feedbackRequest.user)}
                  authorType={getUserType(feedbackRequest.user)}
                  comments={feedbackRequest.commentsCount}
                  datapoints={feedbackRequest.votesCount}
                  lastCommentDate={
                    feedbackRequest.lastComment
                      ? feedbackRequest.lastComment
                      : undefined
                  }
                  statuses={getFeedbackRequestStatusList(feedbackRequest)}
                  tags={feedbackRequest.tags}
                  thumbnail={
                    feedbackRequest.imageList
                      ? imageToMedia(feedbackRequest.imageList.mainImage)
                      : feedbackRequest.imageList
                  }
                  title={feedbackRequest.title}
                  views={feedbackRequest.hits}
                />
              </Link>
            ))}
            {!!feedbackRequests.length && (
              <InfoCard key="help-randomly">
                <InfoCardImage
                  alt=""
                  aria-hidden="true"
                  src={SurpriseMeImage}
                />
                <Link to={`${url}/random`}>
                  <InfoCardButton>Surprise me</InfoCardButton>
                </Link>
              </InfoCard>
            )}
          </CardGrid>
        ) : (
          <NoResults />
        )}
        <Pagination
          contentRef={contentRef}
          current={Math.min(page, pageCount)}
          hash={hash}
          search={search}
          state={state}
          total={pageCount}
          url={url}
        />
        <FeaturedLink
          ButtonComponent={ProtectedButton}
          text="Get feedback"
          title="ask for feedback"
          to={`${url}/ask`}
        >
          <p>
            Get a fresh set of eyes on your graph or slide: submit a request for
            feedback to understand what’s working well and get pointers on
            iterating to refine.
          </p>
        </FeaturedLink>
        <HelpSection>
          <Help>
            <HelpTitle>Why is feedback important?</HelpTitle>
            <HelpText>
              Both asking for input from and giving constructive feedback to
              others is important as you refine your data storytelling skills.
              Solicit feedback from others to understand where your visual
              designs are working as intended and when you might need to iterate
              for best results. Giving feedback to others helps you put words
              around design choices and the thought process that drives them.
              Whenever you give feedback, make it about the work and not about
              the person and focus on not only what you recommend changing, but
              also why.
            </HelpText>
            <HelpText>
              Want some feedback-related resources? Check out SWD podcast
              Episode #1{' '}
              <ExternalLink
                href="http://storytellingwithdata.libsyn.com/storytelling-with-data-1-the-art-of-feedback"
                rel="noreferrer noopener"
                target="__blank"
              >
                "the art of feedback"
              </ExternalLink>{' '}
              and{' '}
              <ExternalLink
                href="https://www.amazon.com/gp/product/1119621496/ref=as_li_qf_asin_il_tl?ie=UTF8&tag=storytellingwithdata-20&creative=9325&linkCode=as2&creativeASIN=1119621496&linkId=c74bc50a287b2986edae7e3b95f9f5f4"
                rel="noreferrer noopener"
                target="__blank"
              >
                Let’s Practice!
              </ExternalLink>{' '}
              Exercise 9.3: give &amp; receive effective feedback.
            </HelpText>
          </Help>
        </HelpSection>
      </Content>
    </Container>
  );
};

export default connect(FeedbackRoot);
