import { SupportForm, SUPPORT_FORM_KEY, validateSupportForm } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<SupportForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<SupportForm>({
    form: SUPPORT_FORM_KEY,
    initialValues: {
      inquiryType: 'Subscription Issues',
      issueDescription: '',
    },
    validate: validateSupportForm,
  }),
) as FormDecorator<SupportForm, OwnProps, ConnectedProps>;
