import React, { FC, useCallback } from 'react';
import { useConnect } from './connect';
import FormChangeEmail from './FormChangeEmail';
import { handleChangeEmailFormSubmitted, handleDeleteAccount } from './logic';
import {
  CloseAccountContainter,
  CloseButton,
  Container,
  Paragraph,
  TextContainer,
  Title,
} from './styles';
import { Props } from './types';

const ChangeAccount: FC<Props> = () => {
  const { editProfile, openConfirmation } = useConnect();

  const onFormChangeEmailSubmitted = useCallback(
    handleChangeEmailFormSubmitted({ editProfile }),
    [editProfile],
  );

  const onDeleteAccount = useCallback(
    handleDeleteAccount({
      openConfirmation,
    }),
    [openConfirmation],
  );

  return (
    <Container>
      <Title>Change email</Title>
      <FormChangeEmail onSubmit={onFormChangeEmailSubmitted} />
      <CloseAccountContainter>
        <TextContainer>
          <Title>Close account</Title>
          <Paragraph>
            Once you delete your user, there is no going back. Please be
            certain.
          </Paragraph>
        </TextContainer>
        <CloseButton variant="danger" onClick={onDeleteAccount}>
          Close account
        </CloseButton>
      </CloseAccountContainter>
    </Container>
  );
};

export default ChangeAccount;
