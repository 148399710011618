import { Challenge, PaginatedResponse, UpsertByIdPayload } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getChallenges = createAsyncAction(
  'challenge/GET_CHALLENGES_REQUEST',
  'challenge/GET_CHALLENGES_SUCCESS',
  'challenge/GET_CHALLENGE_FAILURE',
)<
  { search?: string; silent?: boolean },
  PaginatedResponse<Challenge> & { active?: Challenge },
  undefined
>();

export const getChallenge = createAsyncAction(
  'challenge/GET_CHALLENGE_REQUEST',
  'challenge/GET_CHALLENGE_SUCCESS',
  'challenge/GET_CHALLENGE_FAILURE',
)<{ slug: string; silent?: boolean }, Challenge, undefined>();

export const _upsertChallengesById = createAction(
  'feedback/_UPSERT_CHALLENGES_BY_ID',
)<UpsertByIdPayload<Challenge>>();
