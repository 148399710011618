import DefaultCardContent from 'components/CardContent';
import DefaultSWDLogoIcon from 'components/icons/SWDLogo';
import styled from 'styled-components';
import Info from './Info';

export const StaticDescription = styled.span`
  margin: 0.375rem 0 0.625rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey400};
`;

export const RecapInfo = styled(Info)`
  margin-top: auto;
`;

export const RecapContent = styled(DefaultCardContent)`
  padding: 1.75rem ${({ theme }) => theme.spacing.lg} 0;
`;

export const SumUpLabel = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary};
  max-height: 40px;
  align-content: center;
  align-items: baseline;
  justify-content: center;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.781rem;
`;

export const SumUpSpan = styled.span`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.028rem;
`;

export const SumUpIcon = styled(DefaultSWDLogoIcon)`
  color: ${({ theme }) => theme.colors.white};
  height: 0.938rem;
  width: 1.313rem;
`;
