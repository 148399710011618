import { EditorState } from 'components/Editor/types';
import { VisualTagInputsFieldNames, VisualTagInputsForm } from 'model';
import { FormNameMap } from 'utils/forms';

export const GALLERY_EDIT_FORM_KEY = (editId?: string) =>
  `forms/GALLERY_EDIT/${editId ? `edit/${editId}` : 'new'}`;

export interface GalleryEditForm extends VisualTagInputsForm {
  context: EditorState;
  title: string;
}

export const GalleryEditFormFieldNames: FormNameMap<GalleryEditForm> = {
  ...VisualTagInputsFieldNames,
  context: 'context',
  title: 'title',
};
