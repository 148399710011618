import { replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  AddMembersCorporatePremiumNames,
  ADD_MEMBERS_CORPORATE_PREMIUM_FORM_KEY,
  Coupon,
  PaymentFormNames,
  PAYMENT_FORM_KEY,
  Plan,
  User,
} from 'model';
import { stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { combineLatest, from, merge } from 'rxjs';
import { catchError, delay, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { up } from 'utils/Paths';
import { editProfile } from '../auth';
import { showSnackbar } from '../snackbar';
import {
  addExtraSeats,
  addMembers,
  cancelSubscription,
  deleteMember,
  getCouponInfo,
  getUserInfo,
  reactivateSubscription,
  resendInvite,
  subscribe,
  subscriptionLifetime,
  updateCompanyName,
  updatePaymentMethod,
  updatePlan,
  updatePlanInterval,
} from './actions';

export const cancelSubscriptionEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(cancelSubscription.request)),
    mergeMap(() =>
      request<{ data: Plan[] }>({
        body: {
          confirm: true,
        },
        method: 'POST',
        path: 'payment/cancel',
      }).pipe(
        mergeMap((response) => [
          cancelSubscription.success(response.data),
          showSnackbar({
            message: 'Your premium membership has been successfully cancelled',
          }),
          replace('/members/me/settings/subscription'),
        ]),
        catchError((err) => [cancelSubscription.failure(err)]),
      ),
    ),
  );

// tslint:disable:no-any
export const getUserInfoEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getUserInfo.request)),
    mergeMap(() =>
      combineLatest([
        request<any>({
          path: 'payment/current-user',
        }),
        request<any>({
          path: 'payment/subscription',
        }),
        request<any>({
          path: 'payment/invoices',
        }),
      ]).pipe(
        mergeMap(
          ([currentUserResponse, subscriptionResponse, invoicesResponse]) => [
            getUserInfo.success({
              invoices: invoicesResponse.results.map((invoice: any) => ({
                date: invoice.date,
                id: invoice.id,
                paid: invoice.paid,
                pdfUrl: invoice.invoicePdf,
                periodEnd:
                  invoice.periodEnd === invoice.periodStart
                    ? invoice.items[0].periodEnd
                    : invoice.periodEnd,
                periodStart:
                  invoice.periodEnd === invoice.periodStart
                    ? invoice.items[0].periodStart
                    : invoice.periodStart,
                total: invoice.total,
              })),
              info: {
                amount: subscriptionResponse.amount,
                cancelAtPeriodEnd: subscriptionResponse.cancelAtPeriodEnd,
                cardExpMonth: currentUserResponse.expMonth,
                cardExpYear: currentUserResponse.expYear,
                cardKind: currentUserResponse.cardKind,
                cardLast4: currentUserResponse.cardLast4,
                currentPeriodEnd: subscriptionResponse.currentPeriodEnd,
                planId: subscriptionResponse.plan,
                quantity: subscriptionResponse.quantity,
                groupSubscription: subscriptionResponse.groupSubscription,
              },
            }),
          ],
        ),
        catchError((err) => [getUserInfo.failure(err)]),
      ),
    ),
  );
// tslint:enable:no-any

export const addMembersEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(addMembers.request)),
    mergeMap(({ payload: { emails } }) =>
      fullRequest({
        body: { emails },
        method: 'POST',
        path: 'payment/group/create',
      }).pipe(
        mergeMap(() => [
          ...(state$.value.auth.user
            ? [
                editProfile.success({
                  ...(state$.value.auth.user as User),
                  ...(!!emails.find(
                    (it) => it === state$.value.auth.user?.email,
                  ) && {
                    isPremium: true,
                  }),
                }),
              ]
            : []),
          addMembers.success({}),
          showSnackbar({
            message: 'Your invitations has been successfully sent!',
          }),
          replace(up(state$.value.router.location.pathname)),
          getUserInfo.request({}),
        ]),
        catchError((err) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            const { response } = err;
            return [
              addMembers.failure(response),
              stopSubmit(ADD_MEMBERS_CORPORATE_PREMIUM_FORM_KEY, {
                [AddMembersCorporatePremiumNames.emails]: response.emails,
              }),
            ];
          }

          return [addMembers.failure(err)];
        }),
      ),
    ),
  );

export const deleteMemberEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteMember.request)),
    mergeMap(({ payload: { memberId } }) =>
      request({
        body: memberId,
        method: 'DELETE',
        path: `payment/group/delete/${memberId}`,
      }).pipe(
        mergeMap(() => {
          const memberSelected = state$.value.premium.userInfo?.groupSubscription?.users.find(
            (member) => member.id === memberId,
          );
          return [
            ...(state$.value.auth.user
              ? [
                  editProfile.success({
                    ...(state$.value.auth.user as User),
                    ...(memberSelected?.user === state$.value.auth.user.id && {
                      isPremium: false,
                    }),
                  }),
                ]
              : []),
            deleteMember.success({ memberId }),
            showSnackbar({
              message:
                "This member's premium access has been successfully removed",
            }),
            getUserInfo.request({}),
          ];
        }),
        catchError((err) => [deleteMember.failure(err)]),
      ),
    ),
  );

export const resendInviteEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(resendInvite.request)),
    mergeMap(({ payload: { email } }) =>
      request({
        body: { email },
        method: 'POST',
        path: `payment/group/resend`,
      }).pipe(
        mergeMap((email) => [
          resendInvite.success({ email }),
          showSnackbar({
            message: 'The invitation has been successfully sent',
          }),
        ]),
        catchError((err) => [resendInvite.failure(err)]),
      ),
    ),
  );

export const reactivateSubscriptionEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(reactivateSubscription.request)),
    mergeMap(() =>
      request<{ data: Plan[] }>({
        body: {
          confirm: true,
        },
        method: 'POST',
        path: 'payment/reactivate-subscription',
      }).pipe(
        mergeMap((response) => [
          reactivateSubscription.success(response.data),
          showSnackbar({
            message: 'Your premium membership is active. Welcome back!',
          }),
        ]),
        catchError((err) => [reactivateSubscription.failure(err)]),
      ),
    ),
  );

export const subscribeEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    /*
     * Warning! This epic is pretty hacky. Please take your time to understand
     * the workflow.
     */
    filter(isActionOf(subscribe.request)),
    mergeMap(({ payload }) =>
      fullRequest({
        body: payload,
        method: 'POST',
        path: 'payment/subscription',
      }).pipe(
        /*
         * The backend request responds too early. If we send a GET to
         * "payment/subscription" (getUserInfo.request) right after this one
         * responds, the information will be returned empty, as if we didn't
         * subscribe yet.
         *
         * This is precisely what happens when we are redirected to the premium
         * subscription settings: we request the premium info. What's more: we
         * can't populate the content returned by that request with the content
         * returned by this one, because even though the REST entity is the
         * same, they return different data. And even then, doing that would add
         * more complexity to an already complex code.
         *
         * Therefore we simply wait for a couple of seconds before continuing
         * with the processing logic. This wait is not ideal, but as this is a
         * payment request the user shouldn't be too bothered by it taking it
         * longer than usual (the backend endpoint already takes longer than
         * the rest).
         */
        delay(3000),
        /*
         * This structure is a bit unusual, but we simpy want to avoid a race
         * condition where the user is redirected from the "premium settings"
         * page to the "go premium page" before their data has been updated
         * locally to have "isPremium" or "isPremiumGroupOwner" set to true, as
         * the container wouldn't know yet whether the user is premium.
         *
         * With redux-transaction we can't delay side-effects (like
         * connected-react-router's "replace") so we manually delay them.
         *
         * This is obviously a hack. The correct alternative would be to refrain
         * from doing the redirect until the state is valid (maybe through a
         * loading flag). But the container logic is already complex enough and
         * I thought it would be better to keep the dirty stuff here in favour
         * of a cleaner container, as that code will change more frequently.
         */
        mergeMap(() =>
          merge(
            /*
             * We immediately update the user info.
             */
            from([
              ...(state$.value.auth.user
                ? [
                    editProfile.success({
                      ...(state$.value.auth.user as User),
                      ...(payload.companyName
                        ? {
                            isPremium: false,
                          }
                        : {
                            isPremiumGroupOwner: true,
                          }),
                    }),
                  ]
                : []),
            ]),
            /*
             * We do the rest of the logic after a slight delay which is enough
             * for the user info to have been updated.
             */
            from([
              subscribe.success({}),
              showSnackbar({
                message: 'Congratulations! Your premium membership is active.',
              }),
            ]).pipe(
              mergeMap(() => {
                window.location.pathname = '/members/me/settings/subscription';
                return [];
              }),
            ),
          ),
        ),
        catchError((err) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            const { response } = err;
            const invalidCoupon = response?.message
              ?.toLowerCase()
              ?.includes('No such coupon'.toLowerCase());

            return [
              subscribe.failure(response),
              stopSubmit(
                PAYMENT_FORM_KEY,
                invalidCoupon
                  ? {
                      [PaymentFormNames.coupon]: 'Enter a valid coupon',
                    }
                  : {
                      [PaymentFormNames.card]: response.message,
                    },
              ),
            ];
          }

          return [subscribe.failure(err)];
        }),
      ),
    ),
  );

export const subscriptionLifetimeEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    /*
     * Warning! This epic is pretty hacky. Please take your time to understand
     * the workflow.
     */
    filter(isActionOf(subscriptionLifetime.request)),
    mergeMap(({ payload }) =>
      fullRequest({
        body: payload,
        method: 'POST',
        path: 'payment/subscription-lifetime',
      }).pipe(
        /*
         * The backend request responds too early. If we send a GET to
         * "payment/subscription" (getUserInfo.request) right after this one
         * responds, the information will be returned empty, as if we didn't
         * subscribe yet.
         *
         * This is precisely what happens when we are redirected to the premium
         * subscription settings: we request the premium info. What's more: we
         * can't populate the content returned by that request with the content
         * returned by this one, because even though the REST entity is the
         * same, they return different data. And even then, doing that would add
         * more complexity to an already complex code.
         *
         * Therefore we simply wait for a couple of seconds before continuing
         * with the processing logic. This wait is not ideal, but as this is a
         * payment request the user shouldn't be too bothered by it taking it
         * longer than usual (the backend endpoint already takes longer than
         * the rest).
         */
        delay(3000),
        /*
         * This structure is a bit unusual, but we simpy want to avoid a race
         * condition where the user is redirected from the "premium settings"
         * page to the "go premium page" before their data has been updated
         * locally to have "isPremium" or "isPremiumGroupOwner" set to true, as
         * the container wouldn't know yet whether the user is premium.
         *
         * With redux-transaction we can't delay side-effects (like
         * connected-react-router's "replace") so we manually delay them.
         *
         * This is obviously a hack. The correct alternative would be to refrain
         * from doing the redirect until the state is valid (maybe through a
         * loading flag). But the container logic is already complex enough and
         * I thought it would be better to keep the dirty stuff here in favour
         * of a cleaner container, as that code will change more frequently.
         */
        mergeMap(() =>
          merge(
            /*
             * We immediately update the user info.
             */
            from([
              ...(state$.value.auth.user
                ? [
                    editProfile.success({
                      ...(state$.value.auth.user as User),
                      ...(payload.companyName
                        ? {
                            isPremium: false,
                          }
                        : {
                            isPremiumGroupOwner: true,
                          }),
                    }),
                  ]
                : []),
            ]),
            /*
             * We do the rest of the logic after a slight delay which is enough
             * for the user info to have been updated.
             */
            from([
              subscriptionLifetime.success({}),
              showSnackbar({
                message: 'Congratulations! Your premium membership is active.',
              }),
            ]).pipe(
              mergeMap(() => {
                window.location.pathname = '/members/me/settings/subscription';
                return [];
              }),
            ),
          ),
        ),
        catchError((err) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            const { response } = err;
            const invalidCoupon = response?.message
              ?.toLowerCase()
              ?.includes('No such coupon'.toLowerCase());

            return [
              subscriptionLifetime.failure(response),
              stopSubmit(
                PAYMENT_FORM_KEY,
                invalidCoupon
                  ? {
                      [PaymentFormNames.coupon]: 'Enter a valid coupon',
                    }
                  : {
                      [PaymentFormNames.card]: response.message,
                    },
              ),
            ];
          }

          return [subscriptionLifetime.failure(err)];
        }),
      ),
    ),
  );

export const updatePaymentMethodEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(updatePaymentMethod.request)),
    mergeMap(({ payload: { paymentMethod, ...rest } }) =>
      fullRequest({
        body: { paymentMethod },
        method: 'POST',
        path: 'payment/change-card',
      }).pipe(
        mergeMap(() => [
          updatePaymentMethod.success(rest),
          replace({
            pathname: '/members/me/settings/subscription',
            state: {
              scrollToTop: false,
            },
          }),
          showSnackbar({
            message: 'Your payment method has been updated.',
          }),
        ]),
        catchError((err) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            const { response } = err;
            return [
              updatePaymentMethod.failure(response),
              stopSubmit(PAYMENT_FORM_KEY, {
                [PaymentFormNames.card]: response.message,
              }),
            ];
          }

          return [updatePaymentMethod.failure(err)];
        }),
      ),
    ),
  );

export const updateCompanyNameEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(updateCompanyName.request)),
    mergeMap(({ payload: { companyName } }) =>
      request({
        body: { companyName },
        method: 'PUT',
        path: 'payment/update-premium-group',
      }).pipe(
        mergeMap(() => [
          updateCompanyName.success({ companyName }),
          showSnackbar({
            message: 'The company name has been successfully updated',
          }),
          replace(up(state$.value.router.location.pathname)),
        ]),
        catchError((err) => [updateCompanyName.failure(err)]),
      ),
    ),
  );

export const addExtraSeatsEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(addExtraSeats.request)),
    mergeMap(({ payload: { totalUsers } }) =>
      request({
        body: { totalUsers },
        method: 'POST',
        path: 'payment/increase-corporate-plan',
      }).pipe(
        mergeMap(() => [
          addExtraSeats.success({ totalUsers }),
          showSnackbar({
            message: 'The number of seats has been successfully updated',
          }),
          replace(up(state$.value.router.location.pathname)),
        ]),
        catchError((err) => [addExtraSeats.failure(err)]),
      ),
    ),
  );

export const updatePlanIntervalEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(updatePlanInterval.request)),
    mergeMap(({ payload: { newPlan } }) =>
      request({
        body: { newPlan },
        method: 'POST',
        path: 'payment/change-plan',
      }).pipe(
        delay(3000),
        mergeMap(() => {
          window.location.pathname = '/members/me/settings/subscription';
          return [];
        }),
        catchError((err) => [updatePlanInterval.failure(err)]),
      ),
    ),
  );

export const updatePlanEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(updatePlan.request)),
    mergeMap(({ payload: { stripePlan, quantity, companyName } }) =>
      request({
        body: { stripePlan, quantity, companyName },
        method: 'POST',
        path: 'payment/change-plan-with-cancel',
      }).pipe(
        delay(3000),
        mergeMap(() => {
          window.location.pathname = '/members/me/settings/subscription';
          return [];
        }),
        catchError((err) => [updatePlan.failure(err)]),
      ),
    ),
  );

export const getCouponInfoEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getCouponInfo.request)),
    mergeMap(({ payload: { slug } }) =>
      request<Coupon>({
        path: `payment/coupon/${slug}`,
      }).pipe(
        mergeMap((coupon) => [getCouponInfo.success(coupon)]),
        catchError((err) => [
          getCouponInfo.failure(err),
          stopSubmit(PAYMENT_FORM_KEY, {
            [PaymentFormNames.coupon]: 'Enter a valid coupon',
          }),
        ]),
      ),
    ),
  );

export default combineEpics(
  addExtraSeatsEpic,
  addMembersEpic,
  deleteMemberEpic,
  cancelSubscriptionEpic,
  getUserInfoEpic,
  reactivateSubscriptionEpic,
  resendInviteEpic,
  subscribeEpic,
  subscriptionLifetimeEpic,
  updateCompanyNameEpic,
  updatePaymentMethodEpic,
  updatePlanIntervalEpic,
  updatePlanEpic,
  getCouponInfoEpic,
);
