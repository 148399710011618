import React, { FC, memo, useMemo } from 'react';
import { getUserTypeLabel } from 'utils/getUserTypeLabel';
import { Avatar, Container, Details, Info, Name } from './styles';
import { Props } from './types';

const Member: FC<Props> = ({ userType, name, location, avatar, ...rest }) => {
  const getUserLabel = useMemo(() => {
    return getUserTypeLabel(userType);
  }, [userType]);

  return (
    <Container {...rest}>
      <Avatar src={avatar} variant={userType} aria-hidden="true" />

      <Info>
        <Name>{name}</Name>

        <Details>
          {`${getUserLabel}${location ? ` · ${location}` : ''}`}
        </Details>
      </Info>
    </Container>
  );
};

export default memo(Member);
