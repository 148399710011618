import { AngleDownIcon } from 'components/icons';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { buttonReset } from 'styles/mixins';

export const AngleIcon = styled(AngleDownIcon)`
  margin-left: ${({ theme }) => `${theme.spacing.sm}`};
  width: 0.7rem;
  padding-bottom: 0.1rem;
  max-height: 0.688rem;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  transition: height 3s ease;
`;

export const HeaderContainer = styled.button<{ showReplies: boolean }>`
  ${buttonReset};
  color: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: baseline;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  ${({ showReplies }) =>
    showReplies &&
    css`
      ${AngleIcon} {
        transform: rotate(180deg);
        padding-top: 0.1rem;
        padding-bottom: 0;
      }
    `}
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const ContentContainer = styled(motion.ul).attrs({
  animate: 'open',
  initial: 'collapsed',
  exit: 'collapsed',
  variants: {
    collapsed: { height: 0, opacity: 0 },
    open: { height: 'auto', opacity: 1 },
  },
  transition: { duration: 0.3 },
})`
  overflow: hidden;
  list-style: none;

  > :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  }
`;
