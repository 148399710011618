import {
  BooleanInput,
  Button as DefaultButton,
  ConnectedSearchInput as DefaultSearchInput,
  FiltersButton as DefaultFiltersButton,
  OrderDirection as DefaultOrderDirection,
  Select,
} from 'components';
import { Input as SearchInputInput } from 'components/ConnectedSearchInput/styles';
import { TimesIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { from, visuallyHidden } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.form`
  display: grid;
  grid-gap: 0.875rem;
  grid-template-columns: 1fr;
  margin-top: ${({ theme }) => theme.spacing.xlg};
  position: relative;

  svg {
    color: ${({ theme }) => theme.colors.accent};
  }

  ${from.tablet`
    grid-gap: 1.875rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: ${({ theme }) => theme.spacing.lg};
  `};
`;

export const FirstGroupFields = styled.div<{ fullWidth: boolean }>`
  display: flex;
  grid-column: auto;
  justify-content: flex-end;

  ${from.tablet<{ fullWidth: boolean }>`
    grid-column: 1/3;
    
    ${({ fullWidth }) =>
      fullWidth &&
      css`
        grid-column: 1/4;
      `};
  `};
`;

export const SecondGroupFields = styled.div`
  display: flex;
  grid-column: auto;
  justify-content: flex-start;
  margin-bottom: 2rem;

  ${from.tablet`
    grid-column: 3/3;
    justify-content: flex-end;
    margin-bottom: 0;
  `};
`;

export const SearchInput = styled(DefaultSearchInput)`
  ${SearchInputInput} {
    width: 100%;
  }
`;

export const FiltersButton = styled(DefaultFiltersButton)`
  height: 2.75rem;
  margin-left: ${({ theme }) => theme.spacing.md};

  :hover {
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }

  ${from.tablet`
    margin-left: ${({ theme }) => theme.spacing.lg};
`}
`;

export const FilterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: 0 2px 20px -7px ${({ theme }) => addAlpha(theme.colors.grey500, 0.7)};
  display: flex;
  flex-direction: column;
  grid-column: 1/3;
  left: 0;
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: ${ZIndex.SEARCH_FILTERS};
`;

export const FilterSection = styled.div<{ fillSpace?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  margin: 0.9375rem 1rem 0.9375rem 0.5rem;
  padding: 0 1rem;

  > div {
    margin-bottom: 0;
  }

  ${from.mobileXL`
    border-left: 1px solid ${({ theme }) => theme.colors.grey200};
    margin: 0.9375rem 1.875rem 0.9375rem;
    padding: 0 0 0 1rem;
  `};

  ${({ fillSpace }) =>
    fillSpace &&
    css`
      grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    `};
`;

export const FilterTitle = styled.h1`
  font-size: 1.25rem;
  margin: 0;
`;

export const FilterDescription = styled.p`
  font-size: 0.875rem;
  margin: 0;
`;

export const FilterHeader = styled.div`
  padding: 1.5rem 1.875rem 0.9375rem 1.875rem;
  * + * {
    margin-top: 1.25rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.9375rem 1.5rem 1.875rem auto;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }

  ${from.mobileXL`
    margin: 0.9375rem 1.875rem 1.875rem auto;
  `};
`;

export const Button = styled(DefaultButton)``;

export const CheckboxContainer = styled.div`
  margin-top: 2.5625rem;
  padding: 0 0 0 3rem;
`;

export const CheckboxContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.sm};

  input,
  label {
    cursor: pointer;
  }

  > div {
    cursor: pointer;
    align-items: start;
    background: none;
    margin-bottom: 1.125rem;
    width: 100%;
  }

  ${from.tablet`
    flex-direction: row;

    > div {
      margin-bottom: 2.125rem;
      width: 30%;
    }
  `};
`;

export const VisuallyHiddenLabel = styled.label`
  ${visuallyHidden};
`;

export const OrderSelect = styled(Select)`
  border-radius: 0.25rem;
  margin-bottom: 0;
  width: 100%;

  ${from.mobileXL`
    width: 9rem;
  `}

  > * > * {
    :hover {
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
    }
  }
`;

export const OrderBooleanInput = styled(BooleanInput)`
  height: 2.75rem;
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.spacing.sm};
`;

export const OrderDirection = styled(DefaultOrderDirection)`
  height: 2.75rem;

  :hover {
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }
`;

export const FilterTitleRow = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: auto;
  }
`;

export const CloseButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  border-radius: 4px;
  margin-top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseIcon = styled(TimesIcon)`
  height: 18px;
  width: 18px;
`;
