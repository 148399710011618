import {
  HyperlinkIcon as DefaultHyperlinkIcon,
  ShareIcon as DefaultShareIcon,
  TwitterIcon as DefaultTwitterIcon,
} from 'components/icons';
import DefaultLink from 'components/Link';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { buttonReset, from, whiteBox } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const HyperlinkIcon = styled(DefaultHyperlinkIcon)`
  color: ${({ theme }) => theme.colors.accent};
  height: 1rem;
  width: 1rem;
`;

export const ContentWrapper = styled.div`
  display: none;
  right: 0;
  padding: 1rem;
  position: absolute;
  margin-right: -1rem;
  top: 100%;
  z-index: ${ZIndex.DROPDOWN_MENU};

  :hover {
    display: block;
  }

  ${from.tabletH`
    margin-right: -5rem;
  `}
`;

export const Container = styled.div`
  position: relative;

  :focus-within {
    ${ContentWrapper} {
      display: block;
    }
  }
`;

export const Content = styled.div`
  ${whiteBox}
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  right: 0;
  padding: 1.25rem 0 1.25rem 1.25rem;
  min-width: 15rem;

  div:not(:last-child) {
    margin-bottom: 1.125rem;
  }
`;
const commonStyles = css`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey400};
  cursor: pointer;
  display: flex;
  text-transform: uppercase;
  width: 100%;

  :hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.black};
  }
`;
export const CopyButton = styled.button<{ copyLink: boolean }>`
  ${commonStyles};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  padding: 0;
  text-transform: uppercase;

  ${({ copyLink }) =>
    copyLink &&
    css`
      color: ${({ theme }) => theme.colors.accent};
      cursor: initial;
      :hover:not(:disabled) {
        color: ${({ theme }) => theme.colors.accent};
      }
    `}
`;

export const Link = styled(DefaultLink)`
  ${commonStyles};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;

export const ShareIconContainer = styled.button`
  ${buttonReset};
  align-items: center;
  border: 0.038rem solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.238rem;
  cursor: pointer;
  display: flex;
  padding: 0.75rem;
  :hover {
    box-shadow: 0 2px 8px -3px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
    & + ${ContentWrapper} {
      display: block;
    }
  }

  :focus-visible {
    & + ${ContentWrapper} {
      display: block;
    }
  }
`;

export const ShareIcon = styled(DefaultShareIcon)`
  color: ${({ theme }) => theme.colors.accent};
  height: 1rem;
  overflow: inherit;
  width: 1rem;
`;

export const TwitterIcon = styled(DefaultTwitterIcon)`
  color: ${({ theme }) => theme.colors.accent};
  height: 1rem;
  width: 1rem;
`;
