import { FormNameMap } from 'utils/forms';

export const ADD_MEMBERS_CORPORATE_PREMIUM_FORM_KEY =
  'forms/ADD_MEMBERS_CORPORATE_PREMIUM';

export interface AddMembersCorporatePremiumForm {
  emails: string;
}

export const AddMembersCorporatePremiumNames: FormNameMap<AddMembersCorporatePremiumForm> = {
  emails: 'emails',
};
