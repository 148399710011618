import styled, { css } from 'styled-components';
import { buttonReset } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const item = css`
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.xsm};
  padding: 0.8rem 0.8rem;
  text-align: left;

  :hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ItemAnchor = styled.a`
  ${item};
  text-decoration: none;
`;

export const ItemButton = styled.button`
  ${buttonReset};
  ${item};
`;
