import { Error, Input } from 'components';
import { ResetPasswordFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { ButtonPrimary, Container, Row } from './styles';
import { Props } from './types';

const SetPasswordPremiumInvitationForm: FC<Props> = ({
  error,
  handleSubmit,
  invalid,
  loading,
  pristine,
}) => (
  <Container onSubmit={handleSubmit}>
    <Row>
      <Field
        autocomplete="given-name"
        component={Input}
        label="First Name"
        name={ResetPasswordFieldNames.firstName}
        placeholder="Enter your first name"
        type="text"
        validate={required}
      />
      <Field
        autocomplete="family-name"
        component={Input}
        label="Last Name"
        name={ResetPasswordFieldNames.lastName}
        placeholder="Enter your last name"
        type="text"
        validate={required}
      />
    </Row>
    <Field
      component={Input}
      label="Password"
      name={ResetPasswordFieldNames.password}
      placeholder="Enter your password"
      type="password"
      validate={required}
    />
    <Field
      component={Input}
      label="Repeat password"
      name={ResetPasswordFieldNames.repeatPassword}
      placeholder="Repeat your password"
      type="password"
      validate={required}
    />
    {error && <Error text={error} />}
    <Row>
      <ButtonPrimary disabled={invalid || loading || pristine} type="submit">
        {loading ? 'Loading...' : 'Set password'}
      </ButtonPrimary>
    </Row>
  </Container>
);

export default connect(SetPasswordPremiumInvitationForm);
