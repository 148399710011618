import { connect } from 'react-redux';
import { updateCompanyName } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  updateCompanyName: updateCompanyName.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
