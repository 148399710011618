import Images from 'containers/Common/Images';
import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import ConversationDetail from './ConversationDetail';
import ConversationsRoot from './ConversationsRoot';
import { Props } from './types';

const Feedback: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Talk</BreadcrumbsItem>
      <Switch>
        <Route component={Images} path={`${path}/:conversationId/images`} />
        <Route
          component={Images}
          path={`${path}/:conversationId/:commentId/comment-image`}
        />
        <Route path={`${path}/:id`}>
          <ConversationDetail />
        </Route>
        <Route component={ConversationsRoot} path={path} />
      </Switch>
    </>
  );
};

export default Feedback;
