import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import { composeReducers } from 'utils/composeReducers';
import {
  createLoadingReducer,
  defaultLoadingState,
} from 'utils/createLoadingReducer';
import { getEvent } from './actions';
import { EventAction, EventState } from './types';

export const INITIAL_STATE: EventState = {
  ...defaultLoadingState,
  event: undefined,
  eventLoading: false,
};

const reducer: Reducer<EventState, EventAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getEvent.success):
      return {
        ...state,
        event: action.payload,
      };
    default:
      return state;
  }
};

export default composeReducers(
  createLoadingReducer(
    isActionOf(getEvent.request),
    isActionOf(getEvent.success),
    isActionOf(getEvent.failure),
    INITIAL_STATE,
  ),
  reducer,
);
