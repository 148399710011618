import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  overflow: hidden;
  width: 100%;

  > *:first-child {
    ${from.laptop`
      float: right;
      margin: 0 0 ${({ theme }) => `${theme.spacing.md} ${theme.spacing.md}`};
      width: 40rem;
    `};
  }
`;
