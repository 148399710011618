import type { Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSynchronizePluginState } from './sync';
import type { Props } from './types';
import { createEditorState } from './utils';

const useInitialize = ({
  editorRef,
  editorViewRef,
  onChange,
  value,
}: {
  editorRef: RefObject<HTMLDivElement | null>;
  editorViewRef: MutableRefObject<EditorView | null>;
  onChange: Props['onChange'];
  value: Props['value'];
}) => {
  // We put the value in a ref so a change in the value doesn't trigger an
  // EditorView reinitialization. Instead, the changes to the value are listened
  // to in "useUpdate".
  const valueRef = useRef(value);
  const [focused, setFocused] = useState(false);

  const dispatchTransaction = useCallback(
    (transaction: Transaction) => {
      if (editorViewRef.current) {
        const nextState = editorViewRef.current.state.apply(transaction);
        onChange(nextState);
      }
    },
    [editorViewRef, onChange],
  );

  useEffect(() => {
    if (editorRef.current) {
      const nextEditorView = new EditorView(editorRef.current, {
        // TODO maybe we could force the empty state to always be created
        //      outside the Editor and remove this conditional?
        state: valueRef.current ?? createEditorState(),
        // Avoiding the defaultEvent makes the reference to not close before it handles its own event.
        handleDOMEvents: {
          focusin: () => {
            setFocused(true);
            return true;
          },
          focusout: (_, e) => {
            e.preventDefault();
            setFocused(false);
            return true;
          },
        },
      });
      editorViewRef.current = nextEditorView;

      return () => {
        nextEditorView.destroy();
      };
    }

    return () => {
      /* noop */
    };
  }, [editorRef, editorViewRef, valueRef]);

  useEffect(() => {
    if (editorViewRef.current) {
      editorViewRef.current.setProps({
        dispatchTransaction,
      });
    }
  }, [dispatchTransaction, editorViewRef]);

  return {
    focused,
  };
};

const useUpdate = ({
  editorViewRef,
  value,
}: {
  editorViewRef: MutableRefObject<EditorView | null>;
  value: Props['value'];
}) => {
  useEffect(() => {
    if (editorViewRef.current && value) {
      editorViewRef.current.updateState(value);
    }
  }, [editorViewRef, value]);
};

export const useEditor = ({
  onChange,
  placeholder,
  referenceProvider,
  value,
}: {
  onChange: Props['onChange'];
  placeholder: Props['placeholder'];
  referenceProvider: Props['referenceProvider'];
  value: Props['value'];
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const editorViewRef = useRef<EditorView | null>(null);

  const { focused } = useInitialize({
    editorRef,
    editorViewRef,
    onChange,
    value,
  });
  useUpdate({ editorViewRef, value });
  useSynchronizePluginState({ editorViewRef, placeholder, referenceProvider });

  return {
    editorRef,
    focused,
  };
};
