import React, { FC } from 'react';
import {
  AchievementsContainer,
  BarsIcon,
  Done,
  Item,
  ItemHeader,
  ItemTitle,
  List,
  Title,
  ToDo,
} from './styles';
import { Props } from './types';

const Achievements: FC<Props> = ({
  titleDone,
  titleToDo,
  isAchievementsTab,
  achievements,
  ...rest
}) => {
  const achievementLink = (type: string) => {
    switch (type) {
      case 'challenge':
        return '/challenges';
      case 'exercise':
        return '/exercises';
      case 'feedback':
        return '/feedback';
      case 'profile':
        return '/members/me/settings/edit-profile';
    }
  };

  return (
    <AchievementsContainer {...rest} row={isAchievementsTab}>
      {achievements.granted.length > 0 && (
        <Done>
          <Title>{titleDone}</Title>
          <List>
            {achievements.granted.map((achievement) => (
              <Item
                to={{
                  pathname: achievementLink(achievement.type),
                }}
                key={achievement.text}
                $done={true}
                $isLinkable={false}
              >
                <ItemHeader>
                  <BarsIcon />
                  <ItemTitle>{achievement.text}</ItemTitle>
                </ItemHeader>
              </Item>
            ))}
          </List>
        </Done>
      )}
      <ToDo>
        <Title>{titleToDo}</Title>
        <List>
          {achievements.next.map((achievement) => (
            <Item
              to={{
                pathname: achievementLink(achievement.type),
              }}
              key={achievement.text}
              $done={false}
              $isLinkable={
                achievement.type !== 'comment' &&
                achievement.type !== 'datapoint'
              }
            >
              <ItemHeader>
                <BarsIcon />
                <ItemTitle>{achievement.text}</ItemTitle>
              </ItemHeader>
            </Item>
          ))}
        </List>
      </ToDo>
    </AchievementsContainer>
  );
};

export default Achievements;
