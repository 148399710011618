import { ConfusedShrugIcon } from 'components/icons';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding: 4rem 0;
  text-align: center;
`;

export const Icon = styled(ConfusedShrugIcon)`
  width: 15.625rem;
`;

export const Title = styled.h2`
  font-size: 1.75rem;
`;
