import BaseInput from 'components/BaseInput';
import RawInput from 'components/RawInput';
import styled from 'styled-components';

export const Container = styled(BaseInput)`
  position: relative;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    left: ${({ theme }) => theme.spacing.md};
    position: absolute;
    width: 1rem;
  }
`;

export const HTMLInput = styled(RawInput)`
  padding-left: 2.5rem;
  width: 100%;
`;
