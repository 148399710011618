import { AddImageIcon as DefaultAddImageIcon } from 'components/icons';
import styled from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  border: dashed 1px ${({ theme }) => theme.colors.accent};
  outline: none;
  max-width: 6rem;
  height: 6rem;
  width: 6rem;

  :hover {
    background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.1)};
    box-shadow: 0 6px 29px -10px ${({ theme }) => addAlpha(theme.colors.grey400, 0.4)};
  }
`;

export const AddImageIcon = styled(DefaultAddImageIcon)`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  width: 1.2rem;
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  text-align: center;
`;
