export const buildProfessionalInfo = (
  companyName: string | null,
  companyRole: string | null,
) => {
  if (companyName && companyRole) {
    return `${companyRole}, ${companyName}`;
  }

  if (companyName) {
    return companyName;
  }

  if (companyRole) {
    return companyRole;
  }
};
