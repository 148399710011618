import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  { auth, users: { bySlug, loading } }: RootState,
  { match: { params } }: OwnProps,
) => {
  const { userSlug } = params;
  const user = userSlug
    ? bySlug[userSlug]
    : auth.user && bySlug[auth.user.slug];

  return {
    user,
    loading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
