import { MediaInput as DefaultMediaInput } from 'components';
import { AddImageIcon } from 'components/DragFiles/styles';
import { DropZone } from 'components/MediaSelector/SingleFileVariant/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.form``;

export const Row = styled.div<{ small: boolean }>`
  ${({ small }) =>
    small &&
    css`
      display: flex;
      flex-direction: column;

      > *:not(:last-child) {
        margin-right: 0;
        flex: 1;
      }

      ${from.tablet`
        flex-direction: row;

        > *:not(:last-child) {
          margin-right: ${({ theme }) => theme.spacing.xlg};
        }
     `};
    `};
`;

export const MediaInput = styled(DefaultMediaInput)<{ compact: boolean }>`
  min-width: 100%;
  margin-bottom: 0.6rem;

  ${DropZone} {
    flex: 1;
    max-width: none;

    ${from.tablet`
      max-height: 7.125rem;
    `}
  }

  ${AddImageIcon} {
    ${({ compact }) =>
      compact &&
      `
      margin-bottom: 1rem;

        margin-right: 0;
      `};
  }

  ${from.tablet`
    min-width: 8rem;
    > :last-child {
      height: 100%;
      max-height: none;
    }
  `};

  &:focus-within {
    ${DropZone} {
      background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.1)};
      box-shadow: 0 6px 29px -10px ${({ theme }) => addAlpha(theme.colors.grey400, 0.4)};
    }
  }
`;
