import styled, { css } from 'styled-components';

const accent = css`
  border: 1px solid ${({ theme }) => theme.colors.accent};
`;

export const Container = styled.div<{ isSelected: boolean }>`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: flex;
  margin: 0 0.5rem;
  padding: 0.8rem 0.75rem;
  user-select: none;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ isSelected }) => isSelected && accent};
`;
