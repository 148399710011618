import { connect } from 'react-redux';
import { selectChallenges } from 'redux/modules/challenge';
import { selectDiscoverImages } from 'redux/modules/discover/selectors';
import { selectExercises } from 'redux/modules/exercise';
import { selectExerciseBankItems } from 'redux/modules/exerciseBank/selectors';
import {
  getHomeItemsAuthenticated,
  getHomeItemsGuest,
} from 'redux/modules/home';
import { RootState } from 'redux/modules/types';
import { selectUsers } from 'redux/modules/users';
import { addVote, removeVote } from 'redux/modules/vote';

const mapStateToProps = (state: RootState) => ({
  activeBanner: state.home.banner,
  feedbackRequests: state.home.feedback.slice(0, 3),
  conversations: state.home.conversations.slice(0, 3),
  activeChallenge: state.home.activeChallenge ?? selectChallenges(state)[0],
  activeExercise: state.home.activeExercise ?? selectExercises(state)[0],
  loading: state.discover.loading,
  loggedIn: !!state.auth.user,
  members:
    state.home.users ??
    selectUsers(state)
      .filter((member) => !member.swdTeam)
      .slice(0, 9),
  tags: state.configuration.conversationTags,
  items: state.home.visuals ?? selectDiscoverImages(state).slice(0, 8),
  exerciseBankItems: state.home.exerciseBank ?? selectExerciseBankItems(state),
  myOpenFeedback: state.home.openFeedback ?? state.myOpenFeedback.feedback,
  currentUser: state.auth.user,
});

const mapDispatchToProps = {
  getHomeItemsGuest: getHomeItemsGuest.request,
  getHomeItemsAuthenticated: getHomeItemsAuthenticated.request,
  addVote: addVote.request,
  removeVote: removeVote.request,
};

export default connect(mapStateToProps, mapDispatchToProps);
export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
