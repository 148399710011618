import DefaultButton from 'components/Button';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $disabled: boolean }>`
  position: relative;
  height: 16.25rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  transition: height 0.2s ease;
  width: 17.3125rem;
  box-size: border-box;
  background-color: ${({ theme }) => theme.colors.grey100};
  height: 17rem;

  ${({ $disabled }) =>
    !$disabled &&
    css`
      :hover {
        border: 2px solid ${({ theme }) => theme.colors.accent};
      }
    `}
`;

export const Title = styled.p`
  font-size: 1.75rem;
  line-height: 1.2;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: normal;
`;

export const Price = styled.span`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.black};
  margin: 1.25rem 0 0.5rem;
  letter-spacing: normal;
`;

export const Subtitle = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.5;
  text-align: center;
  text-transform: lowercase;
  letter-spacing: normal;
`;

export const CardButton = styled(DefaultButton)<{ disabled: boolean }>`
  padding: 2.75rem 2rem 1.375rem;
  border: 1px solid ${({ theme }) => theme.colors.accent};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 101%;
  height: 101%;
  border-radius: 0.5rem;
  margin-top: -0.0625rem;
  margin-left: -0.0625rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.accent};

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: none;
  }

  :disabled {
    opacity: 0.6;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const FakeButton = styled.div<{ $currentSubscription: boolean }>`
  width: 100%;
  margin-top: 1.375rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.875rem;
  box-sizing: border-box;
  text-align: center;
  ${({ $currentSubscription }) =>
    $currentSubscription &&
    css`
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.grey200};
      color: ${({ theme }) => theme.colors.judge};
    `}
`;

export const Tag = styled.span`
  position: absolute;
  top: -0.75rem;
  padding: 0.25rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.accent};
  font-size: 1.25rem;
  line-height: 1.1;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  text-transform: initial;
  letter-spacing: normal;
`;
