import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 60.625rem;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 2.75rem 2.5rem 1.875rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const Title = styled.h2`
  margin: 0 0 1.125rem 0;
  font-size: 1.75rem;
  line-height: 1.2;
  text-align: center;
`;

export const Subtitle = styled.p`
  margin: 0 0 1.5rem 0;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  max-width: 30.8125rem;
`;

export const Title2 = styled.h2`
  margin: 0 0 0.75rem 0;
  font-size: 2.25rem;
  line-height: 2.25rem;
  text-align: center;
`;

export const Subtitle2 = styled.p`
  margin: 0 0 3.5rem 0;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  max-width: 30.8125rem;
`;

export const PremiumTypeTabs = styled.div`
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  max-width: 23.75rem;
  cursor: pointer;
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

const PremiumTab = styled.div<{ $activeTab: boolean }>`
  display: inline-block;
  width: 50%;
  border-radius: 0.5rem;

  ${({ $activeTab }) =>
    $activeTab &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};
    `}
`;

export const PremiumTabIndividual = styled(PremiumTab)``;

export const PremiumTabCorporate = styled(PremiumTab)``;

export const TabText = styled.h5<{ $activeTab: boolean }>`
  margin: 0;
  padding: 10px;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-align: center;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  ${from.tablet`
    font-size: 1.25rem;
  `}
  ${({ $activeTab }) =>
    $activeTab &&
    css`
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.weights.bold};
    `}
`;

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > :not(:last-child) {
    margin-bottom: 1.375rem;
  }
  ${from.laptop`
    flex-direction: row;
    > :not(:last-child) {
      margin-bottom: 0;
      margin-right: 1.375rem;
     }
  `}
`;

export const Info = styled.div`
  box-sizing: border-box;
  padding: 2rem 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: solid 1px ${({ theme }) => theme.colors.greyBlue};
  ${from.tablet`
    padding: 2rem 5.8rem;
  `}
`;

export const Description = styled.p`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: 1.5rem;
  margin-top: 0;

  ${from.tablet`
    text-align: center;
  `}
`;

export const Features = styled.ul`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 14px 0px 10px 0px;
`;

export const LongTermFeatures = styled.ul`
  border-top: 4px solid ${({ theme }) => theme.colors.basicGold};
  background-color: ${({ theme }) => theme.colors.lightGold};
  list-style: none;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 10px 0px;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  margin: 14px 0px 0px 0px;
`;

export const SpecialOfferBadge = styled.div`
  padding: 4px 8px;
  text-transform: none;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  margin-top: -1.15rem;
  margin-left: 76px;
  background: ${({ theme }) => theme.colors.basicGold};
  border-radius: 8px;
  letter-spacing: 0px;
`;

export const PromotionInfo = styled.div`
  margin-top: 2rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-transform: none;
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-decoration: none;

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;
