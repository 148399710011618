import { Props } from './types';

export const handleUpdatePlan = ({
  companyName,
  quantity,
  selectedPlanId,
  isCorporate,
  isCurrentlyCorporate,
  updatePlan,
  updatePlanInterval,
}: {
  companyName: string;
  quantity: number;
  selectedPlanId: string;
  isCorporate: boolean;
  isCurrentlyCorporate: boolean;
  updatePlan: Props['updatePlan'];
  updatePlanInterval: Props['updatePlanInterval'];
}) => {
  if (isCurrentlyCorporate || (!isCurrentlyCorporate && !isCorporate)) {
    updatePlanInterval({ newPlan: selectedPlanId });
  } else {
    updatePlan({ quantity, companyName, stripePlan: selectedPlanId });
  }
};
