import {
  ImageAndText,
  ImageListViewer,
  PostAuthor,
  RichText,
  ScrollBox,
} from 'components';
import Actions from 'containers/Common/PostActions';
import React, { FC } from 'react';
import {
  Container,
  ShareButton,
  Title,
  TitleContainer,
  TitleLink,
} from './styles';
import { Props } from './types';

const ChallengeSubmissionView: FC<Props> = ({
  authorAvatar,
  authorId,
  authorType,
  authorName,
  authorSlug,
  comments,
  created,
  description,
  id,
  images,
  isReported,
  isVoted,
  open,
  scroll,
  shareButtonMessage,
  tags,
  title,
  titleClickable,
  url,
  views,
  votes,
  ...rest
}) => (
  <Container {...rest}>
    <TitleContainer>
      {titleClickable ? (
        <TitleLink to={url}>
          <Title>{title}</Title>
        </TitleLink>
      ) : (
        <Title>{title}</Title>
      )}
      <ShareButton
        content={shareButtonMessage}
        url={`${window.location.origin}${url}`}
      />
    </TitleContainer>
    <ScrollBox scroll={scroll}>
      <PostAuthor
        authorType={authorType}
        avatar={authorAvatar}
        comments={comments}
        date={created}
        id={authorId}
        name={authorName}
        slug={authorSlug}
        tags={tags}
        views={views}
      />
      <ImageAndText>
        <ImageListViewer images={images} zoomUrl={`${url}/images`} />
        <strong>Commentary</strong>
        <RichText content={description} />
      </ImageAndText>
      <Actions
        authorId={authorId}
        contentType="challengeresponse"
        id={id}
        isReported={isReported}
        isVoted={isVoted}
        url={url}
        votes={votes}
      />
    </ScrollBox>
  </Container>
);

export default ChallengeSubmissionView;
