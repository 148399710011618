import { ChangePlanForm, CHANGE_PLAN_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState) => {
  const initialValues: ConfigProps<ChangePlanForm>['initialValues'] = {
    quantity: 1,
    companyName: '',
  };
  return {
    initialValues,
    loading: state.premium.updateChangePlan,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ChangePlanForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ChangePlanForm>({
    form: CHANGE_PLAN_FORM_KEY,
  }),
) as FormDecorator<ChangePlanForm, OwnProps, ConnectedProps>;
