import { connect } from 'react-redux';
import { logIn } from 'redux/modules/auth';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  logIn: logIn.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
