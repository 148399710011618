import { connect } from 'react-redux';
import { requestResetPassword, resetPassword } from 'redux/modules/auth';

const mapDispatchToProps = {
  resetPassword: resetPassword.request,
  requestResetPassword: requestResetPassword.request,
};

export default connect(null, mapDispatchToProps);

export type ConnectedProps = typeof mapDispatchToProps;
