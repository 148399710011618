import { useMediaQuery } from '@material-ui/core';
import Form from 'containers/Main/Feedback/AskFeedback/Form';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import { handleDelete, handleSubmit } from './logic';
import { Container, Title } from './styles';
import { Props } from './types';

const Edit: FC<Props> = ({
  editFeedbackRequest,
  history: { replace },
  location: { state = {} },
  match,
  openConfirmationModal,
}) => {
  const url = match?.url;

  const isFromTablet = useMediaQuery(from.tabletH.query);

  const onClose = useCallback(() => {
    if (url) {
      replace({
        pathname: up(url),
        state: {
          ...state,
          scrollToTop: false,
        },
      });
    }
  }, [replace, state, url]);

  const onDelete = useCallback(
    handleDelete({ openConfirmationModal, id: match?.params.id }),
    [openConfirmationModal, match?.params.id],
  );

  const onSubmit = useCallback(
    handleSubmit({
      editFeedbackRequest,
      id: match?.params.id,
    }),
    [editFeedbackRequest, match?.params.id],
  );

  return (
    <Container
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!match}
      scroll="body"
    >
      <Title>Edit your feedback request</Title>
      {match && (
        <Form
          editId={match.params.id}
          onDelete={onDelete}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  );
};

export default connect(Edit);
