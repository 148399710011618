import React, { FC } from 'react';
import { formatMonthNameAndYear } from 'utils/dates';
import {
  AchievementContainer,
  AchievementDate,
  AchievementIcon,
  AchievementsContainer,
  AchievementText,
  List,
  Title,
} from './styles';
import { Props } from './types';

const Achievements: FC<Props> = ({ achievements, ...rest }) => {
  return (
    <AchievementsContainer {...rest}>
      {achievements.length > 0 && (
        <>
          <Title>{`Achievements`}</Title>

          <List>
            {achievements.map((achievement) => (
              <AchievementContainer key={achievement.text}>
                <AchievementIcon />

                <AchievementText>{achievement.text}</AchievementText>

                {achievement.date ? (
                  <AchievementDate>
                    {formatMonthNameAndYear(achievement.date)}
                  </AchievementDate>
                ) : null}
              </AchievementContainer>
            ))}
          </List>
        </>
      )}
    </AchievementsContainer>
  );
};

export default Achievements;
