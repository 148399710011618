import fold_1x_png from 'assets/images/premium/fold.png';
import fold_3x_webp from 'assets/images/premium/fold_3x.webp';
import {
  Pricing as DefaultPricing,
  PricingAnnualPromotion as AnnualPromotionPricing,
  PricingPromotion as PromotionPricing,
} from 'components';
import { SWDLogoIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

const PRICING_OVERLAP = '16.875rem';

export const Container = styled.div`
  max-width: 100%;
`;

const foldGradient = css`
linear-gradient(
  ${({ theme }) => theme.colors.primary},
  ${({ theme }) => addAlpha(theme.colors.accent, 0.9)}
)
`;

export const Fold = styled.div<{ webpSupported: boolean }>`
  background-size: cover;
  padding-bottom: calc(${PRICING_OVERLAP} + 2.5rem);

  ${({ webpSupported }) =>
    webpSupported
      ? css`
          background-image: ${foldGradient}, url('${fold_3x_webp}');
        `
      : css`
          background-image: ${foldGradient}, url('${fold_1x_png}');
        `};
`;

export const FoldContent = styled.div`
  ${container};
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `4rem ${theme.wrapperPaddingH.mobile} 0`};
  ${from.mobileXL`
    padding: ${({ theme }) => `4rem ${theme.wrapperPaddingH.desktop} 0`};
  `}
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  text-align: center;
  ${from.tablet`
    font-size: 2.5rem;
  `}
`;

export const Content = styled.div`
  ${container};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 65.625rem;
  margin: 0 auto;
  padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.mobile}`};
  ${from.mobileXL`
    padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.desktop}`};
  `}
`;

export const Pricing = styled(DefaultPricing)`
  margin-top: -${PRICING_OVERLAP};
`;

export const PricingAnnualPromotion = styled(AnnualPromotionPricing)`
  margin-top: -${PRICING_OVERLAP};
`;

export const PricingPromotion = styled(PromotionPricing)`
  margin-top: -${PRICING_OVERLAP};
`;

export const SubtitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 35rem;
  padding: 4rem 0;
  ${from.tablet`
    padding: 6.875rem 0;
  `}
`;

export const Subtitle = styled.h2`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
  ${from.tablet`
    font-size: 2.5rem;
  `}
`;

export const SWDLogo = styled(SWDLogoIcon)`
  color: ${({ theme }) => theme.colors.basicGold};
  width: 2.25rem;
`;

export const Benefits = styled.div`
  width: 100%;
`;

const ctaGradient = css`
linear-gradient(
  ${({ theme }) => theme.colors.primary},
  ${({ theme }) => addAlpha(theme.colors.accent, 0.6)}
)
`;

export const CTA = styled.div<{ webpSupported: boolean }>`
  align-items: center;
  background-position-y: center;
  background-size: cover;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 3.5rem 0;
  margin: 4rem 0;
  width: 100%;

  ${({ webpSupported }) =>
    webpSupported
      ? css`
          background-image: ${ctaGradient}, url('${fold_3x_webp}');
        `
      : css`
          background-image: ${ctaGradient}, url('${fold_1x_png}');
        `};
  ${from.tablet`
    margin: 6.875rem 0;
  `}
`;

export const CTATitle = styled.h3`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
  margin: 0;
  ${from.tablet`
    font-size: 1.75rem;
  `}
`;
