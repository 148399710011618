import { dark } from './dark';
import { light } from './light';

const _themes = {
  dark,
  light,
};

export type Theme = typeof light;
export type ThemeKey = keyof typeof _themes;

const themes: { [k in ThemeKey]: Theme } = _themes;

export default themes;
