import RawInput from 'components/RawInput';
import RawSelectOption from 'components/RawSelectOption';
import Downshift, { DownshiftProps } from 'downshift';
import React, { FC, memo, useCallback } from 'react';
import { useOptions } from './logic';
import { Container, Menu } from './styles';
import { Props } from './types';

const LocationInput: FC<Props> = ({
  meta,
  label,
  input: { onBlur, onChange, onFocus: _onFocus, value },
}) => {
  const isEmpty = !value;
  const { onInputValueChange: _onInputValueChange, options } = useOptions({
    isEmpty,
    onChange,
    visited: meta.visited,
  });

  const onFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      // tslint:disable-next-line:no-any
      _onFocus(e as any);
      _onInputValueChange(e.target.value);
    },
    [_onFocus, _onInputValueChange],
  );

  const onInputValueChange: DownshiftProps<
    string
  >['onInputValueChange'] = useCallback(
    (value, { type }) => {
      if (type === Downshift.stateChangeTypes.changeInput) {
        _onInputValueChange(value);
        onChange(value);
      }
    },
    [_onInputValueChange, onChange],
  );

  return (
    <Downshift<string>
      onChange={onChange}
      onInputValueChange={onInputValueChange}
      inputValue={value}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        highlightedIndex,
        isOpen,
      }) => (
        <Container
          meta={meta}
          label={label}
          labelProps={getLabelProps()}
          {...getRootProps()}
        >
          <RawInput
            error={meta.error && meta.touched}
            {...getInputProps({ onBlur, onFocus } as never)}
          />
          {isOpen && options.length > 0 && (
            <Menu {...getMenuProps()}>
              {options.map((option, i) => (
                <RawSelectOption
                  isSelected={highlightedIndex === i}
                  key={option}
                  {...getItemProps({
                    index: i,
                    item: option,
                  })}
                >
                  {option}
                </RawSelectOption>
              ))}
            </Menu>
          )}
        </Container>
      )}
    </Downshift>
  );
};

export default memo(LocationInput);
