import { AuthJumbotron, PageTitle } from 'components';
import { decode } from 'jsonwebtoken';
import { SecondaryEmailForm } from 'model';
import { parse } from 'query-string';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import SetSecondaryEmailForm from './SetSecondaryEmailForm';
import { Container, Content } from './styles';
import { Props, QueryString } from './types';

const decodeJwt = decode as (jwt: string) => { email: string } | null;

const SetSecondaryEmail: FC<Props> = ({
  location: { search },
  setSecondaryEmail,
}) => {
  const { token } = parse(search) as QueryString;

  const onSubmitSetSecondaryEmail = useCallback(
    (values: SecondaryEmailForm) => {
      if (token) {
        setSecondaryEmail({ ...values, token });
      }
    },
    [setSecondaryEmail, token],
  );
  const invitationEmail = decodeJwt(token ?? '')?.email;

  return (
    <Container>
      <PageTitle title="Set your secondary email" />
      <AuthJumbotron mainTitle="Great, now you can enjoy a group account in the SWD Community">
        Enter your email and password to log in so we can activate your group
        premium invite.
      </AuthJumbotron>
      <Content>
        {token && (
          <SetSecondaryEmailForm
            onSubmit={onSubmitSetSecondaryEmail}
            invitationEmail={invitationEmail ?? ''}
          />
        )}
      </Content>
    </Container>
  );
};

export default connect(SetSecondaryEmail);
