import { ShareButton as DefaultShareButton } from 'components';
import DefaultComments from 'containers/Common/Comments';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.mobile}`};

  ${from.mobileXL`
    padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.desktop}`};
  `};
`;

export const Submission = styled.div`
  ${container};
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyBlue};

  ${from.tablet`
    border-radius: 0.25rem;
    border: solid 1px ${({ theme }) => theme.colors.greyBlue};
    box-shadow: 0 13px 30px -23px ${({ theme }) =>
      addAlpha(theme.colors.grey400, 0.3)};
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.xsm};
`;

export const Comments = styled(DefaultComments)`
  ${container};
  max-width: 50rem;
  padding-top: 3.75rem;
`;

export const ShareButton = styled(DefaultShareButton)`
  margin-left: auto;
`;
