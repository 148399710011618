import { EditProfileForm, EDIT_PROFILE_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState) => {
  const user = state.auth.user;

  const initialValues = {
    avatar: user?.avatar ?? undefined,
    firstName: user?.firstName,
    lastName: user?.lastName,
    location: user?.location,
    email: user?.email,
    bio: user?.bio,
    goals: user?.goals,
    companyName: user?.companyName,
    companyRole: user?.companyRole,
    socialLinkedin: user?.socialLinkedin,
    socialTwitter: user?.socialTwitter,
    socialWeb: user?.socialWeb,
    socialInstagram: user?.socialInstagram,
    nBooks: user?.nBooks,
    podcastFollower: user?.podcastFollower,
    wAttended: user?.wAttended,
    showActivityInfo: user?.showActivityInfo,
    showProfessionalInfo: user?.showProfessionalInfo,
    showSocialInfo: user?.showSocialInfo,
  };

  return {
    initialValues,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<EditProfileForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<EditProfileForm>({
    form: EDIT_PROFILE_FORM_KEY,
  }),
) as FormDecorator<EditProfileForm, OwnProps, ConnectedProps>;
