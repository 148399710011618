import React from 'react';
import { serialize as serializeElement } from 'react-serialize';
import { cancelSubscription } from 'redux/modules/premium';
import { serializeIntent } from 'utils/intent';
import { useConnect } from './connect';

export const handleCancelSubscription = ({
  openConfirmation,
}: {
  openConfirmation: ReturnType<typeof useConnect>['openConfirmation'];
}) => () => {
  openConfirmation({
    onAcceptIntent: serializeIntent([cancelSubscription.request({})]),
    title: serializeElement(<span>Cancel subscription</span>),
    description: serializeElement(
      <span>
        Are you sure you want to cancel? We’ll be sad to see you go.
        <br />
        You will retain premium access until your membership expires.
      </span>,
    ),
    acceptButtonLabel: 'CANCEL PREMIUM',
    acceptButtonVariant: 'danger',
    cancelButtonLabel: 'NEVER MIND!',
  });
};
