import DefaultAvatar from 'components/Avatar';
import Button from 'components/Button';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import styled, { css } from 'styled-components';
import { buttonReset } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { CalendarIcon } from '../icons';

export const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 0.5rem;
  box-shadow: -3px 3px 20px -10px ${({ theme }) => addAlpha(theme.colors.grey400, 0.8)};
  opacity: 0;
  width: 11.2rem;
  text-align: left;
  padding: 1.125rem;
  overflow: hidden;
  top: 45%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`;

export const Container = styled.div<{ disabled?: boolean }>`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      :hover {
        ${Tooltip} {
          opacity: 1;
        }
      }
    `};
`;

export const CardButton = styled(Button)<{ disabled?: boolean }>`
  ${buttonReset};
  align-items: initial;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: -3px 3px 20px -10px rgba(112, 111, 111, 0.3);
  display: flex;
  flex-direction: column;
  font-weight: initial;
  letter-spacing: initial;
  text-transform: initial;
  width: 16.875rem;
  height: 100%;

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.white};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      :disabled {
        background-color: ${({ theme }) => theme.colors.white};
      }
      :active {
        opacity: 0.6;
      }
    `};
`;

export const TitleContainer = styled.div<{ $booked: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.basicGold};
  color: ${({ theme }) => theme.colors.judge};
  display: flex;
  justify-content: center;
  padding: 1rem;

  ${({ $booked, theme }) =>
    $booked &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
    `};
`;

export const BookedIcon = styled(CalendarIcon)`
  margin-right: 0.75rem;
  width: 1rem;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0 2.5rem;
  align-items: center;

  * + * {
    margin-top: 0.75rem;
  }
`;

export const Time = styled.span`
  opacity: 0.75;
  font-size: 1.5rem;
`;

export const Date = styled(Time)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Main = styled.div``;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.grey100};
  flex: 1;
  > :not(:last-child) {
    margin-bottom: 0.75rem;
  }
`;

export const PresenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 11.875rem;
`;

export const PresenterInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: start;
`;

export const Position = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.75rem;
  line-height: 1.1;
`;

export const Avatar = styled(DefaultAvatar)`
  width: 3.25rem;
  height: 3.25rem;
  margin-bottom: 0;
  margin-right: 0.75rem;
  flex-shrink: 0;
  ${BadgeContainer} {
    width: 1.5rem;
    height: 1.5rem;
    left: 1.75rem;
    bottom: -1px;
  }
`;
