import {
  Conversation,
  ConversationExpanded,
  PaginatedResponse,
  UpsertByIdPayload,
} from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const createConversation = createAsyncAction(
  'conversation/CREATE_CONVERSATION_REQUEST',
  'conversation/CREATE_CONVERSATION_SUCCESS',
  'conversation/CREATE_CONVERSATION_FAILURE',
)<
  {
    conversationTopic: string[];
    description: string;
    title: string;
  },
  Conversation,
  undefined
>();

export const deleteConversation = createAsyncAction(
  'conversation/DELETE_CONVERSATION_REQUEST',
  'conversation/DELETE_CONVERSATION_SUCCESS',
  'conversation/DELETE_CONVERSATION_FAILURE',
)<{ id: string }, { id: string }, undefined>();

export const editConversation = createAsyncAction(
  'conversation/EDIT_CONVERSATION_REQUEST',
  'conversation/EDIT_CONVERSATION_SUCCESS',
  'conversation/EDIT_CONVERSATION_FAILURE',
)<
  Partial<{
    conversationTopic: string[];
    description: string;
    title: string;
  }> & { id: string },
  Conversation,
  undefined
>();

export const getConversation = createAsyncAction(
  'conversation/GET_CONVERSATION_REQUEST',
  'conversation/GET_CONVERSATION_SUCCESS',
  'conversation/GET_CONVERSATION_FAILURE',
)<{ id: string }, ConversationExpanded, undefined>();

export const getConversations = createAsyncAction(
  'conversation/GET_CONVERSATIONS_REQUEST',
  'conversation/GET_CONVERSATIONS_SUCCESS',
  'conversation/GET_CONVERSATIONS_FAILURE',
)<
  { search?: string; silent?: boolean },
  PaginatedResponse<Conversation>,
  undefined
>();

export const _upsertConversationsById = createAction(
  'feedback/_UPSERT_CONVERSATIONS_BY_ID',
)<UpsertByIdPayload<Conversation>>();
