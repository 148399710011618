import { TimesIcon } from 'components/icons';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isMouseInput: boolean }>`
  cursor: pointer;
  margin: -1rem;
  padding: 1rem;
  position: absolute;
  right: ${({ theme }) => theme.spacing.xsm};
  top: ${({ theme }) => theme.spacing.xsm};

  :active {
    opacity: 0.5;
  }

  ${({ isMouseInput }) =>
    isMouseInput &&
    css`
      display: none;
    `};
`;

export const DeleteBox = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent};
  display: flex;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
`;

export const DeleteIcon = styled(TimesIcon)`
  color: ${({ theme }) => theme.colors.white};
  height: 50%;
  border-radius: 50%;
`;
