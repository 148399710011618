import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  hash: state.router.location.hash,
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

const mapDispatchToProps = {
  replace,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
