import React, { FC, memo } from 'react';
import { Container, Progress } from './styles';
import { Props } from './types';

const Loader: FC<Props> = ({ className, size = 50, ...rest }) => (
  <Container className={className}>
    <Progress size={size} {...rest} />
  </Container>
);

export default memo(Loader);
