import { ReportForm } from 'model';
import { Props } from './types';

export const handleFormSubmitted = ({
  contentType,
  objectId,
  closeModal,
  report,
}: {
  contentType: Props['contentType'];
  objectId: Props['objectId'];
  closeModal: Props['closeModal'];
  report: Props['report'];
}) => (values: ReportForm) => {
  if (!contentType || !objectId) {
    return;
  }

  closeModal();
  report({
    contentType,
    objectId,
    comment: values.comments,
    selectedOption: values.reason,
  });
};
