import React, { FC, memo, useEffect, useState } from 'react';
import { Container, LiveIndicator } from './styles';
import { Props } from './types';

function valueOrZero(value: number) {
  return value > 0 ? value : 0;
}

function getTimeLeft(endDate: string) {
  const now = new Date().getTime();
  const countDownDate = new Date(endDate);
  const distance = countDownDate.getTime() - now;
  const hours = valueOrZero(
    Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
  );
  const minutes = valueOrZero(
    Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
  );
  const seconds = valueOrZero(Math.floor((distance % (1000 * 60)) / 1000));
  return {
    hours,
    minutes,
    seconds,
  };
}

function formatTime({
  hours,
  minutes,
  seconds,
}: ReturnType<typeof getTimeLeft>) {
  return `${hours.toString().padStart(2, '0')}h ${minutes
    .toString()
    .padStart(2, '0')}m ${seconds.toString().padStart(2, '0')}s`;
}

const LiveTag: FC<Props> = ({ amaEndDate, ...rest }) => {
  const [time, setTime] = useState(
    () => amaEndDate && formatTime(getTimeLeft(amaEndDate)),
  );
  useEffect(() => {
    if (!amaEndDate) return;
    const id = setInterval(() => {
      const timeLeft = getTimeLeft(amaEndDate);
      const isEnded = Object.values(timeLeft).every((v) => v === 0);
      if (isEnded) {
        clearInterval(id);
      }
      setTime(formatTime(timeLeft));
    }, 1000);
    return () => clearInterval(id);
  }, [amaEndDate]);

  if (!time) {
    return (
      <Container {...rest}>
        <LiveIndicator />
        LIVE NOW
      </Container>
    );
  }

  return (
    <Container {...rest}>
      <LiveIndicator />
      LIVE NOW: {time}
    </Container>
  );
};

export default memo(LiveTag);
