import { OfficeHour, OfficeHourBooking, PaginatedResponse } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const createBooking = createAsyncAction(
  'officeHour/CREATE_BOOKING_REQUEST',
  'officeHour/CREATE_BOOKING_SUCCESS',
  'officeHour/CREATE_BOOKING_FAILURE',
)<{ hourId: string }, OfficeHourBooking, undefined>();

export const deleteBooking = createAsyncAction(
  'officeHour/DELETE_BOOKING_REQUEST',
  'officeHour/DELETE_BOOKING_SUCCESS',
  'officeHour/DELETE_BOOKING_FAILURE',
)<{ id: string }, undefined, undefined>();

export const getOfficeHours = createAsyncAction(
  'officeHour/GET_OFFICE_HOURS_REQUEST',
  'officeHour/GET_OFFICE_HOURS_SUCCESS',
  'officeHour/GET_OFFICE_HOURS_FAILURE',
)<undefined, PaginatedResponse<OfficeHour>, undefined>();

export const updateBooking = createAsyncAction(
  'officeHour/UPDATE_BOOKING_REQUEST',
  'officeHour/UPDATE_BOOKING_SUCCESS',
  'officeHour/UPDATE_BOOKING_FAILURE',
)<
  Partial<OfficeHourBooking> & { session: string },
  OfficeHourBooking,
  undefined
>();
