import { FormNameMap } from 'utils/forms';

export const RESET_PASSWORD_FORM_KEY = 'forms/RESET_PASSWORD';

export interface ResetPasswordForm {
  firstName: string;
  lastName: string;
  password: string;
  repeatPassword: string;
}

export const ResetPasswordFieldNames: FormNameMap<ResetPasswordForm> = {
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  repeatPassword: 'repeatPassword',
};
