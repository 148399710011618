import { AuthJumbotron, PageTitle } from 'components';
import { RequestResetPasswordForm, ResetPasswordForm } from 'model';
import { parse } from 'query-string';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import RequestPasswordFormView from './RequestResetPasswordForm';
import ResetPasswordFormView from './ResetPasswordForm';
import { Container, Content } from './styles';
import { Props, QueryString } from './types';

const ResetPassword: FC<Props> = ({
  location: { search },
  resetPassword,
  requestResetPassword,
}) => {
  const { token, success } = parse(search) as QueryString;

  const onSubmitResetPassword = useCallback(
    (values: ResetPasswordForm) => {
      if (token) {
        resetPassword({ ...values, token });
      }
    },
    [resetPassword, token],
  );
  const onSubmitRequestResetPassword = useCallback(
    (values: RequestResetPasswordForm) => {
      requestResetPassword(values);
    },
    [requestResetPassword],
  );
  const title = success
    ? 'An email has been sent to your registered email address'
    : 'Enter your email address and we will send you a link to reset your password';
  const resetTitle = 'Please enter a new password';
  return (
    <Container>
      <PageTitle title="Reset your password" />
      <AuthJumbotron mainTitle="Reset your password">
        {token ? resetTitle : title}
      </AuthJumbotron>
      {!success && (
        <Content>
          {token ? (
            <ResetPasswordFormView onSubmit={onSubmitResetPassword} />
          ) : (
            <RequestPasswordFormView
              onSubmit={onSubmitRequestResetPassword}
              search={search}
            />
          )}
        </Content>
      )}
    </Container>
  );
};

export default connect(ResetPassword);
