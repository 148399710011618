import { getTextFromHtml } from 'components/RichText';
import ProtectedButton from 'containers/Common/ProtectedButton';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Comment } from 'model';
import React, { FC, memo, useMemo, useState } from 'react';
import { getUserType } from 'utils/getUserType';
import {
  Container,
  Face,
  Faces,
  LastComment,
  NumberOfComments,
} from './styles';
import { Props } from './types';

const HighlightedComment = ({
  comment,
  idle,
}: {
  comment: Comment;
  idle: boolean;
}) => {
  const lastCommentContent = comment.comment.substr(0, 60);

  if (idle) {
    return (
      <LastComment>
        Last comment by <span>{comment.user.firstName}</span> about{' '}
        {formatDistanceToNow(parseISO(comment.created), { addSuffix: true })}
      </LastComment>
    );
  }

  return (
    <LastComment>
      <span>{comment.user.firstName}</span> "
      {getTextFromHtml(lastCommentContent)}"
    </LastComment>
  );
};

const Comments: FC<Props> = ({
  numberOfComments: nComments,
  items,
  isAma,
  live,
  closed,
  amaAnswers,
  url,
  ...rest
}) => {
  const lastCommentId = items.length && items[0].id;
  const [isHovering, setIsHovering] = useState(false);
  const [highlightedCommentId, setHighlightedCommentId] = useState(
    lastCommentId,
  );
  const highlightedComment = useMemo(
    () => items.find((item) => item.id === highlightedCommentId),
    [highlightedCommentId, items],
  );

  const onMouseOver = (id: string) => () => {
    setHighlightedCommentId(id);
    setIsHovering(true);
  };
  const onMouseOut = () => {
    setHighlightedCommentId(lastCommentId);
    setIsHovering(false);
  };

  if (isAma) {
    return (
      <Container isAma={isAma} isEmpty={false} closed={closed} {...rest}>
        {live ? (
          <>
            <NumberOfComments live={true}>{amaAnswers}</NumberOfComments>
            Answers given
          </>
        ) : (
          <>
            <NumberOfComments>{nComments}</NumberOfComments>
            Questions asked
          </>
        )}
        {closed ? (
          <>
            <NumberOfComments>{amaAnswers}</NumberOfComments>
            Answers given
          </>
        ) : (
          <ProtectedButton>participate</ProtectedButton>
        )}
      </Container>
    );
  }

  if (items.length === 0) {
    return (
      <Container isEmpty={true} {...rest}>
        <NumberOfComments>{nComments}</NumberOfComments>
        <LastComment>No comments yet. Be the first to talk!</LastComment>
      </Container>
    );
  }

  return (
    <Container isEmpty={false} {...rest}>
      <NumberOfComments>{nComments}</NumberOfComments>
      <Faces>
        {items.map((item) => (
          <Face
            key={item.id}
            onMouseOver={onMouseOver(item.id)}
            onMouseOut={onMouseOut}
            src={item.user.avatar}
            variant={getUserType(item.user)}
          />
        ))}
      </Faces>
      <HighlightedComment
        comment={highlightedComment as Comment}
        idle={!isHovering}
      />
    </Container>
  );
};

export default memo(Comments);
