import React, { FC } from 'react';
import { Check, CheckContainer, Container } from './styles';
import { Props } from './types';

const FeaturePromotion: FC<Props> = ({ children, ...rest }) => (
  <Container {...rest}>
    <CheckContainer>
      <Check />
    </CheckContainer>
    {children}
  </Container>
);

export default FeaturePromotion;
