import { EditorState } from 'components/Editor/types';
import { Media } from 'components/MediaInput';
import { VisualTagInputsFieldNames, VisualTagInputsForm } from 'model';
import { FormNameMap } from 'utils/forms';
import { UPLOAD_MEDIA_FIELD } from '../utils';

export const EXERCISE_RESPONSE_FORM_KEY = (editSlug?: string) =>
  `forms/EXERCISE_RESPONSE/${editSlug ? `edit/${editSlug}` : 'new'}`;

export interface ExerciseResponseForm extends VisualTagInputsForm {
  [UPLOAD_MEDIA_FIELD]: readonly Media[];
  description: EditorState;
  title: string;
}

export const ExerciseResponseFormFieldNames: FormNameMap<ExerciseResponseForm> = {
  ...VisualTagInputsFieldNames,
  [UPLOAD_MEDIA_FIELD]: UPLOAD_MEDIA_FIELD,
  description: 'description',
  title: 'title',
};
