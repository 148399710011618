import { GalleryImage, Loader, Pagination } from 'components';
import DiscoverItem from 'containers/Common/DiscoverItem';
import {
  handleAddVote,
  handleRemoveVote,
} from 'containers/Main/Discover/DiscoverRoot/logic';
import React, { FC, useCallback, useEffect } from 'react';
import { Route } from 'react-router';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { Loading } from '../../styles';
import { useConnect } from './connect';
import { Container, Images } from './styles';
import { Props } from './types';

const Liked: FC<Props> = ({ contentRef, user }) => {
  const {
    addVote,
    getUserLiked,
    items,
    loading,
    pageCount,
    path,
    push,
    removeVote,
    search,
    state,
    url,
  } = useConnect({ user });
  const page = getPageFromQuery(search);

  const userSlug = user.slug;

  useEffect(() => {
    getUserLiked({ page, slug: userSlug });
  }, [getUserLiked, page, userSlug]);

  const onAddVote = useCallback(handleAddVote({ addVote }), [addVote]);

  const onRemoveVote = useCallback(handleRemoveVote({ removeVote }), [
    removeVote,
  ]);

  const goBack = useCallback(() => {
    push({
      search,
      pathname: url,
      state: {
        scrollToTop: false,
      },
    });
  }, [push, search, url]);

  if (loading && !items.length) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  return (
    <Container>
      <Images>
        {items.map(({ created, id, image, isVoted, user, title }) => (
          <GalleryImage
            avatar={user.avatar}
            created={created}
            id={id}
            image={image}
            imageAlt={title}
            isVoted={isVoted}
            key={id}
            onAddVote={onAddVote}
            onRemoveVote={onRemoveVote}
            to={{
              search,
              pathname: `${url}/${id}`,
              state: {
                scrollToTop: false,
              },
            }}
            userType={getUserType(user)}
          />
        ))}
      </Images>
      <Pagination
        contentRef={contentRef}
        current={Math.min(page, pageCount)}
        state={state}
        total={pageCount}
        url={url}
      />
      <Route path={`${path}/:discoverId`}>
        <DiscoverItem onClose={goBack} scroll={true} />
      </Route>
    </Container>
  );
};

export default Liked;
