import { Props } from './types';

export const handleNotifyClick = ({
  editSettings,
  variant,
}: {
  editSettings: Props['editSettings'];
  variant: Props['variant'];
}) => () => {
  if (variant === 'challenge') {
    return editSettings({ newChallenge: true });
  }
  if (variant === 'exercise') {
    return editSettings({ newExercise: true });
  }
};
