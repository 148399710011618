import { Loader, Portal, SWDLogo } from 'components';
import ScrollBox from 'components/ScrollBox';
import { getUserName } from 'model';
import { parse, stringify } from 'query-string';
import React, { FC, useEffect } from 'react';
import connect from './connect';
import { requestContent } from './logic';
import {
  AuthorLink,
  CloseButton,
  CloseIcon,
  Container,
  Content,
  Header,
  Image,
  Info,
  Light,
  Link,
  RightSection,
  Subtitle,
  Thumbnail,
  Thumbnails,
  Title,
  Video,
} from './styles';
import { Props } from './types';

const Images: FC<Props> = ({
  author,
  getChallengeResponse,
  getConversation,
  getDiscoverItem,
  getExerciseResponse,
  getFeedbackRequest,
  history: { goBack },
  location: { hash, pathname, search },
  match: {
    params: {
      challengeSlug,
      conversationId,
      discoverId,
      exerciseSlug,
      feedbackId,
      submissionSlug,
      commentId,
    },
  },
  media,
  title,
}) => {
  const { selected: _selected, ...searchRest } = parse(search);
  const selected = _selected && !isNaN(+_selected) ? +_selected : 0;

  useEffect(
    requestContent({
      challengeSlug,
      conversationId,
      discoverId,
      exerciseSlug,
      getChallengeResponse,
      getConversation,
      getDiscoverItem,
      getExerciseResponse,
      getFeedbackRequest,
      feedbackId,
      submissionSlug,
    }),
    [
      challengeSlug,
      discoverId,
      commentId,
      exerciseSlug,
      getChallengeResponse,
      getConversation,
      getDiscoverItem,
      getExerciseResponse,
      getFeedbackRequest,
      feedbackId,
      submissionSlug,
    ],
  );

  if (!author || !media) {
    return (
      <Portal>
        <Container>
          <Loader />
        </Container>
      </Portal>
    );
  }

  return (
    <Portal>
      <Container>
        <Header>
          <Link to="/">
            <SWDLogo color="grey" />
          </Link>
          <Info>
            <Title title={title}>{title}</Title>
            <Subtitle>
              <Light>by</Light>{' '}
              <AuthorLink to={`/members/${author.id}`}>
                {getUserName(author)}
              </AuthorLink>
            </Subtitle>
          </Info>
          <RightSection>
            <CloseButton onClick={goBack} aria-label="Close button">
              <CloseIcon />
            </CloseButton>
            {media.length > 1 && (
              <Thumbnails>
                {media.map(({ thumbnail }, i) => (
                  <Link
                    key={thumbnail}
                    replace={true}
                    to={{
                      hash,
                      pathname,
                      search: stringify({
                        ...searchRest,
                        selected: i || undefined,
                      }),
                    }}
                  >
                    <Thumbnail
                      active={selected === i}
                      src={thumbnail}
                      alt={title ? title : ''}
                    />
                  </Link>
                ))}
              </Thumbnails>
            )}
          </RightSection>
        </Header>
        <ScrollBox scroll={true}>
          <Content>
            {media[selected].mediaType === 'IMAGE' && (
              <Image src={media[selected].file} alt={title ? title : ''} />
            )}
            {media[selected].mediaType === 'VIDEO' && (
              <Video src={media[selected].file} alt={title ? title : ''} />
            )}
          </Content>
        </ScrollBox>
      </Container>
    </Portal>
  );
};

export default connect(Images);
