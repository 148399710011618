import DefaultButton from 'components/Button';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => `2rem ${theme.wrapperPaddingH.mobile}`};

  ${from.mobileXL`
    padding: ${({ theme }) => `3rem ${theme.wrapperPaddingH.desktop}`};
`}

  ${from.laptop`
padding: ${({ theme }) => `5rem ${theme.wrapperPaddingH.desktop}`};
`}
`;

export const Content = styled.div`
  ${container};
  text-align: center;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 1.375rem;
  ${from.tablet`
    font-size: 2.5rem;
  `};
`;

export const Description = styled.p`
  font-size: 1rem;
  margin: 0 auto;
  max-width: 55.75rem;
  white-space: pre-line;

  ${from.tablet`
    font-size: 1.25rem;
    line-height: 1.5;
  `};
`;

export const StrongText = styled.strong`
  display: block;
  margin-top: ${({ theme }) => theme.spacing.lg};
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
`;

export const Button = styled(DefaultButton)`
  width: auto;
`;
