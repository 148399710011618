import { FormErrorMap, FormValidator } from 'utils/forms';
import { SupportForm } from './index';

export const validateSupportForm: FormValidator<SupportForm> = ({
  issueDescription,
}) => {
  const errors: FormErrorMap<SupportForm> = {};

  if (issueDescription && issueDescription.length === 0) {
    errors.issueDescription = 'The issue description is empty';
  }

  return errors;
};
