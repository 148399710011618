import { createSelector } from 'reselect';
import { Selector } from '../types';
import { ConfirmationModal, ModalState, ModalType, ReportModal } from './types';

const stateSelector: Selector<ModalState> = ({ modal }) => modal;

export const selectConfirmationModalData: Selector<
  ConfirmationModal['data'] | null
> = createSelector(stateSelector, (state) =>
  state.current === ModalType.CONFIRMATION ? state.data : null,
);

export const selectPremiumInterruptionModalOpen: Selector<boolean> = createSelector(
  stateSelector,
  (state) => state.current === ModalType.PREMIUM_INTERRUPTION,
);

export const selectReportModalData: Selector<
  ReportModal['data'] | null
> = createSelector(stateSelector, (state) =>
  state.current === ModalType.REPORT ? state.data : null,
);

export const selectSignUpModalOpen: Selector<boolean> = createSelector(
  stateSelector,
  (state) => state.current === ModalType.SIGN_UP,
);
