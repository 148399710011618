import React, { FC, useCallback, useRef } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { group } from 'utils/group';
import FileList from './FileList';
import { handleDragEnd, useFilesPerRow } from './logic';
import { FileGroup, FileGroupLabel, Files, FileZone } from './styles';
import { Props } from './types';

export const UploadNewImage = Symbol('UploadNewImage');

const MultiFileVariant: FC<Props> = ({
  entityName,
  entityNamePlural,
  files,
  getDropZoneProps,
  isMouseInput,
  onChange,
  onDeleteFile,
  onUploaded,
  onEdited,
  open,
}) => {
  const anyUploading = files.some((media) => media.status === 'uploading');
  const fileGroupRef = useRef<HTMLDivElement | null>(null);
  const filesPerRow = useFilesPerRow(fileGroupRef);

  const onDragEnd = useCallback(
    handleDragEnd({
      files,
      filesPerRow,
      onChange,
    }),
    [files, filesPerRow, onChange],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Files isMouseInput={isMouseInput} oneFile={files.length === 1}>
        <Droppable direction="horizontal" droppableId="main-image">
          {(provided, snapshot) => (
            <FileGroup isOtherImages={false}>
              <FileGroupLabel>Main {entityName}</FileGroupLabel>
              <FileZone
                isDraggingOver={snapshot.isDraggingOver}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <FileList
                  anyUploading={anyUploading}
                  droppablePlaceholder={provided.placeholder}
                  files={[files[0]]}
                  getDropZoneProps={getDropZoneProps}
                  isDraggingOver={snapshot.isDraggingOver}
                  isMouseInput={isMouseInput}
                  onDeleteFile={onDeleteFile}
                  onUploaded={onUploaded}
                  onEdited={onEdited}
                  open={open}
                />
              </FileZone>
            </FileGroup>
          )}
        </Droppable>
        <FileGroup isOtherImages={true} ref={fileGroupRef}>
          <FileGroupLabel>Other {entityNamePlural}</FileGroupLabel>
          {group([...files.slice(1), UploadNewImage], filesPerRow).map(
            (images, i) => (
              <Droppable
                direction="horizontal"
                droppableId={`other-images-${i}`}
                key={i}
              >
                {(provided, snapshot) => (
                  <FileZone
                    isDraggingOver={snapshot.isDraggingOver}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <FileList
                      anyUploading={anyUploading}
                      droppablePlaceholder={provided.placeholder}
                      files={images}
                      getDropZoneProps={getDropZoneProps}
                      isDraggingOver={snapshot.isDraggingOver}
                      isMouseInput={isMouseInput}
                      onDeleteFile={onDeleteFile}
                      onEdited={onEdited}
                      onUploaded={onUploaded}
                      open={open}
                    />
                  </FileZone>
                )}
              </Droppable>
            ),
          )}
        </FileGroup>
      </Files>
    </DragDropContext>
  );
};

export default MultiFileVariant;
