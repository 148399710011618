import { createSelector } from 'reselect';
import { RootState } from '../types';

const selectConfigurationState = (state: RootState) => state.configuration;

export const selectConversationTags = createSelector(
  selectConfigurationState,
  (state) => state.conversationTags,
);

export const selectPlans = createSelector(
  selectConfigurationState,
  (state) => state.plans,
);

const TAG_NAME_REGEX = /:\s?/;

export const selectVideoTypesTags = createSelector(
  selectConfigurationState,
  (state) =>
    state.videoType.map((tag) => {
      const id = tag.id;
      const label = tag.name;
      const url = tag.name.replace(TAG_NAME_REGEX, '-');
      return { label, url, id };
    }),
);
