import { Button as DefaultButtonPrimary } from 'components';
import { NotFoundIcon } from 'components/icons';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { from, viewContainer } from 'styles/mixins';

export const Container = styled.div``;

export const ErrorImage = styled(NotFoundIcon)`
  height: 13, 5625rem;
  width: 15.6875rem;
`;

export const Content = styled.div`
  ${viewContainer};
  text-align: center;

  ${from.laptop`
    padding: ${({ theme }) => theme.spacing.md};
  `};
`;

export const Image = styled.img`
  width: 15.5rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 2.5rem;
`;

export const Subtitle = styled.h2`
  font-size: 1.75rem;
`;

export const Paragraph = styled.p`
  font-size: 1.25rem;
  margin: auto;
  max-width: 39rem;
`;

export const Button = styled(DefaultButtonPrimary)`
  font-size: 1.125rem;
  letter-spacing: 1.29px;
  margin-top: 1.875rem;
  min-height: 2.875rem;
  padding: ${({ theme }) => theme.spacing.md} 2.375rem;
  width: auto;
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;
