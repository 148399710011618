import { connect } from 'react-redux';
import { getFeedbackRequest } from 'redux/modules/feedback';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { match, history: { push } }: OwnProps,
) => {
  const id = match?.params?.id;

  return {
    id,
    push,
    feedbackRequest: state.feedback.byId[id ?? ''],
    loading: state.feedback.loading,
    url: match?.url,
  };
};

const mapDispatchToProps = {
  getFeedbackRequest: getFeedbackRequest.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
