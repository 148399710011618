import {
  ArrowLink as DefaultArrowLink,
  Button as DefaultButton,
  Fold as DefaultFold,
  Link as DefaultLink,
  SectionTitle as DefaultSectionTitle,
  Submissions as DefaultSubmissions,
} from 'components';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const Fold = styled(DefaultFold)`
  padding-bottom: 2.5rem;
  line-height: 1.5;

  ${from.tablet`
    padding-bottom: 3.75rem;
  `}
`;

export const Button = styled(DefaultButton)`
  width: 100%;
  ${from.mobileXL`
    width: auto;
  `}
`;

export const Challenge = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: none;
  position: relative;
  margin-top: -8.25rem;
`;

export const ChallengeImage = styled.img`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  height: auto;
  max-height: 17.44rem;
  object-fit: cover;
  width: 100%;
`;
export const ChallengeOverlay = styled.div`
  background-color: ${({ theme }) => addAlpha(theme.colors.greyBlue, 0.3)};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Label = styled.span`
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  position: absolute;
  line-height: 1.5rem;
  right: 1rem;
  top: 1rem;
  padding: 0.3rem 1rem 0.09rem 0.75rem;
  letter-spacing: 0.0475rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-transform: uppercase;

  :before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 15px 0;
    border-color: transparent ${({ theme }) => theme.colors.accent} transparent
      transparent;
    left: -13px;
    position: absolute;
    top: 0;
  }

  ${from.mobileXL`
    font-size: 1rem;
    right: ${({ theme }) => theme.spacing.lg};
    top: ${({ theme }) => theme.spacing.lg};
`}
`;

export const Current = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  margin-top: 6rem;
`;

export const Content = styled.div`
  ${container};
  position: relative;
  padding: 2rem ${({ theme }) => theme.wrapperPaddingH.mobile} 1.875rem;
  ${from.tablet`
    padding: 2rem ${({ theme }) => theme.wrapperPaddingH.desktop} 5rem;
  `};
`;

export const Section = styled.div``;

export const SectionReview = styled.div`
  > :first-child {
    margin-top: 0.8rem;
  }

  ${from.tablet`
  > :first-child {
    margin-top: 1.75rem;
  }
`}
`;

export const Featured = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => addAlpha(theme.colors.grey300, 0.4)};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: none;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);

  > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${from.mobileXL`
    padding: 2rem 3.75rem;
`}

  ${from.tabletH`
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem 3.75rem;

    > *:not(:last-child) {
      margin-right: 5.5rem;
      margin-bottom: 0;
    }
  `};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FeaturedTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin: 0;

  ${from.tablet`
    font-size: 1.75rem;
`}
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 1.67;
  margin-bottom: auto;

  ${from.tablet`
    font-size: 1.125rem;
`}
`;

export const Details = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const CountdownTitle = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  letter-spacing: 0.0275rem;
  margin-bottom: 1.25rem;
  text-align: center;
  text-transform: uppercase;
`;

export const CountdownContainer = styled.div`
  margin-bottom: 3.625rem;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const SeeDetailsLink = styled.div`
  margin-top: 0;
  width: auto;

  ${from.tabletH`
    margin-top: auto;
    display: flex;
    width: 100%;
  `};
`;

export const LatestsubmissionParagraph = styled.p`
  font-size: 1.25rem;
  line-height: 1.4;
  letter-spacing: 0;
  margin-bottom: 1.875rem;
`;

export const LatestSubmissionsTitle = styled(DefaultSectionTitle)`
  font-size: 1.5rem;
  letter-spacing: 0;
  line-height: 1.2;
  margin-top: 3.75rem;
  margin-bottom: 1.875rem;

  ${from.tablet`
font-size: 1.75rem;
`}
`;

export const ArrowLink = styled(DefaultArrowLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-content: center;
  line-height: 1;
  letter-spacing: 1px;
  margin-top: 1.875rem;
  padding: ${({ theme }) => theme.spacing.sm} 0;
  text-transform: uppercase;
  width: 8.375rem;
  :hover:not(:disabled) {
    box-shadow: 0 2px 8px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const HelpSection = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  margin-top: 3.75rem;
`;

export const Help = styled.div`
  ${container};

  padding: 3.5rem ${({ theme }) => theme.spacing.lg};

  ${from.mobileXL`
    padding: 5rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const HelpTitle = styled(DefaultSectionTitle)`
  line-height: 1.2;
`;

export const HelpText = styled.p`
  font-size: 1.25rem;
  line-height: 1.75;
`;

export const Submissions = styled(DefaultSubmissions)`
  margin-top: ${({ theme }) => theme.spacing.lg};
`;
