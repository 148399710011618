import { BannerWhite, Button } from 'components';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import { handleNotifyClick } from './logic';
import { Props } from './types';

const AlreadySubmittedContainer: FC<Props> = ({
  subtitle,
  title,
  variant = 'challenge',
  editSettings,
  ...props
}) => {
  const onClick = useCallback(handleNotifyClick({ editSettings, variant }), [
    editSettings,
    variant,
  ]);

  const isSubscribed =
    variant === 'challenge'
      ? props.isSubscribedToChallenges
      : props.isSubscribedToExercises;

  return (
    <BannerWhite subtitle={subtitle} title={title}>
      {!isSubscribed && <Button onClick={onClick}>Notify me</Button>}
    </BannerWhite>
  );
};

export default connect(AlreadySubmittedContainer);
