import { connect } from 'react-redux';
import { getExerciseResponse } from 'redux/modules/exerciseResponse';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { match, history: { push } }: OwnProps,
) => {
  const exerciseSlug = match?.params?.exerciseSlug;
  const submissionSlug = match?.params?.submissionSlug;
  const authUserIsSwdTeam = state.auth.user?.swdTeam;

  return {
    authUserIsSwdTeam,
    exerciseSlug,
    submissionSlug,
    push,
    submission: state.exerciseResponse.bySlug[submissionSlug ?? ''],
    loading: state.exerciseResponse.loading,
    url: match?.url,
  };
};

const mapDispatchToProps = {
  getExerciseResponse: getExerciseResponse.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
