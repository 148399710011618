import React, { FC, memo } from 'react';
import { Button, Container, Image, Overlay, Title } from './styles';
import { Props } from './types';

const VideoPaywall: FC<Props> = ({ image, closeModal, ...rest }) => (
  <Container {...rest}>
    {typeof image === 'object' ? (
      <picture>
        {image.sources.map((source) => (
          <source key={source.type} srcSet={source.srcSet} type={source.type} />
        ))}
        <Image src={image.default} alt="" aria-hidden="true" />
      </picture>
    ) : (
      <Image src={image} alt="" aria-hidden="true" />
    )}
    <Overlay />
    <Title>Upgrade to premium to access this full video and many more!</Title>
    <Button variant="premiumAccent" onClick={closeModal} to="/premium">
      Go premium
    </Button>
  </Container>
);

export default memo(VideoPaywall);
