import { BannerWhite } from 'components';
import ProtectedButton from 'containers/Common/ProtectedButton';
import React, { FC, memo } from 'react';
import { Props } from './types';

const NoResults: FC<Props> = ({ url }) => {
  return (
    <BannerWhite title="No submissions yet. There are currently no submissions for this challenge. Be the first to submit your entry! “Submit your entry!">
      <ProtectedButton to={{ hash: 'accept-challenge', pathname: url }}>
        Let's Practice
      </ProtectedButton>
    </BannerWhite>
  );
};

export default memo(NoResults);
