import { Discover } from 'model';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { selectDiscoverBySlug } from 'redux/modules/discover';
import { getUserLiked } from 'redux/modules/users';
import { addVote, removeVote } from 'redux/modules/vote';
import { useActions } from 'utils/useActions';
import { Props } from './types';

export const useConnect = ({ user }: { user: Props['user'] }) => {
  const { push } = useHistory();
  const { search, state } = useLocation();
  const { path, url } = useRouteMatch<{ userSlug?: string }>();

  const discoverBySlug = useSelector(selectDiscoverBySlug);

  const actions = useActions({
    addVote: addVote.request,
    getUserLiked: getUserLiked.request,
    removeVote: removeVote.request,
  });

  return {
    ...actions,
    path,
    push,
    search,
    state,
    url,
    loading: user ? user.likedSlugs.loading : false,
    pageCount: user ? user.likedSlugs.pageCount : 0,
    items: user
      ? (user.likedSlugs.slugs
          .map((slug) => discoverBySlug[slug])
          .filter((it): it is NonNullable<typeof it> => !!it) as Discover[])
      : [],
  };
};
