import { Modal } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(Modal)`
  & .MuiPaper-root {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 2rem;
    overflow: hidden;

    ${from.mobileXL`
      max-width: 28rem;
      min-height: unset;
      width: 90vw;
    `};
  }
`;

export const Title = styled.h2`
  margin: 0 0 1.25rem;
  font-size: 26px;
  line-height: 2.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Paragraph = styled.p`
  width: 100%;
  line-height: 1.5;
  margin-bottom: 0;

  :first-of-type {
    margin-top: 0;
  }

  strong {
    font-weight: ${({ theme }) => theme.weights.semiBold};
  }
`;
