import { connect } from 'react-redux';
import { selectPlans } from 'redux/modules/configuration';
import {
  reactivateSubscription,
  selectUserPremiumInfo,
} from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  info: selectUserPremiumInfo(state),
  plans: selectPlans(state),
});

const mapDispatchToProps = {
  reactivateSubscription: reactivateSubscription.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
