import BaseCard from 'components/BaseCard';
import { getTextFromHtml } from 'components/RichText';
import React, { FC, memo } from 'react';
import {
  RecapContent,
  RecapInfo,
  StaticDescription as Description,
  SumUpIcon,
  SumUpLabel,
  SumUpSpan,
} from './styles';
import { Props } from './types';

const RecapCard: FC<Props> = ({
  tags,
  title,
  thumbnail,
  srcSet,
  authorAvatar,
  authorName,
  authorType,
  description,
}) => (
  <BaseCard
    isRecap={true}
    srcSet={srcSet}
    outline={true}
    thumbnail={thumbnail}
    tags={tags}
    title={title}
  >
    <RecapContent title={title} tags={tags}>
      <Description>{!!description && getTextFromHtml(description)}</Description>
      {typeof authorName !== 'undefined' && (
        <RecapInfo
          authorAvatar={authorAvatar || undefined}
          authorName={authorName}
          authorType={authorType}
        />
      )}
    </RecapContent>
    <SumUpLabel>
      {' '}
      <SumUpIcon />
      <SumUpSpan>SWD SUMMING UP</SumUpSpan>
    </SumUpLabel>
  </BaseCard>
);

export default memo(RecapCard);
