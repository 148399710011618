import { Loader, Pagination, Podcast } from 'components';
import React, { FC } from 'react';
import { ArrowLink, Results, ResultsHeader, ResultsTitle } from '../../styles';
import connect from './connect';
import { Props } from './types';

const Podcasts: FC<Props> = ({
  available,
  count,
  isDetail,
  items,
  loading,
  location: { search },
  match: { url },
  page,
  pageCount,
  setPage,
  ...rest
}) => {
  if (!available && !loading) {
    return null;
  }

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>podcast {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: podcast"
            to={{
              search,
              pathname: `${url}/podcast`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          {items.map(({ id, link, title }) => (
            <Podcast key={id} href={link} title={title} target="_blank" />
          ))}
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Podcasts);
