import { Button as DefaultButton } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Button = styled(DefaultButton)`
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  margin-top: ${({ theme }) => theme.spacing.md};
  text-align: center;
  ${from.mobileXL`
    margin: 0 0 0 auto;
  `};
`;
