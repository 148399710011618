import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import { CHALLENGES_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { getChallenge, getChallenges, _upsertChallengesById } from './actions';
import { ChallengeAction, ChallengeState } from './types';

export const INITIAL_STATE: ChallengeState = {
  ...defaultStandardState,
  active: null,
};

const reducer: Reducer<ChallengeState, ChallengeAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getChallenges.success):
      return {
        ...state,
        active: action.payload.active || null,
      };
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getChallenges.request),
      success: isActionOf(getChallenges.success),
      failure: isActionOf(getChallenges.failure),
    },
    getOne: {
      request: isActionOf(getChallenge.request),
      success: isActionOf(getChallenge.success),
      failure: isActionOf(getChallenge.failure),
    },
    _upsertById: isActionOf(_upsertChallengesById),
  },
  contractItem: (challenge) => challenge,
  initialState: INITIAL_STATE,
  perPage: CHALLENGES_PER_PAGE,
})(reducer);
