import { MediaListViewer } from 'components';
import React, { FC, useMemo } from 'react';

import { Props } from './types';

const ImageListViewer: FC<Props> = ({ images, ...rest }) => {
  // Pending to refactor to image object
  function getFileType(image: string): string {
    if (image.includes('video/')) {
      return 'VIDEO';
    }

    return 'IMAGE';
  }

  return (
    <MediaListViewer
      media={useMemo(() => {
        return images.map((image) => ({
          file: image,
          mediaType: getFileType(image),
          thumbnail: image,
        }));
      }, [images])}
      {...rest}
    />
  );
};

export default ImageListViewer;
