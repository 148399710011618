import { createEditorState, parseHTML } from 'components/Editor';
import { isLoading } from 'components/MediaInput';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import {
  FeedbackForm,
  FEEDBACK_FORM_KEY,
  UPLOAD_MEDIA_FIELD,
  visualTagInputsInitialValues,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { imageListConvertMedia } from 'utils/imageToMedia';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { isNotFalsy } from 'utils/NonFalsy';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { editId }: OwnProps) => {
  const form = FEEDBACK_FORM_KEY(editId);

  const { selectIsPristine, selectValues } = makeFormSelectors<FeedbackForm>(
    form,
  );

  const values = selectValues(state);
  const feedbackToEdit = state.feedback.byId[editId ?? ''];

  return {
    form,
    initialValues: {
      ...visualTagInputsInitialValues,
      [UPLOAD_MEDIA_FIELD]: [],
      context: createEditorState(),
      addToMyGallery: false,
      expirationDate: null,
      ...(feedbackToEdit
        ? {
            [UPLOAD_MEDIA_FIELD]: imageListConvertMedia([
              feedbackToEdit.imageList?.mainImage ?? '',
              ...(feedbackToEdit.imageList?.extraImages ?? []),
            ]).filter(isNotFalsy),
            addToMyGallery: feedbackToEdit.addToMyGallery,
            context: createEditorState(parseHTML(feedbackToEdit.context)),
            expirationDate:
              feedbackToEdit.expiryDate && parseISO(feedbackToEdit.expiryDate),
            graphType: feedbackToEdit.graphTypes,
            industry: feedbackToEdit.graphIndustry,
            title: feedbackToEdit.title,
            tools: feedbackToEdit.graphTools,
            topic: feedbackToEdit.graphTopic,
          }
        : {}),
    },
    isPristine: selectIsPristine(state),
    loading: state.feedback.loading || isLoading(values?.[UPLOAD_MEDIA_FIELD]),
    showExpirationDateInput: !!values && isValid(values.expirationDate),
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<FeedbackForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FeedbackForm>({
    destroyOnUnmount: false,
  }),
) as FormDecorator<FeedbackForm, OwnProps, ConnectedProps>;
