import { Input } from 'components';
import { addYears, formatISO } from 'date-fns';
import { addMonths } from 'date-fns/esm';
import { AddExtraSeatsCorporatePremiumNames } from 'model/forms/AddExtraSeatsCorporatePremium';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { formatMonthNameDayandYear } from 'utils/dates';
import { required } from 'utils/validations';
import connect from './connect';
import {
  Amount,
  Container,
  ContentGrid,
  Note,
  SubmitButton,
  Subtitle,
  TextContainer,
  Title,
} from './styles';
import { Props } from './types';

const Form: FC<Props> = ({
  handleSubmit,
  planAmountPerMonth = 11.99,
  planInterval,
  quantity = 1,
  totalUsers,
  loading,
}) => {
  const today = new Date();
  const nextYear = addYears(today, 1);
  const nextMonth = addMonths(today, 1);

  const totalAmountPerMonth = planAmountPerMonth * quantity;

  const getNewCost = () => {
    return (
      totalAmountPerMonth +
      planAmountPerMonth * (totalUsers ?? 1)
    ).toFixed(2);
  };

  return (
    <Container onSubmit={handleSubmit}>
      <ContentGrid>
        <TextContainer>
          <Title>Number of seats you want to add</Title>
          <Subtitle>
            Seats are ${planAmountPerMonth} / {planInterval} on your current
            plan
          </Subtitle>
        </TextContainer>
        <Field
          component={Input}
          name={AddExtraSeatsCorporatePremiumNames.totalUsers}
          type="number"
          validate={required}
          value={totalUsers}
          min="1"
          step="1"
        />
      </ContentGrid>
      <ContentGrid>
        <TextContainer>
          <Title>New cost per {planInterval} (charged now)</Title>
          <Subtitle>
            Next payment is on{' '}
            {planInterval === 'month'
              ? formatMonthNameDayandYear(formatISO(nextMonth))
              : formatMonthNameDayandYear(formatISO(nextYear))}
          </Subtitle>
        </TextContainer>
        <Amount>${getNewCost()}</Amount>
      </ContentGrid>
      <Note>
        <span>One time adjustment refund:</span> Immediate pro-rated refund for
        your former seats.
      </Note>
      <SubmitButton type="submit" $disabled={loading}>
        {loading ? 'Please wait…' : `Add ${totalUsers} seats`}
      </SubmitButton>
    </Container>
  );
};

export default connect(Form);
