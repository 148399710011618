import React, { FC, memo } from 'react';
import { Container } from './styles';
import { Props } from './types';

const MultiValue: FC<Props> = ({ getValue, selectProps: { inputValue } }) => {
  const searching = !!inputValue;
  const value = getValue();

  if (searching || !Array.isArray(value)) {
    return null;
  }

  return (
    <Container>
      {value.length === 1 ? value[0].label : `${value.length} tags selected`}
    </Container>
  );
};

export default memo(MultiValue);
