import DefaultOptions from 'containers/Main/Discover/DiscoverRoot/Options';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

// ToDo: Copy & paste from "Discover". We will probaby replace this with a custom ''Gallery' component
export const Images = styled.div<{ myProfile: boolean }>`
  margin-top: ${({ theme }) => theme.spacing.xlg};
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: ${({ theme }) => theme.spacing.lg};

  > * {
    border: solid 1px ${({ theme }) => theme.colors.greyBlue};
    border-radius: 0.4rem;
    overflow: hidden;

    :hover {
      > * {
        opacity: 1;
      }
    }
  }

  ${({ myProfile }) =>
    myProfile &&
    css`
      > :first-child {
        border: none;
      }
    `}
`;

export const Options = styled(DefaultOptions)`
  margin-top: ${({ theme }) => theme.spacing.lg};
`;
