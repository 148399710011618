import React, { FC, memo } from 'react';
import { Button, Container, Plan, Price, Subtitle, Tag, Title } from './styles';
import { Props } from './types';

const PlanPromotion: FC<Props> = ({
  disabled,
  id,
  index,
  isCorporate,
  period,
  price,
  savings,
  subtitle,
  features,
  ...rest
}) => {
  return (
    <Container {...rest} $disabled={disabled}>
      <Plan>
        <Title>{period}</Title>
        <Price>${price} US</Price>
        <Subtitle>{subtitle}</Subtitle>
        <Button
          disabled={disabled}
          variant={'premiumSecondary'}
          to={{
            pathname: isCorporate ? '/subscribe/corporate' : '/subscribe',
            state: {
              selectedPlan: id,
            },
          }}
        >
          Go premium!
        </Button>
        {savings ? (
          <Tag>
            Save {`$${Math.round(savings)} ${isCorporate ? `per seat` : ''}`}
          </Tag>
        ) : null}
        {features}
      </Plan>
    </Container>
  );
};

export default memo(PlanPromotion);
