import { BackendLiveEvent, BookEvent } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { from } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getTimeZoneName } from 'utils/dates';
import {
  createLiveEventBooking,
  editLiveEventBooking,
  getLiveEvents,
} from './actions';

export const getLiveEventsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getLiveEvents.request)),
    mergeMap(() =>
      request<{ results: BackendLiveEvent }>({
        path: 'video/live_event',
      }).pipe(
        mergeMap(({ results: events }) => [
          getLiveEvents.success(
            events.map((event) => ({
              ...event,
              booking: event.booking?.[0] ?? undefined,
            })),
          ),
        ]),
        catchError(() => [getLiveEvents.failure()]),
      ),
    ),
  );

export const editLiveEventBookingEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(editLiveEventBooking.request)),
    mergeMap(({ payload: { id, ...body } }) =>
      request<BookEvent>({
        body,
        method: 'PATCH',
        path: `video/live_event/${id}/booking`,
      }).pipe(
        mergeMap((response) => [
          editLiveEventBooking.success({ ...response, eventId: id }),
        ]),
        catchError(() => [editLiveEventBooking.failure()]),
      ),
    ),
  );

export const createLiveEventBookingEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(createLiveEventBooking.request)),
    withLatestFrom(from(getTimeZoneName())),
    mergeMap(([{ payload: { id, ...body } }, timeZone]) =>
      request<BookEvent>({
        body: {
          ...body,
          timeZone,
        },
        method: 'PUT',
        path: `video/live_event/${id}/booking`,
      }).pipe(
        mergeMap((response) => [
          createLiveEventBooking.success({ ...response, eventId: id }),
        ]),
        catchError(() => [createLiveEventBooking.failure()]),
      ),
    ),
  );

export default combineEpics(
  getLiveEventsEpic,
  editLiveEventBookingEpic,
  createLiveEventBookingEpic,
);
