import { Loader } from 'components';
import React, { ComponentType, Suspense } from 'react';

export default function lazy<TProps extends {}>(
  factory: () => Promise<{ default: ComponentType<TProps> }>,
): ComponentType<TProps> {
  const Component = React.lazy(factory);

  return (props) => (
    <Suspense fallback={<Loader />}>
      {/* tslint:disable-next-line:no-any */}
      <Component {...(props as any)} />
    </Suspense>
  );
}
