import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { from } from 'styles/mixins';
import { pluralize } from 'utils/pluralize';
import {
  ActivityContainer,
  ActivityText,
  ActivityTextPrimary,
  Container,
  FireIcon,
  NumberStreak,
} from './styles';
import { Props } from './types';

const Data: React.FC<Props> = ({ challengeStreak, datapoints }) => {
  const isMobile = !useMediaQuery(from.tabletH.query);

  const numberStreak = (
    <NumberStreak>
      <span>{challengeStreak}</span>

      <FireIcon />
    </NumberStreak>
  );

  return (
    <Container>
      {isMobile ? (
        numberStreak
      ) : (
        <ActivityContainer>
          <ActivityText>{`Current streak`}</ActivityText>

          {numberStreak}
        </ActivityContainer>
      )}

      <ActivityContainer>
        <ActivityText>
          {!isMobile ? 'Recognition: ' : null}

          <ActivityTextPrimary>
            {`${datapoints} ${pluralize(
              datapoints,
              'Datapoint',
              'Datapoints',
            )}`}
          </ActivityTextPrimary>
        </ActivityText>
      </ActivityContainer>
    </Container>
  );
};

export default Data;
