import { GalleryImage, Loader, Pagination } from 'components';
import DiscoverItem from 'containers/Common/DiscoverItem';
import {
  handleAddVote,
  handleRemoveVote,
} from 'containers/Main/Discover/DiscoverRoot/logic';
import React, { FC, useCallback, useEffect } from 'react';
import { Route } from 'react-router';
import { DISCOVER_PER_PAGE } from 'utils/config';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { usePrevious } from 'utils/usePrevious';
import { Loading } from '../styles';
import AddToGalleryFirstStepForm from './AddToGalleryFirstStepForm';
import { useConnect } from './connect';
import { handleDelete } from './logic';
import { Container, Images, Options } from './styles';
import { Props } from './types';

const Gallery: FC<Props> = ({ contentRef, user }) => {
  const {
    addToGalleryImages,
    addVote,
    getUserGallery,
    items,
    loading,
    openConfirmationModal,
    pageCount,
    params,
    path,
    push,
    removeVote,
    search,
    state,
    url,
  } = useConnect({ user });

  const page = getPageFromQuery(search);

  const isMe = !params.userSlug;

  const prevAddToGalleryImages = usePrevious(addToGalleryImages);

  const count = isMe ? DISCOVER_PER_PAGE - 1 : DISCOVER_PER_PAGE;

  const userSlug = user.slug;

  useEffect(() => {
    getUserGallery({ search, count, page, slug: userSlug });
  }, [getUserGallery, search, count, page, userSlug]);

  useEffect(() => {
    if (prevAddToGalleryImages === 0 && addToGalleryImages > 0) {
      push(`${url}/add`);
    }
  }, [addToGalleryImages, prevAddToGalleryImages, push, url]);

  const onAddVote = useCallback(handleAddVote({ addVote }), [addVote]);

  const onDelete = useCallback(handleDelete({ openConfirmationModal }), [
    openConfirmationModal,
  ]);

  const onRemoveVote = useCallback(handleRemoveVote({ removeVote }), [
    removeVote,
  ]);

  const goBack = useCallback(() => {
    push({
      search,
      pathname: url,
      state: {
        scrollToTop: false,
      },
    });
  }, [push, search, url]);

  if (loading && !items.length) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  return (
    <Container>
      <Options />
      <Images myProfile={isMe}>
        {isMe && <AddToGalleryFirstStepForm />}
        {items.map(
          ({ created, id, image, isVoted, user, url: itemUrl, title }) => (
            <GalleryImage
              isMe={isMe}
              avatar={user.avatar}
              created={created}
              hasAssociatedContent={!!itemUrl}
              id={id}
              image={image}
              imageAlt={title}
              isVoted={isVoted}
              key={id}
              onAddVote={onAddVote}
              onDelete={onDelete}
              onRemoveVote={onRemoveVote}
              to={{
                search,
                pathname: `${url}/${id}`,
                state: {
                  scrollToTop: false,
                },
              }}
              userType={getUserType(user)}
            />
          ),
        )}
      </Images>
      <Pagination
        contentRef={contentRef}
        current={Math.min(page, pageCount)}
        total={pageCount}
        state={state}
        url={url}
      />
      <Route path={`${path}/:discoverId`}>
        <DiscoverItem onClose={goBack} scroll={true} />
      </Route>
    </Container>
  );
};

export default Gallery;
