import bowser from 'bowser';
import React, { FC, memo, useCallback } from 'react';
import { handleChange, toNativeInputDate } from './logic';
import { DatePicker, Input } from './styles';
import { Props } from './types';

const browser = bowser.getParser(navigator.userAgent);

const showNativeInput = browser.satisfies({
  edge: '>=1',
  android: {
    chrome: '>=1',
    firefox: '>=1',
  },
});

const DateInput: FC<Props> = ({
  className,
  input: { value, onChange: parentOnChange, ...input },
  max,
  min,
  ...rest
}) => {
  const onChange = useCallback(handleChange({ onChange: parentOnChange }), [
    parentOnChange,
  ]);

  return showNativeInput ? (
    <Input
      className={className}
      input={{
        onChange,
        value: toNativeInputDate(value),
        ...input,
      }}
      max={max && toNativeInputDate(max)}
      min={min && toNativeInputDate(min)}
      type="date"
      {...rest}
    />
  ) : (
    <DatePicker
      className={className}
      maxDate={max}
      minDate={min}
      onChange={parentOnChange}
      value={value}
    />
  );
};

export default memo(DateInput);
