import { Activity, PaginatedRequest, PaginatedResponse } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const getActivityItems = createAsyncAction(
  'activity/GET_ACTIVITY_ITEMS_REQUEST',
  'activity/GET_ACTIVITY_ITEMS_SUCCESS',
  'activity/GET_ACTIVITY_ITEMS_FAILURE',
)<
  PaginatedRequest & { search: string },
  PaginatedResponse<Activity>,
  undefined
>();
