import { FormNameMap } from 'utils/forms';

export const EDIT_PROFILE_FORM_KEY = 'forms/EDIT_PROFILE';

export interface EditProfileForm {
  avatar: string | null;
  email: string | null;
  firstName: string;
  lastName: string;
  location: string;
  bio: string | null;
  goals: string | null;
  socialWeb: string | null;
  socialTwitter: string | null;
  socialLinkedin: string | null;
  socialInstagram: string | null;
  companyName: string | null;
  companyRole: string | null;
  nBooks: number;
  podcastFollower: boolean;
  wAttended: boolean;
  showActivityInfo: boolean;
  showProfessionalInfo: boolean;
  showSocialInfo: boolean;
}

export const EditProfileFormNames: FormNameMap<EditProfileForm> = {
  avatar: 'avatar',
  firstName: 'firstName',
  lastName: 'lastName',
  location: 'location',
  bio: 'bio',
  goals: 'goals',
  companyName: 'companyName',
  companyRole: 'companyRole',
  email: 'email',
  socialLinkedin: 'socialLinkedin',
  socialInstagram: 'socialInstagram',
  socialTwitter: 'socialTwitter',
  socialWeb: 'socialWeb',
  nBooks: 'nBooks',
  podcastFollower: 'podcastFollower',
  wAttended: 'wAttended',
  showActivityInfo: 'showActivityInfo',
  showProfessionalInfo: 'showProfessionalInfo',
  showSocialInfo: 'showSocialInfo',
};
