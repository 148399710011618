import { ClickAwayListener, useMediaQuery } from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import { Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic';
import { from } from 'styles/mixins';
import Breadcrumb from './Breadcrumb';
import {
  ChevronDownIcon,
  ChevronIcon,
  Container,
  ContainerFinalBreadcrumb,
  ContainerFirstBreadcrumb,
  DropdownContainer,
  ResponsiveButtonContainer,
} from './styles';
import { Props } from './types';

// This wrapper exists because react-breadcrumbs-dynamic doesn't wrap the last
// element in a span.
const FinalItem: FC = (props) => (
  <span>
    <Breadcrumb {...props} />
  </span>
);

const Breadcrumbs: FC<Props> = ({ ...rest }) => {
  const isFromLaptop = useMediaQuery(from.laptop.query);

  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  const toggleVisibility = useCallback(
    () => setShowBreadcrumbs((prevState) => !prevState),
    [setShowBreadcrumbs],
  );

  return (
    <Container {...rest}>
      <ContainerFirstBreadcrumb>
        {!isFromLaptop && (
          <ResponsiveButtonContainer onClick={toggleVisibility}>
            <ChevronDownIcon />
          </ResponsiveButtonContainer>
        )}
        {(showBreadcrumbs || isFromLaptop) && (
          <ClickAwayListener onClickAway={toggleVisibility}>
            <DropdownContainer>
              <DynamicBreadcrumbs
                container={Container}
                containerProps={rest}
                item={Breadcrumb}
                separator={<ChevronIcon />}
                finalItem={FinalItem}
              />
            </DropdownContainer>
          </ClickAwayListener>
        )}
      </ContainerFirstBreadcrumb>
      <ContainerFinalBreadcrumb>
        {!isFromLaptop && (
          <DynamicBreadcrumbs
            container={Container}
            containerProps={rest}
            item={Breadcrumb}
            separator={<ChevronIcon />}
            finalItem={FinalItem}
          />
        )}
      </ContainerFinalBreadcrumb>
    </Container>
  );
};

export default Breadcrumbs;
