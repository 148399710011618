import Tag from 'components/Tag';
import styled from 'styled-components';

export const Status = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
`;

export const Ama = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.accent};
  color: white;
`;

export const Container = styled.div`
  margin-right: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;
