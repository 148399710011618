import styled from 'styled-components';

export const Picture = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Sheet = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Circle = Sheet;

export const Plus = Picture;
