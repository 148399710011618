import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ChallengeResponseState } from './types';

const selectState: Selector<ChallengeResponseState> = (state) =>
  state.challengeResponse;

export const selectChallengeResponseById = createSelector(
  selectState,
  (state) => state.byId,
);

export const selectChallengeResponseBySlug = createSelector(
  selectState,
  (state) => state.bySlug,
);

export const selectChallengeLoading = createSelector(
  selectState,
  (state) => state.loading,
);

export const selectSubmissions = createSelector(selectState, ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((it): it is NonNullable<typeof it> => !!it),
);

export const selectLatestSubmissions = createSelector(
  selectSubmissions,
  (submissions) => submissions.slice(0, 3),
);
