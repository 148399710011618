import { AddToGalleryFieldNames, UPLOAD_MEDIA_FIELD } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import { Container, MediaInput } from './styles';
import { Props } from './types';

const Form: FC<Props> = ({ handleSubmit }) => (
  <Container onSubmit={handleSubmit}>
    <Field
      component={MediaInput}
      maxFiles={1}
      name={AddToGalleryFieldNames[UPLOAD_MEDIA_FIELD]}
      onlyImage={true}
    />
  </Container>
);

export default connect(Form);
