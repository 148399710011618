import { FormNameMap } from 'utils/forms';

export const ADD_EXTRA_SEATS_CORPORATE_PREMIUM_FORM_KEY =
  'forms/ADD_EXTRA_SEATS_CORPORATE_PREMIUM';

export interface AddExtraSeatsCorporatePremiumForm {
  totalUsers: number;
}

export const AddExtraSeatsCorporatePremiumNames: FormNameMap<AddExtraSeatsCorporatePremiumForm> = {
  totalUsers: 'totalUsers',
};
