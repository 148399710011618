import debounce from 'lodash/debounce';
import { SearchOptionsForm, SEARCH_OPTIONS_FORM_KEY } from 'model';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  change,
  ConfigProps,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';
import { RootState } from 'redux/modules/types';
import { SEARCH_INPUT_DEBOUNCE_TIME_MS } from 'utils/config';
import { execAll } from 'utils/execAll';
import { FormDecorator } from 'utils/forms';
import { keysOf } from 'utils/keysOf';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { parseOrder } from 'utils/parseOrder';
import { submitOnChange } from 'utils/submitOnChange';
import { without } from 'utils/without';
import { getAvailableFilters } from './logic';
import { OwnProps } from './types';

const { selectIsPristine } = makeFormSelectors<SearchOptionsForm>(
  SEARCH_OPTIONS_FORM_KEY,
);

const defaultValues = {
  aboutExpire: false,
  author: null,
  directionIsAsc: false,
  graphIndustry: null,
  graphTools: null,
  graphTopic: null,
  graphTypes: null,
  conversationTopic: null,
  exerciseTags: null,
  isUnsolved: false,
  order: 'created',
  query: null,
  swdTeam: false,
  videoSeries: null,
  videoPresenters: null,
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const { order, ...filters } = parse(state.router.location.search);
  const { order: availableOrder } = getAvailableFilters(ownProps.contentType);

  const initialValues: ConfigProps<SearchOptionsForm>['initialValues'] = {
    ...defaultValues,
    ...filters,
    ...parseOrder(
      typeof order === 'string' && order
        ? order
        : `-${availableOrder[0]?.value}`,
    ),
  };

  const enabledFilters = keysOf(
    // TODO refactor "without" so it accepts multiple keys
    without(
      without(
        // tslint:disable-next-line: no-any
        without(without(initialValues as any, 'query'), 'directionIsAsc'),
        'order',
      ),
      'page',
    ),
  ).filter(
    // tslint:disable-next-line: no-any
    (key: any) => (initialValues as any)[key] !== (defaultValues as any)[key],
  ).length;

  const { selectValues } = makeFormSelectors<SearchOptionsForm>(
    `${SEARCH_OPTIONS_FORM_KEY}/search`,
  );

  const conversationTagSelected = !!selectValues(state)?.conversationTopic;
  const exerciseTagSelected = !!selectValues(state)?.exerciseTags;

  return {
    conversationTagSelected,
    exerciseTagSelected,
    enabledFilters,
    initialValues,
    conversationTags: state.configuration.conversationTags,
    exerciseTags: state.configuration.exerciseTags,
    form: `${SEARCH_OPTIONS_FORM_KEY}/${ownProps.contentType}`,
    groups: state.configuration.groups,
    industryTags: state.configuration.graphIndustryTags,
    isPristine: selectIsPristine(state),
    loading: state.feedback.loading,
    query: '',
    toolTags: state.configuration.graphToolTags,
    topicTags: state.configuration.graphTopicTags,
    typeTags: state.configuration.graphTypeTags,
    videoSeriesTags: state.configuration.videoSeries,
    videoPresenterTags: state.configuration.videoPresenters,
  };
};

const mapDispatchToProps = {
  submit,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<SearchOptionsForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<SearchOptionsForm>({
    enableReinitialize: true,
    onChange: execAll(
      (values, dispatch, _, prevValues) => {
        const fieldsToReset = [
          'graphIndustry',
          'graphTools',
          'graphTopic',
          'graphTypes',
          'videoSeries',
          'videoPresenters',
        ];
        if (values.conversationTopic !== prevValues.conversationTopic) {
          fieldsToReset.push('exerciseTags');
          fieldsToReset.forEach((field) => {
            dispatch(change(`${SEARCH_OPTIONS_FORM_KEY}/search`, field, null));
          });
        }

        if (values.exerciseTags !== prevValues.exerciseTags) {
          fieldsToReset.push('conversationTopic');
          fieldsToReset.forEach((field) => {
            dispatch(change(`${SEARCH_OPTIONS_FORM_KEY}/search`, field, null));
          });
        }
      },
      submitOnChange<SearchOptionsForm>('directionIsAsc'),
      submitOnChange<SearchOptionsForm>('order'),
      debounce(
        submitOnChange<SearchOptionsForm>('query'),
        SEARCH_INPUT_DEBOUNCE_TIME_MS,
      ),
    ),
  }),
) as FormDecorator<SearchOptionsForm, OwnProps, ConnectedProps>;
