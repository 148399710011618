import communityLanding from 'assets/images/communityLanding.png';
import {
  Card,
  CardGrid,
  Countdown,
  FeedbackComment,
  GalleryImage,
  Loader,
  Member,
  NoResults,
  PageTitle,
  PracticeCard,
} from 'components';
import Statistics from 'components/Card/Statistics';
import { getTextFromHtml } from 'components/RichText';
import DefaultProtectedButton from 'containers/Common/ProtectedButton';
import { formatDistanceToNow } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { getFeedbackRequestStatusList } from 'model';
import { getUserName } from 'model/User';
import React, { FC, useCallback, useEffect } from 'react';
import { getUserType } from 'utils/getUserType';
import { imageToMedia } from 'utils/imageToMedia';
import { pluralize } from 'utils/pluralize';
import { getStatuses } from '../Conversations/ConversationsRoot';
import {
  handleAddVote,
  handleRemoveVote,
} from '../Discover/DiscoverRoot/logic';
import connect from './connect';
import {
  ArrowLink,
  ArrowLinkFit,
  AskFeedbackButton,
  Banner,
  BasicCard,
  BasicCardInfo,
  BasicCardInfoWithImg,
  BasicCardText,
  Button,
  Container,
  Content,
  Conversation,
  Conversations,
  CountdownContainer,
  CountdownTitle,
  Details,
  FeaturedFeedbackTitle,
  FeaturedLink,
  FeedbackComments,
  FeedbackContainer,
  FeedBackSection,
  Gallery,
  Graph,
  GraphImage,
  GraphImg,
  GraphTitle,
  GroupCards,
  GroupContent,
  GroupHeader,
  GroupLabel,
  GroupRow,
  GroupText,
  GroupTitle,
  HeadingRow,
  HeadingSection,
  HighlightedContainer,
  Icon,
  Info,
  InfoDescription,
  InfoSubmissions,
  Jumbotron,
  LandingSectionDescription,
  LastContainer,
  Link,
  MemberGrid,
  MemberSection,
  MyFeedbackContainer,
  MyFeedbackRequest,
  MyPracticeSection,
  NoSubmissionsText,
  NoSubmissionsWrapper,
  Overlay,
  Paragraph,
  PracticeGroup,
  PracticeRow,
  PracticeSection,
  ProtectedButton,
  SeeDetailsLink,
  SmallerJumbotron,
  SubmissionsNumber,
  SubmissionsText,
  TalkSection,
  TalkSectionDescription,
  TalkSectionParagraph,
  Thumbnail,
  Title,
  VisualSection,
} from './styles';
import { Props } from './types';

const Home: FC<Props> = ({
  activeBanner,
  addVote,
  conversations,
  currentUser,
  feedbackRequests,
  getHomeItemsGuest,
  getHomeItemsAuthenticated,
  activeChallenge,
  activeExercise,
  exerciseBankItems,
  myOpenFeedback,
  loading,
  loggedIn,
  members,
  tags,
  items,
  removeVote,
}) => {
  useEffect(() => {
    if (loggedIn) {
      getHomeItemsAuthenticated();
    } else {
      getHomeItemsGuest();
    }
  }, [getHomeItemsAuthenticated, getHomeItemsGuest, loggedIn]);

  const getConversationStatuses = (index: number) =>
    getStatuses(conversations[index], tags);

  const onAddVote = useCallback(handleAddVote({ addVote }), [addVote]);

  const onRemoveVote = useCallback(handleRemoveVote({ removeVote }), [
    removeVote,
  ]);

  return (
    <div id="swd-landing">
      <PageTitle title="SWD community" />
      {loggedIn && activeBanner ? (
        <SmallerJumbotron title={activeBanner.title} img={activeBanner.image}>
          <p>{activeBanner.description}</p>
          {activeBanner.ctaLink && activeBanner.ctaText && (
            <Button to={activeBanner.ctaLink}>{activeBanner.ctaText}</Button>
          )}
        </SmallerJumbotron>
      ) : (
        <Jumbotron title="Welcome to the SWD community!" img={communityLanding}>
          <p>
            This is a place where you can hone your data visualization and
            storytelling skills through practice, getting and giving feedback,
            and discussing topics related to effectively communicating with
            data. Explore, engage, and enhance your data storytelling abilities!
          </p>
        </Jumbotron>
      )}
      <Container>
        {!loggedIn && (
          <PracticeSection>
            <LandingSectionDescription
              text="The way to develop any skill is to practice.
          Here’s a low risk space for you to do just that.
          Take part in one of our regular challenges or exercises
          to flex and refine your data visualization and storytelling
          skills—plus get feedback from others on your work.
          Browse community creations to learn and be inspired."
              title="practice"
              subtitle="learn via"
            />
            {activeChallenge && (
              <BasicCard>
                <Thumbnail
                  alt=""
                  aria-hidden="true"
                  src={activeChallenge.image}
                />
                <Overlay />
                <BasicCardInfoWithImg>
                  <BasicCardText>
                    <Title>Challenge</Title>
                    Each month, a specific challenge is posed, enticing you to
                    undertake something new. Identify data of interest then use
                    it to create your visual and share or browse community
                    creations to learn and be inspired.
                  </BasicCardText>
                  <Button to={`/challenges/${activeChallenge.slug}`}>
                    go to challenge
                  </Button>
                </BasicCardInfoWithImg>
              </BasicCard>
            )}
            {activeExercise && (
              <BasicCard>
                <Thumbnail
                  alt=""
                  aria-hidden="true"
                  src={activeExercise.image}
                />
                <Overlay />
                <BasicCardInfoWithImg>
                  <BasicCardText>
                    <Title>Exercise</Title>
                    Exercises based on real-world scenarios guide you to
                    practice a specific SWD lesson or tip. Download the provided
                    data and solve first hand, then submit your solution to
                    reveal and learn from others’ approaches.
                  </BasicCardText>
                  <Button to={`/exercises/${activeExercise.slug}`}>
                    go to exercise
                  </Button>
                </BasicCardInfoWithImg>
              </BasicCard>
            )}
          </PracticeSection>
        )}
        {loggedIn && (
          <MyPracticeSection>
            <LandingSectionDescription subtitle="learn via" title="practice" />
            <PracticeRow>
              <PracticeGroup>
                <GroupHeader>
                  <GroupLabel>Challenge</GroupLabel>
                  {loading ? (
                    <Loader />
                  ) : activeChallenge ? (
                    <>
                      <GroupTitle>{activeChallenge.name}</GroupTitle>
                      <GroupRow>
                        <Info>
                          <InfoDescription>
                            {activeChallenge.description}
                          </InfoDescription>
                          <InfoSubmissions>
                            <SubmissionsNumber>
                              {activeChallenge.submissionCount}
                            </SubmissionsNumber>
                            <SubmissionsText>
                              {pluralize(
                                activeChallenge.submissionCount,
                                'Submission',
                                'Submissions',
                              )}{' '}
                              so far
                            </SubmissionsText>
                          </InfoSubmissions>
                        </Info>
                        <Details>
                          <CountdownTitle>Challenge ends in</CountdownTitle>
                          <CountdownContainer>
                            <Countdown
                              to={parseISO(activeChallenge.deadline)}
                            />
                          </CountdownContainer>
                          <SeeDetailsLink>
                            <Button
                              to={`/challenges/${activeChallenge.slug}`}
                              variant="primary"
                            >
                              See challenge
                            </Button>
                          </SeeDetailsLink>
                        </Details>
                      </GroupRow>
                    </>
                  ) : (
                    <></>
                  )}
                </GroupHeader>
                <GroupContent>
                  <GroupText>Latest challenge submissions</GroupText>
                  <GroupCards>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {activeChallenge &&
                        activeChallenge.latestSubmissions.length > 0 ? (
                          activeChallenge?.latestSubmissions
                            .slice(0, 3)
                            .map((submission) => (
                              <PracticeCard
                                challengeId={activeChallenge.slug}
                                submissionId={submission.slug}
                                image={
                                  submission.mediaList?.mainMedia.mediaType ===
                                  'IMAGE'
                                    ? submission.mediaList?.mainMedia.file
                                    : ''
                                }
                                link={submission.title}
                                author={submission.user.firstName}
                                hours={
                                  submission.created &&
                                  formatDistanceToNow(
                                    parseISO(submission.created),
                                    { addSuffix: true },
                                  )
                                }
                                key={submission.id}
                              />
                            ))
                        ) : (
                          <NoSubmissionsWrapper>
                            <Icon />
                            <NoSubmissionsText>
                              There are no submissions yet.
                            </NoSubmissionsText>
                          </NoSubmissionsWrapper>
                        )}
                      </>
                    )}
                  </GroupCards>
                  {activeChallenge && (
                    <ArrowLink
                      text={
                        activeChallenge.latestSubmissions.length > 0
                          ? 'See more'
                          : 'Be the first to submit'
                      }
                      visuallyHiddenText={
                        activeChallenge.latestSubmissions.length > 0
                          ? 'challenge submissions'
                          : ''
                      }
                      to={`/challenges/${activeChallenge.slug}`}
                    />
                  )}
                </GroupContent>
              </PracticeGroup>
              <PracticeGroup>
                <GroupContent>
                  <GroupTitle>Exercise bank</GroupTitle>
                  <GroupText>
                    Latest exercise solutions. Submit your solution to unlock
                    details!
                  </GroupText>
                  <GroupCards>
                    {loading ? (
                      <Loader />
                    ) : (
                      <>
                        {exerciseBankItems.map((item) => (
                          <PracticeCard
                            exerciseId={item.exerciseSlug}
                            title={item.exerciseName}
                            solutions={item.exerciseSubmissions}
                            submissionId={item.slug}
                            image={item.submissionImage}
                            link={item.submissionName}
                            author={item.submissionAuthor}
                            hours={
                              item.created &&
                              formatDistanceToNow(parseISO(item.created), {
                                addSuffix: true,
                              })
                            }
                            key={item.id}
                            allowAccessSubmission={
                              item.exerciseLocked || !!currentUser?.swdTeam
                            }
                          />
                        ))}
                      </>
                    )}
                  </GroupCards>
                  <ArrowLink
                    text="See exercise bank"
                    to={{
                      pathname: 'exercises',
                      state: {
                        scrollToElement: '#exercise-bank',
                      },
                    }}
                  />
                </GroupContent>
              </PracticeGroup>
            </PracticeRow>
          </MyPracticeSection>
        )}
      </Container>
      <HighlightedContainer>
        <FeedBackSection>
          {!loggedIn && (
            <FeedbackContainer>
              <LandingSectionDescription
                text="Don’t practice in a vacuum—getting feedback and iterating is key to honing your data storytelling skills. Submit a visual to get feedback from the community or the SWD team or assist others with their feedback requests."
                title="feedback"
                subtitle="get & give"
              />
              <BasicCard>
                <BasicCardInfo>
                  <BasicCardText>
                    <Title>Ask for feedback</Title>
                    Solicit input from others to learn what's working well and
                    where further iteration may be due to help refine your work
                    from good to great.
                  </BasicCardText>
                  <ProtectedButton to="/feedback/ask">
                    Get Feedback
                  </ProtectedButton>
                </BasicCardInfo>
              </BasicCard>
              <BasicCard>
                <BasicCardInfo>
                  <BasicCardText>
                    <Title>Help others</Title>
                    Giving feedback is a great method to solidify
                    learnings—browse feedback requests to determine where you
                    might help other users.
                  </BasicCardText>
                  <Button to="/feedback">Give Feedback</Button>
                </BasicCardInfo>
              </BasicCard>
            </FeedbackContainer>
          )}
          {loggedIn && (
            <MyFeedbackContainer>
              <LandingSectionDescription
                subtitle="get & give"
                title="feedback"
              />
              <HeadingRow>
                <Paragraph>
                  Get a fresh set of eyes on your graph or slide: submit a
                  request for feedback to understand what's working well and get
                  pointers on iterating to refine.
                </Paragraph>
                <AskFeedbackButton to="/feedback/ask">
                  Ask for feedback
                </AskFeedbackButton>
              </HeadingRow>
              {myOpenFeedback && myOpenFeedback.comments && (
                <MyFeedbackRequest>
                  <Graph>
                    <GraphTitle>What do you think of this graph?</GraphTitle>
                    <GraphImage>
                      <GraphImg
                        src={myOpenFeedback.image}
                        alt={myOpenFeedback.title}
                      />
                    </GraphImage>
                    <Statistics
                      comments={myOpenFeedback.commentsCount}
                      datapoints={myOpenFeedback.datapoints}
                      views={myOpenFeedback.views}
                    />
                  </Graph>
                  <FeedbackComments>
                    <GroupText>
                      Last comments on your feedback request
                    </GroupText>
                    {myOpenFeedback.comments.map((comment) => (
                      <FeedbackComment
                        src={comment.userAvatar}
                        author={comment.userName}
                        created={
                          comment.created &&
                          formatDistanceToNow(parseISO(comment.created), {
                            addSuffix: true,
                          })
                        }
                        comment={getTextFromHtml(comment.comment)}
                        key={comment.id}
                      />
                    ))}
                    <ArrowLink
                      text="See more"
                      visuallyHiddenText="about your feedback request"
                      to={`/feedback/${myOpenFeedback.id}`}
                    />
                  </FeedbackComments>
                </MyFeedbackRequest>
              )}
            </MyFeedbackContainer>
          )}
          <FeaturedFeedbackTitle>
            Featured feedback requests
          </FeaturedFeedbackTitle>
          <CardGrid>
            {feedbackRequests.map((feedbackRequest) => (
              <Link
                key={feedbackRequest.id}
                to={`/feedback/${feedbackRequest.id}`}
              >
                <Card
                  authorAvatar={feedbackRequest.user.avatar}
                  authorName={getUserName(feedbackRequest.user)}
                  authorType={getUserType(feedbackRequest.user)}
                  comments={feedbackRequest.commentsCount}
                  datapoints={feedbackRequest.votesCount}
                  lastCommentDate={
                    feedbackRequest.lastComment
                      ? feedbackRequest.lastComment
                      : undefined
                  }
                  statuses={getFeedbackRequestStatusList(feedbackRequest)}
                  tags={feedbackRequest.tags}
                  thumbnail={
                    feedbackRequest.imageList
                      ? imageToMedia(feedbackRequest.imageList.mainImage)
                      : feedbackRequest.imageList
                  }
                  title={feedbackRequest.title}
                  views={feedbackRequest.hits}
                />
              </Link>
            ))}
          </CardGrid>
          <ArrowLink
            text="See more"
            visuallyHiddenText="feedback"
            to="/feedback"
          />
        </FeedBackSection>
      </HighlightedContainer>
      <LastContainer>
        <TalkSection>
          <LandingSectionDescription text="" title="talk" subtitle="engage &" />
          <TalkSectionDescription>
            <TalkSectionParagraph>
              Is there a topic you want to chat about or a question where
              community input would be helpful? Start a discussion or browse
              topics and take part in the conversation.
            </TalkSectionParagraph>
            <DefaultProtectedButton
              to={{
                hash: 'start-conversation',
                pathname: '/conversations',
              }}
            >
              Start a conversation
            </DefaultProtectedButton>
          </TalkSectionDescription>
          <Conversations>
            {conversations.map(
              (
                {
                  created,
                  commentsCount,
                  hits,
                  id,
                  swdTeam,
                  tags,
                  title,
                  description,
                  votesCount,
                  user,
                  isAma,
                  isMonthly = false,
                  isSpotlight = false,
                  spotlightImage,
                  spotlightUsers,
                  spotlightDate,
                  amaParticipants,
                  amaStartDate,
                  amaEndDate,
                  closed,
                  lastComments,
                  amaAnswers,
                  amaQuestions,
                  live,
                },
                index,
              ) => (
                <Link key={id} to={`/conversations/${id}`}>
                  <Conversation
                    created={created}
                    commentsCount={isAma ? amaQuestions : commentsCount}
                    datapoints={votesCount}
                    statuses={getConversationStatuses(index)}
                    swdTeamChimedIn={swdTeam}
                    tags={tags}
                    title={title}
                    description={description}
                    views={hits}
                    authorAvatar={user.avatar!}
                    author={getUserName(user)}
                    authorType={getUserType(user)}
                    isAma={isAma}
                    isMonthly={isMonthly}
                    isSpotlight={isSpotlight}
                    spotlightImage={spotlightImage}
                    spotlightUsers={spotlightUsers}
                    spotlightDate={spotlightDate}
                    amaParticipants={amaParticipants}
                    amaStartDate={amaStartDate}
                    amaEndDate={amaEndDate}
                    closed={closed}
                    live={live}
                    amaAnswers={amaAnswers}
                    lastComments={lastComments}
                    url={`/conversations/${id}`}
                  />
                </Link>
              ),
            )}
          </Conversations>
          <ArrowLink
            text="See more"
            visuallyHiddenText="conversations"
            to="/conversations"
          />
        </TalkSection>
      </LastContainer>
      {!loggedIn && (
        <Content>
          <FeaturedLink text="Get inspired" title="discover" to={`/discover`}>
            <p>
              <span>Not sure what you’re looking for? </span>Use this tool to
              see what’s out there that you didn’t even know that you needed to
              find.
            </p>
          </FeaturedLink>
        </Content>
      )}
      {loggedIn && (
        <Content>
          <Banner text="Get inspired" title="discover" to={`/discover`}>
            <p>
              <span>Not sure what you’re looking for? </span>Use this tool to
              see what’s out there that you didn’t even know that you needed to
              find.
            </p>
          </Banner>
        </Content>
      )}
      {loggedIn && (
        <VisualSection>
          <Content>
            <HeadingSection>Outstanding visuals</HeadingSection>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Gallery>
                  {items.map(({ created, id, image, isVoted, user, title }) => (
                    <GalleryImage
                      avatar={user.avatar}
                      created={created}
                      userType={getUserType(user)}
                      id={id}
                      image={image}
                      imageAlt={title}
                      isVoted={isVoted}
                      key={id}
                      onAddVote={onAddVote}
                      onRemoveVote={onRemoveVote}
                      to={`discover/detail/${id}`}
                    />
                  ))}
                </Gallery>
              </>
            )}
            <ArrowLinkFit
              text="See more"
              visuallyHiddenText="visuals"
              to="/discover"
            />
          </Content>
        </VisualSection>
      )}
      {loggedIn && (
        <MemberSection>
          <Content>
            <HeadingSection>Member activity</HeadingSection>
            {loading ? (
              <Loader />
            ) : members.length > 0 ? (
              <>
                <MemberGrid>
                  {members.map((member) => (
                    <Link to={`/members/${member.id}`} key={member.id}>
                      <Member
                        avatar={member.avatar}
                        location={member.location}
                        name={getUserName({
                          firstName: member.firstName,
                          lastName: member.lastName,
                        })}
                        userType={getUserType(member)}
                      />
                    </Link>
                  ))}
                </MemberGrid>
              </>
            ) : (
              <NoResults />
            )}
            <ArrowLinkFit
              text="See more"
              visuallyHiddenText="members"
              to="/members"
            />
          </Content>
        </MemberSection>
      )}
    </div>
  );
};

export default connect(Home);
