import styled from 'styled-components';

export const Group = styled.g`
  stroke: currentColor;
`;

export const Clapper = styled.path`
  fill: currentColor;
`;

export const Bell = styled.path`
  fill: ${({ theme }) => theme.colors.white};
`;
