import { SetStateAction } from 'react';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { AnySection, SearchResponse } from './types';

export const resetSearchSectionPages = createAction(
  'search/RESET_SEARCH_SECTION_PAGES',
)<undefined>();

export const search = createAsyncAction(
  'search/SEARCH_REQUEST',
  'search/SEARCH_SUCCESS',
  'search/SEARCH_FAILURE',
)<
  {
    query?: string;
    singleSection?: AnySection;
  },
  SearchResponse,
  undefined
>();

export const setSearchSectionPage = createAction(
  'search/SET_SEARCH_SECTION_PAGE',
)<{ page: SetStateAction<number>; section: AnySection }>();
