import { serializeHTML } from 'components/Editor';
import { ConversationForm } from 'model';
import { Props } from './types';

export const handleFormSubmitted = ({
  createConversation,
}: {
  createConversation: Props['createConversation'];
}) => (values: ConversationForm) => {
  createConversation({
    conversationTopic: values.tags,
    description: serializeHTML(values.description),
    title: values.title,
  });
};
