import { replace } from 'connected-react-router';
import { Exercise, isRedirect, PaginatedResponse } from 'model';
import { parse } from 'query-string';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { combineLatest } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EXERCISES_PER_PAGE } from 'utils/config';
import { getExercise, getExercises } from './actions';

export const getExercisesEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getExercises.request)),
    mergeMap(({ payload: { search } }) => {
      const query = search ? parse(search) : {};

      return combineLatest([
        request<PaginatedResponse<Exercise>>({
          path: 'exercises',
          params: {
            page: 1,
            pageSize: 1,
            showActive: 'true',
          },
        }),
        request<PaginatedResponse<Exercise>>({
          path: 'exercises',
          params: {
            pageSize: EXERCISES_PER_PAGE,
            showActive: 'false',
            ...query,
          },
        }),
      ]).pipe(
        mergeMap(([activeResponse, pastResponse]) => {
          const active = activeResponse.results[0];

          return [
            getExercises.success({
              ...pastResponse,
              active,
            }),
          ];
        }),
        catchError(() => [getExercises.failure()]),
      );
    }),
  );

export const getExerciseEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(getExercise.request)),
    mergeMap(({ payload: { slug } }) =>
      request<Exercise>({
        path: `exercises/${slug}`,
      }).pipe(
        mergeMap((response) => {
          if (isRedirect(response)) {
            // We think we have a slug, but we actually have an id
            const id = slug;
            const location = state$.value.router.location;

            if (location.pathname === `/exercises/${id}`) {
              return [
                replace({
                  ...location,
                  pathname: `/exercises/${response.mappings[id]}`,
                }),
              ];
            }

            return [];
          }

          return [getExercise.success(response)];
        }),
        catchError(() => [getExercise.failure()]),
      ),
    ),
  );

export default combineEpics(getExercisesEpic, getExerciseEpic);
