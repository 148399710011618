import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React, { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';

const MuiThemeProvider: FC = ({ children }) => {
  const {
    colors: { accent },
  } = useTheme();

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          primary: {
            main: accent,
          },
        },
      }),
    [accent],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MuiThemeProvider;
