import { TimesIcon } from 'components/icons';
import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { from, prettyScrollbar } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  left: 0;
  min-height: 100vh;
  height: 100%;
  padding: 0 ${({ theme }) => theme.spacing.md};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${ZIndex.FULL_SCREEN_IMAGE_VIEWER};
`;

export const Header = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey300};
  display: flex;
  flex-direction: column-reverse;
  min-height: 4.75rem;
  margin: 0 auto;
  max-width: 73.13rem;
  padding: 1rem 0;

  > :first-child {
    display: none;
  }

  ${from.tabletH`
    align-items: center;
    flex-direction: row;
    height: 3.75rem;
    padding: 0.625rem 0;
  
    >:first-child {
      display: block;
    }
  `};
`;

export const Info = styled.div`
  border: none;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  height: 3.75rem;
  justify-content: center;
  margin-left: 0;
  max-width: 90%;
  padding: 0;

  ${from.tabletH`
    border-left: 1px solid ${({ theme }) => theme.colors.grey300};
    margin-left: 1.25rem;
    max-width: 25rem;
    padding: 0 1.25rem;
  `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 1.25rem;
`;

export const Subtitle = styled.p`
  margin: 0;
`;

export const Light = styled.span`
  opacity: 0.7;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const AuthorLink = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const RightSection = styled.div`
  align-items: flex-end;
  margin-left: auto;
  max-width: 100%;
  overflow: hidden;

  ${from.tabletH`
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem 0 1rem 1.5rem;
  `};
`;

export const Thumbnails = styled.div`
  ${prettyScrollbar({
    size: '0.25rem',
    vertical: false,
  })};

  align-self: flex-end;
  overflow-x: scroll;
  display: flex;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }

  ${from.tabletH`
    margin-right: ${({ theme }) => theme.spacing.md};
  `}
`;

export const Thumbnail = styled.img<{ active: boolean }>`
  border: solid 2px ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border-radius: 0.25rem;
  cursor: pointer;
  display: block;
  object-fit: cover;
  height: 4.5rem;
  width: 6.5rem;

  ${({ active, theme }) =>
    active &&
    css`
      border-color: ${theme.colors.accent};
    `};
`;

export const CloseButton = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  margin-left: auto;
  width: fit-content;

  ${from.tabletH`
    align-self: center;
    border-left: 1px solid ${({ theme }) => theme.colors.grey300};
    display: flex;
    height: 3.75rem;
    padding-left: 1.5rem;
  `};
`;

export const CloseIcon = styled(TimesIcon)`
  width: 1.4rem;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 73.13rem;
  padding: 1rem 0 0.625rem;
`;

export const Image = styled.img`
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  width: 100%;
  display: block;
`;

export const Video = styled(Image).attrs({ as: 'video', controls: true })``;
