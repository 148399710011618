import styled, { css } from 'styled-components';

export const Button = styled.button<{ $active: boolean }>`
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 28px;
  justify-content: center;
  padding: 0;
  width: 28px;

  ${({ $active }) =>
    $active &&
    css`
      background-color: #211c12;
    `};

  :not(:disabled) {
    cursor: pointer;
  }

  :disabled {
    color: #a2a2a6;
  }
`;
