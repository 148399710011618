import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Bell, Clapper, Group } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 16 18" {...props}>
    {children}
    <Group
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".8"
    >
      <Clapper d="M10 14.6666667h0c0 1.1045666-.89543333 2-2 2s-2-.8954334-2-2" />
      <Bell d="M13.6666667 12.6666667V8.33333333v.00003574c-.0025838-2.36649333-1.4755-4.48219333-3.69396666-5.30602l1e-7 6e-7c-.17752667-1.09021333-1.20522667-1.83008667-2.29544-1.65256-.84888.13822867-1.51433334.80368-1.65256 1.65256l2.6e-7-1e-7c-2.2183.82356-3.69052666 2.93974-3.69136666 5.30598667v4.33333336h0c0 1.1045666-.89543334 2-2 2H15.6666671c-1.1045668 0-2.0000001-.8954334-2.0000001-2l-3e-7-.0000029z" />
    </Group>
  </svg>
));
