import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${from.mobileXL`
    display: block;
    height: auto;
  `};
`;

export const Title = styled.h1`
  margin-bottom: 0;
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding: 2rem 0;
  flex: 1;
  ${from.mobileXL`
    width: 21.875rem;
    height: 21.875rem;
  `};
`;

export const ImagePreview = styled.img<{ $angle: number }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${({ $angle }) =>
    $angle &&
    css`
      transform: rotate(${$angle}deg);
      height: 100%;
      width: 100%;
    `}
`;

export const ActionZone = styled.div`
  display: flex;
  > :not(:last-child) {
    padding: 0.625rem;
  }
  > :not(:last-child) svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${({ theme }) => theme.colors.white};
  }
  > :nth-child(2) {
    margin-left: 1rem;
    transform: rotateY(180deg);
  }
  > :last-child {
    margin-left: auto;
  }
`;
