import { contractFeedbackRequest } from 'model';
import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { FEEDBACK_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import {
  createFeedbackRequest,
  deleteFeedbackRequest,
  editFeedbackRequest,
  getFeedbackRequest,
  getFeedbackRequests,
  _upsertFeedbackRequestsById,
} from './actions';
import { FeedbackAction, FeedbackState } from './types';

export const INITIAL_STATE: FeedbackState = {
  ...defaultStandardState,
};

const reducer: Reducer<FeedbackState, FeedbackAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    create: {
      request: isActionOf(createFeedbackRequest.request),
      success: isActionOf(createFeedbackRequest.success),
      failure: isActionOf(createFeedbackRequest.failure),
    },
    edit: {
      request: isActionOf(editFeedbackRequest.request),
      success: isActionOf(editFeedbackRequest.success),
      failure: isActionOf(editFeedbackRequest.failure),
    },
    getMany: {
      request: isActionOf(getFeedbackRequests.request),
      success: isActionOf(getFeedbackRequests.success),
      failure: isActionOf(getFeedbackRequests.failure),
    },
    getOne: {
      request: isActionOf(getFeedbackRequest.request),
      success: isActionOf(getFeedbackRequest.success),
      failure: isActionOf(getFeedbackRequest.failure),
    },
    remove: {
      request: isActionOf(deleteFeedbackRequest.request),
      success: isActionOf(deleteFeedbackRequest.success),
      failure: isActionOf(deleteFeedbackRequest.failure),
    },
    _upsertById: isActionOf(_upsertFeedbackRequestsById),
  },
  contractItem: contractFeedbackRequest,
  // tslint:disable-next-line:no-any
  initialState: INITIAL_STATE as any,
  perPage: FEEDBACK_PER_PAGE,
})(reducer);
