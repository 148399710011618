import React, { FC, memo } from 'react';
import { Button, Container, Link, Price, Subtitle, Tag, Title } from './styles';
import { Props } from './types';

const Plan: FC<Props> = ({
  disabled,
  id,
  index,
  isCorporate,
  period,
  price,
  savings,
  subtitle,
  ...rest
}) => {
  const even = index % 2 === 0;

  return (
    <Container {...rest} $disabled={disabled}>
      <Link
        disabled={disabled}
        variant={even ? 'premiumAccent' : 'premiumSecondary'}
        to={{
          pathname: isCorporate ? '/subscribe/corporate' : '/subscribe',
          state: {
            selectedPlan: id,
          },
        }}
      >
        <Title>{period}</Title>
        <Price>${price} US</Price>
        <Subtitle>{subtitle}</Subtitle>
        <Button>Go premium!</Button>
        {savings ? (
          <Tag>
            Save {`$${Math.round(savings)} ${isCorporate ? `per seat` : ''}`}
          </Tag>
        ) : null}
      </Link>
    </Container>
  );
};

export default memo(Plan);
