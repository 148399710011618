import DefaultRadio from 'components/Radio';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ selected: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.25rem;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-width: 13rem;
  padding: 0.75rem;

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.colors.primary};
    `};
`;

export const Radio = styled(DefaultRadio)`
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
`;

export const Label = styled.span<{ selected: boolean }>`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 1.25rem;

  ${({ selected, theme }) =>
    selected &&
    css`
      color: ${theme.colors.white};
    `};
`;

export const Tag = styled.span`
  padding-inline: 4px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.basicGold};
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-left: 8px;
`;
