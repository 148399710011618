import ProtectedRoute from 'containers/Common/ProtectedRoute';
import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useConnect } from './connect';
import MembersRoot from './MembersRoot';
import Profile from './Profile';
import { MembersSection, Props } from './types';

const SECTIONS: MembersSection[] = ['activity', 'team', 'scholars', 'all'];

const Members: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Members</BreadcrumbsItem>
      <Switch>
        {SECTIONS.map((section) => [
          <Route
            component={MembersRoot}
            key={`MembersRoot_${section}_period`}
            path={`${path}/${section}/:period`}
          />,
          <Route
            component={MembersRoot}
            key={`MembersRoot_${section}`}
            path={`${path}/${section}`}
          />,
        ])}
        <ProtectedRoute key="my-profile" path={`${path}/me`}>
          <Profile />
        </ProtectedRoute>
        <Route key="member-profile" path={`${path}/:userSlug`}>
          <Profile />
        </Route>
        <Redirect key="redirect" to={`${url}/activity`} />
      </Switch>
    </>
  );
};

export default Members;
