import { AutoScrollIntoView, HideComments } from 'components';
import Actions from 'containers/Common/PostActions';
import { getUserName } from 'model';
import React, { FC, useCallback } from 'react';
import { COMMENTS_MAX_DEPTH } from 'utils/config';
import { formatDateAndTime } from 'utils/dates';
import { getUserType } from 'utils/getUserType';
import CommentForm from '../../CommentForm';
import { handleFormSubmitted } from './logic';
import {
  Avatar,
  Body,
  CommentData,
  CommentImage,
  CommentVideo,
  Container,
  Content,
  ContentColumn,
  ContentRow,
  DotSeparator,
  Info,
  Name,
  Replies,
  SidebarColumn,
  UserInfo,
} from './styles';
import { Props } from './types';

const Comment: FC<Props> = ({
  authorAvatar,
  authorId,
  authorName,
  authorType,
  authorSlug,
  children,
  content,
  contentType,
  datapoints,
  date,
  depth = 0,
  disabled,
  id,
  image,
  isReported,
  isVoted,
  objectId,
  onCreateComment,
  onToggleReplyFormOpen,
  parentId,
  replies,
  replyFormsOpen,
  url,
  spotlightAuthors,
  ...rest
}) => {
  const parentReplyFormOpen = !!parentId && !!replyFormsOpen[parentId];
  const replyFormOpen = !!replyFormsOpen[id];

  const onFormSubmitted = useCallback(
    handleFormSubmitted({ contentType, onCreateComment, id, objectId }),
    [contentType, onCreateComment, id, objectId],
  );

  const toggleReplyFormOpen = useCallback(
    () =>
      onToggleReplyFormOpen(
        parentId && depth === COMMENTS_MAX_DEPTH - 1 ? parentId : id,
      ),
    [depth, id, onToggleReplyFormOpen, parentId],
  );

  return (
    <Container {...rest}>
      <Body>
        <SidebarColumn>
          <Avatar
            to={`/members/${authorSlug}`}
            variant={authorType}
            src={authorAvatar}
          />
        </SidebarColumn>
        <ContentColumn>
          <CommentData
            isAuthorSpotligthAuthor={
              spotlightAuthors?.includes(authorId) ?? false
            }
          >
            <UserInfo>
              <Name to={`/members/${authorSlug}`}>{authorName}</Name>
              <DotSeparator>·</DotSeparator>
              <Info>{formatDateAndTime(date)}</Info>
            </UserInfo>
            <ContentRow>
              {image && !image.includes('video/') && (
                <CommentImage
                  images={[image]}
                  zoomUrl={`${url}/${id}/comment-image`}
                />
              )}
              {image && image.includes('video/') && (
                <CommentVideo src={image} controls={true} />
              )}
              <Content content={content} />
            </ContentRow>
            <Actions
              authorId={authorId}
              contentType={contentType}
              id={id}
              isComment={true}
              isReported={isReported}
              isVoted={isVoted}
              replyOnClick={
                !disabled && !(replyFormOpen || parentReplyFormOpen)
                  ? toggleReplyFormOpen
                  : undefined
              }
              url={url}
              votes={datapoints}
            />
          </CommentData>
        </ContentColumn>
        {(replies.length > 0 || replyFormOpen) && (
          <Replies>
            <HideComments>
              {replies.map((comment) => (
                <Comment
                  authorAvatar={comment.user.avatar}
                  authorId={comment.user.id}
                  authorName={getUserName(comment.user)}
                  authorType={getUserType(comment.user)}
                  authorSlug={comment.user.slug}
                  content={comment.comment}
                  contentType={contentType}
                  datapoints={comment.votesCount}
                  date={comment.created}
                  depth={depth + 1}
                  disabled={disabled}
                  id={comment.id}
                  image={comment.image}
                  isReported={comment.isReported}
                  isVoted={comment.isVoted}
                  key={comment.id}
                  objectId={objectId}
                  onCreateComment={onCreateComment}
                  onToggleReplyFormOpen={onToggleReplyFormOpen}
                  parentId={id}
                  replies={comment.children}
                  replyFormsOpen={replyFormsOpen}
                  url={url}
                  spotlightAuthors={spotlightAuthors}
                />
              ))}
              {replyFormOpen && (
                <AutoScrollIntoView>
                  <CommentForm
                    id={id}
                    isCommentReply={true}
                    key="new-reply"
                    onClose={toggleReplyFormOpen}
                    onSubmit={onFormSubmitted}
                  />
                </AutoScrollIntoView>
              )}
            </HideComments>
          </Replies>
        )}
      </Body>
    </Container>
  );
};

export default Comment;
