import { serializeHTML } from 'components/Editor';
import { convertRemoteMedia } from 'components/MediaInput';
import { ChallengeSubmissionForm, UPLOAD_MEDIA_FIELD } from 'model';
import React from 'react';
import { SubmissionError } from 'redux-form';
import { deleteChallengeResponse } from 'redux/modules/challengeResponse';
import { Props } from './types';

export const handleDelete = ({
  challengeId,
  openConfirmationModal,
  submissionId: responseId,
}: {
  challengeId?: string;
  openConfirmationModal: Props['openConfirmationModal'];
  submissionId?: string;
}) => () => {
  if (challengeId && responseId) {
    openConfirmationModal({
      acceptButtonLabel: 'Delete',
      acceptButtonVariant: 'danger',
      cancelButtonLabel: 'Cancel',
      title: <span>Are you sure?</span>,
      description: (
        <span>
          <strong>Deleting this submission can’t be undone</strong>. This entry
          and its associated images, comments, datapoints... will be deleted in
          all the sections of this website.
        </span>
      ),
      onAccept: [deleteChallengeResponse.request({ challengeId, responseId })],
    });
  }
};

export const handleSubmit = ({
  editChallengeResponse,
  challengeId,
  submissionId: responseId,
}: {
  editChallengeResponse: Props['editChallengeResponse'];
  challengeId?: string;
  submissionId?: string;
}) => (values: ChallengeSubmissionForm) => {
  if (challengeId && responseId) {
    const [mainMedia, ...extraMedia] = values[UPLOAD_MEDIA_FIELD].filter(
      convertRemoteMedia,
    );

    if (!mainMedia && values.upload_images.length === 0) {
      throw new SubmissionError({
        [UPLOAD_MEDIA_FIELD]: 'Please add at least one image!',
      });
    }
    let addToGalleryOnlyImage = values.addToMyGallery;
    if (mainMedia.mediaType === 'VIDEO') {
      addToGalleryOnlyImage = false;
    }

    editChallengeResponse({
      challengeId,
      responseId,
      description: serializeHTML(values.description),
      addToMyGallery: addToGalleryOnlyImage,
      graphIndustry: values.industry,
      graphTools: values.tools,
      graphTopic: values.topic,
      graphTypes: values.graphType,
      mediaList: {
        mainMedia,
        extraMedia,
      },
      title: values.title,
    });
  }
};
