import { FilterIcon } from 'components/icons';
import styled from 'styled-components';
import { buttonReset } from 'styles/mixins';

export const Container = styled.button`
  ${buttonReset};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  flex-shrink: 0;
  height: 100%;
  padding: 0 0.75rem;

  :hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }

  > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  line-height: 1rem;
  text-transform: uppercase;
`;

export const CounterRectangle = styled.div`
  display: inline-flex;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
  height: 28px;
  width: 28px;
  align-items: center;
  justify-content: center;
`;

export const Counter = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  line-height: 1rem;
`;

export const Icon = styled(FilterIcon)`
  vertical-align: middle;
`;
