import { useMediaQuery } from '@material-ui/core';
import { AddMembersCorporatePremiumForm } from 'model/forms/AddMembersCorporatePremium';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import Form from './Form';
import { Container, Content, Title } from './styles';
import { Props } from './types';

const AddMemberModal: FC<Props> = ({
  addMembers,
  companyName,
  currentSeats,
  history: { push },
  numberSeats,
  match,
}) => {
  const url = match?.url;
  const isFromTablet = useMediaQuery(from.mobileXL.query);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  const onSubmit = useCallback(
    (value: AddMembersCorporatePremiumForm) => {
      const emails = value.emails;
      const emailsList = emails.split(',').map((email: string) => email.trim());
      addMembers({ emails: emailsList });
    },
    [addMembers],
  );

  return (
    <Container
      animation="slideUp"
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!url}
      scroll="body"
    >
      <Content>
        <Title>Add Member to {companyName}</Title>
        <Form
          onClose={onClose}
          numberSeats={numberSeats}
          onSubmit={onSubmit}
          currentSeats={currentSeats}
        />
      </Content>
    </Container>
  );
};

export default connect(AddMemberModal);
