import styled from 'styled-components';
import { Container as ParentContainer } from '../Tag/styles';

export const LiveIndicator = styled.div`
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid white;
  box-shadow: 0 0 0 1px black;
  width: 4px;
  height: 4px;
  margin-right: 6px;
`;

export const Container = styled(ParentContainer)`
  background-color: ${({ theme }) => theme.colors.error};
  color: white;
  text-transform: none;
  align-items: baseline;
  font-variant-numeric: tabular-nums;
`;
