import { Entity, PaginatedResponse, UpsertByIdPayload } from 'model';
import { Action, Reducer } from 'redux';
import { ActionAssertor } from 'utils/ActionAssertor';
import { composeReducers } from 'utils/composeReducers';
import { createByIdReducer, defaultByIdState } from 'utils/createByIdReducer';
import {
  createLoadingReducer,
  defaultLoadingState,
} from 'utils/createLoadingReducer';
import { StandardState } from './types';

export const createStandardReducer = <
  EntityT extends Entity,
  ExpandedEntityT extends Entity,
  StateT extends StandardState<EntityT>,
  ActionT extends Action
>({
  actions: { create, edit, getMany, getOne, remove, _upsertById },
  contractItem,
  initialState,
  perPage,
}: {
  // tslint:disable:no-any
  actions: {
    create?: {
      request: ActionAssertor<any>;
      success: ActionAssertor<ExpandedEntityT>;
      failure: ActionAssertor<any>;
    };
    edit?: {
      request: ActionAssertor<any>;
      success: ActionAssertor<ExpandedEntityT>;
      failure: ActionAssertor<any>;
    };
    getMany?: {
      request: ActionAssertor<any>;
      success: ActionAssertor<PaginatedResponse<EntityT>>;
      failure: ActionAssertor<any>;
    };
    getOne?: {
      request: ActionAssertor<any>;
      success: ActionAssertor<ExpandedEntityT>;
      failure: ActionAssertor<any>;
    };
    remove?: {
      request: ActionAssertor<any>;
      success: ActionAssertor<{ id: string }>;
      failure: ActionAssertor<any>;
    };
    _upsertById?: ActionAssertor<UpsertByIdPayload<EntityT>>;
  };
  contractItem: (item: ExpandedEntityT) => EntityT;
  initialState: StateT;
  perPage: number;
  // tslint:enable:no-any
}) => (baseReducer: Reducer<StateT, ActionT>): Reducer<StateT, ActionT> =>
  composeReducers(
    createByIdReducer({
      contractItem,
      initialState,
      perPage,
      isCreateSuccess: create?.success,
      isDeleteSuccess: remove?.success,
      isEditSuccess: edit?.success,
      isGetManySuccess: getMany?.success,
      isGetOneSuccess: getOne?.success,
      isUpsertById: _upsertById,
    }),
    ...(create
      ? [
          createLoadingReducer(
            create.request,
            create.success,
            create.failure,
            initialState,
          ),
        ]
      : []),
    ...(remove
      ? [
          createLoadingReducer(
            remove.request,
            remove.success,
            remove.failure,
            initialState,
          ),
        ]
      : []),
    ...(edit
      ? [
          createLoadingReducer(
            edit.request,
            edit.success,
            edit.failure,
            initialState,
          ),
        ]
      : []),
    ...(getMany
      ? [
          createLoadingReducer(
            getMany.request,
            getMany.success,
            getMany.failure,
            initialState,
          ),
        ]
      : []),
    ...(getOne
      ? [
          createLoadingReducer(
            getOne.request,
            getOne.success,
            getOne.failure,
            initialState,
          ),
        ]
      : []),
    baseReducer,
  );

export const defaultStandardState = {
  ...defaultByIdState,
  ...defaultLoadingState,
};

export * from './types';
