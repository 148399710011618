import { ImageIcon as DefaultImageIcon } from 'components/icons';
import DefaultLink from 'components/Link';
import styled from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const PracticeCardContainer = styled.article`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: -13px 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  margin-bottom: 0.313rem;
  padding: 0.625rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Row = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyBlue};
  display: flex;
  align-items: baseline;
  flex-basis: 100%;
  margin: -0.25rem 0 0.625rem;
  padding-bottom: 0.375rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  max-width: 100%;

  ${from.mobileXL`
    align-items: center;
  `};
`;

export const Title = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  margin-right: ${({ theme }) => theme.spacing.md};
`;

export const Tag = styled.div`
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-right: ${({ theme }) => theme.spacing.md};
  padding: 0.25rem 0.438rem 0.063rem;
  text-transform: uppercase;
  display: none;
`;

export const Solutions = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 0.75rem;
  margin-left: auto;
  padding-top: ${({ theme }) => theme.spacing.sm};
  text-transform: uppercase;
  flex-shrink: 0;

  span {
    color: ${({ theme }) => theme.colors.grey400};
    letter-spacing: 0.036rem;
  }
`;

export const Image = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.25rem;
  overflow: hidden;
  height: 3rem;
  width: 4.813rem;
  position: relative;
`;

export const LockOverlay = styled.div`
  background-color: ${({ theme }) => addAlpha(theme.colors.grey500, 0.8)};
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const LockIcon = styled.img`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.169rem;
  height: 1.063rem;
  width: 1.063rem;
  padding: 0.25rem;
  position: absolute;
  bottom: 0.25rem;
  right: 0.25rem;
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  margin-top: ${({ theme }) => theme.spacing.xsm};

  ${from.tablet`
    margin-left: 0.75rem;
  `};
`;

export const Link = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: ${({ theme }) => theme.spacing.xsm} 0;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 17.813rem;

  :hover {
    color: ${({ theme }) => theme.colors.primaryHover};
  }
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.grey400};
  margin: 0;
`;

export const Author = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin: 0 ${({ theme }) => theme.spacing.xsm};
`;

export const ImageIcon = styled(DefaultImageIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 100%;
  height: 100%;
`;
