import React, { FC, memo, useState } from 'react';
import { Container, Content, Toggle } from './styles';
import { Props } from './types';

const ExpandableTruncatedText: FC<Props> = ({ text, limit = 210, ...rest }) => {
  const [expanded, setIsExpanded] = useState(false);
  const toggle = () => setIsExpanded((prev) => !prev);

  if (text.length <= limit) {
    return (
      <Container {...rest}>
        <Content animate="expanded">{text}</Content>
      </Container>
    );
  }

  return (
    <Container {...rest}>
      <Content animate={expanded ? 'expanded' : 'collapsed'}>{text}</Content>
      <Toggle onClick={toggle} variant="link">
        {expanded ? 'Show Less' : 'Show More'}
      </Toggle>
    </Container>
  );
};

export default memo(ExpandableTruncatedText);
