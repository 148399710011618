import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { openPremiumInterruption } from 'redux/modules/modal';
import { getOfficeHours, selectUpcomingHours } from 'redux/modules/officeHour';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  hours: selectUpcomingHours(state),
  isPremium: selectUserIsPremium(state),
});

const mapDispatchToProps = {
  openPremiumInterruption,
  getOfficeHours: getOfficeHours.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
