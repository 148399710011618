import SWDLogo from 'components/SWDLogo';
import format from 'date-fns/format';
import React, { FC, memo } from 'react';
import {
  Contact,
  ContactButton,
  ContactText,
  Container,
  FacebookIcon,
  InstagramIcon,
  LegalFooter,
  LegalFooterWrapper,
  LegalText,
  LinkedInIcon,
  LinkList,
  ListHeader,
  NavigationFooter,
  NavigationFooterWrapper,
  NavigationLink,
  NavigationOuterLink,
  RSSIcon,
  SocialLink,
  SocialLinks,
  TwitterIcon,
} from './styles';
import { Props } from './types';

const Footer: FC<Props> = ({ ...rest }) => (
  <Container {...rest}>
    <NavigationFooterWrapper>
      <NavigationFooter>
        <LinkList>
          <ListHeader>Read</ListHeader>
          <NavigationOuterLink href="http://www.storytellingwithdata.com/books">
            books
          </NavigationOuterLink>
          <NavigationOuterLink href="http://www.storytellingwithdata.com/">
            blog
          </NavigationOuterLink>
        </LinkList>
        <LinkList>
          <ListHeader>Listen</ListHeader>
          <NavigationOuterLink href="http://www.storytellingwithdata.com/podcast">
            podcast
          </NavigationOuterLink>
          <NavigationOuterLink href="http://www.storytellingwithdata.com/videos">
            videos
          </NavigationOuterLink>
        </LinkList>
        <LinkList>
          <ListHeader>Attend</ListHeader>
          <NavigationOuterLink href="http://www.storytellingwithdata.com/public-workshops">
            public workshops
          </NavigationOuterLink>
          <NavigationOuterLink href="http://www.storytellingwithdata.com/custom-workshops">
            custom workshops
          </NavigationOuterLink>
        </LinkList>
        <LinkList>
          <ListHeader>Participate</ListHeader>
          <NavigationLink to="/">community</NavigationLink>

          <NavigationLink to="/premium">go premium!</NavigationLink>
        </LinkList>
        <Contact>
          <SWDLogo />
          <ContactText>
            Helping rid the world of ineffective graphs, one 3D pie at a time!
          </ContactText>
          <ContactButton to="http://www.storytellingwithdata.com/contact">
            Contact us
          </ContactButton>
        </Contact>
      </NavigationFooter>
    </NavigationFooterWrapper>
    <LegalFooterWrapper>
      <LegalFooter>
        <LegalText>
          <span>&copy; {format(new Date(), 'yyyy')} All rights reserved.</span>
        </LegalText>
        <SocialLinks>
          <SocialLink
            to="https://www.twitter.com/storywithdata/"
            aria-label="Twitter"
          >
            <TwitterIcon />
          </SocialLink>
          <SocialLink
            to="https://www.facebook.com/storywithdata/"
            aria-label="Facebook"
          >
            <FacebookIcon />
          </SocialLink>
          <SocialLink
            to="https://www.linkedin.com/company/storytelling-with-data-llc/"
            aria-label="LinkedIn"
          >
            <LinkedInIcon />
          </SocialLink>
          <SocialLink
            to="https://www.instagram.com/storywithdata/"
            aria-label="Instagram"
          >
            <InstagramIcon />
          </SocialLink>
          <SocialLink
            to="http://www.storytellingwithdata.com/"
            aria-label="RSS"
          >
            <RSSIcon />
          </SocialLink>
        </SocialLinks>
      </LegalFooter>
    </LegalFooterWrapper>
  </Container>
);

export default memo(Footer);
