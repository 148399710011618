import { stringify } from 'query-string';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectVideoTypesTags } from 'redux/modules/configuration';
import { Props } from './types';

export const useConnect = ({ category }: { category: Props['category'] }) => {
  const { url } = useRouteMatch();

  const videoTypes = useSelector(selectVideoTypesTags);

  const items = useMemo(() => {
    return [
      {
        active: category === 'all',
        id: 'all',
        label: 'all',
        to: {
          pathname: url,
        },
      },
      ...videoTypes.map((type) => ({
        active: category === type.url,
        id: type.id,
        label: type.label,
        to: {
          pathname: url,
          search: stringify({
            category: type.url,
          }),
        },
      })),
    ];
  }, [category, videoTypes, url]);

  return { items };
};
