import { useCallback, useMemo, useRef } from 'react';

import defaultAvatar from 'assets/images/defaultAvatar.png';
import type {
  ReferencedEntity,
  ReferenceProvider,
} from 'components/Editor/references/types';
import { PaginatedResponse, User } from 'model';
import { request } from 'redux/modules/epicDependencies';
import { USERS_PER_PAGE } from 'utils/config';

export const useReferenceProvider = (): ReferenceProvider => {
  const userStateRef = useRef<ReferencedEntity[]>([]);
  const fetchDataUser = useCallback(
    async (search: string | undefined) => {
      return await request<PaginatedResponse<User>>({
        path: 'users',
        params: {
          query: search,
          pageSize: USERS_PER_PAGE,
        },
      })
        .toPromise()
        .then((res) => {
          const users = res.results.map((user) => {
            const userName = `${user?.firstName} ${user?.lastName}`.toLowerCase();
            return {
              id: user.id,
              image: user.avatar ?? defaultAvatar,
              type: 'user',
              label: userName,
            };
          });
          userStateRef.current = users;
          return users;
        });
    },
    [userStateRef],
  );
  // The fetchDataUser function on getOptions should be debounced.
  // const debouncedFetchDataUser = useMemo(() => debounce(fetchDataUser, 300), [fetchDataUser]);

  const getOptions: ReferenceProvider['getOptions'] = useCallback(
    async (query) => {
      return fetchDataUser(query) ?? [];
    },
    [fetchDataUser],
  );

  const getReferencedEntity: ReferenceProvider['getReferencedEntity'] = useCallback(
    (entityId, entityType) => {
      return userStateRef.current.find(
        ({ id, type }) => id === entityId && type === entityType,
      );
    },
    [userStateRef],
  );

  return useMemo(
    () => ({
      getOptions,
      getReferencedEntity,
    }),
    [getOptions, getReferencedEntity],
  );
};
