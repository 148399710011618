import { DiscoverSearchOptionsFieldNames, Tag } from 'model';
import React, { FC, useCallback } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import {
  Container,
  GraphTypesSelect,
  Order,
  OrderBooleanInput,
  OrderDirection,
  OrderSelect,
  VisuallyHiddenLabel,
} from './styles';
import { Props } from './types';

const ORDERING_OPTIONS = [
  {
    label: 'Random',
    value: 'random',
  },
  {
    label: 'Date',
    value: 'created',
  },
  {
    label: 'Datapoints',
    value: 'datapoints',
  },
  {
    label: 'Comments',
    value: 'comments',
  },
  {
    label: 'Views',
    value: 'views',
  },
];

const tagToOption = ({ id, name }: { id: Tag['id']; name: Tag['name'] }) => ({
  label: name,
  value: id,
});

const Form: FC<Props> = ({ handleSubmit, order, typeTags, className }) => {
  const formatDirection = useCallback(
    (value: boolean) => (order === 'random' ? false : value),
    [order],
  );

  return (
    <Container className={className} onSubmit={handleSubmit}>
      <VisuallyHiddenLabel htmlFor={DiscoverSearchOptionsFieldNames.graphTypes}>
        Type of visual
      </VisuallyHiddenLabel>
      <Field
        component={GraphTypesSelect}
        isClearable={true}
        name={DiscoverSearchOptionsFieldNames.graphTypes}
        options={typeTags.map(tagToOption)}
        placeholder="Type of visual: all"
      />
      <Order>
        <VisuallyHiddenLabel htmlFor={DiscoverSearchOptionsFieldNames.order}>
          Sort by
        </VisuallyHiddenLabel>
        <Field
          component={OrderSelect}
          name={DiscoverSearchOptionsFieldNames.order}
          options={ORDERING_OPTIONS}
        />
        <Field
          CheckboxComponent={OrderDirection}
          component={OrderBooleanInput}
          disabled={order === 'random'}
          name={DiscoverSearchOptionsFieldNames.directionIsAsc}
          format={formatDirection}
        />
      </Order>
    </Container>
  );
};

export default connect(Form);
