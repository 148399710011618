import React, { FC, memo } from 'react';
import { Helmet } from 'react-helmet';
import { Props } from './types';

const PageTitle: FC<Props> = ({ title }) => {
  const defaultTitle = 'storytelling with data';
  return (
    <Helmet>
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
    </Helmet>
  );
};

export default memo(PageTitle);
