import DefaultTags from 'components/Tags';
import styled from 'styled-components';
import LinesWrapper from '../LinesWrapper';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.75rem ${({ theme }) => theme.spacing.lg}
    ${({ theme }) => theme.spacing.lg};
`;

export const Tags = styled(DefaultTags)`
  display: flex;
  flex-wrap: wrap;
  min-height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const TITLE_LINE_HEIGHT = '22px';
const TITLE_MAX_LINES = 2;

export const Title = styled(LinesWrapper).attrs({
  maxLines: TITLE_MAX_LINES,
  forwardedAs: 'h3',
})`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: ${TITLE_LINE_HEIGHT};
  margin: 0;
  position: relative;
`;
