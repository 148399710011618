import React, { FC, memo } from 'react';
import DropFiles from './DropFiles';
import InvalidFiles from './InvalidFiles';
import { Props } from './types';

const DragActive: FC<Props> = ({ isDragAccept }) => {
  return isDragAccept ? <DropFiles /> : <InvalidFiles />;
};

export default memo(DragActive);
