import DefaultAvatar from 'components/Avatar';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  margin-bottom: 0.75rem;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const Avatar = styled(DefaultAvatar)`
  box-sizing: border-box;
  margin-right: 0.25rem;
  height: 2.5rem;
  width: 2.5rem;
`;

export const InfoSection = styled.div`
  margin-left: ${({ theme }) => theme.spacing.sm};
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AuthorName = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  letter-spacing: 0.76px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 24px;
`;

export const AdditionalInfo = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  letter-spacing: 0;
  font-weight: ${({ theme }) => theme.weights.regular};
  opacity: 0.6;
  text-align: right;

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;
