import { useMediaQuery } from '@material-ui/core';
import React, { FC } from 'react';
import { from } from 'styles/mixins';
import connect from './connect';
import {
  Actions,
  ArrowRight,
  Content,
  Description,
  Feature,
  Features,
  LearnMoreButton,
  Modal,
  PremiumButton,
  ScrollWrapper,
  Title,
} from './styles';
import { Props } from './types';

const PremiumInterruptionModal: FC<Props> = ({
  closeModal,
  lowestPrice,
  open,
}) => {
  const webpSupported = !!Modernizr.webp;
  const isFromMobile = useMediaQuery(from.mobile.query);

  return (
    <Modal open={open} onClose={closeModal} fullScreen={!isFromMobile}>
      <ScrollWrapper scroll={true}>
        <Content webpSupported={webpSupported}>
          <Title>
            This content is for premium members—upgrade now to keep learning
            with SWD!
          </Title>
          <Description>
            For as low as ${lowestPrice} US per month, you can gain access to:
          </Description>
          <Features>
            <Feature>Data storyteller office hours</Feature>
            <Feature>Live events</Feature>
            <Feature>On-demand videos</Feature>
          </Features>
          <Actions>
            <PremiumButton onClick={closeModal} to="/subscribe">
              Go premium!
            </PremiumButton>
            <LearnMoreButton onClick={closeModal} to="/premium">
              Learn more
              <ArrowRight />
            </LearnMoreButton>
          </Actions>
        </Content>
      </ScrollWrapper>
    </Modal>
  );
};

export default connect(PremiumInterruptionModal);
