import { Modal as DefaultModal } from 'components';
import {
  CloseButtonContainer as ModalClose,
  Content as ModalContent,
} from 'components/Modal/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Modal = styled(DefaultModal)`
  color: ${({ theme }) => theme.colors.black};
  margin: 0 auto;

  .MuiDialog-paper {
    border: none;
    max-width: initial;
    width: initial;
  }

  ${ModalClose} {
    ${from.tablet`
      color: ${({ theme }) => theme.colors.white};
    `};
  }

  ${ModalContent} {
    ${from.tablet`
      display: grid;
      grid-template-columns: 37.5rem 20rem;
      overflow: hidden;
    `};
  }
`;

export const Column = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  display: none;
  height: 100%;
  overflow: hidden;
  position: relative;

  ${from.tablet`
    display: initial;
  `};
`;

export const CTATopImage = styled.img`
  top: -0.5rem;
  left: -0.5rem;
  position: absolute;
  transform: rotateY(-180deg);

  ${from.mobileXL`
    height: 18rem;
  `};
`;

export const CTABottomImage = styled.img`
  position: absolute;
  bottom: -4rem;
  left: -4rem;

  ${from.mobileXL`
    height: 20rem;
  `};
`;

export const Content = styled.div`
  padding: 2.5rem 2rem 2rem;

  ${from.mobileXL`
    padding: 3.75rem;
  `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-top: 0;
`;

export const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
`;
