import React, { FC, memo } from 'react';
import { Checkbox, Container } from './styles';
import { Props } from './types';

const Option: FC<Props> = ({
  children,
  className,
  innerProps,
  innerRef,
  isSelected,
  selectProps: { isMulti },
}) => (
  <Container
    className={className}
    isSelected={isSelected}
    ref={innerRef}
    {...innerProps}
  >
    {isMulti && <Checkbox checked={isSelected} readOnly={true} />}
    <span>{children}</span>
  </Container>
);

export default memo(Option);
