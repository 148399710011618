import { FormNameMap } from 'utils/forms';

export const REQUEST_RESET_PASSWORD_FORM_KEY = 'forms/REQUEST_RESET_PASSWORD';

export interface RequestResetPasswordForm {
  email: string;
}

export const RequestResetPasswordFieldNames: FormNameMap<RequestResetPasswordForm> = {
  email: 'email',
};
