import Tag from 'components/Tag';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Container } from './styles';
import { Props } from './types';

const Tags: FC<Props> = ({ items, oneLine = false, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [firstHiddenIndex, setFirstHiddenIndex] = useState(Infinity);
  const anyHidden = firstHiddenIndex < items.length;
  const totalHidden = items.length - firstHiddenIndex;
  const moreTagsTitle = items.slice(firstHiddenIndex, items.length).join(', ');
  const moreTagsLabel = `+ ${totalHidden}`;

  useEffect(() => {
    if (ref.current && oneLine) {
      let remainingWidth = ref.current.clientWidth - 36;

      setFirstHiddenIndex(
        Array.from(ref.current.childNodes).reduce((acc, node, i) => {
          // If the acc stopped incrementing, it means that we already ran out
          // of space.
          if (acc !== i) {
            return acc;
          }

          if (!(node instanceof HTMLSpanElement)) {
            return acc;
          }

          const { marginRight } = getComputedStyle(node);

          const willConsume =
            node.clientWidth + (parseInt(marginRight || '', 10) || 0);

          if (remainingWidth > willConsume) {
            remainingWidth -= willConsume;
            return acc + 1;
          }

          return acc;
        }, 0),
      );
    }
  }, [oneLine, ref]);

  return (
    <Container ref={ref} {...rest}>
      {items.map(
        (tag, i) => i < firstHiddenIndex && <Tag key={tag} name={tag} />,
      )}
      {anyHidden && (
        <Tag title={moreTagsTitle} key="more-tags" name={moreTagsLabel} />
      )}
    </Container>
  );
};

export default Tags;
