import { FormErrorMap, FormValidator } from 'utils/forms';
import { ChangeEmailForm } from '.';

export const validateChangeEmailForm: FormValidator<ChangeEmailForm> = ({
  email,
  emailConfirm,
}) => {
  const errors: FormErrorMap<ChangeEmailForm> = {};

  if (email && emailConfirm && email !== emailConfirm) {
    errors.emailConfirm = 'Emails do not match';
  }

  return errors;
};
