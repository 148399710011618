import { LocationDescriptorObject } from 'history';
import { parse, ParsedQuery, stringify } from 'query-string';
import { RootAction } from 'redux/modules/types';
import { DeepReadonly } from 'utility-types';
import { uint32ArrayFromString, uint32ArrayToString } from '../typedArray';

export const parseReturnTo = (
  encoded: string,
  currentSearch: ParsedQuery = {},
): LocationDescriptorObject => {
  const match = encoded.match(/LD__(.+)/);

  if (match) {
    const decoded = deserializeLocationDescriptor(match[1]);

    return {
      ...decoded,
      search: stringify({
        ...currentSearch,
        ...parse(decoded.search ?? ''),
      }),
    };
  }

  return { pathname: encoded, search: stringify(currentSearch) };
};

export const serializeLocationDescriptor = (
  descriptor: LocationDescriptorObject,
) => btoa(JSON.stringify(descriptor));

export const deserializeLocationDescriptor = (
  descriptor: string,
): LocationDescriptorObject => JSON.parse(atob(descriptor));

export const serializeIntent = (actions: RootAction[]) =>
  btoa(
    JSON.stringify(
      actions.map((action) => {
        if ('payload' in action) {
          return {
            p: action.payload,
            t: action.type,
          };
        }

        return {
          t: action.type,
        };
      }),
    ),
  );

export const deserializeIntent = (intent: string): RootAction[] =>
  // tslint:disable-next-line:no-any
  (JSON.parse(atob(intent)) as any[]).map(({ p, t }) => ({
    payload: p,
    type: t,
  }));

const keyParams = { name: 'HMAC', hash: 'SHA-512' } as const;
const keyUsages: KeyUsage[] = ['sign', 'verify'];

export const generateIntentJwk = async () => {
  const key = await crypto.subtle.generateKey(keyParams, true, keyUsages);

  if ('publicKey' in key) {
    throw new Error(
      'Invariant violation: expected a CryptoKey but got a CryptoKeyPair',
    );
  }

  return crypto.subtle.exportKey('jwk', key);
};

export const signIntent = async (
  intent: string,
  jwk: DeepReadonly<JsonWebKey>,
): Promise<string> => {
  const enc = new TextEncoder();

  const encodedIntent = enc.encode(intent);

  const key = await crypto.subtle.importKey(
    'jwk',
    jwk as JsonWebKey,
    keyParams,
    false,
    keyUsages,
  );

  const signature = await crypto.subtle.sign('HMAC', key, encodedIntent);

  const base64sig = btoa(uint32ArrayToString(new Uint32Array(signature)));

  return `${intent}.${base64sig}`;
};

export const parseSignedIntent = async (
  signedIntent: string,
  jwk: DeepReadonly<JsonWebKey>,
) => {
  const enc = new TextEncoder();

  const [intent, base64sig] = signedIntent.split('.');
  const signature = uint32ArrayFromString(atob(base64sig)).buffer;
  const encodedIntent = enc.encode(intent);

  const key = await crypto.subtle.importKey(
    'jwk',
    jwk as JsonWebKey,
    keyParams,
    false,
    keyUsages,
  );

  const valid = await crypto.subtle.verify(
    'HMAC',
    key,
    signature,
    encodedIntent,
  );

  if (!valid) {
    throw new Error('Intent signature invalid!');
  }

  return deserializeIntent(intent);
};
