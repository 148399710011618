import { useMediaQuery } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import Form from '../GalleryEditForm';
import connect from './connect';
import { handleDelete, handleSubmit } from './logic';
import { Container, Title } from './styles';
import { Props } from './types';

const Edit: FC<Props> = ({
  editDiscoverItem,
  history: { replace },
  item,
  location: { state = {} },
  match,
  openConfirmationModal,
}) => {
  const url = match?.url;

  const isFromTablet = useMediaQuery(from.tabletH.query);

  const onClose = useCallback(() => {
    if (url) {
      replace({
        pathname: up(url),
        state: {
          ...state,
          scrollToTop: false,
        },
      });
    }
  }, [replace, state, url]);

  const onDelete = useCallback(
    handleDelete({
      openConfirmationModal,
      discoverId: match?.params.discoverId,
    }),
    [openConfirmationModal, match?.params.discoverId],
  );

  const onSubmit = useCallback(
    handleSubmit({
      editDiscoverItem,
      item,
      discoverId: match?.params.discoverId,
    }),
    [editDiscoverItem, item, match?.params.discoverId],
  );

  return (
    <Container
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!match}
      scroll="body"
    >
      <Title>Edit your content</Title>
      {match && (
        <Form
          editId={match.params.discoverId}
          onDelete={onDelete}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  );
};

export default connect(Edit);
