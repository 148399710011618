import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { addVote, removeVote } from './actions';
import { VoteAction, VoteState } from './types';

export const INITIAL_STATE: VoteState = {
  loading: false,
};

const reducer: Reducer<VoteState, VoteAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(addVote.request):
      return {
        ...state,
        loading: true,
      };
    case getType(addVote.success):
      return {
        ...state,
        loading: false,
      };
    case getType(addVote.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(removeVote.request):
      return {
        ...state,
        loading: true,
      };
    case getType(removeVote.success):
      return {
        ...state,
        loading: false,
      };
    case getType(removeVote.failure):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
