import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    {...props}
  >
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.364"
    >
      <path
        stroke="#004A8F"
        d="M20 15.653v1.739h0c0 1.44-1.168 2.607-2.608 2.608H2.608h0C1.168 20 0 18.832 0 17.392v-1.74"
        transform="translate(1 1)"
      />
      <path
        stroke="#297FD5"
        d="M10 15.555L10 0M16.364 9.189L10 15.553 3.636 9.189"
        transform="translate(1 1)"
      />
    </g>
  </svg>
));
