import { MinusSignIcon, PlusSignIcon } from 'components/icons';
import styled from 'styled-components';

export const PlusIcon = styled(PlusSignIcon)`
  color: ${({ theme }) => theme.colors.accent};
  margin-right: ${({ theme }) => `${theme.spacing.md}`};
  width: 1rem;
`;

export const MinusIcon = styled(MinusSignIcon)`
  color: ${({ theme }) => theme.colors.accent};
  margin-right: ${({ theme }) => `${theme.spacing.md}`};
  width: 1rem;
  display: none;
`;

export const Container = styled.details`
  color: ${({ theme }) => theme.colors.black};

  &[open] {
    ${MinusIcon} {
      display: block;
    }
    ${PlusIcon} {
      display: none;
    }
  }
`;

export const Summary = styled.summary`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey300};
  cursor: pointer;
  display: flex;
  list-style: none;
  padding-bottom: 0.75rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ::-webkit-details-marker {
    display: none;
  }
`;

export const Title = styled.span`
  font-size: 1.3125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;
