import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 30 22" {...props}>
    {children}
    <path
      fillRule="evenodd"
      d="M4.987 0c.258 0 .468.21.468.468v21.064c0 .258-.21.468-.468.468H.468A.468.468 0 0 1 0 21.532V.468C0 .21.21 0 .468 0h4.519zm8.181 9.625c.259 0 .468.21.468.468v11.439c0 .258-.21.468-.468.468H8.65a.468.468 0 0 1-.468-.468V10.093c0-.258.21-.468.468-.468h4.518zM21.35 5.5c.259 0 .468.21.468.468v15.564c0 .258-.21.468-.468.468h-4.518a.468.468 0 0 1-.468-.468V5.968c0-.258.21-.468.468-.468h4.518zm8.182-4.125c.258 0 .468.21.468.468v19.689c0 .258-.21.468-.468.468h-4.519a.468.468 0 0 1-.468-.468V1.843c0-.258.21-.468.468-.468h4.519z"
    />
  </svg>
));
