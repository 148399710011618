import Dialog from '@material-ui/core/Dialog';
import { TimesIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { buttonReset, from } from 'styles/mixins';

export const Root = styled(Dialog)`
  position: relative;
  & .MuiDialog-paperScrollPaper {
    max-height: none;
  }
  & .MuiPaper-root {
    color: inherit;
    box-sizing: border-box;
    border: 0.125rem solid ${({ theme }) => theme.colors.grey200};

    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        max-width: 90vw;
        width: 50rem;
        margin: ${({ theme }) => `0 ${theme.wrapperPaddingH.mobile}`};
        max-height: 76vh;
      `};
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  outline: none;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const CloseButtonContainer = styled.button<{ fullScreen: boolean }>`
  ${buttonReset};
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  position: absolute;
  right: ${({ theme }) => theme.spacing.md};
  top: ${({ theme }) => theme.spacing.md};
  z-index: ${ZIndex.MODAL_CLOSE_BUTTON};
`;

export const CloseIcon = styled(TimesIcon)`
  width: 1rem;

  ${from.mobileXL`
    width: 1.25rem;
  `}
`;
