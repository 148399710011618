import React, { FC, memo, useCallback } from 'react';
import { Container, ContainerVideo } from './styles';
import { Props } from './types';

const Thumbnail: FC<Props> = ({
  index,
  onClick: parentOnClick,
  src,
  mediaType,
  ...rest
}) => {
  const onClick = useCallback(() => parentOnClick(index), [
    index,
    parentOnClick,
  ]);

  if (mediaType === 'VIDEO') {
    return <ContainerVideo onClick={onClick} src={src} {...rest} />;
  }

  return <Container onClick={onClick} src={src} {...rest} />;
};

export default memo(Thumbnail);
