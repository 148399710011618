import React, { FC } from 'react';
import { Container, Icon, Title } from './styles';
import { Props } from './types';

const NoResults: FC<Props> = ({ children, title, ...rest }) => (
  <Container {...rest}>
    <Icon />
    <Title>{title ? title : 'Oops! No results matching your search.'}</Title>
    {children}
  </Container>
);

export default NoResults;
