import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      d="m4 6.66666667v9.33333333h-4v-9.33333333zm6-3.33333334v12.66666667h-4v-12.66666667zm6-3.33333333v16h-4v-16z"
      fill="currentColor"
    />
  </svg>
));
