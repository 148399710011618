import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import { DeepReadonly } from 'utility-types';
import { Challenge } from '.';

export function isChallengeClosed(
  challenge: DeepReadonly<Challenge>,
  now = new Date(),
): boolean {
  return isBefore(parseISO(challenge.deadline), now);
}
