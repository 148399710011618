import { useMediaQuery } from '@material-ui/core';
import { ScrollBox } from 'components';
import Button from 'components/Button';
import React, { FC, memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { from } from 'styles/mixins';
import {
  handleRotate,
  handleSave,
  handleZoom,
  useAvatar,
  useCrop,
} from './logic';
import {
  ButtonGroup,
  ButtonsContainer,
  cropperContainerClassName,
  cropperCropAreaClassName,
  cropperImageClassName,
  DropZone,
  Modal,
  NoFiles,
  Slider,
  Tip,
  Title,
  Tweak,
  TweakHeader,
  TweakLabel,
  Tweaks,
  TweakValue,
} from './styles';
import { Props } from './types';

const AvatarEditor: FC<Props> = ({
  avatar: initialAvatar,
  onChange,
  onDiscard,
  open,
}) => {
  const { avatar, onChangeAvatar } = useAvatar({ initialAvatar });

  const avatarPristine = (initialAvatar ?? null) === avatar;

  const { getRootProps, getInputProps, open: openDropZone } = useDropzone({
    onDrop: onChangeAvatar as (files: File[]) => void,
    accept: ['image/*'],
    noClick: !!avatar,
  });

  const {
    area,
    crop,
    pristine: cropPristine,
    reset: resetCropper,
    rotation,
    setArea,
    setCrop,
    setRotation,
    setZoom,
    zoom,
  } = useCrop({
    avatar,
  });

  const isFromTablet = useMediaQuery(from.tablet.query);

  const pristine = avatarPristine && cropPristine;

  const onCropComplete = useCallback(
    (_, croppedAreaPixels) => {
      if (!isNaN(croppedAreaPixels.x) && !isNaN(croppedAreaPixels.y)) {
        setArea(croppedAreaPixels);
      }
    },
    [setArea],
  );

  const onRotate = useCallback(handleRotate({ setRotation }), [setRotation]);
  const onZoom = useCallback(handleZoom({ setZoom }), [setZoom]);

  const onReset = useCallback(() => {
    onChangeAvatar(initialAvatar ?? null);
    resetCropper();
  }, [onChangeAvatar, initialAvatar, resetCropper]);

  const deleteImageToCrop = useCallback(() => {
    onChangeAvatar(null);
  }, [onChangeAvatar]);

  const onSave = useCallback(
    handleSave({
      area,
      avatar,
      onChange,
      rotation,
    }),
    [area, avatar, onChange, rotation],
  );

  return (
    <Modal fullScreen={!isFromTablet} open={open} onClose={onDiscard}>
      <ScrollBox scroll={true}>
        <Title>Update your profile photo</Title>
        <DropZone hasImage={!!avatar} {...getRootProps()}>
          <input {...getInputProps()} />
          {avatar ? (
            <>
              <Tip>Drag to reposition photo or drop a new photo</Tip>
              <Cropper
                classes={{
                  containerClassName: cropperContainerClassName,
                  cropAreaClassName: cropperCropAreaClassName,
                  mediaClassName: cropperImageClassName,
                }}
                aspect={1}
                crop={crop}
                cropShape="rect"
                image={avatar}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onRotationChange={setRotation}
                onZoomChange={setZoom}
                rotation={rotation}
                zoom={zoom}
              />
            </>
          ) : (
            <NoFiles />
          )}
        </DropZone>
        {avatar && (
          <Tweaks>
            <Tweak>
              <TweakHeader>
                <TweakLabel id="zoom-label">Zoom</TweakLabel>
                <TweakValue>{zoom}x</TweakValue>
              </TweakHeader>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="zoom-label"
                onChange={onZoom}
              />
            </Tweak>
            <Tweak>
              <TweakHeader>
                <TweakLabel id="rotation-label">Rotation</TweakLabel>
                <TweakValue>{rotation}º</TweakValue>
              </TweakHeader>
              <Slider
                value={rotation}
                min={0}
                max={359}
                step={1}
                aria-labelledby="rotation-label"
                onChange={onRotate}
              />
            </Tweak>
          </Tweaks>
        )}
        <ButtonsContainer>
          {avatar && <Button onClick={deleteImageToCrop}>Delete photo</Button>}
          <ButtonGroup>
            {avatar && (
              <Button onClick={openDropZone} variant="secondary">
                Change photo
              </Button>
            )}
            <Button disabled={pristine} onClick={onReset} variant="secondary">
              Reset
            </Button>
            <Button disabled={pristine} onClick={onSave}>
              Save changes
            </Button>
          </ButtonGroup>
        </ButtonsContainer>
      </ScrollBox>
    </Modal>
  );
};

export default memo(AvatarEditor);
