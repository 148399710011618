import { Media, MediaFile } from 'components/MediaSelector/types';

export const imageToMedia = (url: string): MediaFile => {
  return {
    file: url,
    mediaType: 'IMAGE',
    thumbnail: url,
  };
};

export const imageConvertMedia = (url: string): Media => {
  return {
    file: url,
    status: 'uploaded',
    thumbnail: url,
    mediaType: 'IMAGE',
  };
};

export const imageListConvertMedia = (urls: string[]): Media[] => {
  const media: Media[] = [];
  urls.forEach((url) => {
    media.push(imageConvertMedia(url));
  });
  return media;
};
