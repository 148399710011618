import { LocationDescriptor } from 'history';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

export const beginIntent = createAction('intent/BEGIN_INTENT')<{
  returnTo: string | LocationDescriptor;
  intent?: string;
}>();

export const generateIntentKey = createAsyncAction(
  'intent/GENERATE_KEY_REQUEST',
  'intent/GENERATE_KEY_SUCCESS',
  'intent/GENERATE_KEY_FAILURE',
)<undefined, DeepReadonly<JsonWebKey>, undefined>();
