import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { closeModal } from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';
import { updateVideoProgress } from 'redux/modules/video';

const mapStateToProps = (state: RootState) => ({
  userIsPremium: selectUserIsPremium(state),
  isLoggedIn: !!state.auth.user,
});

const mapDispatchToProps = {
  updateVideoProgress,
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
