import CardGrid from 'components/CardGrid';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export default styled(CardGrid)`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing.xlg};
  grid-template-columns: 1fr;

  ${from.mobileXL`
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  `};
`;
