import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
