import { Loader as DefaultLoader } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Loader = styled(DefaultLoader)``;
