import { Card, CardGrid, Loader, Pagination } from 'components';
import { getChallengeResponseStatusList } from 'containers/Main/Challenges/ChallengeRoot/logic';
import { getUserName } from 'model';
import React, { FC } from 'react';
import { getUserType } from 'utils/getUserType';
import { Link } from '../../../styles';
import connect from './connect';
import { Props } from './types';

const Responses: FC<Props> = ({ items, loading, page, pageCount, setPage }) =>
  loading ? (
    <Loader />
  ) : (
    <>
      <CardGrid>
        {items.map((submission) => (
          <Link
            key={submission.id}
            to={`/challenges/${submission.challenge}/${submission.id}`}
            target="_blank"
          >
            <Card
              authorAvatar={submission.user.avatar}
              authorName={getUserName(submission.user)}
              authorType={getUserType(submission.user)}
              comments={submission.commentsCount}
              datapoints={submission.votesCount}
              lastCommentDate={
                submission.lastComment ? submission.lastComment : undefined
              }
              statuses={getChallengeResponseStatusList(submission)}
              tags={submission.tags}
              thumbnail={submission.mediaList?.mainMedia}
              title={submission.title}
              views={submission.hits}
            />
          </Link>
        ))}
      </CardGrid>
      <Pagination
        current={Math.min(page, pageCount)}
        setPage={setPage}
        total={pageCount}
      />
    </>
  );

export default connect(Responses);
