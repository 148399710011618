import { connect } from 'react-redux';
import { editSettings } from 'redux/modules/settings';
import { RootState } from 'redux/modules/types';

const mapStateToProps = ({ settings: { settings } }: RootState) => ({
  isSubscribedToChallenges: settings.newChallenge,
  isSubscribedToExercises: settings.newExercise,
});

const mapDispatchToProps = {
  editSettings: editSettings.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
