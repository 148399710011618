import DefaultButton from 'components/Button';
import styled, { css } from 'styled-components';

export const Button = styled.div`
  width: 100%;
  margin-top: 1.375rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.875rem;
  box-sizing: border-box;
  text-align: center;
  transition: all 200ms ease;
  opacity: 0;
`;

export const Link = styled(DefaultButton)`
  padding: 2.75rem 2rem 1.375rem;
  border: 1px solid ${({ theme }) => theme.colors.accent};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 101%;
  height: 101%;
  border-radius: 0.5rem;
  margin-top: -0.0625rem;
  margin-left: -0.0625rem;
  box-sizing: border-box;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.accent};

  :disabled {
    background-color: transparent;
    opacity: 0.5;
  }

  :hover:not(:disabled),
  :focus-visible:not(:disabled) {
    background-color: transparent;
    box-shadow: none;

    ${Button} {
      opacity: 1;
    }
  }
`;

export const Container = styled.div<{
  $disabled: boolean;
  $specialOffer: boolean;
}>`
  position: relative;
  min-height: 15.3125rem;
  border: 2px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 0.5rem;
  transition: height 0.2s ease;
  width: 17.3125rem;
  box-size: border-box;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ $disabled, theme }) =>
    !$disabled &&
    css`
      :hover {
        min-height: 16.25rem;
        border: 2px solid ${theme.colors.accent};
      }
    `}

  ${({ $specialOffer, theme }) =>
    $specialOffer &&
    css`
      border: 6px solid ${theme.colors.basicGold};

      :hover {
        border: 6px solid ${theme.colors.basicGold};
      }

      ${Link} {
        border-color: transparent;
      }
    `}
`;

export const Title = styled.p`
  font-size: 1.75rem;
  line-height: 1.2;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.accent};
`;

export const Price = styled.span`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.black};
  margin: 1.25rem 0 0.5rem;
  letter-spacing: normal;
`;

export const Subtitle = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.5;
  text-align: center;
  text-transform: lowercase;
  letter-spacing: normal;
`;

export const Tag = styled.span`
  position: absolute;
  top: -0.75rem;
  padding: 0.25rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.accent};
  font-size: 1.25rem;
  line-height: 1.1;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  text-transform: initial;
  letter-spacing: normal;
`;

export const SpecialOfferTag = styled(Tag)`
  top: -1rem;
  background-color: ${({ theme }) => theme.colors.basicGold};
  color: ${({ theme }) => theme.colors.black};
`;

export const ComparativePrice = styled.span`
  font-size: 1.3125rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.grey400};
  margin: 0.75rem 0 -1.25rem;
  letter-spacing: normal;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    width: 90px;
    height: 2px;
    transform: rotate(-6.573deg);
    opacity: 0.72;
    background: ${({ theme }) => theme.colors.error};
  }
`;

export const DarkSubtitle = styled(Subtitle)`
  color: ${({ theme }) => theme.colors.black};
`;

export const SmallSubtitle = styled(Subtitle)`
  font-size: 1rem;
  line-height: 1.875rem;
`;
