import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  margin-top: ${({ theme }) => theme.spacing.xlg};

  ${from.mobileXL`
    > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  }
  `}
`;
