import { Modal as DefaultModal } from 'components';
import styled, { css } from 'styled-components';

export const Modal = styled(DefaultModal)`
  & .MuiPaper-root {
    ${({ fullScreen }) =>
      fullScreen
        ? css`
            min-height: 100vh;
          `
        : css`
            max-width: 1170px;
            width: 90vw;
          `};
  }
`;
