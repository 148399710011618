import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin: 0 0 0.75rem 0;
  :first-letter {
    text-transform: capitalize;
  }

  ${from.tablet`
    font-size: 1.75rem;
`}
`;
