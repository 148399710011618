import Editor from 'components/Editor';
import { useReferenceProvider } from 'components/Editor/referenceProvider';
import { EditorState } from 'components/Editor/types';
import React, { FC, useCallback } from 'react';
import { Container } from './styles';
import { Props } from './types';

const EditorInput: FC<Props> = ({
  className,
  input: { onChange, value },
  label,
  meta,
  placeholder,
  ...rest
}) => {
  const referenceProvider = useReferenceProvider();

  const handleChange = useCallback(
    (nextValue: EditorState) => {
      onChange(nextValue);
    },
    [onChange],
  );

  return (
    <Container label={label} meta={meta} {...rest}>
      <Editor
        className={className}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        referenceProvider={referenceProvider}
      />
    </Container>
  );
};

export default EditorInput;
