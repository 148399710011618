import React, { FC, useCallback } from 'react';
import { useSelectionAnchor } from 'utils/prosemirror';
import { selectOption } from '../actions';
import { useChangeIndexByHover, useFetchOptions } from './logic';
import { Container, Image, Label, Option } from './styles';
import type { Props } from './types';

const Prompt: FC<Props> = ({
  editorView,
  getProvider,
  options,
  selectedOptionIndex,
  state,
  reference,
}) => {
  const { left, top } = useSelectionAnchor({ editorView, state });

  // Change the index of the user selected when mouse enters the option
  const onChangeIndexHover = useChangeIndexByHover({ editorView, state });

  const selectOptionState = useCallback(() => {
    selectOption(reference)(state, editorView.dispatch);
  }, [editorView.dispatch, state, reference]);

  useFetchOptions({
    editorView,
    getProvider,
    state,
  });

  return (
    <Container
      $left={left}
      $top={top}
      onClick={selectOptionState}
      $haveOptions={!!options.length}
    >
      {options.map(({ id, image, label }, index) => (
        <Option
          $selected={index === selectedOptionIndex}
          key={id}
          onMouseEnter={onChangeIndexHover(index)}
        >
          <Image alt={label} src={image} />
          <Label>{label}</Label>
        </Option>
      ))}
    </Container>
  );
};

export default Prompt;
