import { connect } from 'react-redux';
import { resetSearchSectionPages, search } from 'redux/modules/search';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  availableSections: state.search.available,
  lastLocation: state.router.lastLocation,
  loading: state.search.loading,
});

const mapDispatchToProps = {
  resetSearchSectionPages,
  search: search.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
