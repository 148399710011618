import styled from 'styled-components';
import { visuallyHidden } from 'styles/mixins';

export const PageSpan = styled.span`
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  padding-top: 1px;
  margin-inline: 0.5rem;
`;

export const VisuallyHiddenText = styled.span`
  ${visuallyHidden}
`;
