import {
  LocationChangeAction,
  LOCATION_CHANGE,
  replace,
} from 'connected-react-router';
import { parse, stringify } from 'query-string';
import { combineEpics } from 'redux-observable';
import { openSignUp } from 'redux/modules/modal';
import { selectAnyLoading } from 'redux/modules/rootSelectors';
import { Epic } from 'redux/modules/types';
import { from, interval } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mapTo,
  mergeMap,
  take,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  generateIntentJwk,
  parseSignedIntent,
  serializeLocationDescriptor,
  signIntent,
} from 'utils/intent';
import { begin, commit } from 'utils/redux-transaction';
import { beginIntent, generateIntentKey } from './actions';

const beginIntentEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(beginIntent)),
    mergeMap(({ payload: { intent, returnTo } }) => {
      const { jwk } = state$.value.intent;

      if (intent) {
        if (!jwk) {
          return [];
        }

        return from(signIntent(intent, jwk)).pipe(
          map((signedIntent) => ({ returnTo, intent: signedIntent })),
          catchError((e) => {
            console.error(e);
            return [];
          }),
        );
      }

      return [{ intent, returnTo }];
    }),
    mergeMap(({ intent, returnTo }) => [
      begin(),
      openSignUp(),
      replace({
        ...state$.value.router.location,
        search: stringify({
          ...parse(state$.value.router.location.search),
          intent,
          returnTo:
            typeof returnTo === 'string'
              ? returnTo
              : `LD__${serializeLocationDescriptor(returnTo)}`,
        }),
      }),
      commit(),
    ]),
  );

const dispatchIntentEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(
      (action): action is LocationChangeAction =>
        action.type === LOCATION_CHANGE,
    ),
    // Ignore elements if the user is not logged in
    filter(() => !!state$.value.auth.user),
    // Ignore actions that don't have an intent in their query string
    mergeMap(
      ({
        payload: {
          location: { pathname, search },
        },
      }) => {
        const { intent, ...query } = parse(search);

        if (intent && typeof intent === 'string') {
          return [
            {
              intent,
              pathname,
              query,
            },
          ];
        }

        return [];
      },
    ),
    // Wait a bit so all requests for content can be fired
    // If during this time there's a redirect, take the latest value
    debounceTime(50),
    // Wait until all content has been fetched
    mergeMap((action) =>
      selectAnyLoading(state$.value)
        ? interval(5).pipe(
            filter(() => !selectAnyLoading(state$.value)),
            take(1),
            mapTo(action),
          )
        : [action],
    ),
    mergeMap(({ intent: signedIntent, pathname, query }) => {
      const { jwk } = state$.value.intent;

      if (!jwk) {
        return [];
      }

      return from(parseSignedIntent(signedIntent, jwk)).pipe(
        map((intent) => ({ intent, pathname, query })),
        catchError((err) => {
          console.error(err);
          return [];
        }),
      );
    }),
    // Dispatch the intent and remove it from the query string
    mergeMap(({ intent, pathname, query }) => [
      replace({
        ...state$.value.router.location,
        pathname,
        search: stringify(query),
      }),
      ...intent,
      // After the key is used, rotate it.
      generateIntentKey.request(),
    ]),
    catchError((err) => {
      console.error(err);
      return [];
    }),
  );

export const generateIntentKeyEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(generateIntentKey.request)),
    mergeMap(() => {
      const { jwk } = state$.value.intent;

      // If the key was already generated, skip this
      if (jwk) {
        return [generateIntentKey.success(jwk)];
      }

      return from(generateIntentJwk()).pipe(
        mergeMap((key) => [generateIntentKey.success(key)]),
        catchError((err) => {
          console.error(err);
          return [generateIntentKey.failure()];
        }),
      );
    }),
  );

export default combineEpics(
  beginIntentEpic,
  dispatchIntentEpic,
  generateIntentKeyEpic,
);
