import { createEditorState } from 'components/Editor';
import {
  AddToGalleryForm,
  ADD_TO_GALLERY_FORM_KEY,
  UPLOAD_MEDIA_FIELD,
  visualTagInputsInitialValues,
} from 'model';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

export type ConnectedProps = InjectedFormProps<AddToGalleryForm>;

export default compose(
  reduxForm<AddToGalleryForm>({
    destroyOnUnmount: false,
    form: ADD_TO_GALLERY_FORM_KEY,
    initialValues: {
      ...visualTagInputsInitialValues,
      [UPLOAD_MEDIA_FIELD]: [],
      context: createEditorState(),
      title: '',
    },
  }),
) as FormDecorator<AddToGalleryForm, OwnProps, ConnectedProps>;
