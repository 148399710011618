import React, { FC, memo } from 'react';
import { Container, InfoIcon, Text } from './styles';
import { Props } from './types';

const Error: FC<Props> = ({ text, ...rest }) => (
  <Container {...rest}>
    <InfoIcon />
    <Text>{text}</Text>
  </Container>
);

export default memo(Error);
