import { GroupCodeForm, GROUP_CODE_FORM_KEY } from 'model/forms/GroupCode';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<GroupCodeForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<GroupCodeForm>({
    form: `${GROUP_CODE_FORM_KEY}/onboarding/group-code`,
  }),
) as FormDecorator<GroupCodeForm, OwnProps, ConnectedProps>;
