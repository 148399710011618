import { Error, Input } from 'components';
import { LoginFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { email, required } from 'utils/validations';
import connect from './connect';
import { ButtonPrimary, Container, Link, Row } from './styles';
import { Props } from './types';

const RequestResetPasswordForm: FC<Props> = ({
  error,
  handleSubmit,
  invalid,
  loading,
  pristine,
  search,
}) => (
  <Container onSubmit={handleSubmit}>
    <Field
      autocomplete="username"
      component={Input}
      label="Email"
      name={LoginFieldNames.email}
      placeholder="Enter your email"
      type="text"
      validate={[email, required]}
    />
    {error && <Error text={error} />}
    <Row>
      <ButtonPrimary disabled={invalid || loading || pristine} type="submit">
        {loading ? 'Loading...' : 'Send me help'}
      </ButtonPrimary>
    </Row>
    <Link to={{ search, pathname: '/login' }}>
      Wait! I remember my password
    </Link>
  </Container>
);

export default connect(RequestResetPasswordForm);
