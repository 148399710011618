import {
  ChallengeCard,
  ChallengeCardGrid,
  Loader,
  Pagination,
} from 'components';
import React, { FC } from 'react';
import connect from './connect';
import { Props } from './types';

const Proposals: FC<Props> = ({ items, loading, page, pageCount, setPage }) =>
  loading ? (
    <Loader />
  ) : (
    <>
      <ChallengeCardGrid>
        {items.map(({ deadline, description, id, image, name, link }) => (
          <ChallengeCard
            description={description}
            endDate={deadline}
            key={id}
            link={link || `/challenges/${id}`}
            linkText="See challenge"
            thumbnail={image}
            title={name}
          />
        ))}
      </ChallengeCardGrid>
      <Pagination
        current={Math.min(page, pageCount)}
        setPage={setPage}
        total={pageCount}
      />
    </>
  );

export default connect(Proposals);
