import range from 'lodash/range';
import { NonUndefined } from 'utility-types';

export const group = <T>(
  value: readonly T[],
  quantity: number,
): NonUndefined<T>[][] =>
  range(Math.ceil(value.length / quantity)).map((i) =>
    range(quantity)
      .map((j) => value[j + i * quantity])
      .filter((it): it is NonUndefined<typeof it> => typeof it !== 'undefined'),
  );
