import { ConfusedShrugIcon } from 'components/icons';
import styled from 'styled-components';
import { Row, Title as DefaultTitle } from '../SubscriptionRoot/styles';

export const TitleRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  flex-direction: row;
`;

export const Title = styled(DefaultTitle)`
  margin: 0;
`;

export const EmptyMembersContainer = styled.div`
  margin-top: 3.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const EmptyMembersIcon = styled(ConfusedShrugIcon)`
  width: 15.625rem;
`;

export const EmptyMembersTitle = styled.h3`
  margin: 2rem 0 0.75rem;
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
`;

export const EmptyMembersText = styled.p`
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
`;
