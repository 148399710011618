import { DatePicker as DefaultDatePicker } from '@material-ui/pickers';
import DefaultInput from 'components/Input';
import { styles as input } from 'components/RawInput/styles';
import styled from 'styled-components';

export const Input = styled(DefaultInput)``;

export const DatePicker = styled(DefaultDatePicker)`
  .MuiInput-root {
    ${input};

    ::after {
      content: none;
    }

    ::before {
      content: none;
    }
  }

  .MuiInput-input {
    padding: 0;
  }
`;
