import 'prosemirror-view/style/prosemirror.css';
import React, { FC } from 'react';

import { useEditor } from './logic';
import { Container, EditorContainer } from './styles';
import type { Props } from './types';
import { createEditorState, parseHTML, serializeHTML } from './utils';

const Editor: FC<Props> = ({
  onChange,
  placeholder,
  referenceProvider,
  value,
  ...rest
}) => {
  const { editorRef, focused } = useEditor({
    onChange,
    placeholder,
    referenceProvider,
    value,
  });

  return (
    <Container {...rest}>
      <EditorContainer ref={editorRef} focused={focused} />
    </Container>
  );
};

export default Editor;
export { createEditorState, parseHTML, serializeHTML };
