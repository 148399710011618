import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { getChallenge, selectChallengesBySlug } from 'redux/modules/challenge';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { challengeSlug },
    path,
    url,
  } = useRouteMatch<{ challengeSlug: string }>();

  const challengesBySlug = useSelector(selectChallengesBySlug);

  const actions = useActions({
    getChallenge: getChallenge.request,
  });

  return {
    ...actions,
    challengeSlug,
    path,
    url,
    challenge: challengesBySlug[challengeSlug],
  };
};
