import { connect } from 'react-redux';
import { signUp } from 'redux/modules/auth';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (_: RootState) => ({});

const mapDispatchToProps = {
  signUp: signUp.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
