import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.accent};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 14px;
  text-align: center;
  text-transform: uppercase;
`;
