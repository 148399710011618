import { FireIcon as DefaultFireIcon } from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  gap: 8px;

  ${from.tabletH`
    gap: 12px;
  `}
`;

export const ActivityContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 3px 8px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;

  ${from.tabletH`
    gap: 8px;
    padding: 5px 8px;
  `}
`;

export const ActivityText = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 14px;
  letter-spacing: 0.44px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
`;

export const NumberStreak = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 2px 2px 2px 4px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.accent};
  border-radius: 4px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
`;

export const FireIcon = styled(DefaultFireIcon)`
  width: 16px;
  height: 16px;
`;

export const ActivityTextPrimary = styled.span`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 14px;
  letter-spacing: 0.44px;
  text-align: left;
  margin: 0;
  color: ${({ theme }) => theme.colors.accent};
`;
