import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { DISCOVER_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import {
  createDiscoverItem,
  deleteDiscoverItem,
  editDiscoverItem,
  getDiscoverItem,
  getDiscoverItems,
  _upsertDiscoverById,
} from './actions';
import { DiscoverAction, DiscoverState } from './types';

export const INITIAL_STATE: DiscoverState = {
  ...defaultStandardState,
};

const reducer: Reducer<DiscoverState, DiscoverAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    create: {
      request: isActionOf(createDiscoverItem.request),
      success: isActionOf(createDiscoverItem.success),
      failure: isActionOf(createDiscoverItem.failure),
    },
    edit: {
      request: isActionOf(editDiscoverItem.request),
      success: isActionOf(editDiscoverItem.success),
      failure: isActionOf(editDiscoverItem.failure),
    },
    getMany: {
      request: isActionOf(getDiscoverItems.request),
      success: isActionOf(getDiscoverItems.success),
      failure: isActionOf(getDiscoverItems.failure),
    },
    getOne: {
      request: isActionOf(getDiscoverItem.request),
      success: isActionOf(getDiscoverItem.success),
      failure: isActionOf(getDiscoverItem.failure),
    },
    remove: {
      request: isActionOf(deleteDiscoverItem.request),
      success: isActionOf(deleteDiscoverItem.success),
      failure: isActionOf(deleteDiscoverItem.failure),
    },
    _upsertById: isActionOf(_upsertDiscoverById),
  },
  contractItem: (payload) => payload,
  initialState: INITIAL_STATE,
  perPage: DISCOVER_PER_PAGE,
})(reducer);
