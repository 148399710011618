import { FormNameMap } from 'utils/forms';

export const CHANGE_PLAN_FORM_KEY = 'forms/CHANGE_PLAN';

export interface ChangePlanForm {
  quantity: number;
  companyName: string;
}

export const ChangePlanNames: FormNameMap<ChangePlanForm> = {
  quantity: 'quantity',
  companyName: 'companyName',
};
