import React, { FC, memo } from 'react';
import { Container } from './styles';
import { Props } from './types';

const InvalidFiles: FC<Props> = ({ ...rest }) => (
  <Container {...rest}>
    <p>Invalid file type!</p>
  </Container>
);

export default memo(InvalidFiles);
