import DragFiles from 'components/DragFiles';
import React, { FC } from 'react';
import DragActive from './DragActive';
import { DropZone, SingleFile } from './styles';
import { Props } from './types';

const SingleFileVariant: FC<Props> = ({
  canAdd,
  compact,
  files,
  getDropZoneProps,
  isDragAccept,
  isDragActive,
  isMouseInput,
  onDeleteFile,
  onEdited,
  onUploaded,
}) => (
  <DropZone
    canAdd={canAdd}
    compact={compact}
    isMouseInput={isMouseInput}
    {...getDropZoneProps()}
  >
    {isDragActive ? (
      <DragActive isDragAccept={isDragAccept} />
    ) : files.length === 1 ? (
      <SingleFile
        isMouseInput={isMouseInput}
        media={files[0]}
        onDeleteFile={onDeleteFile}
        onEdited={onEdited}
        onUploaded={onUploaded}
      />
    ) : (
      <DragFiles compact={!isMouseInput} />
    )}
  </DropZone>
);

export default SingleFileVariant;
