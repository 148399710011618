import type { Command } from 'prosemirror-commands';
import type { Mark, Node as ProsemirrorNode } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import { Step, StepResult } from 'prosemirror-transform';
import { dispatchToTransaction } from 'utils/prosemirror';
import { getPluginState } from '.';
import type { Schema } from '../../schema';
import type { ReferencedEntity } from '../types';
import { getCurrentReference, getCurrentReferenceNode } from './logic';

export class ModifySelectedIndexStep extends Step {
  public readonly type: 'increment' | 'decrement' | 'reset' | number;

  constructor(type: ModifySelectedIndexStep['type']) {
    super();
    this.type = type;
  }

  apply(doc: ProsemirrorNode) {
    return StepResult.ok(doc);
  }

  invert() {
    if (this.type === 'increment') {
      return new ModifySelectedIndexStep('decrement');
    }
    if (this.type === 'decrement') {
      return new ModifySelectedIndexStep('increment');
    }
    if (typeof this.type === 'number') {
      return new ModifySelectedIndexStep(this.type);
    }

    return new ModifySelectedIndexStep('reset');
  }

  map() {
    return this;
  }

  toJSON() {
    return {};
  }
}

export class ModifyOptionsStep extends Step {
  public readonly options: readonly ReferencedEntity[];

  constructor(options: readonly ReferencedEntity[]) {
    super();
    this.options = options;
  }

  apply(doc: ProsemirrorNode) {
    return StepResult.ok(doc);
  }

  invert() {
    return new ModifyOptionsStep([]);
  }

  map() {
    return this;
  }

  toJSON() {
    return {};
  }
}

export const decrementSelectedIndex: Command<Schema> = (state, dispatch) => {
  if (!getCurrentReference(state)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.step(new ModifySelectedIndexStep('decrement')));
  }

  return true;
};

export const incrementSelectedIndex: Command<Schema> = (state, dispatch) => {
  if (!getCurrentReference(state)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.step(new ModifySelectedIndexStep('increment')));
  }

  return true;
};

export const setSelectedIndexByNumber = (
  promptIndex: number,
): Command<Schema> => (state, dispatch) => {
  if (!getCurrentReference(state)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.step(new ModifySelectedIndexStep(promptIndex)));
  }

  return true;
};

export const resetSelectedIndex: Command<Schema> = (state, dispatch) => {
  if (!getCurrentReference(state)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.step(new ModifySelectedIndexStep('reset')));
  }

  return true;
};

// Allow options to be selected with custom reference
export const selectOptionReference: Command<Schema> = (state, dispatch) => {
  const currentReference = getCurrentReference(state);

  if (!currentReference) {
    return false;
  }

  return selectOption(currentReference)(state, dispatch);
};

export const selectOption = (currentReference?: Mark): Command<Schema> => (
  state,
  dispatch,
) => {
  const { options, selectedOptionIndex } = getPluginState(state);
  const tr = state.tr;
  // We consider the possibility of the index being out of bounds for some
  // reason although that should never happen.
  const selectedOption: ReferencedEntity | undefined =
    options[selectedOptionIndex];

  if (!currentReference || !selectedOption) {
    return false;
  }

  // Find the node containing the currentReference mark. We need to compare the
  // id attribute as ProseMirror discourages comparing by mark identity.
  const result = getCurrentReferenceNode(state, currentReference);

  if (!result) {
    return false;
  }

  const { node: referenceNode, pos: referenceNodePos } = result;

  // Delete all text in the reference except the @ (hence the + 1)
  tr.delete(referenceNodePos + 1, referenceNodePos + referenceNode.nodeSize);

  // Insert the selected option label after the @
  tr.insertText(selectedOption.label, referenceNodePos + 1);

  // Update the mark with the selected option attributes (remove it and add it
  // back as ProseMirror doesn't support updating marks).
  const start = referenceNodePos;
  const end = tr.mapping.map(referenceNodePos + referenceNode.nodeSize);
  tr.removeMark(start, end, state.schema.marks.reference);
  tr.addMark(
    start,
    end,
    state.schema.marks.reference.create({
      id: currentReference.attrs.id,
      entityId: selectedOption.id,
      entityType: selectedOption.type,
    }),
  );

  // If there is nothing after the reference, insert a space outside the mark so
  // the user can continue writing. Otherwise, advance the cursor one position
  // for convenience.
  const { nodeAfter } = tr.doc.resolve(end);
  if (nodeAfter) {
    tr.setSelection(
      TextSelection.create(
        tr.doc,
        tr.selection.anchor + 1,
        tr.selection.head + 1,
      ),
    );
  } else {
    tr.insert(end, state.schema.text(' '));
  }

  resetSelectedIndex(state, dispatchToTransaction(tr));

  if (dispatch) {
    dispatch(tr.scrollIntoView());
  }

  return true;
};

export const setOptions = (
  options: readonly ReferencedEntity[],
): Command<Schema> => (state, dispatch) => {
  if (dispatch) {
    dispatch(state.tr.step(new ModifyOptionsStep(options)));
  }

  return true;
};
