import {
  DiscoverSearchOptionsForm,
  DISCOVER_SEARCH_OPTIONS_FORM_KEY,
} from 'model';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { execAll } from 'utils/execAll';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { parseOrder } from 'utils/parseOrder';
import { submitOnChange } from 'utils/submitOnChange';
import { OwnProps } from './types';

const { selectIsPristine, selectValues } = makeFormSelectors<
  DiscoverSearchOptionsForm
>(DISCOVER_SEARCH_OPTIONS_FORM_KEY);

const defaultValues = {
  directionIsAsc: false,
  graphTypes: [],
  order: 'random',
};

const mapStateToProps = (state: RootState) => {
  const { order, seed: _, ...rest } = parse(state.router.location.search);

  const initialValues: ConfigProps<
    DiscoverSearchOptionsForm
  >['initialValues'] = {
    ...defaultValues,
    ...rest,
    ...parseOrder(typeof order === 'string' && order ? order : 'random'),
  };

  return {
    initialValues,
    isPristine: selectIsPristine(state),
    loading: state.feedback.loading,
    onChange: execAll(
      submitOnChange<DiscoverSearchOptionsForm>('directionIsAsc'),
      submitOnChange<DiscoverSearchOptionsForm>('graphTypes'),
      submitOnChange<DiscoverSearchOptionsForm>('order'),
    ),
    order: selectValues(state)?.order,
    query: '',
    typeTags: state.configuration.graphTypeTags,
  };
};

const mapDispatchToProps = {
  submit,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<DiscoverSearchOptionsForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<DiscoverSearchOptionsForm>({
    enableReinitialize: true,
    form: DISCOVER_SEARCH_OPTIONS_FORM_KEY,
    destroyOnUnmount: false,
  }),
) as FormDecorator<DiscoverSearchOptionsForm, OwnProps, ConnectedProps>;
