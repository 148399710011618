import DefaultAvatar from 'components/Avatar';
import IconRipple from 'components/IconRipple';
import { TrashIcon as DefaultTrashIcon } from 'components/icons';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { buttonReset, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  position: relative;
  :hover {
    box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  }
`;

export const Overlay = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  bottom: 0;
  color: ${({ theme }) => theme.colors.grey400};
  display: flex;
  opacity: 1;
  left: 0;
  position: absolute;
  width: 100%;
  height: 5rem;
  justify-content: space-between;

  ${from.laptop`
    opacity: 0;
  `};

  &:hover,
  &:focus-within {
    opacity: 1;
  }
`;

export const Content = styled(DefaultLink)`
  &:focus {
    + ${Overlay} {
      opacity: 1 !important;
    }
  }
`;

export const Image = styled.img`
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;

  :hover + div {
    opacity: 1;
  }
`;

export const Video = styled.video`
  cursor: pointer;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;

  :hover + div {
    opacity: 1;
  }
`;

export const Avatar = styled(DefaultAvatar)`
  box-sizing: border-box;
  display: block;
  object-fit: cover;
  padding: 0;
  height: 2.25rem;
  width: 2.25rem;
  margin: 0 ${({ theme }) => theme.spacing.md} 0 0;

  ${BadgeContainer} {
    left: 22px;
  }
`;

const OverlaySection = styled.div`
  align-items: center;
  display: flex;
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

export const Info = styled(OverlaySection)``;

export const Actions = styled(OverlaySection)`
  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
`;

export const ThumbsUpButton = styled.button`
  ${buttonReset};
  cursor: pointer;
  margin-left: auto;
  :active {
    background-color: ${({ theme }) => theme.colors.sky};
  }
`;

export const TrashRipple = styled(IconRipple).attrs({
  iconWidth: '1.5rem',
})``;

export const TrashIcon = styled(DefaultTrashIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 1.5rem;
`;
