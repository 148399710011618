import { Button } from 'components';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import { handleClick } from './logic';
import { Props } from './types';

const ProtectedButton: FC<Props> = ({
  beginIntent,
  children,
  location: { hash, key, pathname, search, state },
  loggedIn,
  to,
  ...rest
}) => {
  const onClick = useCallback(
    handleClick({ beginIntent, to, hash, key, pathname, search, state }),
    [beginIntent, to, hash, key, pathname, search, state],
  );

  return (
    <Button {...(loggedIn ? { to } : { onClick })} {...rest}>
      {children}
    </Button>
  );
};

export default connect(ProtectedButton);
