import fold_1x_png from 'assets/images/premium/fold.png';
import fold_3x_webp from 'assets/images/premium/fold_3x.webp';
import { Fold as DefaultFold } from 'components';
import { Check, Container as Feature } from 'components/Pricing/Feature/styles';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  ${container};
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.laptop`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Fold = styled(DefaultFold)`
  padding-bottom: 3.75rem;
  padding-top: 5rem;
`;

const gradient = css`
linear-gradient(
  ${({ theme }) => theme.colors.primary},
  ${({ theme }) => addAlpha(theme.colors.accent, 0.6)}
)
`;

export const CTA = styled.div<{ webpSupported: boolean }>`
  align-items: center;
  background-position-y: top;
  background-size: cover;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 3.5rem 0;
  width: 100%;

  ${({ webpSupported }) =>
    webpSupported
      ? css`
          background-image: ${gradient}, url('${fold_3x_webp}');
        `
      : css`
          background-image: ${gradient}, url('${fold_1x_png}');
        `};
`;

export const CTATitle = styled.h3`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
  margin: 0;
  ${from.tablet`
    font-size: 1.75rem;
  `}
`;

export const Features = styled.ul`
  list-style: none;
  margin: 1.5rem 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.75rem;
  grid-row-gap: 0.5rem;

  ${from.tablet`
  margin: 2rem auto 0;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr 1fr 1fr;
`}
  ${from.laptop`
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 1fr 1fr;
`}

${Feature} {
    ${Check} {
      width: 1.25rem;
    }
  }
`;
