import DefaultButton from 'components/Button';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div<{
  $deletable: boolean;
  $isComment: boolean;
}>`
  display: flex;
  justify-content: flex-end;
  ${({ $isComment }) => $isComment && `justify-content: flex-start`};
  ${({ $deletable }) => $deletable && `justify-content: space-between`};
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  ${from.mobile`
    flex-direction: row;
    > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
    margin-bottom: 0;
    width: auto;
  }
`}
`;

export const Close = styled(DefaultButton)``;

export const Button = styled(DefaultButton)`
  white-space: nowrap;
`;
