import { Summary as DefaultSummary, Tabs as DefaultTabs } from 'components';
import { Tab } from 'components/Tabs/styles';
import styled from 'styled-components';
import { from, prettyScrollbar } from 'styles/mixins';
import DefaultAchievements from './Achievements';

export const Container = styled.div``;

export const UserProfileContent = styled.div``;

export const ActivityData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 3rem;

  ${from.tabletH`
    gap: 40px;
  `}
`;

export const Summary = styled(DefaultSummary)`
  background: lightblue;
  flex-grow: 1;
`;

export const AchievementsBox = styled(DefaultAchievements)``;

export const Tabs = styled(DefaultTabs)`
  ${Tab} {
    min-width: fit-content;

    span {
      padding: 0.55rem 0.8rem;
    }
  }

  ${from.tabletH`
    overflow-x: auto;
    margin: -2px;
    width: 90vw;

    ${prettyScrollbar({ vertical: false })}
  `};
`;
