import { BookEvent, LiveEvent } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const getLiveEvents = createAsyncAction(
  'liveEvent/GET_LIVEEVENTS_REQUEST',
  'liveEvent/GET_LIVEEVENTS_SUCCESS',
  'liveEvent/GET_LIVEEVENTS_FAILURE',
)<undefined, LiveEvent[], undefined>();

export const editLiveEventBooking = createAsyncAction(
  'liveEvent/EDIT_LIVEEVENT_BOOKING_REQUEST',
  'liveEvent/EDIT_LIVEEVENT_BOOKING_SUCCESS',
  'liveEvent/EDIT_LIVEEVENT_BOOKING_FAILURE',
)<
  { id: string } & Partial<BookEvent>,
  BookEvent & { eventId: string },
  undefined
>();

export const createLiveEventBooking = createAsyncAction(
  'liveEvent/CREATE_LIVEEVENT_BOOKING_REQUEST',
  'liveEvent/CREATE_LIVEEVENT_BOOKING_SUCCESS',
  'liveEvent/CREATE_LIVEEVENT_BOOKING_FAILURE',
)<
  { id: string } & Partial<BookEvent>,
  BookEvent & { eventId: string },
  undefined
>();
