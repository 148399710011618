import { useMediaQuery } from '@material-ui/core';
import { ChangePlanForm } from 'model/forms/ChangePlan';
import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import Form from './Form';
import { handleUpdatePlan } from './logic';
import { Container, Content, Paragraph, Title } from './styles';
import { Props } from './types';

const UpdatePaymentMethodModal: FC<Props> = ({
  info,
  history: { push },
  isCurrentlyCorporate,
  match,
  plans,
  updatePlan,
  updatePlanInterval,
  selectedPlanId,
}) => {
  const url = match?.url;
  const isFromTablet = useMediaQuery(from.mobileXL.query);
  const location = useLocation();
  const isCorporate = location.pathname.includes('corporate');
  const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
  const selectedPlanAmount = selectedPlan && selectedPlan?.amount / 100;
  const selectedPlanInterval = selectedPlan?.interval;

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  const onSubmit = useCallback(
    (value: ChangePlanForm) => {
      const companyName = value.companyName;
      const quantity = value.quantity;
      handleUpdatePlan({
        companyName,
        quantity,
        selectedPlanId,
        isCorporate,
        isCurrentlyCorporate,
        updatePlan,
        updatePlanInterval,
      });
    },
    [
      isCorporate,
      isCurrentlyCorporate,
      updatePlan,
      updatePlanInterval,
      selectedPlanId,
    ],
  );

  return (
    <Container
      animation="slideUp"
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!url}
      scroll="body"
    >
      {info && (
        <Content>
          <Title>
            {isCorporate ? 'Group' : 'Individual'}{' '}
            {selectedPlanInterval === 'year' ? 'annual' : 'monthly'}{' '}
            subscription
          </Title>
          <Paragraph>
            Your current plan is{' '}
            <strong>
              {isCurrentlyCorporate ? 'Group - ' : 'Individual - '}$
              {info.plan?.amount && info.plan?.amount / 100} US{' '}
              {info.plan?.interval
                ? isCurrentlyCorporate
                  ? `per ${info.plan?.interval}/per seat`
                  : `per ${info.plan?.interval}`
                : 'lifetime'}
            </strong>{' '}
            and you want to change to{' '}
            <strong>
              {isCorporate ? 'Group - ' : 'Individual - '} ${selectedPlanAmount}{' '}
              US{' '}
              {selectedPlanInterval !== null
                ? isCorporate
                  ? `per ${selectedPlanInterval}/per seat`
                  : `per ${selectedPlanInterval}`
                : 'lifetime'}
            </strong>{' '}
            subscription.
          </Paragraph>
          <Form
            onSubmit={onSubmit}
            isCurrentlyCorporate={isCurrentlyCorporate}
            isCorporate={isCorporate}
          />
        </Content>
      )}
    </Container>
  );
};

export default connect(UpdatePaymentMethodModal);
