import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { getCourses } from './actions';
import { CoursesAction, CoursesState } from './types';

export const INITIAL_STATE: CoursesState = {
  loadingCourses: false,
  courses: [],
};

const reducer: Reducer<CoursesState, CoursesAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getCourses.failure): {
      return {
        ...state,
        loadingCourses: false,
        courses: [],
      };
    }
    case getType(getCourses.request): {
      return {
        ...state,
        loadingCourses: true,
      };
    }
    case getType(getCourses.success): {
      return {
        ...state,
        loadingCourses: false,
        courses: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
