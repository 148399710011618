import { Link as DefaultLink, Modal as DefaultModal, Tag } from 'components';
import {
  CloseButtonContainer as Close,
  Content as ModalContent,
} from 'components/Modal/styles';
import styled, { css } from 'styled-components';
import { from, prettyScrollbar } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Modal = styled(DefaultModal)<{ $withChat: boolean }>`
  position: relative;

  ${({ $withChat }) => css`
    ${from.mobileXL`
      max-width: ${$withChat ? `73.3rem` : `56.25rem`};
      margin: 0 auto;
    `};
  `}

  .MuiPaper-root {
    color: ${({ theme }) => theme.colors.black};
    box-shadow: -0.8125rem 0.8125rem 1.875rem -1.4375rem rgba(112, 111, 111, 0.3);
    border: 0;
    width: 100%;
    min-height: 747px;

    ${from.mobileXL`
      max-height: 900px;
      border-radius: 0.25rem;
    `};

    @media screen and (max-height: 760px) and (min-width: 768px) {
      height: 100%;
      overflow-y: auto;
    }

    ${({ $withChat }) =>
      $withChat &&
      css`
        ${from.tablet`
          transform: translate(-8px);
          margin: 0;
        `}
      `};
  }

  ${ModalContent} {
    display: flex;
    flex: 1;

    flex-direction: column;

    ${from.mobileXL`
    flex-direction: row;
  `}

    ${prettyScrollbar({ vertical: true })};
  }

  ${Close} {
    width: 3rem;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    height: 3rem;
    border-radius: 0.125rem;
    background-color: ${({ theme }) => addAlpha(theme.colors.grey400, 0.5)};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    display: flex;
    z-index: 1;

    ${({ $withChat }) =>
      $withChat &&
      css`
        ${from.mobileXL`
          display: none;
        `}
      `};

    > svg {
      width: 0.875rem;
      height: 0.875rem;
      margin: auto;
    }
  }
`;

export const VideoAndInfoContainer = styled.div`
  flex: 2;
`;

export const LiveChat = styled.iframe.attrs({
  scrolling: 'no',
})`
  border: none;
  flex: 1;
  padding-bottom: 8px;
`;

export const Container = styled.div`
  padding: 1.5rem;
  padding-top: 2rem;

  ${from.mobileXL`
    padding: 2.5rem;
  `}
`;

export const VideoContainer = styled.div`
  max-height: 31.75rem;
  min-height: 13rem;
  background-color: #000;
  display: flex;
  align-items: center;

  ${from.tablet`
    min-height: 25rem;
  `}
`;

export const Assignment = styled(DefaultLink)`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.accent};
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.black};
  margin: 0.5rem auto 0 0;
  padding-right: 1rem;
`;

export const MainInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  margin-bottom: 0.9375rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CategoryTag = styled(Tag)`
  background-color: ${({ theme }) => theme.colors.basicGold};
  color: ${({ theme }) => theme.colors.judge};
`;
