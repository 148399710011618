import { RootState, Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ExerciseResponseState } from './types';

const selectState: Selector<ExerciseResponseState> = (state) =>
  state.exerciseResponse;

export const selectExerciseResponseById = (state: RootState) =>
  state.exerciseResponse.byId;

export const selectExerciseResponseBySlug = (state: RootState) =>
  state.exerciseResponse.bySlug;

export const selectExerciseResponseLoading = (state: RootState) =>
  state.exerciseResponse.loading;

export const selectSubmissions = createSelector(selectState, ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((it): it is NonNullable<typeof it> => !!it),
);

export const selectLatestSubmissions = createSelector(
  selectSubmissions,
  (submissions) => submissions.slice(0, 3),
);
