import { DateInput as BaseDateInput, Select } from 'components';
import styled, { css } from 'styled-components';

export const Container = styled.form`
  margin: auto;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Row = styled.div`
  display: flex;

  > * {
    flex: 1;
    min-width: 7rem;

    :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.md};
    }
  }
`;

export const SelectShowDate = styled(Select)`
  flex: initial;
`;

export const DateInput = styled(BaseDateInput)<{ show: boolean }>`
  flex: 1;
  max-width: 230px;
  width: 100%;

  ${({ show }) =>
    !show &&
    css`
      visibility: hidden;
    `};
`;

export const Warning = styled.p`
  background-color: ${({ theme }) => theme.colors.grey200};
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  padding: 20px;
`;
