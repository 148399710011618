import ThumbsUp from 'components/ThumbsUp';
import React, { FC, memo, useCallback } from 'react';
import { formatElapsedTime } from 'utils/dates';
import {
  Actions,
  Avatar,
  Container,
  Content,
  Image,
  Info,
  Overlay,
  ThumbsUpButton,
  TrashIcon,
  TrashRipple,
  Video,
} from './styles';
import { Props } from './types';

const GalleryImage: FC<Props> = ({
  avatar,
  created,
  hasAssociatedContent,
  id,
  image,
  imageAlt = '',
  isVoted,
  isMe,
  onAddVote,
  onDelete: parentOnDelete,
  onRemoveVote,
  userType,
  to,
}) => {
  const addVote = useCallback(() => {
    onAddVote(id);
  }, [id, onAddVote]);

  const onDelete = useCallback(() => {
    if (parentOnDelete) {
      parentOnDelete(id, !!hasAssociatedContent);
    }
  }, [hasAssociatedContent, id, parentOnDelete]);

  const removeVote = useCallback(() => {
    onRemoveVote(id);
  }, [id, onRemoveVote]);

  return (
    <Container>
      <Content to={to}>
        {image && !image.includes('video/') && (
          <Image src={image} alt={imageAlt} />
        )}
        {image && image.includes('video/') && <Video src={image} />}
      </Content>
      <Overlay>
        <Info>
          <Avatar variant={userType} src={avatar} />
          {formatElapsedTime(created)}
        </Info>
        <Actions>
          {parentOnDelete && isMe && (
            <ThumbsUpButton onClick={onDelete} aria-label="Delete">
              <TrashRipple show={false}>
                <TrashIcon />
              </TrashRipple>
            </ThumbsUpButton>
          )}
          <ThumbsUpButton
            onClick={isVoted ? removeVote : addVote}
            aria-label={isVoted ? 'Remove like' : 'Add like'}
          >
            <ThumbsUp isVoted={isVoted} />
          </ThumbsUpButton>
        </Actions>
      </Overlay>
    </Container>
  );
};

export default memo(GalleryImage);
