import { Button } from 'components';
import Rotate from 'components/icons/Rotate';
import React, { FC, memo, useCallback, useState } from 'react';
import { handleRotate } from './logic';
import {
  ActionZone,
  Container,
  ImageContainer,
  ImagePreview,
  Title,
} from './styles';
import { Props } from './types';

const ImageEditor: FC<Props> = ({ file, onSave, ...rest }) => {
  const [angle, setAngle] = useState(0);
  const rotate = useCallback(() => setAngle(angle + 90), [angle, setAngle]);
  const rotateLeft = useCallback(() => setAngle(angle - 90), [angle, setAngle]);
  const handleOnSave = useCallback(() => handleRotate(file, angle, onSave), [
    file,
    angle,
    onSave,
  ]);

  return (
    <Container {...rest}>
      <Title>Edit your photo</Title>
      <ImageContainer>
        <ImagePreview src={file} $angle={angle} alt="Preview of your photo" />
      </ImageContainer>
      <ActionZone>
        <Button onClick={rotateLeft}>
          <Rotate />
        </Button>
        <Button onClick={rotate}>
          <Rotate />
        </Button>
        <Button onClick={handleOnSave}>Save changes</Button>
      </ActionZone>
    </Container>
  );
};

export default memo(ImageEditor);
