import { LinkProps } from 'react-router-dom';

export const isExternalUrl = (url?: LinkProps['to']) => {
  if (typeof url === 'undefined') {
    // If the url is undefined, render an "a". We might be wanting to render an
    // a for other purposes than navigation.
    return true;
  }

  if (typeof url === 'string') {
    return url.startsWith('http') || url === '#';
  }

  return false;
};
