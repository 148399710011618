import fold_1x_png from 'assets/images/premium/fold.png';
import fold_3x_webp from 'assets/images/premium/fold_3x.webp';
import { Fold as DefaultFold } from 'components';
import { Description as FoldDescription } from 'components/Fold/styles';
import { Check, Container as Feature } from 'components/Pricing/Feature/styles';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

const CTA_OVERLAP = '8.5rem';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

export const Fold = styled(DefaultFold)<{ isPremium: boolean }>`
  ${({ isPremium }) =>
    !isPremium &&
    css`
      margin-bottom: ${CTA_OVERLAP};
    `};
  ${FoldDescription} {
    max-width: 52.75rem;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  flex: 1;
  padding: ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop}
  `};
`;

export const Content = styled.div`
  ${container};
  padding: 0 0 6.875rem;

  ${from.mobile`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile} 6.875rem;
  `};
`;

const gradient = css`
linear-gradient(
  ${({ theme }) => theme.colors.primary},
  ${({ theme }) => addAlpha(theme.colors.accent, 0.6)}
)
`;

export const CTA = styled.div<{ webpSupported: boolean }>`
  align-items: center;
  background-position-y: top;
  background-size: cover;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin-top: -${CTA_OVERLAP};
  padding: 3.5rem 0;
  width: 100%;

  ${({ webpSupported }) =>
    webpSupported
      ? css`
          background-image: ${gradient}, url('${fold_3x_webp}');
        `
      : css`
          background-image: ${gradient}, url('${fold_1x_png}');
        `};
`;

export const CTATitle = styled.h3`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    font-size: 1.75rem;
  `}
`;

export const Features = styled.ul`
  list-style: none;
  margin: 1.5rem 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.75rem;
  grid-row-gap: 0.5rem;

  ${from.tablet`
    margin: 2rem auto 0;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 1fr 1fr;
  `}
  ${from.laptop`
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr 1fr;
  `}

  ${Feature} {
    ${Check} {
      width: 1.25rem;
    }
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 28px;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 3.75rem 0 1.25rem;
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 0 0.5rem;

  a {
    color: ${({ theme }) => theme.colors.accent};
    font-weight: ${({ theme }) => theme.weights.bold};
    text-decoration: none;
  }
  ${from.tablet`
  font-size: 1.25rem;
  `}
`;

export const Hours = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: -${({ theme }) => theme.spacing.md};

  > * {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;
