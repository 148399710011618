import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { randomId } from 'utils/randomId';

const subtitle = css`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1.14px;
  line-height: 0.88;
  opacity: 0.8;
  text-transform: uppercase;
`;

export const ResultsSubtitle = styled.h3`
  ${subtitle};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  :first-of-type {
    margin-top: 0;
  }

  :last-of-type {
    margin-top: 3rem;
  }
`;

export const SubNavigation = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
`;

const active = `active-${randomId()}`;

export const SubNavLink = styled(NavLink).attrs({
  activeClassName: active,
})`
  ${subtitle};
  padding: ${({ theme }) => theme.spacing.xsm} 0;
  text-decoration: none;

  &:not(.${active}) {
    opacity: 0.5;
  }
`;

export const Separator = styled.span`
  background-color: ${({ theme }) => theme.colors.grey300};
  width: 1px;
`;
