import RawText from 'components/RawText';
import React, { FC, memo } from 'react';
import { Action, Avatar, Container, Link, Text } from './styles';
import { Props } from './types';

const ActivityCard: FC<Props> = ({
  action,
  authorType,
  avatar,
  object,
  objectLink,
  subject,
  subjectLink,
  ...rest
}) => (
  <Container {...rest}>
    <Avatar variant={authorType} src={avatar} />
    <Text>
      <Link to={subjectLink}>{subject}</Link>
      <Action>{action}</Action>
      <Link to={objectLink}>
        <RawText content={object} />
      </Link>
    </Text>
  </Container>
);

export default memo(ActivityCard);
