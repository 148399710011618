import { connect } from 'react-redux';
import { resetPassword } from 'redux/modules/auth';

const mapDispatchToProps = {
  setPassword: resetPassword.request,
};

export default connect(null, mapDispatchToProps);

export type ConnectedProps = typeof mapDispatchToProps;
