import { Card, CardGrid, Loader, Pagination } from 'components';
import { getFeedbackRequestStatusList, getUserName } from 'model';
import React, { FC } from 'react';
import { getUserType } from 'utils/getUserType';
import { imageToMedia } from 'utils/imageToMedia';
import {
  ArrowLink,
  Link,
  Results,
  ResultsHeader,
  ResultsTitle,
} from '../../styles';
import connect from './connect';
import { Props } from './types';

const Feedback: FC<Props> = ({
  available,
  count,
  isDetail,
  items,
  loading,
  location: { search },
  match: { url },
  page,
  pageCount,
  setPage,
  ...rest
}) => {
  if (!available && !loading) {
    return null;
  }

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>feedback {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: feedback"
            to={{
              search,
              pathname: `${url}/feedback`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <CardGrid>
            {items.map((feedbackRequest) => (
              <Link
                target="_blank"
                key={feedbackRequest.id}
                to={`/feedback/${feedbackRequest.id}`}
              >
                <Card
                  authorAvatar={feedbackRequest.user.avatar}
                  authorName={getUserName(feedbackRequest.user)}
                  authorType={getUserType(feedbackRequest.user)}
                  comments={feedbackRequest.commentsCount}
                  datapoints={feedbackRequest.votesCount}
                  lastCommentDate={
                    feedbackRequest.lastComment
                      ? feedbackRequest.lastComment
                      : undefined
                  }
                  statuses={getFeedbackRequestStatusList(feedbackRequest)}
                  tags={feedbackRequest.tags}
                  thumbnail={
                    feedbackRequest.imageList
                      ? imageToMedia(feedbackRequest.imageList.mainImage)
                      : feedbackRequest.imageList
                  }
                  title={feedbackRequest.title}
                  views={feedbackRequest.hits}
                />
              </Link>
            ))}
          </CardGrid>
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Feedback);
