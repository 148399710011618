import { connect } from 'react-redux';
import { editConversation } from 'redux/modules/conversation';
import { RootState } from 'redux/modules/types';
import { openConfirmationModal } from 'utils/openConfirmationModal';
import { OwnProps } from './types';

const mapStateToProps = (_: RootState, __: OwnProps) => ({});

const mapDispatchToProps = {
  openConfirmationModal,
  editConversation: editConversation.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
