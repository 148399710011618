import { useMediaQuery } from '@material-ui/core';
import { oneLine } from 'common-tags';
import { Loader } from 'components';
import ChallengeSubmissionView from 'containers/Common/ChallengeSubmissionView';
import { getUserName } from 'model';
import React, { FC, useCallback, useEffect } from 'react';
import { from } from 'styles/mixins';
import { getUserType } from 'utils/getUserType';
import { up } from 'utils/Paths';
import connect from './connect';
import { Modal } from './styles';
import { Props } from './types';

const Preview: FC<Props> = ({
  challengeSlug,
  getChallengeResponse,
  loading,
  push,
  submission,
  submissionSlug,
  url,
}) => {
  const fullScreen = !useMediaQuery(from.tablet.query);

  useEffect(() => {
    if (challengeSlug && submissionSlug) {
      getChallengeResponse({
        challengeSlug,
        challengeResponseSlug: submissionSlug,
      });
    }
  }, [challengeSlug, getChallengeResponse, submissionSlug]);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  return (
    <Modal
      animation="fade"
      fullScreen={fullScreen}
      onClose={onClose}
      open={!!submissionSlug}
      scroll={undefined}
    >
      {submission && url ? (
        <ChallengeSubmissionView
          authorAvatar={submission.user.avatar}
          authorId={submission.user.id}
          authorType={getUserType(submission.user)}
          authorName={getUserName(submission.user)}
          authorSlug={submission.user.slug}
          comments={submission.commentsCount}
          created={submission.created}
          description={submission.description}
          id={submission.id}
          images={
            submission.mediaList
              ? [
                  submission.mediaList.mainMedia.file,
                  ...submission.mediaList.extraMedia.map((media) => media.file),
                ]
              : []
          }
          isReported={submission.isReported}
          isVoted={submission.isVoted}
          open={!!submissionSlug}
          scroll={true}
          shareButtonMessage={oneLine`Thought this was interesting from the SWD
          community. Check it out! - ${submission.title}: `}
          tags={submission.tags}
          title={submission.title}
          titleClickable={true}
          url={`/challenges/${challengeSlug}/${submission.slug}`}
          views={submission.hits}
          votes={submission.votesCount}
        />
      ) : (
        loading && <Loader />
      )}
    </Modal>
  );
};

export default connect(Preview);
