import React, { FC, forwardRef, RefObject } from 'react';
import { Container } from './styles';
import { Props } from './types';

export const BaseMenu = forwardRef<HTMLDivElement, Props>(
  ({ className, children, innerProps }, ref) => (
    <Container className={className} ref={ref} {...innerProps}>
      {children}
    </Container>
  ),
);

// react-select actually passes us an innerRef, even though the typings don't
// document it.
const Menu: FC<Props & { innerRef: RefObject<HTMLDivElement> }> = ({
  children,
  innerRef,
  ...rest
}) => (
  <BaseMenu ref={innerRef} {...rest}>
    {children}
  </BaseMenu>
);

export default Menu;
