import { reorder } from 'utils/reorder';

export const reorderMultidimensional = <T>(
  list: ReadonlyArray<ReadonlyArray<T>>,
  startArrayIndex: number,
  startElementIndex: number,
  endArrayIndex: number,
  endElementIndex: number,
): T[][] => {
  if (startArrayIndex === endArrayIndex) {
    return list.map((array, i) =>
      i === startArrayIndex
        ? reorder(array, startElementIndex, endElementIndex)
        : [...array],
    );
  }

  const newList = [...list].map((row) => [...row]);

  const [element] = newList[startArrayIndex].splice(startElementIndex, 1);
  newList[endArrayIndex].splice(endElementIndex, 0, element);

  return newList;
};
