import {
  BooleanInput,
  ConnectedSearchInput as DefaultSearchInput,
  OrderDirection as DefaultOrderDirection,
  Select as DefaultSelect,
} from 'components';
import { Input as SearchInputInput } from 'components/ConnectedSearchInput/styles';
import styled from 'styled-components';
import { from, visuallyHidden } from 'styles/mixins';

export const Container = styled.form`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xlg};

  ${from.tablet`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
    justify-content: space-between;
  `};
`;

export const SearchInput = styled(DefaultSearchInput)`
  margin-bottom: 1rem;

  ${SearchInputInput} {
    width: 100%;
  }

  ${from.tablet`
    max-width: 12.5rem;
    margin-bottom: 0;
  `}
`;

export const CourseSelect = styled(DefaultSelect)`
  grid-column: 1/3;
  border-radius: 0.25rem;
  margin-bottom: 0;
  width: 100%;

  ${from.mobileXL`
    grid-column: 1/2;
  `};

  ${from.tablet`
    max-width: 15rem;
  `};

  > * > * {
    :hover {
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
    }
  }
`;

export const Order = styled.div`
  display: flex;
  grid-column: 1/3;
  justify-content: flex-start;
  width: 100%;

  ${from.tablet`
    justify-content: flex-end;
    margin-left: 0;
    width: fit-content;
  `};
`;

export const OrderSelect = styled(DefaultSelect)`
  border-radius: 0.25rem;
  margin-bottom: 0;
  flex: 1;

  ${from.tablet`
    width: 9rem;
  `};

  > * > * {
    :hover {
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
    }
  }
`;

export const OrderBooleanInput = styled(BooleanInput)`
  height: 2.75rem;
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.spacing.sm};
`;

export const OrderDirection = styled(DefaultOrderDirection)`
  height: 2.75rem;

  :hover {
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }
`;

export const VisuallyHiddenLabel = styled.label`
  ${visuallyHidden};
`;
