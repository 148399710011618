import { ChevronIcon as DefaultChevron } from 'components/icons';
import styled from 'styled-components';
import { from, whiteBox } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 1rem 0;
  align-items: center;
  ${from.mobileXL`
    align-items: flex-start;
  `}

  span {
    align-items: center;
    display: flex;
    margin: 0 0.3rem 0.8rem;

    ${from.laptop`
      margin: 0 0.65rem 0 0;
    `}

    :last-child {
      pointer-events: none;
      margin-bottom: 0rem;

      > svg {
        display: none;
      }
    }
  }

  ${from.laptop`
    padding: 1.25rem 0;
  `}
`;

export const ContainerFinalBreadcrumb = styled.div`
  margin-left: 0.3rem;
  // Only show the last element and show more text (responsive)
  span {
    :not(:last-child) {
      display: none;
    }
    :last-child {
      a {
        max-width: inherit;
        white-space: normal;
      }
      ${from.mobileXL`
        a {
          max-width: 12.5rem;
          white-space: nowrap;
        }
      `}
    }
  }
`;

export const ContainerFirstBreadcrumb = styled.div``;

export const ChevronIcon = styled(DefaultChevron)`
  margin-left: 0.65rem;
  width: 9px;
  color: ${({ theme }) => theme.colors.accent};
`;

export const ChevronDownIcon = styled(DefaultChevron)`
  width: 12px;
  color: ${({ theme }) => theme.colors.accent};
  transform: rotate(90deg);
  margin-left: 1px;
`;

export const ResponsiveButtonContainer = styled.div`
  ${whiteBox}
  display: flex;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`;

export const DropdownContainer = styled.div`
  ${whiteBox}
  position: absolute;
  z-index: 1;
  margin-top: 0.3rem;
  >:first-child {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0.3rem 0.4rem 0.2rem;
    ${from.laptop`
    flex-direction: row;
    `}
  }
  ${from.laptop`
    flex-direction: row;
    position: static;
    box-shadow: none;
    border: none;
   `}
  }
  span {
    // Hide chevron icon in responsive
    svg {
      display: none;
      ${from.laptop`
          display: flex;
        `}
    }
    // Hide HomeLogo icon in responsive
    :first-child {
      display: none;
      align-items: flex-start;
      ${from.laptop`
      display: flex;
      `}
      a {
        margin-top: -3px;
      }
    }
    // Hide last element in dropdown content (responsive)
    :last-child {
      margin-bottom: 0.8rem;
      ${from.laptop`
        margin-bottom: 0;
      `}
      > * {
        display: none;
        ${from.laptop`
        display: inline-block;
        color: ${({ theme }) => theme.colors.grey400};
        font-weight: ${({ theme }) => theme.weights.bold};
        `}
      }
    }
  }
`;
