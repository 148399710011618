import type { EditorState } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import { useMemo } from 'react';

const PADDING = 8;
const CORRECTION = 16;

/**
 * Returns coordinates for an element to anchor to the editor selection.
 */
export const useSelectionAnchor = ({
  editorView,
  state,
}: {
  editorView: EditorView;
  state: EditorState;
}) =>
  useMemo(() => {
    /*
     * These positions are relative to the viewport. We could simply use them
     * with "position: fixed", but that would not work if the user scrolled as
     * the positioned element would remain anchored to the same position while
     * the coordinates would change.
     *
     * A naive implementation would just update these coords on scroll. While
     * that would work, it would be slow and would not make for a smooth scroll
     * effect.
     */
    const from = editorView.coordsAtPos(state.selection.from);
    const to = editorView.coordsAtPos(state.selection.to);

    /*
     * Therefore, we bring the editor's position relative to the viewport...
     */
    const box = editorView.dom.getBoundingClientRect();

    /*
     * ...to compute the position of the selection relative to the editor, not
     * to the viewport. This way we can absolutely position the element and let
     * the browser handle scrolling logic.
     */
    const relativeLeft = from.left - box.left;
    const relativeTop = from.top - box.top;

    /*
     * We want to position the element below the selection, not on top of it, so
     * we compute the dimensions of the selection box and adjust our current
     * coordinates based on it.
     */
    const width = to.left - from.left;
    const height = to.bottom - from.top;
    const adjustedLeft = relativeLeft + width / 2;
    const adjustedTop = relativeTop + height;

    /*
     * On the Y axis, we apply a PADDING to separate a bit the positioned
     * element from the selection.
     *
     * We also apply a CORRECTION to make this logic work perfectly. This
     * correction fixes a vertical gap created because the relativeTop is
     * actually a bit higher than the top of the selection. To be honest...
     * I have no idea why this happens, but it works fine with the CORRECTION
     * applied (which is a value that I determined by trial and error).
     */
    const left = adjustedLeft;
    const top = adjustedTop + PADDING + CORRECTION;

    return { left, top };
  }, [editorView, state]);
