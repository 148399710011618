import React, { FC } from 'react';
import {
  Container,
  Content,
  Description,
  Image,
  JumbotronInfo,
  Title,
} from './styles';
import { Props } from './types';

const Jumbotron: FC<Props> = ({ children, title, img, ...rest }) => (
  <Container {...rest}>
    <Content hasImage={!!img}>
      <JumbotronInfo>
        <Title>{title}</Title>
        <Description>{children}</Description>
      </JumbotronInfo>
      {img && <Image src={img} alt="" aria-hidden="true" />}
    </Content>
  </Container>
);

export default Jumbotron;
