import overview425png from 'assets/images/premiumCTA/Videos.png';
import overview425webp from 'assets/images/premiumCTA/Videos.webp';
import overview850png from 'assets/images/premiumCTA/Videos@2x.png';
import overview850webp from 'assets/images/premiumCTA/Videos@2x.webp';
import overview1275png from 'assets/images/premiumCTA/Videos@3x.png';
import overview1275webp from 'assets/images/premiumCTA/Videos@3x.webp';
import React, { FC, memo } from 'react';
import {
  Button,
  Container,
  Description,
  OverviewImage,
  OVERVIEW_IMAGE_WIDTH,
  TextContent,
  Title,
} from './styles';
import { Props } from './types';

const PremiumCTA: FC<Props> = ({ buttonName, buttonTo }) => (
  <Container>
    <TextContent>
      <Title>
        Learn with the <span>SWD team</span>
      </Title>
      <Description>
        In addition to practicing, seeing tactics and strategies illustrated is
        an excellent way to expand your knowledge. Premium membership grants you
        access to our growing collection of video content.
      </Description>
      <Button variant="premiumAccent" to={buttonTo}>
        {buttonName}
      </Button>
    </TextContent>
    <picture>
      <source
        sizes={OVERVIEW_IMAGE_WIDTH}
        srcSet={`
          ${overview425webp} 425w,
          ${overview850webp} 850w,
          ${overview1275webp} 1275w
        `}
        type="image/webp"
      />
      <source
        sizes={OVERVIEW_IMAGE_WIDTH}
        srcSet={`
          ${overview425png} 425w,
          ${overview850png} 850w,
          ${overview1275png} 1275w
        `}
        type="image/png"
      />
      <OverviewImage src={overview425png} alt="Preview video collection" />
    </picture>
  </Container>
);

export default memo(PremiumCTA);
