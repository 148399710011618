import React, { FC, useEffect } from 'react';
import { Switch as DefaultSwitch } from 'react-router-dom';
import { waitForElement } from 'utils/waitForElement';
import { Props } from './types';

const Switch: FC<Props> = ({ action, location, ...rest }) => {
  const pathname = location?.pathname ?? '';
  const { scrollToElement, scrollToTop = true } = location?.state ?? {};

  const scrollTo = location?.hash || scrollToElement;

  useEffect(() => {
    (async () => {
      // If the user went back, preserve their previous scroll position
      if (action === 'POP') {
        return;
      }

      if (scrollTo) {
        const id = scrollTo.replace('#', '');
        const element = await waitForElement(id);

        if (element) {
          element.scrollIntoView();
        }
      } else {
        // Use setTimeout to ensure that the scroll is made when the content
        // is rendered.
        setTimeout(() => {
          if (scrollToTop) {
            window.scrollTo(0, 0);
          }
        });
      }
    })();
  }, [action, pathname, scrollTo, scrollToTop]);

  return <DefaultSwitch location={location} {...rest} />;
};

export default Switch;
