import defaultAvatar from 'assets/images/defaultAvatar.png';
import { Button } from 'components';
import React, { FC, useCallback } from 'react';
import { up } from 'utils/Paths';
import {
  Section,
  SubscriptionContainer,
  Table,
} from '../SubscriptionRoot/styles';
import { useConnect } from './connect';
import {
  EmptyMembersContainer,
  EmptyMembersIcon,
  EmptyMembersText,
  EmptyMembersTitle,
  Title,
  TitleRow,
} from './styles';
import SubscriptionSeatRow from './SubscriptionSeatRow';
import { Props } from './types';

const SubscriptionSeats: FC<Props> = () => {
  const { url, members, deleteMember, resendInvite, quantity } = useConnect();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleDeleteMember = useCallback(
    (memberId: string) => {
      deleteMember({ memberId });
    },
    [deleteMember],
  );

  const handleResendInvite = useCallback(
    (email: string) => {
      resendInvite({ email });
    },
    [resendInvite],
  );

  const openDropdown = (
    event: React.MouseEvent<HTMLDivElement | HTMLElement, MouseEvent>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };
  const closeDropdown = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <SubscriptionContainer>
      <Section>
        <TitleRow>
          <Title>
            Members {members?.length}/{quantity}
          </Title>
          {members && members.length === quantity ? (
            <Button
              to={{
                pathname: `${up(url)}/add-extra-seats`,
                state: {
                  scrollToTop: false,
                },
              }}
              variant="secondary"
            >
              Get extra seats
            </Button>
          ) : (
            <Button
              to={{
                pathname: `${url}/add-member`,
                state: {
                  scrollToTop: false,
                },
              }}
              variant="secondary"
            >
              Add Member
            </Button>
          )}
        </TitleRow>
        {members && members.length > 0 ? (
          <Table>
            {members.map((member) => (
              <SubscriptionSeatRow
                key={member.email}
                avatar={member.avatar ?? defaultAvatar}
                name={member.fullName ?? 'Member'}
                email={member.email}
                status={member.status}
                onDelete={handleDeleteMember}
                resendInvite={handleResendInvite}
                id={member.id}
                openDropdown={openDropdown}
                closeDropdown={closeDropdown}
                anchorEl={anchorEl}
              />
            ))}
          </Table>
        ) : (
          <EmptyMembersContainer>
            <EmptyMembersIcon />
            <EmptyMembersTitle>
              You haven’t added any members yet.
            </EmptyMembersTitle>
            <EmptyMembersText>
              Add them so they can start enjoying our community!
            </EmptyMembersText>
          </EmptyMembersContainer>
        )}
      </Section>
    </SubscriptionContainer>
  );
};

export default SubscriptionSeats;
