import BaseInput from 'components/BaseInput';
import styled from 'styled-components';

export const Container = styled(BaseInput)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white};

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;

export const Label = styled.label``;
