import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { editSettings, getSettings } from './actions';
import { SettingsAction, SettingsState } from './types';

export const INITIAL_STATE: SettingsState = {
  loading: false,
  settings: {
    newBlog: false,
    newPodcast: false,
    newWorkshop: false,
    newChallenge: false,
    newExercise: false,
    newCommentsMyConversation: false,
    newCommentsMyFeedback: false,
    newCommentsMyChallengeResponse: false,
    newCommentsMyExerciseResponse: false,
    newCommentsOnMyComment: false,
    newMention: false,
  },
};

const reducer: Reducer<SettingsState, SettingsAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getSettings.request):
      return {
        ...state,
        loading: true,
      };
    case getType(getSettings.success):
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    case getType(getSettings.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(editSettings.request):
      return {
        ...state,
        loading: true,
      };
    case getType(editSettings.success):
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
    case getType(editSettings.failure):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
