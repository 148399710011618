import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { getExercises, selectExercises } from 'redux/modules/exercise';
import { selectLatestSubmissions } from 'redux/modules/exerciseResponse';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  activeExercise: state.exercise.active
    ? state.exercise.active
    : selectExercises(state)[0],
  latestSubmissions: selectLatestSubmissions(state),
  loading: state.exercise.loading,
  pageCount: state.exercise.pageCount,
  pastExercises: selectExercises(state),
  wentBack: selectUserWentBack(state),
  isPremium: selectUserIsPremium(state),
});

const mapDispatchToProps = {
  getExercises: getExercises.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
