import { nanoid } from 'nanoid/non-secure';
import {
  InputRule,
  inputRules as baseInputRules,
  wrappingInputRule,
} from 'prosemirror-inputrules';
import { MarkType, NodeType, Slice } from 'prosemirror-model';
import type { Schema } from '../schema';

export const blockQuoteRule = (nodeType: NodeType<Schema>) =>
  wrappingInputRule(/^\s*>\s$/, nodeType);

const emStrongRule = (schema: Schema) =>
  new InputRule(/\*.+\*$/, (state, match, start, end) => {
    const input = match[0];
    const tr = state.tr;

    if (input.substr(0, 2) === '**') {
      if (input.substr(input.length - 2, input.length) !== '**') {
        // We have **TEXT or **TEXT*. Allow the user to continue typing.
        return null;
      }

      // We have **TEXT**. Make it strong and remove the asterisks.
      tr.addMark(start, end, schema.mark(schema.marks.strong));
      tr.replace(start, end, tr.doc.slice(start + 2, end - 1));
    } else if (input.substr(0, 1) === '*') {
      // We have *TEXT*. Make it em and remove the asterisks.
      tr.addMark(start, end, schema.mark(schema.marks.em));
      tr.replace(start, end, tr.doc.slice(start + 1, end));
    }

    return tr;
  });

const linkRule = (linkMarkType: MarkType<Schema>) =>
  new InputRule(/\[(.+)]\((.+)\)$/, (state, match, start, end) => {
    const tr = state.tr;
    const [, text, href] = match;

    tr.replaceRange(start, end, Slice.empty);
    tr.insertText(text, start);
    tr.addMark(
      start,
      start + text.length,
      linkMarkType.create({
        href,
      }),
    );

    return tr;
  });

const listRule = (
  regex: RegExp,
  listNodeType: NodeType<Schema>,
  listItemNodeType: NodeType<Schema>,
  paragraphNodeType: NodeType<Schema>,
) =>
  new InputRule(regex, (state, _, start, end) => {
    const tr = state.tr;

    tr.replaceRangeWith(
      start,
      end,
      listNodeType.create(
        {},
        listItemNodeType.create({}, paragraphNodeType.create({})),
      ),
    );

    return tr;
  });

const referenceRule = (schema: Schema) =>
  new InputRule(/^.*@$/, (state, match, _, end) => {
    const input = match[0];

    // Only create a reference if the @ is preceded by a space, or is at the
    // beginning of the block. Otherwise, the user might be typing an email.
    if (![' ', undefined].includes(input[input.length - 2])) {
      return null;
    }

    const tr = state.tr;

    tr.insertText('@');
    tr.addMark(
      tr.mapping.map(end) - 1,
      tr.mapping.map(end),
      schema.marks.reference.create({
        id: nanoid(),
      }),
    );

    return tr;
  });

export default function inputRules(schema: Schema) {
  return baseInputRules({
    rules: [
      blockQuoteRule(schema.nodes.blockquote),
      emStrongRule(schema),
      linkRule(schema.marks.link),
      listRule(
        /^[*-]\s$/,
        schema.nodes.bulletList,
        schema.nodes.listItem,
        schema.nodes.paragraph,
      ),
      listRule(
        /^[0-9]+\.\s$/,
        schema.nodes.orderedList,
        schema.nodes.listItem,
        schema.nodes.paragraph,
      ),
      referenceRule(schema),
    ],
  });
}
