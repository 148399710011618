import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 16 16" {...props}>
    {children}
    <g fillRule="evenodd">
      <path d="M7.995 15.384a7.34 7.34 0 01-5.221-2.161A7.339 7.339 0 01.607 8C.605 3.925 3.917.609 7.992.607c3.89 0 7.128 3.036 7.376 6.913v.019a7.028 7.028 0 01-.058 1.507.463.463 0 01-.827.21.46.46 0 01-.087-.342c.043-.3.065-.609.065-.914h-3.693a.462.462 0 01-.023-.922v-.016a15.91 15.91 0 00-.37-2.755h-4.75a16.397 16.397 0 00-.37 2.77h2.283a.462.462 0 110 .923H5.23c0 .947.072 1.878.216 2.769h1.63a.462.462 0 010 .923H5.625c.243 1.066.584 1.967.994 2.621a6.538 6.538 0 002.294.085.464.464 0 01.52.394.462.462 0 01-.393.52 7.586 7.586 0 01-1.037.072h-.008zM2.69 11.692c.22.313.466.607.737.878a6.41 6.41 0 001.939 1.334 11.31 11.31 0 01-.692-2.212H2.69zm1.818-.923c-.133-.885-.2-1.816-.2-2.77H1.53c0 .972.21 1.903.623 2.77h2.354zm9.89-3.692a6.45 6.45 0 00-1.095-2.77h-1.978c.18.848.295 1.757.343 2.708l.003.062h2.727zm-10.068 0c.046-.978.162-1.907.344-2.77H2.69a6.453 6.453 0 00-1.094 2.77h2.733zm8.192-3.693a6.502 6.502 0 00-1.886-1.289c.172.391.326.823.46 1.29h1.426zm-2.395 0c-.21-.67-.467-1.253-.75-1.704a6.47 6.47 0 00-1.38-.15l-.003-.231v.23c-.457 0-.916.05-1.369.149-.288.456-.54 1.03-.751 1.706h4.253zm-5.223 0c.134-.466.29-.9.464-1.293A6.493 6.493 0 003.47 3.384h1.433z" />
      <path d="M13.347 15.383a.867.867 0 01-.616-.254l-1.419-1.42-.35.352a.871.871 0 01-1.453-.368L8.18 9.269A.874.874 0 019.27 8.18l4.424 1.327a.874.874 0 01.367 1.452l-.35.351 1.418 1.42a.853.853 0 01.255.617c0 .232-.09.452-.256.616l-1.163 1.164a.866.866 0 01-.618.255zm-2.035-2.788c.123 0 .24.048.326.136l1.71 1.71 1.092-1.094-1.71-1.71a.464.464 0 010-.652l.617-.617-4.256-1.277 1.277 4.257.617-.617a.467.467 0 01.327-.136z" />
    </g>
  </svg>
));
