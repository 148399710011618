import { connect } from 'react-redux';
import { editDiscoverItem, getDiscoverItem } from 'redux/modules/discover';
import { RootState } from 'redux/modules/types';
import { openConfirmationModal } from 'utils/openConfirmationModal';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const discoverId = ownProps.match?.params?.discoverId ?? '';
  const item = state.discover.byId[discoverId];
  return {
    item,
    userIsAuthor: state.auth.user?.id === item?.user.id,
  };
};

const mapDispatchToProps = {
  openConfirmationModal,
  editDiscoverItem: editDiscoverItem.request,
  getDiscoverItem: getDiscoverItem.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
