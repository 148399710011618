import { FormActions, Input } from 'components';
import { ChangePasswordFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container, InputGroup } from './styles';
import { Props } from './types';

const Form: FC<Props> = ({
  handleSubmit,
  invalid,
  loading,
  pristine,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <InputGroup>
      <Field
        autocomplete="current-password"
        component={Input}
        label="OLD PASSWORD"
        name={ChangePasswordFieldNames.oldPassword}
        placeholder="Old password"
        type="password"
        validate={required}
      />
      <div />
      <Field
        autocomplete="new-password"
        component={Input}
        label="NEW PASSWORD"
        name={ChangePasswordFieldNames.password}
        placeholder="New password"
        type="password"
        validate={required}
      />
      <Field
        autocomplete="new-password"
        component={Input}
        label="CONFIRM NEW PASSWORD"
        name={ChangePasswordFieldNames.passwordConfirm}
        placeholder="Confirm new password"
        type="password"
        validate={required}
      />
    </InputGroup>
    <FormActions
      invalid={invalid}
      loading={loading}
      pristine={pristine}
      submitFailed={submitFailed}
    />
  </Container>
);

export default connect(Form);
