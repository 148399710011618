import { Input } from 'components';
import { SignUpFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { normalizeEmail } from 'utils/normalizeEmail';
import { email, required } from 'utils/validations';
import connect from './connect';
import { Button, Container, LogInLink, LogInText, Row } from './styles';
import { Props } from './types';

const SignUpForm: FC<Props> = ({
  handleSubmit,
  invalid,
  loading,
  onLeave,
  pristine,
  search,
}) => (
  <Container onSubmit={handleSubmit}>
    <Row>
      <Field
        autocomplete="given-name"
        component={Input}
        label="First Name"
        name={SignUpFieldNames.firstName}
        placeholder="Enter your first name"
        type="text"
        validate={required}
      />
      <Field
        autocomplete="family-name"
        component={Input}
        label="Last Name"
        name={SignUpFieldNames.lastName}
        placeholder="Enter your last name"
        type="text"
        validate={required}
      />
    </Row>
    <Field
      autocomplete="username"
      component={Input}
      label="Email"
      name={SignUpFieldNames.email}
      placeholder="Enter your email"
      type="text"
      validate={[email, required]}
      normalize={normalizeEmail}
    />
    <Row>
      <Field
        autocomplete="new-password"
        component={Input}
        label="Password"
        name={SignUpFieldNames.password}
        placeholder="Enter your password"
        type="password"
        validate={required}
      />
      <Field
        autocomplete="new-password"
        component={Input}
        label="Confirm your password"
        name={SignUpFieldNames.passwordConfirm}
        placeholder="Confirm your password"
        type="password"
        validate={required}
      />
    </Row>
    <Button disabled={invalid || loading || pristine} type="submit">
      {loading ? 'Signing up...' : 'Sign up'}
    </Button>
    <LogInText>
      Already have an account?{' '}
      <LogInLink onClick={onLeave} to={{ search, pathname: '/login' }}>
        Log in
      </LogInLink>
    </LogInText>
  </Container>
);

export default connect(SignUpForm);
