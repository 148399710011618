import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

import type { PluginProps, State } from './types';

const pluginKey = new PluginKey<State>('highlight-selection');

export const highlightSelectionPlugin = ({
  highlightWhen: shouldHighlight,
}: PluginProps) =>
  new Plugin<State>({
    key: pluginKey,
    props: {
      decorations(state) {
        if (shouldHighlight(state)) {
          return pluginKey.getState(state)?.decorationSet;
        }

        return undefined;
      },
    },
    state: {
      init: () => ({ decorationSet: DecorationSet.empty }),
      apply(tr, _, __, editorState) {
        const decorationSet = DecorationSet.create(editorState.doc, [
          Decoration.inline(
            tr.selection.$from.pos,
            tr.selection.$to.pos,
            { class: 'selection-marker' },
            { inclusiveLeft: true, inclusiveRight: true },
          ),
        ]);

        return { decorationSet };
      },
    },
  });
