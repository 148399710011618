import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ExerciseState } from './types';

const selectState: Selector<ExerciseState> = (state) => state.exercise;

export const selectExerciseBySlug = createSelector(
  selectState,
  (state) => state.bySlug,
);

export const selectExercises = createSelector(selectState, ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((it): it is NonNullable<typeof it> => !!it),
);
