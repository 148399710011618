import { RootState } from 'redux/modules/types';
import { createSelector } from 'reselect';

export const selectAuthState = (state: RootState) => state.auth;

export const selectAuthLoading = createSelector(
  selectAuthState,
  (state) => state.loading,
);

export const selectUser = createSelector(
  selectAuthState,
  (state) => state.user,
);

export const selectUserId = createSelector(
  selectAuthState,
  (state) => state.user?.id,
);

export const selectUserIsPremium = createSelector(
  selectAuthState,
  (state) =>
    !!state.user?.isPremium ||
    !!state.user?.isPremiumGroupMember ||
    !!state.user?.isPremiumGroupOwner,
);

export const selectUserIsSWD = createSelector(
  selectAuthState,
  (state) => !!state.user?.swdTeam,
);

export const selectUserIsForcedPremium = createSelector(
  selectAuthState,
  (state) => !!state.user?.isForcedPremium,
);

export const selectUserIsPremiumGroupMember = createSelector(
  selectAuthState,
  (state) => !!state.user?.isPremiumGroupMember,
);

export const selectUserIsPremiumGroupOwner = createSelector(
  selectAuthState,
  (state) => !!state.user?.isPremiumGroupOwner,
);
