import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Rect } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 16 16" {...props}>
    {children}
    <g fill="none" stroke="#FFF" transform="translate(1 1)">
      <Rect fill="currentColor" width="15" height="15" x="-.5" y="-.5" rx="2" />
      <path
        fill="#FFF"
        d="M4.376 2.624h-.752v8.752h.752V2.624zm3 5h-.752v3.752h.752V7.624zm3-3h-.752v6.752h.752V4.624z"
      />
    </g>
  </svg>
));
