import differenceInSeconds from 'date-fns/fp/differenceInSeconds';
import isBefore from 'date-fns/fp/isBefore';
import { useLiveDate } from 'utils/useLiveDate';

const ONE_MINUTE = 60;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

export const useCountdown = (to: Date) => {
  const now = useLiveDate(1000);

  const isBeforeNow = isBefore(now);

  if (isBeforeNow(to)) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const secondsFromNow = differenceInSeconds(now);
  let seconds = secondsFromNow(to);

  const days = Math.floor(seconds / ONE_DAY);
  seconds -= days * ONE_DAY;

  const hours = Math.floor(seconds / ONE_HOUR);
  seconds -= hours * ONE_HOUR;

  const minutes = Math.floor(seconds / ONE_MINUTE);
  seconds -= minutes * ONE_MINUTE;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
