import { AutoScrollIntoView, HideComments } from 'components';
import Actions from 'containers/Common/PostActions';
import { getUserName } from 'model';
import React, { FC, useCallback, useMemo } from 'react';
import { COMMENTS_MAX_DEPTH } from 'utils/config';
import { formatDateAndTime } from 'utils/dates';
import { getUserType } from 'utils/getUserType';
import ReplyForm from '../../QuestionForm';
import { handleFormSubmitted } from './logic';
import {
  AnsweredTag,
  Avatar,
  Body,
  CommentData,
  CommentImage,
  Container,
  Content,
  ContentColumn,
  ContentRow,
  DotSeparator,
  Info,
  Name,
  Replies,
  SidebarColumn,
  UserInfo,
} from './styles';
import { Props } from './types';

const Comment: FC<Props> = ({
  amaParticipants,
  amaAuthor,
  authorAvatar,
  authorId,
  authorName,
  authorType,
  children,
  closed,
  content,
  contentType,
  datapoints,
  date,
  depth = 0,
  disabled,
  id,
  image,
  isReported,
  isVoted,
  loggedIn,
  objectId,
  onCreateComment,
  onToggleReplyFormOpen,
  parentId,
  replies,
  isReply,
  replyFormsOpen,
  currentUser,
  url,
  isReplyReplied,
  ...rest
}) => {
  const parentReplyFormOpen = !!parentId && !!replyFormsOpen[parentId];
  const replyFormOpen = !!replyFormsOpen[id];

  const isAnswered = !parentId && replies && replies.length > 0;

  const canReply = useMemo(() => {
    if (depth > 0) return true;
    const isAParticipant =
      !!currentUser && amaParticipants.includes(currentUser.id);
    return isAParticipant;
  }, [amaParticipants, currentUser, depth]);

  const onFormSubmitted = useCallback(
    handleFormSubmitted({ contentType, onCreateComment, id, objectId }),
    [contentType, onCreateComment, id],
  );

  const toggleReplyFormOpen = useCallback(
    () =>
      onToggleReplyFormOpen(
        parentId && depth === COMMENTS_MAX_DEPTH - 1 ? parentId : id,
      ),
    [depth, id, onToggleReplyFormOpen, parentId],
  );

  return (
    <Container {...rest}>
      <Body>
        <SidebarColumn>
          <Avatar
            to={`/members/${authorId}`}
            variant={authorType}
            src={authorAvatar}
            name={authorName}
          />
        </SidebarColumn>
        <ContentColumn>
          <CommentData
            isAuthorAmaAuthor={authorId === amaAuthor}
            hasAuthorReplied={
              replies.filter((reply) => reply.user.id === amaAuthor).length >
                0 ||
              (isReply && isReplyReplied)
            }
          >
            <UserInfo>
              <Name to={`/members/${authorId}`}>{authorName}</Name>
              <DotSeparator>·</DotSeparator>
              <Info>{formatDateAndTime(date)}</Info>
              {isAnswered && <AnsweredTag name="Answered!" />}
            </UserInfo>
            <ContentRow>
              {image && (
                <CommentImage
                  images={[image]}
                  zoomUrl={`${url}/${id}/comment-image`}
                />
              )}
              <Content content={content} />
            </ContentRow>
            <Actions
              allowEdit={loggedIn && !closed}
              authorId={authorId}
              contentType={contentType}
              id={id}
              isComment={true}
              isReported={isReported}
              isVoted={isVoted}
              replyOnClick={
                !closed && canReply && !(replyFormOpen || parentReplyFormOpen)
                  ? toggleReplyFormOpen
                  : undefined
              }
              swdTeam={authorType === 'swd'}
              url={url}
              votes={datapoints}
            />
          </CommentData>
        </ContentColumn>
        {(replies.length > 0 || replyFormOpen) && (
          <Replies>
            <HideComments>
              {replies.map((comment) => (
                <Comment
                  amaParticipants={amaParticipants}
                  amaAuthor={amaAuthor}
                  authorAvatar={comment.user.avatar}
                  authorId={comment.user.id}
                  authorName={getUserName(comment.user)}
                  authorType={getUserType(comment.user)}
                  closed={closed}
                  content={comment.comment}
                  contentType={contentType}
                  datapoints={comment.votesCount}
                  date={comment.created}
                  depth={depth + 1}
                  disabled={disabled || !currentUser}
                  id={comment.id}
                  image={comment.image}
                  isReported={comment.isReported}
                  isVoted={comment.isVoted}
                  key={comment.id}
                  loggedIn={loggedIn}
                  objectId={objectId}
                  onCreateComment={onCreateComment}
                  onToggleReplyFormOpen={onToggleReplyFormOpen}
                  parentId={id}
                  replies={comment.children}
                  isReply={true}
                  replyFormsOpen={replyFormsOpen}
                  currentUser={currentUser}
                  url={url}
                  isReplyReplied={
                    replies
                      .slice(
                        replies.findIndex((it) => it.id === comment.id) + 1,
                      )
                      .filter((reply) => reply.user.id === amaAuthor).length > 0
                  }
                />
              ))}
              {replyFormOpen && currentUser && (
                <AutoScrollIntoView>
                  <Container {...rest}>
                    <Body>
                      <SidebarColumn>
                        <Avatar
                          to={`/members/${currentUser.id}`}
                          variant={getUserType(currentUser)}
                          src={currentUser.avatar}
                          name={`${currentUser.firstName} ${currentUser.lastName}`}
                        />
                      </SidebarColumn>
                      <ReplyForm
                        id={id}
                        isCommentReply={true}
                        swdTeam={currentUser.swdTeam}
                        key="new-reply"
                        onClose={toggleReplyFormOpen}
                        onSubmit={onFormSubmitted}
                      />
                    </Body>
                  </Container>
                </AutoScrollIntoView>
              )}
            </HideComments>
          </Replies>
        )}
      </Body>
    </Container>
  );
};

export default Comment;
