import { useMediaQuery } from '@material-ui/core';
import { oneLine } from 'common-tags';
import { Loader } from 'components';
import ConversationView from 'containers/Common/ConversationView';
import { getStatuses } from 'containers/Main/Conversations/ConversationsRoot';
import { getUserName } from 'model';
import React, { FC, useCallback, useEffect } from 'react';
import { from } from 'styles/mixins';
import { getUserType } from 'utils/getUserType';
import { up } from 'utils/Paths';
import connect from './connect';
import { Modal } from './styles';
import { Props } from './types';

const Preview: FC<Props> = ({
  conversation,
  getConversation,
  id,
  loading,
  push,
  tags,
  url,
}) => {
  const fullScreen = !useMediaQuery(from.tablet.query);

  useEffect(() => {
    if (id) {
      getConversation({ id });
    }
  }, [id, getConversation]);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  return (
    <Modal
      $hasImage={!!conversation?.amaImage}
      animation="fade"
      fullScreen={fullScreen}
      onClose={onClose}
      open={!!id}
      scroll={fullScreen ? undefined : 'body'}
    >
      {conversation && url ? (
        <ConversationView
          amaEndDate={conversation.amaEndDate ?? undefined}
          amaImage={conversation.amaImage ?? undefined}
          authorAvatar={conversation.user.avatar}
          authorId={conversation.user.id}
          authorName={getUserName(conversation.user)}
          authorType={getUserType(conversation.user)}
          authorSlug={conversation.user.slug}
          closed={conversation.closed}
          comments={conversation.commentsCount}
          created={conversation.created}
          description={conversation.description}
          id={conversation.id}
          isAma={conversation.isAma}
          isSpotlight={conversation.isSpotlight}
          spotlightUsers={conversation.spotlightUsers}
          spotlightDate={conversation.spotlightDate}
          spotlightImage={conversation.spotlightImage}
          isReported={conversation.isReported}
          isVoted={conversation.isVoted}
          live={conversation.live}
          open={!!id}
          scroll={true}
          shareButtonMessage={oneLine`Thought this was interesting from the SWD
          community. Check it out! - ${conversation.title}: `}
          statuses={getStatuses(conversation, tags)}
          tags={conversation.tags}
          title={conversation.title}
          titleClickable={true}
          url={`/conversations/${conversation.id}`}
          views={conversation.hits}
          votes={conversation.votesCount}
        />
      ) : (
        loading && <Loader />
      )}
    </Modal>
  );
};

export default connect(Preview);
