import React, { FC } from 'react';
import { Props } from './types';

import { UpgradeButton } from './styles';

const ActionButton: FC<Props> = ({
  isPremiumGroupOwner,
  corporateGroupLength,
  totalSeats,
  currentUrl,
}) => {
  if (isPremiumGroupOwner) {
    if (corporateGroupLength === totalSeats) {
      return (
        <UpgradeButton
          variant={'premiumSecondary'}
          to={`${currentUrl}/add-extra-seats`}
        >
          Get extra seats
        </UpgradeButton>
      );
    }
    return (
      <UpgradeButton
        variant={'premiumSecondary'}
        to={`${currentUrl}/subscription-seats`}
      >
        Add members
      </UpgradeButton>
    );
  }
  return (
    <UpgradeButton
      variant={'premiumSecondary'}
      to={{
        pathname: `${currentUrl}/change-subscription`,
        state: {
          forceCorporateTab: true,
        },
      }}
    >
      Create a group account
    </UpgradeButton>
  );
};

export default ActionButton;
