import { FeaturedVideo } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(FeaturedVideo)``;

export const Tag = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  letter-spacing: 0.028rem;
  text-transform: uppercase;
`;

export const Title = styled.h2`
  font-size: 1.375rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;

  ${from.tablet`
    font-size: 1.75rem;
  `}
`;
