import React, { ComponentType, FC } from 'react';
import { RouteComponentProps } from 'react-router';
import { ElementProps } from 'utils/ElementProps';
import { SubSectionProps } from '../types';

export const connectSectionDetail = (
  Section: ComponentType<ElementProps<'div'> & SubSectionProps>,
): FC<ElementProps<'div'> & RouteComponentProps> => ({ ...rest }) => (
  <Section isDetail={true} {...rest} />
);
