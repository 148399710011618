import type { Mark, MarkType } from 'prosemirror-model';
import type { EditorState } from 'prosemirror-state';

import type { Schema } from 'components/Editor/schema';

/*
 * TODO This implementation is a bit naive, because there are some edge cases
 *  not covered. For example:
 *
 * - Write some text and put everything bold, then put the cursor at the
 *   beginning. The bold mark is not active because there is no "nodeBefore".
 * - Activate the bold mark, keep writing, and deactivate it. The bold mark
 *   is still active because the "nodeBefore" is bold.
 *
 * There must be a way for prosemirror to determine if the text to be written
 * will have a mark or not. We should investigate that and if possible re-use
 * the same logic.
 */
export function isMarkActive(
  markType: MarkType<Schema>,
): (state: EditorState) => boolean {
  const isOfType = (mark: Mark) => mark.type === markType;

  return (state) => {
    const nodeBeforeMarks = state.selection.$from.nodeBefore?.marks ?? [];
    const storedMarks = state.storedMarks ?? [];
    const allMarks = [...nodeBeforeMarks, ...storedMarks];

    return allMarks.some(isOfType);
  };
}
