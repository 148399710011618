import { MediaInput as DefaultMediaInput } from 'components';
import { Thumbnail } from 'components/MediaSelector/MultiFileVariant/FileList/Media/styles';
import { SingleFile } from 'components/MediaSelector/SingleFileVariant/styles';
import { Container as ImageSelectorContainer } from 'components/MediaSelector/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form``;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  }

  ${from.tabletH`
    flex-direction: row;
  `};
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${from.tabletH`
    :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.xlg};
    }
  `};

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const imageSize = css`
  height: 5rem;
  width: 5rem;

  ${from.tabletH`
    height: 10rem;
    width: 10rem;
  `};
`;

export const MediaInput = styled(DefaultMediaInput)`
  flex: 1;

  ${ImageSelectorContainer} {
    flex: 1;

    ${SingleFile} {
      ${imageSize};

      ${Thumbnail} {
        ${imageSize};
      }
    }
  }
`;
