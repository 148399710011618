import SWDTeamBadge from 'components/SWDTeamOrPremiumBadge';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div<{ swdTeamOrPremium?: boolean }>`
  align-items: center;
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  border-radius: 4px;
  display: flex;
  flex-shrink: 0;
  height: 15rem;
  justify-content: center;
  margin-bottom: 0.875rem;
  margin-right: 0;
  object-fit: cover;
  width: 15rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey300};

  ${({ swdTeamOrPremium }) =>
    swdTeamOrPremium &&
    css`
      position: relative;
    `}

  ${from.mobileXL`
    margin-right: ${({ theme }) => theme.spacing.xlg};
  `};
`;

export const Picture = styled.img`
  border-radius: 4px;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const SWDOverlayBadge = styled(SWDTeamBadge)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  ${({ big }) =>
    big &&
    css`
      width: 100%;
    `}
`;
