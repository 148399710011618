import styled from 'styled-components';

export const PlusIcon = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Border = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const MagnifyingGlass = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;
