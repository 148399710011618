import { createEditorState, parseHTML } from 'components/Editor';
import {
  GalleryEditForm,
  GALLERY_EDIT_FORM_KEY,
  visualTagInputsInitialValues,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { editId }: OwnProps) => {
  const form = GALLERY_EDIT_FORM_KEY(editId);

  const { selectIsPristine } = makeFormSelectors<GalleryEditForm>(form);

  const galleryToEdit = state.discover.byId[editId ?? ''];

  return {
    form,
    initialValues: {
      ...visualTagInputsInitialValues,
      context: createEditorState(),
      title: '',
      ...(galleryToEdit
        ? {
            context: createEditorState(parseHTML(galleryToEdit.context ?? '')),
            graphType: galleryToEdit.graphTypes,
            industry: galleryToEdit.graphIndustry,
            title: galleryToEdit.title,
            tools: galleryToEdit.graphTools,
            topic: galleryToEdit.graphTopic,
          }
        : {}),
    },
    isPristine: selectIsPristine(state),
    loading: state.challengeResponse.loading,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<GalleryEditForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<GalleryEditForm>({
    destroyOnUnmount: false,
  }),
) as FormDecorator<GalleryEditForm, OwnProps, ConnectedProps>;
