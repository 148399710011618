import { useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { selectFeedbackBySlug } from 'redux/modules/feedback';
import { getUserFeedbackResponses } from 'redux/modules/users';
import { useActions } from 'utils/useActions';
import { Props } from './types';

export const useConnect = ({ user }: { user: Props['user'] }) => {
  const { goBack } = useHistory();
  const { search } = useLocation();
  const { path, url } = useRouteMatch<{ userSlug?: string }>();

  const feedbackBySlug = useSelector(selectFeedbackBySlug);

  const actions = useActions({
    getUserFeedbackResponses: getUserFeedbackResponses.request,
  });

  return {
    ...actions,
    goBack,
    path,
    search,
    url,
    loading: user ? user.feedbackResponseSlugs.loading : false,
    pageCount: user ? user.feedbackResponseSlugs.pageCount : 0,
    items: user
      ? user.feedbackResponseSlugs.slugs
          .map((slug) => feedbackBySlug[slug])
          .filter((it): it is NonNullable<typeof it> => !!it)
      : [],
  };
};
