import DOMPurify from 'dompurify';
import React, { FC, memo } from 'react';
import { Props } from './types';

const RawText: FC<Props> = ({ content, ...rest }) => {
  const sanitized = DOMPurify.sanitize(content, { ALLOWED_TAGS: [] });

  return <span dangerouslySetInnerHTML={{ __html: sanitized }} {...rest} />;
};

export default memo(RawText);
