import { FormNameMap } from 'utils/forms';

export const SUPPORT_FORM_KEY = 'forms/SUPPORT';

export interface SupportForm {
  inquiryType: string;
  issueDescription: string;
}

export const SupportFormFieldNames: FormNameMap<SupportForm> = {
  inquiryType: 'inquiryType',
  issueDescription: 'issueDescription',
};

export * from './logic';
