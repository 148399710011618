import { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const reference = css`
  .reference {
    border-radius: 0.125rem;
    color: ${({ theme }) => theme.colors.error};

    &[data-reference-entity-type][data-reference-entity-id] {
      background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.1)};
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;
