import styled from 'styled-components';
import { title, viewContainer } from 'styles/mixins';

export const Container = styled.div`
  ${viewContainer};
  margin-top: 0;
`;

export const Title = styled.h1`
  ${title};
`;
