import React, { FC, memo, useMemo, useState } from 'react';
import HighlightedSubmission from './HighlightedSubmission';
import { Container, Face, Faces, NumberOfComments, Title } from './styles';
import { Props } from './types';

const Submissions: FC<Props> = ({
  items,
  count,
  submissionTitle,
  submissionSubtitle,
  ...rest
}) => {
  const lastSubmissionId = items && items.length && items[0].id;
  const [highlightedSubmissionId, setHighlightedSubmissionId] = useState(
    lastSubmissionId,
  );
  const highlightedSubmission =
    useMemo(
      () => items && items.find((item) => item.id === highlightedSubmissionId),
      [highlightedSubmissionId, items],
    ) ?? items[0];

  const onMouseOver = (id: string) => () => {
    setHighlightedSubmissionId(id);
  };
  const onMouseOut = () => {
    setHighlightedSubmissionId(lastSubmissionId);
  };

  return (
    <Container {...rest}>
      <Title>{submissionTitle}</Title>
      <Faces>
        <NumberOfComments>{count}</NumberOfComments>
        {items.map((item) => (
          <Face
            key={item.id}
            onMouseOver={onMouseOver(item.id)}
            onMouseOut={onMouseOut}
            src={item.avatar}
            variant={item.userType}
          />
        ))}
      </Faces>
      {highlightedSubmission && (
        <HighlightedSubmission
          created={highlightedSubmission.created}
          firstName={highlightedSubmission.firstName}
          lastName={highlightedSubmission.lastName}
          submissionSubtitle={submissionSubtitle}
        />
      )}
    </Container>
  );
};

export default memo(Submissions);
