import { Action, Reducer } from 'redux';
import { ActionAssertor } from 'utils/ActionAssertor';
import { LoadingState } from './types';

export const createLoadingReducer = <
  StateT extends LoadingState,
  ActionT extends Action
>(
  isRequest: ActionAssertor<unknown>,
  isSuccess: ActionAssertor<unknown>,
  isFailure: ActionAssertor<unknown>,
  initialState: StateT,
): Reducer<StateT, ActionT> => (state = initialState, action) => {
  if (isRequest(action)) {
    // If this is a silent request, don't set the loading state to true.
    if (
      'payload' in action &&
      typeof action.payload === 'object' &&
      action.payload !== null &&
      'silent' in action.payload
    ) {
      const payload = action.payload as { silent: unknown };

      if (payload.silent) {
        return state;
      }
    }

    return {
      ...state,
      loading: true,
    };
  }

  if (isSuccess(action) || isFailure(action)) {
    return {
      ...state,
      loading: false,
    };
  }

  return state;
};

export const defaultLoadingState = {
  loading: false,
};

export * from './types';
