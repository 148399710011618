import { goBack, push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  CHANGE_PASSWORD_FORM_KEY,
  EDIT_PROFILE_FORM_KEY,
  GROUP_CODE_FORM_KEY,
  LOGIN_FORM_KEY,
  REQUEST_RESET_PASSWORD_FORM_KEY,
  RESET_PASSWORD_FORM_KEY,
  SECONDARY_EMAIL_FORM_KEY,
  SIGN_UP_FORM_KEY,
  User,
} from 'model';
import { parse, stringify } from 'query-string';
import { stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { getConfiguration } from 'redux/modules/configuration';
import { generateIntentKey } from 'redux/modules/intent';
import { closeModal, selectSignUpModalOpen } from 'redux/modules/modal';
import { getSettings } from 'redux/modules/settings';
import { showSnackbar } from 'redux/modules/snackbar';
import { Epic, RootAction } from 'redux/modules/types';
import { merge, of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { getToken, setToken } from 'services/storage';
import { getType, isActionOf } from 'typesafe-actions';
import { createActionWaiter } from 'utils/actionWaiter';
import {
  changePassword,
  deleteAccount,
  editProfile,
  logIn,
  logOut,
  requestResetPassword,
  resetPassword,
  saveToken,
  setGroupCode,
  setSecondaryEmail,
  signUp,
  support,
  verify,
} from './actions';

interface LoginResponse {
  access: string;
  refresh: string;
  user: string;
}

interface ResetPasswordResponse {
  token: string;
}
interface SetSecondaryEmailResponse {
  token: string;
}

const INITIALIZE_ACTIONS = [generateIntentKey, getConfiguration];
const POST_LOGIN_ACTIONS = [getSettings.request()];

export const changePasswordEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(changePassword.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<{}>({
        body,
        path: 'auth/password',
        method: 'PUT',
      }).pipe(
        mergeMap(() => [
          changePassword.success(),
          showSnackbar({
            message: 'Your password has been changed',
          }),
          push('/members/me'),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              changePassword.failure(),
              stopSubmit(CHANGE_PASSWORD_FORM_KEY, err.response),
            ];
          }

          return [changePassword.failure()];
        }),
      ),
    ),
  );

export const logInEpic: Epic = (action$, state$, { fullRequest, request }) =>
  action$.pipe(
    filter(isActionOf(logIn.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<LoginResponse>({
        body,
        method: 'POST',
        path: 'auth/login',
      }).pipe(
        mergeMap(({ response: { access } }) => {
          setToken(access);

          return request<User>({
            path: 'users/me',
          });
        }),
        mergeMap((user) => [
          logIn.success(user),
          ...POST_LOGIN_ACTIONS,
          // If the user logged in from the log in modal...
          ...(selectSignUpModalOpen(state$.value)
            ? [
                // Close it
                closeModal(),
                // Remove the returnTo URL, since they didn't change their URL
                // when logging in.
                replace({
                  ...state$.value.router.location,
                  search: stringify({
                    ...parse(state$.value.router.location.search),
                    returnTo: undefined,
                  }),
                }),
                // The above replace action will also automatically dispatch
                // the intent.
              ]
            : []),
          ...(user.firstLogin
            ? [
                push({
                  search: state$.value.router.location.search,
                  pathname: '/onboarding',
                  state: {
                    onboardingAllowed: true,
                  },
                }),
              ]
            : []),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.UNAUTHORIZED) {
            return [
              logIn.failure(),
              stopSubmit(LOGIN_FORM_KEY, {
                _error: 'Email or password invalid',
              }),
            ];
          }

          if (
            err.response?.nonFieldErrors?.includes('REDIRECT_ACTIVATE_EMAIL')
          ) {
            return [
              logIn.failure(),
              push({
                pathname: 'verify-email',
                state: {
                  email: body.email,
                },
              }),
            ];
          }

          return [logIn.failure()];
        }),
      ),
    ),
  );

export const saveTokenEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(saveToken.request)),
    mergeMap(({ payload: body }) => {
      setToken(body.token);

      return request<User>({
        path: 'users/me',
      });
    }),
    mergeMap((user) => [
      saveToken.success(user),
      ...POST_LOGIN_ACTIONS,
      ...(user.firstLogin
        ? [
            push({
              pathname: '/onboarding',
              state: {
                onboardingAllowed: true,
              },
            }),
          ]
        : []),
    ]),
    catchError(() => {
      return [saveToken.failure(), push('/')];
    }),
  );

export const logOutEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(logOut.request)),
    mergeMap(() => {
      setToken(null);

      return [logOut.success(), push('/')];
    }),
  );

export const signUpEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(signUp.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<User>({
        body,
        method: 'POST',
        path: 'users',
      }).pipe(
        mergeMap(({ response: user }) => {
          return [
            signUp.success(),
            push({
              pathname: '/verify-email',
              state: {
                email: user.email,
              },
            }),
          ];
        }),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              signUp.failure(),
              stopSubmit(SIGN_UP_FORM_KEY, err.response),
            ];
          }

          return [signUp.failure()];
        }),
      ),
    ),
  );

export const editProfileEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editProfile.request)),
    mergeMap(({ payload: { hasFinishedOnboarding, isOnboarding, ...body } }) =>
      fullRequest<User>({
        body,
        method: 'PATCH',
        path: 'users/me',
      }).pipe(
        mergeMap(({ response: user }) => [
          editProfile.success(user),
          ...(isOnboarding
            ? []
            : hasFinishedOnboarding
            ? [
                replace('/members/me'),
                showSnackbar({
                  message: 'Profile updated! Welcome to SWD!',
                }),
              ]
            : [
                replace({
                  pathname: '/members/me',
                  search: state$.value.router.location.search,
                }),
                showSnackbar({
                  message: 'Profile updated!',
                }),
              ]),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editProfile.failure(),
              stopSubmit(EDIT_PROFILE_FORM_KEY, err.response),
            ];
          }

          return [editProfile.failure()];
        }),
      ),
    ),
  );

export const requestResetPasswordEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(requestResetPassword.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<User>({
        body,
        method: 'POST',
        path: 'auth/reset_password',
      }).pipe(
        mergeMap(() => [
          replace(`${state$.value.router.location.pathname}?success=true`),
          requestResetPassword.success(),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              requestResetPassword.failure(),
              stopSubmit(REQUEST_RESET_PASSWORD_FORM_KEY, err.response),
            ];
          }

          return [requestResetPassword.failure()];
        }),
      ),
    ),
  );

export const resetPasswordEpic: Epic = (action$, _, { fullRequest, request }) =>
  action$.pipe(
    filter(isActionOf(resetPassword.request)),
    mergeMap(
      ({
        payload: {
          token,
          firstName,
          lastName,
          password,
          repeatPassword,
          isNewUser,
        },
      }) =>
        fullRequest<ResetPasswordResponse>({
          body: {
            firstName,
            lastName,
            password,
            repeatPassword,
          },
          method: 'PUT',
          path: `auth/reset_password/confirm/${token}`,
        }).pipe(
          mergeMap(({ response: { token } }) => {
            setToken(token);
            return request<User>({
              path: 'users/me',
            });
          }),
          mergeMap((user) => [
            resetPassword.success(user),
            isNewUser
              ? replace({
                  pathname: '/onboarding',
                  state: {
                    onboardingAllowed: true,
                  },
                })
              : replace('/'),
            ...POST_LOGIN_ACTIONS,
          ]),
          catchError((err: AjaxError) => {
            if (err.status === StatusCodes.BAD_REQUEST) {
              return [
                resetPassword.failure(),
                stopSubmit(RESET_PASSWORD_FORM_KEY, err.response),
              ];
            }

            return [resetPassword.failure()];
          }),
        ),
    ),
  );

export const setSecondaryEmailEpic: Epic = (
  action$,
  _,
  { fullRequest, request },
) =>
  action$.pipe(
    filter(isActionOf(setSecondaryEmail.request)),
    mergeMap(({ payload: { token, email, password } }) =>
      fullRequest<SetSecondaryEmailResponse>({
        body: {
          email,
          password,
        },
        method: 'PUT',
        path: `auth/secondary_email/confirm/${token}`,
      }).pipe(
        mergeMap(({ response: { token } }) => {
          setToken(token);

          return request<User>({
            path: 'users/me',
          });
        }),
        mergeMap((user) => [
          setSecondaryEmail.success(user),
          logIn.request({ email, password }),
          ...POST_LOGIN_ACTIONS,
          replace({
            pathname: '/',
          }),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.UNAUTHORIZED) {
            return [
              setSecondaryEmail.failure(),
              stopSubmit(SECONDARY_EMAIL_FORM_KEY, {
                _error: 'Email or password invalid',
              }),
            ];
          }

          return [setSecondaryEmail.failure()];
        }),
      ),
    ),
  );

export const verifyEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(verify.request)),
    mergeMap(() => {
      const token = getToken();

      const waitForInitialization = createActionWaiter<RootAction, RootAction>(
        action$,
        ...INITIALIZE_ACTIONS.map(({ failure, success }) => [
          getType(success),
          getType(failure),
        ]),
      );

      return merge(
        // Emit all initialize actions
        merge(...INITIALIZE_ACTIONS.map(({ request }) => of(request()))),
        // Meanwhile, begin the verify process
        (token
          ? request<{}>({
              path: 'auth/token/verify',
              method: 'POST',
              body: {
                token: getToken(),
              },
            }).pipe(
              mergeMap(() =>
                request<User>({
                  path: 'users/me',
                }),
              ),
              mergeMap((user) => [verify.success(user), ...POST_LOGIN_ACTIONS]),
              catchError(() => {
                setToken(null);

                return [
                  verify.failure(),
                  // If we're here, the initialization must have failed because
                  // 401. Re-attempt it, as this time it won't set the authn
                  // header and won't result in 401.
                  ...INITIALIZE_ACTIONS.map(({ request }) => request()),
                ];
              }),
            )
          : of(verify.failure())
        ).pipe(
          // If the verify process finished but the initialization didn't, wait
          // until it does. If the initialization already finished, proceed.
          waitForInitialization(),
        ),
      );
    }),
  );

export const setGroupCodeEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(setGroupCode.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<{}>({
        body,
        path: 'users/me/code',
        method: 'PUT',
      }).pipe(
        mergeMap(() => [setGroupCode.success()]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              setGroupCode.failure(),
              stopSubmit(
                `${GROUP_CODE_FORM_KEY}/onboarding/group-code`,
                err.response,
              ),
            ];
          }

          return [setGroupCode.failure()];
        }),
      ),
    ),
  );

export const supportEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(support.request)),
    mergeMap(({ payload: { inquiryType, issueDescription } }) =>
      fullRequest<{}>({
        body: {
          inquiryType,
          issueDescription,
        },
        path: 'support/tickets',
        method: 'POST',
      }).pipe(
        mergeMap(() => [
          support.success(),
          showSnackbar({
            message: 'Your support request has been sent successfully',
          }),
          goBack(),
        ]),
        catchError(() => [support.failure()]),
      ),
    ),
  );

export const deleteAccountEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(deleteAccount.request)),
    mergeMap(() =>
      fullRequest<User>({
        method: 'POST',
        path: 'auth/delete',
      }).pipe(
        mergeMap(() => {
          window.location.pathname = '/';
          return [deleteAccount.success()];
        }),
        catchError(() => {
          return [
            deleteAccount.failure(),
            showSnackbar({
              message: 'Something bad happened; please try again later.',
            }),
          ];
        }),
      ),
    ),
  );

export default combineEpics(
  changePasswordEpic,
  deleteAccountEpic,
  logInEpic,
  saveTokenEpic,
  logOutEpic,
  signUpEpic,
  verifyEpic,
  editProfileEpic,
  requestResetPasswordEpic,
  resetPasswordEpic,
  setGroupCodeEpic,
  setSecondaryEmailEpic,
  supportEpic,
);
