import { Button as DefaultButtonPrimary, Link } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  text-align: left;
  max-width: 28.75rem;
  width: 100%;
`;

export const Row = styled.div`
  ${from.mobileXL`
    display: flex;
    
    > * {
      flex: 1;
      :not(:last-child) {
        margin-right: ${({ theme }) => theme.spacing.md};
      }
    }
  `};
`;

export const Button = styled(DefaultButtonPrimary)`
  min-width: 8.88rem;
`;

export const LogInText = styled.p`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: ${({ theme }) => theme.spacing.xlg} 0 0 0;
`;

export const LogInLink = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  font-weight: ${({ theme }) => theme.weights.bold};
`;
