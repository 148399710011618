import { connect } from 'react-redux';
import { support } from 'redux/modules/auth';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  supportRequest: support.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
