import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;
