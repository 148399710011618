import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      d="M4.7041 16.9131H2.2041C1.51375 16.9131 0.954102 16.3534 0.954102 15.6631V1.91309C0.954102 1.22273 1.51375 0.663086 2.2041 0.663086H11.0616C11.3929 0.663157 11.7106 0.794737 11.9449 1.02892L14.3383 3.42225C14.5725 3.65658 14.704 3.9743 14.7041 4.30559V8.16309"
      stroke="#297FD5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2041 14.6906V18.5106L14.0791 19.4131L10.9541 18.5106V14.6906"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7041 13.9131L14.0791 11.8631L8.4541 13.9131L14.0791 15.6631L19.7041 13.9131Z"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.4541 13.9131V16.7048"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4541 3.78809H4.7041"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2041 3.78809H10.9541"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4541 6.91309H4.7041"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2041 6.91309H10.9541"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4541 10.0381H4.7041"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4541 13.1631H4.7041"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.2041 10.0381H10.9541"
      stroke="#004A8F"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
