import { Conversation as DefaultConversation } from 'components';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const Content = styled.div`
  ${container};
  color: ${({ theme }) => theme.colors.black};
  padding: 2.5rem ${({ theme }) => theme.wrapperPaddingH.mobile} 10rem;

  ${from.tablet`
    padding: 2.5rem ${({ theme }) => theme.wrapperPaddingH.desktop} 10rem;
  `};
`;

export const Section = styled.div`
  :not(:last-child) {
    margin-bottom: 3.75rem;
  }
`;

export const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Heading = styled.div`
  margin-right: ${({ theme }) => theme.spacing.xlg};
  flex: 1;
`;

export const SectionDescription = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 1.875rem;
`;

export const Conversation = styled(DefaultConversation)``;

export const Conversations = styled.div`
  width: 100%;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;
