import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { BookCover, BookCoverSeparator, BookSheets } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <BookCover d="M10.833 19.088a14.157 14.157 0 017.38-3.241 1.333 1.333 0 001.16-1.323V5.175a1.343 1.343 0 00-1.53-1.333 14.223 14.223 0 00-7.01 3.193 1.363 1.363 0 01-1.671 0 14.223 14.223 0 00-7.008-3.19 1.342 1.342 0 00-1.529 1.33v9.35c0 .669.496 1.234 1.16 1.322a14.158 14.158 0 017.382 3.241c.49.38 1.176.38 1.666 0z" />
      <BookSheets d="M16.683.625a14.732 14.732 0 00-5.85 2.97 1.363 1.363 0 01-1.671 0A14.723 14.723 0 003.32.626" />
      <BookCoverSeparator d="M10 7.317v12.058" />
    </g>
  </svg>
));
