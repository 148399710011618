import DOMPurify from 'dompurify';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';

export function afterSanitizeAttributesCallback(node: Element) {
  if (node.tagName.toLowerCase() === 'a') {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noreferrer noopener');
  }
}

export function useSanitizedHTML(content: string) {
  return useMemo(() => {
    return DOMPurify.sanitize(content);
  }, [content]);
}

export function useAfterRender() {
  const ref = useRef<HTMLDivElement>(null);
  const { push } = useHistory();

  useEffect(() => {
    if (!ref.current) {
      return () => {};
    }

    const onMutate: MutationCallback = () => {
      const references = ref.current?.querySelectorAll(
        'span[data-reference-entity-id][data-reference-entity-type]',
      );

      Array.from(references ?? [])
        .filter((el): el is HTMLSpanElement => el instanceof HTMLSpanElement)
        .forEach((reference) => {
          const {
            referenceEntityId: entityId,
            referenceEntityType: entityType,
          } = reference.dataset;

          if (entityId && entityType === 'user') {
            const onClick = () => {
              push(`/members/${entityId}`);
            };

            reference.addEventListener('click', onClick);
          }
        });
    };

    const observer = new MutationObserver(onMutate);
    observer.observe(ref.current, {
      childList: true,
      subtree: true,
    });

    // Emit an initial event in case the rendering of the html finished before
    // the listener was setup.
    onMutate([], observer);

    return () => {
      observer.disconnect();
    };
  }, [push, ref]);

  return { ref };
}
