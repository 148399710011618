import produce from 'immer';
import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { createBooking, getOfficeHours, updateBooking } from './actions';
import { OfficeHourAction, OfficeHourState } from './types';

export const INITIAL_STATE: OfficeHourState = {
  ...defaultStandardState,
};

const reducer: Reducer<OfficeHourState, OfficeHourAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(createBooking.request):
    case getType(updateBooking.request):
      return {
        ...state,
        loading: true,
      };
    case getType(createBooking.success):
    case getType(updateBooking.success):
      return produce(state, (next) => {
        const session = next.byId[action.payload.session];

        if (session) {
          session.booking = action.payload;
        }

        next.loading = false;
      });
    case getType(createBooking.failure):
    case getType(updateBooking.failure):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getOfficeHours.request),
      success: isActionOf(getOfficeHours.success),
      failure: isActionOf(getOfficeHours.failure),
    },
  },
  contractItem: ({ id, slug }) => ({ id, slug }),
  initialState: INITIAL_STATE,
  perPage: 10,
})(reducer);
