import React, { FC, useCallback, useMemo } from 'react';

import { Button } from './styles';
import type { Props } from './types';

const Item: FC<Props> = ({ isActive, state, command, editorView, Icon }) => {
  const onClick = useCallback(() => {
    command(editorView.state, editorView.dispatch, editorView);
  }, [command, editorView]);

  const { active, enabled } = useMemo(
    () => ({
      active: isActive(state),
      enabled: command(state, undefined, editorView),
    }),
    [command, editorView, isActive, state],
  );

  return (
    <Button
      $active={active}
      disabled={!enabled}
      type="button"
      onClick={onClick}
    >
      <Icon />
    </Button>
  );
};

export default Item;
