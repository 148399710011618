import { Statuses } from 'model';
import React, { FC } from 'react';
import { formatElapsedTime } from 'utils/dates';

import excludeOtherTag from 'utils/excludeOtherTag';
import {
  BottomRowElements,
  CardLink,
  Container,
  Content,
  Description,
  EndDate,
  EndDateGroup,
  EndDateTitle,
  EndDateValue,
  Overlay,
  Status,
  StatusList,
  Tags,
  Thumbnail,
  Title,
} from './styles';
import { Props } from './types';

const ChallengeCard: FC<Props> = ({
  children,
  description,
  endDate,
  link,
  linkText,
  maintainTagsSpaceIfNoTags = false,
  status,
  tags,
  thumbnail,
  title,
  ...rest
}) => {
  const filteredTags = tags?.filter(excludeOtherTag);

  return (
    <Container {...rest}>
      <CardLink to={link}>
        <Thumbnail alt="" aria-hidden="true" src={thumbnail} />
        <Overlay />
        <Content>
          <Title>{title}</Title>
          {(filteredTags || maintainTagsSpaceIfNoTags) && (
            <Tags items={filteredTags ?? []} oneLine={true} />
          )}
          {endDate && (
            <EndDate>
              <EndDateGroup>
                <EndDateTitle>Ended</EndDateTitle>
                <EndDateValue>{formatElapsedTime(endDate)}</EndDateValue>
              </EndDateGroup>
            </EndDate>
          )}
          <Description>{description}</Description>
          <BottomRowElements>
            {children}
            {status && status.length > 0 && (
              <StatusList>
                {status.map(
                  (stat) =>
                    stat !== Statuses.CLOSED && (
                      <Status
                        key={stat}
                        alreadySubmitted={stat === Statuses.ALREADY_SUBMITTED}
                        solvedByUser={stat === Statuses.SOLVED_BY_USER}
                        expiresSoon={stat === Statuses.EXPIRES_SOON}
                      >
                        {stat}
                      </Status>
                    ),
                )}
              </StatusList>
            )}
          </BottomRowElements>
        </Content>
      </CardLink>
    </Container>
  );
};

export default ChallengeCard;
