import { push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  Conversation,
  ConversationExpanded,
  CONVERSATION_FORM_KEY,
  PaginatedResponse,
} from 'model';
import { parse } from 'query-string';
import { destroy, stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { addComments } from 'redux/modules/comment';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { CONVERSATIONS_PER_PAGE } from 'utils/config';
import { showSnackbar } from '../snackbar';
import {
  createConversation,
  deleteConversation,
  editConversation,
  getConversation,
  getConversations,
} from './actions';

export const createConversationEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(createConversation.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<ConversationExpanded>({
        body,
        method: 'POST',
        path: 'conversations',
      }).pipe(
        mergeMap(({ response: conversation }) => [
          createConversation.success(conversation),
          push(`/conversations/${conversation.id}`),
          destroy(CONVERSATION_FORM_KEY()),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              createConversation.failure(),
              stopSubmit(CONVERSATION_FORM_KEY(), err.response),
            ];
          }

          return [createConversation.failure()];
        }),
      ),
    ),
  );

export const deleteConversationEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteConversation.request)),
    mergeMap(({ payload: { id } }) =>
      request({
        method: 'DELETE',
        path: `conversations/${id}`,
      }).pipe(
        mergeMap(() => [
          deleteConversation.success({ id }),
          showSnackbar({
            message: 'Your conversation was deleted!',
          }),
          replace('/conversations'),
        ]),
        catchError(() => [deleteConversation.failure()]),
      ),
    ),
  );

export const editConversationEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editConversation.request)),
    mergeMap(({ payload: { id, ...body } }) =>
      fullRequest<ConversationExpanded>({
        body,
        method: 'PUT',
        path: `conversations/${id}`,
      }).pipe(
        mergeMap(({ response: conversation }) => [
          editConversation.success(conversation),
          showSnackbar({
            message: 'Conversation updated!',
          }),
          push(`/conversations/${id}`),
          destroy(CONVERSATION_FORM_KEY(id)),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editConversation.failure(),
              stopSubmit(CONVERSATION_FORM_KEY(id), err.response),
            ];
          }

          return [editConversation.failure()];
        }),
      ),
    ),
  );

export const getConversationEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getConversation.request)),
    mergeMap(({ payload: { id } }) =>
      fullRequest<ConversationExpanded>({
        path: `conversations/${id}`,
      }).pipe(
        mergeMap(({ response: conversation }) => [
          getConversation.success(conversation),
          addComments(conversation.comments),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getConversation.failure(), replace('/404')];
          }

          return [getConversation.failure()];
        }),
      ),
    ),
  );

export const getConversationsEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getConversations.request)),
    mergeMap(({ payload: { search } }) => {
      const query = search ? parse(search) : {};

      return fullRequest<PaginatedResponse<Conversation>>({
        path: 'conversations',
        params: {
          pageSize: CONVERSATIONS_PER_PAGE,
          ...query,
        },
      }).pipe(
        mergeMap(({ response }) => [getConversations.success(response)]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getConversations.failure(), replace('/404')];
          }

          return [getConversations.failure()];
        }),
      );
    }),
  );

export default combineEpics(
  createConversationEpic,
  deleteConversationEpic,
  editConversationEpic,
  getConversationEpic,
  getConversationsEpic,
);
