import styled from 'styled-components';

export const Gallery = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -${({ theme }) => theme.spacing.md};
  margin-right: -${({ theme }) => theme.spacing.md};
`;

export const ImgContainer = styled.div`
  background-color: transparent;
  border-radius: 0.25rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  margin-right: ${({ theme }) => theme.spacing.md};
`;

export const Image = styled.img`
  border-radius: 0.25rem;
  cursor: pointer;
  display: block;
  height: 17.25rem;
  max-width: 100%;
  object-fit: cover;
  width: 23.125rem;

  :hover {
    opacity: 0.6;
  }
`;
