import {
  EditProfileForm,
  GroupCodeForm,
  OnboardingSection,
  ONBOARDING_SECTIONS,
} from 'model';
import insertHttp from 'utils/insertHttp';
import { useConnect } from './connect';

export const handleSubmit = ({
  setGroupCode,
  editProfile,
  push,
  search,
  section = '',
  url,
}: {
  setGroupCode: ReturnType<typeof useConnect>['setGroupCode'];
  editProfile: ReturnType<typeof useConnect>['editProfile'];
  push: ReturnType<typeof useConnect>['push'];
  search: ReturnType<typeof useConnect>['search'];
  section: string | undefined;
  url: ReturnType<typeof useConnect>['url'];
}) => (values: Partial<EditProfileForm> | GroupCodeForm) => {
  const sectionIndex = ONBOARDING_SECTIONS.indexOf(
    section as OnboardingSection,
  );

  if (sectionIndex >= 0) {
    const nextSection = ONBOARDING_SECTIONS[sectionIndex + 1];

    if ('groupCode' in values) {
      if (values.groupCode) {
        setGroupCode({ onboardingCode: values.groupCode });
      }
    } else {
      if (values.socialWeb) {
        values.socialWeb = insertHttp(values.socialWeb);
      }

      if (values.socialLinkedin) {
        values.socialLinkedin = insertHttp(values.socialLinkedin);
      }

      editProfile({
        isOnboarding: !!nextSection,
        hasFinishedOnboarding: !nextSection,
        firstLogin: !!nextSection,
        ...values,
      });
    }

    if (nextSection) {
      push({
        search,
        pathname: `${url}/${nextSection}`,
        state: {
          onboardingAllowed: true,
          scrollToTop: false,
        },
      });
    }
  }
};
