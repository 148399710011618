import { blockquote } from 'components/Editor/styles';
import styled from 'styled-components';

export const Container = styled.div`
  ${blockquote};

  line-height: 1.75rem;

  a {
    color: ${({ theme }) => theme.colors.accent};
    font-weight: ${({ theme }) => theme.weights.semiBold};
    font-family: 'Proxima Nova';
  }
  .reference {
    color: ${({ theme }) => theme.colors.accent};
    font-weight: ${({ theme }) => theme.weights.semiBold};
    font-family: 'Proxima Nova';
    cursor: pointer;
  }
  .reference:hover {
    text-decoration: underline;
  }
`;
