import { Card, CardGrid, Loader, ModalRoute, Pagination } from 'components';
import { getUserName } from 'model/User';
import React, { FC, useEffect } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { Loading } from '../../styles';
import { useConnect } from './connect';
import Preview from './Preview';
import { Container, Link, SearchOptionsChallenges } from './styles';
import { Props } from './types';

const Challenges: FC<Props> = ({ contentRef, user }) => {
  const {
    getUserChallengeResponses,
    items,
    loading,
    pageCount,
    path,
    search,
    state,
    url,
  } = useConnect({ user });

  const page = getPageFromQuery(search);

  const userSlug = user.slug;

  useEffect(() => {
    getUserChallengeResponses({ search, page, slug: userSlug });
  }, [getUserChallengeResponses, search, page, userSlug]);

  if (loading && !items.length) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  return (
    <Container>
      <SearchOptionsChallenges
        contentType="challenge"
        searchPlaceholder="Search..."
      />
      <CardGrid>
        {items.map(
          ({
            challengeSlug,
            commentsCount,
            hits,
            id,
            mediaList,
            lastComment,
            slug,
            tags,
            title,
            user,
            votesCount,
          }) => (
            <Link
              key={id}
              to={{
                pathname: `${url}/${challengeSlug}/${slug}`,
                state: {
                  scrollToTop: false,
                },
              }}
            >
              <Card
                authorAvatar={user.avatar}
                authorName={getUserName(user)}
                authorType={getUserType(user)}
                comments={commentsCount}
                datapoints={votesCount}
                lastCommentDate={lastComment ? lastComment : undefined}
                statuses={[]}
                tags={tags}
                thumbnail={mediaList?.mainMedia}
                title={title}
                views={hits}
              />
            </Link>
          ),
        )}
      </CardGrid>
      <Pagination
        contentRef={contentRef}
        current={Math.min(page, pageCount)}
        state={state}
        total={pageCount}
        url={url}
      />
      <ModalRoute
        component={Preview}
        path={`${path}/:challengeSlug/:submissionSlug`}
      />
    </Container>
  );
};

export default Challenges;
