import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Zoom from '@material-ui/core/Zoom';
import React, { forwardRef } from 'react';

const SlideUp = forwardRef<unknown, TransitionProps>(
  ({ children, ...props }, ref) => (
    <Slide direction="up" {...props} ref={ref}>
      {
        /* tslint:disable-next-line:no-any */
        children as any
      }
    </Slide>
  ),
);

const animations = {
  slideUp: SlideUp,
  zoom: Zoom,
  fade: Fade,
};

export default animations;

export type AnimationName = keyof typeof animations;
