import { FormNameMap } from 'utils/forms';

export const ACTIVITY_FILTERS_FORM_KEY = 'forms/ActivityFilters';

export interface EveryoneMemberFilter {
  type: 'everyone';
}

export interface SWDTeamMemberFilter {
  type: 'swdTeam';
}

export interface SpecificMemberFilter {
  type: 'specificMember';
  name: string;
}

export interface ActivityFiltersForm {
  commentsReceived: boolean;
  commentsGiven: boolean;
  voteGiven: boolean;
  voteReceived: boolean;
  feedbackReceived: boolean;
  feedbackGiven: boolean;
  memberFilter:
    | EveryoneMemberFilter
    | SWDTeamMemberFilter
    | SpecificMemberFilter;
}

export const ActivityFiltersFieldNames: FormNameMap<ActivityFiltersForm> = {
  commentsReceived: 'commentsReceived',
  commentsGiven: 'commentsGiven',
  voteGiven: 'voteGiven',
  voteReceived: 'voteReceived',
  feedbackReceived: 'feedbackReceived',
  feedbackGiven: 'feedbackGiven',
  memberFilter: 'memberFilter',
};
