import { serializeHTML } from 'components/Editor';
import { GalleryEditForm } from 'model';
import React from 'react';
import { deleteDiscoverItem } from 'redux/modules/discover';
import { Props } from './types';

export const handleDelete = ({
  discoverId,
  openConfirmationModal,
}: {
  discoverId?: string;
  openConfirmationModal: Props['openConfirmationModal'];
}) => () => {
  if (discoverId) {
    openConfirmationModal({
      acceptButtonLabel: 'Delete',
      acceptButtonVariant: 'danger',
      cancelButtonLabel: 'Cancel',
      title: <span>Are you sure?</span>,
      description: (
        <span>
          <strong>Deleting this submission can’t be undone</strong>. This entry
          and its associated images, comments, datapoints... will be deleted in
          all the sections of this website.
        </span>
      ),
      onAccept: [deleteDiscoverItem.request({ discoverId })],
    });
  }
};

export const handleSubmit = ({
  editDiscoverItem,
  discoverId,
  item,
}: {
  editDiscoverItem: Props['editDiscoverItem'];
  item: Props['item'];
  discoverId?: string;
}) => (values: GalleryEditForm) => {
  if (discoverId) {
    editDiscoverItem({
      discoverId,
      context: serializeHTML(values.context),
      image: item?.image,
      graphIndustry: values.industry,
      graphTools: values.tools,
      graphTopic: values.topic,
      graphTypes: values.graphType,
      title: values.title,
    });
  }
};
