import type { EditorState } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import React, {
  createRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { render } from 'react-dom';

import type {
  AdaptedViewProps,
  ViewAdapterProps,
  ViewAdapterRef,
} from './types';

const ViewAdapter = forwardRef<ViewAdapterRef, ViewAdapterProps>(
  ({ Component, initialEditorState }, ref) => {
    const [editorState, setView] = useState<EditorState>(initialEditorState);

    useImperativeHandle(
      ref,
      () => ({
        update: (nextState: EditorState) => {
          setView(nextState);
        },
      }),
      [setView],
    );

    return <Component state={editorState} />;
  },
);

class AdaptedView {
  private adapterRef = createRef<ViewAdapterRef>();

  private dom: HTMLElement | undefined = undefined;

  // Because render is asynchronous, a race condition where the view is
  // constructed and then destroyed before the render has finished might occur.
  //
  // This is the case of reinitialization for example: an empty state is loaded,
  // and then data from the api is retrieved and a new state is swapped in,
  // causing another View to be created and the current one to be destroyed.
  //
  // We mitigate this with the destroyed flag. If the element has been destroyed
  // before it has been rendered, then don't place the rendered element into the
  // DOM.
  private destroyed = false;

  private editorView: EditorView;

  constructor({ Component, editorView, onRender }: AdaptedViewProps) {
    this.editorView = editorView;

    const container = document.createElement('div');

    render(
      <ViewAdapter
        Component={Component}
        initialEditorState={editorView.state}
        ref={this.adapterRef}
      />,
      container,
      () => {
        if (container.firstChild instanceof HTMLElement && !this.destroyed) {
          this.dom = container.firstChild;
          onRender(this.dom);
        }
      },
    );
  }

  update() {
    this.adapterRef.current?.update(this.editorView.state);
  }

  destroy() {
    this.destroyed = true;
    this.dom?.remove();
  }
}

/**
 * Transforms a React component into a ProseMirror View which can be easily
 * integrated as a plugin.
 *
 * In order to do so, it creates an Adapter component that hooks the adapted
 * component to the ProseMirror lifecycle.
 *
 * If we're comfortable with this pattern, this could be extracted into its own
 * package and published as an open source module.
 */
export default function createAdaptedView(props: AdaptedViewProps) {
  return new AdaptedView(props);
}
