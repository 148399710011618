import { Action, AnyAction, Middleware, Reducer } from 'redux';
import { composeReducers } from 'utils/composeReducers';

/*
 * The transaction middlewares allows to perform batch updates to the redux
 * store, preventing unnecessary re-renders and enabling the developer to
 * remove unintended illegal redux states, while still executing side effects
 * from other middlewares, like connected-react-router.
 */

const ADD_ACTION_TO_TRANSACTION = '@@redux-transaction/ADD_ACTION';
const COMMIT_TRANSACTION = '@@redux-transaction/COMMIT';
const BEGIN_TRANSACTION = '@@redux-transaction/BEGIN';

export const begin = () => ({
  type: BEGIN_TRANSACTION,
});

export const commit = () => ({
  type: COMMIT_TRANSACTION,
});

/**
 * @param reducer Your store's root reducer.
 * @return An object containing the transactionMiddleware and the
 * transactionReducer. Append the transactionMiddleware to your applyMiddlewares
 * call. Any middlewares below the transactionMiddleware will never be called
 * while in a transaction, so usually it should be placed last. Use the
 * transactionReducer when configuring your store.
 */
export const createTransactionMiddleware = <S, A extends Action = AnyAction>(
  reducer: Reducer<S, A>,
) => {
  let active = false;
  let pendingState: S | null = null;

  const transactionReducer = composeReducers(reducer, (state, action) => {
    if (action.type === COMMIT_TRANSACTION) {
      return pendingState!;
    }

    return state!;
  });

  const transactionMiddleware: Middleware = (store) => (next) => (action) => {
    if (action.type === COMMIT_TRANSACTION) {
      active = false;
    }

    if (active) {
      pendingState = reducer(pendingState!, action);
      return next({
        type: ADD_ACTION_TO_TRANSACTION,
        payload: {
          action,
        },
        meta: {
          nextState: pendingState,
        },
      });
    }

    if (action.type === BEGIN_TRANSACTION) {
      active = true;
      pendingState = store.getState();
    }

    return next(action);
  };

  return { transactionMiddleware, transactionReducer };
};

export type TransactionAction =
  | ReturnType<typeof begin>
  | ReturnType<typeof commit>;
