import DefaultCommunityIcon from 'assets/images/logoCommunity.svg';
import styled from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  overflow: hidden;
  padding-inline: 1.75rem;
  position: relative;
  text-align: left;
  height: 100vh;

  ${from.mobileXL`
    border-radius: 0.25rem;
    padding-inline: 4.625rem;
    text-align: center;
  `};

  ${from.laptop`
    padding-inline: 10.625rem;
  `};
`;

export const CTALeftImage = styled.img`
  bottom: -1rem;
  height: 30%;
  left: -7rem;
  position: absolute;

  ${from.mobileXL`
    height: 35%;
  `};
`;

export const CTARightImage = styled.img`
  height: 30%;
  position: absolute;
  right: -1rem;
  top: -1.5rem;

  ${from.mobileXL`
    height: 35%;
    right: -2rem;
    top: -0.5rem;
  `};
`;

export const CommunityIcon = styled.img.attrs({
  src: DefaultCommunityIcon,
})``;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.75;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875;
  margin: 0;
`;

export const Caption = styled(Description)`
  position: absolute;
  bottom: 5rem;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const ButtonLink = styled.button`
  padding: 0;
  color: inherit;
  cursor: pointer;
  background-color: transparent;
  border-width: 0;
  color: ${({ theme }) => theme.colors.accent};
  font-weight: 600;
`;

export const BannerInfo = styled.div<{ $hidden: boolean }>`
  display: flex;
  padding: 0.625rem 1.25rem;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.grey100};
  transition: opacity 0.5s ease-in-out;

  opacity: ${({ $hidden }) => ($hidden ? '0' : '1')};
`;
