import React, { FC, useCallback } from 'react';
import { pluralize } from 'utils/pluralize';
import NewCommentForm from './CommentForm';
import CommentList from './CommentList';
import connect from './connect';
import Edit from './Edit';
import { handleFormSubmitted, handleToggleReplyFormOpen } from './logic';
import NewCommentButton from './NewCommentButton';
import {
  Container,
  NewCommentSection,
  Section,
  Title,
  TitlePostComment,
  TitleRow,
} from './styles';
import { Props } from './types';

const Comments: FC<Props> = ({
  beginIntent,
  comments = [],
  contentType,
  createComment,
  disabled,
  id,
  loggedIn,
  path,
  push,
  replyFormsOpen,
  toggleReplyFormOpen,
  totalComments,
  url,
  spotlightAuthors,
  ...rest
}) => {
  const onFormSubmitted = useCallback(
    handleFormSubmitted({
      createComment,
      id,
      contentType,
    }),
    [createComment, id, contentType],
  );

  const onToggleReplyFormOpen = useCallback(
    handleToggleReplyFormOpen({
      beginIntent,
      loggedIn,
      toggleReplyFormOpen,
      url,
    }),
    [beginIntent, loggedIn, toggleReplyFormOpen, url],
  );
  return (
    <Container {...rest}>
      <Section disabled={disabled}>
        <TitleRow>
          <Title>
            {totalComments} {pluralize(totalComments, 'Comment', 'Comments')}
          </Title>
          <NewCommentButton
            beginIntent={beginIntent}
            disabled={!!disabled}
            loggedIn={loggedIn}
            url={url}
          />
        </TitleRow>
        <CommentList
          comments={comments}
          contentType={contentType}
          disabled={!!disabled}
          objectId={id}
          onCreateComment={createComment}
          onToggleReplyFormOpen={onToggleReplyFormOpen}
          replyFormsOpen={replyFormsOpen}
          url={url}
          spotlightAuthors={spotlightAuthors}
        />
      </Section>
      <NewCommentSection id="new-comment" canAdd={loggedIn && !disabled}>
        {loggedIn && !disabled && (
          <>
            <TitleRow>
              <TitlePostComment>Add your comment or question</TitlePostComment>
            </TitleRow>
            <NewCommentForm id={id} onSubmit={onFormSubmitted} />
          </>
        )}
      </NewCommentSection>
      {loggedIn && <Edit contentType={contentType} objectId={id} path={path} />}
    </Container>
  );
};

export default connect(Comments);
