import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';
import {
  getProminentVideo,
  selectProminentVideo,
  selectProminentVideoLoading,
} from 'redux/modules/video';

const mapStateToProps = (state: RootState) => ({
  prominentVideo: selectProminentVideo(state),
  prominentVideoLoading: selectProminentVideoLoading(state),
});

const mapDispatchToProps = {
  getProminentVideo: getProminentVideo.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
