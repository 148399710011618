import { RootState } from 'redux/modules/types';
import { createSelector } from 'reselect';

export const selectVideoState = (state: RootState) => state.video;

export const selectVideos = createSelector(selectVideoState, ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((it): it is NonNullable<typeof it> => !!it),
);

export const selectProminentVideo = createSelector(
  selectVideoState,
  (state) => state.prominentVideo,
);

export const selectVideo = createSelector(
  selectVideoState,
  (state) => state.video,
);

export const selectProminentVideoLoading = createSelector(
  selectVideoState,
  (state) => state.prominentVideoLoading,
);

export const selectVideoLoading = createSelector(
  selectVideoState,
  (state) => state.videoLoading,
);

export const selectVideoPageCount = createSelector(
  selectVideoState,
  (state) => state.pageCount,
);
