import { Comment } from '.';
import { userMock } from '../User';

export const commentMock: Comment = {
  slug: 'test',
  children: [],
  comment: 'Hello world!',
  contentType: 'feedback',
  created: '2019-09-02T14:00:00.000Z',
  id: 'b507b61c-b4b8-4c9e-b71f-d27b4cb8fa0b',
  isReported: false,
  isVoted: false,
  modified: '2019-09-02T14:00:00.000Z',
  objectId: '627f60d3-8cc1-4c4a-9671-f7028ae8e325',
  parent: null,
  user: userMock,
  votesCount: 0,
};
