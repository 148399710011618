import { PageTitle } from 'components';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import { handleFormSupportSubmitted } from './logic';
import { Container } from './styles';
import SupportForm from './SupportForm';
import { Props } from './types';

const Support: FC<Props> = ({ supportRequest }) => {
  const onFormSupportSubmitted = useCallback(
    handleFormSupportSubmitted({ supportRequest }),
    [supportRequest],
  );

  return (
    <Container>
      <PageTitle title="Support" />
      <SupportForm onSubmit={onFormSupportSubmitted} />
    </Container>
  );
};

export default connect(Support);
