import { getUserName } from 'model/User';
import React, { FC } from 'react';
import { getUserType } from 'utils/getUserType';
import Comment from './Comment';
import { Container } from './styles';
import { Props } from './types';

const CommentList: FC<Props> = ({
  comments,
  contentType,
  disabled,
  objectId,
  onCreateComment,
  onToggleReplyFormOpen,
  replyFormsOpen,
  url,
  spotlightAuthors,
}) => (
  <Container role="list">
    {comments.map((comment) => (
      <Comment
        authorAvatar={comment.user.avatar}
        authorId={comment.user.id}
        authorName={getUserName(comment.user)}
        authorType={getUserType(comment.user)}
        authorSlug={comment.user.slug}
        content={comment.comment}
        contentType={contentType}
        datapoints={comment.votesCount}
        date={comment.created}
        disabled={disabled}
        id={comment.id}
        image={comment.image}
        isReported={comment.isReported}
        isVoted={comment.isVoted}
        key={comment.id}
        objectId={objectId}
        onCreateComment={onCreateComment}
        onToggleReplyFormOpen={onToggleReplyFormOpen}
        replies={comment.children}
        replyFormsOpen={replyFormsOpen}
        url={url}
        spotlightAuthors={spotlightAuthors}
      />
    ))}
  </Container>
);

export default CommentList;
