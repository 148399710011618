import React, { FC, memo } from 'react';
import { Container, TabLink } from './styles';
import { Props } from './types';

const TabsDropdown: FC<Props> = ({ items, ...rest }) => {
  const activeFilter = items.find((item) => item.active);

  return (
    <Container header={activeFilter?.label ?? items[0].label} {...rest}>
      {({ closeDropdown }) => (
        <>
          {items.map(({ active, id, label, to }) => (
            <TabLink $active={active} key={id} onClick={closeDropdown} to={to}>
              {label}
            </TabLink>
          ))}
        </>
      )}
    </Container>
  );
};

export default memo(TabsDropdown);
