import React, { FC } from 'react';
import { breakpointDebuggerEnabled, isDev } from 'utils/config';
import { Container } from './styles';
import { Props } from './types';

const BreakpointDebugger: FC<Props> = ({ ...rest }) => <Container {...rest} />;

const EMPTY_COMPONENT: FC = () => null;

export default isDev && breakpointDebuggerEnabled
  ? BreakpointDebugger
  : EMPTY_COMPONENT;
