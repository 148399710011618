import { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { setSearchSectionPage } from 'redux/modules/search';
import { RootState } from 'redux/modules/types';
import {
  SEARCH_CONVERSATIONS_PER_PAGE_DETAIL,
  SEARCH_CONVERSATIONS_PER_PAGE_MASTER,
} from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { isDetail }: OwnProps) => ({
  available: state.search.available.conversations,
  count: state.search.count.conversations,
  items: state.search.ids.conversations
    .map((id) => state.search.byId.conversations[id])
    .filter((it): it is NonNullable<typeof it> => !!it),
  loading: state.search.loading.conversations,
  page: state.search.page.conversations,
  tags: state.configuration.conversationTags,
  pageCount: getPageCount(
    state.search.count.conversations,
    isDetail
      ? SEARCH_CONVERSATIONS_PER_PAGE_DETAIL
      : SEARCH_CONVERSATIONS_PER_PAGE_MASTER,
  ),
});

const mapDispatchToProps = {
  setPage: (page: SetStateAction<number>) =>
    setSearchSectionPage({
      page,
      section: 'conversations',
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
