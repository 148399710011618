import {
  BooleanInput as DefaultBooleanInput,
  Button as DefaultButton,
  Checkbox as DefaultCheckbox,
  ConnectedSearchInput as DefaultSearchInput,
  Radio as DefaultRadio,
} from 'components';
import { Input as SearchInputInput } from 'components/ConnectedSearchInput/styles';
import styled from 'styled-components';
import { from, whiteBox } from 'styles/mixins';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;

  svg {
    color: ${({ theme }) => theme.colors.accent};
  }

  ${from.tablet`
    grid-gap: 1.875rem;
    margin-top: ${({ theme }) => theme.spacing.lg};
  `};
`;

export const FiltersResponsiveButton = styled(DefaultButton)`
  background: none;
  padding: 0;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  height: 2.25rem;
  margin-left: ${({ theme }) => theme.spacing.md};
  :hover:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.accent};
    background: none;
  }
`;

export const DropdownContent = styled.div`
  ${whiteBox}
  > :nth-child(3) {
    margin-top: 1.25rem;
  }
  display: block;
  position: absolute;
  right: 0%;
  background-color: white;
  width: 14.875rem;
  top: 3rem;
  padding: 1.5rem;
  z-index: 1;

  ${from.tablet`
      display: contents;
    `}
`;

export const GroupFields = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  > * {
    margin: 0;
    padding: 0.813rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  }
  > :last-child {
    border-bottom: none;
  }
`;

export const FiltersGroupTitle = styled.h5`
  margin: 0 0 0.25rem 0;
  font-size: 0.875rem;
  font-weight: normal;
  letter-spacing: 0.028rem;
  text-transform: uppercase;
`;

export const CheckboxRadio = styled(DefaultCheckbox)`
  width: 0.5rem;
  height: 0.5rem;

  :before {
    left: -2.5px;
    top: -0.25rem;
    width: 1rem;
    height: 1rem;
    overflow: hidden;
    border-radius: 50%;
  }
  :checked {
    :after {
      background-size: 10px;
      height: 0.65rem;
      width: 0.8rem;
      left: -1px;
      top: -1.5px;
    }
    :before {
      border: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const RadioButton = styled(DefaultRadio)`
  :checked {
    :after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E")
        center center no-repeat;
      height: 100%;
      width: 10px;
      top: -7px;
      left: 7px;
    }
  }
`;

export const BooleanInputActivity = styled(DefaultBooleanInput)`
  > :first-child {
    margin-right: ${({ theme }) => theme.spacing.lg};
  }
`;
export const BooleanInputRadio = styled(DefaultBooleanInput)`
  > :first-child {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
`;

export const SearchInput = styled(DefaultSearchInput)`
  ${SearchInputInput} {
    width: 100%;
  }
`;

export const SearchContainer = styled.div``;

export const ApplyFilterButton = styled(DefaultButton)`
  margin-top: ${({ theme }) => theme.spacing.md};
`;
