import { FormNameMap } from 'utils/forms';

export const REPORT_FORM_KEY = 'forms/REPORT';

export interface ReportForm {
  reason: string;
  comments: string;
}

export const ReportFormNames: FormNameMap<ReportForm> = {
  reason: 'reason',
  comments: 'comments',
};
