import { connect } from 'react-redux';
import { getChallengeResponse } from 'redux/modules/challengeResponse';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { match, history: { push } }: OwnProps,
) => {
  const challengeSlug = match?.params?.challengeSlug;
  const submissionSlug = match?.params?.submissionSlug;

  return {
    challengeSlug,
    submissionSlug,
    push,
    submission: state.challengeResponse.bySlug[submissionSlug ?? ''],
    loading: state.challengeResponse.loading,
    url: match?.url,
  };
};

const mapDispatchToProps = {
  getChallengeResponse: getChallengeResponse.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
