import { parse, stringify } from 'query-string';
import { Props } from './types';

export const handleClose = ({
  closeModal,
  location,
  replace,
}: {
  closeModal: Props['closeModal'];
  location: Props['location'];
  replace: Props['replace'];
}) => () => {
  closeModal();
  replace({
    ...location,
    search: stringify({
      ...parse(location.search),
      intent: undefined,
      returnTo: undefined,
    }),
  });
};
