import styled from 'styled-components';
import { from, paragraph, title, viewContainer } from 'styles/mixins';

export const Container = styled.div`
  ${viewContainer};
  margin-bottom: 7rem;

  ${from.laptop`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const Title = styled.h1`
  ${title};
  margin: 0;
`;

export const Paragraph = styled.p`
  ${paragraph};
  max-width: 42rem;
`;
