import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="17"
  >
    {children}
    <path d="M7.72 16.328a3.581 3.581 0 0 1-3.446-2.672H1.486a.89.89 0 0 1 0-1.781h2.789a3.58 3.58 0 0 1 3.444-2.672 3.582 3.582 0 0 1 3.447 2.672h6.35a.89.89 0 0 1 0 1.781h-6.35a3.581 3.581 0 0 1-3.446 2.672zm-.001-5.344a1.782 1.782 0 0 0 0 3.563c.983 0 1.782-.8 1.782-1.781 0-.982-.799-1.782-1.782-1.782v-.445.445zm7.125-3.562A3.582 3.582 0 0 1 11.4 4.75H1.486a.89.89 0 0 1 0-1.781h9.913A3.581 3.581 0 0 1 14.845.297a3.567 3.567 0 0 1 3.562 3.562 3.569 3.569 0 0 1-3.563 3.563zm0-5.344c-.981 0-1.78.8-1.78 1.781 0 .982.799 1.782 1.78 1.782.983 0 1.782-.8 1.782-1.782s-.799-1.78-1.781-1.78z" />
  </svg>
));
