import React, { FC } from 'react';
import { useConnect } from './connect';
import { AchievementsSection } from './styles';
import { Props } from './types';

const Achievements: FC<Props> = ({ achievements }) => {
  useConnect();

  return (
    <AchievementsSection
      isAchievementsTab={true}
      titleDone="Recent achievements"
      titleToDo="To tackle next"
      achievements={achievements}
    />
  );
};

export default Achievements;
