import {
  ImageAndText as DefaultImageAndText,
  ImageListViewer as DefaultImageListViewer,
  Link,
  ShareButton as DefaultShareButton,
} from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.75rem 2.75rem;

  ${from.tablet`
    padding: 2.5rem 3.75rem;
  `}
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const ImageAndText = styled(DefaultImageAndText)`
  p:first-child {
    margin-top: 0;
  }

  > *:first-child {
    ${from.laptop`
      margin: 0 0 ${({ theme }) => `${theme.spacing.md} ${theme.spacing.xlg}`};
      width: 31rem;
    `};
  }
`;

export const ImageListViewer = styled(DefaultImageListViewer)``;

export const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
`;

export const TitleLink = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin: 0 1rem ${({ theme }) => theme.spacing.xsm} 0;

  ${from.tablet`
    font-size: 1.75rem;
  `}
`;

export const ShareButton = styled(DefaultShareButton)`
  margin-left: auto;
`;
