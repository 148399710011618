import DefaultAvatar from 'components/Avatar';
import DefaultButton from 'components/Button';
import DefaultRadio from 'components/Radio';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  margin-top: 0;
`;

export const Description = styled.p`
  line-height: 1.5;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${from.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `};
`;

export const Info = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
`;

export const Date = styled.span`
  font-size: 1.25rem;
  margin: 0.5rem 0 0;
  display: flex;
  flex-direction: column;

  ${from.mobileXL`
        flex-direction: row;
  `};

  :last-child {
    margin-bottom: 1rem;
    ${from.tablet`
        margin-bottom: 0;
      `};
  }

  span {
    :not(:last-child) {
      margin-right: 20px;
    }
  }

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;

export const SetReminder = styled(Section)`
  color: ${({ theme }) => theme.colors.black};
  display: block;
`;

export const Options = styled.div`
  margin: ${({ theme }) => theme.spacing.md} 0
    ${({ theme }) => theme.spacing.xlg};
`;

export const Option = styled.div<{ checked: boolean }>`
  align-items: center;
  border-radius: 0.25rem;
  border: solid 1px transparent;
  display: flex;
  min-width: 9.5rem;
  padding: ${({ theme }) => theme.spacing.sm};
  width: fit-content;

  ${({ checked, theme }) =>
    checked &&
    css`
      border-color: ${theme.colors.accent};
    `};

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.grey400};
  line-height: 1.5;
`;

export const Radio = styled(DefaultRadio)`
  height: 1.25rem;
  width: 1.25rem;

  :checked + ${Label} {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  ${from.tablet`
    flex-direction: row;
    
    > *:not(:last-child) {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  `};
`;

export const Button = styled(DefaultButton)``;

export const CancelButton = styled(DefaultButton)`
  ${from.tablet`
    margin-left: auto;
  `};
`;

export const Separator = styled.span`
  display: none;

  ${from.mobileXL`
        display: inline;
  `};
`;

export const Bottom = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.grey100};
  flex: 1;
  width: 100%;
  margin-top: 0.625rem;
  flex-direction: column;
  > :not(:last-child) {
    margin-right: 0;
    margin-bottom: 0.75rem;
  }
  ${from.tablet`
    flex-direction: row;
    > :not(:last-child) {
      margin-right: 0.75rem;
    }
  `};
`;

export const PresenterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 11.875rem;
`;

export const PresenterInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.5;
  text-align: start;
`;

export const Position = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.75rem;
  line-height: 1.1;
`;

export const Avatar = styled(DefaultAvatar)`
  width: 3.25rem;
  height: 3.25rem;
  margin-bottom: 0;
  margin-right: 0.75rem;
  flex-shrink: 0;
  ${BadgeContainer} {
    width: 1.5rem;
    height: 1.5rem;
    left: 1.75rem;
    bottom: -1px;
  }
`;
