import { ScrollBox } from 'components';
import Actions from 'containers/Common/PostActions';
import React, { FC, memo } from 'react';
import { formatElapsedTime } from 'utils/dates';
import excludeOtherTag from 'utils/excludeOtherTag';
import { pluralize } from 'utils/pluralize';
import {
  Avatar,
  AvatarContainer,
  Container,
  Content,
  ContentInfo,
  HeaderContainer,
  ImageListViewer,
  InfoHeader,
  ShareButton,
  Tags,
  Title,
  TitleContainer,
  UserLink,
  UserName,
} from './styles';
import { Props } from './types';

const DiscoverDetail: FC<Props> = ({
  context,
  commentsCount = 0,
  created,
  hits,
  id,
  image,
  isReported,
  isVoted,
  onAddVote,
  onRemoveVote,
  open,
  scroll,
  pathname,
  search,
  tags,
  title,
  url,
  userAvatar,
  userId,
  userName,
  userType,
  votesCount,
  itemUrl,
  ...rest
}) => {
  const elapsedTime = formatElapsedTime(created);
  const totalHits = `${hits} ${pluralize(hits, 'view', 'views')}`;
  const totalComments = `${commentsCount} ${pluralize(
    commentsCount,
    'comment',
    'comments',
  )}`;

  const currentUrl = url
    ? window.location.origin + url
    : window.location.origin + pathname;
  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${title}: `;
  const itemPathname = itemUrl || `/discover/detail/${id}`;

  const filteredTags = tags?.filter(excludeOtherTag);

  return (
    <Container {...rest}>
      <Content>
        <TitleContainer>
          {title && <Title to={itemPathname}>{title}</Title>}
        </TitleContainer>
        <ScrollBox scroll={scroll}>
          <HeaderContainer>
            <InfoHeader>
              <AvatarContainer>
                <Avatar
                  to={`/members/${userId}`}
                  src={userAvatar}
                  variant={userType}
                  name={userName}
                />
              </AvatarContainer>
              <UserName>
                <UserLink to={`/members/${userId}`}>{userName}</UserLink>
                {elapsedTime} · {totalHits} · {totalComments}
              </UserName>
              {filteredTags && filteredTags.length > 0 && (
                <Tags items={filteredTags} />
              )}
              <ShareButton content={shareButtonMessage} url={currentUrl} />
            </InfoHeader>
          </HeaderContainer>
          <ContentInfo>
            {image && (
              <ImageListViewer
                images={[image]}
                zoomUrl={{
                  search,
                  pathname: `${url}/images`,
                }}
              />
            )}
          </ContentInfo>
          <Actions
            allowEdit={false}
            authorId={userId}
            contentType="galleryentry"
            id={id}
            isReported={isReported}
            isVoted={isVoted}
            url={url}
            votes={votesCount || 0}
            commentLocation={{
              pathname: itemPathname,
              hash: 'new-comment',
            }}
          />
        </ScrollBox>
      </Content>
    </Container>
  );
};

export default memo(DiscoverDetail);
