import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { getExercise, selectExerciseBySlug } from 'redux/modules/exercise';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { exerciseSlug },
    path,
    url,
  } = useRouteMatch<{ exerciseSlug: string }>();

  const bySlug = useSelector(selectExerciseBySlug);

  const exercise = bySlug[exerciseSlug];

  const actions = useActions({
    getExercise: getExercise.request,
  });

  return { ...actions, exercise, exerciseSlug, path, url };
};
