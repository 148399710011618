import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

export const Section = styled.div<{
  disabled: boolean | undefined;
  closed?: boolean;
}>`
  ${({ closed }) =>
    closed &&
    css`
      padding-bottom: 4rem;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      margin-bottom: 10rem;
    `} :not(:last-child) {
    margin-bottom: 3.75rem;
  }
`;

export const NewCommentSection = styled.div<{ canAdd: boolean }>`
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacing.lg};

  ${({ canAdd }) =>
    canAdd &&
    css`
      box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);
      border: solid 1px ${({ theme }) => theme.colors.greyBlue};
      margin-bottom: 10rem;

      ${from.mobileXL`
        padding: 3.75rem;
      `};
    `};
`;

export const TitleRow = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.75rem;

  ${from.mobileXL`
    align-items: center;
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.spacing.md};
    margin-left: 0;
  `};
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0;
  ${from.tablet`
    font-size:1.25rem;
    font-weight: ${({ theme }) => theme.weights.bold};
  `}
`;

export const TitlePostComment = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
`;
