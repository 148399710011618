import { oneLine } from 'common-tags';
import {
  BooleanInput,
  EditorInput,
  FormActions,
  Input,
  Label,
  MediaInput,
  Toggle,
} from 'components';

import VisualTagInputs from 'containers/Common/VisualTagInputs';
import addDays from 'date-fns/fp/addDays';
import isValid from 'date-fns/isValid';
import startOfDay from 'date-fns/startOfDay';
import { FeedbackFieldNames, UPLOAD_MEDIA_FIELD } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required, title } from 'utils/validations';
import connect from './connect';
import { Container, DateInput, Row, SelectShowDate, Warning } from './styles';
import { Props } from './types';

const nextDay = addDays(1);

const formatDateExists = (date: Date | null) => (isValid(date) ? 'yes' : 'no');

const parseDateExists = (val: 'yes' | 'no') => {
  if (val === 'yes') {
    return nextDay(new Date());
  }

  if (val === 'no') {
    return null;
  }

  return undefined;
};

const Form: FC<Props> = (props) => {
  const {
    editId,
    handleSubmit,
    invalid,
    isPristine,
    loading,
    onDelete,
    reset,
    showExpirationDateInput,
    submitFailed,
  } = props;

  return (
    <Container onSubmit={handleSubmit}>
      <Field
        component={Input}
        label="Project title"
        name={FeedbackFieldNames.title}
        placeholder="Name of your project or feedback request"
        type="text"
        validate={[required, title]}
      />
      <Field
        component={EditorInput}
        label="What feedback would be useful?"
        name={FeedbackFieldNames.context}
        placeholder={oneLine`Let others know specifically what input would be most helpful. Also, what background information or other context would be useful for people to know when providing feedback? Do you face any constraints that potential feedback granters should be aware of?`}
        validate={required}
      />
      <Field
        component={MediaInput}
        label="Upload images"
        name={FeedbackFieldNames[UPLOAD_MEDIA_FIELD]}
        onlyImage={true}
      />
      <Field
        CheckboxComponent={Toggle}
        component={BooleanInput}
        name={FeedbackFieldNames.addToMyGallery}
        label="Add main image to my gallery"
      />
      <VisualTagInputs />
      <div>
        <Label htmlFor={FeedbackFieldNames.expirationDate}>
          IS THERE A DATE AFTER WHICH FEEDBACK WILL NO LONGER BE USEFUL?
        </Label>
        <Row>
          <Field
            component={SelectShowDate}
            format={formatDateExists}
            name={FeedbackFieldNames.expirationDate}
            options={[
              {
                label: 'Yes',
                value: 'yes',
              },
              {
                label: 'No',
                value: 'no',
              },
            ]}
            parse={parseDateExists}
          />
          <Field
            component={DateInput}
            min={nextDay(new Date())}
            name={FeedbackFieldNames.expirationDate}
            parse={startOfDay}
            show={showExpirationDateInput}
          />
        </Row>
      </div>
      <Warning>
        Please do not share any confidential information—if using an example
        from your work, consider how you can change details or redact sensitive
        info and still get the desired feedback. Storytelling with data is not
        responsible nor liable for any sensitive or confidential information
        shared on this platform.
      </Warning>
      <FormActions
        invalid={invalid}
        loading={loading}
        onDelete={onDelete}
        pristine={isPristine}
        reset={editId ? reset : undefined}
        submitFailed={submitFailed}
      />
    </Container>
  );
};

export default connect(Form);
