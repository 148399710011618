import React, { FC, useCallback } from 'react';
import { handleClick } from './logic';
import { Container, Label } from './styles';
import { Props } from './types';

const BooleanInput: FC<Props> = ({
  className,
  CheckboxComponent,
  input: { name, onChange, value, ...input },
  label,
  meta,
  ...rest
}) => {
  const onClick = useCallback(handleClick({ onChange, value }), [
    onChange,
    value,
  ]);

  return (
    <Container className={className} meta={meta} name={name} onClick={onClick}>
      <CheckboxComponent
        id={label}
        name={name}
        checked={value}
        onChange={onChange}
        {...rest}
        {...input}
      />
      {label && <Label htmlFor={label}>{label}</Label>}
    </Container>
  );
};

export default BooleanInput;
