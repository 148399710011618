import {
  ChallengeCardGrid,
  Loader,
  NoResults,
  PageTitle,
  Pagination,
  PremiumCTA,
  Tags,
} from 'components';
import SearchOptions from 'containers/Common/ChallengeSearchOptions';
import { Statuses } from 'model';
import React, { FC, useEffect, useRef } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import connect from './connect';

import excludeOtherTag from 'utils/excludeOtherTag';
import {
  Actions,
  Button,
  ChallengeCard,
  Container,
  Content,
  Current,
  Exercise,
  ExerciseImage,
  ExerciseOverlay,
  Featured,
  FeaturedTitle,
  Fold,
  Help,
  HelpSection,
  HelpText,
  HelpTitle,
  Info,
  LatestSubmissionsTitle,
  Paragraph,
  Section,
  SeeDetailsContainer,
  ShareButton,
  Submissions,
} from './styles';
import { Props } from './types';

const ExerciseRoot: FC<Props> = ({
  activeExercise,
  getExercises,
  loading,
  location: { hash, search, state },
  match: { url },
  pageCount,
  pastExercises,
  pathname,
  wentBack,
  isPremium,
}) => {
  const page = getPageFromQuery(search);
  const title = 'exercises';
  const description = `Undertake targeted practice by completing the latest exercise or browse the exercise bank
  and tackle one relevant to a current project or your data communication development goals.`;

  const pastExercisesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getExercises({ search, silent: wentBack });
  }, [getExercises, search, wentBack]);

  if (loading && !activeExercise) {
    return (
      <Container>
        <PageTitle title={title} />
        <Fold title={title} description={description} />
        <Loader />
      </Container>
    );
  }

  const currentUrl = url
    ? window.location.origin + url
    : window.location.origin + pathname;
  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${
    activeExercise && activeExercise.name
  }: `;
  const submissionTitle = 'solutions so far';
  const submissionSubtitle = 'solution';

  const activeExerciseFilteredTags = activeExercise?.tags.filter(
    excludeOtherTag,
  );

  return (
    <Container>
      <PageTitle title={title} />
      <Fold title={title} description={description} />
      {activeExercise && (
        <Current>
          <Content>
            <Exercise>
              <ExerciseImage
                alt=""
                aria-hidden="true"
                src={activeExercise.image}
              />
              <ExerciseOverlay />
            </Exercise>
            <Section isMain={true}>
              <Featured>
                <Info>
                  <FeaturedTitle>{activeExercise.name}</FeaturedTitle>
                  <ShareButton content={shareButtonMessage} url={currentUrl} />
                </Info>
                {activeExerciseFilteredTags && (
                  <Tags items={activeExercise.tags} oneLine={false} />
                )}

                <Info>
                  <Paragraph>{activeExercise.description}</Paragraph>
                </Info>
                <Actions>
                  <Submissions
                    submissionTitle={submissionTitle}
                    submissionSubtitle={submissionSubtitle}
                    count={activeExercise.submissionCount}
                    items={
                      activeExercise.latestSubmissions.map((item) => ({
                        id: item.id,
                        avatar: item.user.avatar,
                        userType: getUserType(item.user),
                        created: item.created,
                        firstName: item.user.firstName,
                        lastName: item.user.lastName,
                      })) ?? []
                    }
                  />
                  <SeeDetailsContainer>
                    <Button to={`${url}/${activeExercise.slug}`}>
                      See exercise
                    </Button>
                  </SeeDetailsContainer>
                </Actions>
              </Featured>
            </Section>
          </Content>
        </Current>
      )}
      <Content id="exercise-bank">
        <Section ref={pastExercisesRef} isMain={false}>
          <LatestSubmissionsTitle>
            Browse the exercise bank
          </LatestSubmissionsTitle>
          <SearchOptions
            contentType="exercise"
            searchPlaceholder="Search exercises..."
          />
          {loading ? (
            <Loader />
          ) : pastExercises.length > 0 ? (
            <>
              <ChallengeCardGrid>
                {pastExercises.map(
                  ({
                    description,
                    ended,
                    id,
                    image,
                    name,
                    latestSubmissions,
                    slug,
                    submissionCount,
                    submittedResponse,
                    tags,
                  }) => (
                    <ChallengeCard
                      description={description}
                      key={id}
                      link={`${url}/${slug}`}
                      linkText="See Exercise"
                      maintainTagsSpaceIfNoTags={true}
                      status={[
                        ...(ended ? [Statuses.CLOSED] : []),
                        ...(submittedResponse ? [Statuses.SOLVED_BY_USER] : []),
                      ]}
                      tags={tags}
                      thumbnail={image}
                      title={name}
                    >
                      <Submissions
                        submissionTitle={submissionTitle}
                        submissionSubtitle={submissionSubtitle}
                        count={submissionCount}
                        items={
                          latestSubmissions.map((submission) => ({
                            id: submission.id,
                            created: submission.created,
                            avatar: submission.user.avatar,
                            firstName: submission.user.firstName,
                            userType: getUserType(submission.user),
                          })) ?? []
                        }
                      />
                    </ChallengeCard>
                  ),
                )}
              </ChallengeCardGrid>
              <Pagination
                contentRef={pastExercisesRef}
                current={Math.min(page, pageCount)}
                hash={hash}
                search={search}
                state={state}
                total={pageCount}
                url={url}
              />
            </>
          ) : (
            <NoResults />
          )}
        </Section>
        {isPremium ? (
          <PremiumCTA buttonName="SEE SOLUTIONS" buttonTo="/videos/all" />
        ) : (
          <PremiumCTA buttonName="GO PREMIUM" buttonTo="/premium" />
        )}
      </Content>
      <HelpSection>
        <Help>
          <HelpTitle>What is an exercise?</HelpTitle>
          <HelpText>
            Refine your data visualization and storytelling skills through
            targeted practice. Each exercise is inspired by a real-world
            scenario and provides an opportunity to apply specific techniques,
            get to know your tools better, and build good habits for
            communicating effectively with data.
          </HelpText>
          <HelpText>
            New exercises are introduced regularly, and the exercise bank
            remains open for exploration and execution of past exercises, which
            never expire. Submitting a solution unlocks those that others have
            created and shared, both from the community and the SWD team.
            Compare and contrast your approach and engage in conversations with
            others who have solved the given exercise to further your learning.
          </HelpText>
        </Help>
      </HelpSection>
    </Container>
  );
};

export default connect(ExerciseRoot);
