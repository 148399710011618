import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg stroke="currentColor" viewBox="0 0 24 24" {...props}>
    {children}
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.5"
      d="M2 22L22 2m0 20L2 2"
    />
  </svg>
));
