import React, { FC } from 'react';
import { Check, Container, Line, Step, StepNumber, Steps } from './styles';
import { Props } from './types';

const OnboardingSteps: FC<Props> = ({
  baseUrl,
  current,
  search,
  sections,
  ...rest
}) => {
  const currentNumber = sections.indexOf(current) + 1;

  return (
    <Container numberOfSteps={sections.length} {...rest}>
      <Line aria-hidden={true} />
      <Steps>
        {sections.map((section, index) => {
          const number = index + 1;

          return (
            <Step
              key={section}
              reached={currentNumber >= number}
              to={{
                search,
                pathname: `${baseUrl}/${section}`,
                state: {
                  onboardingAllowed: true,
                  scrollToTop: false,
                },
              }}
            >
              {currentNumber > number ? (
                <Check />
              ) : (
                <StepNumber>{number}</StepNumber>
              )}
            </Step>
          );
        })}
      </Steps>
    </Container>
  );
};

export default OnboardingSteps;
