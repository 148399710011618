import { Validator } from 'redux-form';

// From https://emailregex.com/
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const GROUP_CODE_REGEX = /^[a-zA-Z0-9-_]{1,30}$/;
export const email: Validator = (value) =>
  value && !EMAIL_REGEX.test(value) ? 'Invalid email address' : undefined;

export const groupCode: Validator = (value) =>
  value && !GROUP_CODE_REGEX.test(value) ? 'Invalid group code' : undefined;

export const required: Validator = (value) => {
  if (Array.isArray(value) && value.length === 0) {
    return 'This field is required';
  }

  if (value || value === 0) {
    return undefined;
  }

  return 'This field is required';
};

export const maxLength = (length: number): Validator => (value) =>
  `${value}`.length > length
    ? `This field can't have more than ${length} characters.`
    : undefined;

export const isLinkedinUrl: Validator = (value) => {
  if (
    value &&
    !RegExp(/^(https?:\/\/)?([a-z]+\.)?linkedin\.com\/in\/.+/).test(value)
  ) {
    return 'Please, provide a valid LinkedIn profile link.';
  }

  return undefined;
};

export const title = maxLength(120);
