import { connect } from 'react-redux';
import {
  closeModal,
  selectPremiumInterruptionModalOpen,
} from 'redux/modules/modal';
import { selectPremiumLowestMonthlyPrice } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  lowestPrice: selectPremiumLowestMonthlyPrice(state),
  open: selectPremiumInterruptionModalOpen(state),
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
