import { BooleanInput, Checkbox, Label, Select } from 'components';
import { SearchOptionsFieldNames, Tag } from 'model';
import React, { FC, useCallback, useState } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import { getAvailableFilters } from './logic';
import {
  Button,
  ButtonsContainer,
  CheckboxContainer,
  CheckboxContent,
  CloseButton,
  CloseIcon,
  Container,
  FilterContainer,
  FilterDescription,
  FilterHeader,
  FiltersButton,
  FilterSection,
  FilterTitle,
  FilterTitleRow,
  FirstGroupFields,
  OrderBooleanInput,
  OrderDirection,
  OrderSelect,
  SearchInput,
  SecondGroupFields,
  VisuallyHiddenLabel,
} from './styles';
import { Props } from './types';

const YES_NO_OPTIONS = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
];

const tagToOption = ({ id, name }: { id: Tag['id']; name: Tag['name'] }) => ({
  label: name,
  value: id,
});

const Form: FC<Props> = ({
  className,
  contentType,
  conversationTags,
  enabledFilters,
  exerciseTags,
  groups,
  handleSubmit: parentHandleSubmit,
  industryTags,
  onClearFilters: parentOnClearFilters,
  reset,
  searchPlaceholder,
  toolTags,
  topicTags,
  typeTags,
  conversationTagSelected,
  exerciseTagSelected,
  pathname,
  videoSeriesTags,
  videoPresenterTags,
}) => {
  const { advancedCriteria, filters, order } = getAvailableFilters(contentType);
  const isSearchView = pathname === '/search';

  const advancedCriteriaAvailable =
    advancedCriteria.aboutExpire ||
    advancedCriteria.isUnsolved ||
    advancedCriteria.swdTeam;

  const filtersAvailable =
    advancedCriteriaAvailable ||
    filters.conversationTopic ||
    filters.exerciseTags ||
    filters.graphIndustry ||
    filters.graphTools ||
    filters.graphTopic ||
    filters.graphTypes ||
    filters.solved ||
    filters.videoSeries ||
    filters.videoPresenters;

  const orderingAvailable = order.length > 0;
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const toggleVisibility = useCallback(
    () => setShowFilterOptions((prevState) => !prevState),
    [],
  );

  const handleSubmit = useCallback(
    (values) => {
      setShowFilterOptions(false);
      parentHandleSubmit(values);
    },
    [parentHandleSubmit, setShowFilterOptions],
  );

  const onClearFilters = useCallback(() => {
    parentOnClearFilters();
    reset();
    setShowFilterOptions(false);
  }, [parentOnClearFilters, reset, setShowFilterOptions]);

  return (
    <Container className={className} onSubmit={handleSubmit}>
      <FirstGroupFields fullWidth={!orderingAvailable}>
        <Field
          component={SearchInput}
          name={SearchOptionsFieldNames.query}
          type="text"
          placeholder={searchPlaceholder}
        />
        {filtersAvailable && (
          <FiltersButton count={enabledFilters} onClick={toggleVisibility} />
        )}
      </FirstGroupFields>
      {orderingAvailable && (
        <SecondGroupFields>
          <VisuallyHiddenLabel htmlFor={SearchOptionsFieldNames.order}>
            Sort by
          </VisuallyHiddenLabel>
          <Field
            component={OrderSelect}
            name={SearchOptionsFieldNames.order}
            options={order}
          />
          <Field
            CheckboxComponent={OrderDirection}
            component={OrderBooleanInput}
            name={SearchOptionsFieldNames.directionIsAsc}
          />
        </SecondGroupFields>
      )}
      {showFilterOptions && (
        <FilterContainer>
          <FilterHeader>
            <FilterTitleRow>
              <FilterTitle>Filter by</FilterTitle>
              <CloseButton onClick={toggleVisibility}>
                <CloseIcon />
              </CloseButton>
            </FilterTitleRow>
            {isSearchView && (
              <FilterDescription>
                Get more specific results by selecting tags from below. Select a
                conversation tag to focus your search on conversation results or
                select an exercise target to get only exercise results.
              </FilterDescription>
            )}
          </FilterHeader>
          {(filters.graphTypes ||
            filters.graphTools ||
            filters.graphTopic ||
            filters.graphIndustry ||
            filters.videoSeries) && (
            <FilterSection>
              {filters.graphTypes && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="Type of visual"
                  name={SearchOptionsFieldNames.graphTypes}
                  options={typeTags.map(tagToOption)}
                  isDisabled={conversationTagSelected || exerciseTagSelected}
                />
              )}
              {filters.graphTools && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="Tool"
                  name={SearchOptionsFieldNames.graphTools}
                  options={toolTags.map(tagToOption)}
                  isDisabled={conversationTagSelected || exerciseTagSelected}
                />
              )}
              {filters.graphTopic && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="Topic"
                  name={SearchOptionsFieldNames.graphTopic}
                  options={
                    contentType === 'exerciseresponse'
                      ? topicTags
                          .filter((tag) => tag.name.toLowerCase() !== 'recap')
                          .map(tagToOption)
                      : topicTags.map(tagToOption)
                  }
                  isDisabled={conversationTagSelected || exerciseTagSelected}
                />
              )}
              {filters.graphIndustry && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="Department or Industry"
                  name={SearchOptionsFieldNames.graphIndustry}
                  options={industryTags.map(tagToOption)}
                  isDisabled={conversationTagSelected || exerciseTagSelected}
                />
              )}
              {filters.videoSeries && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="Video series"
                  name={SearchOptionsFieldNames.videoSeries}
                  options={videoSeriesTags.map(tagToOption)}
                  isDisabled={exerciseTagSelected}
                />
              )}
            </FilterSection>
          )}
          {(filters.conversationTopic ||
            filters.exerciseTags ||
            filters.videoPresenters) && (
            <FilterSection>
              {filters.videoPresenters && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="Video presenters"
                  name={SearchOptionsFieldNames.videoPresenters}
                  options={videoPresenterTags.map(tagToOption)}
                  isDisabled={exerciseTagSelected}
                />
              )}
              {filters.conversationTopic && (
                <Field
                  component={Select}
                  isClearable={true}
                  label={contentType === 'search' ? 'Conversation Tag' : 'Tag'}
                  name={SearchOptionsFieldNames.conversationTopic}
                  options={conversationTags.map(tagToOption)}
                  isDisabled={exerciseTagSelected}
                />
              )}
              {filters.exerciseTags && (
                <Field
                  component={Select}
                  isClearable={true}
                  label="SWD lesson"
                  name={SearchOptionsFieldNames.exerciseTags}
                  options={exerciseTags.map(tagToOption)}
                  isDisabled={conversationTagSelected}
                />
              )}
            </FilterSection>
          )}
          {filters.solved && (
            <FilterSection>
              <Field
                component={Select}
                isClearable={true}
                label="Solved by SWD Team"
                name={SearchOptionsFieldNames.solved}
                options={YES_NO_OPTIONS}
                placeholder="Both"
              />
            </FilterSection>
          )}
          {filters.author && (
            <FilterSection fillSpace={isSearchView}>
              <Field
                component={Select}
                isClearable={true}
                label="Author"
                name={SearchOptionsFieldNames.author}
                options={groups.map(tagToOption)}
              />
            </FilterSection>
          )}
          {advancedCriteriaAvailable && (
            <CheckboxContainer>
              <Label>Advanced criteria</Label>
              <CheckboxContent>
                {advancedCriteria.aboutExpire && (
                  <Field
                    CheckboxComponent={Checkbox}
                    component={BooleanInput}
                    name={SearchOptionsFieldNames.aboutExpire}
                    label="Only about to expire"
                  />
                )}
                {advancedCriteria.swdTeam && (
                  <Field
                    CheckboxComponent={Checkbox}
                    component={BooleanInput}
                    name={SearchOptionsFieldNames.swdTeam}
                    label="Only reviewed by SWD team"
                  />
                )}
                {advancedCriteria.isUnsolved && (
                  <Field
                    CheckboxComponent={Checkbox}
                    component={BooleanInput}
                    name={SearchOptionsFieldNames.isUnsolved}
                    label="Only unsolved requests"
                  />
                )}
              </CheckboxContent>
            </CheckboxContainer>
          )}
          <ButtonsContainer>
            <Button onClick={onClearFilters} type="button" variant="secondary">
              Clear
            </Button>
            <Button type="submit">Apply filter</Button>
          </ButtonsContainer>
        </FilterContainer>
      )}
    </Container>
  );
};

export default connect(Form);
