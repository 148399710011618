import Images from 'containers/Common/Images';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import ExerciseDetailRoot from './ExerciseDetailRoot';
import ExerciseSubmission from './ExerciseSubmission';
import { Props } from './types';

const ExerciseDetail: FC<Props> = () => {
  const { exercise, exerciseSlug, getExercise, path, url } = useConnect();
  const name = exercise?.name ?? 'Loading...';

  useEffect(() => {
    getExercise({ slug: exerciseSlug, silent: true });
  }, [getExercise, exerciseSlug]);

  return (
    <>
      <BreadcrumbsItem to={url}>{name}</BreadcrumbsItem>
      <Switch>
        <Route component={Images} path={`${path}/images`} />
        <Route component={Images} path={`${path}/:submissionSlug/images`} />
        <Route
          component={Images}
          path={`${path}/:submissionSlug/:commentId/comment-image`}
        />
        <Route component={ExerciseDetailRoot} path={`${path}/video-solution`} />
        <Route path={`${path}/:submissionSlug`}>
          <ExerciseSubmission />
        </Route>
        <Route component={ExerciseDetailRoot} path={path} />
      </Switch>
    </>
  );
};

export default ExerciseDetail;
