import clamp from 'lodash/clamp';
import type { Mark } from 'prosemirror-model';
import type { Transaction } from 'prosemirror-state';
import { findChildrenByMark, NodeWithPos } from 'prosemirror-utils';

import type { EditorState } from '../../types';
import { ModifyOptionsStep, ModifySelectedIndexStep } from './actions';
import type { State } from './types';

export const applyTransactionToState = (
  tr: Transaction,
  value: State,
): State => {
  const modifyOptionsStep = tr.steps.find(
    (step): step is ModifyOptionsStep => step instanceof ModifyOptionsStep,
  );

  const options = modifyOptionsStep ? modifyOptionsStep.options : value.options;

  const modifyIndexSteps = tr.steps.filter(
    (step): step is ModifySelectedIndexStep =>
      step instanceof ModifySelectedIndexStep,
  );

  // Conceptually, many steps can go in the same transaction, so we
  // consider this possibility by processing each step serially. Though,
  // in practice we will only have one step at a time.
  const index = modifyIndexSteps.reduce((index, step) => {
    if (typeof step.type === 'number') {
      return step.type;
    }
    switch (step.type) {
      case 'increment':
        if (index >= value.options.length - 1) {
          return 0;
        }
        return index + 1;
      case 'decrement':
        if (index <= 0) {
          return value.options.length - 1;
        }
        return index - 1;
      default:
      case 'reset':
        return 0;
    }
  }, value.selectedOptionIndex);

  return {
    options,
    // We clamp the index in case the options have been modified to prevent
    // an invalid index.
    selectedOptionIndex: clamp(index, 0, options.length - 1),
  };
};

export const getCurrentReference = (state: EditorState): Mark | undefined =>
  state.selection.$from
    .marks()
    .find((mark) => mark.type === state.schema.marks.reference);

export const getCurrentReferenceNode = (
  state: EditorState,
  mark = getCurrentReference(state),
): NodeWithPos | undefined => {
  if (!mark) {
    return undefined;
  }

  const { $from } = state.selection;

  const result = findChildrenByMark(
    $from.parent,
    state.schema.marks.reference,
  ).find(
    ({ node }) =>
      node.marks.find((mark) => mark.type === state.schema.marks.reference)
        ?.attrs.id === mark.attrs.id,
  );

  if (!result) {
    return undefined;
  }

  const { node, pos: posWithinParent } = result;
  const parentStart = $from.pos - $from.parentOffset;
  const posWithinDoc = posWithinParent + parentStart;

  return {
    node,
    pos: posWithinDoc,
  };
};
