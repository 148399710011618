import { Modal as DefaultModal } from 'components';
import { TitleContainer } from 'containers/Common/ConversationView/styles';
import styled, { css } from 'styled-components';

export const Modal = styled(DefaultModal)<{ $hasImage: boolean }>`
  ${TitleContainer} {
    margin-bottom: 1rem;
  }
  & .MuiPaper-root {
    ${({ fullScreen, $hasImage }) =>
      fullScreen
        ? css`
            height: auto;
            min-height: 100vh;
          `
        : css`
            max-width: ${$hasImage ? 1170 : 920}px;
            width: 90vw;
          `};
  }
`;
