import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.span).attrs({
  animate: 'shown',
  exit: 'hidden',
  initial: 'hidden',
  variants: {
    shown: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  },
  transition: {
    duration: 0.1,
  },
})<{
  $left: number;
  $top: number;
}>`
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
  display: flex;
  left: ${({ $left }) => $left}px;
  position: absolute;
  top: ${({ $top }) => $top}px;
  z-index: 999;
  transform: translateX(-50%);
`;

export const Section = styled.div`
  display: flex;
  padding: 0.375rem 0.75rem;

  :not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.grey200};
  }

  > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;
