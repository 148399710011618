import { ConfigProps, submit } from 'redux-form';

export const submitOnChange = <
  FormModel,
  FieldKey extends keyof FormModel = keyof FormModel
>(
  field: FieldKey,
  isEqual: (
    a: FormModel[FieldKey] | undefined,
    b: FormModel[FieldKey] | undefined,
  ) => boolean = (a, b) => a === b,
): NonNullable<ConfigProps<FormModel>['onChange']> => (
  values,
  dispatch,
  props,
  prevValues,
) => {
  // If there are no prevValues it means that the form is in the process of
  // initialization and the initial values are being set. Don't dispatch submit
  // actions for the initial values.
  if (Object.keys(prevValues).length === 0) {
    return;
  }

  if (!isEqual(values[field], prevValues[field])) {
    dispatch(submit(props.form ?? ''));
  }
};
