import React, { memo } from 'react';
import { Button, PlusIcon } from './styles';

const UserProfileFollowButton = () => {
  return (
    <Button variant="primary">
      Follow <PlusIcon />
    </Button>
  );
};

export default memo(UserProfileFollowButton);
