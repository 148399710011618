import { Course } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getCourses } from './actions';

export const getCoursesEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getCourses.request)),
    mergeMap(() => {
      return request<Course[]>({
        path: 'courses/search',
      }).pipe(
        mergeMap((res) => [getCourses.success(res)]),
        catchError(() => [getCourses.failure()]),
      );
    }),
  );

export default combineEpics(getCoursesEpic);
