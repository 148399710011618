import { Action, Reducer } from 'redux';

/*
 * This is a simpler implementation of reduce-reducers ([1]) that is compliant
 * with standard Redux reducers.
 *
 * A standard Redux reducer must be able to accept an undefined state, and must
 * return the default state in that scenario. The reduce-reducers utility
 * returns a reducer that will not accept an undefined state (and thus, forces
 * us to pass an initial state). This reducer is not accepted by redux's
 * combineReducers and therefore we have to re-implement the library.
 *
 * [1]: https://github.com/redux-utilities/reduce-reducers/
 */
export const composeReducers = <S, T extends Action>(
  firstReducer: Reducer<S, T>,
  ...reducers: Reducer<S, T>[]
): Reducer<S, T> => (prevState, action) => {
  const firstState = firstReducer(prevState, action);

  return reducers.reduce(
    (nextState, reducer) => reducer(nextState, action),
    firstState,
  );
};
