import { User } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const changePassword = createAsyncAction(
  'auth/CHANGE_PASSWORD_REQUEST',
  'auth/CHANGE_PASSWORD_SUCCESS',
  'auth/CHANGE_PASSWORD_FAILURE',
)<{ oldPassword: string; newPassword: string }, undefined, undefined>();

export const logIn = createAsyncAction(
  'auth/LOG_IN_REQUEST',
  'auth/LOG_IN_SUCCESS',
  'auth/LOG_IN_FAILURE',
)<{ email: string; password: string }, User, undefined>();

export const saveToken = createAsyncAction(
  'auth/SAVE_TOKEN_REQUEST',
  'auth/SAVE_TOKEN_SUCCESS',
  'auth/SAVE_TOKEN_FAILURE',
)<{ token: string }, User, undefined>();

export const requestResetPassword = createAsyncAction(
  'auth/REQUEST_RESET_PASSWORD_REQUEST',
  'auth/REQUEST_RESET_PASSWORD_SUCCESS',
  'auth/REQUEST_RESET_PASSWORD_FAILURE',
)<{ email: string }, undefined, undefined>();

export const resetPassword = createAsyncAction(
  'auth/RESET_PASSWORD_REQUEST',
  'auth/RESET_PASSWORD_SUCCESS',
  'auth/RESET_PASSWORD_FAILURE',
)<
  {
    token: string;
    firstName?: string;
    lastName?: string;
    password: string;
    repeatPassword: string;
    isNewUser?: boolean;
  },
  User,
  undefined
>();

export const setSecondaryEmail = createAsyncAction(
  'auth/SET_SECONDARY_EMAIL_REQUEST',
  'auth/SET_SECONDARY_EMAIL_SUCCESS',
  'auth/SET_SECONDARY_EMAIL_FAILURE',
)<{ token: string; email: string; password: string }, User, undefined>();

export const logOut = createAsyncAction(
  'auth/LOG_OUT_REQUEST',
  'auth/LOG_OUT_SUCCESS',
  'auth/LOG_OUT_FAILURE',
)<undefined, undefined, undefined>();

export const signUp = createAsyncAction(
  'auth/SIGN_UP_REQUEST',
  'auth/SIGN_UP_SUCCESS',
  'auth/SIGN_UP_FAILURE',
)<
  {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  },
  undefined,
  undefined
>();

export const editProfile = createAsyncAction(
  'auth/EDIT_PROFILE_REQUEST',
  'auth/EDIT_PROFILE_SUCCESS',
  'auth/EDIT_PROFILE_FAILURE',
)<
  Partial<{
    avatar: string | null;
    companyName: string | null;
    companyRole: string | null;
    email: string | null;
    firstName: string;
    isOnboarding: boolean;
    hasFinishedOnboarding: boolean;
    lastName: string;
    location: string;
    socialInstagram: string | null;
    socialLinkedin: string | null;
    socialTwitter: string | null;
    socialWeb: string | null;
    firstLogin: boolean;
  }>,
  User,
  undefined
>();

export const setGroupCode = createAsyncAction(
  'auth/SET_GROUP_CODE_REQUEST',
  'auth/SET_GROUP_CODE_SUCCESS',
  'auth/SET_GROUP_CODE_FAILURE',
)<{ onboardingCode: string }, undefined, undefined>();

export const verify = createAsyncAction(
  'auth/VERIFY_REQUEST',
  'auth/VERIFY_SUCCESS',
  'auth/VERIFY_FAILURE',
)<undefined, User | null, undefined>();

export const support = createAsyncAction(
  'auth/SUPPORT_REQUEST',
  'auth/SUPPORT_SUCCESS',
  'auth/SUPPORT_FAILURE',
)<{ inquiryType: string; issueDescription: string }, undefined, undefined>();

export const deleteAccount = createAsyncAction(
  'auth/DELETE_ACCOUNT_REQUEST',
  'auth/DELETE_ACCOUNT_SUCCESS',
  'auth/DELETE_ACCOUNT_FAILURE',
)<undefined, undefined, undefined>();
