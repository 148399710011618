import { Button } from 'components';
import { Container as DefaultInput } from 'components/BaseInput/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  width: 100%;
  ${DefaultInput} {
    height: 2.75rem;
    width: 100%;
    margin-bottom: 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }

    > :first-child {
      height: 100%;
      padding-right: 0.75rem;
    }
  }
`;

export const Section = styled.div`
  margin: 0.5rem 0 1rem;
  width: 100%;
`;

export const CorporateSection = styled.div`
  margin-top: 1.25rem;
  width: 100%;
`;

export const SectionTitle = styled.h3`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.5;
  margin: 0 0 ${({ theme }) => theme.spacing.sm};
  ${from.mobileXL`
    margin:0 0 0.5rem;
  `}
`;

export const SectionDescription = styled.p`
  margin: 0 0 ${({ theme }) => theme.spacing.sm};
  font-size: 0.875rem;
  line-height: 1.5;
`;

export const SubmitButton = styled(Button)<{ $disabled: boolean }>`
  width: 100%;
  margin-top: 1.2rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.sky};
      pointer-events: none;
    `}
`;
