import {
  LoginForm,
  LOGIN_FORM_KEY,
  SignUpForm,
  SIGN_UP_FORM_KEY,
  validateSignUpForm as validate,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const { selectValues: selectLoginValues } = makeFormSelectors<LoginForm>(
  LOGIN_FORM_KEY,
);

const mapStateToProps = (state: RootState) => {
  const login = selectLoginValues(state);

  const initialValues: ConfigProps<SignUpForm>['initialValues'] = {
    email: login ? login.email : '',
    firstName: '',
    lastName: '',
    password: login ? login.password : '',
    passwordConfirm: '',
  };

  return {
    initialValues,
    loading: state.auth.loading,
    search: state.router.location.search,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<SignUpForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<SignUpForm>({
    validate,
    form: SIGN_UP_FORM_KEY,
  }),
) as FormDecorator<SignUpForm, OwnProps, ConnectedProps>;
