import styled from 'styled-components';

export const LastComment = styled.div`
  margin-right: ${({ theme }) => theme.spacing.sm};
  grid-column: 1 / -1;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.conversationComment};

  > :first-child {
    color: ${({ theme }) => theme.colors.black};
  }
  > :last-child {
    white-space: nowrap;
  }

  > * {
    margin: 0;
  }
`;
