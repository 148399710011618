import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;
  }

  ${from.tablet`
    justify-content: space-between;
    
    > * {
      width: 48%;
    }
  `};

  ${from.laptop`
    > * {
      flex: 1;
      width: 25%;
      margin-right: ${({ theme }) => theme.spacing.md};
      :last-child {
        margin-right: 0;
      }
    }
  `};
`;
