import { serializeHTML } from 'components/Editor';
import { ConversationForm } from 'model';
import React from 'react';
import { deleteConversation } from 'redux/modules/conversation';
import { Props } from './types';

export const handleDelete = ({
  id,
  openConfirmationModal,
}: {
  id?: string;
  openConfirmationModal: Props['openConfirmationModal'];
}) => () => {
  if (id) {
    openConfirmationModal({
      acceptButtonLabel: 'Delete',
      acceptButtonVariant: 'danger',
      cancelButtonLabel: 'Cancel',
      title: <span>Are you sure?</span>,
      description: (
        <span>
          <strong>Deleting this conversation can’t be undone</strong>. This
          entry and its associated images, comments, datapoints... will be
          deleted in all the sections of this website.
        </span>
      ),
      onAccept: [deleteConversation.request({ id })],
    });
  }
};

export const handleSubmit = ({
  editConversation,
  id,
}: {
  editConversation: Props['editConversation'];
  id?: string;
}) => (values: ConversationForm) => {
  if (id) {
    editConversation({
      id,
      conversationTopic: values.tags,
      description: serializeHTML(values.description),
      title: values.title,
    });
  }
};
