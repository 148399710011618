import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { deleteMember, resendInvite } from 'redux/modules/premium';
import {
  selectMembers,
  selectMembersQuantity,
} from 'redux/modules/premium/selectors';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const members = useSelector(selectMembers);
  const { url } = useRouteMatch();
  const quantity = useSelector(selectMembersQuantity);

  const actions = useActions({
    deleteMember: deleteMember.request,
    resendInvite: resendInvite.request,
  });

  return {
    ...actions,
    members,
    quantity,
    url,
  };
};
