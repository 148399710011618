import BaseInput from 'components/BaseInput';
import React, { FC, memo } from 'react';
import { HTMLTextArea } from './styles';
import { Props } from './types';

const Textarea: FC<Props> = ({ input, label, meta, ...rest }) => (
  <BaseInput name={input.name} label={label} meta={meta}>
    <HTMLTextArea
      id={input.name}
      error={meta.error && meta.touched}
      {...input}
      {...rest}
    />
  </BaseInput>
);

export default memo(Textarea);
