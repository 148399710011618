import SearchOptions from 'containers/Common/SearchOptions';
import {
  FilterContainer,
  FirstGroupFields,
  SecondGroupFields,
} from 'containers/Common/SearchOptions/Form/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const ChallengeSearchOptions = styled(SearchOptions)`
  ${from.tablet`
    grid-template-columns: 1fr 1fr;

    ${FirstGroupFields} {
      grid-column: 1/2;
    }

    ${SecondGroupFields} {
      grid-column: 2/2;
    }
    
    ${FilterContainer} {
      grid-column: 1/2;
    }
  `};
`;
