import debounce from 'lodash/debounce';
import {
  SearchMembersForm,
  SEARCH_MEMBERS_FORM_DEFAULT_VALUES,
  SEARCH_MEMBERS_FORM_KEY,
} from 'model';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { getCourses, selectCourses } from 'redux/modules/courses';
import { RootState } from 'redux/modules/types';
import { SEARCH_INPUT_DEBOUNCE_TIME_MS } from 'utils/config';
import { execAll } from 'utils/execAll';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { parseOrder } from 'utils/parseOrder';
import { submitOnChange } from 'utils/submitOnChange';
import { OwnProps } from './types';

const { selectIsPristine, selectValues } = makeFormSelectors<SearchMembersForm>(
  SEARCH_MEMBERS_FORM_KEY,
);

const defaultValues: SearchMembersForm = SEARCH_MEMBERS_FORM_DEFAULT_VALUES;

const mapStateToProps = (state: RootState) => {
  const { order, ...search } = parse(state.router.location.search);

  const initialValues: ConfigProps<SearchMembersForm>['initialValues'] = {
    ...defaultValues,
    ...search,
    ...(typeof order === 'string' && order ? parseOrder(order) : {}),
  };

  return {
    initialValues,
    location: state.router.location,
    isPristine: selectIsPristine(state),
    courses: selectCourses(state),
    loading: state.users.loading,
    order: selectValues(state)?.order,
  };
};

const mapDispatchToProps = {
  submit,
  getCourses: getCourses.request,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<SearchMembersForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<SearchMembersForm>({
    enableReinitialize: true,
    form: SEARCH_MEMBERS_FORM_KEY,
    destroyOnUnmount: false,
    onChange: execAll(
      debounce(
        submitOnChange<SearchMembersForm>('query'),
        SEARCH_INPUT_DEBOUNCE_TIME_MS,
      ),
      submitOnChange<SearchMembersForm>('order'),
      submitOnChange<SearchMembersForm>('directionIsAsc'),
      submitOnChange<SearchMembersForm>('course'),
    ),
  }),
) as FormDecorator<SearchMembersForm, OwnProps, ConnectedProps>;
