import DefaultEventImage from 'assets/images/event/cole_presenting_graph_in_background.png';
import DefaultEventImageWebp from 'assets/images/event/cole_presenting_graph_in_background.webp';
import React, { FC, memo } from 'react';
import { useLocation } from 'react-router';
import {
  Button,
  Container,
  Countdown,
  Description,
  Image,
  Overlay,
  Title,
} from './styles';
import { Props } from './types';

const UpcomingLiveEvent: FC<Props> = ({
  buttonText,
  buttonLink,
  description,
  image,
  link,
  isPremium,
  isVimeoEvent,
  startDate,
  slug,
  title,
  ...rest
}) => {
  const { pathname } = useLocation();
  return (
    <Container {...rest}>
      {image ? (
        <picture>
          <Image src={image} alt="" aria-hidden="true" />
        </picture>
      ) : (
        <picture>
          <source srcSet={DefaultEventImageWebp} type="image/webp" />
          <source srcSet={DefaultEventImage} type="image/png" />
          <Image src={DefaultEventImage} alt="" aria-hidden="true" />
        </picture>
      )}
      <Overlay />
      <Title>{title}</Title>

      {startDate && <Countdown to={new Date(startDate)} />}
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      {isPremium ? (
        <Button
          variant="premiumAccent"
          to={
            buttonLink ?? {
              pathname: `${pathname}/register/more-info/${slug}`,
              state: {
                scrollToTop: false,
              },
            }
          }
        >
          {buttonText ?? 'Register now!'}
        </Button>
      ) : (
        <Button variant="premiumAccent" to="/premium">
          Go Premium
        </Button>
      )}
    </Container>
  );
};

export default memo(UpcomingLiveEvent);
