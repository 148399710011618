import { BookingNotification } from 'model';
import { up } from 'utils/Paths';
import { Props } from './types';

export const handleAddToCalendar = ({
  hourId,
  updateBooking,
}: {
  hourId?: string;
  updateBooking: Props['updateBooking'];
}) => () => {
  if (hourId) {
    updateBooking({
      addToCalendar: true,
      session: hourId,
    });
  }
};

export const handleBook = ({
  createBooking,
  hourId,
}: {
  createBooking: Props['createBooking'];
  hourId?: string;
}) => () => {
  if (hourId) {
    createBooking({ hourId });
  }
};

export const handleCancelBook = ({
  deleteBooking,
  hourId,
}: {
  deleteBooking: Props['deleteBooking'];
  hourId?: string;
}) => () => {
  if (hourId) {
    deleteBooking({ id: hourId });
  }
};

export const handleClose = ({
  push,
  url,
}: {
  push: Props['history']['push'];
  url?: string;
}) => () => {
  if (url) {
    push(up(url));
  }
};

export const handleSaveReminder = ({
  hourId,
  onClose,
  updateBooking,
}: {
  hourId?: string;
  onClose: () => void;
  updateBooking: Props['updateBooking'];
}) => (sendNotification: BookingNotification) => {
  if (hourId) {
    updateBooking({
      sendNotification,
      session: hourId,
    });
  }

  onClose();
};
