import { Button } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: grid;
  ${from.laptop`
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  `}
`;

export const Title = styled.h2`
  margin: 0 0 1rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.primary};
  ${from.laptop`
    grid-column: span 2;
  `}
`;

export const CloseAccountContainter = styled.div`
  margin-top: 2.125rem;
  padding-top: 2.125rem;
  border-top: 1px solid ${({ theme }) => theme.colors.grey200};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
`;

export const Paragraph = styled.p`
  margin: 0;
  max-width: 21.25rem;
  line-height: 1.5;
`;

export const CloseButton = styled(Button)`
  flex-shrink: 0;
`;
