import { FormNameMap } from 'utils/forms';

export const UPDATE_COMPANY_DETAILS_CORPORATE_PREMIUM_FORM_KEY =
  'forms/UPDATE_COMPANY_DETAILS_CORPORATE_PREMIUM';

export interface UpdateCompanyDetailsCorporatePremiumForm {
  companyName: string;
}

export const UpdateCompanyDetailsPremiumNames: FormNameMap<UpdateCompanyDetailsCorporatePremiumForm> = {
  companyName: 'companyName',
};
