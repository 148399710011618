import { ArrowRightIcon } from 'components/icons';
import Link from 'components/Link';
import styled from 'styled-components';
import { visuallyHidden } from 'styles/mixins';

export const Container = styled(Link)`
  align-items: center;
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 0.875rem;
  height: 100%;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }

  :hover {
    text-decoration: underline;
  }
`;

export const Text = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1.5rem;
`;

export const VisuallyHiddenText = styled.span`
  ${visuallyHidden};
`;

export const Arrow = styled(ArrowRightIcon)`
  width: 1rem;
`;
