import {
  LocationChangeAction,
  LOCATION_CHANGE,
  RouterAction,
  RouterState,
} from 'connected-react-router';
import { Reducer } from 'redux';
import { composeReducers } from '../composeReducers';

export interface RouterStateWithLastLocation extends RouterState {
  lastLocation?: RouterState['location'];
}

export const addLastLocation = (
  routerReducer: Reducer<RouterState, LocationChangeAction>,
): Reducer<RouterStateWithLastLocation, LocationChangeAction> => {
  const initialState = routerReducer(undefined, ({
    type: '__INIT__',
  } as unknown) as LocationChangeAction);

  const lastLocationReducer: Reducer<RouterState, RouterAction> = (
    state = initialState,
    action,
  ) => {
    if (action.type === LOCATION_CHANGE && action.payload.action === 'PUSH') {
      return {
        ...state,
        lastLocation: state.location,
      };
    }

    return state;
  };

  return composeReducers(lastLocationReducer, routerReducer);
};
