import { styles as input } from 'components/RawInput/styles';
import styled, { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';
import { selectionMarker } from './highlightSelection/styles';
import { placeholder } from './Placeholder/styles';
import { reference } from './references/styles';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  width: 100%;
`;

const paragraphsNoExtraMargin = css`
  p {
    :first-child {
      margin-block-start: 0;
    }

    :last-child {
      margin-block-end: 0;
    }
  }
`;

export const blockquote = css`
  blockquote {
    background: ${({ theme }) => addAlpha(theme.colors.primary, 0.1)};
    border-left: 0.25rem solid ${({ theme }) => theme.colors.primary};
    margin: 1.5rem 0.625rem;
    padding: 0.5rem 0.625rem;

    ${paragraphsNoExtraMargin};
  }
`;

export const EditorContainer = styled.div<{ focused: boolean }>`
  ${input};
  box-sizing: border-box;
  flex: 1;
  min-height: 7.25rem;
  padding: ${({ theme }) =>
    `0 ${theme.spacing.md} ${theme.spacing.lg} ${theme.spacing.md}`};
  position: relative;

  ${({ theme, focused }) =>
    focused &&
    css`
      border: solid 1px ${theme.colors.accent};
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
    `}

  .ProseMirror {
    ${blockquote};
    ${placeholder};
    ${reference};
    ${selectionMarker};

    outline: none;
    line-height: 1.5rem;

    h1 {
      font-weight: ${({ theme }) => theme.weights.bold};
      font-size: 1.5rem;
    }

    h2 {
      font-weight: ${({ theme }) => theme.weights.semiBold};
      font-size: 1.25rem;
    }

    strong {
      font-weight: ${({ theme }) => theme.weights.bold};
    }

    li,
    ol {
      p {
        margin: 0;
      }
    }

    pre {
      display: flex;

      code {
        background-color: #e8ebed;
        flex: 1;
      }
    }
  }
`;
