import { useMediaQuery } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import { buildProfessionalInfo } from 'utils/profile';
import Data from './Data';
import {
  // AchievementContainer,
  // AchievementCount,
  // AchievementIcon,
  // AchievementsContainer,
  Actions,
  Avatar,
  CogIcon,
  // FollowButton,
  // FollowContainer,
  CogIconContainer,
  Container,
  DetailsContainer,
  EditProfile,
  EditProfileContainer,
  EditProfileContent,
  Location,
  LocationIcon,
  Name,
  NameContainer,
  PersonalInfoContainer,
  Position,
  ShareButton,
  SummaryContainer,
  UserDetailsContainer,
} from './styles';
import { Props } from './types';

const UserProfileHeader: FC<Props> = ({
  avatar,
  canEditProfile,
  companyName,
  companyRole,
  datapoints,
  location,
  name,
  url,
  userId,
  challengeStreak,
  userType,
  ...rest
}) => {
  const isMobile = !useMediaQuery(from.tabletH.query);
  const publicProfileUrl = `${up(url, 1)}`;
  const userProfileUrl = `${publicProfileUrl}/${userId}`;
  const publicUserProfileUrl = `${window.location.origin}${userProfileUrl}`;

  const shareButtonMessage = canEditProfile
    ? `Check me out on the SWD community!`
    : `Thought this user was interesting from the SWD community. Check ${name} out!: `;

  const hasSetProfessionalData = !!companyName || !!companyRole;

  const shareButtonComponent = (
    <ShareButton content={shareButtonMessage} url={publicUserProfileUrl} />
  );
  const dataComponent = (
    <Data challengeStreak={challengeStreak} datapoints={datapoints} />
  );

  return (
    <Container {...rest}>
      <UserDetailsContainer>
        <SummaryContainer>
          <Avatar src={avatar} profile={true} variant={userType} />

          <DetailsContainer>
            <NameContainer>
              <Name>{name}</Name>

              <Actions>
                {/* @todo Pending Achievements */}
                {/* {!isFromTablet ? shareButtonComponent : null} */}
                {shareButtonComponent}

                {canEditProfile && (
                  <EditProfileContainer>
                    <CogIconContainer>
                      <CogIcon />
                    </CogIconContainer>

                    <EditProfileContent>
                      <EditProfile to={`${url}/settings/edit-profile`}>
                        Edit profile
                      </EditProfile>

                      <EditProfile to={userProfileUrl}>
                        See public profile
                      </EditProfile>
                    </EditProfileContent>
                  </EditProfileContainer>
                )}
              </Actions>
            </NameContainer>

            {isMobile ? dataComponent : null}
          </DetailsContainer>
        </SummaryContainer>

        <PersonalInfoContainer>
          {hasSetProfessionalData && (
            <Position>
              {buildProfessionalInfo(companyName, companyRole)}
            </Position>
          )}

          {location && (
            <Location>
              <LocationIcon />

              {location}
            </Location>
          )}
        </PersonalInfoContainer>

        {!isMobile ? dataComponent : null}

        {/* @todo Pending */}
        {/* {isFromTablet ? (
          <FollowContainer>
            <FollowButton />

            {shareButtonComponent}
          </FollowContainer>
        ) : null} */}
      </UserDetailsContainer>

      {/* @todo Pending achievements */}
      {/* <AchievementsContainer>
        <AchievementContainer>
          <AchievementIcon $color="accent" />
        </AchievementContainer>

        <AchievementContainer>
          <AchievementIcon $color="primary" />

          <AchievementCount>3</AchievementCount>
        </AchievementContainer>

        <AchievementContainer>
          <AchievementIcon $color="grey400" />

          <AchievementCount>3</AchievementCount>
        </AchievementContainer>

        <AchievementContainer>
          <AchievementIcon $color="accent" />
        </AchievementContainer>

        <AchievementContainer>
          <AchievementIcon $color="accent" />

          <AchievementCount>3</AchievementCount>
        </AchievementContainer>

        <AchievementContainer>
          <AchievementIcon $color="accent" />
        </AchievementContainer>
      </AchievementsContainer> */}
    </Container>
  );
};

export default memo(UserProfileHeader);
