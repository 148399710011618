import { Button, Textarea } from 'components';
import React, { ChangeEvent, FC, useState } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import {
  ActionZone,
  Container,
  Error,
  Instructions,
  NumberSeats,
} from './styles';
import { Props } from './types';

import { AddMembersCorporatePremiumNames } from 'model/forms/AddMembersCorporatePremium';

const Form: FC<Props> = ({
  emailError,
  numberSeats = 1,
  onClose,
  handleSubmit,
  currentSeats = 0,
}) => {
  const [inputValueLength, setInputValueLength] = useState<number>(
    currentSeats,
  );
  const moreThanSubscription = inputValueLength > numberSeats;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.split(',');
    const totalSeats = value.length + currentSeats;
    setInputValueLength(totalSeats);
  };

  const showChangeSubscriptionButton = false;

  return (
    <Container onSubmit={handleSubmit}>
      <NumberSeats $moreThanSubscription={moreThanSubscription}>
        {inputValueLength} / {numberSeats}
      </NumberSeats>
      <Instructions>
        Enter one or more email addresses of people you want to invite. comma
        separated
      </Instructions>
      <Field
        component={Textarea}
        name={AddMembersCorporatePremiumNames.emails}
        placeholder="member1@storytellingdata.com, member2@storytellingdata.com..."
        rows="8"
        type="text"
        onChange={handleInputChange}
      />
      {typeof emailError === 'string' && <Error text={emailError} />}
      <ActionZone>
        {moreThanSubscription && showChangeSubscriptionButton && (
          <Button variant="primary">Change subscription</Button>
        )}
        <Button variant="primary" disabled={moreThanSubscription} type="submit">
          send invitation email
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ActionZone>
    </Container>
  );
};

export default connect(Form);
