import { Loader, Member, NoResults, PageTitle } from 'components';
import { MembersSection } from 'containers/Main/Members/types';
import { getUserName } from 'model/User';
import React, { FC, useEffect, useRef } from 'react';
import { matchPath } from 'react-router';
import { Redirect } from 'react-router-dom';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { up } from 'utils/Paths';
import connect from './connect';
import { handleFiltersChanged, handleFormSubmitted } from './logic';
import {
  ActivityOrderEnum,
  MembersOrderEnum,
} from './MembersTable/MembersOrders/Form/types';
import {
  Container,
  Content,
  Header,
  HeaderShadow,
  Heading,
  Link,
  MemberGrid,
  MembersTable,
  Options,
  OutstandingMembers,
  Pagination,
  Paragraph,
  SearchOptions,
  Tabs,
  Title,
} from './styles';
import { Props, TabItem } from './types';

const TAB_ITEMS: TabItem[] = [
  {
    label: 'Activity',
    url: 'activity',
  },
  {
    label: 'SWD team',
    url: 'team',
  },
  {
    label: 'SWD scholars',
    url: 'scholars',
  },
  {
    label: 'All members',
    url: 'all',
  },
];

const Members: FC<Props> = ({
  getMembers,
  history: { push },
  loading,
  location: { search, state },
  match: {
    params: { period },
    url,
  },
  members,
  pageCount: defaultPageCount,
  wentBack,
}) => {
  const section =
    matchPath<{ section: MembersSection }>(url, '/members/:section')?.params
      ?.section ?? 'activity';

  const baseUrl = up(url, period ? 2 : 1);
  const page = getPageFromQuery(search);

  const optionsRef = useRef<HTMLDivElement>(null);

  const pageCount = defaultPageCount;

  useEffect(
    handleFiltersChanged({ getMembers, period, search, section, wentBack }),
    [getMembers, period, search, section, wentBack],
  );

  if (!period && (section === 'activity' || section === 'all')) {
    switch (section) {
      case 'activity':
        return (
          <Redirect
            to={`${baseUrl}/${section}/month?activityOrder=${ActivityOrderEnum.OVERALL_ACTIVITY}`}
          />
        );
      case 'all':
        return (
          <Redirect
            to={`${baseUrl}/${section}/all?order=${MembersOrderEnum.ALPHABETICAL}`}
          />
        );

      default:
        break;
    }
  }

  return (
    <Container>
      <HeaderShadow>
        <Header>
          <PageTitle title="SWD community members" />

          <Heading>
            <Title>community members</Title>

            <Paragraph>
              The SWD community has members from all over the world. Connect and
              engage with others who communicate with data.
            </Paragraph>
          </Heading>

          <Tabs variant="secondary" baseUrl={baseUrl} items={TAB_ITEMS} />
        </Header>
      </HeaderShadow>

      <Content>
        {section === 'activity' ? <OutstandingMembers period={period} /> : null}

        {section !== 'team' ? (
          <Options ref={optionsRef}>
            <SearchOptions
              handleFormSubmitted={handleFormSubmitted}
              baseUrl={baseUrl}
              push={push}
              section={section}
              period={period}
            />
          </Options>
        ) : null}

        {section === 'activity' ? (
          <MembersTable
            contentRef={optionsRef}
            search={search}
            state={state}
            url={url}
            handleFormSubmitted={handleFormSubmitted}
          />
        ) : (
          <>
            {loading ? (
              <Loader />
            ) : members.length > 0 ? (
              <>
                <MemberGrid>
                  {members.map((member) => (
                    <Link to={`/members/${member.slug}`} key={member.id}>
                      <Member
                        avatar={member.avatar}
                        location={member.location}
                        name={getUserName({
                          firstName: member.firstName,
                          lastName: member.lastName,
                        })}
                        userType={getUserType(member)}
                      />
                    </Link>
                  ))}
                </MemberGrid>

                <Pagination
                  contentRef={optionsRef}
                  current={Math.min(page, pageCount)}
                  state={state}
                  total={pageCount}
                  search={search}
                  url={url}
                />
              </>
            ) : (
              <NoResults />
            )}
          </>
        )}
      </Content>
    </Container>
  );
};

export default connect(Members);
