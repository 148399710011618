import CTALeftAsset from 'assets/images/bannerWhite/CTALeft.svg';
import CTARightAsset from 'assets/images/bannerWhite/CTARight.svg';
import React, { FC, useCallback, useEffect, useState } from 'react';
import connect from './connect';
import {
  BannerInfo,
  ButtonLink,
  Caption,
  CommunityIcon,
  Container,
  CTALeftImage,
  CTARightImage,
  Description,
  Title,
} from './styles';
import { LocationState, Props } from './types';

const VerifyEmail: FC<Props> = ({
  location: { pathname, state },
  history,
  sendActivationEmailRequest,
}) => {
  const locationState: LocationState | undefined = state;
  const success = locationState?.success;

  const [email] = useState(locationState?.email);

  useEffect(() => {
    if (!email) {
      history.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    if (success !== undefined) {
      setTimeout(() => {
        history.replace({
          pathname,
          state: {
            email,
          },
        });
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const onClickTryAgain = useCallback(() => {
    if (email) {
      sendActivationEmailRequest({ email });
    }
  }, [email, sendActivationEmailRequest]);

  if (!email) {
    return null;
  }

  return (
    <Container>
      <CTALeftImage alt="" aria-hidden="true" src={CTALeftAsset} />

      <CTARightImage alt="" aria-hidden="true" src={CTARightAsset} />

      <CommunityIcon alt="SWD Community logo" />

      <Title>Confirm your email</Title>

      <Description>
        You’re almost ready to start exploring the many exciting features the
        SWD community has to offer!
        <br />
        Before you do, we just need to verify your email address.
        <br />
        Simply, check your inbox and click the confirmation link to access the
        Community.
      </Description>

      <BannerInfo $hidden={!success}>New confirmation email sent!</BannerInfo>

      <Caption>
        If it doesn’t arrive within 3 minutes check your junk folders or{' '}
        <ButtonLink onClick={onClickTryAgain}>click here</ButtonLink> to
        resubmit the email.
      </Caption>
    </Container>
  );
};

export default connect(VerifyEmail);
