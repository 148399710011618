import DefaultAvatar from 'components/Avatar';
import DefaultImageListViewer from 'components/ImageListViewer';
import DefaultLink from 'components/Link';
import RichText from 'components/RichText';
import DefaultShareButton from 'components/ShareButton';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import DefaultTags from 'components/Tags';
import DefaultComments from 'containers/Common/Comments';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.mobile}
`};
  ${from.mobileXL`
    padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.desktop}`};
  `};
`;

export const PostWrapper = styled.div`
  ${container};
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 1.875rem;
`;

export const Post = styled.div`
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  padding: 2.5rem 1rem 1rem;
  ${from.tablet`
    padding: 2.5rem 2rem 2rem;
  `};
  ${from.laptop`
     padding: 2.5rem 3.75rem;
  `};
`;

export const Comments = styled(DefaultComments)`
  margin: 0 auto;
  padding-top: 3.75rem;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Title = styled.div`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1.375rem;
  margin: 0;
  padding-bottom: 1.125rem;

  ${from.mobileXL`
    padding-bottom:1.125rem;
  `};
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const InfoHeader = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: 3.6875rem auto;
  column-gap: ${({ theme }) => theme.spacing.md};
  row-gap: 0.5rem;
  margin: 1.1875rem 0 2rem;
`;

export const AvatarContainer = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  grid-row: 1/3;
`;

export const Avatar = styled(DefaultAvatar)`
  height: 3.75rem;
  width: 3.75rem;
  margin: 0;

  ${BadgeContainer} {
    height: 1.4375rem;
    width: 1.4375rem;
  }
`;

export const UserLink = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

export const UserName = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.5rem;
  margin: 0 0 0.5625rem;
`;

export const Tags = styled(DefaultTags)`
  grid-column: 1/3;
  ${from.mobileXL`

    grid-column: 2;
  `};
`;

export const ImageListViewer = styled(DefaultImageListViewer)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  overflow: hidden;
  ${from.tablet`
    width: 100%;
  `};
`;

export const InfoFooter = styled.div`
  background: ${({ theme }) => theme.colors.white};
  ${from.tablet`
    margin-right: 2.5rem;
    width: 42%;
  `};
`;

export const Context = styled(RichText)`
  margin: 0;
  padding: 0.75rem 0 1.875rem 0;

  p {
    margin: 0;
  }
`;

export const Text = styled.span``;

export const ShareButton = styled(DefaultShareButton)`
  margin-left: auto;
  div ~ div {
    right: -1.25rem;
    ${from.tablet`
    left: inherit;
    right: 0;
  `};
  }
`;
