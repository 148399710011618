import { User } from 'model';
import { connect } from 'react-redux';
import { getChallengeResponse } from 'redux/modules/challengeResponse';
import { getConversation } from 'redux/modules/conversation';
import { getDiscoverItem } from 'redux/modules/discover';
import { getExerciseResponse } from 'redux/modules/exerciseResponse';
import { getFeedbackRequest } from 'redux/modules/feedback';
import { RootState } from 'redux/modules/types';
import { DeepReadonly } from 'utility-types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  {
    match: {
      params: {
        challengeSlug,
        conversationId,
        discoverId,
        exerciseSlug,
        feedbackId,
        submissionSlug,
        commentId,
      },
    },
  }: OwnProps,
): {
  author?: DeepReadonly<User>;
  media?: ReadonlyArray<{
    file: string;
    mediaType: string;
    thumbnail?: string;
  }>;
  title?: string;
} => {
  function toMedia(file: string, type?: string) {
    return {
      file,
      mediaType: type ? type : 'IMAGE',
      thumbnail: file,
    };
  }

  if (commentId) {
    const response = state.comment.byCommentId[commentId];
    if (response) {
      return {
        author: response.user,
        media: response.image ? [toMedia(response.image)] : undefined,
      };
    }
  }

  if (challengeSlug) {
    if (submissionSlug) {
      const response = state.challengeResponse.bySlug[submissionSlug];
      if (response) {
        return {
          author: response.user,
          media: response.mediaList
            ? [
                response.mediaList.mainMedia,
                ...response.mediaList.extraMedia.map((extra) => extra),
              ]
            : undefined,
          title: response.title,
        };
      }
    } else {
      const challenge = state.challenge.bySlug[challengeSlug];
      if (challenge) {
        return {
          author: challenge.user,
          media: [toMedia(challenge.image)],
          title: challenge.name,
        };
      }
    }
  }

  if (conversationId) {
    const conversation = state.conversation.byId[conversationId];

    if (conversation?.amaImage) {
      return {
        author: conversation.user,
        media: [toMedia(conversation.amaImage)],
        title: conversation.title,
      };
    }
    if (conversation?.spotlightImage) {
      return {
        author: conversation.user,
        media: [toMedia(conversation.spotlightImage)],
        title: conversation.title,
      };
    }
  }

  if (discoverId) {
    const discover = state.discover.byId[discoverId];

    if (discover) {
      return {
        author: discover.user,
        media: [toMedia(discover.image)],
        title: discover.title,
      };
    }
  }

  if (exerciseSlug) {
    if (submissionSlug) {
      const response = state.exerciseResponse.bySlug[submissionSlug];

      if (response) {
        return {
          author: response.user,
          media: response.mediaList
            ? [response.mediaList.mainMedia, ...response.mediaList.extraMedia]
            : undefined,
          title: response.title,
        };
      }
    } else {
      const exercise = state.exercise.bySlug[exerciseSlug];
      if (exercise) {
        return {
          author: exercise.user,
          media: [toMedia(exercise.image)],
          title: exercise.name,
        };
      }
    }
  }

  if (feedbackId) {
    const feedback = state.feedback.byId[feedbackId];

    if (feedback) {
      return {
        author: feedback.user,
        media: feedback.imageList
          ? [
              feedback.imageList.mainImage,
              ...feedback.imageList.extraImages,
            ].map((file) => toMedia(file, 'IMAGE'))
          : undefined,
        title: feedback.title,
      };
    }
  }

  return {};
};

const mapDispatchToProps = {
  getChallengeResponse: getChallengeResponse.request,
  getConversation: getConversation.request,
  getDiscoverItem: getDiscoverItem.request,
  getExerciseResponse: getExerciseResponse.request,
  getFeedbackRequest: getFeedbackRequest.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
