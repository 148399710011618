import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { getMyOpenFeedbackItems } from './actions';
import { MyOpenFeedbackAction, MyOpenFeedbackState } from './types';

export const INITIAL_STATE: MyOpenFeedbackState = {};

const reducer: Reducer<MyOpenFeedbackState, MyOpenFeedbackAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getMyOpenFeedbackItems.success):
      return {
        feedback: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
