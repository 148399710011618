import { connect } from 'react-redux';
import { editExerciseResponse } from 'redux/modules/exerciseResponse';
import { RootState } from 'redux/modules/types';
import { openConfirmationModal } from 'utils/openConfirmationModal';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { match }: OwnProps) => ({
  exercise: state.exercise.bySlug[match?.params?.exerciseSlug || ''],
  submission:
    state.exerciseResponse.bySlug[match?.params?.submissionSlug || ''],
});

const mapDispatchToProps = {
  openConfirmationModal,
  editExerciseResponse: editExerciseResponse.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
