import { Button, Checkbox, RadioGroup, Textarea } from 'components';
import { ReportFormNames, Tag } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import {
  ButtonsContainer,
  CheckContainer,
  CommentsContainer,
  Container,
  Title,
} from './styles';
import { Props } from './types';

const tagToOption = ({ id, name }: Tag) => ({
  label: name,
  value: id,
});

const Form: FC<Props> = ({
  closeModal,
  handleSubmit,
  otherReason,
  reason,
  reportOptions,
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      <Title>Why do you want to report this content?</Title>
      <CheckContainer>
        <Field
          CheckboxComponent={Checkbox}
          component={RadioGroup}
          name={ReportFormNames.reason}
          options={reportOptions.map(tagToOption)}
        />
      </CheckContainer>
      <CommentsContainer>
        <Field
          component={Textarea}
          name={ReportFormNames.comments}
          label="Comments:"
          placeholder="Give us some info about this report."
          rows="5"
          validate={reason === otherReason ? required : undefined}
        />
      </CommentsContainer>
      <ButtonsContainer>
        <Button onClick={closeModal} type="button" variant="secondary">
          Cancel
        </Button>
        <Button type="submit">Send report</Button>
      </ButtonsContainer>
    </Container>
  );
};

export default connect(Form);
