import { connect } from 'react-redux';
import { setSecondaryEmail } from 'redux/modules/auth';

const mapDispatchToProps = {
  setSecondaryEmail: setSecondaryEmail.request,
};

export default connect(null, mapDispatchToProps);

export type ConnectedProps = typeof mapDispatchToProps;
