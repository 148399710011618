import { Discover, PaginatedResponse, UpsertByIdPayload } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const createDiscoverItem = createAsyncAction(
  'feedback/CREATE_DISCOVER_ITEM_REQUEST',
  'feedback/CREATE_DISCOVER_ITEM_SUCCESS',
  'feedback/CREATE_DISCOVER_ITEM_FAILURE',
)<
  {
    context: string;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    image: string;
    title: string;
  },
  Discover,
  undefined
>();

export const getDiscoverItems = createAsyncAction(
  'discover/GET_DISCOVER_ITEMS_REQUEST',
  'discover/GET_DISCOVER_ITEMS_SUCCESS',
  'discover/GET_DISCOVER_ITEMS_FAILURE',
)<
  { search: string; silent?: boolean },
  PaginatedResponse<Discover>,
  undefined
>();

export const getDiscoverItem = createAsyncAction(
  'discover/GET_DISCOVER_ITEM_REQUEST',
  'discover/GET_DISCOVER_ITEM_SUCCESS',
  'discover/GET_DISCOVER_ITEM_FAILURE',
)<{ id: string; silent?: boolean }, Discover, undefined>();

export const _upsertDiscoverById = createAction(
  'feedback/_UPSERT_DISCOVER_BY_ID',
)<UpsertByIdPayload<Discover>>();

export const deleteDiscoverItem = createAsyncAction(
  'discover/DELETE_DISCOVER_ITEM_REQUEST',
  'discover/DELETE_DISCOVER_ITEM_SUCCESS',
  'discover/DELETE_DISCOVER_ITEM_FAILURE',
)<{ discoverId: string }, { id: string }, undefined>();

export const editDiscoverItem = createAsyncAction(
  'discover/EDIT_DISCOVER_ITEM_REQUEST',
  'discover/EDIT_DISCOVER_ITEM_SUCCESS',
  'discover/EDIT_DISCOVER_ITEM_FAILURE',
)<
  Partial<{
    context: string;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    image: string | undefined;
    title: string;
  }> & {
    discoverId: string;
  },
  Discover,
  undefined
>();
