import {
  ChallengeResponse,
  MediaList,
  PaginatedResponse,
  UpsertByIdPayload,
} from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const createChallengeResponse = createAsyncAction(
  'challengeResponse/CREATE_CHALLENGE_RESPONSE_REQUEST',
  'challengeResponse/CREATE_CHALLENGE_RESPONSE_SUCCESS',
  'challengeResponse/CREATE_CHALLENGE_RESPONSE_FAILURE',
)<
  {
    addToMyGallery: boolean;
    challengeId: string;
    description: string;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    mediaList: MediaList;
    title: string;
  },
  ChallengeResponse,
  undefined
>();

export const deleteChallengeResponse = createAsyncAction(
  'challengeResponse/DELETE_CHALLENGE_RESPONSE_REQUEST',
  'challengeResponse/DELETE_CHALLENGE_RESPONSE_SUCCESS',
  'challengeResponse/DELETE_CHALLENGE_RESPONSE_FAILURE',
)<{ challengeId: string; responseId: string }, { id: string }, undefined>();

export const editChallengeResponse = createAsyncAction(
  'challengeResponse/EDIT_CHALLENGE_RESPONSE_REQUEST',
  'challengeResponse/EDIT_CHALLENGE_RESPONSE_SUCCESS',
  'challengeResponse/EDIT_CHALLENGE_RESPONSE_FAILURE',
)<
  Partial<{
    addToMyGallery: boolean;
    description: string;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    mediaList: MediaList;
    title: string;
  }> & {
    challengeId: string;
    responseId: string;
  },
  ChallengeResponse,
  undefined
>();

export const getChallengeResponse = createAsyncAction(
  'challengeResponse/GET_CHALLENGE_RESPONSE_REQUEST',
  'challengeResponse/GET_CHALLENGE_RESPONSE_SUCCESS',
  'challengeResponse/GET_CHALLENGE_RESPONSE_FAILURE',
)<
  { challengeSlug: string; challengeResponseSlug: string },
  ChallengeResponse,
  undefined
>();

export const getChallengeResponses = createAsyncAction(
  'challengeResponse/GET_CHALLENGE_RESPONSES_REQUEST',
  'challengeResponse/GET_CHALLENGE_RESPONSES_SUCCESS',
  'challengeResponse/GET_CHALLENGE_RESPONSES_FAILURE',
)<
  { challengeSlug: string; search?: string; silent?: boolean },
  PaginatedResponse<ChallengeResponse>,
  undefined
>();

export const _upsertChallengeResponsesById = createAction(
  'feedback/_UPSERT_CHALLENGE_RESPONSES_BY_ID',
)<UpsertByIdPayload<ChallengeResponse>>();
