/*
 * WARNING!
 *
 * This file was generated by the following autogen template:
 *
 * src/redux/modules/appReducers.ts.hbs
 *
 * Do not modify, as any changes will be overwritten!
 */

import { reducer as activeBanner } from './activeBanner';
import { reducer as activity } from './activity';
import { reducer as auth } from './auth';
import { reducer as challenge } from './challenge';
import { reducer as challengeResponse } from './challengeResponse';
import { reducer as comment } from './comment';
import { reducer as configuration } from './configuration';
import { reducer as conversation } from './conversation';
import { reducer as courses } from './courses';
import { reducer as discover } from './discover';
import { reducer as event } from './event';
import { reducer as exercise } from './exercise';
import { reducer as exerciseBank } from './exerciseBank';
import { reducer as exerciseResponse } from './exerciseResponse';
import { reducer as feedback } from './feedback';
import { reducer as home } from './home';
import { reducer as intent } from './intent';
import { reducer as liveEvents } from './liveEvents';
import { reducer as modal } from './modal';
import { reducer as myOpenFeedback } from './myOpenFeedback';
import { reducer as officeHour } from './officeHour';
import { reducer as premium } from './premium';
import { reducer as report } from './report';
import { reducer as search } from './search';
import { reducer as settings } from './settings';
import { reducer as snackbar } from './snackbar';
import { reducer as theme } from './theme';
import { reducer as users } from './users';
import { reducer as video } from './video';
import { reducer as vote } from './vote';

export const appReducers = {
  activeBanner,
  activity,
  auth,
  challenge,
  challengeResponse,
  comment,
  configuration,
  conversation,
  courses,
  discover,
  event,
  exercise,
  exerciseBank,
  exerciseResponse,
  feedback,
  home,
  intent,
  liveEvents,
  modal,
  myOpenFeedback,
  officeHour,
  premium,
  report,
  search,
  settings,
  snackbar,
  theme,
  users,
  video,
  vote,
};
