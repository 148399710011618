import { $HeaderRankingProps } from './types';

export const RANKING_STYLES: Record<number, $HeaderRankingProps> = {
  1: {
    background: 'basicGold',
    color: 'judge',
  },
  2: {
    background: 'grey200',
    color: 'grey400',
  },
  3: {
    background: 'judge',
    color: 'white',
    opacity: 0.5,
  },
};
