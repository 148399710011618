import {
  Banner,
  Challenge,
  Conversation,
  Discover,
  Exercise,
  ExerciseResponseExpanded,
  FeedbackRequest,
  FeedbackRequestExpanded,
  User,
} from 'model';
import { createAsyncAction } from 'typesafe-actions';
import { HomeAuthState, HomeGuestState } from './types';

export type GuestResponse = {
  feedback: FeedbackRequest[];
  conversations: Conversation[];
  challenge: Challenge;
  exercise: Exercise;
};

export type AuthenticatedResponse = {
  feedback: FeedbackRequest[];
  conversations: Conversation[];
  challenge: Challenge;
  latestExerciseResponses: ExerciseResponseExpanded[];
  openFeedback: FeedbackRequestExpanded;
  banner: Banner;
  visuals: Discover[];
  users: User[];
};

export const getHomeItemsGuest = createAsyncAction(
  'home/GET_HOME_ITEMS_GUEST_REQUEST',
  'home/GET_HOME_ITEMS_GUEST_SUCCESS',
  'home/GET_HOME_ITEMS_GUEST_FAILURE',
)<undefined, HomeGuestState | undefined, undefined>();

export const getHomeItemsAuthenticated = createAsyncAction(
  'home/GET_HOME_ITEMS_AUTHENTICATED_REQUEST',
  'home/GET_HOME_ITEMS_AUTHENTICATED_SUCCESS',
  'home/GET_HOME_ITEMS_AUTHENTICATED_FAILURE',
)<undefined, HomeAuthState, undefined>();
