import { serializeHTML } from 'components/Editor';
import { CommentForm, UPLOAD_MEDIA_FIELD } from 'model';
import { toggleReplyFormOpen as createToggleReplyFormOpenAction } from 'redux/modules/comment';
import { serializeIntent } from 'utils/intent';
import { isUploaded } from '../../../components/MediaInput';
import { Props } from './types';

export const handleFormSubmitted = ({
  contentType,
  createComment,
  id,
}: {
  contentType: Props['contentType'];
  createComment: Props['createComment'];
  id: Props['id'];
}) => ({ comment, [UPLOAD_MEDIA_FIELD]: media = [] }: CommentForm) => {
  const [image] = media.filter(isUploaded);

  createComment({
    contentType,
    comment: serializeHTML(comment),
    image: image?.file,
    objectId: id,
  });
};

export const handleToggleReplyFormOpen = ({
  beginIntent,
  loggedIn,
  toggleReplyFormOpen: dispatchToggleReplyFormOpen,
  url,
}: {
  beginIntent: Props['beginIntent'];
  loggedIn: Props['loggedIn'];
  toggleReplyFormOpen: Props['toggleReplyFormOpen'];
  url: Props['url'];
}) => (id: string) => {
  if (loggedIn) {
    dispatchToggleReplyFormOpen(id);
  } else {
    beginIntent({
      intent: serializeIntent([createToggleReplyFormOpenAction(id)]),
      returnTo: url,
    });
  }
};
