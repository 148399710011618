import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { beginIntent } from 'redux/modules/intent';
import { openReport } from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';
import { addVote, removeVote } from 'redux/modules/vote';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { authorId }: OwnProps) => ({
  loadingVote: state.vote.loading,
  locationState: state.router.location.state || {},
  loggedIn: !!state.auth.user,
  userIsAuthor: authorId === state.auth.user?.id,
  pathname: state.router.location.pathname,
});

const mapDispatchToProps = {
  beginIntent,
  push,
  openReport,
  addVote: addVote.request,
  removeVote: removeVote.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
