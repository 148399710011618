import { Props } from './types';

export const requestContent = ({
  challengeSlug,
  conversationId,
  discoverId,
  exerciseSlug,
  getChallengeResponse,
  getConversation,
  getDiscoverItem,
  getExerciseResponse,
  getFeedbackRequest,
  feedbackId,
  submissionSlug,
}: {
  challengeSlug: Props['match']['params']['challengeSlug'];
  conversationId: Props['match']['params']['conversationId'];
  discoverId: Props['match']['params']['discoverId'];
  exerciseSlug: Props['match']['params']['exerciseSlug'];
  getChallengeResponse: Props['getChallengeResponse'];
  getConversation: Props['getConversation'];
  getDiscoverItem: Props['getDiscoverItem'];
  getExerciseResponse: Props['getExerciseResponse'];
  getFeedbackRequest: Props['getFeedbackRequest'];
  feedbackId: Props['match']['params']['feedbackId'];
  submissionSlug: Props['match']['params']['submissionSlug'];
}) => () => {
  if (challengeSlug && submissionSlug) {
    getChallengeResponse({
      challengeSlug,
      challengeResponseSlug: submissionSlug,
    });
  }

  if (conversationId) {
    getConversation({ id: conversationId });
  }

  if (discoverId) {
    getDiscoverItem({ id: discoverId });
  }

  if (exerciseSlug && submissionSlug) {
    getExerciseResponse({ exerciseSlug, exerciseResponseSlug: submissionSlug });
  }

  if (feedbackId) {
    getFeedbackRequest({ id: feedbackId });
  }
};
