import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { CHALLENGE_RESPONSES_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import {
  createChallengeResponse,
  deleteChallengeResponse,
  editChallengeResponse,
  getChallengeResponse,
  getChallengeResponses,
  _upsertChallengeResponsesById,
} from './actions';
import { ChallengeResponseAction, ChallengeResponseState } from './types';

export const INITIAL_STATE: ChallengeResponseState = {
  ...defaultStandardState,
};

const reducer: Reducer<ChallengeResponseState, ChallengeResponseAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    create: {
      request: isActionOf(createChallengeResponse.request),
      success: isActionOf(createChallengeResponse.success),
      failure: isActionOf(createChallengeResponse.failure),
    },
    edit: {
      request: isActionOf(editChallengeResponse.request),
      success: isActionOf(editChallengeResponse.success),
      failure: isActionOf(editChallengeResponse.failure),
    },
    getMany: {
      request: isActionOf(getChallengeResponses.request),
      success: isActionOf(getChallengeResponses.success),
      failure: isActionOf(getChallengeResponses.failure),
    },
    getOne: {
      request: isActionOf(getChallengeResponse.request),
      success: isActionOf(getChallengeResponse.success),
      failure: isActionOf(getChallengeResponse.failure),
    },
    remove: {
      request: isActionOf(deleteChallengeResponse.request),
      success: isActionOf(deleteChallengeResponse.success),
      failure: isActionOf(deleteChallengeResponse.failure),
    },
    _upsertById: isActionOf(_upsertChallengeResponsesById),
  },
  contractItem: (it) => it,
  initialState: INITIAL_STATE,
  perPage: CHALLENGE_RESPONSES_PER_PAGE,
})(reducer);
