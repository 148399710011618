import { Props } from './types';

export const handleClick = ({
  onChange,
  value,
}: {
  onChange: Props['input']['onChange'];
  value: Props['input']['value'];
}) => () => {
  onChange(!value);
};
