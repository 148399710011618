import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { shouldCloseMenu } from '../logic';
import { BurgerLink, Label } from '../styles';
import { CaretDownIcon, Content, Dropdown } from './styles';
import { Props } from './types';

const DropdownMenuMobile: FC<Props> = ({ items, name, pathname }) => {
  const [opened, setIsOpened] = useState(
    () => !shouldCloseMenu(pathname, name.toLowerCase()),
  );

  const toggleDropdown = useCallback(() => {
    setIsOpened((previousState) => !previousState);
  }, []);

  useEffect(() => {
    shouldCloseMenu(pathname, name.toLowerCase()) && setIsOpened(false);
  }, [pathname, name]);

  return (
    <>
      <Dropdown opened={opened} onClick={toggleDropdown}>
        <Label>{name}</Label>
        <CaretDownIcon />
      </Dropdown>
      <AnimatePresence>
        {opened && (
          <Content
            key={name}
            initial={{ height: '0px' }}
            animate={{ height: 'auto' }}
            exit={{ height: '0px' }}
          >
            {items.map((item) => {
              if (!('path' in item)) return null;
              return (
                <BurgerLink key={item.path} to={item.path}>
                  {item.label}
                </BurgerLink>
              );
            })}
          </Content>
        )}
      </AnimatePresence>
    </>
  );
};

export default DropdownMenuMobile;
