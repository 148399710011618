import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  selectUserIsForcedPremium,
  selectUserIsPremium,
  selectUserIsPremiumGroupMember,
  selectUserIsPremiumGroupOwner,
  selectUserIsSWD,
} from 'redux/modules/auth';
import { openConfirmation } from 'redux/modules/modal';
import {
  selectInvoices,
  selectMembers,
  selectPremiumLoading,
  selectUserPremiumInfo,
} from 'redux/modules/premium';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { path, url } = useRouteMatch();
  const isSWDTeam = useSelector(selectUserIsSWD);
  const isPremium = useSelector(selectUserIsPremium);
  const isForcedPremium = useSelector(selectUserIsForcedPremium);
  const isPremiumGroupMember = useSelector(selectUserIsPremiumGroupMember);
  const isPremiumGroupOwner = useSelector(selectUserIsPremiumGroupOwner);
  const info = useSelector(selectUserPremiumInfo);
  const invoices = useSelector(selectInvoices);
  const loading = useSelector(selectPremiumLoading);
  const corporateGroupLength = useSelector(selectMembers)?.length;

  const actions = useActions({
    openConfirmation,
  });

  return {
    ...actions,
    corporateGroupLength,
    info,
    invoices,
    isSWDTeam,
    isPremium,
    isPremiumGroupMember,
    isPremiumGroupOwner,
    isForcedPremium,
    loading,
    path,
    url,
  };
};
