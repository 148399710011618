import React, { FC } from 'react';
import {
  ActionButton,
  ExternalLink,
  HorizontalRule,
  InternalLink,
  Label,
} from './styles';
import { Props } from './types';

const Item: FC<Props> = ({ item }) => {
  if ('path' in item) {
    const { label, path, highlighted } = item;

    return (
      <InternalLink $highlighted={!!highlighted} to={path}>
        {label}
      </InternalLink>
    );
  }

  if ('action' in item) {
    const { action, label } = item;

    return <ActionButton onClick={action}>{label}</ActionButton>;
  }

  if ('href' in item) {
    const { label, href, ...props } = item;

    return (
      <ExternalLink href={href} {...props}>
        {label}
      </ExternalLink>
    );
  }

  if ('spacer' in item) {
    return <HorizontalRule />;
  }

  const { label, ...props } = item;

  return <Label {...props}>{label}</Label>;
};

export default Item;
