import bannerLanding from 'assets/images/bannerLanding.svg';
import {
  ArrowLink as DefaultArrowLink,
  Button as DefaultButton,
  Conversation as DefaultConversation,
  FeaturedLink as DefaultFeaturedLink,
  Jumbotron as DefaultJumbotron,
  LandingSectionDescription as DefaultLandingSectionDescription,
  LinesWrapper,
} from 'components';
import {
  FlipCardBottom,
  FlipCardTop,
  Unit,
  Value,
} from 'components/Countdown/styles';
import { CTALeftImage, CTARightImage } from 'components/FeaturedLink/styles';
import { ConfusedShrugIcon } from 'components/icons';
import { TitleContainer } from 'components/LandingSectionDescription/styles';
import { Tag } from 'components/PracticeCard/styles';
import DefaultProtectedButton from 'containers/Common/ProtectedButton';
import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { container, from, whiteBox } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

const buttonStyles = css`
  margin-right: auto;
`;

export const Button = styled(DefaultButton)`
  ${buttonStyles};
`;

export const ProtectedButton = styled(DefaultProtectedButton)`
  ${buttonStyles};
`;

export const Container = styled.div`
  padding: 3.4375rem ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 5.625rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const HighlightedContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: ${({ theme }) =>
    `3.4375rem ${theme.wrapperPaddingH.mobile} 1.71875rem`};

  ${from.mobileXL`
    padding: ${({ theme }) => `6.875rem ${theme.wrapperPaddingH.desktop} 5rem`};
  `};
`;

export const LastContainer = styled.div`
  padding: ${({ theme }) =>
    `3.4375rem ${theme.wrapperPaddingH.mobile} 1.71875rem`};

  ${from.mobileXL`
    padding: ${({ theme }) =>
      `6.875rem ${theme.wrapperPaddingH.desktop} 3.4375rem`};
  `};
`;

export const Content = styled.div`
  ${container};
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};
  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Title = styled.h3`
  font-size: 2.5rem;
  letter-spacing: 0;
`;

export const FeaturedLink = styled(DefaultFeaturedLink)`
  margin: 3.75rem 0 6.875rem 0;
  p {
    max-width: 37.5rem;
    span {
      font-weight: ${({ theme }) => theme.weights.bold};
    }
  }
`;

export const Jumbotron = styled(DefaultJumbotron)`
  > div {
    flex-direction: column;

    ${from.mobileXL`
      flex-direction: row;
    `};
  }

  img {
    width: 80%;

    ${from.mobileXL`
      width: 45%;
    `};

    ${from.tablet`
      width: 35%;
    `};
  }

  ${Button} {
    margin-top: 1.25rem;
  }
`;

export const SmallerJumbotron = styled(Jumbotron)`
  img {
    max-height: 15rem;
    object-fit: contain;
  }
`;

export const Banner = styled(FeaturedLink)`
  align-items: flex-start;
  text-align: left;
  background-image: url(${bannerLanding});
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 230px;
  margin: 2rem 0 0;

  ${from.tablet`
      background-position: 95% 60%;
      background-size: 30%;
      padding: 3.75rem 4.625rem;

      p {
        max-width: 70%;
      }
  `};

  ${from.laptop`
      p {
        max-width: 37.5rem;
      }
  `};

  ${CTALeftImage}, ${CTARightImage} {
    display: none;
  }
`;

export const BannerImage = styled.img``;

export const LandingSectionDescription = styled(
  DefaultLandingSectionDescription,
)`
  max-width: 100%;
`;

export const PracticeSection = styled.section`
  ${container};
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.875rem;

  ${from.tablet`
    grid-template-columns: 1fr 1fr;
  `};

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const MyPracticeSection = styled.section`
  ${container};

  ${TitleContainer} {
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  }
`;

export const PracticeRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.875rem;

  ${from.laptop`
    grid-template-columns: 1fr 1fr;
  `};
`;

export const PracticeGroup = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: -13px 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const GroupLabel = styled.p`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0 0 0.625rem;
`;

export const GroupTitle = styled(LinesWrapper).attrs({
  maxLines: 1,
  forwardedAs: 'h3',
})`
  font-size: 1.75rem;
  margin: 0 0 0.625rem;
`;

export const GroupHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => `1.25rem ${theme.spacing.md} 1.75rem`};

  ${GroupTitle} {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }

  ${from.mobileXL`
    padding: ${({ theme }) => `1.25rem ${theme.spacing.xlg} 1.75rem`};
  `};
`;

export const GroupRow = styled.div`
  display: flex;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  margin-right: 0;

  ${from.tablet`
    margin-bottom: 0;
    margin-right: ${({ theme }) => theme.spacing.lg};
  `};
`;

export const InfoDescription = styled(LinesWrapper).attrs({
  maxLines: 5,
  forwardedAs: 'p',
})`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 0.75rem;
`;

export const InfoSubmissions = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  padding: 0.375rem 0.625rem 0.375rem 0.375rem;
  margin: auto auto 0 0;
`;

export const SubmissionsNumber = styled.div<{ live?: boolean }>`
  border-radius: 3px;
  background-color: ${({ theme, live }) =>
    theme.colors[live ? 'error' : 'accent']};
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin: 0 0.625rem 0 0;
  line-height: 1;
`;

export const SubmissionsText = styled.p`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1rem;
  margin: 0;
  text-transform: uppercase;
`;

export const Details = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  min-width: 190px;
`;

export const CountdownTitle = styled.div`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0.036rem;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
`;

export const CountdownContainer = styled.div`
  font-size: 1.25rem;
  margin-bottom: 2.688rem;

  > * > *:not(:last-child) {
    margin-right: 1.25rem;
  }

  ${FlipCardTop},
  ${FlipCardBottom} {
    border-radius: 0.125rem;
    height: 1.313rem;
    width: 2.75rem;
  }

  ${Value} {
    font-size: 1.25rem;
    top: 0.625rem;
  }

  ${Unit} {
    font-size: 0.813rem;
  }
`;

export const SeeDetailsLink = styled.div`
  margin-top: 0;

  ${from.tablet`
    margin-top: auto;
    display: flex;
  `};

  ${Button} {
    padding: 0.813rem 1rem;
  }
`;

export const GroupContent = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => `1.25rem ${theme.spacing.md} ${theme.spacing.lg}`};

  ${from.mobileXL`
    padding: ${({ theme }) =>
      `1.25rem ${theme.spacing.xlg} ${theme.spacing.lg}`};
  `};
`;

export const GroupText = styled.p`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.5rem;
  margin-bottom: 1rem;
`;

export const GroupCards = styled.div`
  flex-grow: 1;
  margin-bottom: 1rem;

  > :first-child {
    ${Tag} {
      display: inline-block;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

export const FeedBackSection = styled.section`
  ${container};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FeedbackContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.875rem;

  ${from.tablet`
    grid-template-columns: 1fr 1fr;
  `};

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const MyFeedbackContainer = styled.div``;

export const HeadingRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Paragraph = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0;
  margin: 0 0 ${({ theme }) => theme.spacing.lg};

  ${from.tablet`
    margin: 0 ${({ theme }) => theme.spacing.xlg} 0 0;
  `};
`;

export const AskFeedbackButton = styled(ProtectedButton)`
  flex: 1;
  white-space: nowrap;
`;

export const MyFeedbackRequest = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: -13px 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  display: flex;
  padding: 1.5rem;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Graph = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  padding-bottom: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;

  ${from.tablet`
    border-bottom: none;
    border-right: solid 1px ${({ theme }) => theme.colors.grey200};
    margin-bottom: 0;
    padding-bottom: 0;
    padding-right: ${({ theme }) => theme.spacing.lg};
  `};
`;

export const GraphTitle = styled.h3`
  font-size: 1.25rem;
  margin: 0 0 ${({ theme }) => theme.spacing.md};
`;

export const GraphImage = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.grey200};
  border-radius: 0.125rem;
  height: 15rem;
  width: 100%;
  margin-bottom: 1.25rem;

  ${from.tablet`
    width: 20rem;
  `};
`;

export const GraphImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const FeedbackComments = styled.div`
  display: flex;
  flex-direction: column;

  ${from.tablet`
    padding-left: ${({ theme }) => theme.spacing.lg};
  `};

  > p {
    margin: 0 0 1.125rem;
  }

  > a {
    margin-top: auto;
  }
`;

export const FeaturedFeedbackTitle = styled.h2`
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.8)};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1.14px;
  line-height: 0.88;
  margin: 3.875rem 0 1.8125rem 0;
  text-transform: uppercase;
`;

export const TalkSection = styled.section`
  ${container};
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const TalkSectionDescription = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};

  ${Button} {
    margin-left: auto;
    margin-top: ${({ theme }) => theme.spacing.md};
  }

  ${from.tabletH`
    flex-direction: row;
  `};
`;

export const TalkSectionParagraph = styled.p`
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.65)};
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.5;
  max-width: 71%;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.tabletH`
    margin: 0;
  `};
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: ${({ theme }) => addAlpha(theme.colors.bookLight, 0.3)};
  top: 0;
  left: 0;
  height: 14.13rem;
  width: 100%;
`;

export const BasicCard = styled.div`
  ${whiteBox}
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

const CardInfoDefault = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${Title} {
    font-size: 1.75rem;
    letter-spacing: 0;
    margin: 0 0 0.5625rem;
    line-height: 1.21;
  }
`;

export const BasicCardInfo = styled.div`
  ${CardInfoDefault}
  padding: ${({ theme }) => `2.5rem ${theme.spacing.lg} 2.125rem`};
`;

export const BasicCardInfoWithImg = styled.div`
  ${CardInfoDefault}
  padding: ${({ theme }) => `${theme.spacing.lg} ${theme.spacing.lg} 2.125rem`};
`;
export const BasicCardText = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 1.125rem;
  line-height: 1.67;
  margin: 0 0 1.1875rem 0;
`;

export const Thumbnail = styled.img`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0;
  height: 14.13rem;
  max-height: 14.13rem;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const ArrowLink = styled(DefaultArrowLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  height: auto;
  justify-content: center;
  line-height: 1;
  letter-spacing: 1px;
  margin: 1.875rem auto 0 0;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  text-transform: uppercase;
  :hover:not(:disabled) {
    box-shadow: 0 2px 8px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const ArrowLinkFit = styled(ArrowLink)`
  margin-top: 0;
  width: fit-content;
`;

export const Conversation = styled(DefaultConversation)``;

export const Conversations = styled.div`
  width: 100%;
`;

export const HeadingSection = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const VisualSection = styled.section`
  padding: 3.75rem 0;
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-column-gap: 2.813rem;
  grid-row-gap: 1.563rem;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  > * {
    background-color: ${({ theme }) => addAlpha(theme.colors.grey400, 0.5)};
    border: solid 1px ${({ theme }) => theme.colors.grey200};
    border-radius: 0.25rem;
    overflow: hidden;
    height: 17.25rem;

    &:focus-within {
      border-color: ${({ theme }) => theme.colors.primary};
    }

    ${from.tabletH`
    height: 12.25rem;
  `};

    :hover {
      > * {
        opacity: 1;
      }
    }
  }
`;

export const MemberSection = styled.section`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 3.75rem 0;
`;

export const MemberGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  grid-row-gap: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.mobileXL`
    grid-column-gap: 2rem;
    grid-row-gap: 1.25rem;
  `}
`;

export const Icon = styled(ConfusedShrugIcon)`
  width: 15.625rem;
  margin: ${({ theme }) => theme.spacing.md} 0;
`;

export const NoSubmissionsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: -13px 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoSubmissionsText = styled.div`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;
