import { Props } from './types';

export const handleAddVote = ({ addVote }: { addVote: Props['addVote'] }) => (
  objectId: string,
) => {
  addVote({
    objectId,
    contentType: 'galleryentry',
  });
};

export const handleRemoveVote = ({
  removeVote,
}: {
  removeVote: Props['removeVote'];
}) => (objectId: string) => {
  removeVote({
    objectId,
    contentType: 'galleryentry',
  });
};
