import { Select, Textarea } from 'components';
import { SupportFormFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container, FormActions, InputGroup, Title } from './styles';
import { Props } from './types';

export const inquiryOptions = [
  {
    label: 'Subscription Issues',
    value: 'Subscription Issues',
  },
  {
    label: 'Payments',
    value: 'Payments',
  },
  {
    label: 'Account Issues',
    value: 'Account Issues',
  },
  {
    label: 'Feedback',
    value: 'Feedback',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

const Form: FC<Props> = ({
  handleSubmit,
  invalid,
  loading,
  pristine,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <Title>support</Title>
    <InputGroup>
      <Field
        component={Select}
        label="Inquiry Type"
        name={SupportFormFieldNames.inquiryType}
        options={inquiryOptions}
      />
      <Field
        component={Textarea}
        label="Describe the issue"
        placeholder="What is the issue?"
        name={SupportFormFieldNames.issueDescription}
        rows="8"
        validate={required}
      />
    </InputGroup>
    <FormActions
      invalid={invalid}
      pristine={pristine}
      submitFailed={submitFailed}
      loading={loading}
      submitLabel="Send inquiry"
    />
  </Container>
);

export default connect(Form);
