import Link from 'components/Link';
import { Container as LiveTag } from 'components/LiveTag/styles';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  height: 20.75rem;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;

  ${from.tablet`
    padding: 2rem;
    height: 27rem;
  `}

  &:focus-within {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
  }
`;

export const Image = styled.img`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => addAlpha(theme.colors.primary, 0.3)} 78%,
    ${({ theme }) => theme.colors.primary}
  );
`;

export const Title = styled.h3`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  line-height: 0.8;

  ${from.tablet`
    font-size: 28px;
  `}
`;

export const Info = styled.div`
  box-shadow: -2px 0 0 ${({ theme }) => theme.colors.basicGold};
  position: relative;
  padding-left: 12px;

  ${LiveTag} {
    width: max-content;
  }

  ${Title} {
    margin-top: 14px;
  }
`;

export const PlayButton = motion.custom(styled.div`
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: ${({ theme }) => addAlpha(theme.colors.white, 0.5)};
  position: absolute;
  top: 50%;
  left: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    width: 75%;
    height: 75%;
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }

  svg {
    fill: ${({ theme }) => theme.colors.accent};
    position: relative;
    width: 35px;
    height: 35px;
    left: 3px;
  }
`);

export const VideoLink = styled(Link)<{ $zoomEventClosed: boolean }>`
  ${({ $zoomEventClosed }) =>
    $zoomEventClosed &&
    css`
      pointer-events: none;
      > :last-child {
        display: none;
      }
    `}
`;

export const ZoomButton = styled(Link)`
  display: block;
  border-radius: 0.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 1px;
  padding: 0.8125rem 1.25rem;
  text-transform: uppercase;
  position: relative;
  background-color: ${({ theme }) => theme.colors.basicGold};
  color: ${({ theme }) => theme.colors.judge};
  margin-top: 2rem;
  width: fit-content;
  :hover {
    background-color: ${({ theme }) => theme.colors.peach};
    box-shadow: 0 2px 15px -3px rgba(88, 78, 54, 0.4);
  }
`;
