import { CaretDownIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { whiteBox } from '../../styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  min-height: 2.25rem;
  position: relative;
  width: 100%;

  :hover {
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }
`;

export const DropdownIcon = styled(CaretDownIcon)`
  color: ${({ theme }) => theme.colors.accent};
  margin: 0 0.75rem 0 auto;
  width: 0.6rem;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 4;
  top: 3.125rem;
`;

export const Content = styled.div`
  ${whiteBox};
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;

  padding: 0 ${({ theme }) => theme.spacing.sm}
    ${({ theme }) => theme.spacing.xsm};
`;

export const Header = styled.div<{ showDropdown: boolean }>`
  padding: ${({ theme }) => theme.spacing.xsm} 0
    ${({ theme }) => theme.spacing.xsm} ${({ theme }) => theme.spacing.sm};
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 4px;
  box-sizing: border-box;
  min-height: 2.25rem;

  ${({ showDropdown }) =>
    showDropdown &&
    css`
      border-color: ${({ theme }) => theme.colors.accent};
    `}
`;
