import CTALeftAsset from 'assets/images/featuredLink/CTALeft.svg';
import CTARightAsset from 'assets/images/featuredLink/CTARight.svg';
import React, { FC } from 'react';
import {
  Button,
  Container,
  CTALeftImage,
  CTARightImage,
  FeaturedContent,
  Title,
} from './styles';
import { Props } from './types';

const FeaturedLink: FC<Props> = ({
  ButtonComponent = Button,
  children,
  className,
  text,
  title,
  to,
}) => (
  <Container className={className}>
    <CTALeftImage alt="" aria-hidden="true" src={CTALeftAsset} />
    <CTARightImage alt="" aria-hidden="true" src={CTARightAsset} />
    <Title>{title}</Title>
    <FeaturedContent>{children}</FeaturedContent>
    <ButtonComponent mode="big" to={to}>
      {text}
    </ButtonComponent>
  </Container>
);

export default FeaturedLink;
