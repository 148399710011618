import React, { FC, useCallback } from 'react';
import connect from './connect';
import { Snack, Text } from './styles';
import { Props } from './types';

const Snackbar: FC<Props> = ({ message, hideSnackbar, pushFromQueue }) => {
  const onClose = useCallback(() => {
    hideSnackbar();
    setTimeout(pushFromQueue, 100);
  }, [hideSnackbar, pushFromQueue]);

  return (
    <Snack
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!message}
      onClose={onClose}
      autoHideDuration={3000}
      message={<Text>{message || ' '}</Text>}
    />
  );
};

export default connect(Snackbar);
