import {
  AvatarInput,
  BooleanInput,
  Input,
  InputWithIcon,
  LocationInput,
  Select,
  Textarea,
  Toggle,
} from 'components';
import { EditProfileFormNames } from 'model';
import React, { FC, useState } from 'react';
import { Field } from 'redux-form';
import { isLinkedinUrl, required } from 'utils/validations';
import connect from './connect';
import {
  Container,
  FormActions,
  Info,
  InfoSection,
  InstagramIcon,
  LinkedInIcon,
  Section,
  SectionHeader,
  SectionInputs,
  Title,
  TwitterIcon,
  WebsiteIcon,
} from './styles';
import { Props } from './types';

export const swdBooksReadOptions = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: '1 book',
    value: 1,
  },
  {
    label: '2 books',
    value: 2,
  },
  {
    label: '3 books',
    value: 3,
  },
];

export const workshopAttendedOptions = [
  {
    label: 'Not yet',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

export const subscribedToPodcastOptions = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

const Form: FC<Props> = ({
  handleSubmit,
  invalid,
  pristine,
  submitFailed,
  loading,
}) => {
  const [avatarLoading, setAvatarLoading] = useState(false);

  return (
    <Container onSubmit={handleSubmit}>
      <Field
        component={AvatarInput}
        name={EditProfileFormNames.avatar}
        onChangeLoading={setAvatarLoading}
      />
      <InfoSection>
        <Section>
          <SectionHeader>
            <Title>Basic info</Title>
            <Info>This info will be shown in your public profile</Info>
          </SectionHeader>
          <SectionInputs>
            <Field
              component={Input}
              name={EditProfileFormNames.firstName}
              placeholder="First Name"
              label="FIRST NAME"
              type="text"
            />
            <Field
              component={Input}
              name={EditProfileFormNames.lastName}
              placeholder="Last Name"
              label="LAST NAME"
              type="text"
            />
            <Field
              component={LocationInput}
              name={EditProfileFormNames.location}
              placeholder="Your location"
              label="LOCATION"
              type="text"
            />
          </SectionInputs>
        </Section>
        <Section>
          <SectionHeader>
            <Title>Professional info</Title>
            <Info>
              <Field
                CheckboxComponent={Toggle}
                component={BooleanInput}
                name={EditProfileFormNames.showProfessionalInfo}
                label="Show this info on my public profile"
              />
            </Info>
          </SectionHeader>
          <SectionInputs>
            <Field
              component={Input}
              name={EditProfileFormNames.companyName}
              placeholder="Company"
              label="COMPANY"
              type="text"
            />
            <Field
              component={Input}
              name={EditProfileFormNames.companyRole}
              placeholder="Role"
              label="ROLE"
              type="text"
            />
          </SectionInputs>
        </Section>
        <Section id="personalinfo">
          <SectionHeader>
            <Title>Personal info</Title>
            <Info>This info will be shown in your public profile</Info>
          </SectionHeader>
          <Field
            component={Textarea}
            name={EditProfileFormNames.bio}
            label="TELL THE COMMUNITY A BIT ABOUT YOURSELF"
            placeholder="Biography"
            rows="8"
            validate={required}
          />
          <Field
            component={Textarea}
            name={EditProfileFormNames.goals}
            label="What are your current goals related to communicating data?"
            placeholder="Share your current goals with us: what skills are you interested in developing, or what input would be helpful from the community?"
            rows="8"
            validate={required}
          />
        </Section>
        <Section>
          <SectionHeader>
            <Title>Social info</Title>
            <Info>
              <Field
                CheckboxComponent={Toggle}
                component={BooleanInput}
                name={EditProfileFormNames.showSocialInfo}
                label="Show this info on my public profile"
              />
            </Info>
          </SectionHeader>
          <SectionInputs>
            <Field
              component={InputWithIcon}
              Icon={WebsiteIcon}
              name={EditProfileFormNames.socialWeb}
              placeholder="Link to your website"
              label="WEBSITE"
              type="text"
            />
            <Field
              component={InputWithIcon}
              Icon={TwitterIcon}
              name={EditProfileFormNames.socialTwitter}
              placeholder="Twitter user account"
              label="TWITTER PROFILE"
              type="text"
            />
            <Field
              component={InputWithIcon}
              Icon={LinkedInIcon}
              name={EditProfileFormNames.socialLinkedin}
              placeholder="Link to your Linkedin profile"
              label="LINKEDIN PROFILE"
              type="text"
              validate={isLinkedinUrl}
            />
            <Field
              component={InputWithIcon}
              Icon={InstagramIcon}
              name={EditProfileFormNames.socialInstagram}
              placeholder="Instagram user account"
              label="INSTAGRAM PROFILE"
              type="text"
            />
          </SectionInputs>
        </Section>
        <Section id="showactivityprofile">
          <SectionHeader>
            <Title>Info related to your SWD activity</Title>
            <Info>
              <Field
                CheckboxComponent={Toggle}
                component={BooleanInput}
                name={EditProfileFormNames.showActivityInfo}
                label="Show this info on my public profile"
              />
            </Info>
          </SectionHeader>
          <SectionInputs>
            <Field
              component={Select}
              name={EditProfileFormNames.nBooks}
              options={swdBooksReadOptions}
              label="How many SWD books have you read?"
            />
            <Field
              component={Select}
              name={EditProfileFormNames.podcastFollower}
              options={subscribedToPodcastOptions}
              label="Are you subscribed to the SWD podcast?"
            />
            <Field
              component={Select}
              name={EditProfileFormNames.wAttended}
              options={workshopAttendedOptions}
              label="Have you attended any SWD workshops?"
            />
          </SectionInputs>
        </Section>
        <FormActions
          invalid={invalid}
          pristine={pristine}
          submitFailed={submitFailed}
          loading={loading || avatarLoading}
          submitLabel="Save changes"
        />
      </InfoSection>
    </Container>
  );
};

export default connect(Form);
