import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { buttonReset, menuLink } from 'styles/mixins';
import { randomId } from 'utils/randomId';

const linkStyles = css`
  ${menuLink};
  color: ${({ theme }) => theme.colors.grey400};
  line-height: normal;
  padding: 0 1.125rem;
`;

const communityLinkStyles = css`
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

const active = `active-${randomId()}`;

export const InternalLink = styled(NavLink).attrs({
  activeClassName: active,
})<{ $highlighted: boolean }>`
  ${linkStyles};
  ${communityLinkStyles};

  &:not(:hover)&.${active} {
    color: ${({ theme }) => theme.colors.accent};
  }

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      color: ${({ theme }) => theme.colors.primary};

      :hover {
        color: ${({ theme }) => theme.colors.darkPrimary};
      }
    `}
`;

export const ActionButton = styled.button`
  ${buttonReset}
  ${linkStyles}
  ${communityLinkStyles}
  text-align: left;
  cursor: pointer;
`;

export const ExternalLink = styled.a`
  ${linkStyles};
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  line-height: normal;
  padding: 0 1.125rem;
  font-size: 0.875rem;
  font-weight: normal;
  cursor: auto;
`;

export const HorizontalRule = styled.hr`
  background-color: ${({ theme }) => theme.colors.grey200};
  height: 0.0625rem;
  width: 80%;
  margin: 0.875rem auto 1.1875rem;
  border: 0;
`;
