import { Conversation, ConversationExpanded } from '.';
import { userMock } from '../User';

export const conversationMock: Conversation = {
  slug: 'recommended-reading-info-we-trust',
  commentsCount: 40,
  conversationTopic: [],
  created: '2019-09-02T14:00:00.000Z',
  description: 'Hello world!',
  hits: 40,
  id: '19de001b-fe31-471a-9048-722ba343e194',
  isReported: false,
  isVoted: false,
  lastComment: null,
  modified: '2019-09-02T14:00:00.000Z',
  swdTeam: true,
  tags: ['Tag name'],
  title: 'Recommended reading: Info We Trust',
  user: userMock,
  votesCount: 40,
  isAma: false,
  isMonthly: false,
  isSpotlight: false,
  spotlightImage: 'https://placekitten.com/720/460',
  spotlightUsers: [userMock],
  spotlightDate: '2020-07-29',
  amaParticipants: [],
  amaStartDate: null,
  amaEndDate: null,
  amaQuestions: 0,
  amaAnswers: 0,
  amaImage: null,
  closed: false,
  live: false,
  lastComments: [],
};

export const conversationExpandedMock: ConversationExpanded = {
  ...conversationMock,
  comments: [],
};
