export const getFallbackUrl = ({
  path,
  about,
  gallery,
  contributions,
  activity,
  goPremium,
}: {
  path: string;
  about: boolean;
  gallery: boolean;
  contributions: boolean;
  activity: boolean;
  goPremium: boolean;
}) => {
  if (about) {
    return `${path}/about`;
  }
  if (gallery) {
    return `${path}/gallery`;
  }
  if (contributions) {
    return `${path}/contributions`;
  }
  if (activity) {
    return `${path}/activity`;
  }
  if (goPremium) {
    return `${path}/go-premium`;
  }
  return null;
};
