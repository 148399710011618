import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  left: 0;
  min-height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
`;
