import {
  ArrowLink as DefaultArrowLink,
  Button as DefaultButton,
} from 'components';
import { OnDemandVideosIcon } from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${from.tabletH`
    display: grid;
    grid-template-columns: 23.75rem auto;
    grid-template-rows: auto;
    column-gap: 6.563rem;
    row-gap: 0;
  `}
`;

export const PremiumIcon = styled(OnDemandVideosIcon)`
  max-height: 100%;
  width: 90%;
  margin-bottom: 3rem;
  ${from.mobileXL`
  width: 65%;
  `}
  ${from.tablet`
    width: 50%;
  `}
  
  ${from.tabletH`
    width: 100%;
    height: auto;
    align-self: center;
  `}
`;

export const InfoContainer = styled.div``;

export const InfoParagraph = styled.p`
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const InfoTitle = styled.h2`
  font-size: 1.75rem;
  margin: 0 0 1.25rem 0;
`;

export const Features = styled.ul`
  list-style: none;
  width: fit-content;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.75rem;
  grid-row-gap: 0.5rem;
  ${from.tablet`
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 1fr 1fr;
  `}
`;

export const ButtonsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlg};
  min-width: 11.4rem;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

export const UpgradeButton = styled(DefaultButton)`
  box-sizing: border-box;
  padding: 13px 18px;
  height: 2.75rem;
  margin: 0 0 ${({ theme }) => theme.spacing.md};
  width: 100%;
  ${from.mobileXL`
    width: auto;
    padding: 13px;
    margin: 0;
  `}
  ${from.tablet`
    padding: 13px 18px;
  `}
`;

export const PremiumButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
`;

export const ArrowLink = styled(DefaultArrowLink)`
  width: fit-content;
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  height: auto;
  letter-spacing: 1px;
  text-transform: uppercase;
`;
