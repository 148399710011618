import DefaultAvatar from 'components/Avatar';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 0.75rem;
  letter-spacing: 0.036rem;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const Submissions = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  letter-spacing: 0.0275rem;
  text-transform: uppercase;
`;

export const NumberOfComments = styled.div<{ live?: boolean }>`
  border-radius: 3px;
  background-color: ${({ theme, live }) =>
    theme.colors[live ? 'error' : 'accent']};
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
  line-height: 1;
`;

export const Faces = styled.div`
  display: flex;
  > * + * {
    margin-left: 4px;
  }
`;

export const Face = styled(DefaultAvatar)`
  width: 28px;
  height: 28px;
  margin-right: 0;
  margin-bottom: 5px;
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary};
  }
`;

export const LastComment = styled.div`
  grid-column: 1 / -1;

  font-size: 12px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.conversationComment};

  span {
    color: ${({ theme }) => theme.colors.black};
  }

  > * {
    margin: 0;
  }
`;
