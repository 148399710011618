import Select from 'components/Select';
import styled from 'styled-components';
import { from, visuallyHidden } from 'styles/mixins';

export const VisuallyHiddenLabel = styled.label`
  ${visuallyHidden};
`;

export const OrderSelect = styled(Select)`
  border-radius: 0.25rem;
  margin-bottom: 1rem;

  ${from.tablet`
    margin-bottom: 0;
    margin-left: auto;
    justify-self: center;
    width: 13.75rem;
    grid-row: auto;
  `};

  > * > * {
    :hover {
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
    }
  }
`;
