import { TimesIcon } from 'components/icons';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
`;

export const Icon = styled(TimesIcon)`
  width: 0.6rem;
`;
