import deepmerge from 'deepmerge';
import { Entity, PaginatedResponse, UpsertByIdPayload } from 'model';
import { Action, Reducer } from 'redux';
import { ActionAssertor } from 'utils/ActionAssertor';
import { buildById, buildBySlug, upsertById } from 'utils/byId';
import { getPageCount } from 'utils/getPageCount';
import { ByIdState } from './types';

/*
 * The application is going through a big refactor, where all IDs will be
 * replaced by slugs in the backend. For now, the solution is to duplicate all
 * the contents of "byId" in "bySlug". However, ideally we will drop "byId" in
 * the future.
 */

// ToDo: Not sure if we will use these two methods in the future,
// for now let's leave them here.
const customMerge: deepmerge.Options['customMerge'] = (key: string) => {
  if (key.includes('Ids')) {
    return (oldItem) => oldItem;
  }
};
const arrayMerge: deepmerge.Options['arrayMerge'] = (_, sourceArray) =>
  sourceArray;

export const createByIdReducer = <
  EntityT extends Entity,
  ExpandedEntityT extends Entity,
  StateT extends ByIdState<EntityT>,
  ActionT extends Action
>({
  initialState,
  isCreateSuccess,
  isDeleteSuccess,
  isEditSuccess,
  isGetManySuccess,
  isGetOneSuccess,
  isUpsertById,
  perPage,
}: {
  contractItem: (item: ExpandedEntityT) => EntityT;
  initialState: StateT;
  isCreateSuccess?: ActionAssertor<ExpandedEntityT>;
  isDeleteSuccess?: ActionAssertor<{ id: string }>;
  isEditSuccess?: ActionAssertor<ExpandedEntityT>;
  isGetManySuccess?: ActionAssertor<PaginatedResponse<EntityT>>;
  isGetOneSuccess?: ActionAssertor<ExpandedEntityT>;
  isUpsertById?: ActionAssertor<UpsertByIdPayload<EntityT>>;
  perPage: number;
}): Reducer<StateT, ActionT> => (state = initialState, action) => {
  if (
    (isGetOneSuccess && isGetOneSuccess(action)) ||
    (isCreateSuccess && isCreateSuccess(action)) ||
    (isEditSuccess && isEditSuccess(action))
  ) {
    const item = action.payload as ExpandedEntityT;
    const oldItem = state.byId[action.payload.id];
    const newItem = oldItem
      ? deepmerge(oldItem, item, {
          customMerge,
          arrayMerge,
        })
      : item;

    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: newItem,
      },
      bySlug: {
        ...state.bySlug,
        [action.payload.slug]: newItem,
      },
    };
  }

  if (isGetManySuccess && isGetManySuccess(action)) {
    const { count, results } = action.payload;

    return {
      ...state,
      count,
      byId: {
        ...state.byId,
        ...buildById(results),
      },
      bySlug: {
        ...state.bySlug,
        ...buildBySlug(results),
      },
      ids: results.map(({ id }) => id),
      pageCount: getPageCount(count, perPage),
    };
  }

  if (isDeleteSuccess && isDeleteSuccess(action)) {
    return {
      ...state,
      byId: {
        ...state.byId,
        [action.payload.id]: undefined,
      },
      ids: state.ids.filter((id) => id !== action.payload.id),
    };
  }

  if (isUpsertById && isUpsertById(action)) {
    return {
      ...state,
      ...upsertById(state, action.payload),
    };
  }

  return state;
};

export const defaultByIdState = {
  byId: {},
  bySlug: {},
  ids: [],
  count: 0,
  pageCount: 1,
};

export * from './types';
