import { Button } from 'components';
import { ExclamationCircleIcon } from 'components/icons';
import DefaultModal from 'components/Modal';
import styled, { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';
import { Container as Delete } from './Delete/styles';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    width: auto;
    padding: 2rem;
    max-height: inherit;
  }
`;

export const EditButton = styled(Button)`
  position: absolute;
  bottom: 0;
  display: none;
  padding: 0.3rem 0.5rem 0.2rem;
  font-size: 0.675rem;
  margin: 0 0 5px 5px;
`;

export const Container = styled.div`
  position: relative;
  background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.2)};
  box-shadow: 0 2px 8px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  height: 6rem;
  width: 6rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;

  :hover {
    border-color: ${({ theme }) => theme.colors.accent};

    ${Delete} {
      display: block;
    }
    ${EditButton} {
      display: block;
    }
  }
`;

export const Thumbnail = styled.img<{ $dim: boolean }>`
  border-radius: 2.8px;
  box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  display: block;
  height: 6rem;
  object-fit: cover;
  user-select: none;
  width: 6rem;

  ${({ $dim }) =>
    $dim &&
    css`
      opacity: 0.5;
    `};
`;

export const VideoThumbnail = styled(Thumbnail).attrs({ as: 'video' })`
  pointer-events: none;
`;

export const Progress = styled.progress`
  background-color: ${({ theme }) => theme.colors.sky};
  border: none;
  bottom: 0;
  height: 0.25rem;
  position: absolute;
  width: 100%;

  ::-webkit-progress-bar {
    background-color: ${({ theme }) => theme.colors.sky};
  }

  ::-webkit-progress-value {
    background-color: ${({ theme }) => theme.colors.accent};
  }

  ::-moz-progress-bar {
    background-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const Error = styled(ExclamationCircleIcon)`
  bottom: 50%;
  color: ${({ theme }) => theme.colors.error};
  position: absolute;
  right: 50%;
  transform: translateX(50%) translateY(50%);
  width: 2.5rem;
`;
