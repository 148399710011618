import { useMediaQuery } from '@material-ui/core';
import React, { FC, memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  handleDelete,
  handleDrop,
  handleFileEdited,
  handleFileUploaded,
} from './logic';
import MultiFileVariant from './MultiFileVariant';
import SingleFileVariant from './SingleFileVariant';
import { Container, Input } from './styles';
import { Props } from './types';

const MediaSelector: FC<Props> = ({
  accept = ['IMAGE', 'VIDEO'],
  compact = false,
  maxFiles = Infinity,
  onChange,
  id,
  value,
}) => {
  const onDelete = useCallback(handleDelete({ onChange, value }), [
    onChange,
    value,
  ]);

  const isMouseInput = useMediaQuery('(pointer:fine)');

  const onDrop = useCallback(
    handleDrop({
      maxFiles,
      onChange,
      value,
    }),
    [maxFiles, onChange, value],
  );

  const onFileUploaded = useCallback(handleFileUploaded({ onChange, value }), [
    onChange,
    value,
  ]);

  const onFileEdited = useCallback(
    handleFileEdited({ maxFiles, onChange, value }),
    [maxFiles, onChange, value],
  );

  const canAdd = value.length < maxFiles;

  const {
    isDragActive,
    isDragAccept,
    getRootProps,
    getInputProps,
    open,
  } = useDropzone({
    onDrop,
    accept: [
      accept.includes('IMAGE') && 'image/*',
      accept.includes('VIDEO') && 'video/*',
    ].filter((it): it is string => typeof it === 'string'),
    disabled: !canAdd,
  });

  return (
    <Container isMouseInput={isMouseInput}>
      <Input {...getInputProps({ id })} />
      {maxFiles === 1 || value.length < 1 ? (
        <SingleFileVariant
          canAdd={canAdd}
          compact={compact}
          files={value}
          getDropZoneProps={getRootProps}
          isDragAccept={isDragAccept}
          isDragActive={isDragActive}
          isMouseInput={isMouseInput}
          onDeleteFile={onDelete}
          onEdited={onFileEdited}
          onUploaded={onFileUploaded}
        />
      ) : (
        <MultiFileVariant
          entityName={accept.length > 1 ? 'media' : 'image'}
          entityNamePlural={accept.length > 1 ? 'media' : 'images'}
          files={value}
          getDropZoneProps={getRootProps}
          isMouseInput={isMouseInput}
          onChange={onChange}
          onDeleteFile={onDelete}
          onEdited={onFileEdited}
          onUploaded={onFileUploaded}
          open={open}
        />
      )}
    </Container>
  );
};

export default memo(MediaSelector);
