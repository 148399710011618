export const getFallbackUrl = ({
  path,
  recentActivity,
  challenges,
  exercises,
  feedbacks,
  liked,
  conversations,
  achievements,
}: {
  path: string;
  recentActivity: boolean;
  feedbacks: boolean;
  exercises: boolean;
  challenges: boolean;
  liked: boolean;
  conversations: boolean;
  achievements: boolean;
}) => {
  if (recentActivity) {
    return `${path}/recent-activity`;
  }
  if (challenges) {
    return `${path}/challenges`;
  }
  if (exercises) {
    return `${path}/exercises`;
  }
  if (feedbacks) {
    return `${path}/feedback`;
  }
  if (conversations) {
    return `${path}/conversations`;
  }
  if (liked) {
    return `${path}/liked`;
  }
  if (achievements) {
    return `${path}/achievements`;
  }
  return null;
};
