import styled, { css } from 'styled-components';

export const Container = styled.p<{ maxLines: number }>`
  ${({ maxLines }) => css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${maxLines};
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
