import { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { setSearchSectionPage } from 'redux/modules/search';
import { RootState } from 'redux/modules/types';
import {
  SEARCH_BOOKS_PER_PAGE_DETAIL,
  SEARCH_BOOKS_PER_PAGE_MASTER,
} from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { isDetail }: OwnProps) => ({
  available: state.search.available.books,
  count: state.search.count.books,
  items: state.search.ids.books
    .map((id) => state.search.byId.books[id])
    .filter((it): it is NonNullable<typeof it> => !!it),
  loading: state.search.loading.books,
  page: state.search.page.books,
  pageCount: getPageCount(
    state.search.count.books,
    isDetail ? SEARCH_BOOKS_PER_PAGE_DETAIL : SEARCH_BOOKS_PER_PAGE_MASTER,
  ),
});

const mapDispatchToProps = {
  setPage: (page: SetStateAction<number>) =>
    setSearchSectionPage({
      page,
      section: 'books',
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
