import DOMPurify from 'dompurify';
import overSome from 'lodash/fp/overSome';
import { history } from 'prosemirror-history';
import { DOMParser, DOMSerializer } from 'prosemirror-model';
import { EditorState as BaseEditorState } from 'prosemirror-state';
import { highlightSelectionPlugin } from './highlightSelection';
import inputRules from './inputRules';
import keymap from './keymap';
import { createLinkPromptPlugin } from './LinkPrompt';
import { menuPlugin } from './Menu';
import { placeholderPlugin } from './Placeholder';
import { createReferencePlugins } from './references';
import { schema } from './schema';
import type { EditorState, SerializedState } from './types';

export const createEditorState = (serializedDoc?: SerializedState) => {
  const { isLinkPromptOpen, linkPromptPlugin } = createLinkPromptPlugin();
  const {
    isAnyReferencePromptOpen,
    referencePlugins,
  } = createReferencePlugins({ schema });

  return BaseEditorState.create({
    schema,
    doc: serializedDoc && schema.nodeFromJSON(serializedDoc),
    plugins: [
      history(),
      inputRules(schema),
      keymap(schema),
      linkPromptPlugin,
      highlightSelectionPlugin({
        highlightWhen: overSome([isLinkPromptOpen]),
      }),
      placeholderPlugin(),
      menuPlugin({
        schema,
        remainClosedWhen: overSome([
          isAnyReferencePromptOpen,
          isLinkPromptOpen,
        ]),
      }),
      ...referencePlugins,
    ],
  });
};

export const parseHTML = (rawHTML: string): SerializedState => {
  const sanitizedHTML = DOMPurify.sanitize(rawHTML, {
    ALLOWED_TAGS: [
      'a',
      'blockquote',
      'em',
      'li',
      'ol',
      'p',
      'span',
      'strong',
      'u',
    ],
  });

  const parser = DOMParser.fromSchema(schema);
  const dom = document.createElement('div');
  dom.innerHTML = sanitizedHTML;

  return parser.parse(dom).toJSON();
};

export const serializeHTML = (state: EditorState): string => {
  const serializer = DOMSerializer.fromSchema(schema);
  const fragment = serializer.serializeFragment(state.doc.content);

  return Array.from(fragment.children)
    .map((el) => el.outerHTML)
    .join('');
};
