import { ArrowLeftIcon, ArrowRightIcon } from 'components/icons';
import DefaultLink from 'components/Link';
import React from 'react';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.nav`
  margin-top: ${({ theme }) => theme.spacing.xlg};
  display: flex;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: 0.3rem;

    ${from.mobileXL`
      margin-right: 0.75rem;
    `};
  }
`;

const elementWrapper = css<{ $active: boolean; $disabled?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: 0.5px solid ${({ theme }) => theme.colors.greyBlue};
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  height: 1.75rem;
  justify-content: center;
  width: 100%;
  min-width: 1.75rem;
  max-width: fit-content;

  ${from.mobile`
      height: 2rem;
      min-width: 2rem;
  `};

  ${({ $disabled, theme }) =>
    $disabled
      ? css`
          color: ${theme.colors.greyBlue};
          cursor: default;
          pointer-events: none;
        `
      : css`
          :hover {
            background-color: ${theme.colors.grey100};
          }
        `};

  ${({ $active, $disabled, theme }) =>
    $active &&
    !$disabled &&
    css`
      background-color: ${theme.colors.accent};
      color: ${theme.colors.white};

      :hover {
        background-color: ${theme.colors.accent};
      }
    `};
`;

export const Link = styled((props) => <DefaultLink {...props} />)<{
  $active: boolean;
  $disabled?: boolean;
}>`
  ${elementWrapper};
`;

export const arrow = css`
  width: 1.25rem;
  padding-top: 1px;
`;

export const ArrowLeft = styled(ArrowLeftIcon)`
  ${arrow};
`;

export const ArrowRight = styled(ArrowRightIcon)`
  ${arrow};
`;
