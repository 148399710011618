import React, { FC } from 'react';
import {
  BadgeBackground,
  BadgeContainer,
  BadgeContent,
  Container,
  SWDLogoIcon,
  SWDLogoSmallIcon,
} from './styles';
import { Props } from './types';

const SWDTeamOrPremiumBadge: FC<Props> = ({ big, variant, ...rest }) => (
  <Container {...rest}>
    {big ? (
      <BadgeContent>
        <BadgeBackground>
          {variant === 'swd' ? 'SWD Team Member' : 'Premium Member'}
        </BadgeBackground>
        <BadgeContainer big={big} $variant={variant}>
          <SWDLogoIcon />
        </BadgeContainer>
      </BadgeContent>
    ) : (
      <BadgeContainer big={false} $variant={variant}>
        <SWDLogoSmallIcon />
      </BadgeContainer>
    )}
  </Container>
);

export default SWDTeamOrPremiumBadge;
