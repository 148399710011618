import styled, { css } from 'styled-components';

export const Container = styled.div<{ scroll: boolean }>`
  margin: 0 auto;
  outline: none;
  position: relative;
  width: 100%;

  ${({ scroll }) =>
    scroll &&
    css`
      overflow-y: auto;
    `};
`;
