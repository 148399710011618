import { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { setSearchSectionPage } from 'redux/modules/search';
import { RootState } from 'redux/modules/types';
import {
  SEARCH_BLOG_PER_PAGE_DETAIL,
  SEARCH_BLOG_PER_PAGE_MASTER,
} from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { isDetail }: OwnProps) => ({
  available: state.search.available.blog,
  count: state.search.count.blog,
  items: state.search.ids.blog
    .map((id) => state.search.byId.blog[id])
    .filter((it): it is NonNullable<typeof it> => !!it),
  loading: state.search.loading.blog,
  page: state.search.page.blog,
  pageCount: getPageCount(
    state.search.count.blog,
    isDetail ? SEARCH_BLOG_PER_PAGE_DETAIL : SEARCH_BLOG_PER_PAGE_MASTER,
  ),
});

const mapDispatchToProps = {
  setPage: (page: SetStateAction<number>) =>
    setSearchSectionPage({
      page,
      section: 'blog',
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
