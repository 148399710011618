import styled, { css } from 'styled-components';

export const styles = css<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.875rem;
  padding-left: 0.75rem;
  padding-top: 0.8125rem;
  padding-bottom: 0.875rem;
  text-overflow: ellipsis;

  :placeholder {
    color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }

  :focus {
    outline: none;
    border: solid 1px ${({ theme }) => theme.colors.accent};
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }

  :hover {
    outline: none;
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }

  ${({ error }) =>
    error &&
    css`
      border: solid 1px ${({ theme }) => theme.colors.error};
    `}
`;

export const Container = styled.input<{ error?: boolean }>`
  ${styles};
`;
