/* import { Input } from 'components'; */
/* import { RawInput } from 'components'; */
import React, { FC } from 'react';
import connect from './connect';
import { Container } from './styles';
import { Props } from './types';
/* import { Field } from 'redux-form'; */
/* import { required } from 'utils/validations'; */

const Form: FC<Props> = () => {
  return (
    <Container>
      {/* <Field
          component={Input}
          name={PaymentFormNames.cardholderName}
          label="Name"
          placeholder="Name Surname"
          validate={required}
        />
        <InputLabel>Email</InputLabel>
        <RawInput
          placeholder="example@emailaccount.com"
          name="admin-email"
        / >
        <SubmitButton disabled={loading} type="submit">
        {loading
          ? 'Please wait...'
          : 'Set as new admin'
          }
      </SubmitButton>
      */}
    </Container>
  );
};

export default connect(Form);
