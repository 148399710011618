import React, { FC, memo } from 'react';
import { ButtonDefault, Link } from './styles';
import { Props } from './types';

const Button: FC<Props> = ({
  children,
  className,
  disabled,
  mode,
  onClick,
  target,
  to,
  variant = 'primary',
  ...rest
}) =>
  !disabled && to ? (
    <Link
      className={className}
      mode={mode}
      onClick={onClick}
      to={to}
      variant={variant}
      target={target}
    >
      {children}
    </Link>
  ) : (
    <ButtonDefault
      className={className}
      disabled={disabled}
      mode={mode}
      onClick={onClick}
      {...rest}
      variant={variant}
    >
      {children}
    </ButtonDefault>
  );

export default memo(Button);
