import { BookingNotification } from 'model';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Props } from './types';

export const handleChangeReminderTime = ({
  setReminderTime,
}: {
  setReminderTime: Dispatch<SetStateAction<BookingNotification>>;
}) => (e: ChangeEvent<HTMLInputElement>) => {
  setReminderTime(e.target.value as BookingNotification);
};

export const handleSave = ({
  onSaveReminder,
  reminderTime,
}: {
  onSaveReminder: Props['onSaveReminder'];
  reminderTime: BookingNotification;
}) => () => {
  onSaveReminder(reminderTime);
};
