import React, { FC } from 'react';
import { isExternalUrl } from 'utils/isExternalUrl';
import { ExternalLink, InternalLink } from './styles';
import { AnchorProps, Props } from './types';

const isAnchorProps = (props: Props): props is AnchorProps =>
  isExternalUrl(props.to);

const Link: FC<Props> = (props) => {
  if (isAnchorProps(props)) {
    const { children, className, to, ...rest } = props;

    return (
      <ExternalLink
        rel="noreferrer noopener"
        href={to}
        {...rest}
        target="_blank"
        className={className}
      >
        {children}
      </ExternalLink>
    );
  }

  const { children, ...rest } = props;

  return <InternalLink {...rest}>{children}</InternalLink>;
};

export default Link;
