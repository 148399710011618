import { BaseThemedCssFunction, css, DefaultTheme } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';
import { keysOf } from '../utils/keysOf';

export const FOOTER_MARGIN = '6.875rem';

const size = {
  mobile: '400px',
  mobileXL: '480px',
  tablet: '768px',
  tabletH: '990px',
  laptop: '1024px',
} as const;

const minWidthQuery = (width: string) => `@media (min-width: ${width})`;

const minWidth = (width: string): BaseThemedCssFunction<DefaultTheme> => (
  // tslint:disable:no-any
  first: any,
  ...args: any
) =>
  // tslint:enable:no-any
  css`
    ${minWidthQuery(width)} {
      ${css(first, ...args)};
    }
  `;

interface Breakpoint extends ReturnType<typeof minWidth> {
  query: string;
}

export const from = keysOf(size).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (() => {
      const mixin = minWidth(size[key]) as Breakpoint;
      mixin.query = minWidthQuery(size[key]);
      return mixin;
    })(),
  }),
  {} as { [key in keyof typeof size]: Breakpoint },
);

export const buttonReset = css`
  background: none;
  border: none;
  padding: 0;
`;

export const container = css`
  margin: 0 auto;
  max-width: 73.13rem;
`;

export const wrapper = css`
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const viewContainer = css`
  margin: 4.375rem auto 10rem auto;
  max-width: 73.13rem;
  padding: 0 ${({ theme }) => theme.spacing.lg};

  ${from.laptop`
    padding: 0;
  `};
`;

export const ICON_WIDTH = '1.5rem';

export const icon = css`
  width: ${ICON_WIDTH};
`;

export const menuLink = css`
  font-size: 14px;
  letter-spacing: 0.6px;
  line-height: 2;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const burgerLink = css`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 1.25rem;
  margin-top: ${({ theme }) => theme.spacing.md};
  text-transform: capitalize;
`;

export const title = css`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;

  ${from.tablet`
    font-size: 2.5rem;
  `};
`;

export const paragraph = css`
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 2.25rem 0 ${({ theme }) => theme.spacing.xlg} 0;

  ${from.tablet`
    font-size: 1.25rem;
    line-height: 1.875rem;
  `};
`;

export const label = css`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0.57px;
  line-height: 1.23rem;
  margin-left: ${({ theme }) => theme.spacing.xsm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  text-transform: uppercase;
`;

export const prettyScrollbar = ({
  vertical = true,
  size = '0.4rem',
}: {
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: ${size};
  scrollbar-color: ${({ theme }) => theme.colors.grey300} transparent;

  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grey100};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey300};
    border-radius: 4px;
  }
`;

export const whiteBox = css`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: -13px 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
`;

export const visuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`;
