import styled from 'styled-components';

export const Container = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
`;
