import { push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  ADD_TO_GALLERY_FORM_KEY,
  Discover,
  GALLERY_EDIT_FORM_KEY,
  PaginatedResponse,
} from 'model';
import { parse } from 'query-string';
import { matchPath } from 'react-router';
import { destroy, reset, stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { showSnackbar } from 'redux/modules/snackbar';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { DISCOVER_PER_PAGE } from 'utils/config';
import { addComments } from '../comment';
import {
  createDiscoverItem,
  deleteDiscoverItem,
  editDiscoverItem,
  getDiscoverItem,
  getDiscoverItems,
} from './actions';

export const createDiscoverItemEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(createDiscoverItem.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<Discover>({
        body,
        method: 'POST',
        path: 'discover',
      }).pipe(
        mergeMap(({ response: item }) => [
          createDiscoverItem.success(item),
          replace(`/discover/detail/${item.id}`),
          reset(ADD_TO_GALLERY_FORM_KEY),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              createDiscoverItem.failure(),
              stopSubmit(ADD_TO_GALLERY_FORM_KEY, err.response),
            ];
          }

          return [createDiscoverItem.failure()];
        }),
      ),
    ),
  );

export const deleteDiscoverItemEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteDiscoverItem.request)),
    mergeMap(({ payload: { discoverId } }) =>
      request({
        method: 'DELETE',
        path: `discover/${discoverId}`,
      }).pipe(
        mergeMap(() => [
          deleteDiscoverItem.success({ id: discoverId }),
          showSnackbar({
            message: 'Your gallery item was deleted!',
          }),
          // If the user is deleting the discover item from its profile, don't
          // redirect. If they are deleting it from the edit modal, redirect
          // them.
          ...(matchPath(state$.value.router.location?.pathname, '/members/me')
            ? []
            : [replace('/discover')]),
        ]),
        catchError(() => [deleteDiscoverItem.failure()]),
      ),
    ),
  );

export const editDiscoverItemEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editDiscoverItem.request)),
    mergeMap(({ payload: { discoverId, ...body } }) =>
      fullRequest<Discover>({
        body,
        method: 'PUT',
        path: `discover/${discoverId}`,
      }).pipe(
        mergeMap(({ response: item }) => [
          editDiscoverItem.success(item),
          showSnackbar({
            message: 'Gallery entry updated!',
          }),
          push(`/discover/detail/${discoverId}`),
          destroy(GALLERY_EDIT_FORM_KEY(discoverId)),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editDiscoverItem.failure(),
              stopSubmit(GALLERY_EDIT_FORM_KEY(discoverId), err.response),
            ];
          }

          return [editDiscoverItem.failure()];
        }),
      ),
    ),
  );

export const getDiscoverItemsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getDiscoverItems.request)),
    mergeMap(({ payload: { search } }) =>
      request<PaginatedResponse<Discover>>({
        path: 'discover',
        params: {
          ...parse(search),
          pageSize: DISCOVER_PER_PAGE,
        },
      }).pipe(
        mergeMap((response) => [getDiscoverItems.success(response)]),
        catchError(() => [getDiscoverItems.failure()]),
      ),
    ),
  );

export const getDiscoverItemEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getDiscoverItem.request)),
    mergeMap(({ payload: { id } }) =>
      request<Discover>({
        path: `discover/${id}`,
      }).pipe(
        mergeMap((response) => [
          getDiscoverItem.success(response),
          addComments(response.comments ?? []),
        ]),
        catchError(() => [getDiscoverItem.failure()]),
      ),
    ),
  );

export default combineEpics(
  createDiscoverItemEpic,
  deleteDiscoverItemEpic,
  editDiscoverItemEpic,
  getDiscoverItemsEpic,
  getDiscoverItemEpic,
);
