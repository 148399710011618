import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { AppContentType, QuestionForm, UPLOAD_MEDIA_FIELD } from 'model';
import React from 'react';
import { deleteComment } from 'redux/modules/comment';
import { Props } from './types';

export const handleDelete = ({
  commentId,
  openConfirmationModal,
}: {
  commentId?: string;
  openConfirmationModal: Props['openConfirmationModal'];
}) => () => {
  if (commentId) {
    openConfirmationModal({
      acceptButtonLabel: 'Delete',
      acceptButtonVariant: 'danger',
      cancelButtonLabel: 'Cancel',
      title: <span>Are you sure?</span>,
      description: <span>Deleting this comment can’t be undone.</span>,
      onAccept: [
        deleteComment.request({
          id: commentId,
        }),
      ],
    });
  }
};

export const handleSubmit = ({
  commentId,
  contentType,
  editComment,
  objectId,
}: {
  commentId?: string;
  contentType?: AppContentType;
  editComment: Props['editComment'];
  objectId?: string;
}) => ({ comment, title, [UPLOAD_MEDIA_FIELD]: media = [] }: QuestionForm) => {
  if (commentId && objectId && contentType) {
    const [image] = media.filter(isUploaded);

    const mergedComment = `<h1>${title}</h1>${serializeHTML(comment)}`;
    editComment({
      commentId,
      contentType,
      objectId,
      comment: mergedComment,
      image: image?.file || null,
    });
  }
};
