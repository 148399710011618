import { Button as DefaultButtonPrimary, Input } from 'components';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { commonButtonStyles } from '../styles';

export const Container = styled.form`
  text-align: left;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 1rem;
  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  text-align: center;
`;

export const InvitationEmailInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.grey100};
  border: solid 1px ${({ theme }) => theme.colors.grey100};
  :hover {
    box-shadow: none;
  }
`;

export const ButtonPrimary = styled(DefaultButtonPrimary)`
  ${commonButtonStyles};
  margin-top: 0;
  min-width: 8.88rem;
  width: auto;
`;

export const ResetPassword = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.67px;
  margin: auto 0;
  text-align: center;
  text-decoration: none;
`;
