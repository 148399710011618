import { userMock } from 'model';
import { FeedbackRequest, FeedbackRequestExpanded } from '.';

export const feedbackRequestMock: FeedbackRequest = {
  slug: 'test',
  addToMyGallery: false,
  commentsCount: 0,
  context: 'Lorem ipsum dolor sit amet',
  created: '2019-09-02T14:00:00.000Z',
  description: '',
  expiryDate: undefined,
  hits: 0,
  id: '75ffc4a7-7e8d-4127-9085-b6a31207b6ea',
  imageList: null,
  isReported: false,
  isAboutToExpire: false,
  isSolved: false,
  isVoted: false,
  lastComment: null,
  status: 'Pending',
  swdTeam: false,
  tags: [],
  title: 'Test',
  modified: '2019-09-02T14:00:00.000Z',
  user: userMock,
  votesCount: 45,
};

export const feedbackRequestExpandedMock: FeedbackRequestExpanded = {
  ...feedbackRequestMock,
  comments: [],
  user: userMock,
};
