import { EditorState } from 'components/Editor/types';
import { Media } from 'components/MediaInput';
import { VisualTagInputsFieldNames, VisualTagInputsForm } from 'model';
import { FormNameMap } from 'utils/forms';
import { UPLOAD_MEDIA_FIELD } from '../utils';

export const CHALLENGE_SUBMISSION_FORM_KEY = (editSlug?: string) =>
  `forms/CHALLENGE_SUBMISSION/${editSlug ? `edit/${editSlug}` : 'new'}`;

export interface ChallengeSubmissionForm extends VisualTagInputsForm {
  [UPLOAD_MEDIA_FIELD]: readonly Media[];
  description: EditorState;
  addToMyGallery: boolean;
  title: string;
}

export const ChallengeSubmissionFormFieldNames: FormNameMap<ChallengeSubmissionForm> = {
  ...VisualTagInputsFieldNames,
  [UPLOAD_MEDIA_FIELD]: UPLOAD_MEDIA_FIELD,
  addToMyGallery: 'addToMyGallery',
  description: 'description',
  title: 'title',
};
