import {
  ArrowLink as DefaultArrowLink,
  Button as DefaultButton,
  ChallengeCard as DefaultChallengeCard,
  Fold as DefaultFold,
  SectionTitle as DefaultSectionTitle,
  ShareButton as DefaultShareButton,
  Submissions as DefaultSubmissions,
} from 'components';
import { Status } from 'components/ChallengeCard/styles';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const Fold = styled(DefaultFold)`
  padding-bottom: 13rem;
`;

export const Exercise = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: none;
  position: relative;
  margin-top: -11.25rem;
`;

export const ExerciseImage = styled.img`
  display: block;
  object-fit: cover;
  width: 100%;
  height: auto;
  max-height: 17.44rem;

  ${from.mobileXL`
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
`}
`;

export const ExerciseOverlay = styled.div`
  background-color: ${({ theme }) => addAlpha(theme.colors.bookLight, 0.3)};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Current = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const Content = styled.div`
  ${container};
  position: relative;
  padding: 1rem 0 3rem;
  ${from.mobileXL`
    padding: 1rem ${({ theme }) => theme.wrapperPaddingH.desktop} 5rem;
  `};
`;

export const Button = styled(DefaultButton)`
  padding: 1rem 2.125rem;
  flex-shrink: 0;
  font-size: 1.125rem;
`;

export const Section = styled.div<{ isMain: boolean }>`
  ${({ isMain }) =>
    !isMain &&
    css`
      padding: 0 1rem;
      ${from.mobileXL`
      padding: 0;
    `}
    `}
`;

export const Featured = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => addAlpha(theme.colors.grey300, 0.4)};
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: none;
  display: flex;
  flex-direction: column;
  padding: 1.875rem;
  box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);

  > :first-child {
    margin-bottom: 0.6rem;
  }

  ${from.tablet`
    > *:not(:last-child) {
      margin-bottom: 0;
    }
    > :first-child{
      margin-bottom: 0.6rem;
    }
  `};
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const FeaturedTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 1.125rem;
  line-height: 1.67;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  ${from.tablet`
    flex-direction: row;
  `};
`;

export const SeeDetailsContainer = styled.div`
  margin-bottom: 0;
  margin-top: 0.75rem;

  ${from.tablet`
    margin-left: auto;
    margin-top: 0;
  `};
`;

export const LatestSubmissionsTitle = styled(DefaultSectionTitle)`
  font-size: 1.75rem;
  letter-spacing: 0;
  margin-top: 2.75rem;
  margin-bottom: 1.875rem;

  ${from.tablet`
    margin-top: 3.75rem;
`}
`;

export const ChallengeCard = styled(DefaultChallengeCard)`
  ${Status} {
    ${from.tabletH`
    width: max-content;
  `};
  }
`;

export const ArrowLink = styled(DefaultArrowLink)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-content: center;
  line-height: 1;
  letter-spacing: 1px;
  margin-left: 0;
  margin-top: 1.875rem;
  padding: ${({ theme }) => theme.spacing.sm} 0;
  text-transform: uppercase;
  width: 8.375rem;
  :hover:not(:disabled) {
    box-shadow: 0 2px 8px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.accent};
  }

  ${from.mobileXL`
    margin-left: auto;
  `};
`;

export const HelpSection = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  margin-top: 2rem;

  ${from.mobileXL`
    margin-top: 3.75rem;
`}
`;

export const Help = styled.div`
  ${container};

  padding: 2.75rem ${({ theme }) => theme.wrapperPaddingH.mobile};

  p {
    line-height: 1.75rem;
  }

  ${from.mobileXL`
    padding: 5rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const HelpTitle = styled(DefaultSectionTitle)``;

export const HelpText = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
`;

export const Submissions = styled(DefaultSubmissions)`
  font-size: 1.75rem;
  padding: 0.5625rem 0.875rem 0 0;
`;

export const ShareButton = styled(DefaultShareButton)`
  margin-left: auto;
`;
