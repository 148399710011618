import { SignUpForm } from 'model';
import { Props } from './types';

export const handleSubmit = ({ signUp }: { signUp: Props['signUp'] }) => ({
  email,
  firstName,
  lastName,
  password,
}: SignUpForm) => {
  signUp({ email, firstName, lastName, password });
};
