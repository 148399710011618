import {
  FeedbackRequest,
  FeedbackRequestExpanded,
  ImageList,
  PaginatedResponse,
  UpsertByIdPayload,
} from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const createFeedbackRequest = createAsyncAction(
  'feedback/CREATE_FEEDBACK_REQUEST_REQUEST',
  'feedback/CREATE_FEEDBACK_REQUEST_SUCCESS',
  'feedback/CREATE_FEEDBACK_REQUEST_FAILURE',
)<
  {
    addToMyGallery: boolean;
    context: string;
    expiryDate: string | null;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    imageList: ImageList;
    title: string;
  },
  FeedbackRequestExpanded,
  undefined
>();

export const deleteFeedbackRequest = createAsyncAction(
  'feedback/DELETE_FEEDBACK_REQUEST_REQUEST',
  'feedback/DELETE_FEEDBACK_REQUEST_SUCCESS',
  'feedback/DELETE_FEEDBACK_REQUEST_FAILURE',
)<{ id: string }, { id: string }, undefined>();

export const editFeedbackRequest = createAsyncAction(
  'feedback/EDIT_FEEDBACK_REQUEST_REQUEST',
  'feedback/EDIT_FEEDBACK_REQUEST_SUCCESS',
  'feedback/EDIT_FEEDBACK_REQUEST_FAILURE',
)<
  Partial<{
    addToMyGallery: boolean;
    context: string;
    expiryDate: string | null;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    imageList: ImageList;
    title: string;
  }> & { id: string },
  FeedbackRequestExpanded,
  undefined
>();

export const getFeedbackRequest = createAsyncAction(
  'feedback/GET_FEEDBACK_REQUEST_REQUEST',
  'feedback/GET_FEEDBACK_REQUEST_SUCCESS',
  'feedback/GET_FEEDBACK_REQUEST_FAILURE',
)<{ id: string }, FeedbackRequestExpanded, undefined>();

export const getFeedbackRequests = createAsyncAction(
  'feedback/GET_FEEDBACK_REQUESTS_REQUEST',
  'feedback/GET_FEEDBACK_REQUESTS_SUCCESS',
  'feedback/GET_FEEDBACK_REQUESTS_FAILURE',
)<
  {
    search?: string;
    silent?: boolean;
  },
  PaginatedResponse<FeedbackRequest>,
  undefined
>();

export const _upsertFeedbackRequestsById = createAction(
  'feedback/_UPSERT_FEEDBACK_REQUESTS_BY_ID',
)<UpsertByIdPayload<FeedbackRequest>>();
