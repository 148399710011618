import { AnimatePresence } from 'framer-motion';
import { toggleMark, wrapIn } from 'prosemirror-commands';
import { wrapInList } from 'prosemirror-schema-list';
import { Plugin, Selection } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import createAdaptedView from 'utils/prosemirror-react-view-adapter';

import { openLinkPrompt } from 'components/Editor/LinkPrompt/actions';
import { isMarkActive } from 'components/Editor/Menu/logic';
import themes from 'styles/themes';

import Prompt from './Prompt';
import {
  BulletListIcon,
  Container,
  EmphasisIcon,
  LinkIcon,
  OrderedListIcon,
  QuoteIcon,
  StrongIcon,
} from './styles';
import type { MenuSection, PluginProps, Props } from './types';

const createMenu = ({
  editorView,
  items: itemSections,
  remainClosedWhen: shouldRemainClosed,
}: {
  editorView: EditorView;
  items: readonly MenuSection[];
  remainClosedWhen: PluginProps['remainClosedWhen'];
}): FC<Props> => ({ state }) => {
  const isOpen = !state.selection.empty && !shouldRemainClosed(state);

  return (
    <Container>
      <AnimatePresence>
        {isOpen && (
          <Prompt
            editorView={editorView}
            itemSections={itemSections}
            state={state}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export const menuPlugin = ({ remainClosedWhen, schema }: PluginProps) =>
  new Plugin({
    props: {
      handleDOMEvents: {
        focusout: (editorView) => {
          // Clear the selection on focusout to prevent the menu from remaining
          // open if the user clicks outside the editor
          editorView.dispatch(
            editorView.state.tr.setSelection(
              Selection.atStart(editorView.state.doc),
            ),
          );
          // Don't prevent events from propagating.
          return true;
        },
      },
    },
    view: (editorView) => {
      const Menu = createMenu({
        remainClosedWhen,
        editorView,
        items: [
          {
            id: 'section-1',
            items: [
              {
                command: toggleMark(schema.marks.strong),
                Icon: StrongIcon,
                id: 'strong',
                isActive: isMarkActive(schema.marks.strong),
                type: 'icon',
              },
              {
                command: toggleMark(schema.marks.em),
                Icon: EmphasisIcon,
                id: 'em',
                isActive: isMarkActive(schema.marks.em),
                type: 'icon',
              },
              {
                command: wrapIn(schema.nodes.blockquote),
                Icon: QuoteIcon,
                id: 'blockquote',
                // TODO figure out how to do this
                isActive: () => false,
                type: 'icon',
              },
              {
                command: wrapInList(schema.nodes.bulletList),
                Icon: BulletListIcon,
                id: 'bullet-list',
                // TODO figure out how to do this
                isActive: () => false,
                type: 'icon',
              },
              {
                command: wrapInList(schema.nodes.orderedList),
                Icon: OrderedListIcon,
                id: 'ordered-list',
                // TODO figure out how to do this
                isActive: () => false,
                type: 'icon',
              },
            ],
          },
          {
            id: 'section-2',
            items: [
              {
                command: openLinkPrompt,
                Icon: LinkIcon,
                id: 'link',
                isActive: () => false,
                type: 'icon',
              },
            ],
          },
        ],
      });

      return createAdaptedView({
        editorView,
        Component: ({ state }) => (
          // For now this is fine, but if we add support for dark themes, we
          // need to find a way to propagate the theme from the root theme
          // context into this sub-context.
          <ThemeProvider theme={themes.light}>
            <Menu state={state} />
          </ThemeProvider>
        ),
        onRender: (dom) => {
          editorView.dom.parentNode?.insertBefore(dom, editorView.dom);
        },
      });
    },
  });
