import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { TalkingBubble, TalkingBubbleLineDetail } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    {...props}
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g strokeWidth="1.188" transform="translate(-370 -358)">
        <g transform="translate(0 122)">
          <g transform="translate(370 237)">
            <g>
              <TalkingBubble d="M7.568 14.844c-.403-.071-6.333 2.098-6.333 2.098 1.457-1.37 2.209-2.692 2.035-4.006A6.666 6.666 0 01.594 7.75C.594 3.737 4.552.483 9.5.483s8.906 3.254 8.906 7.267c0 4.014-3.99 7.292-8.906 7.292a10.878 10.878 0 01-1.932-.198h0z" />
              <TalkingBubbleLineDetail d="M11.012 3.008c2.692.554 4.687 2.534 4.687 4.9a4.75 4.75 0 01-2.114 3.8" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
));
