import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md};

  strong {
    cursor: pointer;
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;
