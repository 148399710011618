import { base64toFile } from 'utils/fileToBase64';

// https://stackoverflow.com/a/29398757

export const handleRotate = async (
  src: string,
  angle: number,
  onSave: (file: File) => void,
) => {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = src;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if ([90, 270].indexOf(angle) > -1) {
      canvas.width = img.height;
      canvas.height = img.width;
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
    }
    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    if (angle === 90 || angle === 270) {
      ctx?.translate(img.height / 2, img.width / 2);
    } else {
      ctx?.translate(img.width / 2, img.height / 2);
    }
    ctx?.rotate((angle * Math.PI) / 180);
    ctx?.drawImage(img, -img.width / 2, -img.height / 2);
    const newFileUrl = canvas.toDataURL('image/jpeg');
    const newRotateFile = base64toFile(newFileUrl);
    onSave(newRotateFile);
  };
};
