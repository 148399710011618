import { MouseEventHandler } from 'react';
import { Props } from './types';

export const handleClick = ({
  file,
  onDeleteFile,
}: {
  file: Props['file'];
  onDeleteFile: Props['onDeleteFile'];
}): MouseEventHandler<HTMLDivElement> => (e) => {
  e.stopPropagation();
  onDeleteFile(file);
};
