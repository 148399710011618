import ClickDropdown from 'components/ClickDropdown';
import { InternalLink } from 'components/Link/styles';
import React from 'react';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(ClickDropdown)`
  margin: 0 0 1rem;
  justify-self: center;

  ${from.tablet`
    display: none;
  `}

  > * > * {
    :hover {
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.2);
      border: 1px solid ${({ theme }) => theme.colors.grey200};
    }
  }
`;

export const TabLink = styled((props) => <InternalLink {...props} />)<{
  $active: boolean;
}>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  padding: 0.8rem 0.8rem;
  margin-top: ${({ theme }) => theme.spacing.xsm};
  border-radius: 4px;
  :hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }
  ${({ $active }) =>
    $active &&
    css`
        border: 1px solid ${({ theme }) => theme.colors.accent};
        }
      `}
`;
