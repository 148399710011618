import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { LeftChain, MiddleChain, RightChain } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 18 9" {...props}>
    {children}
    <g fill="currentColor">
      <LeftChain d="M7.674 5.88a.75.75 0 00-.98.406.725.725 0 01-.165.244.739.739 0 01-.529.22H3a1.5 1.5 0 01-1.5-1.5V3A1.5 1.5 0 013 1.5h3c.2 0 .39.079.531.22.07.07.125.152.163.244a.75.75 0 101.385-.576A2.25 2.25 0 006 0H3a3 3 0 00-3 3v2.25a3 3 0 003 3h3a2.255 2.255 0 002.079-1.387.75.75 0 00-.405-.982z" />
      <MiddleChain d="M4.5 4.125c0 .414.336.75.75.75h8.25a.75.75 0 100-1.5H5.25a.75.75 0 00-.75.75z" />
      <RightChain d="M15 0h-3c-.91 0-1.73.548-2.079 1.388a.75.75 0 101.385.575A.742.742 0 0112 1.5h3A1.5 1.5 0 0116.5 3v2.25a1.5 1.5 0 01-1.5 1.5h-3a.74.74 0 01-.531-.22.721.721 0 01-.163-.243.75.75 0 00-1.385.576A2.25 2.25 0 0012 8.25h3a3 3 0 003-3V3a3 3 0 00-3-3z" />
    </g>
  </svg>
));
