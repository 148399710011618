import DefaultAvatarInput from 'components/AvatarInput';
import { Avatar, ChangeAvatar, Loader } from 'components/AvatarInput/styles';
import { ButtonDefault } from 'components/Button/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form``;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
`;

export const Info = styled.p`
  font-size: 14px;
  line-height: 1rem;
  margin: 0.8rem 0 0 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.875rem 0.375rem;

  ${from.tablet`
    flex-direction: row;
    padding: 2.5rem 3.75rem 0.375rem;
  `};
`;

export const Inputs = styled.div`
  flex: 1;

  ${from.tablet`
    margin-left: 2.25rem;
  `};
`;

export const AvatarInput = styled(DefaultAvatarInput)`
  ${Avatar} {
    height: 13.75rem;
    width: 13.75rem;
  }

  ${Loader} {
    height: 13.75rem;
    width: 13.75rem;
  }

  ${ChangeAvatar} {
    width: auto;
    margin-top: 20px;
  }

  ${ButtonDefault} {
    padding: 0.8125rem 1.25rem;
  }
`;

export { TitleSection } from '../styles';
