import uniq from 'lodash/uniq';
import { stringify } from 'query-string';
import { useEffect } from 'react';
import { combineLatest, of } from 'rxjs';
import { useEventCallback, useObservable } from 'rxjs-hooks';
import { ajax } from 'rxjs/ajax';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  withLatestFrom,
} from 'rxjs/operators';
import { MAPBOX_ACCESS_TOKEN } from 'utils/config';
import { useReactiveValueRef } from '../../utils/useReactiveValueRef';
import { MapboxResponse, Props } from './types';

const parseValue = (inputValue: string) =>
  encodeURIComponent(
    inputValue
      .toLowerCase()
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/;/g, ''),
  );

const parseResponse = ({ features }: MapboxResponse) =>
  uniq(
    features.map(({ place_name }) =>
      // Remove duplicate names, i.e:
      // Sevilla, Sevilla, Spain -> Sevilla, Spain
      uniq(place_name.split(', ')).join(', '),
    ),
  );

export const loadOptionsByCoordinates = (coordinates: Coordinates) => {
  const query = stringify({
    access_token: MAPBOX_ACCESS_TOKEN,
    types: 'place',
  });

  const coordString = `${coordinates.longitude},${coordinates.latitude}`;

  return ajax({
    url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordString}.json?${query}`,
  }).pipe(
    map(({ response }) => response),
    map(parseResponse),
    catchError(() => []),
  );
};

const loadOptionsByQuery = (
  inputValue: string,
  coordinates: Coordinates | null,
) => {
  const query = stringify({
    access_token: MAPBOX_ACCESS_TOKEN,
    autocomplete: true,
    types: 'place',
    ...(coordinates
      ? {
          proximity: `${coordinates.longitude},${coordinates.latitude}`,
        }
      : {}),
  });

  return ajax({
    url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue}.json?${query}`,
  }).pipe(
    map(({ response }) => response),
    map(parseResponse),
    catchError(() => []),
  );
};

export const useOptions = ({
  isEmpty,
  onChange,
  visited,
}: {
  isEmpty: boolean;
  onChange: Props['input']['onChange'];
  visited: Props['meta']['visited'];
}) => {
  const isEmptyRef = useReactiveValueRef(isEmpty);

  const [coordinates, nearestPlaces] = useObservable<
    [Coordinates | null, string[]],
    [typeof visited]
  >(
    (input$) =>
      input$.pipe(
        filter(([visited]) => visited),
        mergeMap(() => {
          if ('geolocation' in navigator) {
            return new Promise<Position>((resolve, reject) =>
              navigator.geolocation.getCurrentPosition(resolve, reject),
            );
          }

          return [];
        }),
        mergeMap(({ coords }) =>
          combineLatest([
            of(coords),
            loadOptionsByCoordinates(coords).pipe(startWith([])),
          ]),
        ),
        catchError(() => []),
      ),
    [null, []],
    [visited],
  );

  useEffect(() => {
    if (isEmptyRef.current && nearestPlaces.length > 0) {
      onChange(nearestPlaces[0]);
    }
  }, [isEmptyRef, nearestPlaces, onChange]);

  const [onInputValueChange, options] = useEventCallback<
    string,
    string[],
    [typeof coordinates, typeof nearestPlaces]
  >(
    (event$, input$) =>
      event$.pipe(
        withLatestFrom(input$),
        mergeMap(([inputValue, [coordinates, nearestPlaces]]) => {
          const parsed = parseValue(inputValue);

          if (!parsed) {
            return [nearestPlaces];
          }

          return loadOptionsByQuery(parsed, coordinates);
        }),
      ),
    [],
    [coordinates, nearestPlaces],
  );

  return { onInputValueChange, options };
};
