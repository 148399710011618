/*
 * WARNING!
 *
 * This file was generated by the following autogen template:
 *
 * src/redux/modules/appEpics.ts.hbs
 *
 * Do not modify, as any changes will be overwritten!
 */

import { combineEpics } from 'redux-observable';
import { epic as activeBanner } from './activeBanner';
import { epic as activity } from './activity';
import { epic as auth } from './auth';
import { epic as challenge } from './challenge';
import { epic as challengeResponse } from './challengeResponse';
import { epic as comment } from './comment';
import { epic as configuration } from './configuration';
import { epic as conversation } from './conversation';
import { epic as courses } from './courses';
import { epic as discover } from './discover';
import { epic as event } from './event';
import { epic as exercise } from './exercise';
import { epic as exerciseBank } from './exerciseBank';
import { epic as exerciseResponse } from './exerciseResponse';
import { epic as feedback } from './feedback';
import { epic as home } from './home';
import { epic as intent } from './intent';
import { epic as liveEvents } from './liveEvents';
import { epic as myOpenFeedback } from './myOpenFeedback';
import { epic as officeHour } from './officeHour';
import { epic as premium } from './premium';
import { epic as report } from './report';
import { epic as search } from './search';
import { epic as settings } from './settings';
import { epic as users } from './users';
import { epic as video } from './video';
import { epic as vote } from './vote';

export const appEpics = combineEpics(
  activeBanner,
  activity,
  auth,
  challenge,
  challengeResponse,
  comment,
  configuration,
  conversation,
  courses,
  discover,
  event,
  exercise,
  exerciseBank,
  exerciseResponse,
  feedback,
  home,
  intent,
  liveEvents,
  myOpenFeedback,
  officeHour,
  premium,
  report,
  search,
  settings,
  users,
  video,
  vote,
);
