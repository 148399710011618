import { connect } from 'react-redux';
import { editProfile } from 'redux/modules/auth';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (_: RootState, __: OwnProps) => ({});

const mapDispatchToProps = {
  editProfile: editProfile.request,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps);
