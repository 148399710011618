import {
  baseKeymap,
  chainCommands,
  createParagraphNear,
  exitCode,
  liftEmptyBlock,
  splitBlock,
  toggleMark,
} from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { keymap as createKeymapPlugin } from 'prosemirror-keymap';
import {
  liftListItem,
  sinkListItem,
  splitListItem,
} from 'prosemirror-schema-list';

import { replaceSelectionWithEmptyNode } from '../commands';
import {
  decrementSelectedIndex as decrementSelectedIndexInReferenceSelector,
  incrementSelectedIndex as incrementSelectedIndexInReferenceSelector,
  selectOptionReference as selectOptionInReferenceSelector,
} from '../references/ReferenceSelector/actions';
import type { Schema } from '../schema';

const isMac =
  typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;

const insertLineBreak = (schema: Schema) =>
  chainCommands(
    exitCode,
    replaceSelectionWithEmptyNode(schema.nodes.hardBreak),
  );

const commonKeymap = (schema: Schema) => ({
  ArrowDown: incrementSelectedIndexInReferenceSelector,
  ArrowUp: decrementSelectedIndexInReferenceSelector,
  // TODO Set escape to close reference selector or link selector
  Enter: chainCommands<Schema>(
    selectOptionInReferenceSelector,
    splitListItem(schema.nodes.listItem),
    createParagraphNear,
    liftEmptyBlock,
    splitBlock,
  ),
  'Mod-b': toggleMark(schema.marks.strong),
  'Mod-i': toggleMark(schema.marks.em),
  'Mod-y': redo,
  'Mod-z': undo,
  'Shift-Enter': insertLineBreak(schema),
  'Shift-Tab': liftListItem(schema.nodes.listItem),
  Tab: sinkListItem(schema.nodes.listItem),
});

const macKeymap = (schema: Schema) => ({
  'Ctrl-Enter': insertLineBreak(schema),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const pcKeymap = (_: Schema) => ({});

export default function keymap(schema: Schema) {
  return createKeymapPlugin({
    ...baseKeymap,
    ...commonKeymap(schema),
    ...(isMac ? macKeymap : pcKeymap)(schema),
  });
}
