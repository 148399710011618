import { Button as DefaultButton, Modal as DefaultModal } from 'components';
import styled from 'styled-components';

export const Modal = styled(DefaultModal)`
  margin: 0 auto;
  max-width: 24rem;

  .MuiPaper-root {
    color: ${({ theme }) => theme.colors.black};
    padding: ${({ theme }) => theme.spacing.xlg};
  }
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Description = styled.p`
  line-height: 1.5rem;

  strong {
    font-weight: ${({ theme }) => theme.weights.semiBold};
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.lg};
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

export const Button = styled(DefaultButton)`
  width: 100%;
`;
