import { BooleanInput as DefaultBooleanInput } from 'components';
import { Label as BooleanInputLabel } from 'components/BooleanInput/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form``;

export const Description = styled.p`
  font-size: 0.875rem;
  line-height: 1.71rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing.xlg};
  grid-row-gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.laptop`
    grid-template-columns: repeat(2, 1fr);
  `};
  > * {
    margin-bottom: 0;
    border: solid 1px ${({ theme }) => theme.colors.grey300};
  }
`;

export const BooleanInput = styled(DefaultBooleanInput)`
  padding: ${({ theme }) => theme.spacing.md};

  ${BooleanInputLabel} {
    color: ${({ theme }) => theme.colors.black};
  }

  input {
    margin-left: auto;
    order: 1;
  }
`;
