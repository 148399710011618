import DefaultError from 'components/Error';
import styled from 'styled-components';
import { label } from 'styles/mixins';

export const Container = styled.div<{ invalid: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ invalid }) =>
    invalid ? 'margin-bottom:  0.75rem;' : 'margin-bottom:2.125rem;'}
`;

export const Label = styled.label`
  ${label};
`;

export const Error = styled(DefaultError)`
  margin-top: 0.25rem;
`;
