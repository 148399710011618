import { useRouteMatch } from 'react-router';

export const useConnect = () => {
  const { path, url } = useRouteMatch();

  return {
    path,
    url,
  };
};
