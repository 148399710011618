import DefaultBannerWhite from 'components/BannerWhite';
import DefaultCardGrid from 'components/CardGrid';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  position: relative;
`;

export const Content = styled.div`
  opacity: 0.3;
  pointer-events: none;
`;

export const BannerWhite = styled(DefaultBannerWhite)`
  position: absolute;
  top: 15%;
  margin: 1rem;
  opacity: 1;

  > *:first-child {
    display: none;
  }

  svg {
    bottom: -1rem;
    top: auto;
  }

  ${from.mobileXL`
    margin: 1rem 4rem;

    > *:first-child {
      display: block;
    }
  
    svg {
      bottom: auto;
      top: -1.5rem;
    }
  `};
`;

export const CardGrid = styled(DefaultCardGrid)`
  @media (max-width: 750px) {
    > div:nth-child(2) {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    > div:last-child {
      display: none;
    }
  }
`;
