import { connect } from 'react-redux';
import { getDiscoverItem } from 'redux/modules/discover';
import { RootState } from 'redux/modules/types';
import { addVote, removeVote } from 'redux/modules/vote';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { id }: OwnProps) => {
  const { router } = state;

  return {
    item: id && state.discover.byId[id],
    pathname: router.location.pathname,
    search: router.location.search,
  };
};

const mapDispatchToProps = {
  addVote: addVote.request,
  getDiscoverItem: getDiscoverItem.request,
  removeVote: removeVote.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
