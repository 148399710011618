import { connect } from 'react-redux';
import { editChallengeResponse } from 'redux/modules/challengeResponse';
import { RootState } from 'redux/modules/types';
import { openConfirmationModal } from 'utils/openConfirmationModal';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { match }: OwnProps) => ({
  challenge: state.challenge.bySlug[match?.params?.challengeSlug || ''],
  submission:
    state.challengeResponse.bySlug[match?.params?.submissionSlug || ''],
});

const mapDispatchToProps = {
  openConfirmationModal,
  editChallengeResponse: editChallengeResponse.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
