import { ExerciseResponse } from 'model';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { selectExerciseResponseBySlug } from 'redux/modules/exerciseResponse';
import { getUserExerciseResponses } from 'redux/modules/users';
import { useActions } from 'utils/useActions';
import { Props } from './types';

export const useConnect = ({ user }: { user: Props['user'] }) => {
  const { search, state } = useLocation();
  const { path, url } = useRouteMatch<{ userSlug?: string }>();

  const exerciseResponseBySlug = useSelector(selectExerciseResponseBySlug);

  const actions = useActions({
    getUserExerciseResponses: getUserExerciseResponses.request,
  });

  return {
    ...actions,
    path,
    search,
    state,
    url,
    loading: user ? user.exerciseResponseSlugs.loading : false,
    pageCount: user ? user.exerciseResponseSlugs.pageCount : 0,
    items: user
      ? (user.exerciseResponseSlugs.slugs
          .map((slug) => exerciseResponseBySlug[slug])
          .filter(
            (it): it is NonNullable<typeof it> => !!it,
          ) as ExerciseResponse[])
      : [],
  };
};
