import { parse, stringify } from 'query-string';
import React, { FC, useState } from 'react';

import {
  Container,
  Image,
  ImageContainer,
  Overlay,
  Thumbnails,
  Video,
  ZoomIcon,
  ZoomIconBg,
  ZoomLink,
} from './styles';
import Thumbnail from './Thumbnail';
import { Props } from './types';

const MediaListViewer: FC<Props> = ({ media, zoomUrl, ...rest }) => {
  const [_selectedIndex, setSelectedIndex] = useState(0);
  const multipleImage = media.length > 1;

  // If the index is invalid, default to 0.
  // If there are multiple images and the user is selecting the last one, then
  // removes every image but one, the index will be pointing to a non existing
  // image. Discard that index and use 0.
  const selectedIndex = _selectedIndex < media.length ? _selectedIndex : 0;

  if (media.length > 0) {
    return (
      <Container showThumbnails={multipleImage} {...rest}>
        <ImageContainer>
          {media[selectedIndex].mediaType === 'IMAGE' && (
            <>
              <Overlay />
              <Image
                src={media[selectedIndex].file}
                alt=""
                aria-hidden="true"
              />
              {zoomUrl && (
                <ZoomLink
                  to={
                    typeof zoomUrl === 'string'
                      ? {
                          pathname: zoomUrl,
                          search: stringify({
                            selected: selectedIndex || undefined,
                          }),
                        }
                      : {
                          ...zoomUrl,
                          search: stringify({
                            ...parse(zoomUrl.search || ''),
                            selected: selectedIndex || undefined,
                          }),
                        }
                  }
                  aria-label="Preview zoomed image"
                >
                  <ZoomIconBg>
                    <ZoomIcon />
                  </ZoomIconBg>
                </ZoomLink>
              )}
            </>
          )}
          {media[selectedIndex].mediaType === 'VIDEO' && (
            <Video src={media[selectedIndex].file} />
          )}
        </ImageContainer>
        {multipleImage && (
          <Thumbnails>
            {media.map(({ mediaType, thumbnail, file }, i) => (
              <Thumbnail
                active={i === selectedIndex}
                index={i}
                key={thumbnail}
                onClick={setSelectedIndex}
                src={mediaType === 'VIDEO' ? file ?? '' : thumbnail ?? ''}
                mediaType={mediaType}
              />
            ))}
          </Thumbnails>
        )}
      </Container>
    );
  }
  return <></>;
};

export default MediaListViewer;
