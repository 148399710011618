import React, { FC } from 'react';
import { ICON_WIDTH } from 'styles/mixins';
import { Container } from './styles';
import { Props } from './types';

const IconRipple: FC<Props> = ({
  children,
  iconWidth = ICON_WIDTH,
  rippleReach = '0.375rem',
  ...rest
}) => (
  <Container iconWidth={iconWidth} rippleReach={rippleReach} {...rest}>
    {children}
  </Container>
);

export default IconRipple;
