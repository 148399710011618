import React, { forwardRef } from 'react';
import { AddImageIcon, Container, Title } from './styles';
import { Props } from './types';

const UploadImageButton = forwardRef<HTMLDivElement, Props>(
  ({ open, ...rest }, ref) => (
    <Container onClick={open} ref={ref} {...rest}>
      <AddImageIcon />
      <Title>Choose file</Title>
    </Container>
  ),
);

export default UploadImageButton;
