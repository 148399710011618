import DefaultButton from 'components/Button';
import DefaultCountdown from 'components/Countdown';
import { FlipCardBottom, FlipCardTop, Unit } from 'components/Countdown/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Image = styled.img`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => addAlpha(theme.colors.primary, 0.4)} 40%,
    ${({ theme }) => theme.colors.primary}
  );
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.75rem;
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
  ${from.tablet`
    font-size: 2.5rem;
  `}
`;

export const Description = styled.p`
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.5;
  position: relative;
  width: 85%;
  text-align: center;
  margin-top: 44px;

  ${from.tablet`
    width: 58%;
    font-size: 1.25rem;
  `}
`;

export const Countdown = styled(DefaultCountdown)`
  color: ${({ theme }) => theme.colors.primary};

  ${FlipCardTop} {
    margin-bottom: 1px;
    border: 0;
  }
  ${FlipCardTop}, ${FlipCardBottom} {
    background-color: ${({ theme }) => theme.colors.lightViolet};
  }
  ${Unit} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Button = styled(DefaultButton)`
  box-sizing: border-box;
  position: relative;
  min-width: 11.4rem;
  height: 2.875rem;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 50px 0;
  color: ${({ theme }) => theme.colors.white};

  ${from.tablet`
    padding: 50px 0 60px;
  `}
`;
