import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

interface ContainerProps {
  notFirstOrLast: boolean;
}

export const Container = styled.div<ContainerProps>`
  border-top: 2px solid ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem 3.75rem;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }

  ${from.tablet<ContainerProps>`
    flex-direction: row;
    
    > *:not(:last-child) {
      margin: 0 ${({ theme }) => theme.spacing.md} 0;
    }
    
    ${({ notFirstOrLast }) =>
      notFirstOrLast &&
      css`
        > :first-child {
          margin-right: auto;
          margin-left: 0;
        }
      `};
  `};
`;
