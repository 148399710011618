import React, { FC } from 'react';
import ConfirmModal from './ConfirmModal';
import PremiumInterruptionModal from './PremiumInterruptionModal';
import ReportModal from './ReportModal';
import SignUpModal from './SignUpModal';

const Modals: FC = () => (
  <>
    <ConfirmModal />
    <PremiumInterruptionModal />
    <SignUpModal />
    <ReportModal />
  </>
);

export default Modals;
