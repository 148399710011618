import {
  SWDLogoIcon as DefaultSWDLogoIcon,
  SWDLogoSmallIcon as DefaultSWDLogoSmallIcon,
} from 'components/icons';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const BadgeBackground = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  bottom: 0;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 11px;
  line-height: 13px;
  height: 24px;
  letter-spacing: 0.44px;
  padding-left: 4.0625rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

export const BadgeContainer = styled.div<{
  big: boolean;
  $variant?: 'swd' | 'premium';
}>`
  align-items: center;
  bottom: -3px;
  display: flex;
  height: 1rem;
  left: 65%;
  position: absolute;
  justify-content: center;
  width: 1rem;

  ${({ big, $variant }) =>
    big &&
    css`
      bottom: 8.5px;
      height: 43px;
      left: 0.75rem;
      width: 43px;
      border-radius: 6.5px;
      background-color: ${({ theme }) =>
        $variant === 'premium' ? theme.colors.basicGold : theme.colors.accent};
    `}

  ${({ $variant }) =>
    $variant === 'premium' &&
    css`
      rect {
        color: ${({ theme }) => theme.colors.basicGold};
      }
    `}
`;

export const BadgeContent = styled.div``;

export const SWDLogoIcon = styled(DefaultSWDLogoIcon)`
  color: ${({ theme }) => theme.colors.white};
  height: 1.375rem;
  width: 1.875rem;
`;

export const SWDLogoSmallIcon = styled(DefaultSWDLogoSmallIcon)`
  color: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
`;
