import logo from 'assets/images/logo.svg';
import logoGrey from 'assets/images/logoGrey.svg';
import logoSmall from 'assets/images/logoSmall.svg';
import React, { FC, memo } from 'react';
import { Container } from './styles';
import { Props } from './types';

const SWDLogo: FC<Props> = ({ variant = 'big', color, ...rest }) => (
  <Container
    alt="Storytelling with Data"
    src={color === 'grey' ? logoGrey : variant === 'big' ? logo : logoSmall}
    variant={variant}
    color={color}
    {...rest}
  />
);

export default memo(SWDLogo);
