// Typescript doesn't have a NaN type:
// https://github.com/Microsoft/TypeScript/issues/28682
// Also, Typescript doesn't seem to have BigInt literal types, as the number
// type does. So, we can't exclude '0n' from T.
export type Falsy = false | '' | 0 | null | undefined;

export type NonFalsy<T> = T extends Falsy ? never : T;

export function isNotFalsy<T>(value: T): value is NonFalsy<T> {
  return !!value;
}
