import { AchievementIcon as DefaultAchievementIcon } from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const AchievementsContainer = styled.section`
  min-width: 16.875rem;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 2.5rem;
  margin: 0 0 ${({ theme }) => theme.spacing.sm};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  ${from.tabletH`
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
    column-gap: 8px;
  `}
`;

export const AchievementContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: min-content auto;
  row-gap: 4px;
  column-gap: 12px;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-align: left;
  color: ${({ theme }) => theme.colors.black}CC;
`;

export const AchievementIcon = styled(DefaultAchievementIcon)`
  color: ${({ theme }) => theme.colors.accent};
  grid-row: 1/3;
`;

export const AchievementText = styled.p`
  grid-column: 2;
  margin: 0;
  font-size: 14px;
  line-height: 19.6px;
  align-self: flex-end;
`;

export const AchievementDate = styled.p`
  grid-column: 2;
  margin: 0;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.83px;
  align-self: flex-start;
  text-transform: uppercase;
`;
