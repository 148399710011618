import { replace } from 'connected-react-router';
import {
  BookingNotification,
  OfficeHour,
  OfficeHourBooking,
  PaginatedResponse,
} from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { from } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getTimeZoneName } from 'utils/dates';
import { showSnackbar } from '../snackbar';
import {
  createBooking,
  deleteBooking,
  getOfficeHours,
  updateBooking,
} from './actions';

export const createOfficeHourBookingEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(createBooking.request)),
    withLatestFrom(from(getTimeZoneName())),
    mergeMap(([{ payload: { hourId } }, timeZone]) =>
      request<OfficeHourBooking>({
        body: {
          timeZone,
          sendNotification: BookingNotification.NONE,
          session: hourId,
        },
        method: 'POST',
        path: 'office/booking/create',
      }).pipe(
        mergeMap((response) => [createBooking.success(response)]),
        catchError(() => [createBooking.failure()]),
      ),
    ),
  );

export const deleteOfficeHourBookingEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteBooking.request)),
    mergeMap(({ payload: { id } }) =>
      request<OfficeHourBooking>({
        method: 'DELETE',
        path: `office/booking/cancel/${id}`,
      }).pipe(
        mergeMap(() => [
          deleteBooking.success(),
          showSnackbar({
            message: 'Your office hour was deleted!',
          }),
          replace(`/office-hours`),
          getOfficeHours.request(),
        ]),
        catchError(() => [deleteBooking.failure()]),
      ),
    ),
  );

export const getOfficeHoursEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getOfficeHours.request)),
    mergeMap(() =>
      request<PaginatedResponse<OfficeHour>>({
        path: 'office/session',
      }).pipe(
        mergeMap((response) => [getOfficeHours.success(response)]),
        catchError(() => [getOfficeHours.failure()]),
      ),
    ),
  );

export const updateOfficeHourBookingEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(updateBooking.request)),
    mergeMap(({ payload: { session, ...body } }) =>
      request<OfficeHourBooking>({
        body,
        method: 'PATCH',
        path: `office/session/${session}/booking`,
      }).pipe(
        mergeMap((response) => [createBooking.success(response)]),
        catchError(() => [createBooking.failure()]),
      ),
    ),
  );

export default combineEpics(
  createOfficeHourBookingEpic,
  getOfficeHoursEpic,
  updateOfficeHourBookingEpic,
  deleteOfficeHourBookingEpic,
);
