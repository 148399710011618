import { FormNameMap } from 'utils/forms';

export const DISCOVER_SEARCH_OPTIONS_FORM_KEY = 'forms/DiscoverSearchOptions';

export interface DiscoverSearchOptionsForm {
  directionIsAsc: boolean;
  graphTypes: string[];
  order: string;
}

export const DiscoverSearchOptionsFieldNames: FormNameMap<DiscoverSearchOptionsForm> = {
  directionIsAsc: 'directionIsAsc',
  graphTypes: 'graphTypes',
  order: 'order',
};
