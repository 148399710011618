import { useMediaQuery } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import Form from './Form';
import { Container, Paragraph, Title } from './styles';
import { Props } from './types';

const UpdateAdminModal: FC<Props> = ({ history: { push }, match }) => {
  const url = match?.url;
  const isFromTablet = useMediaQuery(from.mobileXL.query);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  return (
    <Container
      animation="slideUp"
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!url}
      scroll="body"
    >
      <Title>Change admin</Title>
      <Paragraph>Enter the info of the new admin of this account.</Paragraph>
      <Form onClose={onClose} />
    </Container>
  );
};

export default connect(UpdateAdminModal);
