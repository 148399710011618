import { createEditorState, parseHTML } from 'components/Editor';
import { isLoading } from 'components/MediaInput';
import {
  ExerciseResponseForm,
  EXERCISE_RESPONSE_FORM_KEY,
  normalizeMediaList,
  UPLOAD_MEDIA_FIELD,
  visualTagInputsInitialValues,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { editSlug }: OwnProps) => {
  const form = EXERCISE_RESPONSE_FORM_KEY(editSlug);

  const { selectIsPristine, selectValues } = makeFormSelectors<
    ExerciseResponseForm
  >(form);
  const values = selectValues(state);

  const submissionToEdit = state.exerciseResponse.bySlug[editSlug ?? ''];

  return {
    form,
    initialValues: {
      ...visualTagInputsInitialValues,
      [UPLOAD_MEDIA_FIELD]: [],
      description: createEditorState(),
      title: '',
      ...(submissionToEdit
        ? {
            [UPLOAD_MEDIA_FIELD]: normalizeMediaList(
              submissionToEdit.mediaList,
            ),
            addToMyGallery: submissionToEdit.addToMyGallery,
            description: createEditorState(
              parseHTML(submissionToEdit.description),
            ),
            graphType: submissionToEdit.graphTypes,
            industry: submissionToEdit.graphIndustry,
            title: submissionToEdit.title,
            tools: submissionToEdit.graphTools,
            topic: submissionToEdit.graphTopic,
          }
        : {}),
    },
    isPristine: selectIsPristine(state),
    loading:
      state.exerciseResponse.loading || isLoading(values?.[UPLOAD_MEDIA_FIELD]),
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ExerciseResponseForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ExerciseResponseForm>({
    destroyOnUnmount: false,
  }),
) as FormDecorator<ExerciseResponseForm, OwnProps, ConnectedProps>;
