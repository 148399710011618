import styled, { css } from 'styled-components';

export const Container = styled.div<{
  isFocused: boolean;
  isDisabled: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 4px;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  flex-wrap: nowrap;
  min-height: 45.09px;
  min-width: 5rem;
  transition: all 100ms;

  > :first-child {
    flex-wrap: nowrap;
    white-space: nowrap;

    > :first-child {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  :hover {
    outline: none;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      border-color: ${({ theme }) => theme.colors.accent};
    `};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      background-color: ${({ theme }) => theme.colors.grey100};
      opacity: 0.3;
    `};
`;
