import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { getExerciseBankItems } from './actions';
import { ExerciseBankAction, ExerciseBankState } from './types';

export const INITIAL_STATE: ExerciseBankState = {
  ...defaultStandardState,
};

const reducer: Reducer<ExerciseBankState, ExerciseBankAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getExerciseBankItems.request),
      success: isActionOf(getExerciseBankItems.success),
      failure: isActionOf(getExerciseBankItems.failure),
    },
  },
  contractItem: ({ id, slug }) => ({ id, slug }),
  initialState: INITIAL_STATE,
  perPage: 10,
})(reducer);
