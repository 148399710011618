import React, { FC } from 'react';
import Blogs from './Blogs';
import Books from './Books';
import Challenges from './Challenges';
import Conversations from './Conversations';
import Exercises from './Exercises';
import Feedback from './Feedback';
import { connectSectionDetail } from './logic';
import Podcasts from './Podcasts';
import { Props } from './types';
import Videos from './Videos';
import Visuals from './Visuals';

const Sections: FC<Props> = ({ ...rest }) => (
  <>
    <Blogs {...rest} />
    <Books {...rest} />
    <Podcasts {...rest} />
    <Conversations {...rest} />
    <Visuals {...rest} />
    <Challenges {...rest} />
    <Exercises {...rest} />
    <Feedback {...rest} />
    <Videos {...rest} />
  </>
);

export default Sections;

export const BlogsSection = connectSectionDetail(Blogs);
export const BooksSection = connectSectionDetail(Books);
export const PodcastsSection = connectSectionDetail(Podcasts);
export const ConversationsSection = connectSectionDetail(Conversations);
export const VisualsSection = connectSectionDetail(Visuals);
export const ChallengesSection = connectSectionDetail(Challenges);
export const ExercisesSection = connectSectionDetail(Exercises);
export const FeedbackSection = connectSectionDetail(Feedback);
export const VideosSection = connectSectionDetail(Videos);
