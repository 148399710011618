import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { selectPlans } from 'redux/modules/configuration';
import { selectPremiumYearlySavings } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  isPremium: selectUserIsPremium(state),
  plans: selectPlans(state),
  yearlySavings: selectPremiumYearlySavings(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
