import { Modal } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';
import { Title as DefaultTitle } from '../../SubscriptionRoot/styles';

export const Container = styled(Modal)`
  & .MuiPaper-root {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 2rem;
    overflow: hidden;

    ${from.mobileXL`
      max-width: 43.3125rem;
      min-height: unset;
      width: 90vw;
    `};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled(DefaultTitle)`
  margin-bottom: 0.375rem;
`;
