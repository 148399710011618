import {
  MediaInput as DefaultMediaInput,
  Textarea as DefaultTextarea,
} from 'components';
import { DropZone } from 'components/MediaSelector/SingleFileVariant/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.form<{
  isCommentReply: boolean;
  swdTeam: boolean;
}>`
  ${({ isCommentReply, swdTeam }) =>
    isCommentReply &&
    css`
      border-left: 2px solid
        ${({ theme }) => theme.colors[swdTeam ? 'primary' : 'grey200']};
      padding-left: ${({ theme }) => theme.spacing.md};
      > :last-child {
        justify-content: flex-start;
      }
    `}
`;

export const Row = styled.div<{ small: boolean }>`
  ${({ small }) =>
    small &&
    css`
      display: flex;
      flex-direction: column;

      > * {
        margin-bottom: 0;
      }

      > *:not(:last-child) {
        flex: 1;
        margin-right: 0;
      }

      ${from.tablet`
        flex-direction: row;

        > *:not(:last-child) {
          margin-right: ${({ theme }) => theme.spacing.lg};
        }
     `};
    `};
`;

export const Textarea = styled(DefaultTextarea)`
  flex: 1;
`;

export const MediaInput = styled(DefaultMediaInput)<{ compact: boolean }>`
  min-width: 100%;
  ${DropZone} {
    flex: 1;
    > * > svg:first-child {
      width: 2rem;
      ${from.tablet`
        width: 1rem;
        margin-bottom: 1rem;
      `}

      ${({ compact }) => compact && `margin-bottom: 0`};
    }
  }

  ${from.tablet`
    min-width: 8rem;
    > :last-child {
      height: 100%;
    }
  `};

  &:focus-within {
    ${DropZone} {
      background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.1)};
      box-shadow: 0 6px 29px -10px ${({ theme }) => addAlpha(theme.colors.grey400, 0.4)};
    }
  }
`;
