import { ExerciseResponseExpanded, PaginatedResponse } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getExerciseBankItems } from './actions';

export const normalizeExercise = (result: ExerciseResponseExpanded) => ({
  exerciseId: result.exercise.id,
  exerciseSlug: result.exercise.slug,
  exerciseName: result.exerciseName,
  exerciseLocked: result.exercise.submittedResponse,
  exerciseSubmissions: result.exercise.submissionCount,
  id: result.id,
  created: result.created,
  modified: result.modified,
  slug: result.slug,
  submissionAuthor: `${result.user.firstName} ${result.user.lastName}`,
  submissionImage: result.mediaList?.mainMedia.thumbnail ?? '',
  submissionName: result.title,
});

export const getExerciseBankItemsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getExerciseBankItems.request)),
    mergeMap(() =>
      request<PaginatedResponse<ExerciseResponseExpanded>>({
        path: 'exerciseresponses/latest',
      }).pipe(
        mergeMap((response) => {
          return [
            getExerciseBankItems.success({
              ...response,
              results: response.results.map(normalizeExercise),
            }),
          ];
        }),
        catchError(() => [getExerciseBankItems.failure()]),
      ),
    ),
  );

export default combineEpics(getExerciseBankItemsEpic);
