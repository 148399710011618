import Button from 'components/Button';
import { OfficeHoursIcon } from 'components/icons';
import React, { forwardRef } from 'react';
import { getDateAndTime } from './logic';
import {
  Actions,
  Container,
  Date,
  Description,
  Info,
  Section,
  Separator,
} from './styles';
import { Props } from './types';

const OfficeHourBook = forwardRef<HTMLDivElement, Props>(
  ({ endDate, loading, onBook, onClose, startDate, ...rest }, ref) => {
    const { date, time } = getDateAndTime({ endDate, startDate });

    return (
      <Container ref={ref} {...rest}>
        <OfficeHoursIcon />
        <Info>
          <Section>Office Hours</Section>
          <Date>
            <span>
              <strong>{date}</strong>
            </span>
            <Separator>|</Separator>
            <span>{time}</span>
          </Date>
          <Description>
            We’ll send an invite to your email with a{' '}
            <a
              href="https://zoom.us/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Zoom
            </a>{' '}
            link.
          </Description>
          <Actions>
            <Button disabled={loading} onClick={onBook}>
              Book now
            </Button>
            <Button disabled={loading} onClick={onClose} variant="secondary">
              Cancel
            </Button>
          </Actions>
        </Info>
      </Container>
    );
  },
);

export default OfficeHourBook;
