import { Link, ShareButton as DefaultShareButton } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 3rem ${({ theme }) => theme.wrapperPaddingH.mobile} 1rem
    ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.tablet`
    padding: 3rem 2rem 2rem;
  `};

  ${from.laptop`
     padding: 2.5rem 3.75rem;
  `};
`;

export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const TitleLink = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.xsm};
`;

export const ShareButton = styled(DefaultShareButton)`
  margin-left: auto;
`;
