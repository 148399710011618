import { useEffect, useMemo } from 'react';
import { epicDependencies } from 'redux/modules';
import { useObservable } from 'rxjs-hooks';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { uploadImage } from 'utils/uploadImage';
import { Media } from '../../../types';
import { Props } from './types';

export const useThumbnail = (file: string | File): string => {
  return useMemo(
    () => (typeof file === 'string' ? file : URL.createObjectURL(file)),
    [file],
  );
};

export const useUploadImage = ({
  media,
  onUploaded,
}: {
  media: Media;
  onUploaded: Props['onUploaded'];
}) => {
  const uploadState = useObservable<
    { error?: boolean; progress: number; url?: string } | null,
    [Media]
  >(
    (input$) =>
      input$.pipe(
        mergeMap(([media]) => {
          if (media.status === 'uploading') {
            return [media.file];
          }

          return [];
        }),
        mergeMap((file) =>
          uploadImage(file, epicDependencies).pipe(
            map(({ progress, url }) => ({
              progress,
              url: progress === 1 ? url : undefined,
            })),
            catchError(() => [{ error: true, progress: 0 }]),
          ),
        ),
      ),
    null,
    [media],
  );

  const url = uploadState?.url;
  const progress = uploadState?.progress ?? 0;

  useEffect(() => {
    if (media.status === 'uploading' && url) {
      onUploaded(media, url);
    }
  }, [media, onUploaded, url]);

  return { progress };
};
