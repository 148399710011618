import { Navigation, PageTitle, Tabs } from 'components';
import React, { FC, useCallback } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, Switch } from 'react-router-dom';
import { up } from 'utils/Paths';
import GoPremium from '../GoPremium';
import ChangeAccount from './ChangeAccount';
import { useConnect } from './connect';
import FormChangePassword from './FormChangePassword';
import FormEditProfile from './FormEditProfile';
import FormEmailNotifications from './FormEmailNotifications';
import {
  handleChangePasswordFormSubmitted,
  handleEditProfile,
  handleNotificationsFormSubmitted,
} from './logic';
import { Container, Content, Controls, SWDLogo, Title } from './styles';
import Subscription from './Subscription';
import { Props } from './types';

const Settings: FC<Props> = () => {
  const {
    changePassword,
    editProfile,
    editSettings,
    isPremium,
    isForcedPremium,
    isPremiumGroupMember,
    isPremiumGroupOwner,
    path,
    url,
    userId,
  } = useConnect();

  const onEditProfile = useCallback(handleEditProfile({ editProfile }), [
    editProfile,
  ]);
  const onFormNotificationsSubmitted = useCallback(
    handleNotificationsFormSubmitted({ editSettings }),
    [editSettings],
  );
  const onFormChangePasswordSubmitted = useCallback(
    handleChangePasswordFormSubmitted({ changePassword }),
    [changePassword],
  );

  if (userId) {
    // This route is exclusively reserved for "/members/me"
    return <Redirect to={up(url)} />;
  }

  return (
    <Container>
      <PageTitle title="Settings" />
      <BreadcrumbsItem to={url}>Account settings</BreadcrumbsItem>
      <Navigation />
      <Title>Account settings</Title>
      <Content id="#settingscontent">
        <Controls>
          <Tabs
            baseUrl={url}
            items={[
              {
                label: 'Edit profile',
                url: {
                  pathname: `edit-profile`,
                  state: {
                    scrollToElement: '#settingscontent',
                  },
                },
              },
              {
                label: 'My account',
                url: {
                  pathname: `change-account`,
                  state: {
                    scrollToElement: '#settingscontent',
                  },
                },
              },
              {
                label: 'Change password',
                url: {
                  pathname: `change-password`,
                  state: {
                    scrollToElement: '#settingscontent',
                  },
                },
              },
              {
                label: 'Email notifications',
                url: {
                  pathname: `email-notifications`,
                  state: {
                    scrollToElement: '#settingscontent',
                  },
                },
              },
              isPremium ||
              isForcedPremium ||
              isPremiumGroupMember ||
              isPremiumGroupOwner
                ? {
                    label: 'Premium subscription',
                    url: {
                      pathname: `subscription`,
                      state: {
                        scrollToElement: '#settingscontent',
                      },
                    },
                  }
                : {
                    label: (
                      <>
                        Go premium! <SWDLogo />
                      </>
                    ),
                    url: {
                      pathname: `go-premium`,
                      state: {
                        scrollToElement: '#settingscontent',
                      },
                    },
                  },
            ]}
          />
        </Controls>
        <Switch>
          <Route path={`${path}/edit-profile`}>
            <FormEditProfile onSubmit={onEditProfile} />
          </Route>
          <Route path={`${path}/email-notifications`}>
            <FormEmailNotifications onSubmit={onFormNotificationsSubmitted} />
          </Route>
          <Route path={`${path}/change-password`}>
            <FormChangePassword onSubmit={onFormChangePasswordSubmitted} />
          </Route>
          <Route path={`${path}/change-account`}>
            <ChangeAccount />
          </Route>
          <Route path={`${path}/go-premium`}>
            <GoPremium />
          </Route>
          <Route path={`${path}/subscription`}>
            <Subscription />
          </Route>
          <Redirect to={`${url}/edit-profile`} />
        </Switch>
      </Content>
    </Container>
  );
};

export default Settings;
