import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="39"
    height="37"
    viewBox="0 0 39 37"
  >
    <defs>
      <rect id="link-b" width="29" height="27" x="0" y="0" rx="4" />
      <filter
        id="link-a"
        width="158.6%"
        height="163%"
        x="-29.3%"
        y="-24.1%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          radius="1.5"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.28627451   0 0 0 0 0.31372549   0 0 0 0 0.341176471  0 0 0 0.400841346 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 3)">
      <use fill="#000" filter="url(#link-a)" xlinkHref="#link-b" />
      <rect
        width="28"
        height="26"
        x=".5"
        y=".5"
        fill="#FFF"
        stroke="#297FD5"
        strokeLinejoin="inherit"
        rx="4"
      />
      <g fillRule="nonzero" transform="translate(6 9)">
        <path
          fill="#297FD5"
          d="M7.67399847,5.88075183 L7.67399843,5.88075181 C7.29147218,5.72191756 6.85261343,5.90323764 6.69374843,6.28575181 L6.69374846,6.28575176 C6.65631783,6.37763876 6.60041246,6.46085801 6.52949846,6.53025176 L6.52949849,6.53025172 C6.38977499,6.67179172 6.19888349,6.75101422 6,6.75000172 L3,6.75000172 L3,6.75000172 C2.17157093,6.75000168 1.5,6.07842847 1.5,5.25000172 C1.5,5.25000172 1.5,5.25000172 1.5,5.25000172 L1.5,3.00000028 L1.5,3.00000028 C1.5,2.17157445 2.17157093,1.50000028 3,1.50000028 L6,1.50000028 L6,1.50000028 C6.1994114,1.49929201 6.3907454,1.57874445 6.5309984,1.72050195 L6.5309984,1.72050194 C6.60110638,1.78967632 6.6564704,1.87233944 6.6937484,1.96350194 L6.69374839,1.96350192 C6.84927514,2.34740817 7.28657239,2.53254642 7.67048089,2.37701967 C8.05438714,2.22149292 8.23952539,1.78419567 8.08399864,1.40028717 C8.08236735,1.39626043 8.08070109,1.39224792 8.07900006,1.38825019 L8.07900013,1.38825035 C7.73048338,0.547672852 6.90996763,-0.000232147874 6,2.78160255e-07 L3,2.78160255e-07 L3,2.78160255e-07 C1.343145,2.78160255e-07 3.26849658e-13,1.34314535 3.26849658e-13,3.00000028 C3.26849658e-13,3.00000028 3.26849658e-13,3.00000028 3.26849658e-13,3.00000028 L3.26849658e-13,5.25000035 L3.26849658e-13,5.25000081 C3.26849658e-13,6.90685581 1.343145,8.25000081 3,8.25000081 L6,8.25000081 L6,8.25000081 C6.90935262,8.24894207 7.72911012,7.70184755 8.07900012,6.8625008 L8.07900014,6.86250076 C8.23854914,6.48024826 8.05801199,6.04103326 7.67575964,5.88148576 C7.67517349,5.88124111 7.67458703,5.8809972 7.67400026,5.88075403 L7.67399847,5.88075183 Z"
        />
        <path
          fill="#004A8F"
          d="M4.49999841,4.12500183 L4.49999841,4.12500194 C4.49999841,4.53921569 4.83578472,4.87500194 5.24999847,4.87500194 L13.4999984,4.87500194 L13.4999984,4.87500194 C13.9142122,4.87500194 14.2499984,4.53921569 14.2499984,4.12500194 C14.2499984,3.71078819 13.9142122,3.37500194 13.4999984,3.37500194 L5.24999844,3.37500194 L5.24999841,3.37500194 C4.83578466,3.37500194 4.49999841,3.71078819 4.49999841,4.12500194 C4.49999841,4.12500194 4.49999841,4.12500194 4.49999841,4.12500194 L4.49999841,4.12500183 Z"
        />
        <path
          fill="#297FD5"
          d="M14.9999985,3.55271368e-15 L11.9999985,3.55271368e-15 L11.9999985,3.55271368e-15 C11.090586,0.000859184445 10.2707235,0.548021579 9.92099845,1.38750183 L9.92099843,1.38750188 C9.75882368,1.76864813 9.93633481,2.20909688 10.3174807,2.37126938 C10.6986269,2.53344413 11.1390757,2.35593301 11.3012482,1.97478713 C11.3029492,1.97078936 11.3046155,1.96677691 11.3062468,1.96275016 L11.3062467,1.9627502 C11.3438645,1.87096295 11.3997455,1.78777895 11.4704967,1.7182502 L11.4704967,1.71825022 C11.6106612,1.57754722 11.801396,1.49892997 11.9999967,1.5 L14.9999967,1.5 L14.9999967,1.5 C15.8284242,1.5 16.4999967,2.17157272 16.4999967,3 L16.4999967,5.25000022 L16.4999967,5.25000022 C16.4999967,6.07842772 15.8284234,6.75000022 14.9999967,6.75000022 L11.9999967,6.75000022 L11.9999967,6.75000022 C11.8005169,6.7510496 11.6090562,6.67154497 11.4689967,6.52950022 L11.4689967,6.52950023 C11.3987264,6.46046033 11.3433387,6.37776173 11.3062467,6.28650023 L11.3062467,6.28650027 C11.147189,5.90397402 10.708148,5.72281752 10.3256217,5.88187527 C9.94309545,6.04093302 9.76193895,6.47997402 9.9209967,6.86250027 L9.92099665,6.86250013 C10.2697466,7.70278513 11.0902166,8.25035263 11.9999966,8.2500003 L14.9999966,8.2500003 L14.9999965,8.2500003 C16.6568515,8.2500003 17.9999965,6.90685513 17.9999965,5.25000013 L17.9999965,3 L17.9999965,3 C17.9999965,1.343145 16.6568515,3.55271368e-15 14.9999965,3.55271368e-15 L14.9999985,3.55271368e-15 Z"
        />
      </g>
    </g>
  </svg>
));
