import { EditorState } from 'components/Editor/types';
import { Media } from 'components/MediaSelector/types';
import { VisualTagInputsFieldNames, VisualTagInputsForm } from 'model';
import { FormNameMap } from 'utils/forms';
import { UPLOAD_MEDIA_FIELD } from '../utils';

export const ADD_TO_GALLERY_FORM_KEY = 'forms/ADD_TO_GALLERY';

export interface AddToGalleryForm extends VisualTagInputsForm {
  [UPLOAD_MEDIA_FIELD]: readonly Media[];
  context: EditorState;
  title: string;
}

export const AddToGalleryFieldNames: FormNameMap<AddToGalleryForm> = {
  ...VisualTagInputsFieldNames,
  [UPLOAD_MEDIA_FIELD]: UPLOAD_MEDIA_FIELD,
  context: 'context',
  title: 'title',
};
