import React, { FC, memo } from 'react';
import { Arrow, Container, Text, VisuallyHiddenText } from './styles';
import { Props } from './types';

const ArrowLink: FC<Props> = ({ text, visuallyHiddenText, to, ...rest }) => (
  <Container to={to} {...rest}>
    <Text>{text}</Text>
    <VisuallyHiddenText>{visuallyHiddenText}</VisuallyHiddenText>
    <Arrow />
  </Container>
);

export default memo(ArrowLink);
