import React, { FC, useCallback, useMemo } from 'react';
import { generateGoogleCalendarLink } from 'utils/calendar';
import { handleDownloadCalendar } from './logic';
import { Container, ItemAnchor, ItemButton } from './styles';
import { Props } from './types';

const Content: FC<Props> = ({
  closeDropdown,
  start,
  end,
  name,
  onAdd,
  ...rest
}) => {
  const downloadCalendar = useCallback(
    handleDownloadCalendar({ start, end, name, closeDropdown }),
    [start, end, name, closeDropdown],
  );

  const commonOnClick = useCallback(
    ({ download }: { download: boolean }) => () => {
      onAdd && onAdd();

      if (download) {
        downloadCalendar();
      } else {
        closeDropdown();
      }
    },
    [closeDropdown, downloadCalendar, onAdd],
  );

  const onClickCalendar = useMemo(() => commonOnClick({ download: true }), [
    commonOnClick,
  ]);
  const onClickGoogleCalendar = useMemo(
    () => commonOnClick({ download: false }),
    [commonOnClick],
  );

  return (
    <Container {...rest}>
      <ItemButton onClick={onClickCalendar}>Calendar</ItemButton>
      <ItemAnchor
        href={generateGoogleCalendarLink({
          end,
          name,
          begin: start,
        })}
        onClick={onClickGoogleCalendar}
        rel="noreferrer noopener"
        target="_blank"
      >
        Google Calendar
      </ItemAnchor>
    </Container>
  );
};

export default Content;
