import styled from 'styled-components';

export const BookCover = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const BookSheets = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const BookCoverSeparator = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;
