import DOMPurify from 'dompurify';
import React, { FC, memo } from 'react';
import {
  afterSanitizeAttributesCallback,
  useAfterRender,
  useSanitizedHTML,
} from './logic';
import { Container } from './styles';
import { Props } from './types';

export function getTextFromHtml(content: string) {
  return DOMPurify.sanitize(content, { ALLOWED_TAGS: [] });
}

DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributesCallback);

const RichText: FC<Props> = ({ content, ...rest }) => {
  const sanitizedHTML = useSanitizedHTML(content);
  const { ref } = useAfterRender();

  return (
    <Container
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      ref={ref}
      {...rest}
    />
  );
};

export default memo(RichText);
