import { connect } from 'react-redux';
import { hideSnackbar, pushFromQueue } from 'redux/modules/snackbar';
import { RootState } from 'redux/modules/types';

const mapStateToProps = ({ snackbar }: RootState) => ({
  message: snackbar.active?.message,
});

const mapDispatchToProps = {
  hideSnackbar,
  pushFromQueue,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps);
