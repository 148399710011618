import { ResetPasswordForm, RESET_PASSWORD_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';
import validate from './validate';

const mapStateToProps = ({ auth }: RootState) => ({
  loading: auth.loading,
});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ResetPasswordForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ResetPasswordForm>({
    validate,
    form: RESET_PASSWORD_FORM_KEY,
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
      repeatPassword: '',
    },
  }),
) as FormDecorator<ResetPasswordForm, OwnProps, ConnectedProps>;
