import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    {children}

    <path
      fill="currentColor"
      d="M12.063 2.065a.667.667 0 0 0-.759.149.805.805 0 0 0-.205.738c.099.44.151.9.151 1.37 0 1.076-.271 1.83-.738 2.455-.474.635-1.16 1.152-2.027 1.735a.25.25 0 0 0-.038.031l-.105.106a6.75 6.75 0 1 0 9.685 2.63.25.25 0 0 0-.413-.05l-.208.241c-.878 1.027-1.587 1.855-3.04 2.225-.062.015-.1.004-.127-.013a.245.245 0 0 1-.091-.124.412.412 0 0 1 .074-.414c.777-.843 1.329-1.987 1.526-3.614.37-3.048-1.015-6.294-3.685-7.465Z"
    />
  </svg>
));
