import { CaretDownIcon as DefaultCaretDownIcon } from 'components/icons';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { burgerLink } from 'styles/mixins';

export const CaretDownIcon = styled(DefaultCaretDownIcon)`
  color: ${({ theme }) => theme.colors.grey400};
  width: 0.4rem;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.sm};
`;

const BurgerDropdown = css`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  outline: none;
  padding: 0;

  > div {
    position: initial;
    width: 100%;
  }

  span {
    ${burgerLink};
    margin: 0;
  }

  ul {
    background-color: transparent;
    box-shadow: none;
    padding: 0 0 1.3125rem 0;
  }

  li a {
    ${burgerLink};
    font-size: 1.25rem;
  }
`;

export const Dropdown = styled.button<{ opened: boolean }>`
  ${BurgerDropdown};
  border: none;
  background: none;
  box-shadow: none;
  cursor: pointer;

  ${({ opened }) =>
    opened &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};
`;

export const Content = motion.custom(styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  a {
    ${burgerLink};
    font-weight: normal;
    filter: brightness(120%);
    margin: 0;
    text-transform: initial;

    &::first-letter {
      text-transform: lowercase;
    }
  }
`);
