import { ClickAwayListener, useMediaQuery } from '@material-ui/core';
import { FilterIcon } from 'components/icons';
import { ActivityFiltersFieldNames } from 'model';
import React, { FC, useCallback, useState } from 'react';
import { Field } from 'redux-form';
import { from } from 'styles/mixins';
import connect from './connect';
import {
  formatEveryoneSelected,
  formatSpecificMemberName,
  formatSpecificMemberSelected,
  formatSWDTeamSelected,
  parseEveryoneSelected,
  parseSpecificMemberName,
  parseSpecificMemberSelected,
  parseSWDTeamSelected,
} from './logic';
import {
  ApplyFilterButton,
  BooleanInputActivity,
  BooleanInputRadio,
  CheckboxRadio,
  Container,
  DropdownContent,
  FiltersGroupTitle,
  FiltersResponsiveButton,
  GroupFields,
  RadioButton,
  SearchContainer,
  SearchInput,
} from './styles';
import { Props } from './types';

const Form: FC<Props> = ({ className, handleSubmit }) => {
  const isFromTablet = useMediaQuery(from.tablet.query);
  const displayDropdown = !isFromTablet;

  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const toggleVisibility = useCallback(
    () => setShowFilterOptions((prevState) => !prevState),
    [],
  );

  return (
    <Container className={className} onSubmit={handleSubmit}>
      {displayDropdown && (
        <FiltersResponsiveButton onClick={toggleVisibility}>
          <FilterIcon />
        </FiltersResponsiveButton>
      )}
      {(showFilterOptions || isFromTablet) && (
        <ClickAwayListener onClickAway={toggleVisibility}>
          <DropdownContent>
            <FiltersGroupTitle>Show me...</FiltersGroupTitle>
            <GroupFields>
              <Field
                CheckboxComponent={CheckboxRadio}
                component={BooleanInputActivity}
                name={ActivityFiltersFieldNames.commentsReceived}
                label="Comments received"
              />
              <Field
                CheckboxComponent={CheckboxRadio}
                component={BooleanInputActivity}
                name={ActivityFiltersFieldNames.commentsGiven}
                label="Comments given"
              />
              <Field
                CheckboxComponent={CheckboxRadio}
                component={BooleanInputActivity}
                name={ActivityFiltersFieldNames.voteGiven}
                label="Datapoints given"
              />
              <Field
                CheckboxComponent={CheckboxRadio}
                component={BooleanInputActivity}
                name={ActivityFiltersFieldNames.voteReceived}
                label="Datapoints received"
              />
              <Field
                CheckboxComponent={CheckboxRadio}
                component={BooleanInputActivity}
                name={ActivityFiltersFieldNames.feedbackReceived}
                label="Feedback received"
              />
              <Field
                CheckboxComponent={CheckboxRadio}
                component={BooleanInputActivity}
                name={ActivityFiltersFieldNames.feedbackGiven}
                label="Feedback given"
              />
            </GroupFields>
            <FiltersGroupTitle>From</FiltersGroupTitle>
            <GroupFields>
              <Field
                CheckboxComponent={RadioButton}
                format={formatEveryoneSelected}
                component={BooleanInputRadio}
                name={ActivityFiltersFieldNames.memberFilter}
                parse={parseEveryoneSelected}
                label="Everyone"
              />
              <Field
                CheckboxComponent={RadioButton}
                format={formatSWDTeamSelected}
                component={BooleanInputRadio}
                name={ActivityFiltersFieldNames.memberFilter}
                parse={parseSWDTeamSelected}
                label="SWD team"
              />
              <Field
                CheckboxComponent={RadioButton}
                format={formatSpecificMemberSelected}
                component={BooleanInputRadio}
                name={ActivityFiltersFieldNames.memberFilter}
                label="Specific member"
                parse={parseSpecificMemberSelected}
              />
            </GroupFields>
            <SearchContainer>
              <Field
                component={SearchInput}
                format={formatSpecificMemberName}
                name={ActivityFiltersFieldNames.memberFilter}
                type="text"
                placeholder="Search member..."
                parse={parseSpecificMemberName}
              />
            </SearchContainer>
            {displayDropdown && (
              <ApplyFilterButton onClick={toggleVisibility}>
                Apply filters
              </ApplyFilterButton>
            )}
          </DropdownContent>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default connect(Form);
