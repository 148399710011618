import { ActivityFiltersForm } from 'model';

export const formatEveryoneSelected = (
  memberFilter: ActivityFiltersForm['memberFilter'] | undefined,
) => memberFilter?.type === 'everyone';

export const formatSWDTeamSelected = (
  memberFilter: ActivityFiltersForm['memberFilter'] | undefined,
) => memberFilter?.type === 'swdTeam';

export const formatSpecificMemberSelected = (
  memberFilter: ActivityFiltersForm['memberFilter'] | undefined,
) => memberFilter?.type === 'specificMember';

export const formatSpecificMemberName = (
  memberFilter: ActivityFiltersForm['memberFilter'] | undefined,
) => (memberFilter?.type === 'specificMember' ? memberFilter?.name : '');

export const parseEveryoneSelected = (val: boolean) => {
  if (val) {
    return { type: 'everyone' };
  }
};

export const parseSWDTeamSelected = (val: boolean) => {
  if (val) {
    return { type: 'swdTeam' };
  }
};

export const parseSpecificMemberSelected = (val: boolean) => {
  if (val) {
    return { type: 'specificMember', name: '' };
  }
};

export const parseSpecificMemberName = (val: string) => {
  return { type: 'specificMember', name: val };
};
