import { createAction } from 'typesafe-actions';
import { ConfirmationModal, ReportModal } from './types';

export const closeModal = createAction('modal/CLOSE_MODAL')<undefined>();

export const openConfirmation = createAction('modal/OPEN_CONFIRMATION')<
  ConfirmationModal['data']
>();

export const openPremiumInterruption = createAction(
  'modal/OPEN_PREMIUM_INTERRUPTION',
)<undefined>();

export const openReport = createAction('modal/OPEN_REPORT')<
  ReportModal['data']
>();

export const openSignUp = createAction('modal/OPEN_SIGN_UP')<undefined>();
