import { Fold as DefaultFold, NoResults as DefaultNoResults } from 'components';
import { Description } from 'components/Fold/styles';
import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  ${container};
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile} 110px;
`;

export const Fold = styled(DefaultFold)`
  ${from.laptop`
    ${Description} {
      width: 50rem;
      margin: 0 auto;
    }
  `}
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const Title = styled.h2`
  margin: 5rem 0 2rem;
  font-size: 1.75rem;
  line-height: 2.125rem;
  color: ${({ theme }) => theme.colors.primary};
`;

export const MoreUpcomingEvents = styled.div``;

export const NoResults = styled(DefaultNoResults)<{ $mainEvent?: boolean }>`
  ${({ $mainEvent }) =>
    $mainEvent &&
    css`
      padding-top: 0;
    `}
`;
