import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { CoursesState } from './types';

const selectState: Selector<CoursesState> = ({ courses }) => courses;

export const selectCourses = createSelector(
  selectState,
  ({ courses }) => courses,
);
