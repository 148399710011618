import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  max-width: 50rem;
`;

export const Section = styled.div<{ disabled: boolean | undefined }>`
  margin-bottom: 5rem;
  ${({ disabled }) =>
    disabled &&
    css`
      margin-bottom: 10rem;
    `} :not (:last-child) {
    margin-bottom: 3.75rem;
  }
`;

export const NewCommentSection = styled.div<{ canAdd: boolean }>`
  border-radius: 0.25rem;
  background-color: var(--white);
  padding: ${({ theme }) => theme.spacing.xsm};

  ${({ canAdd }) =>
    canAdd &&
    css`
      margin-bottom: 6rem;

      ${from.mobileXL`
        padding: ${({ theme }) => theme.spacing.lg};
        box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);
        border: solid 1px ${({ theme }) => theme.colors.greyBlue};
      `}

      ${from.tablet`
        padding: 3.75rem;
        margin-bottom: 10rem;
      `};
    `};
`;

export const TitleRow = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.75rem;

  ${from.mobileXL`
    align-items: center;
    flex-direction: row;
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  `};
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  ${from.tablet`
    font-size: 1.25rem;
    font-weight: ${({ theme }) => theme.weights.bold};
  `};
`;

export const TitlePostComment = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;

  ${from.tablet`
    font-size: 1.75rem;
`}
`;
