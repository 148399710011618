import { useMediaQuery } from '@material-ui/core';
import { DiscoverDetail, Modal } from 'components';
import { getUserName } from 'model';
import React, { FC, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { from } from 'styles/mixins';
import { getUserType } from 'utils/getUserType';
import connect from './connect';
import { ExternalProps, Props } from './types';

const DiscoverItem: FC<Props> = ({
  addVote,
  getDiscoverItem,
  id,
  item,
  onClose,
  pathname,
  removeVote,
  scroll,
  search,
  url,
}) => {
  const fullScreen = !useMediaQuery(from.tablet.query);

  useEffect(() => {
    if (id) {
      getDiscoverItem({ id, silent: true });
    }
  }, [id, getDiscoverItem]);

  const onAddVote = useCallback(() => {
    addVote({
      contentType: 'galleryentry',
      objectId: id || '',
    });
  }, [addVote, id]);

  const onRemoveVote = useCallback(() => {
    removeVote({
      contentType: 'galleryentry',
      objectId: id || '',
    });
  }, [id, removeVote]);

  if (!item || !url) {
    return null;
  }

  const { user, ...rest } = item;

  return (
    <Modal
      open={!!id}
      animation="fade"
      onClose={onClose}
      scroll={undefined}
      fullScreen={fullScreen}
    >
      <DiscoverDetail
        {...rest}
        onAddVote={onAddVote}
        onRemoveVote={onRemoveVote}
        open={!!id}
        pathname={pathname}
        scroll={scroll}
        search={search}
        swdTeam={user.swdTeam}
        url={url}
        userAvatar={user.avatar || undefined}
        userId={user.id}
        userName={getUserName(user)}
        userType={getUserType(user)}
        itemUrl={item.url}
      />
    </Modal>
  );
};

const Connected = connect(DiscoverItem);

const Wrapper: FC<ExternalProps> = (props) => {
  const match = useRouteMatch<{ discoverId: string }>();

  return (
    <Connected
      id={match ? match.params.discoverId : undefined}
      url={match ? match.url : undefined}
      {...props}
    />
  );
};

export default Wrapper;
