import { ThumbsUpIcon as DefaultThumbsUpIcon } from 'components/icons';
import { Stars, Thumb } from 'components/icons/ThumbsUp/styles';
import styled, { css } from 'styled-components';

interface ThumbsUpIconProps {
  $isVoted: boolean;
  $iconWidth: string;
}

export const ThumbsUpIcon = styled(DefaultThumbsUpIcon)<ThumbsUpIconProps>`
  width: ${({ $iconWidth }) => $iconWidth};

  // Optical alignment correction
  margin-bottom: 2%;
  margin-left: 5%;

  ${({ $isVoted, theme }) =>
    $isVoted &&
    css`
      ${Thumb} {
        color: ${theme.colors.primary};
      }

      ${Stars} {
        color: ${theme.colors.accent};
      }
    `}
`;
