import { Comment } from 'model';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { buildById, ById, upsertById } from 'utils/byId';
import { without } from 'utils/without';
import {
  addComments,
  createComment,
  deleteComment,
  editComment,
  toggleReplyFormOpen,
  _upsertCommentsById,
} from './actions';
import { CommentAction, CommentState } from './types';

export const INITIAL_STATE: CommentState = {
  byCommentId: {},
  byParentId: {},
  loading: false,
  replyFormsOpen: {},
};

const buildByCommentId = (
  comments: Comment[],
): ById<Omit<Comment, 'children'>> =>
  comments
    .map(({ children }) => buildByCommentId(children))
    .reduce(
      (acc, byId) => ({ ...acc, ...byId }),
      buildById(comments.map((comment) => without(comment, 'children'))),
    );

const buildByParentId = (
  comments: Comment[],
): { [id: string]: string[] | undefined } =>
  comments
    .map(({ children }) => buildByParentId(children))
    .reduce(
      (acc, el) => ({ ...acc, ...el }),
      comments.reduce((byParentId, comment) => {
        const parent = comment.parent ?? comment.objectId;

        return {
          ...byParentId,
          [parent]: [...(byParentId[parent] || []), comment.id],
        };
      }, {} as { [id: string]: string[] | undefined }),
    );

const reducer: Reducer<CommentState, CommentAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(addComments):
      const comments = action.payload;

      return {
        ...state,
        byCommentId: {
          ...state.byCommentId,
          ...buildByCommentId(comments),
        },
        byParentId: {
          ...state.byParentId,
          ...buildByParentId(comments),
        },
      };
    case getType(createComment.request):
      return {
        ...state,
        loading: true,
      };
    case getType(createComment.success): {
      const comment = action.payload;
      const parent = comment.parent ?? comment.objectId;

      return {
        ...state,
        byCommentId: {
          ...state.byCommentId,
          [comment.id]: without(comment, 'children'),
        },
        byParentId: {
          ...state.byParentId,
          [parent]: [...(state.byParentId[parent] || []), comment.id],
        },
        loading: false,
      };
    }
    case getType(createComment.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(deleteComment.request):
      return {
        ...state,
        loading: true,
      };
    case getType(deleteComment.success):
      return {
        ...state,
        byCommentId: {
          ...state.byCommentId,
          [action.payload.id]: undefined,
        },
        loading: false,
      };
    case getType(deleteComment.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(editComment.request):
      return {
        ...state,
        loading: true,
      };
    case getType(editComment.success):
      return {
        ...state,
        byCommentId: {
          ...state.byCommentId,
          [action.payload.id]: action.payload,
        },
        loading: false,
      };
    case getType(editComment.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(toggleReplyFormOpen):
      return {
        ...state,
        replyFormsOpen: {
          ...state.replyFormsOpen,
          [action.payload]: !state.replyFormsOpen[action.payload] || undefined,
        },
      };
    case getType(_upsertCommentsById):
      return {
        ...state,
        byCommentId: upsertById(
          {
            byId: state.byCommentId,
            bySlug: {},
          },
          // tslint:disable-next-line:no-any
          action.payload as any,
        ).byId,
      };
    default:
      return state;
  }
};

export default reducer;
