import styled from 'styled-components';
import { from } from 'styles/mixins';
import FeaturedVideoDefault from '../FeaturedVideo';
import Tag from '../Tag';

export const FeaturedVideo = styled(FeaturedVideoDefault)`
  height: 27rem;
`;

export const FeaturedVideoTitle = styled.h3`
  font-size: 1.275rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  margin-bottom: 4px;
  margin-top: 8px;
  line-height: normal;
  letter-spacing: normal;
  font-weight: ${({ theme }) => theme.weights.bold};

  ${from.tablet`
    font-size: 1.375rem;
    margin: 0;
  `}

  ${from.tabletH`
    font-size: 1.75rem;
    margin: 0.5rem 0;
  `}

  span {
    font-weight: ${({ theme }) => theme.weights.light};
    font-weight: 400;
    display: block;
    height: 1.3125rem;
    line-height: 1.5625rem;
    font-size: 1.15rem;

    ${from.tablet`
      box-shadow: -0.125rem 0 #ffffff;
      padding-left: 1.75rem;
      margin-left: 1.875rem;
      font-size: inherit;
      display: inline-block;
    `}
  }
`;

export const FeaturedVideoDescription = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.275rem;

  ${from.mobileXL`
    font-size: 1rem;
    line-height: 1.475rem;
  `}

  ${from.tablet`
    font-size: 1.25rem;
    line-height: 1.875rem;
    `}

  ${from.tabletH`
    width: 70%;
  `}
`;

export const FeaturedVideoClosed = styled(Tag)`
  width: fit-content;
`;
