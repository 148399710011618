import round from 'lodash/round';
import { createSelector } from 'reselect';
import { getPlanMonthlyPrice, Plan } from '../../model/Plan';
import { without } from '../../utils/without';
import { selectPlans } from './configuration';
import { selectPremiumState } from './premium';
import { Selector } from './types';

export const selectAnyLoading: Selector<boolean> = (state) =>
  state.auth.loading ||
  state.challenge.loading ||
  state.challengeResponse.loading ||
  state.comment.loading ||
  state.conversation.loading ||
  state.discover.loading ||
  state.exercise.loading ||
  state.exerciseResponse.loading ||
  state.feedback.loading ||
  state.settings.loading ||
  state.users.loading ||
  state.vote.loading;

export const selectPremiumYearlySavings = createSelector(
  selectPlans,
  (plans) => {
    if (plans.find((it) => it.interval) === null) {
      return 0;
    }
    const yearly = plans.find(
      (it) => it.interval === 'year' && !it.metadata.disabled,
    );
    const monthly = plans.find(
      (it) => it.interval === 'month' && !it.metadata.disabled,
    );

    if (!yearly || !monthly) {
      return 0;
    }

    return ((monthly.amount + 1) * 12 - (yearly.amount + 1)) / 100;
  },
);

export const selectPremiumLowestMonthlyPrice = createSelector(
  selectPlans,
  (plans) => {
    const plansFilter = plans.filter((p) => p.active && !p.metadata.disabled);
    const lowest = plansFilter.reduce((current, plan) => {
      if (
        !current ||
        getPlanMonthlyPrice(plan) < getPlanMonthlyPrice(current)
      ) {
        return plan;
      }

      return current;
    }, undefined as Plan | undefined);

    return lowest ? round(getPlanMonthlyPrice(lowest), 2) : '49';
  },
);

export const selectUserPremiumInfo = createSelector(
  selectPremiumState,
  selectPlans,
  ({ userInfo }, plans) => {
    if (!userInfo) {
      return undefined;
    }

    const plan = plans.find((plan) => plan.id === userInfo.planId);

    if (!plan && userInfo.planId === 'premium_lifetime') {
      const plan = plans.find(
        (plan) => `${plan.amount}` === `${userInfo.amount * 100}`,
      );
      return {
        ...without(userInfo, 'planId'),
        plan,
      };
    }

    if (!plan) {
      return undefined;
    }

    return {
      ...without(userInfo, 'planId'),
      plan,
    };
  },
);
