import { InternalAppContentType } from 'model';

const COMMON_ORDERING_OPTIONS = [
  {
    label: 'Date',
    value: 'created',
  },
];

export const getAvailableFilters = (contentType: InternalAppContentType) => {
  switch (contentType) {
    case 'challengeresponse':
    case 'exerciseresponse':
      return {
        advancedCriteria: {
          aboutExpire: false,
          author: false,
          isUnsolved: false,
          swdTeam: false,
        },
        filters: {
          conversationTopic: false,
          exerciseTags: false,
          graphIndustry: true,
          graphTools: true,
          graphTopic: true,
          graphTypes: true,
          solved: false,
        },
        order: [
          {
            label: 'Activity',
            value: 'activity',
          },
          ...COMMON_ORDERING_OPTIONS,
          {
            label: 'Comments',
            value: 'comments',
          },
          {
            label: 'Datapoints',
            value: 'datapoints',
          },
          {
            label: 'Views',
            value: 'views',
          },
        ],
      };
    case 'feedback':
      return {
        advancedCriteria: {
          aboutExpire: true,
          isUnsolved: true,
          swdTeam: true,
        },
        filters: {
          author: false,
          conversationTopic: false,
          exerciseTags: false,
          graphIndustry: true,
          graphTools: true,
          graphTopic: true,
          graphTypes: true,
          solved: false,
        },
        order: [
          ...COMMON_ORDERING_OPTIONS,
          {
            label: 'Comments',
            value: 'comments',
          },
          {
            label: 'Datapoints',
            value: 'datapoints',
          },
          {
            label: 'Views',
            value: 'views',
          },
          {
            label: 'Urgent',
            value: 'urgent',
          },
        ],
      };
    case 'conversation':
      return {
        advancedCriteria: {
          aboutExpire: false,
          isUnsolved: false,
          swdTeam: true,
        },
        filters: {
          author: true,
          conversationTopic: true,
          exerciseTags: false,
          graphIndustry: false,
          graphTools: false,
          graphTopic: false,
          graphTypes: false,
          solved: false,
        },
        order: [
          {
            label: 'Activity',
            value: 'activity',
          },
          ...COMMON_ORDERING_OPTIONS,
          {
            label: 'Comments',
            value: 'comments',
          },
          {
            label: 'Datapoints',
            value: 'datapoints',
          },
          {
            label: 'Views',
            value: 'views',
          },
        ],
      };
    case 'search':
      return {
        advancedCriteria: {
          aboutExpire: false,
          isUnsolved: false,
          swdTeam: false,
        },
        filters: {
          author: true,
          conversationTopic: true,
          exerciseTags: true,
          graphIndustry: true,
          graphTools: true,
          graphTopic: true,
          graphTypes: true,
          solved: false,
        },
        order: [],
      };
    case 'video':
      return {
        advancedCriteria: {
          aboutExpire: false,
          isUnsolved: false,
          swdTeam: false,
        },
        filters: {
          author: false,
          conversationTopic: false,
          exerciseTags: true,
          graphIndustry: false,
          graphTools: true,
          graphTopic: false,
          graphTypes: false,
          solved: false,
          videoSeries: true,
          videoPresenters: true,
        },
        order: [
          ...COMMON_ORDERING_OPTIONS,
          { label: 'Duration', value: 'duration' },
        ],
      };
    case 'event':
      return {
        advancedCriteria: {
          aboutExpire: false,
          isUnsolved: false,
          swdTeam: false,
        },
        filters: {
          author: false,
          conversationTopic: false,
          exerciseTags: false,
          graphIndustry: false,
          graphTools: false,
          graphTopic: false,
          graphTypes: false,
          solved: false,
        },
        order: [
          ...COMMON_ORDERING_OPTIONS,
          { label: 'Duration', value: 'duration' },
        ],
      };
    case 'challenge':
      return {
        advancedCriteria: {
          aboutExpire: false,
          isUnsolved: false,
          swdTeam: false,
        },
        filters: {
          author: false,
          conversationTopic: false,
          exerciseTags: false,
          graphIndustry: false,
          graphTools: false,
          graphTopic: false,
          graphTypes: false,
          solved: false,
        },
        order: [
          ...COMMON_ORDERING_OPTIONS,
          {
            label: 'Submissions',
            value: 'submissions',
          },
        ],
      };
    case 'exercise':
    default:
      return {
        advancedCriteria: {
          aboutExpire: false,
          isUnsolved: false,
          swdTeam: false,
        },
        filters: {
          author: false,
          conversationTopic: false,
          exerciseTags: true,
          graphIndustry: false,
          graphTools: false,
          graphTopic: false,
          graphTypes: false,
          solved: true,
        },
        order: [
          ...COMMON_ORDERING_OPTIONS,
          {
            label: 'Submissions',
            value: 'submissions',
          },
        ],
      };
  }
};
