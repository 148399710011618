import React, { FC, memo } from 'react';
import {
  Button,
  ComparativePrice,
  Container,
  DarkSubtitle,
  Link,
  Price,
  SmallSubtitle,
  SpecialOfferTag,
  Subtitle,
  Tag,
  Title,
} from './styles';
import { Props } from './types';

const Plan: FC<Props> = ({
  disabled,
  id,
  index,
  isCorporate,
  period,
  price,
  savings,
  subtitle,
  specialOffer = false,
  ...rest
}) => {
  const even = index % 2 === 0;

  const priceDiscount = price - 100;

  return (
    <Container {...rest} $disabled={disabled} $specialOffer={specialOffer}>
      <Link
        disabled={disabled}
        variant={even ? 'premiumAccent' : 'premiumSecondary'}
        to={{
          pathname: isCorporate ? '/subscribe/corporate' : '/subscribe',
          state: {
            selectedPlan: id,
          },
        }}
      >
        <Title>{period}</Title>
        {specialOffer ? <ComparativePrice>${price} US</ComparativePrice> : null}
        {specialOffer ? (
          <Price>${priceDiscount} US</Price>
        ) : (
          <Price>${price} US</Price>
        )}
        {specialOffer ? (
          <>
            <DarkSubtitle>first year</DarkSubtitle>
            <SmallSubtitle>${price} annually thereafter</SmallSubtitle>
          </>
        ) : (
          <Subtitle>{subtitle}</Subtitle>
        )}
        <Button>Go premium!</Button>
        {specialOffer ? (
          <SpecialOfferTag>Special offer!</SpecialOfferTag>
        ) : null}
        {savings && !specialOffer ? (
          <Tag>
            Save {`$${Math.round(savings)} ${isCorporate ? `per seat` : ''}`}
          </Tag>
        ) : null}
      </Link>
    </Container>
  );
};

export default memo(Plan);
