import { useMediaQuery } from '@material-ui/core';
import CTABottomAsset from 'assets/images/bannerWhite/CTALeft.svg';
import CTATopAsset from 'assets/images/bannerWhite/CTARight.svg';
import { ScrollBox } from 'components';
import SignUpFragment from 'containers/Common/SignUpFragment';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import connect from './connect';
import { handleClose } from './logic';
import {
  Column,
  Content,
  CTABottomImage,
  CTATopImage,
  Description,
  Modal,
  Title,
} from './styles';
import { Props } from './types';

const SignUpModal: FC<Props> = ({ closeModal, location, open, replace }) => {
  const isTablet = useMediaQuery(from.tablet.query);

  const onClose = useCallback(
    handleClose({
      closeModal,
      location,
      replace,
    }),
    [closeModal, location, replace],
  );

  return (
    <Modal fullScreen={!isTablet} open={open} onClose={onClose}>
      <ScrollBox scroll={true}>
        <Content>
          <Title>Want to participate?</Title>
          <Description>
            Join the SWD community! It’s fast and simple. Create your account or
            login below.
          </Description>
          <SignUpFragment onLeave={closeModal} />
        </Content>
      </ScrollBox>
      <Column>
        <CTATopImage alt="" aria-hidden="true" src={CTATopAsset} />
        <CTABottomImage alt="" aria-hidden="true" src={CTABottomAsset} />
      </Column>
    </Modal>
  );
};

export default connect(SignUpModal);
