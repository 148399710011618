import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { ACTIVITY_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { getActivityItems } from './actions';
import { ActivityAction, ActivityState } from './types';

export const INITIAL_STATE: ActivityState = {
  ...defaultStandardState,
};

const reducer: Reducer<ActivityState, ActivityAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getActivityItems.request),
      success: isActionOf(getActivityItems.success),
      failure: isActionOf(getActivityItems.failure),
    },
  },
  contractItem: (payload) => payload,
  initialState: INITIAL_STATE,
  perPage: ACTIVITY_PER_PAGE,
})(reducer);
