import styled from 'styled-components';

export const Box = styled.input`
  height: 1rem;
  min-width: 1rem;
  position: relative;
  width: 1rem;
  box-sizing: border-box;
  flex-shrink: 0;

  :before {
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    border: solid 1px ${({ theme }) => theme.colors.grey300};
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    box-sizing: inherit;
  }

  :active {
    :before {
      background-color: ${({ theme }) => theme.colors.grey100};
    }
  }

  :checked {
    :before {
      background-color: ${({ theme }) => theme.colors.accent};
    }
    :after {
      border: 1px solid ${({ theme }) => theme.colors.accent};
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E")
        center center no-repeat;
      content: '';
      color: white;
      background-size: 12px;
      align-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      border-radius: 2px;
      box-sizing: inherit;
      height: 100%;
    }
  }
`;
