import React, { FC, memo } from 'react';
import { useConnect } from './connect';
import { Container, Tab } from './styles';
import TabsDropdown from './TabsDropdown';
import { Props } from './types';

const VideoTabs: FC<Props> = ({ category }) => {
  const { items } = useConnect({ category });

  return (
    <>
      <TabsDropdown items={items} />
      <Container>
        {items.map(({ active, id, label, to }) => (
          <Tab $active={active} key={id} to={to}>
            <span>{label}</span>
          </Tab>
        ))}
      </Container>
    </>
  );
};

export default memo(VideoTabs);
