export enum Statuses {
  CLOSED = 'Closed',
  EXPIRES_SOON = 'Ending soon!',
  SOLVED = 'Solved',
  SWD_TEAM = 'SWD team chimed in',
  SOLVED_BY_TEAM = 'SWD solution',
  ALREADY_SUBMITTED = 'Challenge accepted!',
  SOLVED_BY_USER = 'Exercise solved!',
  AMA = 'AMA Session',
  MONTHLY_THREAD = 'Featured Conversation',
}
