import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { CONVERSATIONS_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import {
  createConversation,
  deleteConversation,
  editConversation,
  getConversation,
  getConversations,
  _upsertConversationsById,
} from './actions';
import { ConversationAction, ConversationState } from './types';

export const INITIAL_STATE: ConversationState = {
  ...defaultStandardState,
};

const reducer: Reducer<ConversationState, ConversationAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    create: {
      request: isActionOf(createConversation.request),
      success: isActionOf(createConversation.success),
      failure: isActionOf(createConversation.failure),
    },
    edit: {
      request: isActionOf(editConversation.request),
      success: isActionOf(editConversation.success),
      failure: isActionOf(editConversation.failure),
    },
    getMany: {
      request: isActionOf(getConversations.request),
      success: isActionOf(getConversations.success),
      failure: isActionOf(getConversations.failure),
    },
    getOne: {
      request: isActionOf(getConversation.request),
      success: isActionOf(getConversation.success),
      failure: isActionOf(getConversation.failure),
    },
    remove: {
      request: isActionOf(deleteConversation.request),
      success: isActionOf(deleteConversation.success),
      failure: isActionOf(deleteConversation.failure),
    },
    _upsertById: isActionOf(_upsertConversationsById),
  },
  contractItem: (payload) => payload,
  initialState: INITIAL_STATE,
  perPage: CONVERSATIONS_PER_PAGE,
})(reducer);
