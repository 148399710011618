import { FormErrorMap, FormValidator } from 'utils/forms';
import { ChangePasswordForm } from '.';

export const validateChangePasswordForm: FormValidator<ChangePasswordForm> = ({
  password,
  passwordConfirm,
}) => {
  const errors: FormErrorMap<ChangePasswordForm> = {};

  if (password && passwordConfirm && password !== passwordConfirm) {
    errors.passwordConfirm = 'Passwords do not match';
  }

  return errors;
};
