import Link from 'components/Link';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: none;
  flex-grow: 1;
  margin-top: 5rem;

  > :nth-child(2) {
    padding-left: 0;
  }

  > :last-child {
    padding-right: 0;
  }

  ${from.tablet`
    display: flex;
  `}
`;

export const Tab = styled(Link)<{
  $active: boolean;
}>`
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey200};
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-weight: ${({ theme }) => theme.weights.regular};
  padding: 0.55rem 0.4rem;
  text-align: center;
  text-decoration: none;

  span {
    align-items: center;
    margin-bottom: -2px;
    padding: 0.55rem 1rem;

    ${({ $active }) =>
      $active &&
      css`
        border-bottom: 2px solid ${({ theme }) => theme.colors.accent};
        color: ${({ theme }) => theme.colors.black};
        font-weight: ${({ theme }) => theme.weights.semiBold};
      `};
  }
`;
