import { replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import { BookEvent, LiveEvent } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Overwrite } from 'utility-types';
import { getEvent } from './actions';

export const getEventEpic: Epic = (actions$, _, { fullRequest }) =>
  actions$.pipe(
    filter(isActionOf(getEvent.request)),
    mergeMap(({ payload: { slug } }) =>
      fullRequest<
        Overwrite<
          LiveEvent,
          {
            booking: BookEvent[];
          }
        >
      >({
        path: `video/live_event/${slug}`,
      }).pipe(
        mergeMap(({ response: event }) => [
          getEvent.success({
            ...event,
            booking: event.booking[0],
          }),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getEvent.failure(err), replace('/404')];
          }
          return [getEvent.failure(err)];
        }),
      ),
    ),
  );

export default combineEpics(getEventEpic);
