import { RootState, Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { DiscoverState } from './types';

const selectState: Selector<DiscoverState> = ({ discover }) => discover;

export const selectDiscoverById = (state: RootState) => state.discover.byId;
export const selectDiscoverBySlug = (state: RootState) => state.discover.bySlug;

export const selectDiscoverImages = createSelector(
  selectState,
  ({ byId, ids }) =>
    ids
      .map((id) => byId[id])
      .filter((it): it is NonNullable<typeof it> => !!it),
);
