import IconRipple from 'components/IconRipple';
import React, { FC, memo } from 'react';
import { ThumbsUpIcon } from './styles';
import { Props } from './types';

const ThumbsUp: FC<Props> = ({
  disableRipple = false,
  isVoted,
  width = '1.5rem',
  ...rest
}) => (
  <IconRipple iconWidth={width} show={!disableRipple && isVoted} {...rest}>
    <ThumbsUpIcon $isVoted={isVoted} $iconWidth={width} />
  </IconRipple>
);

export default memo(ThumbsUp);
