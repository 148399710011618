import Play from 'components/icons/Play';
import React, { FC, memo } from 'react';
import {
  Container,
  Image,
  Info,
  Overlay,
  PlayButton,
  VideoLink,
  ZoomButton,
} from './styles';
import { Props } from './types';

const FeaturedVideo: FC<Props> = ({
  buttonTextZoom,
  image,
  to,
  id,
  isZoomEvent = false,
  isPremium = false,
  children,
  zoomEventClosed = false,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <VideoLink to={to} $zoomEventClosed={zoomEventClosed}>
        <Image src={image} alt="" aria-hidden="true" />
        <Overlay />
        <Info>{children}</Info>
        {isZoomEvent && !zoomEventClosed ? (
          isPremium ? (
            <ZoomButton to={to} target="_blank">
              {buttonTextZoom}
            </ZoomButton>
          ) : (
            <ZoomButton to="/premium">Go Premium</ZoomButton>
          )
        ) : (
          <PlayButton
            style={{ x: '-50%', y: '-50%' }}
            whileHover={{ scale: 1.05 }}
          >
            <Play />
          </PlayButton>
        )}
      </VideoLink>
    </Container>
  );
};

export default memo(FeaturedVideo);
