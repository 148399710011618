import { Coupon, Invoice, PremiumInfo } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

export const cancelSubscription = createAsyncAction(
  'premium/CANCEL_SUBSCRIPTION_REQUEST',
  'premium/CANCEL_SUBSCRIPTION_SUCCESS',
  'premium/CANCEL_SUBSCRIPTION_FAILURE',
)<{}, {}, Error>();

export const getUserInfo = createAsyncAction(
  'premium/GET_USER_INFO_REQUEST',
  'premium/GET_USER_INFO_SUCCESS',
  'premium/GET_USER_INFO_FAILURE',
)<
  { silent?: boolean },
  DeepReadonly<{
    invoices: Invoice[];
    info: PremiumInfo;
  }>,
  Error
>();

export const reactivateSubscription = createAsyncAction(
  'premium/REACTIVATE_SUBSCRIPTION_REQUEST',
  'premium/REACTIVATE_SUBSCRIPTION_SUCCESS',
  'premium/REACTIVATE_SUBSCRIPTION_FAILURE',
)<{}, {}, Error>();

export const subscribe = createAsyncAction(
  'premium/SUBSCRIBE_REQUEST',
  'premium/SUBSCRIBE_SUCCESS',
  'premium/SUBSCRIBE_FAILURE',
)<
  {
    quantity: number;
    paymentMethod: string;
    stripePlan: string;
    companyName?: string;
    coupon?: string;
  },
  {},
  Error
>();

export const subscriptionLifetime = createAsyncAction(
  'premium/SUBSCRIPTION_LIFETIME_REQUEST',
  'premium/SUBSCRIPTION_LIFETIME_SUCCESS',
  'premium/SUBSCRIPTION_LIFETIME_FAILURE',
)<
  {
    companyName?: string;
    quantity: number;
    paymentMethod: string;
    stripePlan: string;
  },
  {},
  Error
>();

export const addMembers = createAsyncAction(
  'premium/ADD_MEMBERS_REQUEST',
  'premium/ADD_MEMBERS_SUCCESS',
  'premium/ADD_MEMBERS_FAILURE',
)<
  {
    emails: string[];
  },
  {},
  Error
>();

export const deleteMember = createAsyncAction(
  'premium/DELETE_MEMBER_REQUEST',
  'premium/DELETE_MEMBER_SUCCESS',
  'premium/DELETE_MEMBER_FAILURE',
)<
  {
    memberId: string;
  },
  { memberId: string },
  Error
>();

export const resendInvite = createAsyncAction(
  'premium/RESEND_INVITE_MEMBER_REQUEST',
  'premium/RESEND_INVITE_MEMBER_SUCCESS',
  'premium/RESEND_INVITE_MEMBER_FAILURE',
)<
  {
    email: string;
  },
  {},
  Error
>();

export const updatePaymentMethod = createAsyncAction(
  'premium/UPDATE_PAYMENT_METHOD_REQUEST',
  'premium/UPDATE_PAYMENT_METHOD_SUCCESS',
  'premium/UPDATE_PAYMENT_METHOD_FAILURE',
)<
  {
    cardExpMonth: string;
    cardExpYear: string;
    cardKind: string;
    cardLast4: string;
    paymentMethod: string;
  },
  {
    cardExpMonth: string;
    cardExpYear: string;
    cardKind: string;
    cardLast4: string;
  },
  Error
>();

export const updatePlan = createAsyncAction(
  'premium/UPDATE_PLAN_REQUEST',
  'premium/UPDATE_PLAN_SUCCESS',
  'premium/UPDATE_PLAN_FAILURE',
)<
  {
    stripePlan: string;
    quantity: number;
    companyName: string;
  },
  { stripePlan: string; quantity: number; companyName: string },
  Error
>();

export const updatePlanInterval = createAsyncAction(
  'premium/UPDATE_PLAN_INTERVAL_REQUEST',
  'premium/UPDATE_PLAN_INTERVAL_SUCCESS',
  'premium/UPDATE_PLAN_INTERVAL_FAILURE',
)<
  {
    newPlan: string;
  },
  { newPlan: string },
  Error
>();

export const updateCompanyName = createAsyncAction(
  'premium/UPDATE_COMPANY_NAME_REQUEST',
  'premium/UPDATE_COMPANY_NAME_SUCCESS',
  'premium/UPDATE_COMPANY_NAME_FAILURE',
)<
  {
    companyName: string;
  },
  { companyName: string },
  Error
>();

export const addExtraSeats = createAsyncAction(
  'premium/ADD_EXTRA_SEATS_REQUEST',
  'premium/ADD_EXTRA_SEATS_SUCCESS',
  'premium/ADD_EXTRA_SEATS_FAILURE',
)<
  {
    totalUsers: number;
  },
  { totalUsers: number },
  Error
>();

export const getCouponInfo = createAsyncAction(
  'premium/GET_COUPON_INFO_REQUEST',
  'premium/GET_COUPON_INFO_SUCCESS',
  'premium/GET_COUPON_INFO_FAILURE',
)<{ slug: string }, Coupon, Error>();

export const cleanCouponInfo = createAction('premium/CLEAN_COUPON_INFO')<
  undefined
>();
