import React, { FC } from 'react';
import { Container, Icon } from './styles';
import { Props } from './types';

const ClearIndicator: FC<Props> = ({ className, innerProps }) => (
  <Container className={className} {...innerProps}>
    <Icon />
  </Container>
);

export default ClearIndicator;
