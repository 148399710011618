import { Activity, PaginatedResponse } from 'model';
import { parse } from 'query-string';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ACTIVITY_PER_PAGE } from 'utils/config';
import { getActivityItems } from './actions';

export const getActivityItemsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getActivityItems.request)),
    mergeMap(({ payload: { search, page } }) =>
      request<PaginatedResponse<Activity>>({
        path: 'activity',
        params: {
          ...parse(search),
          page,
          pageSize: ACTIVITY_PER_PAGE,
        },
      }).pipe(
        mergeMap((response) => [getActivityItems.success(response)]),
        catchError(() => [getActivityItems.failure()]),
      ),
    ),
  );

export default combineEpics(getActivityItemsEpic);
