import { connect } from 'react-redux';
import { closeModal, selectConfirmationModalData } from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  data: selectConfirmationModalData(state),
});

const mapDispatchToProps = {
  closeModal,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
