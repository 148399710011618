import { Props } from './types';

export const requestVideo = ({
  videoSlug,
  eventSlug,
  getVideo,
  getEvent,
}: {
  videoSlug: string | undefined;
  eventSlug: string | undefined;
  getVideo: Props['getVideo'];
  getEvent: Props['getEvent'];
}) => {
  if (videoSlug) {
    getVideo({ slug: videoSlug });
  }

  if (eventSlug) {
    getEvent({ slug: eventSlug });
  }
};
