import { getUserName } from 'model/User';
import React, { FC } from 'react';
import { getUserType } from 'utils/getUserType';
import Comment from './Comment';
import connect from './connect';
import { Container } from './styles';
import { Props } from './types';

const CommentList: FC<Props> = ({
  amaParticipants,
  amaAuthor,
  closed,
  comments,
  contentType,
  disabled,
  loggedIn,
  objectId,
  onCreateComment,
  onToggleReplyFormOpen,
  replyFormsOpen,
  url,
  currentUser,
}) => {
  return (
    <Container role="list">
      {comments.map((comment) => (
        <Comment
          amaParticipants={amaParticipants}
          amaAuthor={amaAuthor}
          authorAvatar={comment.user.avatar}
          authorId={comment.user.id}
          authorName={getUserName(comment.user)}
          authorType={getUserType(comment.user)}
          closed={!!closed}
          content={comment.comment}
          contentType={contentType}
          datapoints={comment.votesCount}
          date={comment.created}
          disabled={disabled}
          id={comment.id}
          image={comment.image}
          isReported={comment.isReported}
          isVoted={comment.isVoted}
          key={comment.id}
          loggedIn={loggedIn}
          objectId={objectId}
          onCreateComment={onCreateComment}
          onToggleReplyFormOpen={onToggleReplyFormOpen}
          replies={comment.children}
          replyFormsOpen={replyFormsOpen}
          currentUser={currentUser}
          url={url}
          isReply={false}
          isReplyReplied={
            !!comment.children &&
            comment.children
              .slice(
                comment.children.findIndex((it) => it.id === comment.id) + 1,
              )
              .filter((reply) => reply.user.id === amaAuthor).length > 0
          }
        />
      ))}
    </Container>
  );
};

export default connect(CommentList);
