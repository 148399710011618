import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => `1.5rem ${theme.wrapperPaddingH.mobile} 2rem`};

  ${from.mobileXL`
    padding: ${({ theme }) => `3rem ${theme.wrapperPaddingH.desktop} 4.275rem`};
  `};
`;

export const Content = styled.div<{ hasImage?: boolean }>`
  ${container};
  color: ${({ theme }) => theme.colors.white};
  ${({ hasImage }) =>
    hasImage &&
    css`
      display: flex;
      align-items: center;
    `}
  p {
    margin: 0;
  }
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  letter-spacing: 0;

  ${from.tablet`
    font-size: 2.5rem;
  `};
`;

export const Description = styled.div`
  font-size: 1rem;
  max-width: 43.75rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    font-size: 1.25rem;
    line-height: 1.5;
  `};
`;

export const Image = styled.img`
  flex: 0 0 25%;
  width: 35%;
`;

export const JumbotronInfo = styled.div`
  flex: 1 0 60%;
`;
