import styled from 'styled-components';
import { buttonReset } from 'styles/mixins';
import { SortAmountDownIcon as DefaultSortAmountDownIcon } from '../icons';

export const Container = styled.button`
  ${buttonReset};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.md};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;

  :disabled {
    color: ${({ theme }) => theme.colors.grey300};
    cursor: initial;
  }
`;

export const SortAmountDownIcon = styled(DefaultSortAmountDownIcon)`
  width: 1rem;
`;

export const Box = styled.input`
  display: none;

  :checked + ${SortAmountDownIcon} {
    transform: rotate(180deg) scaleX(-1);
  }
`;
