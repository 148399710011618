import styled, { css } from 'styled-components';

export const Container = styled.div<{ offsetWidth: number }>`
  display: grid;
  grid-template-columns: 38% 40% 22%;
  margin-top: auto;
  > :not(:first-child) {
    padding-left: 0.625rem;
    border-left: 1px solid ${({ theme }) => theme.colors.grey300};
  }
  > :first-child {
    padding-left: 0;
  }

  ${({ offsetWidth }) =>
    offsetWidth &&
    offsetWidth > 306 &&
    css`
      grid-template-columns: 33% 36% 31%;

      > :not(:first-child) {
        padding-left: 1.3125rem;
      }

      > :first-child {
        padding-left: 0.625rem;
      }
    `};
`;

export const Statistic = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StatisticValue = styled.span`
  font-size: 1.25rem;
  line-height: 1.71;
  letter-spacing: 0;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.regular};
  opacity: 0.8;
`;

export const StatisticTitle = styled.span`
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.57px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  opacity: 0.8;
`;
