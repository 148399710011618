import produce from 'immer';
import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import { composeReducers } from 'utils/composeReducers';
import { createLoadingReducer } from 'utils/createLoadingReducer';
import {
  addExtraSeats,
  cancelSubscription,
  cleanCouponInfo,
  deleteMember,
  getCouponInfo,
  getUserInfo,
  reactivateSubscription,
  subscribe,
  subscriptionLifetime,
  updateCompanyName,
  updatePaymentMethod,
  updatePlan,
  updatePlanInterval,
} from './actions';
import { PremiumAction, PremiumState } from './types';

export const INITIAL_STATE: PremiumState = {
  invoices: [],
  loading: false,
  userInfo: undefined,
  updatePaymentMethodLoading: false,
  updateQuantityLoading: false,
  updateChangePlan: false,
  getCouponInfoLoading: false,
  couponInfo: undefined,
};

const reducer: Reducer<PremiumState, PremiumAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(cancelSubscription.request):
      return {
        ...state,
        loading: true,
      };
    case getType(cancelSubscription.success):
      return {
        ...state,
        loading: false,
        userInfo:
          state.userInfo &&
          produce(state.userInfo, (next) => {
            next.cancelAtPeriodEnd = true;
          }),
      };
    case getType(cancelSubscription.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(getUserInfo.success):
      return {
        ...state,
        invoices: action.payload.invoices,
        userInfo: action.payload.info,
      };
    case getType(deleteMember.success):
      return produce(state, (next) => {
        if (next.userInfo?.groupSubscription?.users) {
          next.userInfo.groupSubscription.users = next.userInfo.groupSubscription.users.filter(
            (member) => {
              return member.id !== action.payload.memberId;
            },
          );
        }
      });
    case getType(reactivateSubscription.request):
      return {
        ...state,
        loading: true,
      };
    case getType(reactivateSubscription.success):
      return {
        ...state,
        loading: false,
      };
    case getType(updatePlan.request):
      return {
        ...state,
        updateChangePlan: true,
      };
    case getType(updatePlan.success):
      return {
        ...state,
        updateChangePlan: false,
      };
    case getType(updatePlanInterval.request):
      return {
        ...state,
        updateChangePlan: true,
      };
    case getType(updatePlanInterval.success):
      return {
        ...state,
        updateChangePlan: false,
      };
    case getType(updateCompanyName.success):
      return produce(state, (next) => {
        if (next.userInfo?.groupSubscription) {
          next.userInfo.groupSubscription.companyName =
            action.payload.companyName;
        }
      });
    case getType(addExtraSeats.request):
      return produce(state, (next) => {
        next.updateQuantityLoading = true;
      });
    case getType(addExtraSeats.success):
      return produce(state, (next) => {
        if (next.userInfo) {
          next.userInfo.quantity = action.payload.totalUsers;
        }
      });
    case getType(updatePaymentMethod.request):
      return {
        ...state,
        updatePaymentMethodLoading: true,
      };
    case getType(updatePaymentMethod.success):
      return produce(state, (next) => {
        next.updatePaymentMethodLoading = false;

        if (next.userInfo) {
          next.userInfo.cardExpMonth = action.payload.cardExpMonth;
          next.userInfo.cardExpYear = action.payload.cardExpYear;
          next.userInfo.cardKind = action.payload.cardKind;
          next.userInfo.cardLast4 = action.payload.cardLast4;
        }
      });
    case getType(updatePaymentMethod.failure):
      return {
        ...state,
        updatePaymentMethodLoading: false,
      };
    case getType(cleanCouponInfo):
      return {
        ...state,
        getCouponInfoLoading: false,
        couponInfo: undefined,
      };
    case getType(getCouponInfo.request):
      return {
        ...state,
        getCouponInfoLoading: true,
      };
    case getType(getCouponInfo.success):
      return produce(state, (next) => {
        next.getCouponInfoLoading = false;
        next.couponInfo = action.payload;
      });
    case getType(getCouponInfo.failure):
      return {
        ...state,
        getCouponInfoLoading: false,
      };
    default:
      return state;
  }
};

export default composeReducers(
  createLoadingReducer(
    isActionOf(getUserInfo.request),
    isActionOf(getUserInfo.success),
    isActionOf(getUserInfo.failure),
    INITIAL_STATE,
  ),
  createLoadingReducer(
    isActionOf(subscribe.request),
    isActionOf(subscribe.success),
    isActionOf(subscribe.failure),
    INITIAL_STATE,
  ),
  createLoadingReducer(
    isActionOf(subscriptionLifetime.request),
    isActionOf(subscriptionLifetime.success),
    isActionOf(subscriptionLifetime.failure),
    INITIAL_STATE,
  ),
  reducer,
);
