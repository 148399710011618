import React, { FC } from 'react';
import {
  formatDayOfWeek,
  formatHour,
  formatMonthNameAndDay,
} from 'utils/dates';
import {
  Avatar,
  BookedIcon,
  Bottom,
  CardButton,
  Container,
  Content,
  Date,
  Main,
  Name,
  Position,
  PresenterContainer,
  PresenterInfo,
  Time,
  Title,
  TitleContainer,
  Tooltip,
} from './styles';
import { Props } from './types';

const OfficeHour: FC<Props> = ({
  booked,
  disabled,
  endDate: end,
  startDate: start,
  presenters,
  ...rest
}) => (
  <Container disabled={disabled}>
    <CardButton {...rest} disabled={disabled}>
      <Main>
        <TitleContainer $booked={booked}>
          {booked && (
            <BookedIcon
              title="Booked"
              desc="You have booked this office hour"
            />
          )}
          <Title>{formatDayOfWeek(start)}</Title>
        </TitleContainer>
        <Content>
          <Date>{formatMonthNameAndDay(start)}</Date>
          <Time>
            {formatHour(start)} - {formatHour(end)}
          </Time>
        </Content>
      </Main>
      {presenters ? (
        <Bottom>
          {presenters.map((presenter) => (
            <PresenterContainer key={presenter.id}>
              <Avatar variant="swd" src={presenter.avatar} />
              <PresenterInfo>
                <Name>{presenter.name}</Name>
                <Position>SWD team</Position>
              </PresenterInfo>
            </PresenterContainer>
          ))}
        </Bottom>
      ) : null}
    </CardButton>
    <Tooltip>You can only sign up for one office hour at a time.</Tooltip>
  </Container>
);

export default OfficeHour;
