import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import {
  handleAddToCalendar,
  handleBook,
  handleCancelBook,
  handleClose,
  handleSaveReminder,
} from './logic';
import { Container, Content, OfficeHourBook, OfficeHourBooked } from './styles';
import { Props } from './types';

const Hour: FC<Props> = ({
  createBooking,
  deleteBooking,
  history: { push },
  hour,
  loading,
  match,
  updateBooking,
}) => {
  const hourId = hour?.id;
  const url = match?.url;

  const onAddToCalendar = useCallback(
    handleAddToCalendar({
      hourId,
      updateBooking,
    }),
    [hourId, updateBooking],
  );

  const onBook = useCallback(handleBook({ createBooking, hourId }), [
    createBooking,
    hourId,
  ]);

  const onCancel = useCallback(
    handleCancelBook({ deleteBooking, hourId: hour?.booking.id }),
    [deleteBooking, hourId],
  );

  const onClose = useCallback(handleClose({ push, url }), [push, url]);

  const onSaveReminder = useCallback(
    handleSaveReminder({
      hourId,
      onClose,
      updateBooking,
    }),
    [hourId, onClose, updateBooking],
  );

  return (
    <Container $booked={!!hour?.booked} open={!!hour} onClose={onClose}>
      <Content>
        <AnimatePresence>
          {hour?.booked ? (
            <OfficeHourBooked
              endDate={hour.endDatetime}
              loading={loading}
              notification={hour.notification}
              onAddToCalendar={onAddToCalendar}
              onClose={onClose}
              onSaveReminder={onSaveReminder}
              startDate={hour.startDatetime}
              presenters={hour.presenters}
              onCancel={onCancel}
            />
          ) : (
            <OfficeHourBook
              endDate={hour?.endDatetime}
              loading={loading}
              onBook={onBook}
              onClose={onClose}
              startDate={hour?.startDatetime}
            />
          )}
        </AnimatePresence>
      </Content>
    </Container>
  );
};

export default connect(Hour);
