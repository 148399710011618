import type { Command } from 'prosemirror-commands';
import type { NodeType } from 'prosemirror-model';

import type { Schema } from '../schema';

export const replaceSelectionWithEmptyNode = (
  node: NodeType<Schema>,
): Command<Schema> => (state, dispatch) => {
  const tr = state.tr;

  tr.replaceSelectionWith(node.create());
  tr.scrollIntoView();

  if (dispatch) {
    dispatch(tr);
  }

  return true;
};
