import { push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  FeedbackRequest,
  FeedbackRequestExpanded,
  FEEDBACK_FORM_KEY,
  PaginatedResponse,
} from 'model';
import { parse } from 'query-string';
import { destroy, stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { Epic, RootAction } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { FEEDBACK_PER_PAGE } from 'utils/config';
import { addComments } from '../comment';
import { showSnackbar } from '../snackbar';
import {
  createFeedbackRequest,
  deleteFeedbackRequest,
  editFeedbackRequest,
  getFeedbackRequest,
  getFeedbackRequests,
} from './actions';

export const createFeedbackRequestEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(createFeedbackRequest.request)),
    mergeMap(({ payload: body }) =>
      fullRequest<FeedbackRequestExpanded>({
        body,
        method: 'POST',
        path: 'feedback',
      }).pipe(
        mergeMap(({ response: feedback }) => [
          createFeedbackRequest.success(feedback),
          push(`/feedback/${feedback.id}`),
          destroy(FEEDBACK_FORM_KEY()),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              createFeedbackRequest.failure(),
              stopSubmit(FEEDBACK_FORM_KEY(), err.response),
            ];
          }

          return [createFeedbackRequest.failure()];
        }),
      ),
    ),
  );

export const deleteFeedbackRequestEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteFeedbackRequest.request)),
    mergeMap(({ payload: { id } }) =>
      request({
        method: 'DELETE',
        path: `feedback/${id}`,
      }).pipe(
        mergeMap(() => [
          deleteFeedbackRequest.success({ id }),
          showSnackbar({
            message: 'Your feedback request was deleted!',
          }),
          replace('/feedback'),
        ]),
        catchError(() => [deleteFeedbackRequest.failure()]),
      ),
    ),
  );

export const editFeedbackRequestEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editFeedbackRequest.request)),
    mergeMap(({ payload: { id, ...body } }) =>
      fullRequest<FeedbackRequestExpanded>({
        body,
        method: 'PUT',
        path: `feedback/${id}`,
      }).pipe(
        mergeMap(({ response: feedback }) => [
          editFeedbackRequest.success(feedback),
          showSnackbar({
            message: 'Feedback request updated!',
          }),
          push(`/feedback/${id}`),
          destroy(FEEDBACK_FORM_KEY(id)),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editFeedbackRequest.failure(),
              stopSubmit(FEEDBACK_FORM_KEY(id), err.response),
            ];
          }

          return [editFeedbackRequest.failure()];
        }),
      ),
    ),
  );

export const getFeedbackRequestEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getFeedbackRequest.request)),
    mergeMap(({ payload: { id } }) =>
      fullRequest<FeedbackRequestExpanded>({
        path: `feedback/${id}`,
      }).pipe(
        mergeMap(({ response: feedback }) => {
          const mainActions: RootAction[] = [
            getFeedbackRequest.success(feedback),
            addComments(feedback.comments),
          ];

          if (id === 'random') {
            mainActions.push(replace(`/feedback/${feedback.id}`));
          }

          return mainActions;
        }),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getFeedbackRequest.failure(), replace('/404')];
          }

          return [getFeedbackRequest.failure()];
        }),
      ),
    ),
  );

export const getFeedbackRequestsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getFeedbackRequests.request)),
    mergeMap(({ payload: { search } }) => {
      const query = search ? parse(search) : {};

      return request<PaginatedResponse<FeedbackRequest>>({
        path: 'feedback',
        params: {
          pageSize: FEEDBACK_PER_PAGE,
          ...query,
        },
      }).pipe(
        mergeMap((response) => [getFeedbackRequests.success(response)]),
        catchError(() => [getFeedbackRequests.failure()]),
      );
    }),
  );

export default combineEpics(
  createFeedbackRequestEpic,
  deleteFeedbackRequestEpic,
  editFeedbackRequestEpic,
  getFeedbackRequestEpic,
  getFeedbackRequestsEpic,
);
