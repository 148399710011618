import { push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  ExerciseResponse,
  EXERCISE_RESPONSE_FORM_KEY,
  isRedirect,
  PaginatedResponse,
} from 'model';
import { parse } from 'query-string';
import { destroy, stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { addComments } from 'redux/modules/comment';
import { createFeedbackRequest } from 'redux/modules/feedback';
import { showSnackbar } from 'redux/modules/snackbar';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EXERCISE_RESPONSES_PER_PAGE } from 'utils/config';
import { up } from 'utils/Paths';
import { _upsertExercisesById } from '../exercise';
import {
  createExerciseResponse,
  deleteExerciseResponse,
  editExerciseResponse,
  getExerciseResponse,
  getExerciseResponses,
} from './actions';

export const createExerciseResponseEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(createExerciseResponse.request)),
    mergeMap(({ payload: { exerciseId, ...body } }) =>
      fullRequest<ExerciseResponse>({
        body,
        method: 'POST',
        path: `exercises/${exerciseId}/responses`,
      }).pipe(
        mergeMap(({ response: exerciseResponse }) => [
          createExerciseResponse.success(exerciseResponse),
          push(
            `/exercises/${exerciseResponse.exerciseSlug}/${exerciseResponse.slug}`,
          ),
          destroy(EXERCISE_RESPONSE_FORM_KEY()),
          _upsertExercisesById([
            {
              id: exerciseId,
              submittedResponse: true,
            },
          ]),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              createExerciseResponse.failure(),
              stopSubmit(EXERCISE_RESPONSE_FORM_KEY(), err.response),
            ];
          }

          return [createFeedbackRequest.failure()];
        }),
      ),
    ),
  );

export const deleteExerciseResponseEpic: Epic = (
  action$,
  state$,
  { request },
) =>
  action$.pipe(
    filter(isActionOf(deleteExerciseResponse.request)),
    mergeMap(({ payload: { exerciseId, responseId } }) =>
      request({
        method: 'DELETE',
        path: `exercises/${exerciseId}/responses/${responseId}`,
      }).pipe(
        mergeMap(() => {
          const exerciseSlug = state$.value.exercise.byId[exerciseId]?.slug;

          return [
            deleteExerciseResponse.success({ id: exerciseId }),
            showSnackbar({
              message: 'Your submission was deleted!',
            }),
            replace(`/exercises/${exerciseSlug}`),
          ];
        }),
        catchError(() => [deleteExerciseResponse.failure()]),
      ),
    ),
  );

export const editExerciseResponseEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editExerciseResponse.request)),
    mergeMap(({ payload: { exerciseId, responseId, ...body } }) =>
      fullRequest<ExerciseResponse>({
        body,
        method: 'PUT',
        path: `exercises/${exerciseId}/responses/${responseId}`,
      }).pipe(
        mergeMap(({ response: exerciseResponse }) => [
          editExerciseResponse.success(exerciseResponse),
          showSnackbar({
            message: 'Exercise submission updated!',
          }),
          push(
            `/exercises/${exerciseResponse.exerciseSlug}/${exerciseResponse.slug}`,
          ),
          destroy(EXERCISE_RESPONSE_FORM_KEY(responseId)),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editExerciseResponse.failure(),
              stopSubmit(EXERCISE_RESPONSE_FORM_KEY(responseId), err.response),
            ];
          }

          return [editExerciseResponse.failure()];
        }),
      ),
    ),
  );

export const getExerciseResponseEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(getExerciseResponse.request)),
    mergeMap(({ payload: { exerciseSlug, exerciseResponseSlug } }) =>
      fullRequest<ExerciseResponse>({
        path: `exercises/${exerciseSlug}/responses/${exerciseResponseSlug}`,
      }).pipe(
        mergeMap(({ response }) => {
          if (isRedirect(response)) {
            // We think we have slugs, but we actually have ids
            const exerciseId = exerciseSlug;
            const exerciseResponseId = exerciseResponseSlug;
            const location = state$.value.router.location;

            if (
              location.pathname ===
              `/exercises/${exerciseId}/${exerciseResponseId}`
            ) {
              return [
                replace({
                  ...location,
                  pathname: `/exercises/${response.mappings[exerciseId]}/${response.mappings[exerciseResponseId]}`,
                }),
              ];
            }

            return [];
          }

          return [
            getExerciseResponse.success(response),
            addComments(response.comments),
          ];
        }),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.FORBIDDEN) {
            return [
              getExerciseResponse.failure(),
              replace({
                pathname: up(state$.value.router.location.pathname),
                hash: 'exercise-not-submitted',
              }),
            ];
          }

          return [getExerciseResponse.failure()];
        }),
      ),
    ),
  );

export const getExerciseResponsesEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getExerciseResponses.request)),
    mergeMap(({ payload: { exerciseSlug, search } }) => {
      const query = search ? parse(search) : {};

      return request<PaginatedResponse<ExerciseResponse>>({
        path: `exercises/${exerciseSlug}/responses`,
        params: {
          pageSize: EXERCISE_RESPONSES_PER_PAGE,
          ...query,
        },
      }).pipe(
        mergeMap((response) => {
          if (isRedirect(response)) {
            // If this is a redirect, just do nothing. The exercise detail page
            // will follow the redirect of the exercise request.
            return [];
          }

          return [getExerciseResponses.success(response)];
        }),
        catchError(() => [getExerciseResponses.failure()]),
      );
    }),
  );

export default combineEpics(
  createExerciseResponseEpic,
  deleteExerciseResponseEpic,
  editExerciseResponseEpic,
  getExerciseResponseEpic,
  getExerciseResponsesEpic,
);
