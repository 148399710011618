import SWDTeamOrPremiumBadge from 'components/SWDTeamOrPremiumBadge';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  padding: 0.375rem 0.75rem;
`;

export const SWDOverlayBadge = styled(SWDTeamOrPremiumBadge)`
  margin-right: 0.625rem;

  > * {
    position: static;
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const Label = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0.0275rem;
  text-transform: uppercase;
`;
