export const SEARCH_SECTIONS = [
  'blog',
  'books',
  'challenges',
  'conversations',
  'exercises',
  'feedback',
  'podcast',
  'visuals',
  'videos',
] as const;

export type SearchSection = typeof SEARCH_SECTIONS[number];
