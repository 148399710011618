import SectionMenu from 'components/SectionMenu';
import SectionMenuLink from 'components/SectionMenuLink';
import { SEARCH_SECTIONS } from 'model';
import React, { FC, memo } from 'react';
import { Props } from './types';

const SearchSections: FC<Props> = ({
  available = {},
  children,
  search,
  url,
  ...rest
}) => (
  <SectionMenu {...rest}>
    <SectionMenuLink
      disabled={false}
      exact={true}
      key="all"
      to={{
        search,
        pathname: url,
      }}
    >
      all
    </SectionMenuLink>
    {SEARCH_SECTIONS.map((section) => (
      <SectionMenuLink
        disabled={!available[section]}
        key={section}
        to={{
          search,
          pathname: `${url}/${section}`,
        }}
      >
        {section}
      </SectionMenuLink>
    ))}
  </SectionMenu>
);

export default memo(SearchSections);
