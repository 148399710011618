import { Settings } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { showSnackbar } from '../snackbar';
import { editSettings, getSettings } from './actions';

export const getSettingsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getSettings.request)),
    mergeMap(() =>
      request<Settings>({
        path: 'users/me/settings',
      }).pipe(
        mergeMap((settings) => [getSettings.success(settings)]),
        catchError(() => [getSettings.failure()]),
      ),
    ),
  );

export const editSettingsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(editSettings.request)),
    mergeMap(({ payload }) =>
      request<Settings>({
        body: payload,
        path: 'users/me/settings',
        method: 'PUT',
      }).pipe(
        mergeMap((settings) => [
          editSettings.success(settings),
          showSnackbar({
            message: 'Your settings have been updated successfully',
          }),
        ]),
        catchError(() => [editSettings.failure()]),
      ),
    ),
  );

export default combineEpics(getSettingsEpic, editSettingsEpic);
