import { Loader, Pagination } from 'components';
import React, { FC } from 'react';
import {
  ArrowLink,
  ExternalLink,
  Results,
  ResultsHeader,
  ResultsTitle,
} from '../../styles';
import connect from './connect';
import { BookContainer, BookCover } from './styles';
import { Props } from './types';

const Books: FC<Props> = ({
  available,
  count,
  isDetail,
  items,
  loading,
  location: { search },
  match: { url },
  page,
  pageCount,
  setPage,
  ...rest
}) => {
  if (!available && !loading) {
    return null;
  }

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>books {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: books"
            to={{
              search,
              pathname: `${url}/books`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BookContainer>
            {items.map(({ id, image, title, link }) => (
              <ExternalLink href={link} key={id} rel="noopener" target="_blank">
                <BookCover src={image} alt={title} />
              </ExternalLink>
            ))}
          </BookContainer>
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Books);
