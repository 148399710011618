import DefaultEventImage from 'assets/images/event/cole_presenting_graph_in_background.png';
import DefaultEventImageWebp from 'assets/images/event/cole_presenting_graph_in_background.webp';
import { getDateAndTime } from 'components/OfficeHourBook/logic';
import DOMPurify from 'dompurify';
import React, { FC, memo, useMemo } from 'react';

import { parseISO } from 'date-fns';
import {
  CardButton,
  CardDate,
  CardDescription,
  CardTitle,
  Container,
  Image,
  ImageContainer,
  TextContainer,
} from './styles';
import { Props } from './types';

const LiveEventListItem: FC<Props> = ({
  description,
  endDate,
  image,
  isPremium,
  pathname,
  slug,
  startDate,
  title,
  zoomRegisterLink,
}) => {
  const sanitizedDescription = useMemo(() => DOMPurify.sanitize(description), [
    description,
  ]);
  const dateAndTime = useMemo(() => {
    if (startDate && endDate) {
      return getDateAndTime({
        endDate: parseISO(endDate),
        startDate: parseISO(startDate),
      });
    }
  }, [endDate, startDate]);

  return (
    <Container>
      <ImageContainer>
        {image ? (
          <picture>
            <Image src={image} alt="" aria-hidden="true" />
          </picture>
        ) : (
          <picture>
            <source srcSet={DefaultEventImageWebp} type="image/webp" />
            <source srcSet={DefaultEventImage} type="image/png" />
            <Image src={DefaultEventImage} alt="" aria-hidden="true" />
          </picture>
        )}
      </ImageContainer>
      <TextContainer>
        <CardTitle>{title}</CardTitle>
        <CardDate>
          <strong>{dateAndTime?.date}</strong>|<span>{dateAndTime?.time}</span>
        </CardDate>
        <CardDescription>{sanitizedDescription}</CardDescription>
        {isPremium ? (
          <CardButton
            variant="link"
            to={
              zoomRegisterLink ?? {
                pathname: `${pathname}/register/more-info/${slug}`,
                state: {
                  scrollToTop: false,
                },
              }
            }
          >
            Register now
          </CardButton>
        ) : (
          <CardButton variant="link" to="/premium">
            Go Premium
          </CardButton>
        )}
      </TextContainer>
    </Container>
  );
};

export default memo(LiveEventListItem);
