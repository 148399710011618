import {
  Button,
  FullScreenContainer,
  SWDLogo as DefaultSWDLogo,
} from 'components';
import DefaultFeature from 'components/AvailablePremiumSubscriptionsCard/Feature';
import { TimesIcon } from 'components/icons';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { buttonReset, container, from } from 'styles/mixins';

export const Container = styled(FullScreenContainer)`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  ${container};
  display: flex;
  flex-direction: column;
  padding: 0.525rem 1rem;
  align-items: center;
  flex: 1;
`;

export const CommunityTitle = styled(DefaultLink)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const SWDLogo = styled(DefaultSWDLogo)`
  width: 10.1875rem;
`;

export const CloseIcon = styled(TimesIcon)`
  color: ${({ theme }) => theme.colors.primary};
  height: 1.8125rem;
  width: 1.25rem;
`;

export const GoBackButton = styled(Button)`
  ${buttonReset};
  :hover:not(:disabled) {
    background-color: transparent;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.darkPrimary};
  }
  margin-left: auto;
  padding: ${({ theme }) => theme.spacing.sm};
`;

export const Title = styled.h2`
  margin: 0 0 1.25rem;
  font-size: 1.75rem;
  line-height: 2.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

export const Paragraph = styled.p`
  max-width: 58.125rem;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
`;

export const Info = styled.div`
  box-sizing: border-box;
  padding: 2rem 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: solid 1px ${({ theme }) => theme.colors.greyBlue};
  ${from.tablet`
    padding: 2rem 5.8rem;
  `}
`;

export const Description = styled.p`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: 1.5rem;
  margin-top: 0;

  ${from.tablet`
    text-align: center;
  `}
`;

export const Features = styled.ul`
  list-style: none;
  width: fit-content;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.75rem;
  grid-row-gap: 0.5rem;
  ${from.tablet`
    margin: 0 auto;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 1fr 1fr;
  `}
  ${from.laptop`
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr 1fr;
  `}
`;

export const Feature = styled(DefaultFeature)``;
