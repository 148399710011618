import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 13 13" {...props}>
    {children}
    <path d="M11.828 6.682H1.668c-.395 0-.716-.301-.716-.673 0-.372.32-.674.716-.674h10.16c.395 0 .715.302.715.674 0 .372-.32.673-.715.673zm0-.667c.004 0 .007-.003.007-.006 0-.004-.003-.007-.007-.007H1.668c-.004 0-.007.003-.007.007 0 .003.003.006.007.006h10.16z" />
    <path d="M2.68 6.009l4.235 3.986c.28.263.28.69 0 .952a.747.747 0 01-1.012 0L1.162 6.485a.646.646 0 010-.952L5.903 1.07a.747.747 0 011.012 0c.28.263.28.69 0 .952L2.68 6.01zm3.734 4.457L1.678 6.01 6.414 1.55a.006.006 0 000-.01.007.007 0 00-.01 0L1.663 6.004a.006.006 0 000 .01l4.741 4.462a.007.007 0 00.01 0 .006.006 0 000-.01z" />
  </svg>
));
