import DefaultButton from 'components/Button';
import DefaultSearchIcon from 'components/icons/Search';
import styled from 'styled-components';

export const Button = styled(DefaultButton)`
  align-items: flex-end;
  padding: 0.563rem 0.875rem;
`;

export const SearchLabel = styled.span`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const SearchIcon = styled(DefaultSearchIcon)`
  margin-left: ${({ theme }) => theme.spacing.sm};
  width: 14px;
`;
