import { Navigation as DefaultNavigation } from 'components';
import DefaultComments from 'containers/Common/Comments';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};
  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Navigation = styled(DefaultNavigation)`
  > :first-child {
    padding: 1rem 0;
  }
`;

export const PostWrapper = styled.div`
  ${container};
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 1.875rem;
`;

export const Section = styled.div`
  width: 100%;

  ${from.mobileXL`
    width: 47%;
  `};
`;

export const Comments = styled(DefaultComments)`
  margin: 0 auto;
  padding-top: 3.75rem;
`;
