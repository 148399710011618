import { WrappedFieldProps } from 'redux-form';
import { ConnectedProps } from './connect';

export interface OwnProps {}

export interface Props extends ConnectedProps, OwnProps {}

export interface InputRadioProps extends WrappedFieldProps {
  id: string;
}

export enum ActivityOrderEnum {
  OVERALL_ACTIVITY = 'overall-activity',
  CHALLENGE_STREAK = 'challenge-streak',
  COMMENTS = 'comments',
  UPLOADS = 'uploads',
  DATAPOINTS = 'datapoints',
}

export type ActivityOrderOption = {
  value: ActivityOrderEnum;
  label: string;
};

export enum MembersOrderEnum {
  JOIN = 'join',
  ALPHABETICAL = 'alphabetical',
}

export type MembersOrderOption = {
  value: MembersOrderEnum;
  label: string;
};
