import IconRipple from 'components/IconRipple';
import React, { FC, memo } from 'react';
import { ReportIcon } from './styles';
import { Props } from './types';

const Report: FC<Props> = ({
  disableRipple = false,
  isReported,
  width = '1.5rem',
  ...rest
}) => (
  <IconRipple iconWidth={width} show={!disableRipple && isReported} {...rest}>
    <ReportIcon $iconWidth={width} $isReported={isReported} />
  </IconRipple>
);

export default memo(Report);
