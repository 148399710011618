import { SearchOptionsForm } from 'model';
import { parse, stringify } from 'query-string';
import { keysOf } from 'utils/keysOf';
import { getAvailableFilters } from './Form/logic';
import { Props } from './types';

export const handleFiltersCleared = ({
  hash,
  pathname,
  replace,
  search,
}: {
  hash: Props['hash'];
  pathname: Props['pathname'];
  replace: Props['replace'];
  search: Props['search'];
}) => () => {
  replace({
    hash,
    pathname,
    search: stringify({
      ...parse(search),
      aboutExpire: undefined,
      author: undefined,
      conversationTopic: undefined,
      exerciseTags: undefined,
      graphIndustry: undefined,
      graphTools: undefined,
      graphTopic: undefined,
      graphTypes: undefined,
      isUnsolved: undefined,
      solved: undefined,
      swdTeam: undefined,
      videoSeries: undefined,
      videoPresenters: undefined,
    }),
  });
};

export const handleFormSubmitted = ({
  contentType,
  hash,
  pathname,
  replace,
  search,
  state = {},
}: {
  contentType: Props['contentType'];
  hash: Props['hash'];
  pathname: Props['pathname'];
  replace: Props['replace'];
  search: Props['search'];
  state: Props['state'];
}) => ({ directionIsAsc, ...values }: SearchOptionsForm) => {
  const { order: availableOrder } = getAvailableFilters(contentType);

  const params = {
    ...parse(search),
    ...values,
  };

  params.order = `${directionIsAsc ? '' : '-'}${params.order}`;

  replace({
    hash,
    pathname,
    search: stringify(
      keysOf(params).reduce(
        (acc, key) => ({
          ...acc,
          [key]: (() => {
            // If the order is the default value, remove it
            if (
              key === 'order' &&
              params[key] === `-${availableOrder[0]?.value}`
            ) {
              return undefined;
            }

            // If the field is falsy, remove it
            return params[key] || undefined;
          })(),
          // If the search filters changed, go back to the first page
          page: undefined,
        }),
        {},
      ),
    ),
    state: {
      ...state,
      scrollToTop: false,
    },
  });
};
