import { AppContentType } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const addVote = createAsyncAction(
  'vote/ADD_VOTE_REQUEST',
  'vote/ADD_VOTE_SUCCESS',
  'vote/ADD_VOTE_FAILURE',
)<
  {
    contentType: AppContentType;
    objectId: string;
  },
  undefined,
  undefined
>();

export const removeVote = createAsyncAction(
  'vote/REMOVE_VOTE_REQUEST',
  'vote/REMOVE_VOTE_SUCCESS',
  'vote/REMOVE_VOTE_FAILURE',
)<
  {
    contentType: AppContentType;
    objectId: string;
  },
  undefined,
  undefined
>();
