import { FormNameMap } from 'utils/forms';

export const SETTINGS_FORM_KEY = 'forms/Settings';
export interface SettingsForm {
  newBlog: boolean;
  newPodcast: boolean;
  newWorkshop: boolean;
  newChallenge: boolean;
  newExercise: boolean;
  newCommentsMyConversation: boolean;
  newCommentsMyFeedback: boolean;
  newCommentsMyChallengeResponse: boolean;
  newCommentsMyExerciseResponse: boolean;
  newCommentsOnMyComment: boolean;
  newMention: boolean;
}

export const SettingsFormNames: FormNameMap<SettingsForm> = {
  newBlog: 'newBlog',
  newPodcast: 'newPodcast',
  newWorkshop: 'newWorkshop',
  newChallenge: 'newChallenge',
  newExercise: 'newExercise',
  newCommentsMyConversation: 'newCommentsMyConversation',
  newCommentsMyFeedback: 'newCommentsMyFeedback',
  newCommentsMyChallengeResponse: 'newCommentsMyChallengeResponse',
  newCommentsMyExerciseResponse: 'newCommentsMyExerciseResponse',
  newCommentsOnMyComment: 'newCommentsOnMyComment',
  newMention: 'newMention',
};
