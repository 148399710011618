import React, { FC, memo, useCallback } from 'react';
import { handleBeginCommentIntent } from './logic';
import { Button } from './styles';
import { Props } from './types';

const NewCommentButton: FC<Props> = ({
  beginIntent,
  disabled,
  loggedIn,
  url,
}) => {
  const onBeginCommentIntent = useCallback(
    handleBeginCommentIntent({ beginIntent, url }),
    [beginIntent, url],
  );

  if (disabled) {
    return null;
  }

  return loggedIn ? (
    <Button to="#new-comment">Leave a comment</Button>
  ) : (
    <Button onClick={onBeginCommentIntent}>Leave a comment</Button>
  );
};

export default memo(NewCommentButton);
