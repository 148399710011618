import LiveTag from 'components/LiveTag';
import { Closed } from 'components/Tag';
import { Statuses as StatusCodes } from 'model';
import React, { FC, memo } from 'react';
import { Ama, Container, Status } from './styles';
import { Props } from './types';

const Statuses: FC<Props> = ({
  isAma,
  live,
  closed,
  authorIsSwdTeam,
  amaEndDate,
  statuses,
}) => {
  let mappedStatuses = statuses
    .map((status, index) => {
      if (status === StatusCodes.SWD_TEAM && !isAma && !authorIsSwdTeam) {
        return <Status key={status} name={status} />;
      }
      if (status === StatusCodes.CLOSED) {
        return <Closed key={index} />;
      }
      if (status === StatusCodes.AMA) {
        return <Ama key={status} name={status} />;
      }
      if (status === StatusCodes.MONTHLY_THREAD) {
        return <Status key={status} name={status} />;
      }
      return null;
    })
    .filter(Boolean);

  if (isAma) {
    if (live && !closed && amaEndDate) {
      mappedStatuses = [<LiveTag key={'ama-live-tag'} />, ...mappedStatuses];
    }

    if (closed) {
      mappedStatuses = [...mappedStatuses, <Closed key={'ama-live-closed'} />];
    }
  }

  if (!mappedStatuses.length) return null;

  return <Container>{mappedStatuses}</Container>;
};

export default memo(Statuses);
