import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Border, MagnifyingGlass, PlusIcon } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="none"
    fillRule="evenodd"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.2"
    viewBox="0 0 26 26"
    {...props}
  >
    {children}
    <PlusIcon d="M15.25 13.25v4M17.25 15.25h-4" />
    <Border d="M1.5 4.566V2.50000015c0-.552285.447715-1 1-1h2.067M4.567 24.5H2.49999996c-.552285 0-1-.447715-1-1v-2.067M1.5 8.4v3.07M1.5 14.53v3.07M24.5 8.4v3.07M8.4 1.5h3.07M14.53 1.5h3.07M21.433 1.5H23.5c.552285 0 1 .447715 1 1v2.066M11.47 24.5H8.4" />
    <MagnifyingGlass d="M19.4696875 10.7963175c2.39509 2.39509 2.39509 6.27829 0 8.67337-2.39509 2.39509-6.27829 2.39509-8.67337 0-2.39509-2.39509-2.39509-6.27829 0-8.67337 2.39509-2.39509 6.27829-2.39509 8.67337 0M19.64 19.64l4.86 4.86" />
  </svg>
));
