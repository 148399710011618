import React, { FC } from 'react';
import Content from './Content';
import { Container, DropdownHeader } from './styles';
import { Props } from './types';

const AddToCalendar: FC<Props> = ({ start, end, name, onAdd, ...rest }) => (
  <Container
    header={<DropdownHeader>Add to my calendar</DropdownHeader>}
    {...rest}
  >
    {({ closeDropdown }) => (
      <Content
        start={start}
        end={end}
        closeDropdown={closeDropdown}
        name={name}
        onAdd={onAdd}
      />
    )}
  </Container>
);

export default AddToCalendar;
