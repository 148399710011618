const suffix = ['th', 'st', 'nd', 'rd'];

export const toOrdinal = (position: number) => {
  if (position === 0) {
    return {
      ordinal: '0',
      suffix: undefined,
    };
  }

  const remainder = position % 100;
  const ordinalSuffix =
    suffix[(remainder - 20) % 10] || suffix[remainder] || suffix[0];
  return {
    ordinal: `${position}${ordinalSuffix}`,
    suffix: ordinalSuffix,
  };
};
