import { Settings } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const getSettings = createAsyncAction(
  'settings/GET_SETTINGS_REQUEST',
  'settings/GET_SETTINGS_SUCCESS',
  'settings/GET_SETTINGS_FAILURE',
)<undefined, Settings, undefined>();

export const editSettings = createAsyncAction(
  'settings/PUT_SETTINGS_REQUEST',
  'settings/PUT_SETTINGS_SUCCESS',
  'settings/PUT_SETTINGS_FAILURE',
)<Partial<Settings>, Settings, undefined>();
