import { LocationInput, Textarea } from 'components';
import { EditProfileFormNames } from 'model';
import React, { FC, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Field } from 'redux-form';
import SectionFooter from '../SectionFooter';
import connect from './connect';
import {
  AvatarInput,
  Container,
  Content,
  Info,
  Inputs,
  Title,
  TitleSection,
} from './styles';
import { Props } from './types';

const BasicInfo: FC<Props> = ({ handleSubmit, loading, valid }) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const [avatarLoading, setAvatarLoading] = useState(false);

  return (
    <Container onSubmit={handleSubmit}>
      <TitleSection>
        <Title>Basic info</Title>
        <Info>This info will be shown in your public profile</Info>
      </TitleSection>
      <Content>
        <Field
          component={AvatarInput}
          name={EditProfileFormNames.avatar}
          onChangeLoading={setAvatarLoading}
        />
        <Inputs>
          <Field
            component={LocationInput}
            label="LOCATION"
            name={EditProfileFormNames.location}
            placeholder="The city where you live"
            type="text"
          />
          <Field
            component={Textarea}
            name={EditProfileFormNames.bio}
            label="TELL THE COMMUNITY A BIT ABOUT YOURSELF"
            placeholder="Brief description for your profile"
            rows="8"
          />
          <Field
            component={Textarea}
            name={EditProfileFormNames.goals}
            label="What are your current goals related to communicating data?"
            placeholder="Share your goals with us: what skills are you interested in developing, or what input would be helpful from the community?"
            rows="8"
          />
        </Inputs>
      </Content>
      <SectionFooter
        baseUrl={url}
        canSubmit={!(loading || avatarLoading) && valid}
        search={search}
        section="basic-info"
      />
    </Container>
  );
};

export default connect(BasicInfo);
