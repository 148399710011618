import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  margin-top: 2rem;
`;

export const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    grid-template-columns: repeat(4, 1fr);

    > *:first-child {
      grid-column: 1/3;
    }

    > *:last-child {
      grid-column: 3/-1;
    }
  `};

  ${from.laptop`
    > *:first-child {
      grid-column: 1/4;
    }

    > *:last-child {
      grid-column: 4/-1;
    }
  `};
`;
