import flatMap from 'lodash/flatMap';
import { RefObject } from 'react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { group } from 'utils/group';
import { reorderMultidimensional } from 'utils/reorderMultidimensional';
import { useElementDimensions } from 'utils/useElementDimensions';
import { Props } from './types';

export const handleDragEnd = ({
  files,
  filesPerRow,
  onChange,
}: {
  files: Props['files'];
  filesPerRow: number;
  onChange: Props['onChange'];
}) => (result: DropResult, _: ResponderProvided) => {
  if (!result.destination) {
    return;
  }

  const grid = [[files[0]], ...group(files.slice(1), filesPerRow)];

  if (grid.length === 1) {
    return;
  }

  const ids = grid.map((_, i) =>
    i === 0 ? 'main-image' : `other-images-${i - 1}`,
  );

  const nextGrid = reorderMultidimensional(
    grid,
    ids.indexOf(result.source.droppableId),
    result.source.index,
    ids.indexOf(result.destination.droppableId),
    result.destination.droppableId === 'main-image'
      ? 0
      : result.destination.index,
  );

  onChange(flatMap(nextGrid));
};

export const useFilesPerRow = (ref: RefObject<HTMLDivElement | null>) => {
  const { offsetWidth } = useElementDimensions(ref);

  if (offsetWidth > 928) {
    return 9;
  }

  if (offsetWidth > 824) {
    return 8;
  }

  if (offsetWidth > 720) {
    return 7;
  }

  if (offsetWidth > 616) {
    return 6;
  }

  if (offsetWidth > 512) {
    return 5;
  }

  if (offsetWidth > 408) {
    return 4;
  }

  if (offsetWidth > 304) {
    return 3;
  }

  return 2;
};
