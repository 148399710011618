import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  selectAuthLoading,
  selectUserIsPremium,
  selectUserIsPremiumGroupMember,
} from 'redux/modules/auth';
import { selectPremiumLowestMonthlyPrice } from 'redux/modules/premium';

export const useConnect = () => {
  const { url } = useRouteMatch();

  const isPremium = useSelector(selectUserIsPremium);
  const loading = useSelector(selectAuthLoading);
  const lowestPrice = useSelector(selectPremiumLowestMonthlyPrice);
  const isPremiumGroupMember = useSelector(selectUserIsPremiumGroupMember);

  return {
    isPremium,
    isPremiumGroupMember,
    loading,
    lowestPrice,
    url,
  };
};
