import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
  changePassword,
  editProfile,
  selectUserIsForcedPremium,
  selectUserIsPremium,
  selectUserIsPremiumGroupMember,
  selectUserIsPremiumGroupOwner,
} from 'redux/modules/auth';
import { editSettings } from 'redux/modules/settings';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { userId },
    path,
    url,
  } = useRouteMatch<{ userId: string }>();

  const isPremium = useSelector(selectUserIsPremium);
  const isForcedPremium = useSelector(selectUserIsForcedPremium);
  const isPremiumGroupMember = useSelector(selectUserIsPremiumGroupMember);
  const isPremiumGroupOwner = useSelector(selectUserIsPremiumGroupOwner);

  const actions = useActions({
    editSettings: editSettings.request,
    changePassword: changePassword.request,
    editProfile: editProfile.request,
  });

  return {
    ...actions,
    isPremium,
    isForcedPremium,
    isPremiumGroupMember,
    isPremiumGroupOwner,
    path,
    url,
    userId,
  };
};
