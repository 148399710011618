import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => {
  const challengesLoading = state.search.loading.challenges;
  const challengeResponsesLoading = state.search.loading.challengeResponse;

  return {
    challengesLoading,
    challengeResponsesLoading,
    available: state.search.available.challenges,
    challengeCount: state.search.count.challenges,
    challengeResponseCount: state.search.count.challengeResponse,
    loading: challengesLoading || challengeResponsesLoading,
    showChallengeResponses:
      state.search.ids.challengeResponse.length > 0 ||
      challengeResponsesLoading,
    showChallenges: state.search.ids.challenges.length > 0 || challengesLoading,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
