import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';

interface SectionProps {
  isGroupCode: boolean;
  isSWDInfo: boolean;
}

export const Container = styled.div`
  ${container};
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  padding: 2rem 0;

  ${from.tablet`
    padding: 5rem 0;
  `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0 1rem 1rem;
  text-align: center;

  ${from.tablet`
    font-size: 2.5rem;
  `};
`;

export const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 1rem 0;
  text-align: center;

  ${from.tablet`
    font-size: 1.25rem;
    margin: 0;
  `};
`;

export const Section = styled.div<SectionProps>`
  margin-top: 1.7rem;
  width: 100%;
  max-width: 50rem;
  box-sizing: border-box;

  ${from.tabletH<SectionProps>`
    max-width: 920px;
    margin-top: 2.7rem;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.colors.greyBlue};
    box-shadow: -13px 13px 30px -23px ${({ theme }) => theme.colors.grey500};
    
    ${({ isGroupCode }) =>
      isGroupCode &&
      css`
        width: 532px;
      `};
    
    ${({ isSWDInfo }) =>
      isSWDInfo &&
      css`
        width: 800px;
      `};
    
  `};
`;

export const TitleSection = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey100};
  display: flex;
  padding: 0 1.875rem 1rem;
  flex-direction: column;

  ${from.tablet`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem 3.75rem;
 `};
`;
