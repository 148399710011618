import type { EditorView } from 'prosemirror-view';
import { RefObject, useEffect } from 'react';
import { SetPlaceholderStep } from './Placeholder/actions';
import { SetReferenceProviderStep } from './references/providerHolder/actions';
import { ReferenceProvider } from './references/types';

export function useSynchronizePluginState({
  editorViewRef,
  placeholder,
  referenceProvider,
}: {
  editorViewRef: RefObject<EditorView | null>;
  placeholder: string | undefined;
  referenceProvider: ReferenceProvider | undefined;
}) {
  useEffect(() => {
    if (editorViewRef.current) {
      const tr = editorViewRef.current.state.tr;

      if (placeholder) {
        tr.step(new SetPlaceholderStep(placeholder));
      }

      if (referenceProvider) {
        tr.step(new SetReferenceProviderStep(referenceProvider));
      }

      editorViewRef.current.dispatch(tr);
    }
  }, [editorViewRef, placeholder, referenceProvider]);
}
