import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';
import { getUsers, selectUsers } from 'redux/modules/users';

const mapStateToProps = (state: RootState) => ({
  loading: state.users.loading,
  count: state.users.count,
  members: selectUsers(state),
});

const mapDispatchToProps = {
  getMembers: getUsers.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
