import BaseInput from 'components/BaseInput';
import MediaSelector from 'components/MediaSelector';
import { Media } from 'components/MediaSelector/types';
import React, { FC } from 'react';
import { Props } from './types';

const MediaInput: FC<Props> = ({
  className,
  input: { onChange, name, value },
  label,
  onlyImage = false,
  meta,
  ...rest
}) => {
  if (onlyImage) {
    return (
      <BaseInput className={className} label={label} name={name} meta={meta}>
        <MediaSelector
          onChange={onChange}
          value={value ?? []}
          accept={['IMAGE']}
          {...rest}
          id={name}
        />
      </BaseInput>
    );
  }
  return (
    <BaseInput className={className} label={label} name={name} meta={meta}>
      <MediaSelector
        onChange={onChange}
        value={value ?? []}
        {...rest}
        id={name}
      />
    </BaseInput>
  );
};

export default MediaInput;

export * from './utils';
export type { Media };
