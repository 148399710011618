import { Challenge } from '.';
import { userMock } from '../User';

export const challengeMock: Challenge = {
  slug: 'test-challenge',
  context: 'Hello world',
  created: '2019-09-02T14:00:00.000Z',
  deadline: '2019-09-02T14:00:00.000Z',
  description: 'Hello world',
  id: 'c6e76a4b-d160-4373-8df4-49a8dc19a5ed',
  image: 'https://placekitten.com/720/460',
  modified: '2019-09-02T14:00:00.000Z',
  name: 'Test challenge',
  latestSubmissions: [],
  relatedResources: 'Hello world',
  resource: 'https://placekitten.com/720/460',
  submissionCount: 1,
  submittedResponse: true,
  theChallenge: 'Hello world',
  user: userMock,
  swdTeam: false,
  swdSolution: false,
};
