import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { SetPlaceholderStep } from './actions';
import { State } from './types';

const pluginKey = new PluginKey<State>('placeholder');

const INITIAL_STATE: State = {
  placeholder: '',
};

export function placeholderPlugin() {
  return new Plugin<State>({
    key: pluginKey,
    props: {
      decorations: (state) => {
        const { doc } = state;

        const empty =
          doc.textContent === '' &&
          doc.childCount <= 1 &&
          doc.content.size <= 2;

        if (!empty) {
          return DecorationSet.empty;
        }

        return DecorationSet.create(doc, [
          Decoration.node(0, doc.firstChild?.nodeSize ?? 0, {
            class: 'placeholder',
            'data-value': pluginKey.getState(state)?.placeholder,
          }),
        ]);
      },
    },
    state: {
      apply(tr, value) {
        const setPlaceholderStep = tr.steps.find(
          (step): step is SetPlaceholderStep =>
            step instanceof SetPlaceholderStep,
        );

        if (setPlaceholderStep) {
          return {
            placeholder: setPlaceholderStep.placeholder,
          };
        }

        return {
          placeholder: value.placeholder,
        };
      },
      init: () => INITIAL_STATE,
    },
  });
}
