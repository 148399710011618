import { Link, PostAuthor as DefaultPostAuthor } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  padding: 3rem 1rem 1rem;
  display: flex;
  flex-direction: column;

  ${from.tablet`
    padding: 3rem 2rem 2rem;
  `};

  ${from.laptop`
     padding: 2.5rem 3.75rem;
  `};
`;

export const DateClosed = styled.p`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  letter-spacing: 0.6px;
  margin-right: ${({ theme }) => theme.spacing.md};
`;

export const FeaturedWord = styled.span`
  font-weight: 600;
  margin: 0 0.3em 0 0.3rem;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const PostAuthor = styled(DefaultPostAuthor)`
  order: -1;
  ${from.tablet`
  order: 0;
  `};
`;

export const SectionTitle = styled.h2`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
`;

export const ShareContainer = styled.div`
  display: flex;
  align-items: center;
  order: 0;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    margin: 0 0 0 auto;
  `};
`;

export const TitleLink = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.xsm};
  order: -2;

  ${from.laptop`
     order: 0;
  `};
`;

export const TitleRow = styled.div`
  display: contents;

  ${from.tablet`
    display: flex;
    align-items: center;
  `};
`;
