import { OnboardingSteps as DefaultOnboardingSteps } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const OnboardingSteps = styled(DefaultOnboardingSteps)`
  margin-top: 2rem;

  ${from.tablet`
    margin-top: 3.2rem;
  `};
`;
