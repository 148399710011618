import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Description = styled.p`
  line-height: 1.5rem;
  margin: 0 0 ${({ theme }) => theme.spacing.md};
`;
