import React, { FC } from 'react';
import excludeOtherTag from 'utils/excludeOtherTag';
import { reorderTagsArray } from './logic';
import { Content, Tags, Title } from './styles';
import { Props } from './types';

const CardContent: FC<Props> = ({ tags, title, children, ...rest }) => {
  const filteredTags = tags.filter(excludeOtherTag);

  return (
    <Content {...rest}>
      {!!filteredTags && filteredTags.length !== 0 && (
        <Tags items={reorderTagsArray(filteredTags)} oneLine={true} />
      )}
      <Title>{title}</Title>
      {children}
    </Content>
  );
};

export default CardContent;
