import { Loader, ModalRoute, Pagination, Video, VideoGrid } from 'components';
import VideoModal from 'containers/Common/Modals/VideoModal';
import React, { FC } from 'react';
import { ArrowLink, Results, ResultsHeader, ResultsTitle } from '../../styles';
import connect from './connect';
import { Props } from './types';

const Videos: FC<Props> = ({
  available,
  count,
  isDetail,
  items,
  loading,
  location: { search },
  match: { path, url },
  page,
  pageCount,
  setPage,
  ...rest
}) => {
  if (!available && !loading) {
    return null;
  }

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>videos {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: videos"
            to={{
              search,
              pathname: `${url}/videos`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <VideoGrid>
            {items.map((video) => (
              <Video
                category={video.videoTypeTag}
                createdAt={video.created}
                description={video.description}
                duration={video.duration}
                image={video.thumbnail}
                key={video.id}
                link={video.videoLink}
                progress={video.progress}
                presenterTags={video.videoPresenterTags}
                seriesTags={video.videoSeriesTags}
                exerciseTags={video.exerciseTags}
                graphToolsTags={video.graphToolTags}
                title={video.title}
                views={video.views}
                solutionFor={video.solutionFor}
                contentType="videolesson"
                to={{
                  search,
                  pathname: `${url}/${video.slug}`,
                  state: {
                    scrollToTop: false,
                  },
                }}
              />
            ))}
            <ModalRoute component={VideoModal} path={`${path}/:videoSlug`} />
          </VideoGrid>
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Videos);
