import { RootState, Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { UsersState } from './types';

const selectState: Selector<UsersState> = ({ users }) => users;

export const selectUsersById = (state: RootState) => state.users.byId;

export const selectUsersBySlug = (state: RootState) => state.users.bySlug;

export const selectHeatMapsBySlug = (state: RootState) =>
  state.users.heatMapsBySlug;

export const selectUsers = createSelector(selectState, ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((it): it is NonNullable<typeof it> => !!it),
);

export const selectOutstandingUsers = (state: RootState) =>
  state.users.outstandingUsers;
