import { getUserType } from 'utils/getUserType';

export function getUserTypeLabel(userType: ReturnType<typeof getUserType>) {
  switch (userType) {
    case 'default':
      return 'Member';
    case 'premium':
      return 'Premium Member';
    case 'swd':
      return 'SWD Team';
  }
}
