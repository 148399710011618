import React, { FC, useCallback } from 'react';
import { pluralize } from 'utils/pluralize';
import { NewCommentSection } from '../Comments/styles';
import CommentList from './CommentList';
import connect from './connect';
import Edit from './Edit';
import { handleFormSubmitted, handleToggleReplyFormOpen } from './logic';
import NewQuestionButton from './NewQuestionButton';
import NewQuestionForm from './QuestionForm';
import {
  Container,
  Section,
  Title,
  TitlePostComment,
  TitleRow,
} from './styles';
import { Props } from './types';

const AmaSession: FC<Props> = ({
  amaParticipants,
  amaAuthor,
  beginIntent,
  comments = [],
  contentType,
  closed,
  createComment,
  disabled,
  id,
  loggedIn,
  path,
  push,
  replyFormsOpen,
  toggleReplyFormOpen,
  nQuestions,
  nAnswers,
  url,
  ...rest
}) => {
  const onFormSubmitted = useCallback(
    handleFormSubmitted({
      createComment,
      id,
      contentType,
    }),
    [createComment, id, contentType],
  );

  const onToggleReplyFormOpen = useCallback(
    handleToggleReplyFormOpen({
      beginIntent,
      loggedIn,
      toggleReplyFormOpen,
      url,
    }),
    [beginIntent, loggedIn, toggleReplyFormOpen, url],
  );
  return (
    <Container {...rest}>
      <Section disabled={disabled} closed={closed}>
        <TitleRow>
          <Title>
            {nQuestions} {pluralize(nQuestions, 'Question', 'Questions')} asked
            · {nAnswers} {pluralize(nAnswers, 'Answer', 'Answers')} given
          </Title>
          {!closed && (
            <NewQuestionButton
              beginIntent={beginIntent}
              disabled={!!disabled}
              loggedIn={loggedIn}
              url={url}
            />
          )}
        </TitleRow>
        <CommentList
          loggedIn={loggedIn}
          amaParticipants={amaParticipants}
          amaAuthor={amaAuthor}
          closed={!!closed}
          comments={comments}
          contentType={contentType}
          disabled={!!disabled || !!closed}
          objectId={id}
          onCreateComment={createComment}
          onToggleReplyFormOpen={onToggleReplyFormOpen}
          replyFormsOpen={replyFormsOpen}
          url={url}
        />
      </Section>
      {!closed && (
        <NewCommentSection id="new-question" canAdd={loggedIn && !disabled}>
          {loggedIn && !disabled && (
            <>
              <TitleRow>
                <TitlePostComment>Ask your question!</TitlePostComment>
              </TitleRow>
              <NewQuestionForm id={id} onSubmit={onFormSubmitted} />
            </>
          )}
        </NewCommentSection>
      )}
      {loggedIn && !closed && (
        <Edit contentType={contentType} objectId={id} path={path} />
      )}
    </Container>
  );
};

export default connect(AmaSession);
