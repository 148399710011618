import { LoginForm, LOGIN_FORM_KEY, SignUpForm, SIGN_UP_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const { selectValues } = makeFormSelectors<LoginForm>(LOGIN_FORM_KEY);

const { selectValues: selectSignUpValues } = makeFormSelectors<SignUpForm>(
  SIGN_UP_FORM_KEY,
);

const mapStateToProps = (state: RootState) => {
  const values = selectValues(state);
  const signUp = selectSignUpValues(state);

  const initialValues: ConfigProps<LoginForm>['initialValues'] = {
    email: signUp?.email ?? '',
    password: signUp?.password ?? signUp?.passwordConfirm ?? '',
  };

  return {
    initialValues,
    filled: !!values?.email && !!values?.password,
    loading: state.auth.loading,
    search: state.router.location.search,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<LoginForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<LoginForm>({
    form: LOGIN_FORM_KEY,
  }),
) as FormDecorator<LoginForm, OwnProps, ConnectedProps>;
