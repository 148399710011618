import { getUserName } from 'model';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  logOut,
  selectUser,
  selectUserIsPremium,
  selectUserIsPremiumGroupOwner,
} from 'redux/modules/auth';
import { selectSignUpModalOpen } from 'redux/modules/modal';
import { selectRouterAction } from 'redux/modules/router/selectors';
import { getUserType } from 'utils/getUserType';
import { useActions } from 'utils/useActions';
import { useThereIsALiveEvent } from './logic';

export const useConnect = () => {
  const location = useLocation();
  const { replace } = useHistory();

  const thereIsALiveEvent = useThereIsALiveEvent();

  const action = useSelector(selectRouterAction);
  const signUpModalOpen = useSelector(selectSignUpModalOpen);
  const user = useSelector(selectUser);
  const userIsPremium = useSelector(selectUserIsPremium);
  const userIsPremiumGroupOwner = useSelector(selectUserIsPremiumGroupOwner);

  const userAvatar = user?.avatar;
  const userName = user ? getUserName(user) : undefined;
  const userType = user && getUserType(user);

  const actions = useActions({
    logOut: logOut.request,
  });

  return {
    ...actions,
    action,
    location,
    replace,
    signUpModalOpen,
    thereIsALiveEvent,
    userAvatar,
    userIsPremium,
    userIsPremiumGroupOwner,
    userName,
    userType,
  };
};
