import { createEditorState, parseHTML } from 'components/Editor';
import { isLoading } from 'components/MediaInput';
import { CommentForm, COMMENT_FORM_KEY, UPLOAD_MEDIA_FIELD } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { id, editId }: OwnProps) => {
  const form = COMMENT_FORM_KEY(id, editId);

  const { selectIsPristine, selectValues } = makeFormSelectors<CommentForm>(
    form,
  );
  const values = selectValues(state);

  const commentToEdit = state.comment.byCommentId[editId ?? ''];

  return {
    form,
    filesLoading: isLoading(values?.[UPLOAD_MEDIA_FIELD]),
    initialValues: {
      [UPLOAD_MEDIA_FIELD]: [],
      comment: createEditorState(),
      ...(commentToEdit
        ? {
            [UPLOAD_MEDIA_FIELD]: commentToEdit.image
              ? [
                  {
                    file: commentToEdit.image,
                    mediaType: 'IMAGE',
                    thumbnail: commentToEdit.image,
                  },
                ]
              : [],
            comment: createEditorState(parseHTML(commentToEdit.comment)),
          }
        : {}),
    },
    isPristine: selectIsPristine(state),
    loading: state.comment.loading,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<CommentForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<CommentForm>({
    destroyOnUnmount: false,
  }),
) as FormDecorator<CommentForm, OwnProps, ConnectedProps>;
