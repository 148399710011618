import Images from 'containers/Common/Images';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import ChallengeDetailRoot from './ChallengeDetailRoot';
import ChallengeSubmission from './ChallengeSubmission';
import { useConnect } from './connect';
import { Props } from './types';

const ChallengeDetail: FC<Props> = () => {
  const { challenge, challengeSlug, getChallenge, path, url } = useConnect();

  const name = challenge?.name ?? 'Loading...';

  useEffect(() => {
    getChallenge({ slug: challengeSlug, silent: true });
  }, [challengeSlug, getChallenge]);

  return (
    <>
      <BreadcrumbsItem to={url}>{name}</BreadcrumbsItem>
      <Switch>
        <Route component={Images} path={`${path}/images`} />
        <Route component={Images} path={`${path}/:submissionSlug/images`} />
        <Route
          component={Images}
          path={`${path}/:submissionSlug/:commentId/comment-image`}
        />
        <Route path={`${path}/:submissionSlug`}>
          <ChallengeSubmission />
        </Route>
        <Route component={ChallengeDetailRoot} path={path} />
      </Switch>
    </>
  );
};

export default ChallengeDetail;
