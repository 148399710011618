import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import {
  createComment,
  selectObjectComments,
  toggleReplyFormOpen,
} from 'redux/modules/comment';
import { RootState } from 'redux/modules/types';
import { beginIntent } from '../../../redux/modules/intent';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const object = (() => {
    switch (ownProps.contentType) {
      case 'challengeresponse':
        return state.challengeResponse;
      case 'conversation':
        return state.conversation;
      case 'galleryentry':
        return state.discover;
      case 'exerciseresponse':
        return state.exerciseResponse;
      case 'feedback':
        return state.feedback;
      default:
        return { byId: {} as { [key: string]: undefined } };
    }
  })().byId[ownProps.id];

  return {
    comments: selectObjectComments(state, ownProps),
    loggedIn: !!state.auth.user,
    replyFormsOpen: state.comment.replyFormsOpen,
    totalComments: object?.commentsCount ?? 0,
  };
};

const mapDispatchToProps = {
  beginIntent,
  push,
  toggleReplyFormOpen,
  createComment: createComment.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
