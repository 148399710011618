import {
  Fold,
  FormContainer,
  Loader,
  NoResults,
  PageTitle,
  Pagination,
  SectionTitle,
} from 'components';
import ProtectedButton from 'containers/Common/ProtectedButton';
import SearchOptions from 'containers/Common/SearchOptions';
import {
  Conversation as ConversationModel,
  getUserName,
  Statuses,
  Tag,
} from 'model';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { DeepReadonly } from 'utility-types';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import connect from './connect';
import ConversationForm from './ConversationForm';
import { handleFormSubmitted } from './logic';
import {
  Container,
  Content,
  Conversation,
  Conversations,
  Heading,
  HeadingRow,
  Link,
  Section,
  SectionDescription,
} from './styles';
import { Props } from './types';

export function getStatuses(
  conversation: DeepReadonly<ConversationModel>,
  tags: DeepReadonly<Tag[]>,
) {
  const readOnlyTags = tags
    .filter((tag) => tag.readOnly)
    .map((tag) => tag.name);
  const { swdTeam, tags: conversationTags } = conversation;
  const statuses = readOnlyTags.filter((tag) => conversationTags.includes(tag));
  if (swdTeam) {
    statuses.push(Statuses.SWD_TEAM);
  }
  return statuses;
}

const ConversationsRoot: FC<Props> = ({
  conversations,
  createConversation,
  getConversations,
  location: { hash, search, state },
  loggedIn,
  match: { url },
  pageCount,
  loading,
  wentBack,
  tags,
}) => {
  const page = getPageFromQuery(search);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getConversations({ search, silent: wentBack });
  }, [getConversations, search, wentBack]);

  const onFormSubmitted = useCallback(
    handleFormSubmitted({ createConversation }),
    [createConversation],
  );

  const getConversationStatuses = (index: number) =>
    getStatuses(conversations[index], tags);

  return (
    <Container>
      <PageTitle title="Talk" />
      <Fold
        title="let’s talk"
        description={`Is there a topic you want to chat about or a question where community input would be helpful?
        Start a discussion or browse topics and take part in the conversation.`}
      />
      <Content ref={contentRef}>
        <Section>
          <HeadingRow>
            <Heading>
              <SectionTitle>Join a conversation</SectionTitle>
              <SectionDescription>
                Here’s what the community is talking about: take a look and
                participate!
              </SectionDescription>
            </Heading>
            <ProtectedButton
              to={{
                hash: 'start-conversation',
                pathname: url,
              }}
            >
              Let's talk
            </ProtectedButton>
          </HeadingRow>
          <SearchOptions
            contentType="conversation"
            searchPlaceholder="Search a topic in the Forum..."
          />
          {loading ? (
            <Loader />
          ) : conversations.length > 0 ? (
            <>
              <Conversations>
                {conversations.map(
                  (
                    {
                      commentsCount,
                      hits,
                      id,
                      created,
                      tags,
                      title,
                      description,
                      votesCount,
                      user,
                      isAma,
                      isMonthly = false,
                      isSpotlight = false,
                      spotlightImage,
                      spotlightUsers,
                      spotlightDate,
                      swdTeam,
                      amaParticipants,
                      amaStartDate,
                      amaEndDate,
                      amaAnswers,
                      closed,
                      live,
                      lastComments,
                      amaQuestions,
                    },
                    index,
                  ) => (
                    <Link key={id} to={`${url}/${id}`}>
                      <Conversation
                        created={created}
                        commentsCount={isAma ? amaQuestions : commentsCount}
                        datapoints={votesCount}
                        statuses={getConversationStatuses(index)}
                        swdTeamChimedIn={swdTeam}
                        tags={tags}
                        title={title}
                        description={description}
                        views={hits}
                        authorAvatar={user.avatar!}
                        author={getUserName(user)}
                        authorType={getUserType(user)}
                        isAma={isAma}
                        isMonthly={isMonthly}
                        isSpotlight={isSpotlight}
                        spotlightImage={spotlightImage}
                        spotlightUsers={spotlightUsers}
                        spotlightDate={spotlightDate}
                        amaParticipants={amaParticipants}
                        amaStartDate={amaStartDate}
                        amaEndDate={amaEndDate}
                        closed={closed}
                        amaAnswers={amaAnswers}
                        live={live}
                        lastComments={lastComments}
                        url={`${url}/${id}`}
                      />
                    </Link>
                  ),
                )}
              </Conversations>
              <Pagination
                contentRef={contentRef}
                current={Math.min(page, pageCount)}
                hash={hash}
                search={search}
                state={state}
                total={pageCount}
                url={url}
              />
            </>
          ) : (
            <NoResults />
          )}
        </Section>
        {loggedIn && (
          <FormContainer id="start-conversation">
            <SectionTitle>start a conversation</SectionTitle>
            <ConversationForm onSubmit={onFormSubmitted} />
          </FormContainer>
        )}
      </Content>
    </Container>
  );
};

export default connect(ConversationsRoot);
