import storage from 'redux-persist/lib/storage';

const persistConfig = {
  storage,
  key: 'root',
  version: 1,
  whitelist: ['configuration', 'group', 'intent', 'tag', 'theme', 'home'],
};

export default persistConfig;
