import { AppContentType, Vote } from 'model';
import { combineEpics } from 'redux-observable';
import { _upsertChallengeResponsesById } from 'redux/modules/challengeResponse';
import { _upsertCommentsById } from 'redux/modules/comment';
import { _upsertConversationsById } from 'redux/modules/conversation';
import { _upsertExerciseResponsesById } from 'redux/modules/exerciseResponse';
import { _upsertFeedbackRequestsById } from 'redux/modules/feedback';
import { Epic } from 'redux/modules/types';
import { filter } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { optimistic } from 'utils/optimistic';
import { _upsertDiscoverById } from '../discover';
import { addVote, removeVote } from './actions';

const createContentUpdater = (isVoted: boolean) => (
  contentType: AppContentType,
  objectId: string,
) => {
  switch (contentType) {
    case 'challengeresponse':
      return [
        _upsertChallengeResponsesById([
          {
            isVoted,
            id: objectId,
            votesCount: ({ votesCount }) => votesCount + (isVoted ? 1 : -1),
          },
        ]),
      ];
    case 'comment':
      return [
        _upsertCommentsById([
          {
            isVoted,
            id: objectId,
            votesCount: ({ votesCount }) => votesCount + (isVoted ? 1 : -1),
          },
        ]),
      ];
    case 'conversation':
      return [
        _upsertConversationsById([
          {
            isVoted,
            id: objectId,
            votesCount: ({ votesCount }) => votesCount + (isVoted ? 1 : -1),
          },
        ]),
      ];
    case 'exerciseresponse':
      return [
        _upsertExerciseResponsesById([
          {
            isVoted,
            id: objectId,
            votesCount: ({ votesCount }) => votesCount + (isVoted ? 1 : -1),
          },
        ]),
      ];
    case 'feedback':
      return [
        _upsertFeedbackRequestsById([
          {
            isVoted,
            id: objectId,
            votesCount: ({ votesCount }) => votesCount + (isVoted ? 1 : -1),
          },
        ]),
      ];
    case 'galleryentry':
      return [
        _upsertDiscoverById([
          {
            isVoted,
            id: objectId,
            votesCount: ({ votesCount }) =>
              (votesCount ?? 0) + (isVoted ? 1 : -1),
          },
        ]),
      ];
    default:
      return [];
  }
};

const markAsVoted = createContentUpdater(true);
const markAsNotVoted = createContentUpdater(false);

export const addVoteEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(addVote.request)),
    optimistic({
      onSuccess: () => [addVote.success()],
      onFailure: () => [addVote.failure()],
      sendRequest: ({ payload: body }) =>
        request<Vote>({
          body,
          path: 'vote',
          method: 'POST',
        }),
      rollbackState: ({ payload: body }) =>
        markAsNotVoted(body.contentType, body.objectId),
      updateState: ({ payload: body }) =>
        markAsVoted(body.contentType, body.objectId),
    }),
  );

export const removeVoteEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(removeVote.request)),
    optimistic({
      onSuccess: () => [removeVote.success()],
      onFailure: () => [removeVote.failure()],
      sendRequest: ({ payload: body }) =>
        request<{}>({
          body,
          path: 'vote',
          method: 'DELETE',
        }),
      rollbackState: ({ payload: body }) =>
        markAsVoted(body.contentType, body.objectId),
      updateState: ({ payload: body }) =>
        markAsNotVoted(body.contentType, body.objectId),
    }),
  );

export default combineEpics(addVoteEpic, removeVoteEpic);
