import { Error, Input } from 'components';
import { secondaryEmailFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { email, required } from 'utils/validations';
import connect from './connect';
import {
  ButtonPrimary,
  Container,
  InvitationEmailInput,
  ResetPassword,
  Row,
} from './styles';
import { Props } from './types';

const SetSecondaryEmailForm: FC<Props> = ({
  error,
  handleSubmit,
  invalid,
  invitationEmail,
  loading,
  pristine,
}) => (
  <Container onSubmit={handleSubmit}>
    <Field
      component={InvitationEmailInput}
      label="Invitation Email"
      name="Invitation Email"
      placeholder={invitationEmail}
      type="email"
      disabled={true}
    />
    <Field
      autocomplete="username"
      component={Input}
      label="Email"
      name={secondaryEmailFieldNames.email}
      placeholder="Enter your email"
      type="text"
      validate={[email, required]}
    />
    <Field
      autocomplete="current-password"
      component={Input}
      label="Password"
      name={secondaryEmailFieldNames.password}
      placeholder="Enter your password"
      type="password"
      validate={required}
    />
    {error && <Error text={error} />}
    <Row>
      <ButtonPrimary disabled={invalid || loading || pristine} type="submit">
        {loading ? 'Loading...' : 'Log in'}
      </ButtonPrimary>
      <ResetPassword to="/reset-password">Forgot your password?</ResetPassword>
    </Row>
  </Container>
);

export default connect(SetSecondaryEmailForm);
