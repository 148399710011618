import React, { FC } from 'react';
import { Container, FullWidthContainer, Tab, TabsDropdown } from './styles';
import { Props } from './types';

const Tabs: FC<Props> = ({
  baseUrl,
  items,
  variant = 'primary',
  forceDropdown = false,
  valuePrefix,
  ...rest
}) => {
  const displayDropdown = items.length > 2;

  return (
    <FullWidthContainer $variant={variant} $forceDropdown={forceDropdown}>
      <Container {...rest}>
        {forceDropdown ? (
          <TabsDropdown
            $alwaysVisible={true}
            baseUrl={`${baseUrl}`}
            items={items}
            valuePrefix={valuePrefix}
          />
        ) : (
          <>
            {displayDropdown && (
              <TabsDropdown baseUrl={`${baseUrl}`} items={items} />
            )}
            {items.map(({ label, url }) => (
              <Tab
                $displayDropdown={displayDropdown}
                $variant={variant}
                key={typeof url === 'string' ? url : url.pathname}
                to={{
                  ...(typeof url !== 'string' ? url : {}),
                  pathname: `${baseUrl}/${
                    typeof url === 'string' ? url : url.pathname
                  }`,
                  state: {
                    scrollToTop: false,
                    ...(typeof url !== 'string' ? url.state : {}),
                  },
                }}
              >
                <span>{label}</span>
              </Tab>
            ))}
          </>
        )}
      </Container>
    </FullWidthContainer>
  );
};

export default Tabs;
