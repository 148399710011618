import { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { setSearchSectionPage } from 'redux/modules/search';
import { RootState } from 'redux/modules/types';
import {
  SEARCH_PODCAST_PER_PAGE_DETAIL,
  SEARCH_PODCAST_PER_PAGE_MASTER,
} from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { isDetail }: OwnProps) => ({
  available: state.search.available.podcast,
  count: state.search.count.podcast,
  items: state.search.ids.podcast
    .map((id) => state.search.byId.podcast[id])
    .filter((it): it is NonNullable<typeof it> => !!it),
  loading: state.search.loading.visuals,
  page: state.search.page.podcast,
  pageCount: getPageCount(
    state.search.count.podcast,
    isDetail ? SEARCH_PODCAST_PER_PAGE_DETAIL : SEARCH_PODCAST_PER_PAGE_MASTER,
  ),
});

const mapDispatchToProps = {
  setPage: (page: SetStateAction<number>) =>
    setSearchSectionPage({
      page,
      section: 'podcast',
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
