export const light = {
  colors: {
    accent: '#297fd5',
    primary: '#004a8f',
    primaryHover: '#2271c0',
    darkPrimary: '#003e78',
    white: '#ffffff',
    peach: '#fae1a7',
    basicGold: '#f5dda4',
    lightGold: '#fbf1da',
    donkey: '#a69671',
    judge: '#584e36',
    grey100: '#f6f6f6',
    grey200: '#f2f2f2',
    grey300: '#d0d0d0',
    grey400: '#706f6f',
    grey500: '#495057',
    greyBlue: '#dee2e8',
    conversationComment: '#8e9195',
    sky: '#d7e4f1',
    bookLight: '#bbc9e2',
    red: '#c05050',
    error: '#fe6868',
    black: '#1b1b1b',
    lightViolet: '#b0cceb',
  },
  spacing: {
    xsm: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xlg: '2rem',
  },
  wrapperPaddingH: {
    mobile: '1rem',
    desktop: '2.5rem',
  },
  weights: {
    light: 400,
    regular: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
};
