import DefaultButton from 'components/Button';
import { PlusIcon as DefaultPlusIcon } from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Button = styled(DefaultButton)`
  width: 100%;
  padding: 10px 16px;

  ${from.mobileXL`
    padding: 8px;
  `}
`;

export const PlusIcon = styled(DefaultPlusIcon)`
  width: 20px;
  height: 20px;
  margin-left: 4px;
`;
