import React, { FC, useEffect, useRef } from 'react';
import { Container } from './styles';
import { Props } from './types';

const AutoScrollIntoView: FC<Props> = ({ children, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [ref]);

  return (
    <Container ref={ref} {...rest}>
      {children}
    </Container>
  );
};

export default AutoScrollIntoView;
