import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { Container as OptionContainer } from '../Option/styles';

export const Container = styled.div`
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: ${ZIndex.SELECT_MENU};

  ${OptionContainer}:not (:last-child) {
    margin-bottom: 0.25rem;
  }
`;
