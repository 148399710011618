import { RefObject } from 'react';

export function copyToClipboard(
  textToCopy: string,
  rootRef: RefObject<HTMLDivElement>,
) {
  if (!rootRef.current) {
    return null;
  }

  const dummy = document.createElement('input');
  const button = rootRef.current;
  button.appendChild(dummy);
  dummy.setAttribute('value', textToCopy);
  dummy.select();
  document.execCommand('copy');
  button.removeChild(dummy);
}
