import {
  AddExtraSeatsCorporatePremiumForm,
  ADD_EXTRA_SEATS_CORPORATE_PREMIUM_FORM_KEY,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ConfigProps,
  FormErrors,
  getFormSubmitErrors,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { addExtraSeats } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const { selectValues } = makeFormSelectors<AddExtraSeatsCorporatePremiumForm>(
  ADD_EXTRA_SEATS_CORPORATE_PREMIUM_FORM_KEY,
);

const selectErrors = getFormSubmitErrors(
  ADD_EXTRA_SEATS_CORPORATE_PREMIUM_FORM_KEY,
) as (state: RootState) => FormErrors<AddExtraSeatsCorporatePremiumForm>;

const mapStateToProps = (state: RootState) => {
  const initialValues: ConfigProps<
    AddExtraSeatsCorporatePremiumForm
  >['initialValues'] = {
    totalUsers: 1,
  };
  return {
    initialValues,
    totalUsers: selectValues(state)?.totalUsers,
    emailError: selectErrors(state)?.totalUsers,
    loading: state.premium.updateQuantityLoading,
  };
};

const mapDispatchToProps = {
  addExtraSeats: addExtraSeats.request,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<AddExtraSeatsCorporatePremiumForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<AddExtraSeatsCorporatePremiumForm>({
    form: ADD_EXTRA_SEATS_CORPORATE_PREMIUM_FORM_KEY,
  }),
) as FormDecorator<AddExtraSeatsCorporatePremiumForm, OwnProps, ConnectedProps>;
