import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Bubble, Text } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" {...props}>
    {children}
    <g
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Bubble
        strokeWidth="1.2"
        d="M12.9 1C6.602 1 1.496 5.33 1.496 10.673c.053 2.694 1.293 5.218 3.371 6.863L2.487 23.4l6.32-3.707c1.323.432 2.704.653 4.093.652 6.298 0 11.404-4.33 11.404-9.672C24.304 5.33 19.198 1 12.9 1z"
      />
      <Text strokeWidth="1.5" d="M8 8h7.933M8 11h10.908M8 14h10.908" />
    </g>
  </svg>
));
