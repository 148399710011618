import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectAuthState } from 'redux/modules/auth';
import { getUser, selectUsersBySlug } from 'redux/modules/users';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { path, params, url } = useRouteMatch<{ userSlug?: string }>();

  const auth = useSelector(selectAuthState);
  const usersBySlug = useSelector(selectUsersBySlug);

  const actions = useActions({
    getUser: getUser.request,
  });

  const { userSlug = auth.user!.slug } = params;
  const user = userSlug ? usersBySlug[userSlug] : auth.user;

  return {
    ...actions,
    params,
    path,
    url,
    user,
  };
};
