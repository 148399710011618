import { parse, stringify } from 'query-string';
import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { selectVideoTypesTags } from 'redux/modules/configuration';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { location: { search } }: OwnProps,
) => {
  const { category: rawCategory } = parse(search);
  const category = typeof rawCategory === 'string' ? rawCategory : 'all';

  const videoTypes = selectVideoTypesTags(state).map(({ label, url }) => ({
    label,
    url: {
      active: url === category,
      pathname: '',
      search: stringify({
        category: url,
      }),
    },
  }));

  return {
    category,
    videoTypes,
    isPremium: selectUserIsPremium(state),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
