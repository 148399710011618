import { Button as DefaultButtonPrimary } from 'components';
import styled from 'styled-components';
import { from, paragraph, title, viewContainer } from 'styles/mixins';
import Options from './Options';

export const Container = styled.div`
  ${viewContainer};
  margin-top: 0;
  margin-bottom: 7rem;
  color: ${({ theme }) => theme.colors.black};
  min-height: 30rem;
  position: relative;
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};
  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
  ${from.tablet`
    margin-top: 5rem;
  `};
`;

export const Header = styled.div`
  margin: auto;
  max-width: 45.1875rem;
  text-align: center;
`;

export const Title = styled.h1`
  ${title};
  margin-top: ${({ theme }) => theme.spacing.xlg};
`;

export const Paragraph = styled.p`
  ${paragraph};
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  margin-top: ${({ theme }) => theme.spacing.xlg};
  ${from.mobileXL`
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  `};
`;

export const ButtonPrimary = styled(DefaultButtonPrimary)`
  padding: 1.1rem 1.5rem;
  box-sizing: border-box;
  width: 100%;
  ${from.mobileXL`
    width: auto;
  `};
`;

export const SearchOptions = styled(Options)`
  > :first-child {
    width: 100%;
    padding-right: 0;
  }
  ${from.mobileXL`
    >:first-child {
      padding-right: 3.625rem;
    }
  `};
`;

export const Content = styled.div``;

export const Gallery = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.xlg};

  > * {
    border: solid 1px lightgrey;
    border-radius: 0.4rem;
    overflow: hidden;

    :hover {
      > * {
        opacity: 1;
      }
    }
  }
`;
