import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  industryTags: state.configuration.graphIndustryTags,
  toolTags: state.configuration.graphToolTags,
  topicTags: state.configuration.graphTopicTags,
  typeTags: state.configuration.graphTypeTags,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
