import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectUserId } from 'redux/modules/auth';
import {
  getFeedbackRequest,
  selectFeedbackById,
  selectFeedbackLoading,
} from 'redux/modules/feedback';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { id },
    path,
    url,
  } = useRouteMatch<{ id: string }>();

  const userId = useSelector(selectUserId);
  const byId = useSelector(selectFeedbackById);
  const loading = useSelector(selectFeedbackLoading);

  const feedback = byId[id];
  const userIsAuthor = userId === feedback?.user.id;

  const actions = useActions({
    getFeedbackRequest: getFeedbackRequest.request,
  });

  return {
    ...actions,
    feedback,
    id,
    loading,
    path,
    url,
    userIsAuthor,
  };
};
