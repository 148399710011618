import { Button as DefaultButtonPrimary } from 'components';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { commonButtonStyles } from '../styles';

export const Container = styled.form`
  text-align: left;
`;

export const Row = styled.div`
  display: flex;

  > * {
    flex: 1;
    :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.md};
    }
  }
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  text-align: center;
  width: 100%;
`;

export const ButtonPrimary = styled(DefaultButtonPrimary)`
  ${commonButtonStyles};
`;
