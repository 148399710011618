import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { from } from 'styles/mixins';
import { keysOf } from 'utils/keysOf';

const backgrounds = ['#7f0000', '#12005e', '#003d00', '#bc5100'];

export const Container = styled.div`
  ::after {
    background-color: ${backgrounds[0]};
    bottom: 0;
    color: ${({ theme }) => theme.colors.white};
    content: 'mobile';
    position: fixed;
    right: 0;
    z-index: ${ZIndex.BREAKPOINT_DEBUGGER};

    ${keysOf(from).map(
      (key, i) => from[key]`
        background-color: ${backgrounds[i + 1]};
        content: '${key}';
      `,
    )};
  }
`;
