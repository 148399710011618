import { ChangeEmailForm } from 'model';
import React from 'react';
import { serialize as serializeElement } from 'react-serialize';
import { deleteAccount } from 'redux/modules/auth';
import { serializeIntent } from 'utils/intent';
import { useConnect } from './connect';

export const handleChangeEmailFormSubmitted = ({
  editProfile,
}: {
  editProfile: ReturnType<typeof useConnect>['editProfile'];
}) => ({ email }: ChangeEmailForm) => {
  editProfile({
    email,
  });
};

export const handleDeleteAccount = ({
  openConfirmation,
}: {
  openConfirmation: ReturnType<typeof useConnect>['openConfirmation'];
}) => () => {
  openConfirmation({
    onAcceptIntent: serializeIntent([deleteAccount.request()]),
    title: serializeElement(<span>Close account</span>),
    description: serializeElement(
      <span>
        Are you sure you want to delete your user? We’ll be sad to see you go.
        <br />
        Once you delete your user, there is no going back.
      </span>,
    ),
    acceptButtonLabel: 'CLOSE ACCOUNT',
    acceptButtonVariant: 'danger',
    cancelButtonLabel: 'NEVER MIND!',
  });
};
