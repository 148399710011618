import { FormNameMap } from 'utils/forms';

export const LOGIN_FORM_KEY = 'forms/LOGIN';

export interface LoginForm {
  email: string;
  password: string;
}

export const LoginFieldNames: FormNameMap<LoginForm> = {
  email: 'email',
  password: 'password',
};
