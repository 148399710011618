import {
  Avatar as DefaultAvatar,
  Tabs as DefaultTabs,
  UserProfileHeader as DefaultUserProfileHeader,
} from 'components';
import { Picture } from 'components/Avatar/styles';
import { NotFoundIcon, SWDLogoIcon } from 'components/icons';
import {
  BadgeBackground,
  BadgeContainer,
  SWDLogoIcon as StyledSWDLogoIcon,
} from 'components/SWDTeamOrPremiumBadge/styles';
import { Tab } from 'components/Tabs/styles';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { container, from, paragraph, wrapper } from 'styles/mixins';
import { randomId } from 'utils/randomId';
import GoPremium from '../GoPremium';

export const Container = styled.div`
  width: 100%;
`;

export const UserProfileHeaderContainer = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Header = styled.div`
  padding-top: 1rem;
  background: linear-gradient(to bottom, #f2f2f2 100%, #fff 0%);
  /* @todo Pending achievements: background-size: 100% 278px; */
  background-size: 100% 178px;
  background-repeat: no-repeat;

  /* @todo Pending achievements: background-size: 100% 218px; */
  ${from.tabletH`
    background-size: 100% 198px;
    padding-top: 1.25rem;
  `};
`;

export const WrapperContainer = styled.div`
  ${container};
  ${wrapper};

  display: flex;
  gap: 30px;
  margin-bottom: 20px;
`;

export const UserProfileDetailsContainer = styled.section`
  display: none;

  ${from.tabletH`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 237px;
    height: fit-content;
    padding: 16px 16px 32px;
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  `};
`;

export const Avatar = styled(DefaultAvatar)<{ swdTeam?: boolean }>`
  border: none;
  margin: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 9px;

  ${Picture} {
    border-radius: 9px;
  }

  ${BadgeBackground} {
    display: none;
  }

  ${BadgeContainer} {
    width: 25px;
    height: 25px;
    left: 35px;
    bottom: 2px;
  }

  ${StyledSWDLogoIcon} {
    width: 17px;
    height: 17px;
  }

  ${from.tabletH`
    width: 100%;
    height: 100%;

    ${BadgeBackground} {
      display: flex;
      padding-top: 2px;
    }
    
    ${BadgeContainer} {
      width: 43px;
      height: 43px;
      left: 0.75rem;
      bottom: 4.5px;
    }

    ${StyledSWDLogoIcon} {
      height: 1.375rem;
      width: 1.875rem;
    };
  `};
`;

export const ContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  /* @todo Pending achievements: remove gap */
  ${from.tabletH`
    gap: 40px;
    padding: 40px 0 80px;
    overflow-x: hidden;
  `}
`;

export const UserProfileHeader = styled(DefaultUserProfileHeader)``;

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const MainTabs = styled(DefaultTabs)`
  width: 100%;

  ${Tab} {
    min-width: 164px;
    justify-content: center;

    span {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const SWDLogo = styled(SWDLogoIcon)`
  margin-left: 0.625rem;
  padding: 0.25rem;
  width: 1.3rem;
  height: 1rem;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.basicGold};

  ${from.tabletH`
    width: 2rem;
    height: 1.37rem;
    margin-top: -0.3rem;
  `}
`;

export const ActivitySection = styled.section``;

export const Controls = styled.div`
  display: block;
  ${from.tabletH`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  `};
`;

export const Tabs = styled(DefaultTabs)``;

export const Menu = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

const menuLinkActive = `menuLinkActive-${randomId()}`;

export const MenuLink = styled(NavLink).attrs({
  activeClassName: menuLinkActive,
})`
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey200};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.375rem 1.25rem 0.5rem;
  text-decoration: none;
  opacity: 0.7;
  transition: background-color 0.2s;

  &.${menuLinkActive} {
    border-color: ${({ theme }) => theme.colors.accent};
    opacity: 1;
  }

  :hover {
    border-color: ${({ theme }) => theme.colors.grey300};
  }
`;

export const GoPremiumSection = styled(GoPremium)`
  margin-top: 5.375rem;
`;

export const NoContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5rem;
  width: 100%;
  border-top: 2px solid ${({ theme }) => theme.colors.grey100};
`;

export const NoContentIcon = styled(NotFoundIcon)`
  width: 12.75rem;
  height: 7.8125rem;
`;

export const NoContentText = styled.p`
  ${paragraph};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
