import { ReactNode } from 'react';
import { serialize as serializeElement } from 'react-serialize';
import { openConfirmation } from 'redux/modules/modal';
import { RootAction } from 'redux/modules/types';
import { serializeIntent } from 'utils/intent';

export const openConfirmationModal = ({
  acceptButtonLabel = 'Accept',
  acceptButtonVariant = 'primary',
  cancelButtonLabel = 'Close',
  description,
  onAccept,
  title,
}: {
  acceptButtonLabel?: string;
  acceptButtonVariant?: 'primary' | 'secondary' | 'danger';
  cancelButtonLabel?: string;
  description: ReactNode;
  title: ReactNode;
  onAccept: RootAction[];
}) =>
  openConfirmation({
    acceptButtonLabel,
    acceptButtonVariant,
    cancelButtonLabel,
    description: serializeElement(description),
    onAcceptIntent: serializeIntent(onAccept),
    title: serializeElement(title),
  });
