import type { Node } from 'prosemirror-model';
import type { Transaction } from 'prosemirror-state';
import type { NodeWithPos } from 'prosemirror-utils';

import type { Schema } from '../../schema';
import { ReferenceProvider } from '../types';
import { OptionsProps } from './types';

function isReferenceValid(node: Node<Schema>): boolean {
  return (
    typeof node.text === 'string' &&
    node.text[0] === '@' &&
    node.text[1] !== ' '
  );
}

function isNoOptionsAndSpacer(
  node: Node<Schema>,
  options: readonly OptionsProps[],
): boolean {
  if (
    typeof node.text === 'string' &&
    options.length === 0 &&
    node.text !== '@'
  ) {
    return node.text[node.text.length - 1] !== ' ';
  }
  return true;
}

export const ensureReferencesAreValid = ({
  allReferences,
  schema,
  tr,
}: {
  allReferences: readonly NodeWithPos[];
  schema: Schema;
  tr: Transaction<Schema>;
}) => {
  allReferences
    .filter(({ node }) => !isReferenceValid(node))
    .forEach(({ node, pos }) => {
      tr.removeMark(pos, pos + node.nodeSize, schema.marks.reference);
    });
};

export const exitReferenceOnNoOption = ({
  allReferences,
  schema,
  tr,
  options,
}: {
  allReferences: readonly NodeWithPos[];
  schema: Schema;
  tr: Transaction<Schema>;
  options: readonly OptionsProps[];
}) => {
  allReferences
    .filter(({ node }) => !isNoOptionsAndSpacer(node, options))
    .forEach(({ node, pos }) => {
      tr.removeMark(
        pos + node.nodeSize - 1,
        pos + node.nodeSize,
        schema.marks.reference,
      );
    });
};

export const ensureReferencesAreNotBroken = ({
  allReferences,
  getReferencedEntity,
  schema,
  tr,
}: {
  allReferences: readonly NodeWithPos[];
  getReferencedEntity: ReferenceProvider['getReferencedEntity'] | undefined;
  schema: Schema;
  tr: Transaction<Schema>;
}) => {
  allReferences.forEach(({ node, pos }) => {
    const referenceMark = node.marks.find(
      (mark) => mark.type === schema.marks.reference,
    );

    if (!referenceMark) {
      return;
    }

    const entity = getReferencedEntity?.(
      referenceMark.attrs.entityId,
      referenceMark.attrs.entityType,
    );

    // If we can't find the entity, or the label has not been modified,
    // do nothing.
    if (!entity || `@${entity.label}` === node.textContent) {
      return;
    }

    tr.removeMark(pos, pos + node.nodeSize, schema.marks.reference);
    tr.addMark(
      pos,
      pos + node.nodeSize,
      schema.marks.reference.create({
        id: referenceMark.attrs.id,
      }),
    );
  });
};
