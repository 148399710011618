import { Exercise, PaginatedResponse, UpsertByIdPayload } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getExercises = createAsyncAction(
  'exercise/GET_EXERCISES_REQUEST',
  'exercise/GET_EXERCISES_SUCCESS',
  'exercise/GET_EXERCISE_FAILURE',
)<
  { search?: string; silent?: boolean },
  PaginatedResponse<Exercise> & { active: Exercise },
  undefined
>();

export const getExercise = createAsyncAction(
  'exercise/GET_EXERCISE_REQUEST',
  'exercise/GET_EXERCISE_SUCCESS',
  'exercise/GET_EXERCISE_FAILURE',
)<{ slug: string; silent?: boolean }, Exercise, undefined>();

export const _upsertExercisesById = createAction(
  'feedback/_UPSERT_EXERCISES_BY_ID',
)<UpsertByIdPayload<Exercise>>();
