import { EditorInput, FormActions, Input } from 'components';
import { NewQuestionFieldNames, UPLOAD_MEDIA_FIELD } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container, MediaInput, Row } from './styles';
import { Props } from './types';

const QuestionForm: FC<Props> = ({
  editId,
  filesLoading,
  handleSubmit,
  invalid,
  isCommentReply = false,
  swdTeam = false,
  isPristine,
  loading,
  onClose,
  onDelete,
  reset,
  submitFailed,
}) => (
  <Container
    onSubmit={handleSubmit}
    isCommentReply={isCommentReply}
    swdTeam={swdTeam}
  >
    <Row small={isCommentReply}>
      {!isCommentReply && (
        <Field
          component={Input}
          name={NewQuestionFieldNames.title}
          label="Your main question"
          placeholder="Type your question here"
          validate={required}
        />
      )}
      <Field
        component={EditorInput}
        name={NewQuestionFieldNames.comment}
        label={
          !isCommentReply &&
          'Additional context or comments related to your question'
        }
        validate={required}
      />
      <Field
        component={MediaInput}
        maxFiles={1}
        name={NewQuestionFieldNames[UPLOAD_MEDIA_FIELD]}
        compact={isCommentReply}
      />
    </Row>
    <FormActions
      invalid={invalid}
      loading={loading || filesLoading}
      loadingLabel={filesLoading ? 'Loading...' : 'Posting...'}
      onClose={onClose}
      onDelete={onDelete}
      pristine={isPristine}
      reset={editId ? reset : undefined}
      submitFailed={submitFailed}
      submitLabel={
        isCommentReply ? 'Reply' : editId ? 'Update question' : 'Post question'
      }
    />
  </Container>
);

export default connect(QuestionForm);
