import { epicDependencies } from 'redux/modules';
import { merge } from 'rxjs';
import { useEventCallback } from 'rxjs-hooks';
import {
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  share,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { base64toFile } from 'utils/fileToBase64';
import { uploadImage } from 'utils/uploadImage';
import { Props } from './types';

export const useAvatarUploader = ({
  onChange,
}: {
  onChange: Props['input']['onChange'];
}) => {
  const [uploadAvatar, progress] = useEventCallback<
    string | null,
    number,
    [typeof onChange]
  >(
    (event$, input$) => {
      const image$ = event$.pipe(
        filter((it): it is NonNullable<typeof it> => !!it),
      );

      return merge(
        image$.pipe(mapTo(0)),
        image$.pipe(
          mergeMap((image) => {
            const upload$ = uploadImage(
              base64toFile(image),
              epicDependencies,
            ).pipe(share());

            return merge(
              upload$.pipe(map(({ progress }) => progress * 100)),
              upload$.pipe(
                filter(({ progress }) => progress === 1),
                take(1),
                withLatestFrom(input$),
                tap(([{ url }, [onChange]]) => {
                  onChange(url);
                }),
                ignoreElements(),
              ),
            );
          }),
        ),
      );
    },
    100,
    [onChange],
  ) as [(e: string | null) => void, number];

  return { progress, uploadAvatar };
};
