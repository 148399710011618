import produce, { Draft } from 'immer';
import { Entity, UpsertByIdPayload } from 'model';
import { keysOf } from 'utils/keysOf';

export interface ById<T extends Entity> {
  [key: string]: T | undefined;
}

export function buildById<T extends Entity>(items: T[]): ById<T> {
  return items.reduce(
    (byId, item) => ({
      ...byId,
      [item.id]: item,
    }),
    {},
  );
}

export function buildBySlug<T extends Entity>(items: T[]): ById<T> {
  return items.reduce(
    (byId, item) => ({
      ...byId,
      [item.slug]: item,
    }),
    {},
  );
}

export function upsertById<T extends Entity>(
  state: { byId: ById<T>; bySlug: ById<T> },
  items: UpsertByIdPayload<T>,
): { byId: ById<T>; bySlug: ById<T> } {
  return produce(state, (next) => {
    for (const { id, ...updates } of items) {
      const itemById = next.byId[id] as T; // immer's Draft<T> wasn't working

      if (itemById) {
        for (const key of keysOf(updates)) {
          const value = updates[key];
          const nextValue =
            typeof value === 'function' ? value(itemById) : value;
          itemById[key] = nextValue;

          const itemBySlug = next.bySlug[itemById?.slug || ''] as T;

          if (itemBySlug) {
            itemBySlug[key] = nextValue;
          }
        }
      } else {
        next.byId[id] = { id, ...updates } as Draft<T>;

        if (updates.slug) {
          next.bySlug[updates.slug!] = { id, ...updates } as Draft<T>;
        }
      }
    }
  });
}
