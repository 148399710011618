import { Tabs as DefaultTabs } from 'components';
import { NotFoundIcon } from 'components/icons';
import ActivityFilter from 'containers/Common/ActivityFilter';
import styled from 'styled-components';
import { from, paragraph } from 'styles/mixins';

export const Container = styled.section``;

export const Controls = styled.div`
  display: flex;
  min-width: 80%;
  flex-grow: 1;

  ${from.tablet`
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey100};
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  `};
`;

export const Tabs = styled(DefaultTabs)`
  ${from.tablet`
    max-width: 12rem;
  `}
`;

export const ActivityFilterButton = styled(ActivityFilter)`
  width: 20%;

  ${from.tablet`
    display: none;
  `}
`;

export const NoContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 5rem;
  width: 100%;
`;

export const NoContentIcon = styled(NotFoundIcon)`
  width: 12.75rem;
  height: 7.8125rem;
`;

export const NoContentText = styled.p`
  ${paragraph};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.25rem;
  line-height: 1.5rem;
`;
