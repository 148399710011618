import { ModalRoute } from 'components';
import lazy from 'containers/Common/Lazy';
import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import Form from './SubscriptionRoot';
import AddExtraSeatsModal from './SubscriptionRoot/AddExtraSeatsModal';
import ChangePlanModal from './SubscriptionRoot/ChangePlanModal';
import ChangeSubscription from './SubscriptionRoot/ChangeSubscription';
import ReactivateModal from './SubscriptionRoot/ReactivateModal';
import { Props } from './SubscriptionRoot/types';
import UpdateAdminModal from './SubscriptionRoot/UpdateAdminModal';
import UpdateCompanyNameModal from './SubscriptionRoot/UpdateCompanyNameModal';
import SubscriptionSeats from './SubscriptionSeats';
import AddMemberModal from './SubscriptionSeats/AddMemberModal';

const UpdatePaymentMethodModal = lazy(
  () => import('./SubscriptionRoot/UpdatePaymentMethodModal'),
);

const SubscriptionRoot: FC<Props> = () => {
  const {
    path,
    getPremiumInfo,
    wentBack,
    isPremium,
    planId,
    isSWDTeam,
  } = useConnect();

  useEffect(() => {
    getPremiumInfo({ silent: wentBack });
  }, [isPremium, isSWDTeam, getPremiumInfo, planId, wentBack]);

  return (
    <>
      <Switch>
        <Route
          component={SubscriptionSeats}
          path={`${path}/subscription-seats`}
        />
        <Route
          component={ChangeSubscription}
          path={`${path}/change-subscription`}
        />
        <Route component={Form} path={`${path}/`} />
      </Switch>
      <ModalRoute component={ReactivateModal} path={`${path}/reactivate`} />
      <ModalRoute
        component={UpdatePaymentMethodModal}
        path={`${path}/update-payment-method`}
      />
      <ModalRoute component={UpdateAdminModal} path={`${path}/update-admin`} />
      <ModalRoute
        component={UpdateCompanyNameModal}
        path={`${path}/update-company-name`}
      />
      <ModalRoute
        component={AddExtraSeatsModal}
        path={`${path}/add-extra-seats`}
      />
      <ModalRoute
        component={AddMemberModal}
        path={`${path}/subscription-seats/add-member`}
      />
      <ModalRoute
        component={ChangePlanModal}
        path={`${path}/change-subscription/change-plan`}
      />
    </>
  );
};

export default SubscriptionRoot;
