import { VideoContentType } from 'components/Video/types';
import { PaginatedResponse, Video } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getProminentVideo = createAsyncAction(
  'video/GET_PROMINENT_VIDEO_REQUEST',
  'video/GET_PROMINENT_VIDEO_SUCCESS',
  'video/GET_PROMINENT_VIDEO_FAILURE',
)<undefined, Video | undefined, undefined>();

export const getVideos = createAsyncAction(
  'video/GET_VIDEOS_REQUEST',
  'video/GET_VIDEOS_SUCCESS',
  'video/GET_VIDEOS_FAILURE',
)<
  {
    search?: string;
    silent?: boolean;
    video_type?: string;
  },
  PaginatedResponse<Video>,
  undefined
>();

export const getVideo = createAsyncAction(
  'video/GET_VIDEO_REQUEST',
  'video/GET_VIDEO_SUCCESS',
  'video/GET_VIDEO_FAILURE',
)<{ slug: string }, Video, undefined>();

export const sendVideoProgress = createAsyncAction(
  'video/SEND_VIDEO_PROGRESS_REQUEST',
  'video/SEND_VIDEO_PROGRESS_SUCCESS',
  'video/SEND_VIDEO_PROGRESS_FAILURE',
)<
  { id: string; progress: number; contentType: VideoContentType },
  undefined,
  undefined
>();

export const updateVideoProgress = createAction('video/UPDATE_VIDEO_PROGRESS')<{
  id: string;
  progress: number;
  contentType: VideoContentType;
}>();
