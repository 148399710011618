import { connect } from 'react-redux';
import {
  createConversation,
  getConversations,
  selectConversations,
} from 'redux/modules/conversation';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  conversations: selectConversations(state),
  loading: state.conversation.loading,
  loggedIn: !!state.auth.user,
  pageCount: state.conversation.pageCount,
  wentBack: selectUserWentBack(state),
  tags: state.configuration.conversationTags,
});

const mapDispatchToProps = {
  createConversation: createConversation.request,
  getConversations: getConversations.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
