import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BreakpointDebugger } from 'components';
import { ConnectedRouter } from 'connected-react-router';
import GuestRoute from 'containers/Common/GuestRoute';
import Modals from 'containers/Common/Modals';
import MuiThemeProvider from 'containers/Common/MuiThemeProvider';
import Snackbar from 'containers/Common/Snackbar';
import Main from 'containers/Main';
import VerifyEmail from 'containers/Main/VerifyEmail';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore from 'redux/store';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import themes from 'styles/themes';
import connect from './connect';
import { Container, Loader } from './styles';
import { Props } from './types';

const BaseApp: FC<Props> = ({ init, theme, verify }) => {
  useEffect(() => {
    verify();
  }, [verify]);

  return (
    <StyledComponentsThemeProvider theme={themes[theme]}>
      <MuiThemeProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <BreadcrumbsProvider>
            {init ? (
              <>
                <BreakpointDebugger />
                <Switch>
                  <GuestRoute component={VerifyEmail} path="/verify-email" />

                  <Main />
                </Switch>
                <Modals />
                <Snackbar />
              </>
            ) : (
              <Container>
                <Loader />
              </Container>
            )}
          </BreadcrumbsProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </StyledComponentsThemeProvider>
  );
};

const App = connect(BaseApp);

export default ({
  history,
  store,
  persistor,
}: ReturnType<typeof configureStore>) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </PersistGate>
  </Provider>
);
