import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => `3rem ${theme.wrapperPaddingH.mobile}`};
`;
