import CTALeftAsset from 'assets/images/bannerWhite/CTALeft.svg';
import CTARightAsset from 'assets/images/bannerWhite/CTARight.svg';
import React, { FC } from 'react';
import {
  Container,
  Content,
  CTALeftImage,
  CTARightImage,
  Description,
  Title,
} from './styles';
import { Props } from './types';

const AuthJumbotron: FC<Props> = ({
  children,
  mainTitle = 'Welcome to the SWD community!',
  ...rest
}) => (
  <Container {...rest}>
    <Content>
      <CTALeftImage alt="" aria-hidden="true" src={CTALeftAsset} />
      <CTARightImage alt="" aria-hidden="true" src={CTARightAsset} />
      <Title>{mainTitle}</Title>
      <Description>{children}</Description>
    </Content>
  </Container>
);

export default AuthJumbotron;
