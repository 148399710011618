import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Circle, Picture, Plus, Sheet } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <Picture d="M4.5 6C3.673 6 3 5.327 3 4.5S3.673 3 4.5 3 6 3.673 6 4.5 5.327 6 4.5 6zm0-2.4a.901.901 0 0 0 0 1.8.901.901 0 0 0 0-1.8zM2.348 12a.348.348 0 0 1-.304-.18.353.353 0 0 1 .009-.356l2.1-3.382a.692.692 0 0 1 1.182 0l.455.731L7.333 6.33a.693.693 0 0 1 1.181 0l.433.696a.353.353 0 0 1-.11.483.345.345 0 0 1-.48-.112l-.432-.695-1.84 2.96a.347.347 0 0 1-.591 0l-.75-1.207L2.977 11.3h2.856a.35.35 0 0 1 .349.35.35.35 0 0 1-.349.351H2.348z" />
    <Sheet d="M.975 16A.989.989 0 0 1 0 15V1c0-.551.437-1 .975-1h8.64c.261 0 .505.104.69.293l2.41 2.471c.184.19.285.44.285.707v2.862a.33.33 0 0 1-.325.334.33.33 0 0 1-.325-.334V3.471a.338.338 0 0 0-.095-.236L9.845.764a.324.324 0 0 0-.23-.097H.975A.33.33 0 0 0 .65 1v14a.33.33 0 0 0 .325.333h5.85c.18 0 .325.15.325.334a.33.33 0 0 1-.325.333H.975z" />
    <Circle d="M11.5 16A4.505 4.505 0 0 1 7 11.5C7 9.019 9.019 7 11.5 7S16 9.019 16 11.5 13.981 16 11.5 16zm0-8.308A3.812 3.812 0 0 0 7.692 11.5c0 2.1 1.708 3.808 3.808 3.808 2.1 0 3.808-1.708 3.808-3.808 0-2.1-1.708-3.808-3.808-3.808z" />
    <Plus d="M11.5 14a.357.357 0 0 1-.357-.357v-1.786H9.357a.357.357 0 0 1 0-.714h1.786V9.357a.357.357 0 0 1 .714 0v1.786h1.786a.357.357 0 0 1 0 .714h-1.786v1.786c0 .197-.16.357-.357.357z" />
  </svg>
));
