import { ActivityCard as DefaultActivityCard, Pagination } from 'components';
import { ConfusedShrugIcon } from 'components/icons';
import ActivityFilter from 'containers/Common/ActivityFilter';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  ${container};
  padding: 2.5rem 1rem;
  display: flex;
  flex-wrap: wrap;
`;

export const ActivityCard = styled(DefaultActivityCard)``;

export const ActivityList = styled.ul`
  list-style: none;
  max-width: 54.4rem;
  flex: 1;
  ${ActivityCard}:not (:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

export const ActivityFilters = styled(ActivityFilter)`
  display: none;
  ${from.tablet`
    display: block;
    margin-left: 1.8rem;
    min-width: 11.875rem;
    height: fit-content;
    border-radius: 0.25rem;
    border: 1px solid ${({ theme }) => theme.colors.grey200};
    padding: ${({ theme }) => theme.spacing.lg};
  `}
  ${from.laptop`
    min-width: 16.875rem;
  `}
`;

export const NoActivityContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  text-align: center;
`;

export const ErrorIcon = styled(ConfusedShrugIcon)`
  width: 15.625rem;
`;

export const NoActivityTitle = styled.h2`
  font-size: 1.75rem;
  margin: 2rem 0 0.75rem 0;
`;

export const NoActivitySubtitle = styled.p`
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
`;

export const ActivityPagination = styled(Pagination)`
  width: 100%;
`;
