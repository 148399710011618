import { EditorState } from 'components/Editor/types';
import { FormNameMap } from 'utils/forms';
import { CommentForm } from '../Comment';
import { UPLOAD_MEDIA_FIELD } from '../utils';

export interface QuestionForm extends Omit<CommentForm, 'comment'> {
  title: string;
  comment: EditorState;
}

export const NewQuestionFieldNames: FormNameMap<QuestionForm> = {
  [UPLOAD_MEDIA_FIELD]: UPLOAD_MEDIA_FIELD,
  comment: 'comment',
  title: 'title',
};
