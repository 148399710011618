import { Statuses } from 'model';
import { DeepReadonly } from 'utility-types';
import { dateHasPassed, isExpiringSoon } from 'utils/dates';
import { FeedbackRequest, FeedbackRequestExpanded } from '.';

export const contractFeedbackRequest = ({
  ...rest
}: FeedbackRequestExpanded): FeedbackRequest => ({
  ...rest,
});

export function getFeedbackRequestStatusList(
  feedbackRequest: DeepReadonly<FeedbackRequest>,
): string[] {
  const statusList = [];
  if (
    !feedbackRequest.isSolved &&
    !!feedbackRequest.expiryDate &&
    dateHasPassed(feedbackRequest.expiryDate || '')
  ) {
    statusList.push(Statuses.CLOSED);
  }

  if (
    !statusList.includes(Statuses.CLOSED) &&
    !feedbackRequest.isSolved &&
    (isExpiringSoon(feedbackRequest.expiryDate) ||
      feedbackRequest.isAboutToExpire)
  ) {
    statusList.push(Statuses.EXPIRES_SOON);
  }

  if (feedbackRequest.isSolved) {
    statusList.push(Statuses.SOLVED);
  }

  if (feedbackRequest.swdTeam) {
    statusList.push(Statuses.SWD_TEAM);
  }

  return statusList;
}

export const isFeedbackRequestClosed = ({
  expiryDate,
}: {
  expiryDate?: FeedbackRequest['expiryDate'];
}) => !!expiryDate && dateHasPassed(expiryDate || '');
