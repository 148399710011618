import { AuthJumbotron, Loader, PageTitle } from 'components';
import LoginFragment from 'containers/Common/LoginFragment';
import React, { FC } from 'react';
import connect, { useConnect } from './connect';
import { Container, Content, Loading } from './styles';
import { Props } from './types';

const Login: FC<Props> = ({ saveToken }) => {
  const { token } = useConnect({ saveToken });

  if (token) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  return (
    <Container>
      <PageTitle title="Log in" />
      <AuthJumbotron>
        Enter your email and password to log in. If it’s your first time here,
        take a moment to create your account.
      </AuthJumbotron>
      <Content>
        <LoginFragment />
      </Content>
    </Container>
  );
};

export default connect(Login);
