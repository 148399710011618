import { connect } from 'react-redux';
import { closeModal, selectReportModalData } from 'redux/modules/modal';
import { report } from 'redux/modules/report';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => {
  const data = selectReportModalData(state) || {
    contentType: null,
    objectId: null,
  };

  return {
    contentType: data.contentType,
    objectId: data.objectId,
  };
};

const mapDispatchToProps = {
  closeModal,
  report: report.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
