import DefaultButton from 'components/Button';
import styled from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  position: relative;
  height: 22.75rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${from.mobileXL`
    height: 31.75rem;
  `}
`;

export const Image = styled.img`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    ${({ theme }) => addAlpha(theme.colors.primary, 0.3)} 0%,
    ${({ theme }) => theme.colors.primary}
  );
`;

export const Title = styled.h2`
  margin: 0;
  text-align: center;
  width: 21.875rem;
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
`;

export const Button = styled(DefaultButton)`
  position: relative;
`;
