import styled from 'styled-components';
import { from } from '../../styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;
  position: relative;
`;

export const Content = styled.div`
  align-items: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 32rem;
  padding: 5rem 1rem;
  text-align: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
  max-width: 22.8rem;
`;

export const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 0;
`;

export const CTALeftImage = styled.img`
  bottom: -4rem;
  height: 50%;
  left: -5rem;
  position: absolute;

  ${from.mobileXL`
    height: 80%;
  `};
`;

export const CTARightImage = styled.img`
  height: 45%;
  position: absolute;
  right: -0.5rem;
  top: -1.5rem;

  ${from.mobileXL`
    height: 80%;
    top: -3.5rem;
  `};
`;
