import React, { FC } from 'react';
import { ZIndex } from 'styles/metrics';
import animations from './animations';
import { CloseButtonContainer, CloseIcon, Content, Root } from './styles';
import { Props } from './types';

const Modal: FC<Props> = ({
  animation = 'slideUp',
  children,
  fullScreen,
  onClose,
  open,
  ...rest
}) => (
  <Root
    TransitionComponent={animations[animation]}
    closeAfterTransition={true}
    fullScreen={fullScreen}
    onClose={onClose}
    open={open}
    style={{
      zIndex: ZIndex.MODAL,
    }}
    {...rest}
  >
    <CloseButtonContainer
      aria-label="Close"
      fullScreen={!!fullScreen}
      onClick={onClose}
    >
      <CloseIcon />
    </CloseButtonContainer>
    <Content>{children}</Content>
  </Root>
);

export default Modal;
