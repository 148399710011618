import styled from 'styled-components';

export const Container = styled.form`
  max-width: 26rem;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
