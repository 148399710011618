import { notNullable } from 'utils/notNullable';

export const API_BASE_URL = notNullable(process.env.REACT_APP_API_URL);
export const MAPBOX_ACCESS_TOKEN = notNullable(
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
);

export const SPECIAL_OFFER_ACTIVE =
  process.env.REACT_APP_SPECIAL_OFFER_ACTIVE?.toLowerCase() === 'true';
export const SPECIAL_OFFER_ANNUAL_ACTIVE =
  process.env.REACT_APP_SPECIAL_OFFER_ANNUAL_ACTIVE?.toLowerCase() === 'true';

export const CONFIGURATION_CACHE_DURATION_MINUTES = 15;
export const GUEST_HOME_CACHE_DURATION_MINUTES = 15;

export const DISCOVER_PER_PAGE = 9;
export const FEEDBACK_PER_PAGE = 11;
export const CONVERSATIONS_PER_PAGE = 10;
export const CHALLENGES_PER_PAGE = 4;
export const CHALLENGE_RESPONSES_PER_PAGE = 12;
export const USERS_PER_PAGE = 30;
export const USERS_ACTIVITY_PER_PAGE = 10;
export const EXERCISES_PER_PAGE = 4;
export const EXERCISE_RESPONSES_PER_PAGE = 12;
export const ACTIVITY_PER_PAGE = 20;
export const VIDEOS_PER_PAGE = 6;
export const EVENTS_PER_PAGE = 6;

export const COMMENTS_MAX_DEPTH = 2;

export const SEARCH_INPUT_DEBOUNCE_TIME_MS = 300;
export const VIDEO_TRACK_INTERVAL = 5000;

export const SEARCH_BLOG_PER_PAGE_MASTER = 3;
export const SEARCH_BLOG_PER_PAGE_DETAIL = 12;
export const SEARCH_BOOKS_PER_PAGE_MASTER = 3;
export const SEARCH_BOOKS_PER_PAGE_DETAIL = 12;
export const SEARCH_CHALLENGE_PER_PAGE_MASTER = 2;
export const SEARCH_CHALLENGE_PER_PAGE_DETAIL = 8;
export const SEARCH_CHALLENGE_RESPONSE_PER_PAGE_MASTER = 3;
export const SEARCH_CHALLENGE_RESPONSE_PER_PAGE_DETAIL = 12;
export const SEARCH_CONVERSATIONS_PER_PAGE_MASTER = 3;
export const SEARCH_CONVERSATIONS_PER_PAGE_DETAIL = 12;
export const SEARCH_EXERCISE_PER_PAGE_MASTER = 2;
export const SEARCH_EXERCISE_PER_PAGE_DETAIL = 8;
export const SEARCH_FEEDBACK_PER_PAGE_MASTER = 3;
export const SEARCH_FEEDBACK_PER_PAGE_DETAIL = 12;
export const SEARCH_PODCAST_PER_PAGE_MASTER = 3;
export const SEARCH_PODCAST_PER_PAGE_DETAIL = 12;
export const SEARCH_VISUALS_PER_PAGE_MASTER = 3;
export const SEARCH_VISUALS_PER_PAGE_DETAIL = 12;
export const SEARCH_VIDEOS_PER_PAGE_MASTER = 3;
export const SEARCH_VIDEOS_PER_PAGE_DETAIL = 12;

export const isDev = process.env.NODE_ENV === 'development';
export const isProd = process.env.NODE_ENV === 'production';
export const isTest = process.env.NODE_ENV === 'test';

export const breakpointDebuggerEnabled = !!process.env
  .REACT_APP_BREAKPOINT_DEBUGGER;
