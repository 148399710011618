import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form``;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
`;

export const TextHeader = styled.h3`
  font-size: 20px;
  margin: 0 0 8px 0;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 4px 0;
`;

export const Info = styled.p`
  font-size: 14px;
  line-height: 1.71rem;
  margin: 0;
`;

export const Input = styled.div`
  width: 100%;

  ${from.mobileXL`
    width: 70%;
  `};

  ${from.tabletH`
    width: 247px;
  `};
`;

export const Content = styled.div`
  padding: 2.5rem 1.875rem;

  ${from.tablet`
    padding: 2.5rem 3.75rem;
  `};
`;

export { TitleSection } from '../styles';
