import { Button } from 'components';
import { Container as DefaultInput } from 'components/BaseInput/styles';
import styled, { css } from 'styled-components';

export const Container = styled.form`
  width: 100%;
  ${DefaultInput} {
    height: 2.75rem;
    width: 77px;
    margin-left: auto;
    margin-bottom: 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }

    > :first-child {
      height: 100%;
      padding-right: 0.75rem;
      text-align: right;
    }
  }
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: auto 5.3rem;
  grid-column-gap: 0.75rem;
  margin-bottom: 2rem;
`;

export const TextContainer = styled.div``;

export const Title = styled.h3`
  margin: 0 0 0.3125rem;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.0625rem;
`;

export const Note = styled.p`
  margin: 0 0 0.5rem;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  span {
    font-weight: ${({ theme }) => theme.weights.semiBold};
  }
`;

export const Amount = styled.p`
  margin: 0;
  text-align: right;
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const SubmitButton = styled(Button)<{ $disabled: boolean }>`
  width: 100%;
  margin-top: 0.75rem;
  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.sky};
      pointer-events: none;
    `}
`;
