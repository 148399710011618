import {
  Button as DefaultButtonPrimary,
  FeaturedLink as DefaultFeaturedLink,
  SectionTitle as DefaultSectionTitle,
} from 'components';
import ProtectedButton from 'containers/Common/ProtectedButton';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
`;

export const Content = styled.div`
  ${container};

  padding: 1rem ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 2.5rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.colors.accent};
  cursor: pointer;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const HelpSection = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  margin-top: 3.75rem;
`;

export const Help = styled.div`
  ${container};

  padding: 5rem 0;

  p {
    line-height: 1.75rem;
  }

  ${from.mobileXL`
    padding: 5rem 0;
  `};
`;

export const HelpTitle = styled(DefaultSectionTitle)``;

export const HelpText = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
`;

export const InfoCard = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.primary, 0.3)};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2rem 2.75rem;
`;

export const InfoCardImage = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  object-fit: cover;
  margin-bottom: 2rem;
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  text-decoration: none;
`;

export const InfoCardButton = styled(DefaultButtonPrimary)`
  height: 48px;
`;

export const FeaturedLink = styled(DefaultFeaturedLink)`
  margin: 3.75rem 0;
`;

export const HeadingRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Heading = styled.div`
  margin-right: ${({ theme }) => theme.spacing.xlg};
`;

export const Paragraph = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  letter-spacing: 0;
  margin-bottom: 1.875rem;
`;

export const AskFeedbackButton = styled(ProtectedButton)`
  flex: 1;
  white-space: nowrap;
`;
