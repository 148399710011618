import { Reducer } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { EXERCISE_RESPONSES_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import {
  createExerciseResponse,
  deleteExerciseResponse,
  editExerciseResponse,
  getExerciseResponse,
  getExerciseResponses,
  _upsertExerciseResponsesById,
} from './actions';
import { ExerciseResponseAction, ExerciseResponseState } from './types';

export const INITIAL_STATE: ExerciseResponseState = {
  ...defaultStandardState,
};

const reducer: Reducer<ExerciseResponseState, ExerciseResponseAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    create: {
      request: isActionOf(createExerciseResponse.request),
      success: isActionOf(createExerciseResponse.success),
      failure: isActionOf(createExerciseResponse.failure),
    },
    edit: {
      request: isActionOf(editExerciseResponse.request),
      success: isActionOf(editExerciseResponse.success),
      failure: isActionOf(editExerciseResponse.failure),
    },
    getMany: {
      request: isActionOf(getExerciseResponses.request),
      success: isActionOf(getExerciseResponses.success),
      failure: isActionOf(getExerciseResponses.failure),
    },
    getOne: {
      request: isActionOf(getExerciseResponse.request),
      success: isActionOf(getExerciseResponse.success),
      failure: isActionOf(getExerciseResponse.failure),
    },
    remove: {
      request: isActionOf(deleteExerciseResponse.request),
      success: isActionOf(deleteExerciseResponse.success),
      failure: isActionOf(deleteExerciseResponse.failure),
    },
    _upsertById: isActionOf(_upsertExerciseResponsesById),
  },
  contractItem: (payload) => payload,
  initialState: INITIAL_STATE,
  perPage: EXERCISE_RESPONSES_PER_PAGE,
})(reducer);
