import React, { FC, useMemo } from 'react';
import { deserialize as deserializeElement } from 'react-serialize';
import connect from './connect';
import { useAcceptHandler } from './logic';
import { Actions, Button, Description, Modal, Title } from './styles';
import { Props } from './types';

const useSerializedElement = (serialized: string | undefined) =>
  useMemo(() => (serialized ? deserializeElement(serialized) : null), [
    serialized,
  ]);

const ConfirmModal: FC<Props> = ({ closeModal, data }) => {
  const title = useSerializedElement(data?.title);
  const description = useSerializedElement(data?.description);

  const onAccept = useAcceptHandler({ closeModal, data });

  return (
    <Modal open={!!data} onClose={closeModal}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Actions>
        <Button onClick={onAccept} variant={data?.acceptButtonVariant}>
          {data?.acceptButtonLabel}
        </Button>
        <Button onClick={closeModal} variant="secondary">
          {data?.cancelButtonLabel}
        </Button>
      </Actions>
    </Modal>
  );
};

export default connect(ConfirmModal);
