import React, { FC } from 'react';
import { Container, Content, Description, StrongText, Title } from './styles';
import { Props } from './types';

const Fold: FC<Props> = ({ title, description, label, children, ...rest }) => (
  <Container {...rest}>
    <Content>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {label && <StrongText>{label}</StrongText>}
      {children}
    </Content>
  </Container>
);

export default Fold;
