import { AppContentType } from 'model';
import { createAsyncAction } from 'typesafe-actions';

export const report = createAsyncAction(
  'report/REPORT_REQUEST',
  'report/REPORT_SUCCESS',
  'report/REPORT_FAILURE',
)<
  {
    comment?: string;
    contentType: AppContentType;
    objectId: string;
    selectedOption: string;
  },
  undefined,
  undefined
>();
