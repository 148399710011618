import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const commonStyles = css`
  text-decoration: none;
`;

export const ExternalLink = styled.a`
  ${commonStyles};
`;

export const InternalLink = styled(DefaultLink)`
  ${commonStyles};
`;
