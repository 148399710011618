import { FormNameMap } from 'utils/forms';

export const CHANGE_PASSWORD_FORM_KEY = 'forms/CHANGE_PASSWORD';

export interface ChangePasswordForm {
  oldPassword: string;
  password: string;
  passwordConfirm: string;
}

export const ChangePasswordFieldNames: FormNameMap<ChangePasswordForm> = {
  oldPassword: 'oldPassword',
  password: 'password',
  passwordConfirm: 'passwordConfirm',
};

export * from './logic';
