import { oneLine } from 'common-tags';
import { LocationDescriptor } from 'history';
import clamp from 'lodash/clamp';
import range from 'lodash/range';
import { parse, stringify } from 'query-string';
import React, { Dispatch, FC, memo, SetStateAction, useCallback } from 'react';
import Ellipsis from './Ellipsis';
import Page from './Page';
import { ArrowLeft, ArrowRight, Container, Link } from './styles';
import { Props } from './types';

// 2 3 4 [5] 6 7 8 <--- MAX_PAGES = 7
//        A
//        └----- DEFAULT_ACTIVE_POSITION = 3

const MAX_PAGES = 5;
const DEFAULT_ACTIVE_POSITION = 2;

const noop: Dispatch<SetStateAction<number>> = () => {};

export const Pagination: FC<Props> = ({
  contentRef,
  current,
  hash,
  search,
  setPage = noop,
  showShortcuts = true,
  state,
  total,
  url,
  ...rest
}) => {
  const scrollToContent = useCallback(() => {
    contentRef?.current?.scrollIntoView({
      block: 'start',
    });
  }, [contentRef]);

  const goToPrevPage = useCallback(() => {
    scrollToContent();
    setPage((page) => page - 1);
  }, [scrollToContent, setPage]);

  const goToNextPage = useCallback(() => {
    scrollToContent();
    setPage((page) => page + 1);
  }, [scrollToContent, setPage]);

  if (current > total) {
    throw new RangeError(oneLine`Pagination: the current page (${current}) is
    higher than the total (${total}). This is an invalid state.`);
  }

  if (total < 2) {
    return null;
  }

  const buildPageDestination = (page: number): LocationDescriptor => {
    const { page: prevPage, ...currentQuery } = search
      ? parse(search)
      : {
          page: undefined,
        };

    return {
      hash,
      pathname: url,
      search: stringify({
        ...currentQuery,
        ...(page === 1 ? {} : { page }),
      }),
      state: {
        ...state,
        scrollToTop: false,
      },
    };
  };

  // The number of pages that will be shown. This is typically MAX_PAGES, unless
  // there are fewer, in which case we show every page.
  const pagesToShow = Math.min(MAX_PAGES, total);

  // The number of pages that will be shown after DEFAULT_ACTIVE_POSITION
  // For the example values that would be 3:
  // 2 3 4 [5] 6 7 8 --> 6, 7, 8
  const pagesAfterDefaultPosition = pagesToShow - DEFAULT_ACTIVE_POSITION - 1;

  // The number of pages until we reach the end. 0 means the current page is the
  // last page.
  const pagesToEnd = total - current;

  // The number of pages we need to bring the default position forward.
  // This happens whenever we are getting close to the end. For the example
  // values, assuming that the total is 8, it would vary depending on the
  // current page:
  //
  // representation       | current | total | pagesForward
  // 2  3  4 [5] 6  7  8  |    5    |   8   |      0
  // 2  3  4  5 [6] 7  8  |    6    |   8   |      1
  // 2  3  4  5  6 [7] 8  |    7    |   8   |      2
  // 2  3  4  5  6  7 [8] |    8    |   8   |      3
  const pagesForward =
    pagesAfterDefaultPosition - clamp(pagesToEnd, 0, pagesAfterDefaultPosition);

  // The position where we will show the current page. This is the
  // DEFAULT_ACTIVE_POSITION, unless we're getting close to the end and need to
  // bring the position forward.
  const showCurrentAt = DEFAULT_ACTIVE_POSITION + pagesForward;

  // The value we will show at the leftmost page.
  // For the example values that would be 2:
  // 2 3 4 [5] 6 7 8
  const initial = Math.max(current - showCurrentAt, 1);

  // The value we will show at the rightmost page.
  // For the example values that would be 8:
  // 2 3 4 [5] 6 7 8
  const last = Math.min(initial + MAX_PAGES - 1, total);

  const showFirstShortcut = showShortcuts && initial > 1;
  const showFirstShortcutEllipsis = initial > 2;

  const showLastShortcut = showShortcuts && last < total;
  const showLastShortcutEllipsis = total - last > 1;

  const pages = range(pagesToShow).map((i) => {
    const page = i + initial;

    return (
      <Page
        active={current === page}
        key={`page-${page}`}
        page={page}
        scrollToContent={scrollToContent}
        setPage={setPage}
        url={url && buildPageDestination(page)}
      />
    );
  });

  return (
    <Container {...rest} aria-label="pagination">
      <Link
        $active={false}
        $disabled={current === initial}
        key="last-page"
        onClick={goToPrevPage}
        {...(url ? { to: buildPageDestination(current - 1) } : {})}
        aria-label="previous page"
      >
        <ArrowLeft />
      </Link>
      {showFirstShortcut && (
        <>
          <Page
            active={current === 1}
            key={`page-${1}`}
            page={1}
            scrollToContent={scrollToContent}
            setPage={setPage}
            url={url && buildPageDestination(1)}
          />
          {showFirstShortcutEllipsis && (
            <Ellipsis key="first-shortcut-ellipsis" />
          )}
        </>
      )}
      {pages}
      {showLastShortcut && (
        <>
          {showLastShortcutEllipsis && (
            <Ellipsis key="last-shortcut-ellipsis" />
          )}
          <Page
            active={current === total}
            key={`page-${total}`}
            page={total}
            scrollToContent={scrollToContent}
            setPage={setPage}
            url={url && buildPageDestination(total)}
          />
        </>
      )}
      <Link
        $active={false}
        $disabled={current === total}
        key="next-page"
        onClick={goToNextPage}
        {...(url ? { to: buildPageDestination(current + 1) } : {})}
        aria-label="next page"
      >
        <ArrowRight />
      </Link>
    </Container>
  );
};

export default memo(Pagination);
