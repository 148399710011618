import DefaultButton from 'components/Button';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary};
  margin-top: 5rem;
  padding: 2.5rem;
  border-radius: 0.25rem;
  border-left: 0.625rem solid ${({ theme }) => theme.colors.basicGold};

  ${from.tabletH`
   flex-direction: row;
   align-items: center;
`}

  ${from.laptop`
      padding: 3.75rem;
  `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;

  margin: 0;

  span {
    white-space: nowrap;
  }

  @media (min-width: 66.375rem) {
    font-size: 2rem;
    line-height: 3rem;
  }
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.875rem;
  margin: 0.563rem 0 0 0;
`;

export const TextContent = styled.div`
  ${from.tabletH`
    margin-right: 3.75rem;
  `}
`;

export const OVERVIEW_IMAGE_WIDTH = '26.5rem';

export const OverviewImage = styled.img`
  display: none;
  height: 12.813rem;
  min-width: ${OVERVIEW_IMAGE_WIDTH};

  ${from.tabletH`
    display: initial;
  `}
`;

export const Button = styled(DefaultButton)``;
