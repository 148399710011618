import {
  FacebookIcon as DefaultFacebookIcon,
  InstagramIcon as DefaultInstagramIcon,
  LinkedInIcon as DefaultLinkedInIcon,
  RSSIcon as DefaultRSSIcon,
  TwitterIcon as DefaultTwitterIcon,
} from 'components/icons';
import DefaultExternalLink from 'components/Link';
import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { buttonReset, container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.footer``;

export const NavigationFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey200};
`;

export const NavigationFooter = styled.div`
  ${container};
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 2.75rem ${({ theme }) => theme.spacing.lg};

  ${from.mobileXL`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`}

  ${from.tablet`
    flex-wrap: nowrap;
    padding: 2.75rem ${({ theme }) => theme.wrapperPaddingH.desktop};
`}
`;

export const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  margin-right: ${({ theme }) => theme.spacing.md};

  ${from.mobileXL`
    margin-right: ${({ theme }) => theme.spacing.md};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
`}

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

export const ListHeader = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 0.75rem;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.44px;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing.md} !important;
`;

const linkStyles = css`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
  text-decoration: none;
  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const NavigationOuterLink = styled.a`
  ${linkStyles};
`;

export const NavigationLink = styled(DefaultLink)`
  ${linkStyles};
`;

export const Contact = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  grid-column: 1/3;

  ${from.tablet`
    margin-top: -${({ theme }) => theme.spacing.md};
    max-width: 16rem;
  `}
`;

export const ContactText = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0.875rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const ContactButton = styled(DefaultExternalLink)`
  ${buttonReset};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  display: inline-flex;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 0.875rem;
  justify-content: center;
  line-height: 1;
  letter-spacing: 1px;
  padding: 0.8125rem 1.25rem;
  text-transform: uppercase;

  :active {
    opacity: 0.8;
  }

  :disabled {
    background-color: ${({ theme }) => theme.colors.sky};
    cursor: initial;
  }

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.primaryHover};
    box-shadow: 0 2px 15px -3px ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
  }
`;

export const LegalFooterWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const LegalFooter = styled.div`
  ${container};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) =>
    `${theme.spacing.lg} ${theme.wrapperPaddingH.mobile}`};

  ${from.mobileXL`
    padding: ${({ theme }) =>
      `${theme.spacing.lg} ${theme.wrapperPaddingH.desktop}`};
       flex-direction: row;
  `};
`;

export const LegalText = styled.div`
  font-size: 1rem;
  display: flex;

  justify-content: center;

  *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xlg};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }

  ${from.mobileXL`
    display: inline-block;
    justify-content: flex-start;

    *:not(:last-child) {
      margin-bottom: 0;
    }
  `};
`;

export const LegalLink = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
`;

export const SocialLinks = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${({ theme }) => theme.spacing.xlg};

  *:not(:last-child) {
    margin-right: 1.875rem;
  }

  ${from.mobileXL`
    margin-top: 0;
    margin-right: 0;
  `};
`;

export const SocialLink = styled(DefaultExternalLink)`
  color: ${({ theme }) => theme.colors.white};
`;

export const TwitterIcon = styled(DefaultTwitterIcon)`
  height: 1rem;
`;

export const FacebookIcon = styled(DefaultFacebookIcon)`
  height: 1rem;
`;

export const LinkedInIcon = styled(DefaultLinkedInIcon)`
  height: 1rem;
`;

export const InstagramIcon = styled(DefaultInstagramIcon)`
  height: 1rem;
`;

export const RSSIcon = styled(DefaultRSSIcon)`
  height: 1rem;
`;
