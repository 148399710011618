import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    {children}
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
      d="M12 17V7M7 12h10"
    />
  </svg>
));
