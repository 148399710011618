import AvatarComponent from 'components/Avatar';
import DefaultDropdown from 'components/Dropdown';
import { InternalLink } from 'components/Dropdown/Item/styles';
import DefaultDropdownMenu from 'components/DropdownMenu';
import { Label as DropdownMenuLabel } from 'components/DropdownMenu/styles';
import {
  UserIcon as DefaultUserIcon,
  UsersIcon as DefaultUsersIcon,
} from 'components/icons';
import DefaultLink from 'components/Link';
import { Container as LiveTag, LiveIndicator } from 'components/LiveTag/styles';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import {
  burgerLink,
  buttonReset,
  container,
  from,
  menuLink,
} from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { randomId } from 'utils/randomId';

export const Container = styled.nav``;

export const MainHeader = styled.div`
  ${container};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.9rem 2.5rem;

  ${from.mobileXL`
    padding: 0.875rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};

  ${from.tabletH`
    display: flex;
  `};
`;

export const MainTitle = styled.a`
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.md};
`;

export const Links = styled.nav`
  display: flex;
  flex-wrap: wrap;

  ${from.tablet`
    margin-left: auto;
  `};

  > span {
    margin-right: 1.6rem;

    ${from.laptop`
      margin-right: 2.5rem;
    `}
  }

  > a {
    margin-right: 1.6rem;

    ${from.laptop`
      margin-right: 2.5rem;
    `}
  }

  > :last-child {
    margin-right: 1.5rem;
  }
`;

export const DropdownUserMenu = styled(DefaultDropdown)`
  div:not(:first-child) {
    transform: translateX(-11.25rem) translateY(-0.625rem);
    width: 12.25rem;
  }

  ul {
    border: 0.0625rem solid ${({ theme }) => theme.colors.grey300};
  }
`;

export const CommunityHeaderLink = css`
  font-size: 1rem;
  text-transform: capitalize;
  letter-spacing: normal;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.grey400};
  ${from.tabletH`
    font-size: 0.875rem;
  `}
`;

export const CommunityDropdownMenu = styled(DefaultDropdownMenu)`
  ${DropdownMenuLabel} {
    ${CommunityHeaderLink}
  }

  ${InternalLink} {
    text-transform: initial;

    &::first-letter {
      text-transform: lowercase;
    }

    span::first-letter {
      text-transform: lowercase;
    }
  }
`;

const communityLinkActive = `active-${randomId()}`;

export const CommunityLink = styled(NavLink).attrs({
  activeClassName: communityLinkActive,
})`
  ${menuLink};
  ${CommunityHeaderLink}

  &.${communityLinkActive} {
    color: ${({ theme }) => theme.colors.accent};

    :hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const CommunityPremiumLink = styled(CommunityLink)`
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => theme.colors.darkPrimary};
  }

  &.${communityLinkActive} {
    color: ${({ theme }) => theme.colors.accent};

    :hover {
      color: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`;

export const CommunityHeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  display: none;
  ${from.tabletH`
    display: block;
  `};
`;

export const CommunityHeader = styled.div`
  ${container};
  align-items: center;
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.9rem 2.5rem;
  ${from.mobileXL`
    display: flex;
    padding: 0.875rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const LogoCommunityLink = styled(DefaultLink)``;

export const LogoCommunityIcon = styled.img`
  width: 10rem;
`;

export const MembersBox = styled(DefaultLink)`
  background-color: ${({ theme }) => theme.colors.white};
  border: solid 0.0625rem ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-content: center;
  line-height: 1;
  letter-spacing: 0.0625rem;
  padding: 0.563rem 0;
  text-transform: uppercase;
  width: 7.25rem;

  :hover:not(:disabled) {
    box-shadow: 0 0.125rem 0.5rem -0.1875rem ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const UsersIcon = styled(DefaultUsersIcon)`
  width: 1rem;
  height: 100%;
`;

export const MembersLabel = styled.span`
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  margin-left: ${({ theme }) => theme.spacing.sm};
  text-transform: uppercase;
`;

export const LoginBox = styled(DefaultLink)`
  align-items: center;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  justify-content: center;
  line-height: 1;
  letter-spacing: 0.0625rem;
  text-transform: uppercase;
  margin-left: ${({ theme }) => theme.spacing.md};
  transition: color 0.5s ease;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const UserIcon = styled(DefaultUserIcon)`
  width: 1rem;
  height: 100%;
`;

export const LoginLabel = styled.span`
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: 1rem;
  margin-left: ${({ theme }) => theme.spacing.sm};
  text-transform: uppercase;
  padding-top: 0.0625rem;
`;

export const Avatar = styled(AvatarComponent)`
  border: 0.0625rem solid ${({ theme }) => theme.colors.greyBlue};
  height: 2rem;
  width: 2rem;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey300};
  margin-bottom: 0;
  margin-right: 0;

  :hover {
    box-shadow: 0 0.125rem 0.5rem -0.1875rem ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
    border-color: ${({ theme }) => theme.colors.accent};
  }
`;

export const AvatarWrapper = styled.div`
  padding-top: 1.5rem;
`;

export const BurgerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BurgerMenu = styled.nav`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${({ theme }) =>
    `${theme.spacing.md} ${theme.wrapperPaddingH.mobile}`};

  ${from.mobileXL`
    padding: ${({ theme }) =>
      `${theme.spacing.md} ${theme.wrapperPaddingH.desktop}`};
  `};

  ${from.tabletH`
    display: none;
  `};
`;

export const Burger = styled.button<{ mobileMenu: boolean }>`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 1.5rem;
  justify-content: space-around;
  padding: 0;
  width: 1.5rem;

  &:focus {
    outline: none;
  }

  div {
    background: ${({ theme }) => theme.colors.accent};
    border-radius: 0.625rem;
    height: 0.1875rem;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 0.0625rem;
    width: 1.5rem;

    :first-child {
      transform: rotate(0);
    }

    :nth-child(2) {
      opacity: 1;
      transform: translateX(0);
    }

    :nth-child(3) {
      transform: rotate(0);
    }

    ${({ mobileMenu }) =>
      mobileMenu &&
      css`
        :first-child {
          transform: rotate(45deg);
        }

        :nth-child(2) {
          opacity: 0;
          transform: translateX(1.25rem);
        }

        :nth-child(3) {
          transform: rotate(-45deg);
        }
      `}
  }
`;

export const BurgerCommunity = styled(LogoCommunityLink)``;

export const SectionLinks = styled.div<{ isLogged: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ isLogged }) =>
    isLogged &&
    css`
      border-bottom: solid 1px ${({ theme }) => theme.colors.grey200};
      padding-bottom: ${({ theme }) => theme.spacing.md};
    `}
`;

export const OneLink = styled.div`
  border-bottom: solid 0.0625rem ${({ theme }) => theme.colors.grey200};
  padding: 0 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LastLink = styled.div`
  padding: 1rem 0;
`;

export const BurgerLinks = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: block;
  position: absolute;
  top: 3.5rem;
  left: 0;
  right: 0;
  z-index: ${ZIndex.MOBILE_MENU};
  height: calc(100vh - 9rem);
  overflow-y: auto;
  padding: ${({ theme }) => `3rem ${theme.wrapperPaddingH.mobile}`};
  text-align: center;

  ${from.mobileXL`
  padding: ${({ theme }) => `3rem ${theme.wrapperPaddingH.desktop}`};
`};

  ${from.tabletH`
    display: none;
  `};
`;

export const BurgerLink = styled(CommunityLink)`
  font-weight: bold;
  ${burgerLink};
  margin: 0;
`;

export const BurgerPremiumLink = styled(BurgerLink)`
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => theme.colors.darkPrimary};
  }
`;

export const BurgerButton = styled.button`
  ${buttonReset};
  ${burgerLink};
  ${menuLink}
`;

export const Link = styled(DefaultLink)`
  ${burgerLink};
  line-height: 2;
  margin: 1rem 0;
`;

export const Label = styled.span`
  font-weight: bold;
  ${menuLink};
`;

export const UserName = styled.div`
  padding-top: 1rem;
  ${Label} {
    ${burgerLink};
    font-weight: ${({ theme }) => theme.weights.regular};
    white-space: normal;
    line-height: 1.5;
  }
`;

export const BurgerDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  a,
  button {
    ${burgerLink};
    font-weight: ${({ theme }) => theme.weights.semiBold};
    filter: brightness(120%);
    margin: 0;
  }
`;

export const LiveLabel = styled.span`
  display: flex;
  align-items: baseline;

  ${LiveIndicator} {
    margin-right: 0.5rem;
    background-color: ${({ theme }) => theme.colors.error};
    width: 0.3125rem;
    height: 0.3125rem;
  }

  ${LiveTag} {
    font-size: 0.75rem;
    width: max-content;
    margin-left: 0.5rem;
  }
`;

export const NotificationsLink = styled(Link)<{ $unread: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 0.2rem;
  border: solid 0.05rem ${({ theme }) => theme.colors.greyBlue};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;

  :hover:not(:disabled) {
    box-shadow: 0 0.125rem 0.5rem -0.1875rem ${({ theme }) => addAlpha(theme.colors.black, 0.4)};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.accent};
  }

  svg {
    width: 1.25rem;
    height: 1.25rem;
    color: ${({ theme }) => theme.colors.accent};
  }

  ${({ $unread }) =>
    $unread &&
    css`
      &::after {
        background-color: ${({ theme }) => theme.colors.error};
        content: '';
        position: absolute;
        width: 0.375rem;
        height: 0.375rem;
        right: 0.438rem;
        top: 0.563rem;
        border-radius: 50%;
      }
    `}
`;
