import { createEditorState, parseHTML } from 'components/Editor';
import { isLoading } from 'components/MediaInput';
import { COMMENT_FORM_KEY, QuestionForm, UPLOAD_MEDIA_FIELD } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { isNotFalsy } from 'utils/NonFalsy';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { id, editId }: OwnProps) => {
  const form = COMMENT_FORM_KEY(id, editId);

  const { selectIsPristine, selectValues } = makeFormSelectors<QuestionForm>(
    form,
  );
  const values = selectValues(state);

  const commentToEdit = state.comment.byCommentId[editId ?? ''];

  function getTitleAndComment() {
    if (!commentToEdit) return {};
    const [title, comment] = commentToEdit.comment
      .split(/<\/?h1>/)
      .filter((v) => v.length > 1);
    return {
      title,
      comment: createEditorState(comment ? parseHTML(comment) : undefined),
    };
  }

  return {
    form,
    filesLoading: isLoading(values?.[UPLOAD_MEDIA_FIELD]),
    initialValues: {
      [UPLOAD_MEDIA_FIELD]: [],
      comment: createEditorState(),
      title: '',
      ...(commentToEdit
        ? {
            [UPLOAD_MEDIA_FIELD]: [commentToEdit.image].filter(isNotFalsy),
            ...getTitleAndComment(),
          }
        : {}),
    },
    isPristine: selectIsPristine(state),
    loading: state.comment.loading,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<QuestionForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<QuestionForm>({
    destroyOnUnmount: false,
  }),
) as FormDecorator<QuestionForm, OwnProps, ConnectedProps>;
