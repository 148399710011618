import { Comment } from 'model';
import { ParametricSelector } from 'redux/modules/types';
import { DeepReadonly } from 'utility-types';
import { COMMENTS_MAX_DEPTH } from 'utils/config';

export const selectObjectComments: ParametricSelector<
  { depth?: number; id: string },
  DeepReadonly<Comment[]> | undefined
> = (state, { depth = 0, id }) => {
  if (depth >= COMMENTS_MAX_DEPTH) {
    return [];
  }

  const {
    comment: { byCommentId, byParentId },
  } = state;

  const ids = byParentId[id] as string[] | undefined;

  if (!ids) {
    return undefined;
  }

  return ids
    .map((id) => byCommentId[id])
    .filter((it): it is NonNullable<typeof it> => !!it)
    .map((comment) => ({
      ...comment,
      children:
        selectObjectComments(state, { depth: depth + 1, id: comment.id }) || [],
    }));
};
