import React, { ComponentType, FC } from 'react';
import { matchPath, withRouter } from 'react-router';
import Item from './Item';
import { Container, ContentWrapper, List } from './styles';
import { InternalLinkItem, OwnProps, Props } from './types';

const Dropdown: FC<Props> = ({
  children,
  items,
  location: { pathname },
  trigger,
  ...rest
}) => {
  const active = items
    .filter((item): item is InternalLinkItem => 'path' in item)
    .map((item) => matchPath(pathname, item.path))
    .reduce((anyMatch, currentMatch) => anyMatch || !!currentMatch, false);
  return (
    <Container active={active} {...rest}>
      {trigger}
      <ContentWrapper>
        <List>
          {items.map((item, index) => {
            if ('spacer' in item) {
              return <Item key={index} item={item} />;
            }

            return (
              <Item
                key={typeof item.label === 'string' ? item.label : index}
                item={item}
              />
            );
          })}
        </List>
      </ContentWrapper>
    </Container>
  );
};

export default withRouter(Dropdown) as ComponentType<OwnProps>;
