import { useMediaQuery } from '@material-ui/core';
import PaywallImage from 'assets/images/paywall/man_typing_on_laptop.png';
import PaywallImageWebp from 'assets/images/paywall/man_typing_on_laptop.webp';
import {
  ExpandableTruncatedText,
  Loader,
  ShareButton,
  Tag,
  VideoPaywall,
} from 'components';
import lazy from 'containers/Common/Lazy';
import { isAfter } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import throttle from 'lodash/throttle';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { from } from 'styles/mixins';
import { formatDuration, formatElapsedTime } from 'utils/dates';
import excludeOtherTag from 'utils/excludeOtherTag';
import { useLiveDate } from 'utils/useLiveDate';
import connect from './connect';
import {
  Assignment,
  CategoryTag,
  Container,
  Info,
  LiveChat,
  MainInfo,
  Modal,
  TagsContainer,
  Title,
  VideoAndInfoContainer,
  VideoContainer,
} from './styles';
import { Props, TimeUpdate } from './types';

const VideoPlayer = lazy(() => import('containers/Common/VideoPlayer'));

const BaseVideoModal: FC<Props> = ({
  data,
  updateVideoProgress,
  closeVideoModal,
  userIsPremium,
  url,
  pathname,
  isLoggedIn,
  loading,
}) => {
  const [showPaywall, setShowPaywall] = useState(false);
  const isFromMobileXL = useMediaQuery(from.mobileXL.query);

  const now = useLiveDate(1000);
  const liveEventisClosed =
    data?.endDate && isAfter(now, parseISO(data.endDate));

  const onEnd = useCallback(() => {
    if (userIsPremium) return;
    setShowPaywall(true);
  }, [setShowPaywall, userIsPremium]);

  const onTimeUpdates = useCallback(
    throttle(({ percent }: TimeUpdate) => {
      if (!isLoggedIn || !data || data.contentType !== 'videolesson') return;
      const progress = Math.floor(percent * 100);

      updateVideoProgress({
        progress,
        id: data.id,
        contentType: data.contentType,
      });
    }, 1000),
    [data, updateVideoProgress],
  );

  useEffect(() => {
    if (!data) {
      setShowPaywall(false);
    } else if (data.isLiveEvent && !userIsPremium) {
      setShowPaywall(true);
    } else if (!data.link && !userIsPremium) {
      setShowPaywall(true);
    }
  }, [data, userIsPremium]);

  const chatEnabled =
    userIsPremium && !!data?.isLiveEvent && !liveEventisClosed;
  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${data?.title}: `;
  const currentUrl = url
    ? window.location.origin + url
    : window.location.origin + pathname;

  const dataFilteredTags = data?.tags.filter(excludeOtherTag);

  return (
    <Modal
      open={!!data}
      onClose={closeVideoModal}
      fullScreen={!isFromMobileXL}
      scroll={isFromMobileXL ? 'paper' : 'body'}
      $withChat={chatEnabled}
    >
      {loading ? (
        <Loader />
      ) : (
        data && (
          <>
            <VideoAndInfoContainer>
              <VideoContainer>
                {showPaywall ? (
                  <VideoPaywall
                    image={{
                      sources: [
                        { srcSet: `${PaywallImageWebp}`, type: 'image/webp' },
                        { srcSet: `${PaywallImage}`, type: 'image/png' },
                      ],
                      default: PaywallImage,
                    }}
                    closeModal={closeVideoModal}
                  />
                ) : (
                  <VideoPlayer
                    videoURL={data.link}
                    onEnd={onEnd}
                    onTimeUpdates={onTimeUpdates}
                    progress={data.progress}
                  />
                )}
              </VideoContainer>
              <Container>
                {data.solutionFor && (
                  <Assignment
                    to={`/exercises/${data.solutionFor.slug}`}
                    onClick={closeVideoModal}
                  >
                    SWDEXERCISE: {data.solutionFor.name}
                  </Assignment>
                )}
                <MainInfo>
                  <Title>{data.title}</Title>
                  <ShareButton content={shareButtonMessage} url={currentUrl} />
                </MainInfo>
                <Info>
                  {formatDuration(data.duration)} ·{' '}
                  {formatElapsedTime(data.createdAt)}
                  {data.views !== undefined && ` · ${data.views} views`}
                </Info>
                <TagsContainer>
                  {data.category && <CategoryTag name={data.category} />}
                  {dataFilteredTags?.map((tag: string) => (
                    <Tag key={tag} name={tag} />
                  ))}
                </TagsContainer>
                <ExpandableTruncatedText
                  text={data.description}
                  limit={!isFromMobileXL ? Infinity : undefined}
                />
              </Container>
            </VideoAndInfoContainer>
            {chatEnabled && !!data.chatUrl && <LiveChat src={data.chatUrl} />}
          </>
        )
      )}
    </Modal>
  );
};

export default connect(BaseVideoModal);
