import { RankingMember as DefaultRankingMember } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  margin-top: 40px;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Ranking = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  width: 100%;
  margin-bottom: 2.5rem;
`;

export const RankingMember = styled(DefaultRankingMember)``;
