import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 9 14"
    {...props}
  >
    {children}
    <defs>
      <path
        id="a"
        d="M4.349-1.667L2.833-.15l4.923 4.933-4.923 4.934L4.35 11.23l6.449-6.449z"
      />
    </defs>
    <use
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(-2.033 2.402)"
      xlinkHref="#a"
    />
  </svg>
));
