import overSome from 'lodash/fp/overSome';
import { nanoid } from 'nanoid/non-secure';
import type { MarkSpec } from 'prosemirror-model';
import type { EditorState, Plugin } from 'prosemirror-state';
import { createReferenceProviderHolderPlugin } from './providerHolder';

import { createReferenceSelectorPlugin } from './ReferenceSelector';
import type { PluginProps } from './types';
import { referenceWatcherPlugin } from './watcher';

export const referenceMark: MarkSpec = {
  attrs: {
    id: {},
    entityId: {
      default: undefined,
    },
    entityType: {
      default: undefined,
    },
  },
  content: 'inline*',
  group: 'inline',
  inline: true,
  toDOM: (node) => [
    'span',
    {
      class: 'reference',
      'data-reference-entity-id': node.attrs.entityId,
      'data-reference-entity-type': node.attrs.entityType,
    },
    0,
  ],
  parseDOM: [
    {
      tag: 'span[data-reference-entity-id][data-reference-entity-type]',
      getAttrs: (dom) => {
        if (dom instanceof HTMLElement) {
          const {
            referenceEntityId: entityId,
            referenceEntityType: entityType,
          } = dom.dataset;

          return {
            entityId,
            entityType,
            id: nanoid(),
          };
        }

        return false;
      },
    },
  ],
};

export const createReferencePlugins = ({
  schema,
}: PluginProps): {
  isAnyReferencePromptOpen: (state: EditorState) => boolean;
  referencePlugins: ReadonlyArray<Plugin>;
} => {
  const {
    getProvider,
    referenceProviderHolderPlugin,
  } = createReferenceProviderHolderPlugin();

  const {
    isReferenceSelectorOpen,
    referenceSelectorPlugin,
  } = createReferenceSelectorPlugin({
    getProvider,
  });

  const isAnyReferencePromptOpen = overSome([isReferenceSelectorOpen]);

  const referencePlugins = [
    referenceProviderHolderPlugin,
    referenceSelectorPlugin,
    referenceWatcherPlugin({
      schema,
      getProvider,
    }),
  ];

  return {
    isAnyReferencePromptOpen,
    referencePlugins,
  };
};
