import { ChallengeResponse, Statuses } from 'model';
import { DeepReadonly } from 'utility-types';
import { dateHasPassed, isExpiringSoon } from 'utils/dates';

export function getChallengeResponseStatusList(
  challengeResponse: DeepReadonly<ChallengeResponse>,
): string[] {
  const statusList = [];
  if (
    !!challengeResponse.expiryDate &&
    dateHasPassed(challengeResponse.expiryDate || '')
  ) {
    statusList.push(Statuses.CLOSED);
  }

  if (
    !statusList.includes(Statuses.CLOSED) &&
    (isExpiringSoon(challengeResponse.expiryDate) ||
      challengeResponse.isAboutToExpire)
  ) {
    statusList.push(Statuses.EXPIRES_SOON);
  }

  if (challengeResponse.swdTeam) {
    statusList.push(Statuses.SWD_TEAM);
  }

  return statusList;
}
