import Images from 'containers/Common/Images';
import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import DiscoverRoot from './DiscoverRoot';
import GalleryDetail from './GalleryDetail';
import { Props } from './types';

const Discover: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Discover</BreadcrumbsItem>
      <Switch>
        <Route component={Images} path={`${path}/:discoverId/images`} />
        <Route component={Images} path={`${path}/detail/:discoverId/images`} />
        <Route path={`${path}/detail/:discoverId`}>
          <GalleryDetail />
        </Route>
        <Route component={DiscoverRoot} path={path} />
      </Switch>
    </>
  );
};

export default Discover;
