import { findChildrenByMark } from 'prosemirror-utils';
import type { EditorView } from 'prosemirror-view';
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
} from 'react';

import { dispatchToTransaction } from 'utils/prosemirror';

import type { Schema } from '../../schema';
import { closeLinkPrompt } from '../actions';

export const useHandleClickAway = ({
  editorView,
}: {
  editorView: EditorView<Schema>;
}) =>
  useCallback(() => {
    closeLinkPrompt(editorView.state, editorView.dispatch);
  }, [editorView]);

export const useOnChangeHref = ({
  setHref,
}: {
  setHref: Dispatch<SetStateAction<string>>;
}) =>
  useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setHref(e.target.value);
    },
    [setHref],
  );

export const useHandleSubmit = ({
  editorView,
  href,
}: {
  editorView: EditorView<Schema>;
  href: string;
}) => {
  const saveLink = useCallback(
    (newHref: string) => {
      const state = editorView.state;
      const tr = state.tr;

      const existingMark = state.selection.$from
        .marks()
        .find((mark) => mark.type === state.schema.marks.link);

      if (existingMark) {
        /*
         * If we have an existing mark, we might be editing it or removing it.
         * Since ProseMirror doesn't allow to update a mark in place, we have to
         * always remove it.
         */
        tr.removeMark(0, state.doc.content.size, existingMark);

        if (newHref) {
          const { $from } = state.selection;

          /*
           * Then, if the href is defined, we add it back to all the children that
           * previously contained it. In most cases this will be an array of size
           * one, but in the event of having a link divided in multiple blocks
           * (multiple paragraphs) we will have multiple childrenWithMark.
           */
          const childrenWithMark = findChildrenByMark(
            $from.parent,
            state.schema.marks.link,
          );

          // The position of the start of the node containing the selection
          const parentStart = $from.pos - $from.parentOffset;

          childrenWithMark.forEach(({ node, pos: relativePos }) => {
            tr.addMark(
              // relativePos is the position of the node, not within the document
              // but within its parent. We add parentStart to get the position
              // relative to the whole document.
              parentStart + relativePos,
              parentStart + relativePos + node.nodeSize,
              state.schema.marks.link.create({
                href: newHref,
              }),
            );
          });
        }
      } else {
        /*
         * If we don't have an existing mark, it means that we're creating it.
         * We don't deal with stored marks for links, as supporting that can be
         * a bit tricky because in the popup we remove the focus from the editor.
         */
        tr.addMark(
          state.selection.from,
          state.selection.to,
          state.schema.marks.link.create({
            href: newHref,
          }),
        );
      }

      closeLinkPrompt(state, dispatchToTransaction(tr));

      editorView.dispatch(tr);
    },
    [editorView],
  );

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      saveLink(href);
    },
    [href, saveLink],
  );

  return { onSubmit, saveLink };
};

export const useHandleDelete = ({
  saveLink,
}: {
  saveLink: (href: string) => void;
}) =>
  useCallback(() => {
    saveLink('');
  }, [saveLink]);
