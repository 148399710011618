import { getFormInitialValues, getFormValues } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { isPristine } from 'utils/isPristine';

export const makeFormSelectors = <T extends object>(key: string) => {
  const selectValues = (getFormValues(key) as unknown) as (
    state: RootState,
  ) => T | undefined;

  const selectInitialValues = (getFormInitialValues(key) as unknown) as (
    state: RootState,
  ) => Partial<T> | undefined;

  const selectIsPristine = createSelector(
    selectValues,
    selectInitialValues,
    (values, initialValues) => isPristine(values, initialValues),
  );

  return {
    selectInitialValues,
    selectIsPristine,
    selectValues,
  };
};
