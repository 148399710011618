import { connect } from 'react-redux';
import {
  createBooking,
  deleteBooking,
  normalizeOfficeHour,
  updateBooking,
} from 'redux/modules/officeHour';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { match }: OwnProps) => {
  const hourId = match?.params?.hourId;
  const hour = state.officeHour.byId[hourId ?? ''];

  return {
    hour: hour && normalizeOfficeHour(hour),
    loading: state.officeHour.loading,
  };
};

const mapDispatchToProps = {
  createBooking: createBooking.request,
  updateBooking: updateBooking.request,
  deleteBooking: deleteBooking.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
