import { oneLine } from 'common-tags';
import { EditorInput, FormActions, Input } from 'components';
import VisualTagInputs from 'containers/Common/VisualTagInputs';
import { AddToGalleryFieldNames, UPLOAD_MEDIA_FIELD } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required, title } from 'utils/validations';
import connect from './connect';
import { Column, Container, MediaInput, Row } from './styles';
import { Props } from './types';

const Form: FC<Props> = ({
  handleSubmit,
  invalid,
  isPristine,
  loading,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <Row>
      <Column>
        <Field
          component={MediaInput}
          label="Upload image"
          maxFiles={1}
          name={AddToGalleryFieldNames[UPLOAD_MEDIA_FIELD]}
        />
      </Column>
      <Column>
        <Field
          component={Input}
          label="Project title"
          name={AddToGalleryFieldNames.title}
          placeholder="Title of your project"
          type="text"
          validate={[required, title]}
        />
        <Field
          component={EditorInput}
          label="Relevant context"
          name={AddToGalleryFieldNames.context}
          placeholder={oneLine`Write down your thoughts...`}
          validate={required}
        />
      </Column>
    </Row>
    <VisualTagInputs />
    <FormActions
      invalid={invalid}
      loading={loading}
      pristine={isPristine}
      submitFailed={submitFailed}
    />
  </Container>
);

export default connect(Form);
