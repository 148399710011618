import {
  EditIcon as DefaultEditIcon,
  ReplyIcon as DefaultReplyIcon,
} from 'components/icons';
import Link from 'components/Link';
import styled, { css } from 'styled-components';
import { buttonReset, from, icon } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing.lg};
  ${from.tablet`
    border-bottom: 0;
  `};
`;

const commonButtonStyles = css`
  ${buttonReset};
  display: flex;
  align-items: center;

  :not(:disabled) {
    cursor: pointer;

    :active {
      background-color: ${({ theme }) => theme.colors.sky};
    }
  }

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;

export const ThumbsUpButton = styled.button`
  ${commonButtonStyles};
  > :last-child {
    display: block;
  }
`;

export const ReplyButton = styled.button`
  ${commonButtonStyles};
  margin-left: ${({ theme }) => theme.spacing.lg};
  margin-right: ${({ theme }) => theme.spacing.lg};
`;

export const CommentButton = styled(Link)`
  ${commonButtonStyles};
  text-decoration: none;
  margin-left: ${({ theme }) => theme.spacing.lg};
  margin-right: ${({ theme }) => theme.spacing.lg};
`;

export const ReportButton = styled.button`
  ${commonButtonStyles};
  margin-left: auto;
`;

export const ReplyIcon = styled(DefaultReplyIcon)`
  ${icon};
`;

export const EditLink = styled(Link)`
  ${commonButtonStyles};
  margin-left: auto;
`;

export const EditIcon = styled(DefaultEditIcon)`
  ${icon};
`;

export const Text = styled.span<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  display: none;
  line-height: normal;

  strong {
    font-weight: ${({ theme }) => theme.weights.semiBold};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
    `};

  ${from.mobileXL`
    display: block;
  `};
`;
