import { ONBOARDING_SECTIONS } from 'model';
import React, { FC } from 'react';
import { up } from 'utils/Paths';
import { OnboardingSteps } from './styles';
import { Props } from './types';

const Navigation: FC<Props> = ({
  location: { search },
  match: {
    params: { section },
    url,
  },
}) => (
  <OnboardingSteps
    baseUrl={up(url)}
    current={section}
    search={search}
    sections={ONBOARDING_SECTIONS}
  />
);

export default Navigation;
