import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16.521 16.521"
    {...props}
  >
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      transform="translate(.26 .26)"
    >
      <rect width="15" height="13" x="0.501" y="2.5" rx="1.5" />
      <path d="M.501 6.5h15m-11-2.5V.5m7 3.5V.5" />
    </g>
  </svg>
));
