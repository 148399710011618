import React, { FC, memo, useCallback } from 'react';
import { Container, Label, Radio, Tag } from './styles';
import { Props } from './types';

const Plan: FC<Props> = ({ id, label, onSelect, selected, tag, ...rest }) => {
  const onClick = useCallback(() => {
    onSelect(id);
  }, [id, onSelect]);

  return (
    <Container onClick={onClick} selected={selected} {...rest}>
      <Radio checked={selected} readOnly={true} />
      <Label selected={selected}>{label}</Label>
      {tag ? <Tag>{tag}</Tag> : null}
    </Container>
  );
};

export default memo(Plan);
