import differenceInMinutes from 'date-fns/differenceInMinutes';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { GUEST_HOME_CACHE_DURATION_MINUTES } from 'utils/config';
import { normalizeExercise } from '../exerciseBank';
import { normalizeMyFeedback } from '../myOpenFeedback';
import {
  AuthenticatedResponse,
  getHomeItemsAuthenticated,
  getHomeItemsGuest,
  GuestResponse,
} from './actions';

export const getHomeItemsGuestEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(getHomeItemsGuest.request)),
    mergeMap(() => {
      const lastUpdated = new Date(state$.value.home.updatedAtTimestamp);

      if (
        differenceInMinutes(new Date(), lastUpdated) <
        GUEST_HOME_CACHE_DURATION_MINUTES
      ) {
        return [getHomeItemsGuest.success(undefined)];
      }

      return request<GuestResponse>({
        path: 'home/anonymous',
      }).pipe(
        mergeMap(({ challenge, conversations, exercise, feedback }) => [
          getHomeItemsGuest.success({
            conversations,
            feedback,
            activeChallenge: challenge,
            activeExercise: exercise,
          }),
        ]),
        catchError(() => [getHomeItemsGuest.failure()]),
      );
    }),
  );

export const getHomeItemsAuthenticatedEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getHomeItemsAuthenticated.request)),
    mergeMap(() =>
      request<AuthenticatedResponse>({
        path: 'home/authenticated',
      }).pipe(
        mergeMap(
          ({
            challenge,
            conversations,
            latestExerciseResponses,
            openFeedback,
            visuals,
            users,
            feedback,
            banner,
          }) => [
            getHomeItemsAuthenticated.success({
              conversations,
              feedback,
              visuals,
              users,
              banner,
              activeChallenge: challenge,
              openFeedback: normalizeMyFeedback(openFeedback),
              exerciseBank: latestExerciseResponses.map(normalizeExercise),
            }),
          ],
        ),
        catchError(() => [getHomeItemsAuthenticated.failure()]),
      ),
    ),
  );

export default combineEpics(
  getHomeItemsGuestEpic,
  getHomeItemsAuthenticatedEpic,
);
