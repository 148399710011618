import Dropdown from 'components/Dropdown';
import React, { FC } from 'react';
import { CaretDownIcon, Label } from './styles';
import { Props } from './types';

const DropdownMenu: FC<Props> = ({ items, name, ...rest }) => (
  <Dropdown
    items={items}
    trigger={
      <>
        <Label>{name}</Label>
        <CaretDownIcon />
      </>
    }
    {...rest}
  />
);

export default DropdownMenu;
