import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { editProfile, setGroupCode } from 'redux/modules/auth';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { path, url } = useRouteMatch();
  const { pathname, search, state } = useLocation<{
    onboardingAllowed?: boolean;
    scrollToTop?: boolean;
  }>();
  const { push } = useHistory();

  const actions = useActions({
    editProfile: editProfile.request,
    setGroupCode: setGroupCode.request,
  });

  return {
    ...actions,
    path,
    pathname,
    push,
    search,
    state,
    url,
  };
};
