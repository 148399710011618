import React, { FC, memo } from 'react';
import {
  Container,
  Description,
  ImageContainer,
  Info,
  Link,
  Summary,
  Supertitle,
  Title,
} from './styles';
import { Props } from './types';

const Benefit: FC<Props> = ({
  description,
  Icon,
  summary,
  supertitle,
  title,
  link,
  linkText,
  ...rest
}) => (
  <Container {...rest}>
    <Info>
      <Supertitle>{supertitle}</Supertitle>
      <Title>{title}</Title>
      <Summary>{summary}</Summary>
      <Description>{description}</Description>
      <Link to={link} text={linkText} target="_blank" />
    </Info>
    <ImageContainer>
      <Icon />
    </ImageContainer>
  </Container>
);

export default memo(Benefit);
