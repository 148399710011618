import DefaultAvatar from 'components/Avatar';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div<{
  isAma?: boolean;
  isEmpty: boolean;
  closed?: boolean;
}>`
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 1.25rem 0 0 1.25rem;
  width: 100%;
  align-items: center;

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-row-gap: 4px;
    `}

  ${({ isAma, closed }) =>
    isAma &&
    css`
      grid-template-columns: auto auto 1fr;
      grid-gap: 6px;
      align-items: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.black};
      font-weight: ${({ theme }) => theme.weights.semiBold};
      font-size: 12px;

      > :last-child {
        margin-left: 1rem;
        margin-right: auto;
        ${from.laptop` 
          margin-left: 1.9rem;
        `}
      }

      ${closed &&
      `
        display: flex;

        ${NumberOfComments}:last-child {
          margin-right: 8px;
        }
      `}
    `};
  ${from.tabletH`
      padding-top: 0;
      border-top: 0;
      padding-left: 1rem;
      width: 26rem;
      max-width: 100%;
    `}
`;

export const NumberOfComments = styled.div<{ live?: boolean }>`
  border-radius: 3px;
  background-color: ${({ theme, live }) =>
    theme.colors[live ? 'error' : 'accent']};
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  margin-bottom: 5px;
  line-height: 1;
`;

export const Faces = styled.div`
  display: flex;

  > * + * {
    margin-left: 4px;
  }
`;

export const Face = styled(DefaultAvatar)`
  width: 28px;
  height: 28px;
  margin-right: 0;
  margin-bottom: 5px;
  &:hover {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary};
  }
`;

export const LastComment = styled.div`
  grid-column: 1 / -1;

  font-size: 12px;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.colors.conversationComment};

  span {
    color: ${({ theme }) => theme.colors.black};
  }

  > * {
    margin: 0;
  }
`;
