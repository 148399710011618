import { oneLine } from 'common-tags';
import { EditorInput, FormActions, Input, Select } from 'components';
import { ConversationFieldNames, Tag } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required, title } from 'utils/validations';
import connect from './connect';
import { Container, TitleRow } from './styles';
import { Props } from './types';

const tagToOption = ({ id, name }: Tag) => ({
  label: name,
  value: id,
});

const ConversationForm: FC<Props> = ({
  editId,
  handleSubmit,
  invalid,
  isPristine,
  loading,
  onDelete,
  reset,
  submitFailed,
  tags,
}) => (
  <Container onSubmit={handleSubmit}>
    <TitleRow>
      <Field
        component={Input}
        label="Topic title"
        name={ConversationFieldNames.title}
        placeholder="Title of your topic"
        type="text"
        validate={[required, title]}
      />
      <Field
        component={Select}
        isClearable={true}
        isMulti={true}
        label="Topic Tags"
        name={ConversationFieldNames.tags}
        options={tags.map(tagToOption)}
        validate={required}
      />
    </TitleRow>
    <Field
      component={EditorInput}
      label="Description"
      name={ConversationFieldNames.description}
      placeholder={oneLine`Write down your thoughts...`}
      validate={required}
    />
    <FormActions
      invalid={invalid}
      loading={loading}
      onDelete={onDelete}
      pristine={isPristine}
      reset={editId ? reset : undefined}
      submitFailed={submitFailed}
    />
  </Container>
);

export default connect(ConversationForm);
