import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { report } from './actions';
import { ReportAction, ReportState } from './types';

export const INITIAL_STATE: ReportState = {
  loading: false,
};

const reducer: Reducer<ReportState, ReportAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(report.request):
      return {
        ...state,
        loading: true,
      };
    case getType(report.success):
    case getType(report.failure):
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
