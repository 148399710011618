import styled, { css } from 'styled-components';
import { from, whiteBox } from 'styles/mixins';

export const SummaryContainer = styled.section`
  width: 100%;
`;

export const InfoTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 10rem;
  padding-right: 1.625rem;

  ${from.tablet`
    max-width: 6.875rem;
    padding-right: 1rem;
  `}
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  height: 1.75rem;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 19.6px;
  text-align: left;
  text-transform: lowercase;
  color: ${({ theme }) => theme.colors.black};

  ${from.tablet`
    display: block;
    height: 3.45rem;
  `}
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 2.5rem;
  margin: 0 0 ${({ theme }) => theme.spacing.sm};
`;

export const StatisticsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  row-gap: 24px;
  min-height: 3.25rem;
  padding: 16px 0;
  ${whiteBox}
  border-radius: 8px;
  box-shadow: none;

  > :first-child {
    border: none;
  }

  ${from.tablet`
    display: flex;
    justify-content: space-between;
    padding: 12px; 
  `};
`;

export const Statistic = styled.div`
  border-left: solid 1px ${({ theme }) => theme.colors.grey300}80;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  flex-basis: 100px;
  justify-content: space-evenly;
  margin-left: -1px;
  padding: 0 12px;

  ${from.tablet`
    margin-top: 0;
  `};
`;

export const StatisticValue = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const StatisticKey = styled.span`
  color: ${({ theme }) => theme.colors.black}CC;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 19.6px;
  text-transform: lowercase;
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const Contributions = styled.div``;

export const Graph = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${whiteBox}
  border-radius: 8px;
  padding: ${({ theme }) => `${theme.spacing.xlg} ${theme.spacing.lg} 1.25rem`};
`;

export const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 50vw;

  @media (min-width: 700px) {
    max-width: 60vw;
  }

  ${from.tablet`
    max-width: 100%;
  `}
`;

export const Table = styled.table`
  border-spacing: 1.5px;
  table-layout: fixed;
  min-width: 625px;
`;

export const Thead = styled.thead`
  text-align: left;
`;

export const Th = styled.th`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  padding-bottom: ${({ theme }) => theme.spacing.md};
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr<{ variant?: string }>`
  ${({ variant }) =>
    variant === 'month' &&
    css`
      > td {
        border-right: solid 1px ${({ theme }) => theme.colors.white};

        ::after {
          content: '';
          border-right: solid 1px ${({ theme }) => theme.colors.sky};
          position: absolute;
          height: 100%;
          top: 0;
          right: -2px;
        }
      }
    `};

  ${({ variant }) =>
    variant === 'week' &&
    css`
      td:nth-child(4n + 4) {
        border-right: solid 1px ${({ theme }) => theme.colors.white};

        ::after {
          content: '';
          border-right: solid 1px ${({ theme }) => theme.colors.sky};
          position: absolute;
          height: 100%;
          top: 0;
          right: -2px;
        }
      }
    `};
`;

export const Td = styled.td<{ count: number | null }>`
  background-color: ${(props) =>
    (props.count === null && '#fff') ||
    (props.count === 0 && '#f6f6f6') ||
    (props.count === 1 && '#cce3fa') ||
    (props.count === 2 && '#86baee') ||
    (props.count === 3 && '#297fd5')};
  height: 1.5rem;
  width: 0.7rem;
  position: relative;

  ${from.tablet`
    height: 3.25rem;
  `}
`;

export const LegendArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1.625rem;

  ${from.tablet`
    margin-top: 0.688rem;
  `}
`;

export const LegendSquare = styled.div`
  width: 0.75rem;
  height: 0.625rem;

  :not(:last-child) {
    margin-right: 0.688rem;
  }

  :nth-child(2) {
    background-color: #f6f6f6;
  }

  :nth-child(3) {
    background-color: #cce3fa;
  }

  :nth-child(4) {
    background-color: #86baee;
  }

  :nth-child(5) {
    background-color: #297fd5;
  }

  ${from.tablet`
    :not(:last-child) {
      margin-right: 0.063rem;
    }
  `}
`;

export const LegendText = styled.span`
  margin-right: 0.75rem;
  font-size: 10px;
  letter-spacing: 0.83px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};

  ${from.tablet`
    margin: 0 0.25rem;

    :first-child {
      margin-left: auto;
    }
  `}
`;
