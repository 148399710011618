import { ReportIcon as DefaultReportIcon } from 'components/icons';
import { ExclamationMark, Flag } from 'components/icons/Report/styles';
import styled, { css } from 'styled-components';

interface ReportIconProps {
  $iconWidth: string;
  $isReported: boolean;
}

export const ReportIcon = styled(DefaultReportIcon)<ReportIconProps>`
  width: ${({ $iconWidth }) => $iconWidth};

  // Optical alignment correction
  //margin-bottom: 2%;
  //margin-left: 5%;

  ${({ $isReported, theme }) =>
    $isReported &&
    css`
      ${ExclamationMark} {
        color: ${theme.colors.accent};
      }

      ${Flag} {
        color: ${theme.colors.primary};
      }
    `}
`;
