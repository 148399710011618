import { Modal as DefaultModal } from 'components';
import { TitleContainer } from 'containers/Common/ExerciseSubmissionView/styles';
import styled, { css } from 'styled-components';

export const Modal = styled(DefaultModal)`
  ${TitleContainer} {
    margin-bottom: 1rem;
  }
  & .MuiPaper-root {
    ${({ fullScreen }) =>
      !fullScreen &&
      css`
        max-width: 1170px;
        width: 90vw;
      `};
  }
`;
