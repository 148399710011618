import React, { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Props } from './types';

export const getWrapper = ({
  Component,
  loggedIn,
}: {
  Component: Props['component'];
  loggedIn: Props['loggedIn'];
}): FC<RouteComponentProps> =>
  loggedIn
    ? ({ location: { search } }) => (
        <Redirect
          to={{
            search,
            pathname: '/',
          }}
        />
      )
    : ({ ...props }) => <Component {...props} />;
