import { Loader, NoResults, Video, VideoGrid } from 'components';
import SearchOptions from 'containers/Common/SearchOptions';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { useConnect } from './connect';
import { Container, Pagination } from './styles';
import { Props } from './types';

const VideoList: FC<Props> = ({ category }) => {
  const {
    getVideos,
    hash,
    loading,
    pageCount,
    search,
    state,
    url,
    videos,
    videoTypes,
    wentBack,
  } = useConnect();

  const containerRef = useRef<HTMLDivElement>(null);
  const page = getPageFromQuery(search);

  const filters = useMemo(() => {
    const currentCategory = videoTypes.find((type) => type.url === category)
      ?.id;
    return {
      video_type: currentCategory,
    };
  }, [category, videoTypes]);

  useEffect(() => {
    getVideos({ search, silent: wentBack, ...filters });
  }, [getVideos, search, wentBack, filters]);

  return (
    <Container ref={containerRef}>
      <SearchOptions contentType="video" searchPlaceholder="Search..." />
      {loading ? (
        <Loader />
      ) : videos.length > 0 ? (
        <VideoGrid>
          {videos.map((video) => (
            <Video
              category={video.videoTypeTag}
              createdAt={video.created}
              description={video.description}
              duration={video.duration}
              image={video.thumbnail}
              key={video.id}
              link={video.videoLink}
              progress={video.progress}
              presenterTags={video.videoPresenterTags}
              seriesTags={video.videoSeriesTags}
              exerciseTags={video.exerciseTags}
              graphToolsTags={video.graphToolTags}
              title={video.title}
              views={video.views}
              solutionFor={video.solutionFor}
              contentType="videolesson"
              to={{
                search,
                pathname: `${url}/${video.slug}`,
                state: {
                  scrollToTop: false,
                },
              }}
            />
          ))}
        </VideoGrid>
      ) : (
        <NoResults />
      )}
      <Pagination
        contentRef={containerRef}
        current={Math.min(page, pageCount)}
        hash={hash}
        search={search}
        state={state}
        total={pageCount}
        url={url}
      />
    </Container>
  );
};

export default VideoList;
