import { VisuallyHiddenText } from 'components/ArrowLink/styles';
import React, { FC, memo, useCallback } from 'react';
import { Link } from '../styles';
import { PageSpan } from './styles';
import { Props } from './types';

const Page: FC<Props> = ({ active, page, scrollToContent, setPage, url }) => {
  const goToPage = useCallback(() => {
    scrollToContent();
    setPage(page);
  }, [page, scrollToContent, setPage]);

  return (
    <Link
      $active={active}
      aria-current={active}
      key={`page-${page}`}
      onClick={goToPage}
      {...(url ? { to: url } : {})}
    >
      <VisuallyHiddenText>Page </VisuallyHiddenText>
      <PageSpan>{page}</PageSpan>
    </Link>
  );
};

export default memo(Page);
