import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { ExclamationMark, Flag, Pole } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    {children}
    <g fill="currentColor">
      <Pole d="M1.516 23.495V.758A.758.758 0 1 0 0 .758v22.737a.758.758 0 1 0 1.516 0z" />
      <Flag d="M.97 19.676l3.155-.862c2.437-.667 5.079-.368 7.25.82 2.477 1.355 5.481 1.722 8.278 1.012l2.987-.759c.805-.205 1.36-.859 1.36-1.603V4.233c0-.524-.277-1.016-.746-1.33a2.066 2.066 0 0 0-1.643-.273l-2.37.602c-2.392.607-4.961.293-7.08-.866C9.622.977 6.533.628 3.683 1.408L.53 2.269c-.396.108-.618.481-.497.833.122.352.542.55.937.442l3.154-.862c2.438-.667 5.08-.368 7.251.82 2.477 1.355 5.481 1.722 8.278 1.012l2.37-.602a.414.414 0 0 1 .327.055c.095.063.15.162.15.266v14.051c0 .1-1.086.46-3.259 1.08-2.392.607-4.961.293-7.08-.866-2.539-1.389-5.628-1.738-8.478-.958l-3.153.862c-.396.108-.618.481-.497.833.122.352.542.55.938.441z" />
      <ExclamationMark d="M10.99 15.295a1.137 1.137 0 1 0 1.137-1.137c-.629 0-1.137.509-1.137 1.137zm1.894-2.411V6.821a.758.758 0 0 0-1.515 0v6.063a.758.758 0 1 0 1.515 0z" />
    </g>
  </svg>
));
