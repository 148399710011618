/* import parseISO from 'date-fns/parseISO'; */
import { parseISO } from 'date-fns';
import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { LiveEventsState } from './types';

const selectState: Selector<LiveEventsState> = (state) => state.liveEvents;

export const selectLiveEvents = createSelector(
  selectState,
  (state) => state.events,
);

export const selectLiveEventDates = createSelector(selectState, (state) => {
  const startDate = state.events[0]?.startDate;
  const endDate = state.events[0]?.endDate;

  if (!startDate || !endDate) return undefined;

  try {
    return { start: parseISO(startDate), end: parseISO(endDate) };
  } catch {
    return undefined;
  }
});
