import {
  ChallengeCard,
  ChallengeCardGrid,
  Loader,
  Pagination,
} from 'components';
import React, { FC } from 'react';
import { ArrowLink, Results, ResultsHeader, ResultsTitle } from '../../styles';
import connect from './connect';
import { Props } from './types';

const Exercises: FC<Props> = ({
  available,
  count,
  exercises,
  isDetail,
  loading,
  location: { search },
  match: { url },
  page,
  pageCount,
  setPage,
  ...rest
}) => {
  if (!available && !loading) {
    return null;
  }

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>SWDexercises {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: SWDexercise"
            to={{
              search,
              pathname: `${url}/SWDexercise`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ChallengeCardGrid>
            {exercises.map(({ ended, id, image, name }) => (
              <ChallengeCard
                endDate={ended ? ended : undefined}
                key={id}
                link={`/exercises/${id}`}
                linkText="See SWDexercise"
                thumbnail={image}
                title={name}
              />
            ))}
          </ChallengeCardGrid>
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Exercises);
