import { Menu } from '@material-ui/core';
import { Avatar as DefaultAvatar, Button as DefaultButton } from 'components';
import styled, { css } from 'styled-components';
import { from, whiteBox } from 'styles/mixins';
import {
  Column as DefaultColumn,
  Content as DefaultContent,
  Row as DefaultRow,
} from '../../SubscriptionRoot/styles';

export const Row = styled(DefaultRow)``;

export const Header = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  padding: ${({ theme }) => `1.25rem ${theme.spacing.lg} 0`};
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 1rem;
  }
  ${from.tablet`
    border-right: 1px solid ${({ theme }) => theme.colors.grey200};
    padding: 1.125rem 1.25rem;
    flex-basis: 35%;
    max-width: 35%;
    > :first-child {
      margin-right: 2rem;
    }
  `};
  ${from.tabletH`
    flex-basis: 22.1875rem;
    max-width: 22.1875rem;
  `};
`;

export const Name = styled.h4`
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.875rem;
  word-break: break-all;
  ${from.tablet`
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`};
  ${from.tabletH`
  width: auto
`};
`;

export const Avatar = styled(DefaultAvatar)`
  width: 2.25rem;
  height: 2.25rem;
  margin-bottom: 0px;
  border-radius: 50%;
  border-width: 0.125rem;
  img {
    border-radius: 50%;
  }
`;

export const Content = styled(DefaultContent)`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${from.tablet`
    padding: 0.938rem 2rem 0.938rem 0;
  `};
`;

export const StatusColumn = styled(DefaultColumn)`
  padding: 0;
  width: auto;
  display: flex;
  ${from.tablet`
    width: 5.75rem;
    padding: 0;
  `};
`;

export const StateTag = styled.span<{ $isActive: boolean }>`
  padding: 0.3125rem 0.5rem 0.1875rem;
  background-color: ${({ theme }) => theme.colors.grey200};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.grey400};
  leter-spacing: 0.0375rem;
  text-transform: uppercase;
  border-radius: 0.125rem;
  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.colors.accent};
      background-color: ${({ theme }) => theme.colors.sky};
    `};
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: 1.625rem;
  align-items: center;
  margin-left: auto;
  padding: 0 1.5rem 1.25rem 0;
  ${from.tablet`
    margin: 0 2rem 0 0;
    padding: 0;
`};
`;

export const MoreInfoButton = styled.div`
  display: flex;
  justify-content: center;
  width: 1.625rem;
  height: 2rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const Dot = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  background-color: ${({ theme }) => theme.colors.lightViolet};
  border-radius: 50%;
`;

export const DropdownContainer = styled(Menu)`
  background-color: transparent;
  .MuiMenuItem-root {
    font-family: inherit;
  }
  .MuiMenu-paper {
    padding: 0.625rem;
    ${whiteBox};
  }
  .MuiMenu-list {
    padding: 0;
  }
  .MuiListItem-button.MuiMenuItem-root {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`;

export const ResendButton = styled(DefaultButton)<{ $isDisabled: boolean }>`
  margin: 0 0 0.625rem 0.4rem;
  padding-left: 0px;
  text-transform: initial;
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-align: start;
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      color: ${({ theme }) => theme.colors.grey300};
      pointer-events: none;
    `};
`;

export const RemoveButton = styled(DefaultButton)`
  width: 100%;
  min-height: 2.8125rem;
  display: block;
  padding: 0.75rem 0.625rem 0.65rem;
  text-transform: initial;
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  line-height: 1.0625rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-align: start;
`;
