import DefaultThumbnail from 'assets/images/defaultThumbnail.png';
import { Statuses } from 'model';
import React, { FC } from 'react';
import {
  Container,
  Icon,
  ImageIcon,
  NoThumbnail,
  Overlay,
  Status,
  StatusList,
  SWDOverlayBadge,
  Thumbnail,
} from './styles';
import { Props } from './types';

const cardWidth = '370px';

const BaseCard: FC<Props> = ({
  outline,
  statuses,
  thumbnail,
  srcSet,
  children,
  isRecap,
  ...rest
}) => (
  <Container outline={outline} {...rest}>
    {statuses && (
      <StatusList>
        {statuses.map((status) => (
          <Status
            key={status}
            swdTeam={status === Statuses.SWD_TEAM}
            solvedByTeam={status === Statuses.SOLVED_BY_TEAM}
            expiresSoon={status === Statuses.EXPIRES_SOON}
          >
            <Icon solvedByTeam={status === Statuses.SOLVED_BY_TEAM}>
              <SWDOverlayBadge variant="swd" />
            </Icon>
            {status}
          </Status>
        ))}
      </StatusList>
    )}
    {(thumbnail && thumbnail?.mediaType === 'IMAGE') || srcSet ? (
      <Thumbnail
        alt=""
        aria-hidden="true"
        srcSet={srcSet}
        src={thumbnail?.thumbnail || DefaultThumbnail}
        sizes={cardWidth}
      />
    ) : (
      <NoThumbnail aria-hidden="true">
        <ImageIcon />
      </NoThumbnail>
    )}
    <Overlay />
    {children}
  </Container>
);

export default BaseCard;
