import { VideoContentType } from 'components/Video/types';
import { LiveEvent } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getEvent = createAsyncAction(
  'event/GET_EVENT_REQUEST',
  'event/GET_EVENT_SUCCESS',
  'event/GET_EVENT_FAILURE',
)<{ slug: string }, LiveEvent, Error>();

export const sendEventProgress = createAsyncAction(
  'video/SEND_EVENT_PROGRESS_REQUEST',
  'video/SEND_EVENT_PROGRESS_SUCCESS',
  'video/SEND_EVENT_PROGRESS_FAILURE',
)<
  { id: string; progress: number; contentType: VideoContentType },
  undefined,
  undefined
>();

export const updateEventProgress = createAction('event/UPDATE_EVENT_PROGRESS')<{
  id: string;
  progress: number;
  contentType: VideoContentType;
}>();
