import {
  Avatar as DefaultAvatar,
  ShareButton as DefaultShareButton,
  UserProfileFollowButton as DefaultUserProfileFollowButton,
} from 'components';
import { Picture } from 'components/Avatar/styles';
import {
  AchievementIcon as DefaultAchievementIcon,
  CogIcon as DefaultCogIcon,
  LocationIcon as DefaultLocationIcon,
} from 'components/icons';
import { ShareIcon, ShareIconContainer } from 'components/ShareButton/styles';
import {
  BadgeBackground as StyledBadgeBackground,
  BadgeContainer as StyledBadgeContainer,
  SWDLogoIcon as StyledSWDLogoIcon,
} from 'components/SWDTeamOrPremiumBadge/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { from, visuallyHidden, whiteBox } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { $AchievementIcon } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${from.tabletH`
    gap: 18px;
  `}
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${from.tabletH`
    gap: 12px;
  `}
`;

export const SummaryContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-end;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Avatar = styled(DefaultAvatar)<{ swdTeam?: boolean }>`
  border: none;
  margin: 0;
  height: 5rem;
  width: 5rem;
  border-radius: 4px;

  ${Picture} {
    border-radius: 4px;
  }

  ${StyledBadgeBackground} {
    display: none;
  }

  ${StyledBadgeContainer} {
    width: 25px;
    height: 25px;
    left: 53px;
    bottom: 2px;
  }

  ${StyledSWDLogoIcon} {
    width: 17px;
    height: 17px;
  }

  ${from.tabletH`
    ${visuallyHidden}
  `}
`;

export const NameContainer = styled.div`
  display: flex;
  gap: 21px;
  align-items: center; /* @todo Pending achievements: align-items: flex-end; */

  ${from.tabletH`
    align-items: center;
  `}
`;

export const Name = styled.h2`
  font-size: 28px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 28px;
  text-align: left;
  margin: 0;
`;

export const Actions = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const ShareButton = styled(DefaultShareButton)`
  ${ShareIconContainer} {
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.greyBlue};
    border-radius: 4px;
    padding: 0;
  }

  ${ShareIcon} {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const EditProfileContainer = styled.div`
  position: relative;
  display: flex;
`;

export const CogIconContainer = styled.div`
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;

  :hover {
    padding: 0 1rem 1rem;
    margin: 0 -1rem -1rem;

    ~ div {
      display: flex;
    }
  }
`;

export const CogIcon = styled(DefaultCogIcon)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.0625rem solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  padding: 0.438rem;
`;

export const EditProfileContent = styled.div`
  ${whiteBox}

  display: none;
  flex-direction: column;
  top: 3rem;
  right: 0;
  min-width: 11rem;
  padding: 1.3125rem 0;
  position: absolute;
  z-index: 1;

  *:not(:last-child) {
    margin-bottom: 1rem;
  }

  :hover {
    display: flex;
  }

  ${from.laptop`
    left: 0;
  `};
`;

export const EditProfile = styled(Link)`
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.6)};
  padding: 0 1.125rem;
  font-size: 0.875rem;
  line-height: 1rem;
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const PersonalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${from.tabletH`
    flex-direction: row;
    align-items: center;
  `}
`;

export const FollowContainer = styled.div`
  display: flex;
  gap: 18px;
  justify-content: flex-end; /* @todo Remove when enable follow */

  ${from.tabletH`
    ${visuallyHidden}
  `}
`;

export const FollowButton = styled(DefaultUserProfileFollowButton)``;

export const Position = styled.h4`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 14px;
  letter-spacing: 0.44px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
`;

export const Location = styled.p`
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 19.6px;
  text-align: left;
  margin: 0;
`;

export const LocationIcon = styled(DefaultLocationIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.accent};
`;

export const AchievementsContainer = styled.div`
  display: flex;
  gap: 9px;
  flex-wrap: wrap;
`;

export const AchievementContainer = styled.div`
  position: relative;
`;

export const AchievementIcon = styled(DefaultAchievementIcon)<$AchievementIcon>`
  color: ${({ theme, $color }) => theme.colors[$color]};
`;

export const AchievementCount = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16.51px;
  height: 16.51px;
  border-radius: 82.54px;
  background: ${({ theme }) => theme.colors.basicGold};
  font-size: 11.56px;
  font-weight: 700;
  line-height: 11.56px;
  letter-spacing: 0.36px;
  text-align: left;
  color: ${({ theme }) => theme.colors.judge};
`;
