import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ChallengeState } from './types';

const selectState: Selector<ChallengeState> = (state) => state.challenge;

export const selectChallengesBySlug = createSelector(
  selectState,
  ({ bySlug }) => bySlug,
);

export const selectChallenges = createSelector(selectState, ({ byId, ids }) =>
  ids.map((id) => byId[id]).filter((it): it is NonNullable<typeof it> => !!it),
);
