import Media from 'components/MediaSelector/MultiFileVariant/FileList/Media';
import { Container as UploadedFileDelete } from 'components/MediaSelector/MultiFileVariant/FileList/Media/Delete/styles';
import styled, { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const DropZone = styled.div<{
  canAdd: boolean;
  compact: boolean;
  isMouseInput: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23297FD5FF' stroke-width='2' stroke-dasharray='6%2c 8' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e");
  display: flex;
  flex: 1;
  min-height: 7.875rem;
  outline: none;
  margin-left: 0;

  ${({ compact }) =>
    compact &&
    css`
      min-height: 6rem;
      max-width: 6rem;
      max-height: 6rem;
    `};

  ${({ canAdd, theme }) =>
    canAdd &&
    css`
      :hover {
        background-color: ${addAlpha(theme.colors.accent, 0.1)};
        box-shadow: 0 6px 29px -10px ${addAlpha(theme.colors.grey400, 0.4)};
      }
    `};

  ${({ isMouseInput }) =>
    isMouseInput &&
    css`
      max-width: none;
      max-height: 16rem;
      margin-left: 0;
    `};
`;

export const SingleFile = styled(Media)`
  margin: auto;

  :hover {
    border-color: ${({ theme }) => theme.colors.white};
  }

  ${UploadedFileDelete} {
    display: block;
  }
`;
