import SearchOptions from 'containers/Common/SearchOptions';
import { FirstGroupFields } from 'containers/Common/SearchOptions/Form/styles';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div``;

export const Link = styled(DefaultLink)`
  color: inherit;
  text-decoration: none;
`;

export const SearchOptionsChallenges = styled(SearchOptions)`
  ${FirstGroupFields} {
    ${from.laptop`
    grid-column: 1/2;
    `}
  }
`;
