import { addVote, removeVote } from 'redux/modules/vote';
import { serializeIntent } from 'utils/intent';
import { Props } from './types';

const createAddVoteAction = addVote.request;
const createRemoveVoteAction = removeVote.request;

export const handleVoteClicked = ({
  addVote: dispatchAddVoteAction,
  beginIntent,
  contentType,
  id,
  isComment,
  isVoted,
  loggedIn,
  removeVote: dispatchRemoveVoteAction,
  url,
}: {
  addVote: Props['addVote'];
  beginIntent: Props['beginIntent'];
  contentType: Props['contentType'];
  id: Props['id'];
  isComment: Props['isComment'];
  isVoted: Props['isVoted'];
  loggedIn: Props['loggedIn'];
  removeVote: Props['removeVote'];
  url: Props['url'];
}) => () => {
  const payload = {
    contentType: isComment ? 'comment' : contentType,
    objectId: id,
  };

  if (loggedIn) {
    if (isVoted) {
      dispatchRemoveVoteAction(payload);
    } else {
      dispatchAddVoteAction(payload);
    }
  } else {
    beginIntent({
      intent: serializeIntent([
        isVoted
          ? createRemoveVoteAction(payload)
          : createAddVoteAction(payload),
      ]),
      returnTo: url,
    });
  }
};
