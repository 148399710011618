import styled from 'styled-components';

export const Container = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.sky};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  flex-shrink: 0;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-bottom: 0.25rem;
  padding: 0.3125rem 0.5rem 0.1875rem;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.weights.semiBold};

  :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;

export const ClosedStyled = styled(Container)`
  background-color: ${({ theme }) => theme.colors.grey400};
  color: white;
`;

export const Status = styled(Container)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
`;
