import {
  UpdateCompanyDetailsCorporatePremiumForm,
  UPDATE_COMPANY_DETAILS_CORPORATE_PREMIUM_FORM_KEY,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm } from 'redux-form';
import { updateCompanyName } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = ({ premium }: RootState) => {
  const currentCompanyName = premium.userInfo?.groupSubscription?.companyName;
  const initialValues: ConfigProps<
    UpdateCompanyDetailsCorporatePremiumForm
  >['initialValues'] = {
    companyName: currentCompanyName,
  };
  return {
    initialValues,
  };
};

const mapDispatchToProps = {
  updateCompanyName: updateCompanyName.request,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<UpdateCompanyDetailsCorporatePremiumForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<UpdateCompanyDetailsCorporatePremiumForm>({
    form: UPDATE_COMPANY_DETAILS_CORPORATE_PREMIUM_FORM_KEY,
  }),
) as FormDecorator<
  UpdateCompanyDetailsCorporatePremiumForm,
  OwnProps,
  ConnectedProps
>;
