import React, { FC, useEffect, useRef } from 'react';
import { Container, MinusIcon, PlusIcon, Summary, Title } from './styles';
import { Props } from './types';

const Expander: FC<Props> = ({ children, open, title, ...rest }) => {
  const ref = useRef<HTMLDetailsElement>(null);

  // details by default are collapsed, so open it.
  useEffect(() => {
    if (ref.current && open) {
      ref.current.open = true;
    }
  }, [ref, open]);

  return (
    <Container ref={ref} {...rest}>
      <Summary>
        <PlusIcon />
        <MinusIcon />
        <Title>{title}</Title>
      </Summary>
      {children}
    </Container>
  );
};

export default Expander;
