import {
  MembersOrderEnum,
  MembersOrderOption,
} from 'containers/Main/Members/MembersRoot//MembersTable/MembersOrders/Form/types';
import { SearchMembersFieldNames } from 'model';
import React, { FC, useCallback, useEffect } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import PeriodSelector from './PeriodSelector';
import {
  Container,
  CourseSelect,
  Order,
  OrderBooleanInput,
  OrderDirection,
  OrderSelect,
  SearchInput,
  VisuallyHiddenLabel,
} from './styles';
import { Props } from './types';

const ORDERING_OPTIONS: MembersOrderOption[] = [
  {
    label: 'Join date',
    value: MembersOrderEnum.JOIN,
  },
  {
    label: 'Alphabetical',
    value: MembersOrderEnum.ALPHABETICAL,
  },
];

const Form: FC<Props> = ({
  baseUrl,
  push,
  section,
  search,
  period,
  getCourses,
  courses,
  handleSubmit,
  order,
  className,
}) => {
  const disabled = !order;
  const formatDirection = useCallback(
    (value: boolean) => (disabled ? false : value),
    [disabled],
  );

  useEffect(() => {
    if (section === 'scholars') {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  return (
    <Container className={className} onSubmit={handleSubmit}>
      {section !== 'scholars' ? (
        <Field
          component={SearchInput}
          type="text"
          name={SearchMembersFieldNames.query}
          placeholder="Search a member..."
        />
      ) : (
        <Field
          component={CourseSelect}
          name={SearchMembersFieldNames.course}
          options={[
            { value: '', label: 'Select a course' },
            ...courses.map(({ id, name }) => {
              return {
                value: id,
                label: `${name.slice(0, 1).toUpperCase()}${name
                  .slice(1)
                  .toLowerCase()}`,
              };
            }),
          ]}
        />
      )}

      {section === 'activity' || section === 'all' ? (
        <PeriodSelector
          baseUrl={`${baseUrl}/${section}`}
          push={push}
          search={search}
          value={period}
        />
      ) : null}

      {section === 'all' ? (
        <Order>
          <VisuallyHiddenLabel htmlFor={SearchMembersFieldNames.order}>
            Sort by
          </VisuallyHiddenLabel>

          <Field
            component={OrderSelect}
            name={SearchMembersFieldNames.order}
            options={ORDERING_OPTIONS}
          />

          <Field
            CheckboxComponent={OrderDirection}
            component={OrderBooleanInput}
            name={SearchMembersFieldNames.directionIsAsc}
            format={formatDirection}
            disabled={disabled}
          />
        </Order>
      ) : null}
    </Container>
  );
};

export default connect(Form);
