import { FormNameMap } from 'utils/forms';

export const CHANGE_EMAIL_FORM_KEY = 'forms/CHANGE_EMAIL';

export interface ChangeEmailForm {
  email: string;
  emailConfirm: string;
}

export const ChangeEmailFieldNames: FormNameMap<ChangeEmailForm> = {
  email: 'email',
  emailConfirm: 'emailConfirm',
};

export * from './logic';
