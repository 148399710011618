import { RootState, Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { FeedbackState } from './types';

const selectState: Selector<FeedbackState> = ({ feedback }) => feedback;

export const selectFeedbackById = (state: RootState) => state.feedback.byId;
export const selectFeedbackBySlug = (state: RootState) => state.feedback.bySlug;

export const selectFeedbackLoading = createSelector(
  selectState,
  (state) => state.loading,
);

export const selectFeedbackRequests = createSelector(
  selectState,
  ({ byId, ids }) =>
    ids
      .map((id) => byId[id])
      .filter((it): it is NonNullable<typeof it> => !!it),
);
