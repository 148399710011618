import { interval, race } from 'rxjs';
import { filter, map, mapTo, take } from 'rxjs/operators';

export const waitForElement = (
  id: string,
  timeout = 1000,
): Promise<HTMLElement | null> =>
  race(
    interval(1).pipe(
      map(() => document.getElementById(id)),
      filter((it): it is NonNullable<typeof it> => it !== null),
      take(1),
    ),
    interval(timeout).pipe(mapTo(null), take(1)),
  ).toPromise();
