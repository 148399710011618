import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ConversationState } from './types';

const selectState: Selector<ConversationState> = (state) => state.conversation;

export const selectConversationsById = createSelector(
  selectState,
  (state) => state.byId,
);

export const selectConversationsBySlug = createSelector(
  selectState,
  (state) => state.bySlug,
);

export const selectConversationsLoading = createSelector(
  selectState,
  (state) => state.loading,
);

export const selectConversations = createSelector(
  selectState,
  ({ byId, ids }) =>
    ids
      .map((id) => byId[id])
      .filter((it): it is NonNullable<typeof it> => !!it),
);
