import React, { FC } from 'react';
import { Container } from './styles';
import { Props } from './types';

const ProfileLink: FC<Props> = ({ children, target, ...rest }) => (
  <Container target={target} rel="noreferrer noopener" {...rest}>
    {children}
  </Container>
);

export default ProfileLink;
