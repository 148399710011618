import { PodcastIcon as DefaultPodcastIcon } from 'components/icons';
import styled from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.125rem;
`;

export const PodcastIcon = styled(DefaultPodcastIcon)`
  cursor: pointer;
  height: 100%;
  margin-right: ${({ theme }) => theme.spacing.md};
  width: 32px;
`;

export const Link = styled.a`
  align-items: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  border-radius: 0.25rem;
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.125rem 1.5rem;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;
