import { InternalLink } from 'components/Link/styles';
import DefaultRawText from 'components/RawText';
import DefaultTags from 'components/Tags';
import styled from 'styled-components';
import { from, whiteBox } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { Avatar as DefaultAvatar } from '../index';

export const ConversationGrid = styled.div`
  display: grid;
  grid-template-columns: 2.35rem 1fr 1.375rem;
  flex-grow: 1;
  padding: 1.25rem 1rem 1.25rem 1.25rem;
  border-bottom: solid 1px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  ${from.tabletH`
    grid-template-columns: 2.35rem 1fr 1.375rem;
    grid-template-rows: auto auto auto;
    border: none;
  `};
`;

export const Avatar = styled(DefaultAvatar)`
  grid-column: 1/2;
  grid-row: 1/2;
  box-sizing: border-box;
  margin-bottom: 0;
  height: 2.5rem;
  width: 2.5rem;
  ${from.tabletH`
    grid-row: 1/4;
  `};
`;

export const Title = styled.h3`
  grid-column: 1/3;
  grid-row: 2/3;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  font-size: 1.125rem;
  line-height: normal;
  ${from.tabletH`
    grid-column: 2/3;
    grid-row: 1/2;
    padding-left: 0.625rem;
    margin-top: 0;
    margin-bottom:0.188rem;
  `};
`;

export const AuthorContainer = styled.div`
  opacity: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  grid-column: 2/3;
  grid-row: 1/2;
  padding-left: 0.625rem;
  ${from.tabletH`
    flex-direction: row;
    grid-row: 2/3;
  `};
`;

export const Author = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.weights.regular};
  margin: 0;
`;

export const AmaDate = styled.p`
  opacity: 1;
  height: auto;

  display: flex;
  flex-direction: column;
  grid-column: 2/3;
  grid-row: 1/2;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.weights.regular};
  padding-left: 0.625rem;
  margin: 0;
  > :last-child {
    color: ${({ theme }) => theme.colors.grey400};
    font-size: 0.75rem;
    letter-spacing: 0.2px;
    line-height: normal;
  }
  ${from.tabletH`
    grid-row: 2/3;
    flex-direction: row;
    > :last-child {
      font-size: 1rem;
      letter-spacing: normal;
      line-height: 1.5;
      color: ${({ theme }) => theme.colors.black};
      margin-left: 6px;
      ::before{
        content: " | ";
      }
    }
  `};
`;

export const Date = styled.p`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.75rem;
  margin: 0;
  letter-spacing: 0.2px;
  ${from.tabletH`
  font-size: 1rem;
  margin-left: 5px;
  line-height: 1.5;
    ::before{
      content: " · ";
    }
  `}
`;

export const RawText = styled(DefaultRawText)`
  opacity: 0;
  height: 0;
  transition: opacity 400ms ease-out;
  word-break: break-word;
  grid-column: 2/3;
  grid-row: 2/4;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black};
  margin-top: ${({ theme }) => theme.spacing.xsm};
  padding-right: ${({ theme }) => theme.spacing.md};
  ${from.tablet`
    padding-left: 0.625rem;
  `}
`;

export const StatusesAndTags = styled.div`
  opacity: 1;
  width: 100%;
  height: auto;
  display: flex;
  grid-column: 1/4;
  grid-row: 3/4;
  flex-wrap: wrap;
  ${from.tabletH`
    margin-top: ${({ theme }) => theme.spacing.sm};
    grid-column: 2/3;
    padding-left: 0.625rem;
  `};
`;

export const Tags = styled(DefaultTags)`
  flex: 1;
`;

export const Statistics = styled.div`
  width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
  > :first-child {
    padding: 0;
  }
  ${InternalLink} {
    grid-row: 2/3;
    grid-column: 1/4;
    margin-left: 0;
    width: 100%;
    box-sizing: border-box;
    ${from.tablet`
      grid-row: 1/2;
      grid-column: 3/4;
      margin-left: 1.5rem;
      width: auto;
    `};
  }
  ${from.tablet`
    max-width: 26rem;

  `};
  ${from.tabletH`
    padding: 0.25rem 1rem;
    max-width: 26rem;
    align-self: center;
    border-left: solid 1px ${({ theme }) =>
      addAlpha(theme.colors.grey400, 0.3)};
  `};
  ${from.laptop`
  margin-left: auto;
    `};
`;

export const DatapointsContainer = styled.div`
  grid-column: 3/4;
  grid-row: 1/3;
  text-align: center;

  ${from.tabletH`
    grid-row: 1/4;
    align-self: center;
  `};
`;

export const DatapointsValue = styled.span`
  font-size: 0.875rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 2px;
`;

export const Container = styled.div<{
  isAma?: boolean;
  live?: boolean;
  isMonthly?: boolean;
  isSpotlight?: boolean;
}>`
  ${whiteBox};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 0.25rem;
  min-height: 7.75rem;
  transition: all 200ms ease-in-out;
  border-left: ${({ theme, isAma, live }) =>
    isAma && `6px solid ${theme.colors[live ? 'error' : 'accent']}`};
  border-left: ${({ theme, isSpotlight }) =>
    isSpotlight && `6px solid ${theme.colors.accent}`};
  border-left: ${({ theme, isMonthly }) =>
    isMonthly && `6px solid ${theme.colors.primary}`};
  &:hover {
    box-shadow: 0 2px 20px -7px ${({ theme }) => addAlpha(theme.colors.grey400, 0.75)};
    border: solid 1px ${({ theme }) => theme.colors.grey300};
    border-left: ${({ theme, isAma, live }) =>
      isAma && `6px solid ${theme.colors[live ? 'error' : 'accent']}`};
    border-left: ${({ theme, isMonthly }) =>
      isMonthly && `6px solid ${theme.colors.primary}`};
    border-left: ${({ theme, isSpotlight }) =>
      isSpotlight && `6px solid ${theme.colors.accent}`};

    ${StatusesAndTags}, ${AmaDate}, ${AuthorContainer} {
      ${from.tabletH`
        opacity: 0; 
        height: 0;
        pointer-events: none;
      `};
    }
    ${RawText} {
      ${from.tabletH`
        height: 56px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        opacity: 1;
        pointer-events: auto;
      `};
    }
  }
  ${from.tabletH`
    flex-direction: row;
  `};
`;
