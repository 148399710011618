import CommunityIcon from 'assets/images/logoCommunity.svg';
import DropdownMenu from 'components/DropdownMenu';
import { BellIcon } from 'components/icons';
import LiveTag from 'components/LiveTag';
import { LiveIndicator } from 'components/LiveTag/styles';
import SWDLogo from 'components/SWDLogo';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useLockBodyScroll } from 'utils/lockBodyScroll';
import { isNotFalsy } from 'utils/NonFalsy';
import DropdownMenuMobile from './DropdownMenuMobile';
import SearchButton from './SearchButton';
import {
  Avatar,
  AvatarWrapper,
  Burger,
  BurgerButton,
  BurgerCommunity,
  BurgerContent,
  BurgerDropdownContent,
  BurgerLink,
  BurgerLinks,
  BurgerMenu,
  BurgerPremiumLink,
  CommunityDropdownMenu,
  CommunityHeader,
  CommunityHeaderWrapper,
  CommunityLink,
  CommunityPremiumLink,
  Container,
  DropdownUserMenu,
  Label,
  LastLink,
  Link,
  Links,
  LiveLabel,
  LoginBox,
  LoginLabel,
  LogoCommunityIcon,
  LogoCommunityLink,
  MainHeader,
  MainTitle,
  MembersBox,
  MembersLabel,
  NotificationsLink,
  OneLink,
  SectionLinks,
  UserIcon,
  UserName,
  UsersIcon,
} from './styles';
import { Props } from './types';

const userDropdownTrigger = ({
  avatar,
  variant,
}: {
  avatar: string | undefined;
  variant: 'swd' | 'premium' | 'default';
}) => <Avatar src={avatar} variant={variant} />;

const Header: FC<Props> = ({
  onLogOut,
  pathname,
  userAvatar,
  userName,
  userType,
  isLive,
}) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu((prevState) => !prevState);
  }, [setShowMobileMenu]);

  // Practice and Profile Submenus will stay open if they've just been clicked on. Otherwise, submenus will be closed after redirect.
  useEffect(() => {
    setShowMobileMenu(false);
  }, [pathname]);

  // When the user logs out, close the mobile menu
  useEffect(() => {
    setShowMobileMenu(false);
  }, [setShowMobileMenu, userAvatar]);

  useLockBodyScroll(showMobileMenu);

  return (
    <Container>
      <BurgerMenu>
        <BurgerContent>
          <BurgerCommunity to="/">
            <LogoCommunityIcon alt="SWD Community logo" src={CommunityIcon} />
          </BurgerCommunity>
          <Burger
            mobileMenu={showMobileMenu}
            onClick={toggleMobileMenu}
            aria-label="Menu button"
          >
            <div />
            <div />
            <div />
          </Burger>
        </BurgerContent>
        {showMobileMenu && (
          <BurgerLinks>
            <SectionLinks isLogged={!!userName}>
              <DropdownMenuMobile
                pathname={pathname}
                items={[
                  {
                    label: 'Videos',
                    path: '/videos',
                  },
                  {
                    label: 'Events',
                    path: '/live-events',
                  },
                ]}
                name="Watch"
              />
              <DropdownMenuMobile
                pathname={pathname}
                items={[
                  {
                    label: 'challenges',
                    path: '/challenges',
                  },
                  {
                    label: 'exercises',
                    path: '/exercises',
                  },
                ]}
                name="Practice"
              />
              <DropdownMenuMobile
                pathname={pathname}
                items={[
                  {
                    label: 'feedback',
                    path: '/feedback',
                  },
                  {
                    label: 'office hours',
                    path: '/office-hours',
                  },
                ]}
                name="Refine"
              />
              <BurgerLink to="/conversations">Talk</BurgerLink>
              <BurgerLink to="/discover">Discover</BurgerLink>
              {userType === 'default' && (
                <BurgerPremiumLink to="/premium">Go Premium!</BurgerPremiumLink>
              )}
              <BurgerLink to="/members">Members</BurgerLink>
            </SectionLinks>
            <OneLink>
              {userName && (
                <>
                  <AvatarWrapper>
                    <Avatar
                      src={userAvatar}
                      variant="default"
                      aria-hidden="true"
                    />
                  </AvatarWrapper>
                  <UserName>
                    <Label>{userName}</Label>
                  </UserName>
                  <BurgerDropdownContent>
                    <BurgerLink to="/members/me">Profile</BurgerLink>
                    <BurgerLink to="/members/me/settings">
                      Account Settings
                    </BurgerLink>
                    <BurgerLink to="/support">Support</BurgerLink>
                    <BurgerButton onClick={onLogOut}>Log out</BurgerButton>
                  </BurgerDropdownContent>
                </>
              )}
              {!userName && <BurgerLink to="/login">Log in</BurgerLink>}
            </OneLink>
            <LastLink>
              <Link to="http://www.storytellingwithdata.com/">
                Back to SWD website
              </Link>
            </LastLink>
            <SearchButton />
          </BurgerLinks>
        )}
      </BurgerMenu>
      <MainHeader>
        <MainTitle href="http://www.storytellingwithdata.com">
          <SWDLogo />
        </MainTitle>
        <Links>
          <DropdownMenu
            items={[
              {
                label: 'Blog',
                href: 'http://www.storytellingwithdata.com/blog',
              },
              {
                label: 'Books',
                href: 'http://www.storytellingwithdata.com/books',
              },
              {
                label: 'Chart guide',
                href: 'http://www.storytellingwithdata.com/chart-guide',
              },
              {
                label: 'Makeovers',
                href: 'http://www.storytellingwithdata.com/makeovers',
              },
            ]}
            name="Read"
          />
          <DropdownMenu
            items={[
              {
                label: 'Podcast',
                href: 'http://www.storytellingwithdata.com/podcast',
              },
              {
                label: 'Videos',
                href: 'http://www.storytellingwithdata.com/videos',
              },
            ]}
            name="Listen"
          />
          <DropdownMenu
            items={[
              {
                href: 'http://www.storytellingwithdata.com/public-workshops',
                label: 'Public Workshops',
              },
              {
                href: 'http://www.storytellingwithdata.com/custom-workshops',
                label: 'Custom Workshops',
              },
              {
                href: 'http://www.storytellingwithdata.com/reach',
                label: 'SWD Reach program',
              },
            ]}
            name="Attend"
          />
          <DropdownMenu
            name="Participate"
            items={[
              {
                label: 'SWD Community',
                href: '/',
              },
              {
                label: 'What is SWD community?',
                href: 'https://www.storytellingwithdata.com/community',
              },
              {
                label: 'Instructor group',
                href: 'https://www.storytellingwithdata.com/university',
              },
            ]}
          />
          <DropdownMenu
            items={[
              {
                href: 'http://www.storytellingwithdata.com/contact',
                label: 'Contact Us',
              },
              {
                href: 'http://www.storytellingwithdata.com/meet-the-team',
                label: 'Meet the Team',
              },
              {
                href: 'https://www.storytellingwithdata.com/shop',
                label: 'SWD Shop',
              },
              {
                href: 'https://www.storytellingwithdata.com/careers',
                label: 'Careers',
              },
            ]}
            name="Contact"
          />
        </Links>
        <SearchButton />
      </MainHeader>
      <CommunityHeaderWrapper>
        <CommunityHeader>
          <LogoCommunityLink to="/">
            <LogoCommunityIcon alt="SWD Community logo" src={CommunityIcon} />
          </LogoCommunityLink>
          <Links>
            <CommunityDropdownMenu
              items={[
                {
                  label: 'videos',
                  path: '/videos',
                },
                {
                  label: (
                    <LiveLabel key="Live Events">
                      <span>events</span>
                      {isLive && <LiveTag />}
                    </LiveLabel>
                  ),
                  path: '/live-events',
                },
              ]}
              name={
                <LiveLabel>
                  {isLive && <LiveIndicator />}
                  <span>Watch</span>
                </LiveLabel>
              }
            />
            <CommunityDropdownMenu
              items={[
                {
                  label: 'challenges',
                  path: '/challenges',
                },
                {
                  label: 'exercises',
                  path: '/exercises',
                },
              ]}
              name="Practice"
            />
            <CommunityDropdownMenu
              items={[
                {
                  label: 'feedback',
                  path: '/feedback',
                },
                {
                  label: 'office hours',
                  path: '/office-hours',
                },
              ]}
              name="Refine"
            />
            <CommunityLink to="/conversations">Talk</CommunityLink>
            <CommunityLink to="/discover">Discover</CommunityLink>
            {userType === 'default' && (
              <CommunityPremiumLink to="/premium">
                Go Premium!
              </CommunityPremiumLink>
            )}
          </Links>
          <MembersBox to="/members">
            <UsersIcon />
            <MembersLabel>Members</MembersLabel>
          </MembersBox>
          {!userName && (
            <LoginBox to="/login">
              <UserIcon />
              <LoginLabel>Log In</LoginLabel>
            </LoginBox>
          )}
          {userName && (
            <NotificationsLink
              to="/activity"
              $unread={false}
              aria-label="Activity"
            >
              <BellIcon />
            </NotificationsLink>
          )}
          {userName && (
            <DropdownUserMenu
              items={[
                {
                  label: userName,
                },
                {
                  spacer: true,
                },
                {
                  label: 'Profile',
                  path: '/members/me',
                },
                userType === 'default' && {
                  label: 'Go Premium!',
                  path: '/premium',
                  highlighted: true,
                },
                {
                  label: 'Account Settings',
                  path: '/members/me/settings',
                },
                {
                  label: 'Support',
                  path: '/support',
                },
                {
                  spacer: true,
                },
                {
                  action: onLogOut,
                  label: 'Log out',
                },
              ].filter(isNotFalsy)}
              trigger={userDropdownTrigger({
                avatar: userAvatar,
                variant: 'default',
              })}
            />
          )}
        </CommunityHeader>
      </CommunityHeaderWrapper>
    </Container>
  );
};

export default memo(Header);
