import { FormNameMap } from 'utils/forms';

export const GROUP_CODE_FORM_KEY = 'forms/GROUP_CODE';

export interface GroupCodeForm {
  groupCode: string | null;
}

export const GroupCodeFormNames: FormNameMap<GroupCodeForm> = {
  groupCode: 'groupCode',
};
