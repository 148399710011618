import { FeedbackRequestExpanded } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getMyOpenFeedbackItems } from './actions';

export const normalizeMyFeedback = (response: FeedbackRequestExpanded) => ({
  ...response,
  id: response.id,
  created: response.created,
  modified: response.modified,
  image: response.imageList?.mainImage,
  datapoints: response.votesCount,
  commentsCount: response.commentsCount,
  views: response.hits,
  comments: response.comments?.map((comment) => ({
    id: comment.id,
    created: comment.created,
    userAvatar: comment.user.avatar,
    userName: `${comment.user.firstName} ${comment.user.lastName}`,
    comment: comment.comment,
  })),
});

export const getMyOpenFeedbackItemsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getMyOpenFeedbackItems.request)),
    mergeMap(() =>
      request<FeedbackRequestExpanded>({
        path: 'feedback/my-open',
      }).pipe(
        mergeMap((response) => {
          return [
            getMyOpenFeedbackItems.success(normalizeMyFeedback(response)),
          ];
        }),
        catchError(() => [getMyOpenFeedbackItems.failure()]),
      ),
    ),
  );

export default combineEpics(getMyOpenFeedbackItemsEpic);
