import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parse from 'date-fns/parse';
import { ChangeEvent } from 'react';
import { Props } from './types';

const BROWSER_DATE_FORMAT = 'yyyy-MM-dd';

const fromNativeInputDate = (val: string) =>
  parse(val, BROWSER_DATE_FORMAT, new Date(0));

export const handleChange = ({
  onChange,
}: {
  onChange: Props['input']['onChange'];
}) => ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
  onChange(fromNativeInputDate(value));

export const toNativeInputDate = (val: Date | null) =>
  val instanceof Date && isValid(val) ? format(val, BROWSER_DATE_FORMAT) : '';
