import Label from 'components/Label';
import React, { forwardRef } from 'react';
import { Container, Error } from './styles';
import { Props } from './types';

const BaseInput = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      label,
      labelProps = {},
      meta: { error, touched },
      name,
      ...rest
    },
    ref,
  ) => (
    <Container invalid={touched && error} ref={ref} {...rest}>
      {label && (
        <Label htmlFor={name} {...labelProps}>
          {label}
        </Label>
      )}
      {children}
      {touched && error && <Error text={error} />}
    </Container>
  ),
);

export default BaseInput;
