import Link from 'components/Link';
import DefaultSolvedBySwdTag from 'components/SolvedBySwdTag';
import DefaultTags from 'components/Tags';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import LinesWrapper from '../LinesWrapper';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  box-sizing: border-box;
  position: relative;
  :hover {
    box-shadow: 0 2px 20px -7px ${({ theme }) => addAlpha(theme.colors.grey500, 0.7)};
    border-color: ${({ theme }) => theme.colors.grey300};
  }
`;

export const Tags = styled(DefaultTags)`
  display: flex;
  flex-wrap: wrap;
  min-height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const CardLink = styled(Link)`
  :visited,
  :link,
  :active {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
  }
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StatusList = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.lg};
  ${from.tablet`
    margin-left: auto;
    width: auto;
  `};
`;

export const Status = styled.span<{
  alreadySubmitted: boolean;
  solvedByUser: boolean;
  expiresSoon: boolean;
}>`
  width: 100%;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.accent};
  display: block;
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1rem;
  letter-spacing: 1px;
  padding: 0.9rem 0.625rem 0.8rem 0.7rem;
  text-transform: uppercase;

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }

  ${({ alreadySubmitted }) =>
    alreadySubmitted &&
    css`
      background-color: ${({ theme }) => theme.colors.grey100};
    `};

  ${({ expiresSoon }) =>
    expiresSoon &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};
    `};

  ${({ solvedByUser }) =>
    solvedByUser &&
    css`
      background-color: ${({ theme }) => theme.colors.grey100};
    `};

  ${from.laptop`
    width: max-content;
  `};
`;

export const Thumbnail = styled.img`
  display: block;
  height: 13rem;
  object-fit: cover;
  width: 100%;
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: ${({ theme }) => addAlpha(theme.colors.bookLight, 0.15)};
  top: 0;
  left: 0;
  height: 13rem;
  width: 100%;
`;

export const Content = styled.div`
  padding: ${({ theme }) =>
    `${theme.spacing.lg} ${theme.spacing.xlg} ${theme.spacing.xlg}`};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const TITLE_LINE_HEIGHT = '2rem';
const TITLE_MAX_LINES = 2;

export const Title = styled(LinesWrapper).attrs({
  maxLines: TITLE_MAX_LINES,
  forwardedAs: 'h3',
})`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: ${TITLE_LINE_HEIGHT};
  margin-bottom: ${({ theme }) => theme.spacing.sm};

  ${from.tablet`
    font-size: 1.75rem;
  `}
`;

export const EndDate = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  min-height: 1.5rem;

  ${from.mobileXL`
    align-items: center;
    flex-direction: row;
  `};
`;

export const EndDateGroup = styled.div`
  font-size: 1rem;
`;

export const EndDateTitle = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin-right: 0.5625rem;
  text-transform: uppercase;
`;

export const EndDateValue = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.76px;
  text-transform: uppercase;
`;

export const Description = styled(LinesWrapper).attrs({
  maxLines: 3,
  forwardedAs: 'p',
})`
  margin-top: 0;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.5rem;
  flex-grow: 1;
`;

export const BottomRowElements = styled.div`
  display: flex;
  margin-top: auto;
  flex-direction: column;
  ${from.tablet`
    flex-direction: row;
  `};
  ${from.tablet`
    flex-direction: row;
  `};
`;

export const SolvedBySwdTag = styled(DefaultSolvedBySwdTag)`
  margin-bottom: 0.75rem;

  ${from.mobileXL`
    margin-bottom: 0;
    margin-left: auto;
  `};
`;
