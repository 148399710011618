import React, { FC } from 'react';
import { formatMonthName } from 'utils/dates';
import { pluralize } from 'utils/pluralize';
import {
  Contributions,
  Graph,
  InfoRow,
  InfoTable,
  LegendArea,
  LegendSquare,
  LegendText,
  Statistic,
  StatisticKey,
  StatisticsRow,
  StatisticValue,
  SummaryContainer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Title,
  Tr,
} from './styles';
import { Props } from './types';

const Summary: FC<Props> = ({
  challenges,
  comments,
  datapoints,
  datapointsGiven,
  exercises,
  feedbackGiven,
  feedbackRequested,
  heatMapData,
}) => (
  <>
    <SummaryContainer>
      <Title>Summary</Title>
      <StatisticsRow>
        <Statistic>
          <StatisticValue>{String(challenges)}</StatisticValue>
          <StatisticKey>
            {pluralize(challenges, 'challenge', 'challenges')}
            <br />
            Completed
          </StatisticKey>
        </Statistic>

        <Statistic>
          <StatisticValue>{String(exercises)}</StatisticValue>
          <StatisticKey>
            {pluralize(exercises, 'exercise', 'exercises')}
            <br />
            Solved
          </StatisticKey>
        </Statistic>

        <Statistic>
          <StatisticValue>{String(comments)}</StatisticValue>
          <StatisticKey>
            {pluralize(comments, 'Comment', 'Comments')}
            <br />
            added
          </StatisticKey>
        </Statistic>

        <Statistic>
          <StatisticValue>{feedbackRequested}</StatisticValue>
          <StatisticKey>
            Feedback
            <br />
            {pluralize(feedbackRequested, 'request', 'requests')}
          </StatisticKey>
        </Statistic>

        <Statistic>
          <StatisticValue>{feedbackGiven}</StatisticValue>
          <StatisticKey>
            Feedback
            <br />
            {pluralize(feedbackGiven, 'reply', 'replies')}
          </StatisticKey>
        </Statistic>

        <Statistic>
          <StatisticValue>{datapointsGiven}</StatisticValue>
          <StatisticKey>
            {pluralize(datapointsGiven, 'Datapoint', 'Datapoints')}
            <br />
            Given
          </StatisticKey>
        </Statistic>

        <Statistic>
          <StatisticValue>{datapoints}</StatisticValue>
          <StatisticKey>
            {pluralize(datapoints, 'Datapoint', 'Datapoints')}
            <br />
            Received
          </StatisticKey>
        </Statistic>
      </StatisticsRow>
    </SummaryContainer>

    {heatMapData && (
      <Contributions>
        <Title>Contributions in the last year</Title>
        <Graph>
          <InfoTable>
            <InfoRow>Challenges completed</InfoRow>
            <InfoRow>Exercises solved</InfoRow>
            <InfoRow>Comments added</InfoRow>
            <InfoRow>Datapoints given</InfoRow>
          </InfoTable>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  {heatMapData.map((month, index) => (
                    <Th colSpan={4} key={index}>
                      {formatMonthName(month.date)}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                <Tr variant="month">
                  {heatMapData.map((month, index) => (
                    <Td
                      key={`${month.date}_${index}`}
                      colSpan={4}
                      title={
                        month.challenges === 3
                          ? 'Challenge completed'
                          : 'Challenge not completed'
                      }
                      count={month.challenges}
                    />
                  ))}
                </Tr>
                <Tr variant="week">
                  {heatMapData.flatMap((month) =>
                    [
                      month.exercises1,
                      month.exercises2,
                      month.exercises3,
                      month.exercises4,
                    ].map((week, index) => (
                      <Td
                        key={`${month.date}_${index}`}
                        title={
                          week === null || week === 0 || week === 1
                            ? 'No exercise solved'
                            : week === 2
                            ? 'At least 1 exercise solved'
                            : 'At least 2 exercises solved'
                        }
                        count={week}
                      />
                    )),
                  )}
                </Tr>
                <Tr variant="week">
                  {heatMapData.flatMap((month) =>
                    [
                      month.comments1,
                      month.comments2,
                      month.comments3,
                      month.comments4,
                    ].map((week, index) => (
                      <Td
                        key={`${month.date}_${index}`}
                        title={
                          week === null || week === 0
                            ? '0 comments added'
                            : `At least ${String(week)} ${pluralize(
                                week,
                                'comment',
                                'comments',
                              )} added`
                        }
                        count={week}
                      />
                    )),
                  )}
                </Tr>
                <Tr variant="week">
                  {heatMapData.flatMap((month) =>
                    [
                      month.datapoints1,
                      month.datapoints2,
                      month.datapoints3,
                      month.datapoints4,
                    ].map((week, index) => (
                      <Td
                        key={`${month.date}_${index}`}
                        title={
                          week === null || week === 0
                            ? '0 datapoints given'
                            : week === 1
                            ? 'At least 1 datapoint given'
                            : `At least ${String(
                                week ** 2 + 1,
                              )} datapoints given`
                        }
                        count={week}
                      />
                    )),
                  )}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <LegendArea>
            <LegendText>0/Yes</LegendText>
            <LegendSquare />
            <LegendSquare />
            <LegendSquare />
            <LegendSquare />
            <LegendText>10/No</LegendText>
          </LegendArea>
        </Graph>
      </Contributions>
    )}
  </>
);

export default Summary;
