import defaultAvatar from 'assets/images/defaultAvatar.png';
import React, { FC } from 'react';
import {
  Author,
  Content,
  FeedbackCommentContainer,
  Image,
  Img,
  Info,
  Text,
} from './styles';
import { Props } from './types';

const FeedbackComment: FC<Props> = ({ src, author, created, comment }) => {
  const srcUrl = src ? src : defaultAvatar;

  return (
    <FeedbackCommentContainer>
      <Image>
        <Img src={srcUrl} alt="" aria-hidden={true} />
      </Image>
      <Content>
        <Info>
          <Author>{author}</Author>
          {created}
        </Info>
        <Text>{comment}</Text>
      </Content>
    </FeedbackCommentContainer>
  );
};

export default FeedbackComment;
