import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;

  :hover {
    cursor: pointer;
  }

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`;
