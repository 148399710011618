import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

// ToDo: We will probably get rid of this at some point
export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 2rem;
`;

export const Progress = styled(CircularProgress)``;
