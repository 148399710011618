import { formatDistanceToNow, parseISO } from 'date-fns';
import React, { FC, memo } from 'react';
import { LastComment } from './styles';
import { Props } from './types';

const HighlightedSubmission: FC<Props> = ({
  firstName,
  lastName,
  created,
  submissionSubtitle,
}) => (
  <LastComment>
    Last {submissionSubtitle} by{' '}
    <span>
      {firstName} {lastName}
    </span>{' '}
    <span>
      about{' '}
      {created && formatDistanceToNow(parseISO(created), { addSuffix: true })}
    </span>
  </LastComment>
);

export default memo(HighlightedSubmission);
