import { ChallengeResponse } from 'model';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { selectChallengeResponseBySlug } from 'redux/modules/challengeResponse';
import { getUserChallengeResponses } from 'redux/modules/users';
import { useActions } from 'utils/useActions';
import { Props } from './types';

export const useConnect = ({ user }: { user: Props['user'] }) => {
  const { search, state } = useLocation();
  const { path, url } = useRouteMatch<{ userSlug?: string }>();

  const challengeResponseBySlug = useSelector(selectChallengeResponseBySlug);

  const actions = useActions({
    getUserChallengeResponses: getUserChallengeResponses.request,
  });

  return {
    ...actions,
    path,
    search,
    state,
    url,
    loading: user ? user.challengeResponseSlugs.loading : false,
    pageCount: user ? user.challengeResponseSlugs.pageCount : 0,
    items: user
      ? (user.challengeResponseSlugs.slugs
          .map((slug) => challengeResponseBySlug[slug])
          .filter(
            (it): it is NonNullable<typeof it> => !!it,
          ) as ChallengeResponse[])
      : [],
  };
};
