import { AuthJumbotron, PageTitle } from 'components';
import { ResetPasswordForm } from 'model';
import { parse } from 'query-string';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import SetPasswordPremiumInvitationForm from './SetPasswordPremiumInvitationForm';
import { Container, Content } from './styles';
import { Props, QueryString } from './types';

const SetPasswordPremiumInvitation: FC<Props> = ({
  location: { search },
  setPassword,
}) => {
  const { token } = parse(search) as QueryString;

  const onSubmitSetPassword = useCallback(
    (values: ResetPasswordForm) => {
      if (token) {
        setPassword({ ...values, token, isNewUser: true });
      }
    },
    [setPassword, token],
  );

  return (
    <Container>
      <PageTitle title="Set your password" />
      <AuthJumbotron mainTitle="Set your password">
        Please set your password for your new premium account
      </AuthJumbotron>
      <Content>
        {token && (
          <SetPasswordPremiumInvitationForm onSubmit={onSubmitSetPassword} />
        )}
      </Content>
    </Container>
  );
};

export default connect(SetPasswordPremiumInvitation);
