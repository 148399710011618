import { RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { fromEvent, interval, merge, OperatorFunction } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { filter, first, map } from 'rxjs/operators';
import { useRefEffect } from 'utils/useRefEffect';

declare global {
  type ResizeCallback = (entries: ResizeObserverEntry[]) => void;

  class ResizeObserver {
    constructor(onResize: ResizeCallback);
    disconnect(): void;
    observe(element: HTMLElement): void;
  }

  class ResizeObserverEntry {
    // noinspection JSUnusedGlobalSymbols
    contentRect: DOMRectReadOnly;
    target: HTMLElement;
  }
}

interface Dimensions {
  clientHeight: number;
  clientWidth: number;
  offsetHeight: number;
  offsetWidth: number;
}

const canUseResizeObserver = 'ResizeObserver' in window;

function useResizeObserver(ref: RefObject<HTMLElement | null>): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>({
    clientHeight: 0,
    clientWidth: 0,
    offsetHeight: 0,
    offsetWidth: 0,
  });

  const onResize: ResizeCallback = useCallback(
    ([{ target }]) => {
      setDimensions({
        clientHeight: target.clientHeight,
        clientWidth: target.clientWidth,
        offsetHeight: target.offsetHeight,
        offsetWidth: target.offsetWidth,
      });
    },
    [setDimensions],
  );

  const observer = useMemo(() => new ResizeObserver(onResize), [onResize]);

  const onReceiveElement = useCallback(
    (element: HTMLElement) => {
      observer.observe(element);

      setDimensions({
        clientHeight: element.clientHeight,
        clientWidth: element.clientWidth,
        offsetHeight: element.offsetHeight,
        offsetWidth: element.offsetWidth,
      });
    },
    [observer, setDimensions],
  );

  useRefEffect(onReceiveElement, ref);

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return dimensions;
}

const mapToRef = (
  ref: RefObject<HTMLElement | null>,
): OperatorFunction<unknown, HTMLElement> => (input$) =>
  input$.pipe(
    map(() => ref.current),
    filter((el): el is NonNullable<typeof el> => !!el),
  );

const windowResize$ = fromEvent(window, 'resize', {
  passive: true,
});

export const useWindowResize = (ref: RefObject<HTMLElement | null>) =>
  useObservable<Dimensions>(
    () =>
      merge(
        interval(1).pipe(mapToRef(ref), first()),
        windowResize$.pipe(mapToRef(ref)),
      ).pipe(
        map((el) => ({
          clientHeight: el.clientHeight,
          clientWidth: el.clientWidth,
          offsetHeight: el.offsetHeight,
          offsetWidth: el.offsetWidth,
        })),
      ),
    {
      clientHeight: 0,
      clientWidth: 0,
      offsetHeight: 0,
      offsetWidth: 0,
    },
  );

export function useElementDimensions(
  ref: RefObject<HTMLElement | null>,
): Dimensions {
  // 'canUseResizeObserver' will never change its value, so we can safely
  // use this conditional to choose which hook to use.
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return canUseResizeObserver ? useResizeObserver(ref) : useWindowResize(ref);
}
