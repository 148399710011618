import { Location, LocationDescriptor } from 'history';
import { Props } from './types';

function getPathnameFromLocationDescriptor(
  to: Props['to'],
  currentLocation: Location,
): string | LocationDescriptor {
  if (typeof to === 'string') {
    return to;
  }

  if (typeof to === 'function') {
    return getPathnameFromLocationDescriptor(
      to(currentLocation),
      currentLocation,
    );
  }

  if (typeof to === 'object') {
    return to;
  }

  return '/';
}

export const handleClick = ({
  beginIntent,
  hash,
  key,
  pathname,
  search,
  state,
  to,
}: {
  beginIntent: Props['beginIntent'];
  hash: Props['location']['hash'];
  key: Props['location']['key'];
  pathname: Props['location']['pathname'];
  search: Props['location']['search'];
  state: Props['location']['state'];
  to: Props['to'];
}) => () => {
  beginIntent({
    returnTo: getPathnameFromLocationDescriptor(to, {
      hash,
      key,
      pathname,
      search,
      state,
    }),
  });
};
