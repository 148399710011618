import {
  RequestResetPasswordForm,
  REQUEST_RESET_PASSWORD_FORM_KEY,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = ({ auth }: RootState) => ({
  loading: auth.loading,
});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<RequestResetPasswordForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<RequestResetPasswordForm>({
    form: REQUEST_RESET_PASSWORD_FORM_KEY,
    initialValues: {
      email: '',
    },
  }),
) as FormDecorator<RequestResetPasswordForm, OwnProps, ConnectedProps>;
