import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { getConfiguration } from './actions';
import { ConfigurationAction, ConfigurationState } from './types';

export const INITIAL_STATE: ConfigurationState = {
  conversationTags: [],
  exerciseTags: [],
  graphIndustryTags: [],
  graphToolTags: [],
  graphTopicTags: [],
  graphTypeTags: [],
  groups: [],
  plans: [],
  reportOptions: [],
  updatedAtTimestamp: 0,
  videoPresenters: [],
  videoSeries: [],
  videoType: [],
};

const reducer: Reducer<ConfigurationState, ConfigurationAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getConfiguration.success): {
      if (action.payload) {
        return {
          ...action.payload,
          updatedAtTimestamp: Date.now(),
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default reducer;
