import {
  ChangeEmailForm,
  CHANGE_EMAIL_FORM_KEY,
  validateChangeEmailForm,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState) => ({
  loading: state.auth.loading,
  currentEmail: state.auth.user?.email,
  secondaryEmail: state.auth.user?.secondaryEmail,
});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ChangeEmailForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ChangeEmailForm>({
    form: CHANGE_EMAIL_FORM_KEY,
    initialValues: {
      email: '',
      emailConfirm: '',
    },
    validate: validateChangeEmailForm,
  }),
) as FormDecorator<ChangeEmailForm, OwnProps, ConnectedProps>;
