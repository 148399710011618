import { AddToCalendar, Button } from 'components';
import { getDateAndTime } from 'components/OfficeHourBook/logic';
import parseISO from 'date-fns/parseISO';
import React, { forwardRef, memo } from 'react';
import { BookingNotification } from '../../model/OfficeHourBooking';
import {
  ActionZone,
  CardAction,
  CardDate,
  CardInfo,
  CardTitle,
  Container,
  Description,
  EventDetailsCard,
  EventsIcon,
  InfoContainer,
  ReminderSetText,
  ReminderText,
  Subtitle,
  Title,
} from './styles';
import { Props } from './types';

const LiveEventDetails = forwardRef<HTMLDivElement, Props>(
  (
    {
      endDate,
      eventSlug,
      startDate,
      onNext,
      onClose,
      title,
      description,
      onAdd,
      reminder,
      ...rest
    },
    ref,
  ) => {
    const { date, time } = getDateAndTime({
      endDate: endDate ? parseISO(endDate) : undefined,
      startDate: startDate ? parseISO(startDate) : undefined,
    });

    return (
      <Container ref={ref} {...rest}>
        <EventsIcon />
        <InfoContainer>
          <Subtitle>Live Event</Subtitle>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <EventDetailsCard>
            <CardTitle>Live Event Details</CardTitle>
            <CardInfo>
              <CardDate>
                <span>
                  <strong>{date}</strong>
                </span>
                <span>|</span>
                <span>{time}</span>
              </CardDate>
              <CardAction>
                {startDate && endDate && (
                  <AddToCalendar
                    start={parseISO(startDate)}
                    end={parseISO(endDate)}
                    name={title}
                    onAdd={onAdd}
                  />
                )}
              </CardAction>
            </CardInfo>
          </EventDetailsCard>
          {reminder !== 'N' ? (
            <ReminderSetText>
              You will receive a reminder{' '}
              {reminder === BookingNotification.ONE_DAY_BEFORE
                ? 'a day'
                : 'an hour'}{' '}
              before the event starts.
            </ReminderSetText>
          ) : (
            <>
              <ReminderText>Would you like to set a reminder?</ReminderText>
              <ActionZone>
                <Button variant="primary" onClick={onNext}>
                  Yes, please
                </Button>
                <Button variant="secondary" onClick={onClose}>
                  No, thanks
                </Button>
              </ActionZone>
            </>
          )}
        </InfoContainer>
      </Container>
    );
  },
);

export default memo(LiveEventDetails);
