import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 8 8" {...props}>
    {children}
    <path
      fillRule="evenodd"
      stroke="#297FD5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M.94.738C.85086681.6674218.72740776.65922024.62972181.71738778.53203587.77555532.48041982.88800614.5 1v6c-.01958018.1119939.03203587.2244447.12972181.2826122C.72740776 7.3407798.85086681 7.3325782.94 7.262l5.589-3.024c.242-.131.242-.345 0-.476L.94.738z"
    />
  </svg>
));
