import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { SetReferenceProviderStep } from './actions';
import { State } from './types';

const pluginKey = new PluginKey<State>('reference-provider-holder');

const INITIAL_STATE: State = {
  referenceProvider: undefined,
};

export const createReferenceProviderHolderPlugin = () => {
  const referenceProviderHolderPlugin = new Plugin<State>({
    key: pluginKey,
    state: {
      apply: (tr, value) => {
        const setProviderStep = tr.steps.find(
          (step): step is SetReferenceProviderStep =>
            step instanceof SetReferenceProviderStep,
        );

        if (setProviderStep) {
          return {
            referenceProvider: setProviderStep.referenceProvider,
          };
        }

        return value;
      },
      init: () => INITIAL_STATE,
    },
  });

  const getProvider = (state: EditorState) =>
    pluginKey.getState(state)?.referenceProvider;

  return {
    getProvider,
    referenceProviderHolderPlugin,
  };
};
