import { connect } from 'react-redux';
import {
  createLiveEventBooking,
  editLiveEventBooking,
} from 'redux/modules/liveEvents';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  loading: state.liveEvents.loading,
  events: state.liveEvents.events,
});

const mapDispatchToProps = {
  editEventBooking: editLiveEventBooking.request,
  createEventBooking: createLiveEventBooking.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
