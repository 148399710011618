import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="39"
    height="37"
    viewBox="0 0 39 37"
  >
    <defs>
      <rect id="underline-b" width="29" height="27" x="0" y="0" rx="4" />
      <filter
        id="underline-a"
        width="158.6%"
        height="163%"
        x="-29.3%"
        y="-24.1%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          radius="1.5"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.28627451   0 0 0 0 0.31372549   0 0 0 0 0.341176471  0 0 0 0.400841346 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 3)">
      <use fill="#000" filter="url(#underline-a)" xlinkHref="#underline-b" />
      <rect
        width="28"
        height="26"
        x=".5"
        y=".5"
        fill="#FFF"
        stroke="#297FD5"
        strokeLinejoin="inherit"
        rx="4"
      />
      <g transform="translate(8 7)">
        <path
          fill="#297FD5"
          fillRule="nonzero"
          d="M2.148,4.592 L2.148,1.288 C2.148,0.798 2.05,0.574 1.756,0.574 L1.098,0.574 C1.014,0.574 1,0.546 1,0.448 L1,0.112 C1,0.028 1.014,-6.21724894e-15 1.126,-6.21724894e-15 C1.266,-6.21724894e-15 1.952,0.042 3.422,0.042 C4.654,0.042 5.326,-6.21724894e-15 5.48,-6.21724894e-15 C5.592,-6.21724894e-15 5.606,0.028 5.606,0.112 L5.606,0.476 C5.606,0.546 5.592,0.574 5.494,0.574 L5.018,0.574 C4.542,0.574 4.304,0.84 4.276,1.12 C4.262,1.274 4.248,2.226 4.248,2.94 L4.248,3.864 C4.248,4.606 4.304,6.384 4.318,6.832 C4.332,7.476 4.458,8.106 4.766,8.498 C5.228,9.058 5.886,9.282 6.516,9.282 C7.034,9.282 7.79,9.016 8.168,8.666 C8.728,8.162 9.05,7.392 9.05,6.384 L9.05,4.228 C9.05,3.052 8.91,1.512 8.854,1.19 C8.798,0.826 8.574,0.574 8.336,0.574 L7.888,0.574 C7.804,0.574 7.79,0.532 7.79,0.462 L7.79,0.098 C7.79,0.028 7.804,-6.21724894e-15 7.888,-6.21724894e-15 C8.028,-6.21724894e-15 8.672,0.042 9.624,0.042 C10.38,0.042 10.828,-6.21724894e-15 10.954,-6.21724894e-15 C11.08,-6.21724894e-15 11.094,0.056 11.094,0.126 L11.094,0.434 C11.094,0.518 11.08,0.574 11.01,0.574 L10.576,0.574 C10.044,0.574 10.002,0.966 9.974,1.106 C9.946,1.302 9.946,3.234 9.946,3.43 C9.946,3.626 9.918,6.076 9.876,6.622 C9.792,7.77 9.414,8.652 8.686,9.296 C8.07,9.842 7.118,10.164 6.208,10.164 C5.55,10.164 4.654,10.066 4.066,9.814 C3.394,9.52 2.778,8.862 2.442,7.966 C2.204,7.364 2.148,6.426 2.148,4.592 Z"
        />
        <rect width="13" height="1" y="12" fill="#004A8F" rx=".5" />
      </g>
    </g>
  </svg>
));
