import { Header } from 'components/ClickDropdown/styles';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { randomId } from 'utils/randomId';
import DefaultTabsDropdown from './TabsDropdown';
import { TabLink } from './TabsDropdown/styles';
import { Props } from './types';

const active = `active_${randomId()}`;

const tabsVariants = css<{ $variant: Props['variant'] }>`
  color: ${({ theme }) => theme.colors.grey400};
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-align: center;

  span {
    display: flex;
    align-items: center;
  }

  ${({ $variant }) =>
    ($variant === 'primary' &&
      css`
        padding: 0 0.95rem;
        border-bottom: 4px solid ${({ theme }) => theme.colors.white};
        span {
          padding: 0.65rem 0.45rem;
          margin-bottom: -4px;
        }
      `) ||
    ($variant === 'secondary' &&
      css`
        padding: 0rem 0.4rem;
        border-bottom: 4px solid ${({ theme }) => theme.colors.grey200};
        span {
          padding: 0.9rem 1rem;
          margin-bottom: -4px;
        }
      `) ||
    ($variant === 'tertiary' &&
      css`
        padding: 0 0.4rem;
        font-weight: ${({ theme }) => theme.weights.regular};
        font-size: 1rem;
        border-bottom: 2px solid ${({ theme }) => theme.colors.grey200};
        span {
          padding: 0.55rem 1rem;
          margin-bottom: -2px;
        }
      `)}
`;

export const FullWidthContainer = styled.div<{
  $variant: Props['variant'];
  $forceDropdown: boolean;
}>`
  display: contents;
  ${({ $variant, $forceDropdown }) =>
    $variant === 'primary' &&
    !$forceDropdown &&
    css`
      display: flex;
      width: 100%;
      ${from.tablet`
        box-shadow: 2px 16px 20px -13px ${({ theme }) =>
          addAlpha(theme.colors.grey400, 0.3)};
      `}
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-grow: 1;

  > :nth-child(2) {
    padding-left: 0;
  }

  > :last-child {
    padding-right: 0;
  }
`;

export const TabsDropdown = styled(DefaultTabsDropdown)<{
  $alwaysVisible?: boolean;
}>`
  ${({ $alwaysVisible }) =>
    $alwaysVisible &&
    css`
      height: 2.75rem;

      :hover {
        box-shadow: unset;
      }

      > * > * {
        :hover {
          box-shadow: unset;
          border: 0;
        }
      }

      ${TabLink} {
        :hover {
          background-color: initial;
          color: initial;
        }
      }

      ${from.tablet`
        display: flex;

        > ${Header} {
          width: 100%;
        }
      `}
    `}
`;

export const Tab = styled(NavLink).attrs({
  activeClassName: active,
})<{
  $displayDropdown: boolean;
  $variant: Props['variant'];
}>`
  ${tabsVariants};
  &.${active} span {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 4px solid ${({ theme }) => theme.colors.accent};

    ${({ $variant }) =>
      $variant === 'tertiary' &&
      css`
        border-bottom: 2px solid ${({ theme }) => theme.colors.accent};
        font-weight: ${({ theme }) => theme.weights.semiBold};
      `}
  }

  ${({ $displayDropdown }) =>
    $displayDropdown &&
    css`
      display: none;
      ${from.tablet`
        display: flex;
      `}
    `}
`;
