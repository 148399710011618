import React, { FC, useCallback } from 'react';
import connect from './connect';
import Form from './Form';
import { handleSubmit } from './logic';
import { Props } from './types';

const SignUpFragment: FC<Props> = ({ onLeave, signUp }) => {
  const onSubmit = useCallback(handleSubmit({ signUp }), [signUp]);

  return <Form onLeave={onLeave} onSubmit={onSubmit} />;
};

export default connect(SignUpFragment);
