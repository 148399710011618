import { Schema as ProseMirrorSchema } from 'prosemirror-model';
import {
  marks as basicMarks,
  nodes as basicNodes,
} from 'prosemirror-schema-basic';
import { bulletList, listItem, orderedList } from 'prosemirror-schema-list';

import { referenceMark } from './references';

export const schema = new ProseMirrorSchema({
  // The order of the nodes is important: pasted text will be interpreted in
  // order; higher nodes will take precedence.
  nodes: {
    doc: basicNodes.doc,
    paragraph: basicNodes.paragraph,
    blockquote: basicNodes.blockquote,
    bulletList: {
      attrs: bulletList.attrs,
      content: 'listItem+',
      group: 'block',
      parseDOM: bulletList.parseDOM,
      toDOM: bulletList.toDOM,
    },
    orderedList: {
      attrs: orderedList.attrs,
      content: 'listItem+',
      group: 'block',
      parseDOM: orderedList.parseDOM,
      toDOM: orderedList.toDOM,
    },
    listItem: {
      attrs: listItem.attrs,
      // "block" allows nested lists. This is necessary for
      // prosemirror-schema-list commands like "sinkListItem" to work properly.
      content: 'paragraph block*',
      parseDOM: listItem.parseDOM,
      toDOM: listItem.toDOM,
    },
    text: basicNodes.text,
    hardBreak: basicNodes.hard_break,
  },
  marks: {
    em: basicMarks.em,
    link: basicMarks.link,
    reference: referenceMark,
    strong: basicMarks.strong,
  },
});

export type Schema = typeof schema;
