import { createAction } from 'typesafe-actions';

export const showSnackbar = createAction('snackbar/SHOW_SNACKBAR')<{
  message: string;
}>();

export const hideSnackbar = createAction('snackbar/HIDE_SNACKBAR')<undefined>();

export const pushFromQueue = createAction('snackbar/PUSH_FROM_QUEUE_SNACKBAR')<
  undefined
>();
