import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deserializeIntent } from 'utils/intent';
import { Props } from './types';

export const useAcceptHandler = ({
  closeModal,
  data,
}: {
  closeModal: Props['closeModal'];
  data: Props['data'];
}) => {
  const dispatch = useDispatch();

  const onAcceptIntent = data?.onAcceptIntent;

  return useCallback(() => {
    if (onAcceptIntent) {
      const actions = deserializeIntent(onAcceptIntent);
      closeModal();
      actions.forEach(dispatch);
    }
  }, [closeModal, dispatch, onAcceptIntent]);
};
