import debounce from 'lodash/debounce';
import { ActivityFiltersForm, ACTIVITY_FILTERS_FORM_KEY } from 'model';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { SEARCH_INPUT_DEBOUNCE_TIME_MS } from 'utils/config';
import { execAll } from 'utils/execAll';
import { FormDecorator } from 'utils/forms';
import { submitOnChange } from 'utils/submitOnChange';
import { OwnProps } from './types';

const defaultValues = {
  commentsReceived: true,
  commentsGiven: true,
  voteReceived: true,
  voteGiven: true,
  feedbackReceived: true,
  feedbackGiven: true,
  memberFilter: { type: 'everyone' },
} as const;

const mapStateToProps = (state: RootState) => {
  const { ...filters } = parse(state.router.location.search);

  const currentValues: ConfigProps<ActivityFiltersForm>['initialValues'] = {};

  if (filters.commentsGiven === 'false') {
    currentValues.commentsGiven = false;
  }

  if (filters.commentsReceived === 'false') {
    currentValues.commentsReceived = false;
  }

  if (filters.feedbackGiven === 'false') {
    currentValues.feedbackGiven = false;
  }

  if (filters.feedbackReceived === 'false') {
    currentValues.feedbackReceived = false;
  }

  if (filters.voteReceived === 'false') {
    currentValues.voteReceived = false;
  }

  if (filters.voteGiven === 'false') {
    currentValues.voteGiven = false;
  }

  if (filters.swdTeam === 'true') {
    currentValues.memberFilter = {
      type: 'swdTeam',
    };
  }

  if (typeof filters.specificMember === 'string') {
    currentValues.memberFilter = {
      type: 'specificMember',
      name: filters.specificMember,
    };
  }

  const initialValues: ConfigProps<ActivityFiltersForm>['initialValues'] = {
    ...defaultValues,
    ...currentValues,
  };

  return {
    initialValues,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ActivityFiltersForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ActivityFiltersForm>({
    form: `${ACTIVITY_FILTERS_FORM_KEY}`,
    enableReinitialize: true,
    onChange: execAll(
      submitOnChange<ActivityFiltersForm>('commentsReceived'),
      submitOnChange<ActivityFiltersForm>('commentsGiven'),
      submitOnChange<ActivityFiltersForm>('voteReceived'),
      submitOnChange<ActivityFiltersForm>('voteGiven'),
      submitOnChange<ActivityFiltersForm>('feedbackReceived'),
      submitOnChange<ActivityFiltersForm>('feedbackGiven'),
      debounce(
        submitOnChange<ActivityFiltersForm>('memberFilter'),
        SEARCH_INPUT_DEBOUNCE_TIME_MS,
      ),
    ),
  }),
) as FormDecorator<ActivityFiltersForm, OwnProps, ConnectedProps>;
