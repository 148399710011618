import React, { FC, memo } from 'react';
import { ClosedStyled, Container } from './styles';
import { Props } from './types';

const Tag: FC<Props> = ({ name, ...rest }) => (
  <Container {...rest}>{name}</Container>
);

export const Closed = () => <ClosedStyled>Closed</ClosedStyled>;

export default memo(Tag);
