import React, { FC, memo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Container, TabLink } from './styles';
import { Props } from './types';

const TabsDropdown: FC<Props> = ({ items, baseUrl, valuePrefix, ...rest }) => {
  const location = useLocation();
  const activeFilter = items.find((item) =>
    matchPath(
      location.pathname,
      `${baseUrl}/${
        typeof item.url === 'string' ? item.url : item.url.pathname
      }`,
    ),
  );

  return (
    <Container
      header={
        <>
          {valuePrefix ? valuePrefix : ''}
          {activeFilter?.label ?? items[0]?.label}
        </>
      }
      {...rest}
    >
      {({ closeDropdown }) => (
        <>
          {items.map(({ label, url }) => (
            <TabLink
              $active={(activeFilter?.label || items[0].label) === label}
              onClick={closeDropdown}
              key={typeof url === 'string' ? url : url.pathname}
              to={{
                ...(typeof url !== 'string' ? url : {}),
                pathname: `${baseUrl}/${
                  typeof url === 'string' ? url : url.pathname
                }`,
                state: {
                  scrollToTop: false,
                  ...(typeof url !== 'string' ? url.state : {}),
                },
              }}
            >
              {label}
            </TabLink>
          ))}
        </>
      )}
    </Container>
  );
};

export default memo(TabsDropdown);
