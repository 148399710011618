import { useMediaQuery } from '@material-ui/core';
import { Loader, ScrollBox } from 'components';
import { AnimatePresence } from 'framer-motion';
import { BookingNotification } from 'model/OfficeHourBooking';
import React, { FC, useCallback, useState } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import {
  Container,
  Content,
  LiveEventDetails,
  LiveEventReminder,
} from './styles';
import { Props } from './types';

const MoreInfoModal: FC<Props> = ({
  history: { push },
  match,
  loading,
  events,
  editEventBooking,
  createEventBooking,
}) => {
  const isFromMobileXL = useMediaQuery(from.mobileXL.query);
  const [isOnSetReminder, setIsOnSetReminder] = useState(false);
  const [addedToCalendar, setAddedToCalendar] = useState(false);
  const eventSlug = match?.params.eventSlug;
  const event = events.find((event) => event.slug === eventSlug);

  const url = match?.url ?? '';

  const onNext = useCallback(() => {
    setIsOnSetReminder(true);
  }, []);

  const onClose = useCallback(() => {
    push({
      pathname: up(url, 3),
      state: {
        scrollToTop: false,
      },
    });
    setTimeout(() => setIsOnSetReminder(false), 200);
  }, [push, url]);

  const onSaveReminder = useCallback(
    (reminder: BookingNotification) => {
      if (event) {
        createEventBooking({
          id: event.id,
          sendNotification: reminder,
          addToCalendar: addedToCalendar,
        });
        setIsOnSetReminder(false);
      }
    },
    [event, createEventBooking, addedToCalendar],
  );

  const onAddToCalendar = useCallback(() => {
    if (!event) return;

    if (!event.booking) {
      setAddedToCalendar(true);
      return;
    }

    if (event?.booking.addToCalendar) return;
    editEventBooking({
      id: event.id,
      addToCalendar: true,
    });
  }, [event, editEventBooking]);

  return (
    <Container
      fullScreen={!isFromMobileXL}
      onClose={onClose}
      open={!!match}
      booked={isOnSetReminder}
    >
      <ScrollBox scroll={true}>
        <Content>
          <AnimatePresence>
            {isOnSetReminder ? (
              <LiveEventReminder
                onClose={onClose}
                onSaveReminder={onSaveReminder}
              />
            ) : !loading && event ? (
              <LiveEventDetails
                startDate={event.startDate}
                endDate={event.endDate}
                title={event.title}
                description={event.description}
                reminder={
                  event.booking
                    ? event.booking.sendNotification
                    : BookingNotification.NONE
                }
                onClose={onClose}
                onNext={onNext}
                onAdd={onAddToCalendar}
                eventSlug={event.slug}
              />
            ) : (
              <Loader />
            )}
          </AnimatePresence>
        </Content>
      </ScrollBox>
    </Container>
  );
};

export default connect(MoreInfoModal);
