import { Button } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  min-height: 15rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  margin-bottom: 2rem;
  ${from.tablet`
    flex-direction: row;
  `}
  ${from.laptop`
    max-height: 15rem;
  `}
`;

export const ImageContainer = styled.div`
  height: 200px;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyBlue};
  ${from.tablet`
    max-width: 29%;
    max-height: 100%;
    height: inherit;
    border-bottom: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.greyBlue};
  `}
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.1875rem 0.1875rem 0 0;
  ${from.tablet`
    border-radius: 0.1875rem 0 0 0.1875rem;
  `}
`;

export const TextContainer = styled.div`
  padding: 1.875rem;
`;

export const CardTitle = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.75rem;
  line-height: 2.125rem;
`;

export const CardDate = styled.p`
  margin: 0 0 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  strong {
    margin-right: 16px;
  }
  span {
    margin-left: 14px;
  }
`;

export const CardDescription = styled.p`
  margin: 0 0 0.75rem;
  font-size: 1.125rem;
  line-height: 1.875rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CardButton = styled(Button)`
  margin: 0;
  padding: 0;
`;
