import styled from 'styled-components';
import { from, viewContainer } from 'styles/mixins';

export const Container = styled.div`
  ${viewContainer};
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop} 0;
  margin-bottom: 6.875rem;
  margin-top: 2rem;

  ${from.tablet`
    padding: 0 5rem;
  `};

  ${from.laptop`
    padding: 0 6rem;
  `};
`;
