import { ActivityFiltersForm } from 'model';
import { stringify } from 'query-string';
import { Props } from './types';

export const handleFormSubmitted = ({
  hash,
  pathname,
  replace,
  state = {},
}: {
  hash: Props['hash'];
  pathname: Props['pathname'];
  replace: Props['replace'];
  search: Props['search'];
  state: Props['state'];
}) => (values: ActivityFiltersForm) => {
  // tslint:disable-next-line:no-any
  const params: any = {};

  if (!values.commentsGiven) {
    params['commentsGiven'] = false;
  }

  if (!values.commentsReceived) {
    params['commentsReceived'] = false;
  }

  if (!values.feedbackGiven) {
    params['feedbackGiven'] = false;
  }

  if (!values.feedbackReceived) {
    params['feedbackReceived'] = false;
  }

  if (!values.voteGiven) {
    params['voteGiven'] = false;
  }

  if (!values.voteReceived) {
    params['voteReceived'] = false;
  }

  if (values.memberFilter) {
    if (values.memberFilter.type === 'swdTeam') {
      params['swdTeam'] = true;
    }
    if (values.memberFilter.type === 'specificMember') {
      params['specificMember'] = values.memberFilter.name;
    }
  }

  replace({
    hash,
    pathname,
    search: stringify(params),
    state: {
      ...state,
      scrollToTop: false,
    },
  });
};
