import { Button, ModalRoute, PageTitle } from 'components';
import Feature from 'components/Pricing/Feature';
import VideoModal from 'containers/Common/Modals/VideoModal';
import React, { FC } from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import connect from './connect';
import FeaturedVideo from './FeaturedVideo';
import { Container, CTA, CTATitle, Features, Fold } from './styles';
import { Props } from './types';
import VideoList from './VideoList';
import VideoTabs from './VideoTabs';

const Videos: FC<Props> = ({
  category,
  isPremium,
  location: { pathname },
  match: { path, url },
}) => {
  const webpSupported = !!Modernizr.webp;
  const title = 'Learning videos';

  const legacyVideoMatch = matchPath<{
    category: string;
    videoSlug: string;
  }>(pathname, `${path}/:category/:videoSlug`);
  if (legacyVideoMatch) {
    return <Redirect to={`${url}/${legacyVideoMatch.params.videoSlug}`} />;
  }

  return (
    <Container>
      <PageTitle title={title} />
      <Fold
        description={
          <>
            Seeing lessons employed effectively while hearing the thought
            process is an excellent way to learn.
            <br />
            SWD videos bring some of the magic of our workshops directly to you.
          </>
        }
        title="learning videos"
      />
      {isPremium ? (
        <FeaturedVideo url={url} />
      ) : (
        <CTA webpSupported={webpSupported}>
          <CTATitle>
            Go premium to gain access to
            <br />
            exclusive resources
          </CTATitle>
          <Features>
            <Feature>Live virtual events</Feature>
            <Feature>Office hours</Feature>
            <Feature>On-demand videos</Feature>
            <Feature>Exclusive content</Feature>
            <Feature>Early access</Feature>
            <Feature>Special discounts</Feature>
          </Features>
          <Button variant="premiumAccent" to="/premium">
            Go premium!
          </Button>
        </CTA>
      )}
      <VideoTabs category={category} />
      <VideoList category={category} />
      <ModalRoute component={VideoModal} path={`${path}/:videoSlug`} />
    </Container>
  );
};

export default connect(Videos);
