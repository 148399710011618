import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { useSelector } from 'react-redux';
import { selectLiveEventDates } from 'redux/modules/liveEvents/selectors';

import { useLiveDate } from 'utils/useLiveDate';

export const useThereIsALiveEvent = () => {
  /*
   * 60s of granularity means that the live indicator won't appear/disappear
   * with 100% precision, but I believe this is good enough. I didn't want to
   * make it 1s because this will be present in all pages.
   *
   * In order to improve it we could:
   *
   * - Make useLiveDate accept a second parameter "active" so we can make the
   *   subscription only active when there is a live event, or a live event
   *   is less than one minute away.
   * - Make the granularity 1s during the live event (and one minute after and
   *   one minute before)
   * - Make useLiveDate accept a "until" parameter which will calculate the
   *   granularity based on the target date.
   */
  const now = useLiveDate(60000);
  const liveEventDates = useSelector(selectLiveEventDates);

  if (!liveEventDates) {
    return false;
  }

  const { start, end } = liveEventDates;

  return isAfter(now, start) && isBefore(now, end);
};
