import { Media, RemoteMedia } from 'components/MediaSelector/types';

export function isLoading(value: readonly Media[] = []): boolean {
  return value.some((media) => media && media.status === 'uploading');
}

export function isUploaded(input: Media): input is RemoteMedia {
  return input.status === 'uploaded';
}

export function convertRemoteMedia(input: Media): input is RemoteMedia {
  return input !== null;
}
