import differenceInMinutes from 'date-fns/differenceInMinutes';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { CONFIGURATION_CACHE_DURATION_MINUTES } from 'utils/config';
import { getConfiguration } from './actions';

// tslint:disable:no-any
const getConfigurationEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(getConfiguration.request)),
    mergeMap(() => {
      const lastUpdated = new Date(
        state$.value.configuration.updatedAtTimestamp,
      );

      /*
       * If the cache from redux-persist is not too old, we can skip the request
       * entirely and use what we have.
       *
       * This client-side caching is additional to the caching done by the
       * backend that will return 304 if the content has not changed (which
       * will be most of the time). This is an additional optimization to the
       * initialization process.
       *
       * Note that at the time of writing the backend does not cache this
       * endpoint yet, but the server-side caching should be transparent to us
       * once they have implemented it.
       */
      if (
        differenceInMinutes(new Date(), lastUpdated) <
        CONFIGURATION_CACHE_DURATION_MINUTES
      ) {
        return [getConfiguration.success(undefined)];
      }

      return request<any>({
        headers: {
          // Remove the authentication header because it is not necessary and it
          // prevents this endpoint from being cached
          Authorization: undefined,
        },
        path: 'configuration',
      }).pipe(
        mergeMap(({ groups, ...rest }) => [
          getConfiguration.success({
            groups: groups.map(({ id, ...group }: any) => ({
              // Stringify the id to make it consistent with the rest of the app
              id: `${id}`,
              ...group,
            })),
            ...rest,
          }),
        ]),
        catchError(() => [getConfiguration.failure()]),
      );
    }),
  );
// tslint:enable:no-any

export default combineEpics(getConfigurationEpic);
