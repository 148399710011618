import CTALeftAsset from 'assets/images/bannerWhite/CTALeft.svg';
import CTARightAsset from 'assets/images/bannerWhite/CTARight.svg';
import React, { FC } from 'react';
import {
  Container,
  CTALeftImage,
  CTARightImage,
  Subtitle,
  Title,
} from './styles';
import { Props } from './types';

const BannerWhite: FC<Props> = ({ children, subtitle, title, ...rest }) => (
  <Container {...rest}>
    <CTALeftImage alt="" aria-hidden="true" src={CTALeftAsset} />
    <CTARightImage alt="" aria-hidden="true" src={CTARightAsset} />
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    {children}
  </Container>
);

export default BannerWhite;
