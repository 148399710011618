import React, { FC, memo } from 'react';
import { Container } from './styles';
import { Props } from './types';

const DropFiles: FC<Props> = ({ ...rest }) => (
  <Container {...rest}>
    <p>Drop your media here!</p>
  </Container>
);

export default memo(DropFiles);
