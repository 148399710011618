import React, { FC } from 'react';
import { Link, PodcastIcon, Title } from './styles';
import { Props } from './types';

const Podcast: FC<Props> = ({ title, ...rest }) => (
  <Link {...rest}>
    <PodcastIcon />
    <Title>{title}</Title>
  </Link>
);

export default Podcast;
