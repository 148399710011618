import fold_1x_png from 'assets/images/premium/fold.png';
import fold_3x_webp from 'assets/images/premium/fold_3x.webp';
import {
  Button as DefaultButton,
  Modal as DefaultModal,
  ScrollBox,
} from 'components';
import { ArrowRightIcon } from 'components/icons';
import { CloseButtonContainer as ModalClose } from 'components/Modal/styles';
import DefaultFeature from 'components/Pricing/Feature';
import { Check } from 'components/Pricing/Feature/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Modal = styled(DefaultModal)`
  color: ${({ theme }) => theme.colors.white};
  margin: 0 auto;

  .MuiDialog-paper {
    border: none;
    max-width: 56.25rem;
    width: initial;
  }

  ${ModalClose} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const gradient = css`
linear-gradient(
  to right,
  ${({ theme }) => theme.colors.primary},
  ${({ theme }) => addAlpha(theme.colors.accent, 0.65)}
)
`;

export const Content = styled.div<{ webpSupported: boolean }>`
  background-position-y: center;
  background-size: cover;
  height: 100%;
  padding: 3rem 2rem;

  ${({ webpSupported }) =>
    webpSupported
      ? css`
          background-image: ${gradient}, url('${fold_3x_webp}');
        `
      : css`
          background-image: ${gradient}, url('${fold_1x_png}');
        `};
  ${from.mobileXL`
    padding: 3.75rem 6.875rem;
  `};

  ${from.laptop`
    background-size: contain;
  `};
`;

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-top: 0;
`;

export const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5rem;
`;

export const Features = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Feature = styled(DefaultFeature)`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};

  ${Check} {
    width: 1.25rem;
  }
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: fit-content;

  ${from.tablet`
    flex-direction: row;
    margin: initial;
    width: initial;

    > *:not(:last-child) {
      margin-right: 1.375rem;
    }
  `};
`;

export const PremiumButton = styled(DefaultButton).attrs({
  variant: 'premiumAccent',
})`
  padding: 1rem 2.5rem;
  box-sizing: border-box;
  width: 100%;

  ${from.mobileXL`
    max-width: 20rem;
  `};

  ${from.tablet`
    width: initial;
  `};
`;

export const LearnMoreButton = styled(PremiumButton)`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  box-sizing: border-box;

  ${from.mobileXL`
    max-width: 20rem;
  `};

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ArrowRight = styled(ArrowRightIcon)`
  margin-left: 0.625rem;
  width: 1rem;
`;

export const ScrollWrapper = styled(ScrollBox)`
  height: 100%;
`;
