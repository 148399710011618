import { oneLine } from 'common-tags';
import { Loader, ModalRoute, Navigation, PageTitle } from 'components';
import ChallengeSubmissionView from 'containers/Common/ChallengeSubmissionView';
import { getUserName } from 'model';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { getUserType } from 'utils/getUserType';
import { useConnect } from './connect';
import Edit from './Edit';
import { Comments, Container, Submission } from './styles';
import { Props } from './types';

const ChallengeSubmission: FC<Props> = () => {
  const {
    challengeSlug,
    getSubmission,
    loading,
    path,
    submission,
    submissionSlug,
    url,
    userIsAuthor,
  } = useConnect();

  useEffect(() => {
    getSubmission({ challengeSlug, challengeResponseSlug: submissionSlug });
  }, [challengeSlug, getSubmission, submissionSlug]);

  if (loading && !submission) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!submission) {
    return null;
  }

  return (
    <Container>
      <PageTitle title={submission.title} />
      <BreadcrumbsItem to={url}>
        {submission?.title ?? 'Loading...'}
      </BreadcrumbsItem>
      <Navigation
        previousHref={
          submission.previousResponseSlug &&
          path
            .replace(':challengeSlug', challengeSlug)
            .replace(':submissionSlug', submission.previousResponseSlug)
        }
        previousText="Previous submission"
        nextHref={
          submission.nextResponseSlug &&
          path
            .replace(':challengeSlug', challengeSlug)
            .replace(':submissionSlug', submission.nextResponseSlug)
        }
        nextText="Next submission"
      />
      <Submission>
        <ChallengeSubmissionView
          authorAvatar={submission.user.avatar}
          authorId={submission.user.id}
          authorName={getUserName(submission.user)}
          authorType={getUserType(submission.user)}
          authorSlug={submission.user.slug}
          created={submission.created}
          description={submission.description}
          id={submission.id}
          images={
            submission.mediaList
              ? [
                  submission.mediaList.mainMedia.file,
                  ...submission.mediaList.extraMedia.map((media) => media.file),
                ]
              : []
          }
          isReported={submission.isReported}
          isVoted={submission.isVoted}
          open={!!submissionSlug}
          scroll={false}
          shareButtonMessage={oneLine`Thought this was interesting from the SWD
          community. Check it out! - ${submission.title}: `}
          tags={submission.tags}
          title={submission.title}
          url={url}
          views={submission.hits}
          votes={submission.votesCount}
        />
      </Submission>
      {submission && (
        <Comments
          contentType="challengeresponse"
          id={submission.id}
          path={path}
          url={url}
        />
      )}
      {userIsAuthor && <ModalRoute component={Edit} path={`${path}/edit`} />}
    </Container>
  );
};

export default ChallengeSubmission;
