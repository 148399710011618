import Activity from 'containers/Main/Activity';
import { ActivityList } from 'containers/Main/Activity/styles';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const ProfileActivity = styled(Activity)`
  padding: 0;

  ${ActivityList} {
    margin-top: 0;
    padding-left: 0;

    ${from.tabletH`
      margin-top: revert;
    `}
  }
`;
