import { formatHour, formatMonthNameAndDay } from 'utils/dates';

export const getDateAndTime = ({
  endDate,
  startDate,
}: {
  endDate?: Date;
  startDate?: Date;
}) => {
  const date = startDate && formatMonthNameAndDay(startDate);
  const time =
    startDate && endDate && `${formatHour(startDate)} - ${formatHour(endDate)}`;

  return { date, time };
};
