import { replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import { PaginatedResponse, Video } from 'model';
import { parse } from 'query-string';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap, throttleTime } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { VIDEOS_PER_PAGE, VIDEO_TRACK_INTERVAL } from 'utils/config';
import {
  getProminentVideo,
  getVideo,
  getVideos,
  sendVideoProgress,
  updateVideoProgress,
} from './actions';
export const getProminentVideoEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getProminentVideo.request)),
    mergeMap(() =>
      request<PaginatedResponse<Video>>({
        path: 'video/video_lesson',
        params: {
          prominent: true,
        },
      }).pipe(
        mergeMap((response) => [
          getProminentVideo.success(response.results[0]),
        ]),
        catchError(() => [getProminentVideo.failure()]),
      ),
    ),
  );

export const getVideosEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getVideos.request)),
    mergeMap(({ payload: { search, video_type } }) => {
      const searchQuery = search ? parse(search) : {};
      const queries = {
        video_type,
        ...searchQuery,
        video_presenters:
          typeof searchQuery.videoPresenters === 'string'
            ? searchQuery.videoPresenters
            : undefined,
        video_series:
          typeof searchQuery.videoSeries === 'string'
            ? searchQuery.videoSeries
            : undefined,
        graph_tools:
          typeof searchQuery.graphTools === 'string'
            ? searchQuery.graphTools
            : undefined,
        exercise_tags:
          typeof searchQuery.exerciseTags === 'string'
            ? searchQuery.exerciseTags
            : undefined,
      };
      return fullRequest<PaginatedResponse<Video>>({
        path: 'video/video_lesson',
        params: {
          prominent: false,
          pageSize: VIDEOS_PER_PAGE,
          ...queries,
        },
      }).pipe(
        mergeMap(({ response }) => [getVideos.success(response)]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getVideos.failure(), replace('/404')];
          }

          return [getVideos.failure()];
        }),
      );
    }),
  );

export const getVideoEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getVideo.request)),
    mergeMap(({ payload: { slug } }) =>
      fullRequest<Video>({
        path: `video/video_lesson/${slug}`,
      }).pipe(
        mergeMap(({ response: video }) => [getVideo.success(video)]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getVideo.failure(), replace('/404')];
          }
          return [getVideo.failure()];
        }),
      ),
    ),
  );

export const updateVideoProgressEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(updateVideoProgress)),
    throttleTime(VIDEO_TRACK_INTERVAL),
    mergeMap(({ payload: { id, progress, contentType } }) => {
      const path = `video/video_lesson/${id}/video_progress`;
      return fullRequest({
        path,
        body: {
          progress,
          contentType,
        },
        method: 'PUT',
      }).pipe(
        mergeMap(() => [sendVideoProgress.success()]),
        catchError(() => [sendVideoProgress.failure()]),
      );
    }),
  );

export default combineEpics(
  getVideosEpic,
  getVideoEpic,
  getProminentVideoEpic,
  updateVideoProgressEpic,
);
