import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import { isAnyFilterEnabled } from 'containers/Main/Search/logic';
import {
  Article,
  Book,
  Challenge,
  ChallengeResponse,
  Conversation,
  Discover,
  Exercise,
  FeedbackRequest,
  PaginatedResponse,
  Podcast,
  SearchSection,
  SEARCH_SECTIONS,
  Video,
} from 'model';
import { parse } from 'query-string';
import { matchPath } from 'react-router';
import { combineEpics } from 'redux-observable';
import { Epic, RootAction } from 'redux/modules/types';
import { merge, Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  SEARCH_BLOG_PER_PAGE_DETAIL,
  SEARCH_BLOG_PER_PAGE_MASTER,
  SEARCH_BOOKS_PER_PAGE_DETAIL,
  SEARCH_BOOKS_PER_PAGE_MASTER,
  SEARCH_CHALLENGE_PER_PAGE_DETAIL,
  SEARCH_CHALLENGE_PER_PAGE_MASTER,
  SEARCH_CHALLENGE_RESPONSE_PER_PAGE_DETAIL,
  SEARCH_CHALLENGE_RESPONSE_PER_PAGE_MASTER,
  SEARCH_CONVERSATIONS_PER_PAGE_DETAIL,
  SEARCH_CONVERSATIONS_PER_PAGE_MASTER,
  SEARCH_EXERCISE_PER_PAGE_DETAIL,
  SEARCH_EXERCISE_PER_PAGE_MASTER,
  SEARCH_FEEDBACK_PER_PAGE_DETAIL,
  SEARCH_FEEDBACK_PER_PAGE_MASTER,
  SEARCH_PODCAST_PER_PAGE_DETAIL,
  SEARCH_PODCAST_PER_PAGE_MASTER,
  SEARCH_VIDEOS_PER_PAGE_DETAIL,
  SEARCH_VIDEOS_PER_PAGE_MASTER,
  SEARCH_VISUALS_PER_PAGE_DETAIL,
  SEARCH_VISUALS_PER_PAGE_MASTER,
} from 'utils/config';
import { split } from 'utils/Paths';
import { epicDependencies } from '..';
import { search, setSearchSectionPage } from './actions';
import { SearchResponse, SearchState } from './types';

function getCurrentSection(pathname: string): SearchSection | undefined {
  for (const section of SEARCH_SECTIONS) {
    if (matchPath(pathname, `/search/${section}`)) {
      return section;
    }
  }
}

const isLocationChangeAction = (
  action: RootAction,
): action is LocationChangeAction => action.type === LOCATION_CHANGE;

interface SearchProps {
  params: object;
  request: typeof epicDependencies.request;
  section: SearchSection | undefined;
  state: SearchState;
}

const searchBlogs = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Article>>({
    params: {
      ...params,
      page: page.blog,
      pageSize:
        section === 'blog'
          ? SEARCH_BLOG_PER_PAGE_DETAIL
          : SEARCH_BLOG_PER_PAGE_MASTER,
    },
    path: 'articles/search',
  });

const searchBooks = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Book>>({
    params: {
      ...params,
      page: page.books,
      pageSize:
        section === 'books'
          ? SEARCH_BOOKS_PER_PAGE_DETAIL
          : SEARCH_BOOKS_PER_PAGE_MASTER,
    },
    path: 'books/search',
  });

const searchChallenges = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Challenge>>({
    params: {
      ...params,
      page: page.challenges,
      pageSize:
        section === 'challenges'
          ? SEARCH_CHALLENGE_PER_PAGE_DETAIL
          : SEARCH_CHALLENGE_PER_PAGE_MASTER,
    },
    path: 'challenges/search',
  });

const searchChallengeResponses = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<ChallengeResponse>>({
    params: {
      ...params,
      page: page.challengeResponse,
      pageSize:
        section === 'challenges'
          ? SEARCH_CHALLENGE_RESPONSE_PER_PAGE_DETAIL
          : SEARCH_CHALLENGE_RESPONSE_PER_PAGE_MASTER,
    },
    path: 'challengeresponses/search',
  });

const searchConversations = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Conversation>>({
    params: {
      ...params,
      page: page.conversations,
      pageSize:
        section === 'conversations'
          ? SEARCH_CONVERSATIONS_PER_PAGE_DETAIL
          : SEARCH_CONVERSATIONS_PER_PAGE_MASTER,
    },
    path: 'conversations/search',
  });

const searchExercises = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Exercise>>({
    params: {
      ...params,
      page: page.exercises,
      pageSize:
        section === 'exercises'
          ? SEARCH_EXERCISE_PER_PAGE_DETAIL
          : SEARCH_EXERCISE_PER_PAGE_MASTER,
    },
    path: 'exercises/search',
  });

const searchFeedbackRequests = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<FeedbackRequest>>({
    params: {
      ...params,
      page: page.feedback,
      pageSize:
        section === 'feedback'
          ? SEARCH_FEEDBACK_PER_PAGE_DETAIL
          : SEARCH_FEEDBACK_PER_PAGE_MASTER,
    },
    path: 'feedback/search',
  });

const searchPodcasts = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Podcast>>({
    params: {
      ...params,
      page: page.podcast,
      pageSize:
        section === 'podcast'
          ? SEARCH_PODCAST_PER_PAGE_DETAIL
          : SEARCH_PODCAST_PER_PAGE_MASTER,
    },
    path: 'podcasts/search',
  });

const searchDiscover = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Discover>>({
    params: {
      ...params,
      page: page.visuals,
      pageSize:
        section === 'visuals'
          ? SEARCH_VISUALS_PER_PAGE_DETAIL
          : SEARCH_VISUALS_PER_PAGE_MASTER,
    },
    path: 'discover/search',
  });

const searchVideos = ({
  params,
  request,
  section,
  state: { page },
}: SearchProps) =>
  request<PaginatedResponse<Video>>({
    params: {
      ...params,
      page: page.videos,
      pageSize:
        section === 'videos'
          ? SEARCH_VIDEOS_PER_PAGE_DETAIL
          : SEARCH_VIDEOS_PER_PAGE_MASTER,
    },
    path: 'video/video_lesson',
  });

export const listenToUrlChangesEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isLocationChangeAction),
    filter(({ payload }) => !!matchPath(payload.location.pathname, '/search')),
    mergeMap(({ payload: { location } }) => {
      const { lastLocation } = state$.value.router;

      const searchAction = search.request(parse(location.search));

      // If there is no previous location, the user visited the search page
      // directly. Perform the search.
      if (!lastLocation) {
        return [searchAction];
      }

      const locationChanged = location.pathname !== lastLocation.pathname;
      const filtersChanged = location.search !== lastLocation.search;

      // If the filters changed, always re-perform the search
      if (filtersChanged) {
        return [searchAction];
      }

      if (locationChanged) {
        const paths = split(location.pathname);

        // If we're navigating to a level 3 path, don't re-query, because that
        // means that we're navigating to "/search/proposals/responses" for
        // example.
        //
        // If we are navigating from "/search/" to
        // "/search/challenge/challenges" we will need to re-query, but this
        // is actually done by a redirect:
        //
        // 1. "/search"
        // 2. "/search/proposals"
        // 3. "/search/proposals/challenges"
        //
        // We don't want to re-query on 3, the re-query will happen in 2.
        if (paths.length === 3) {
          return [];
        }

        return [searchAction];
      }

      return [];
    }),
  );

export const searchEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(search.request)),
    map(({ payload }) => payload),
    filter((filters) => isAnyFilterEnabled(filters)),
    mergeMap(({ singleSection, ...params }) => {
      const state = state$.value.search;
      const section = getCurrentSection(state$.value.router.location.pathname);

      const searchProps = {
        request,
        params,
        section,
        state,
      };

      const blogs = searchBlogs(searchProps).pipe(map((blog) => ({ blog })));
      const books = searchBooks(searchProps).pipe(map((books) => ({ books })));
      const challenges = searchChallenges(searchProps).pipe(
        map((challenges) => ({ challenges })),
      );
      const challengeResponses = searchChallengeResponses(searchProps).pipe(
        map((challengeResponse) => ({ challengeResponse })),
      );
      const conversations = searchConversations(searchProps).pipe(
        map((conversations) => ({ conversations })),
      );
      const exercises = searchExercises(searchProps).pipe(
        map((exercises) => ({ exercises })),
      );
      const feedbackRequests = searchFeedbackRequests(searchProps).pipe(
        map((feedback) => ({ feedback })),
      );
      const podcasts = searchPodcasts(searchProps).pipe(
        map((podcast) => ({ podcast })),
      );
      const discover = searchDiscover(searchProps).pipe(
        map((visuals) => ({ visuals })),
      );
      const videos = searchVideos(searchProps).pipe(
        map((videos) => ({ videos })),
      );

      const request$ = (() => {
        if (singleSection) {
          switch (singleSection) {
            case 'blog':
              return blogs;
            case 'books':
              return books;
            case 'challengeResponse':
              return challengeResponses;
            case 'conversations':
              return conversations;
            case 'feedback':
              return feedbackRequests;
            case 'podcast':
              return podcasts;
            case 'challenges':
              return challenges;
            case 'exercises':
              return exercises;
            case 'visuals':
              return discover;
            case 'videos':
              return videos;
          }
        } else {
          return merge(
            blogs,
            books,
            challenges,
            challengeResponses,
            conversations,
            exercises,
            feedbackRequests,
            podcasts,
            discover,
            videos,
          );
        }
      })() as Observable<Partial<SearchResponse>>;

      return request$.pipe(
        mergeMap((response) => [search.success(response)]),
        catchError(() => [search.failure()]),
      );
    }),
  );

export const setSearchSectionPageEpic: Epic = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(setSearchSectionPage)),
    map(({ payload: { section } }) =>
      search.request({
        ...parse(state$.value.router.location.search),
        singleSection: section,
      }),
    ),
  );

export default combineEpics(
  listenToUrlChangesEpic,
  searchEpic,
  setSearchSectionPageEpic,
);
