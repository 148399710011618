import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { QuestionForm, UPLOAD_MEDIA_FIELD } from 'model';
import { Props } from './types';

export const handleFormSubmitted = ({
  contentType,
  onCreateComment: createComment,
  id,
  objectId,
}: {
  contentType: Props['contentType'];
  onCreateComment: Props['onCreateComment'];
  id: Props['id'];
  objectId: Props['objectId'];
}) => ({ comment, [UPLOAD_MEDIA_FIELD]: media }: QuestionForm) => {
  const [image] = media.filter(isUploaded);

  createComment({
    contentType,
    objectId,
    image: image?.file,
    comment: serializeHTML(comment),
    parent: id,
  });
};
