import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Card1, Card2, Data, Person } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Person d="M9.931 11.732a2.5 2.5 0 11-3.535 3.536 2.5 2.5 0 013.535-3.536m2.291 8.813a4.083 4.083 0 00-8.117 0" />
      <Card1 d="M1.455 8.727h20.909M14.182 5.09h7.273a.91.91 0 01.909.91v13.636a.91.91 0 01-.91.91H2.365a.91.91 0 01-.91-.91V6a.91.91 0 01.91-.91h7.272" />
      <Data d="M11.91 3.273a.227.227 0 11-.228.227c0-.126.102-.227.227-.227m2.727 10h5.455M14.636 16h3.182" />
      <Card2 d="M14.182 6.455a.455.455 0 01-.455.454h-3.636a.455.455 0 01-.455-.454V3.727a2.273 2.273 0 014.546 0v2.728z" />
    </g>
  </svg>
));
