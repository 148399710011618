import { LiveEvent, LiveEventListItem, Loader, ModalRoute } from 'components';
import VideoModal from 'containers/Common/Modals/VideoModal';
import React, { FC, useEffect } from 'react';
import connect from './connect';
import MoreInfoModal from './MoreInfoModal';
import {
  Container,
  Fold,
  MoreUpcomingEvents,
  NoResults,
  Title,
} from './styles';
import { Props } from './types';

const Events: FC<Props> = ({
  loading,
  liveEvents,
  getLiveEvents,
  match: { url, path },
  isUserPremium,
}) => {
  useEffect(() => {
    getLiveEvents();
  }, [getLiveEvents]);

  if (!loading && liveEvents.length === 0) {
    return (
      <Container>
        <Fold
          title="live events"
          description={`Nothing compares to engaging in real-time data storytelling and this is your regularly opportunity to do so—from the comfort of your own computer.
          These virtual sessions feature a variety of formats and content, each aimed to help you hone your data visualization and storytelling skills. Tune in to learn the strategies Cole and team teach organizations around the world, see interviews with experts, engage in conversations about topics of interest, get your questions answered, and more. Live events are recorded and can be accessed later on demand by premium members.`}
        />
        <NoResults $mainEvent={true} title="No upcoming events yet" />
      </Container>
    );
  }

  return (
    <Container>
      <Fold
        title="live virtual events"
        description={`Nothing compares to engaging in real-time data storytelling and this is your monthly opportunity to do so—from the comfort of your own computer.`}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <LiveEvent
            title={liveEvents[0].title}
            description={liveEvents[0].description}
            image={liveEvents[0].image}
            isPremium={isUserPremium}
            url={url}
            link={liveEvents[0].link}
            startDate={liveEvents[0].startDate}
            endDate={liveEvents[0].endDate}
            isVimeoEvent={liveEvents[0].ctaLink === null}
            thumbnail={liveEvents[0].thumbnail}
            slug={liveEvents[0].slug}
            buttonText={liveEvents[0].ctaText}
            buttonLink={liveEvents[0].ctaLink}
          />
          {liveEvents.length >= 2 ? (
            <MoreUpcomingEvents>
              <Title>More upcoming events</Title>
              {liveEvents.slice(1).map((item) => (
                <LiveEventListItem
                  key={item.id}
                  title={item.title}
                  description={item.description}
                  image={item.image}
                  isPremium={isUserPremium}
                  startDate={item.startDate}
                  endDate={item.endDate}
                  pathname={path}
                  slug={item.slug}
                  zoomRegisterLink={item.ctaLink}
                />
              ))}
            </MoreUpcomingEvents>
          ) : null}
          <ModalRoute
            component={MoreInfoModal}
            exact={true}
            path={`${path}/register/more-info/:eventSlug`}
          />
          <ModalRoute
            component={VideoModal}
            exact={true}
            path={`${path}/:eventSlug`}
          />
        </>
      )}
    </Container>
  );
};

export default connect(Events);
