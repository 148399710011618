import { Loader } from 'components';
import { getUserName } from 'model';
import React, { useEffect } from 'react';
import connect from './connect';
import { Container, Ranking, RankingMember, Title } from './styles';
import { Props } from './types';

const OutstandingMembers: React.FC<Props> = ({
  loading,
  members,
  period,
  getMembers,
}) => {
  useEffect(() => {
    if (period) {
      getMembers({ period });
    }
  }, [getMembers, period]);

  return (
    <Container>
      <Title>Member activity</Title>

      {loading ? (
        <Loader />
      ) : members.length > 0 ? (
        <Ranking>
          {members.map(
            ({
              id,
              ranking,
              avatar,
              firstName,
              lastName,
              challengeStreak,
              followers,
              dataPoints,
              comments,
              uploads,
              slug,
            }) => {
              return (
                <RankingMember
                  key={id}
                  position={ranking}
                  avatar={avatar}
                  name={getUserName({ firstName, lastName })}
                  streaks={challengeStreak}
                  followers={followers}
                  datapoints={dataPoints}
                  comments={comments}
                  uploads={uploads}
                  slug={slug}
                />
              );
            },
          )}
        </Ranking>
      ) : null}
    </Container>
  );
};

export default connect(OutstandingMembers);
