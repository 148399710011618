import React, { FC, forwardRef } from 'react';
import { Container } from './styles';
import { Props } from './types';

const RawSelectOption: FC<Props> = forwardRef<HTMLDivElement, Props>(
  ({ children, ...rest }, ref) => (
    <Container ref={ref} {...rest}>
      {children}
    </Container>
  ),
);

export default RawSelectOption;
