import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { generateIntentKey } from './actions';
import { IntentAction, IntentState } from './types';

const INITIAL_STATE: IntentState = {
  jwk: null,
};

const reducer: Reducer<IntentState, IntentAction> = (
  state = INITIAL_STATE,
  action,
) => {
  // noinspection JSRedundantSwitchStatement
  switch (action.type) {
    case getType(generateIntentKey.success):
      return {
        ...state,
        jwk: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
