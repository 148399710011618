import React from 'react';
import { deleteDiscoverItem } from 'redux/modules/discover';
import { useConnect } from './connect';

export const handleDelete = ({
  openConfirmationModal,
}: {
  openConfirmationModal: ReturnType<typeof useConnect>['openConfirmationModal'];
}) => (discoverId: string, hasAssociatedContent: boolean) => {
  openConfirmationModal({
    acceptButtonLabel: 'Delete',
    acceptButtonVariant: hasAssociatedContent ? 'primary' : 'danger',
    cancelButtonLabel: 'Cancel',
    title: <span>Are you sure?</span>,
    description: hasAssociatedContent ? (
      <span>
        You are deleting this image from you gallery. However, this image will
        be available in its related content.
      </span>
    ) : (
      <span>
        <strong>Deleting this image can’t be undone</strong>. This entry will be
        deleted from the website.
      </span>
    ),
    onAccept: [deleteDiscoverItem.request({ discoverId })],
  });
};
