import ScrollBox from 'components/ScrollBox';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(ScrollBox)`
  display: flex;
  align-items: center;
  flex-direction: column;

  > *:first-child {
    margin-bottom: 2rem;
    max-width: 12.5rem;
  }

  ${from.tablet`
    flex-direction: row;
    > *:first-child {
      margin-bottom: 0;
      width: 12.5rem;
      margin-right: 2rem;
    }
  `};

  ${from.tabletH`
     > *:first-child {
       margin-right: 3.75rem;
     }
   `};
`;

export const Actions = styled.div`
  > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
`;

export const Date = styled.h1`
  font-size: 1.75rem;
  line-height: 1.43;
  font-weight: initial;
  margin: 0.25rem 0 0;
  display: flex;
  flex-direction: column;

  ${from.tabletH`
    flex-direction: row;
  `}

  span {
    :not(:last-child) {
      margin-right: 20px;
    }
  }

  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;

export const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5;
  margin: 0.25rem 0 1.5rem;

  a {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: none;
  }
`;

export const Separator = styled.span`
  display: none;
  ${from.tabletH`
    display: inline;
  `}
`;
