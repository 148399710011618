import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) =>
    `3rem ${theme.wrapperPaddingH.mobile} 5rem ${theme.wrapperPaddingH.mobile}`};
  max-width: 28.75rem;
  margin-left: auto;
  margin-right: auto;
  > :last-child {
    width: 100%;
  }
`;

export const Or = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
`;

export const commonButtonStyles = css`
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;
