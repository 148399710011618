import { Button, Input } from 'components';
import { UpdateCompanyDetailsPremiumNames } from 'model/forms/UpdateCompanyDetailsCorporatePremium';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container, SubmitButton } from './styles';
import { Props } from './types';

const Form: FC<Props> = ({ handleSubmit, onClose }) => {
  return (
    <Container onSubmit={handleSubmit}>
      <Field
        component={Input}
        name={UpdateCompanyDetailsPremiumNames.companyName}
        label="Company name"
        placeholder="Company name"
        validate={required}
      />
      <SubmitButton type="submit">Save changes</SubmitButton>
      <Button variant="secondary" onClick={onClose} type="button">
        Cancel
      </Button>
    </Container>
  );
};

export default connect(Form);
