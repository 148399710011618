import React, { FC, useCallback } from 'react';
import connect from './connect';
import Form from './Form';
import { handleFiltersCleared, handleFormSubmitted } from './logic';
import { Props } from './types';

const SearchOptions: FC<Props> = ({
  contentType,
  hash,
  pathname,
  replace,
  search,
  state,
  ...rest
}) => {
  const onClearFilters = useCallback(
    handleFiltersCleared({
      hash,
      pathname,
      replace,
      search,
    }),
    [hash, pathname, replace, search],
  );

  const onSubmit = useCallback(
    handleFormSubmitted({
      contentType,
      hash,
      pathname,
      replace,
      search,
      state,
    }),
    [contentType, hash, pathname, replace, search],
  );

  return (
    <Form
      contentType={contentType}
      onClearFilters={onClearFilters}
      onSubmit={onSubmit}
      pathname={pathname}
      {...rest}
    />
  );
};

export default connect(SearchOptions);
