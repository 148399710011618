import clamp from 'lodash/clamp';
import { epicDependencies } from 'redux/modules';
import { mergeMap } from 'rxjs/operators';

export const uploadImage = (
  image: File,
  {
    rawRequestWithProgress,
    request,
  }: {
    rawRequestWithProgress: typeof epicDependencies.rawRequestWithProgress;
    request: typeof epicDependencies.request;
  },
) =>
  request<{
    contentType: string;
    retrieveUrl: string;
    uploadUrl: string;
  }>({
    path: 'files/signed_url',
    params: {
      contentType: image.type,
      filename: image.name,
    },
  }).pipe(
    mergeMap(({ contentType, retrieveUrl, uploadUrl }) =>
      rawRequestWithProgress({
        body: image,
        headers: {
          'Content-Type': contentType,
        },
        method: 'PUT',
        url: uploadUrl,
      }).pipe(
        mergeMap((request) => {
          if (request instanceof ProgressEvent) {
            const progress = clamp(request.loaded / request.total, 0, 1);

            // When the request completes, there is a 100% ProgressEvent and a
            // request completed event afterwards. We want to emit the latter
            // and discard the former so we don't emit the same event twice.
            if (progress === 1) {
              return [];
            }

            return [
              {
                progress,
                url: retrieveUrl,
              },
            ];
          }

          return [
            {
              progress: 1,
              url: retrieveUrl,
            },
          ];
        }),
      ),
    ),
  );
