import styled from 'styled-components';

const SIZE = '2.625rem';

export const Checkbox = styled.input`
  appearance: none;
  background: ${({ theme }) => theme.colors.grey300};
  border-radius: 15px;
  cursor: pointer;
  height: calc(${SIZE} * 0.6);
  outline: none;
  width: ${SIZE};
  border: solid 1px transparent;

  ::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: calc(${SIZE} * 0.42);
    height: calc(${SIZE} * 0.42);
    margin: calc(${SIZE} * 0.07);
    background: ${({ theme }) => theme.colors.white};
    transition: 0.2s;
  }

  :checked {
    background: ${({ theme }) => theme.colors.accent};

    ::after {
      margin-left: calc(${SIZE} * 0.48);
    }
  }

  :focus {
    border: solid 1px ${({ theme }) => theme.colors.accent};
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }
`;
