import { ResetPasswordForm } from 'model/forms/ResetPassword';
import { FormErrors } from 'redux-form';

const validate = ({ password, repeatPassword }: ResetPasswordForm) => {
  const errors: FormErrors<ResetPasswordForm> = {};

  if (password !== repeatPassword) {
    errors.repeatPassword = 'The passwords do not match';
  }

  return errors;
};

export default validate;
