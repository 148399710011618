import { AppContentType, Comment, UpsertByIdPayload } from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const addComments = createAction('comment/ADD_COMMENTS')<Comment[]>();

export const createComment = createAsyncAction(
  'comment/CREATE_COMMENT_REQUEST',
  'comment/CREATE_COMMENT_SUCCESS',
  'comment/CREATE_COMMENT_FAILURE',
)<
  {
    comment: string;
    contentType: AppContentType;
    image?: string;
    objectId: string;
    parent?: string;
  },
  Comment,
  undefined
>();

export const deleteComment = createAsyncAction(
  'comment/DELETE_COMMENT_REQUEST',
  'comment/DELETE_COMMENT_SUCCESS',
  'comment/DELETE_COMMENT_FAILURE',
)<
  {
    id: string;
  },
  {
    id: string;
  },
  undefined
>();

export const editComment = createAsyncAction(
  'comment/EDIT_COMMENT_REQUEST',
  'comment/EDIT_COMMENT_SUCCESS',
  'comment/EDIT_COMMENT_FAILURE',
)<
  {
    comment: string;
    commentId: string;
    contentType: AppContentType;
    image: string | null;
    objectId: string;
  },
  Comment,
  undefined
>();

export const toggleReplyFormOpen = createAction(
  'comment/TOGGLE_REPLY_FORM_OPEN',
)<string>();

export const _upsertCommentsById = createAction(
  'feedback/_UPSERT_COMMENTS_BY_ID',
)<UpsertByIdPayload<Comment>>();
