import Radio from 'components/Radio';
import React, { FC, memo } from 'react';
import { Container, Option } from './styles';
import { Props } from './types';

const id = (name: string, value: string) => `radio_${name}_${value}`;

const RadioGroup: FC<Props> = ({
  input: { name, value: inputValue, ...input },
  options,
  ...rest
}) => (
  <Container {...rest}>
    {options.map(({ label, value }) => (
      <Option key={value}>
        <Radio
          defaultChecked={inputValue === value}
          id={id(name, value)}
          name={name}
          value={value}
          {...input}
        />
        <label htmlFor={id(name, value)}>{label}</label>
      </Option>
    ))}
  </Container>
);

export default memo(RadioGroup);
