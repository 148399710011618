import React, { FC, memo } from 'react';
import { Container, HTMLInput, Wrapper } from './styles';
import { Props } from './types';

const InputWithIcon: FC<Props> = ({
  Icon,
  input,
  label,
  meta,
  name,
  ...rest
}) => (
  <Container name={name} label={label} meta={meta}>
    <Wrapper>
      <HTMLInput {...input} {...rest} />
      <Icon />
    </Wrapper>
  </Container>
);

export default memo(InputWithIcon);
