import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.5rem;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 60.625rem;
  overflow: hidden;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 2.75rem 2.5rem 1.875rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.grey100};
  ${from.laptop`
    height: 35.75rem;
  `}
`;

export const Title = styled.h2`
  margin: 0 0 1.125rem 0;
  font-size: 1.75rem;
  line-height: 1.2;
  text-align: center;
`;

export const Subtitle = styled.p`
  margin: 0 0 1.5rem 0;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  max-width: 30.8125rem;
`;

export const PremiumTypeTabs = styled.div`
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  max-width: 23.75rem;
  cursor: pointer;
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

const PremiumTab = styled.div<{ $activeTab: boolean }>`
  display: inline-block;
  width: 50%;
  border-radius: 0.5rem;

  ${({ $activeTab }) =>
    $activeTab &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};
    `}
`;

export const PremiumTabIndividual = styled(PremiumTab)``;

export const PremiumTabCorporate = styled(PremiumTab)``;

export const TabText = styled.h5<{ $activeTab: boolean }>`
  margin: 0;
  padding: 10px;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-align: center;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  ${from.tablet`
    font-size: 1.25rem;
  `}
  ${({ $activeTab }) =>
    $activeTab &&
    css`
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.weights.bold};
    `}
`;

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > :not(:last-child) {
    margin-bottom: 1.375rem;
  }
  ${from.laptop`
    flex-direction: row;
    > :not(:last-child) {
      margin-bottom: 0;
      margin-right: 1.375rem;
     }
  `}
`;

export const Info = styled.div`
  box-sizing: border-box;
  padding: 2rem 3rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: solid 1px ${({ theme }) => theme.colors.greyBlue};
  ${from.tablet`
    padding: 2rem 5.8rem;
  `}
`;

export const Description = styled.p`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: 1.5rem;
  margin-top: 0;

  ${from.tablet`
    text-align: center;
  `}
`;

export const Features = styled.ul`
  list-style: none;
  width: fit-content;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.75rem;
  grid-row-gap: 0.5rem;
  ${from.tablet`
    margin: 0 auto;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 1fr 1fr;
  `}
  ${from.laptop`
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 1fr 1fr;
  `}
`;
