export enum BookingNotification {
  NONE = 'N',
  ONE_DAY_BEFORE = 'D',
  ONE_HOUR_BEFORE = 'H',
}

export interface OfficeHourBooking {
  id: string;
  session: string;
  addToCalendar: boolean;
  sendNotification: BookingNotification;
}
