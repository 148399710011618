import { FormNameMap } from 'utils/forms';

export const SECONDARY_EMAIL_FORM_KEY = 'forms/SECONDARY_EMAIL';

export interface SecondaryEmailForm {
  email: string;
  password: string;
}

export const secondaryEmailFieldNames: FormNameMap<SecondaryEmailForm> = {
  email: 'email',
  password: 'password',
};
