import CheckIcon from 'components/icons/Check';
import styled from 'styled-components';

export const Container = styled.li`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-transform: none;
  letter-spacing: 0px;
  padding: 0.625rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Check = styled(CheckIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 1rem;
`;

export const CheckContainer = styled.div`
  width: 16px;
  margin-right: 12px;
`;
