import Comments from 'components/Comments';
import { ThumbsUpIcon } from 'components/icons';
import Statuses from 'components/Statuses';
import { formatDistanceToNow, parseISO } from 'date-fns';
import React, { FC, memo } from 'react';
import { amaDate, amaTime } from 'utils/dates';
import excludeOtherTag from 'utils/excludeOtherTag';
import {
  AmaDate,
  Author,
  AuthorContainer,
  Avatar,
  Container,
  ConversationGrid,
  DatapointsContainer,
  DatapointsValue,
  Date,
  RawText,
  Statistics,
  StatusesAndTags,
  Tags,
  Title,
} from './styles';
import { Props } from './types';

const Conversation: FC<Props> = ({
  authorAvatar,
  author,
  authorType,
  swdTeamChimedIn,
  commentsCount,
  datapoints,
  created,
  statuses,
  tags,
  title,
  description,
  views,
  isAma,
  isMonthly = false,
  isSpotlight,
  spotlightImage = null,
  spotlightUsers,
  spotlightDate,
  live,
  amaStartDate,
  amaEndDate,
  closed,
  lastComments,
  amaAnswers,
  url,
  ...rest
}) => {
  const filteredTags = tags
    .filter((tag) => !statuses.includes(tag))
    .filter(excludeOtherTag);

  return (
    <Container
      {...rest}
      isAma={isAma}
      live={live}
      isMonthly={isMonthly}
      isSpotlight={isSpotlight}
    >
      <ConversationGrid>
        <Avatar variant={authorType} src={authorAvatar} />
        {isAma ? (
          <AmaDate>
            {amaStartDate && amaEndDate && (
              <>
                <span>Event Date: {amaDate(amaStartDate)}</span>
                <span>{amaTime(amaStartDate, amaEndDate)}</span>
              </>
            )}
          </AmaDate>
        ) : (
          <AuthorContainer>
            <Author>{author}</Author>
            <Date>
              {' '}
              Posted{' '}
              {created &&
                formatDistanceToNow(parseISO(created), { addSuffix: true })}
            </Date>
          </AuthorContainer>
        )}
        <RawText content={description} />
        <Title>{title}</Title>
        <StatusesAndTags>
          {statuses.length > 0 && (
            <Statuses
              isAma={isAma}
              live={live}
              closed={closed}
              authorIsSwdTeam={authorType === 'swd'}
              amaEndDate={amaEndDate}
              statuses={statuses}
            />
          )}
          <Tags items={filteredTags} oneLine={true} />
        </StatusesAndTags>
        <DatapointsContainer>
          <ThumbsUpIcon width="22px" />
          <DatapointsValue>{datapoints}</DatapointsValue>
        </DatapointsContainer>
      </ConversationGrid>
      <Statistics>
        <Comments
          items={lastComments}
          numberOfComments={commentsCount}
          amaAnswers={amaAnswers}
          isAma={isAma}
          closed={closed}
          live={live}
          url={url}
        />
      </Statistics>
    </Container>
  );
};

export default memo(Conversation);
