import { PageTitle } from 'components';
import { getUserName } from 'model/User';
import React, { FC, useEffect, useRef } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { useConnect } from './connect';
import {
  ActivityCard,
  ActivityFilters,
  ActivityList,
  ActivityPagination,
  Container,
  ErrorIcon,
  NoActivityContainer,
  NoActivitySubtitle,
  NoActivityTitle,
} from './styles';
import { Props } from './types';

const Activity: FC<Props> = ({ className }) => {
  const { activity, getActivity, pageCount, search, url } = useConnect();

  const page = getPageFromQuery(search);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getActivity({ search, page });
  }, [getActivity, search, page]);

  return (
    <Container ref={containerRef} className={className}>
      <PageTitle title="Activity" />
      {activity.length !== 0 ? (
        <ActivityList role="list">
          {activity.map(({ id, message, targetObject, user }) => (
            <ActivityCard
              key={id}
              action={message}
              avatar={user.avatar}
              object={targetObject ? targetObject.title : ''}
              objectLink={targetObject ? targetObject.url : ''}
              subject={getUserName(user)}
              subjectLink={`/members/${user.id}`}
              authorType={getUserType(user)}
            />
          ))}
        </ActivityList>
      ) : (
        <NoActivityContainer>
          <ErrorIcon />
          <NoActivityTitle>
            Oops! You don't have any recent activity yet.
          </NoActivityTitle>
          <NoActivitySubtitle>
            Go ahead and participate in the community!
          </NoActivitySubtitle>
        </NoActivityContainer>
      )}
      <ActivityFilters />
      <ActivityPagination
        contentRef={containerRef}
        current={Math.min(page, pageCount)}
        total={pageCount}
        url={url}
      />
    </Container>
  );
};

export default Activity;
