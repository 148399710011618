import { connect } from 'react-redux';
import { getDiscoverItems } from 'redux/modules/discover';
import { selectDiscoverImages } from 'redux/modules/discover/selectors';
import { RootState } from 'redux/modules/types';
import { addVote, removeVote } from 'redux/modules/vote';

const mapStateToProps = (state: RootState) => ({
  loading: state.discover.loading,
  items: selectDiscoverImages(state),
  pageCount: state.discover.pageCount,
});

const mapDispatchToProps = {
  addVote: addVote.request,
  getDiscoverItems: getDiscoverItems.request,
  removeVote: removeVote.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
