import { Radio as DefaultRadio, Select as DefaultSelect } from 'components';
import styled from 'styled-components';
import { from, visuallyHidden } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.form``;

export const Select = styled(DefaultSelect)`
  margin-bottom: 1rem;

  ${from.tabletH`
    ${visuallyHidden}
  `}
`;

export const Fieldset = styled.fieldset`
  display: none;

  ${from.tabletH`
    margin-inline-start: initial;
    margin-inline-end: initial;
    padding-block-start: initial;
    padding-inline-start: initial;
    padding-inline-end: initial;
    padding-block-end: initial;
    min-inline-size: initial;
    border-width: initial;
    border-style: initial;
    border-color: initial;
    border-image: initial;

    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    padding: 12px 16px;
    border: 1px solid ${({ theme }) => theme.colors.grey200};
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    background: ${({ theme }) => addAlpha(theme.colors.grey200, 0.4)};
  `}
`;

export const VisuallyHiddenLegend = styled.legend`
  ${visuallyHidden}
`;

export const Label = styled.label`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 1rem;
`;

export const VisuallyHiddenInputRadio = styled(DefaultRadio)`
  ${visuallyHidden}

  :not(:disabled) {
    :hover
      + ${Label},
      :checked
      + ${Label},
      :focus
      + ${Label},
      :focus-within
      + ${Label} {
      background: ${({ theme }) => theme.colors.white};
    }

    :hover + ${Label}, :checked + ${Label} {
      border: 1px solid ${({ theme }) => theme.colors.greyBlue};
    }

    :focus + ${Label}, :focus-within + ${Label} {
      border: 1px solid ${({ theme }) => theme.colors.accent};
    }
  }
`;
