import { BooleanInput as DefaultBooleanInput } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form``;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
`;

export const Info = styled.span`
  font-size: 14px;
  line-height: 1rem;
  margin: 0.8rem 0 0 0;
`;

export const BooleanInput = styled(DefaultBooleanInput)`
  margin-bottom: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1.875rem 0.375rem;

  ${from.tablet`
    flex-direction: row;
    padding: 2.5rem 3.75rem 0.375rem;
    
    > * {
      flex: 1;
  
      :first-child {
        margin-right: 2rem;
      }
    }
  `};
`;

export { TitleSection } from '../styles';
