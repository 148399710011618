import { EditProfileForm, EDIT_PROFILE_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState) => {
  const user = state.auth.user;

  const initialValues = {
    showSocialInfo: user?.showSocialInfo,
    socialLinkedin: user?.socialLinkedin,
    socialTwitter: user?.socialTwitter,
    socialWeb: user?.socialWeb,
    socialInstagram: user?.socialInstagram,
  };

  return {
    initialValues,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<EditProfileForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<EditProfileForm>({
    form: `${EDIT_PROFILE_FORM_KEY}/onboarding/social-info`,
  }),
) as FormDecorator<EditProfileForm, OwnProps, ConnectedProps>;
