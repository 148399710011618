import { AppContentType, REPORT_FORM_KEY } from 'model';
import { destroy } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { _upsertChallengeResponsesById } from 'redux/modules/challengeResponse';
import { _upsertCommentsById } from 'redux/modules/comment';
import { _upsertConversationsById } from 'redux/modules/conversation';
import { _upsertDiscoverById } from 'redux/modules/discover';
import { _upsertExerciseResponsesById } from 'redux/modules/exerciseResponse';
import { _upsertFeedbackRequestsById } from 'redux/modules/feedback';
import { showSnackbar } from 'redux/modules/snackbar';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { report } from './actions';

const markAsReported = (contentType: AppContentType, objectId: string) => {
  switch (contentType) {
    case 'challengeresponse':
      return [
        _upsertChallengeResponsesById([
          {
            id: objectId,
            isReported: true,
          },
        ]),
      ];
    case 'comment':
      return [
        _upsertCommentsById([
          {
            id: objectId,
            isReported: true,
          },
        ]),
      ];
    case 'conversation':
      return [
        _upsertConversationsById([
          {
            id: objectId,
            isReported: true,
          },
        ]),
      ];
    case 'exerciseresponse':
      return [
        _upsertExerciseResponsesById([
          {
            id: objectId,
            isReported: true,
          },
        ]),
      ];
    case 'feedback':
      return [
        _upsertFeedbackRequestsById([
          {
            id: objectId,
            isReported: true,
          },
        ]),
      ];
    case 'galleryentry':
      return [
        _upsertDiscoverById([
          {
            id: objectId,
            isReported: true,
          },
        ]),
      ];
    default:
      return [];
  }
};

export const reportEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(report.request)),
    mergeMap(({ payload }) =>
      request<{}>({
        body: payload,
        method: 'POST',
        path: 'report',
      }).pipe(
        mergeMap(() => [
          report.success(),
          ...markAsReported(payload.contentType, payload.objectId),
          showSnackbar({
            message: 'Your report was submitted',
          }),
          destroy(`${REPORT_FORM_KEY}/${payload.objectId}`),
        ]),
        catchError(() => [
          report.failure(),
          showSnackbar({
            message: 'There was an error when sending your report',
          }),
        ]),
      ),
    ),
  );

export default combineEpics(reportEpic);
