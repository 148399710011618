import {
  ExerciseResponse,
  MediaList,
  PaginatedResponse,
  UpsertByIdPayload,
} from 'model';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const createExerciseResponse = createAsyncAction(
  'exerciseResponse/CREATE_EXERCISE_RESPONSE_REQUEST',
  'exerciseResponse/CREATE_EXERCISE_RESPONSE_SUCCESS',
  'exerciseResponse/CREATE_EXERCISE_RESPONSE_FAILURE',
)<
  {
    description: string;
    exerciseId: string;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    mediaList: MediaList;
    title: string;
  },
  ExerciseResponse,
  undefined
>();

export const deleteExerciseResponse = createAsyncAction(
  'exerciseResponse/DELETE_EXERCISE_RESPONSE_REQUEST',
  'exerciseResponse/DELETE_EXERCISE_RESPONSE_SUCCESS',
  'exerciseResponse/DELETE_EXERCISE_RESPONSE_FAILURE',
)<{ exerciseId: string; responseId: string }, { id: string }, undefined>();

export const editExerciseResponse = createAsyncAction(
  'exerciseResponse/EDIT_EXERCISE_RESPONSE_REQUEST',
  'exerciseResponse/EDIT_EXERCISE_RESPONSE_SUCCESS',
  'exerciseResponse/EDIT_EXERCISE_RESPONSE_FAILURE',
)<
  Partial<{
    description: string;
    exerciseId: string;
    graphIndustry: string[];
    graphTools: string[];
    graphTopic: string[];
    graphTypes: string | null;
    mediaList: MediaList;
    title: string;
  }> & {
    exerciseId: string;
    responseId: string;
  },
  ExerciseResponse,
  undefined
>();

export const getExerciseResponse = createAsyncAction(
  'exerciseResponse/GET_EXERCISE_RESPONSE_REQUEST',
  'exerciseResponse/GET_EXERCISE_RESPONSE_SUCCESS',
  'exerciseResponse/GET_EXERCISE_RESPONSE_FAILURE',
)<
  { exerciseSlug: string; exerciseResponseSlug: string },
  ExerciseResponse,
  undefined
>();

export const getExerciseResponses = createAsyncAction(
  'exerciseResponse/GET_EXERCISE_RESPONSES_REQUEST',
  'exerciseResponse/GET_EXERCISE_RESPONSES_SUCCESS',
  'exerciseResponse/GET_EXERCISE_RESPONSES_FAILURE',
)<
  { exerciseSlug: string; search?: string; silent?: boolean },
  PaginatedResponse<ExerciseResponse>,
  undefined
>();

export const _upsertExerciseResponsesById = createAction(
  'feedback/_UPSERT_EXERCISE_RESPONSES_BY_ID',
)<UpsertByIdPayload<ExerciseResponse>>();
