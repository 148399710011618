import { PostAuthor, RichText, ScrollBox } from 'components';
import Actions from 'containers/Common/PostActions';
import React, { FC } from 'react';
import {
  Container,
  ImageAndText,
  ImageListViewer,
  ImageWrapper,
  ShareButton,
  Title,
  TitleContainer,
  TitleLink,
} from './styles';
import { Props } from './types';

const ConversationView: FC<Props> = ({
  amaEndDate,
  amaImage,
  authorAvatar,
  authorId,
  authorName,
  authorType,
  authorSlug,
  closed,
  comments,
  created,
  description,
  id,
  isAma,
  isMonthly,
  isSpotlight,
  spotlightImage,
  spotlightUsers,
  spotlightDate,
  isReported,
  isVoted,
  live,
  open,
  scroll,
  shareButtonMessage,
  statuses,
  tags,
  title,
  titleClickable = false,
  url,
  views,
  votes,
  ...rest
}) => (
  <Container {...rest}>
    <TitleContainer>
      {titleClickable ? (
        <TitleLink to={url}>
          <Title>{title}</Title>
        </TitleLink>
      ) : (
        <Title>{title}</Title>
      )}
      <ShareButton
        content={shareButtonMessage}
        url={`${window.location.origin}${url}`}
      />
    </TitleContainer>
    <ScrollBox scroll={scroll}>
      <PostAuthor
        amaEndDate={amaEndDate}
        authorType={authorType}
        avatar={authorAvatar}
        closed={closed}
        comments={comments}
        date={created}
        id={authorId}
        isAma={isAma}
        isMonthly={isMonthly}
        isSpotlight={isSpotlight}
        live={live}
        name={authorName}
        slug={authorSlug}
        spotlightDate={spotlightDate}
        spotlightImage={spotlightImage}
        spotlightUsers={spotlightUsers}
        statuses={statuses}
        tags={tags}
        views={views}
      />
      {isAma || isSpotlight ? (
        <ImageAndText>
          <ImageWrapper>
            {amaImage && (
              <ImageListViewer images={[amaImage]} zoomUrl={`${url}/images`} />
            )}
            {spotlightImage && (
              <ImageListViewer
                images={[spotlightImage]}
                zoomUrl={`${url}/images`}
              />
            )}
          </ImageWrapper>
          <RichText content={description} />
        </ImageAndText>
      ) : (
        <RichText content={description} />
      )}
      <Actions
        authorId={authorId}
        contentType="conversation"
        id={id}
        isReported={isReported}
        isVoted={isVoted}
        url={url}
        votes={votes}
      />
    </ScrollBox>
  </Container>
);

export default ConversationView;
