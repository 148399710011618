import DefaultAddressCardIcon from 'components/icons/AddressCard';
import DefaultBookIcon from 'components/icons/Book';
import DefaultPodcastIcon from 'components/icons/Podcast';
import DefaultSpotlightIcon from 'components/icons/Spotlight';
import DefaultSchoolTestResultsIcon from 'components/icons/University';
import DefaultLink from 'components/Link';
import DefaultProfileLink from 'components/ProfileLink';
import UserProfileFollowButton from 'components/UserProfileFollowButton';
import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FollowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const FollowInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FollowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.4375px;
  text-align: left;

  span:last-child {
    font-weight: ${({ theme }) => theme.weights.bold};
    letter-spacing: 0;
  }
`;

export const FollowButton = styled(UserProfileFollowButton)``;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SectionTitle = styled.h3`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 24px;
  text-align: left;
  margin: 0;
`;

export const SectionSubtitle = styled.h4`
  font-size: 10px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 10px;
  letter-spacing: 0.83px;
  text-align: left;
  text-transform: uppercase;
  margin: 0;
`;

export const SectionText = styled.p`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 19.6px;
  text-align: left;
  margin: 0;
`;

export const SwdActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProfileLink = styled(DefaultProfileLink)`
  display: flex;
  gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 14px;
  letter-spacing: 0.44px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;

  svg {
    width: 20px;
    height: 100%;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BookIcon = styled(DefaultBookIcon)`
  width: 1.25rem;
`;

export const AddressCardIcon = styled(DefaultAddressCardIcon)`
  width: 1.25rem;
`;

export const PodcastIcon = styled(DefaultPodcastIcon)`
  width: 1.25rem;
`;

export const SpotlightIcon = styled(DefaultSpotlightIcon)`
  width: 1.25rem;
`;

export const SchoolTestResultsIcon = styled(DefaultSchoolTestResultsIcon)`
  width: 1.25rem;
`;

export const SocialLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SocialLink = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 24px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    text-decoration: underline;
  }
`;

export const PersonalWebsiteLink = styled(SocialLink)`
  font-weight: ${({ theme }) => theme.weights.bold};
`;
