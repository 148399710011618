import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="39"
    height="37"
    viewBox="0 0 39 37"
  >
    <defs>
      <rect id="bold-b" width="29" height="27" x="0" y="0" rx="4" />
      <filter
        id="bold-a"
        width="158.6%"
        height="163%"
        x="-29.3%"
        y="-24.1%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          radius="1.5"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.28627451   0 0 0 0 0.31372549   0 0 0 0 0.341176471  0 0 0 0.400841346 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 3)">
      <use fill="#000" filter="url(#bold-a)" xlinkHref="#bold-b" />
      <rect
        width="28"
        height="26"
        x=".5"
        y=".5"
        fill="#FFF"
        stroke="#297FD5"
        strokeLinejoin="inherit"
        rx="4"
      />
      <path
        fill="#297FD5"
        fillRule="nonzero"
        d="M9,20 L15.8909091,20 C19.3454545,20 21,18.7243867 21,16.3419913 C21,14.2222222 19.5454545,13.4155844 16.9454545,13.0591631 L16.9454545,12.9466089 C19.2,12.6277056 20.4363636,11.7272727 20.4363636,10.0952381 C20.4363636,8.02476593 19.0313388,7.04598953 15.5605281,7.00158482 L9,7 L9,7.61904762 L9.66062449,7.7886466 C10.1575185,7.92256074 10.3090909,8.08639187 10.3090909,9.25108225 L10.3090909,17.7489177 L10.3047991,18.0552909 C10.2770878,18.9445427 10.1101952,19.090193 9.66062449,19.2113534 L9,19.3809524 L9,20 Z M15.4,19.0808081 L15.1692644,19.0761853 C14.4918516,19.0455239 14.3088075,18.8495056 14.2921966,18.113533 L14.2909091,13.6031746 L15,13.6031746 C16.3272727,13.6031746 16.8545455,14.0909091 16.8545455,16.2294372 C16.8545455,18.5743146 16.3636364,19.0808081 15.4,19.0808081 Z M14.2909091,12.7215007 L14.2909091,8.05050505 L14.3964584,8.01880257 C14.5553311,7.97593216 14.830303,7.91919192 15.1272727,7.91919192 C16.0909091,7.91919192 16.5818182,8.31313131 16.5818182,10.3766234 L16.5781603,10.7202497 C16.5427879,12.2476062 16.2339394,12.7215007 14.9272727,12.7215007 L14.2909091,12.7215007 Z"
      />
    </g>
  </svg>
));
