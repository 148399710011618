import styled from 'styled-components';
import { from, title } from 'styles/mixins';

export const Loading = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) =>
    `3rem ${theme.wrapperPaddingH.mobile} 5rem ${theme.wrapperPaddingH.mobile}`};
  text-align: center;
`;

export const Title = styled.h1`
  ${title};
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;

  ${from.tablet`
    max-width: 340px;
  `};
`;
