import React, { FC, memo } from 'react';
import { Box, Container, SortAmountDownIcon } from './styles';
import { Props } from './types';

const OrderDirection: FC<Props> = ({ className, disabled, ...rest }) => (
  <Container disabled={disabled} className={className}>
    <Box disabled={disabled} type="checkbox" {...rest} />
    <SortAmountDownIcon title="Sort lowest to highest" />
  </Container>
);

export default memo(OrderDirection);
