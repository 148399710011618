import { User } from '.';

export const getUserName = ({
  firstName,
  lastName,
}: {
  firstName: User['firstName'];
  lastName: User['lastName'];
}) => `${firstName} ${lastName}`;

const initSlugs = {
  pageCount: 0,
  slugs: [] as string[],
  loading: false,
};

export const transformUser = ({
  feedbackResponseSlugs,
  conversationSlugs,
  gallerySlugs,
  challengeResponseSlugs,
  exerciseResponseSlugs,
  likedSlugs,
  ...user
}: User): User => ({
  feedbackResponseSlugs: initSlugs,
  conversationSlugs: initSlugs,
  gallerySlugs: initSlugs,
  challengeResponseSlugs: initSlugs,
  exerciseResponseSlugs: initSlugs,
  likedSlugs: initSlugs,
  ...user,
});
