import { VideoContentType } from 'components/Video/types';
import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { getEvent } from 'redux/modules/event';
import { selectEvent, selectEventLoading } from 'redux/modules/event/selectors';
import { getLiveEvents } from 'redux/modules/liveEvents';
import { RootState } from 'redux/modules/types';
import {
  getProminentVideo,
  getVideo,
  selectVideo,
  selectVideoLoading,
} from 'redux/modules/video';
import { OwnProps } from './types';

export interface VideoModalData {
  chatUrl?: string;
  id: string;
  isLiveEvent?: boolean;
  endDate?: string;
  category?: string;
  createdAt: string;
  description: string;
  duration: number;
  link: string;
  tags: ReadonlyArray<string>;
  title: string;
  views: number;
  solutionFor: Readonly<{ slug: string; name: string }> | null;
  contentType: VideoContentType;
  progress: number;
}

const mapStateToProps = (state: RootState, props: OwnProps) => {
  let data: VideoModalData | undefined;

  if (props.match?.params.videoSlug) {
    const response = selectVideo(state);
    if (response) {
      data = {
        id: response.id,
        createdAt: response.created,
        description: response.description,
        duration: response.duration,
        link: response.videoLink,
        tags: response.videoSeriesTags,
        title: response.title,
        views: response.views,
        solutionFor: response.solutionFor,
        contentType: 'videolesson',
        progress: response.progress,
      };
    }
  }

  const eventSlug = props.match?.params.eventSlug;

  if (eventSlug) {
    const eventResponse = selectEvent(state);

    if (eventResponse) {
      data = {
        id: eventResponse.id,
        chatUrl: eventResponse?.chatUrl,
        createdAt: eventResponse.created,
        description: eventResponse.description,
        duration: eventResponse.duration,
        endDate: eventResponse.endDate,
        link: eventResponse.link,
        tags: eventResponse.exerciseTags,
        title: eventResponse.title,
        views: eventResponse.views,
        progress: eventResponse.progress,
        solutionFor: eventResponse.solutionFor,
        isLiveEvent: !eventResponse.ctaLink,
        contentType: 'pastliveevent',
      };
    }
  }

  return {
    data,
    userIsPremium: selectUserIsPremium(state),
    isLoggedIn: !!state.auth.user,
    videoLoading: selectVideoLoading(state),
    eventLoading: selectEventLoading(state),
  };
};

const mapDispatchToProps = {
  getLiveEvent: getLiveEvents.request,
  getProminentVideo: getProminentVideo.request,
  getVideo: getVideo.request,
  getEvent: getEvent.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
