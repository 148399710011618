import React, { FC, useCallback } from 'react';
import connect from './connect';
import Form from './Form';
import { handleFormSubmitted } from './logic';
import { Props } from './types';

const LoginFragment: FC<Props> = ({ logIn }) => {
  const onSubmit = useCallback(handleFormSubmitted({ logIn }), [logIn]);

  return <Form onSubmit={onSubmit} />;
};

export default connect(LoginFragment);
