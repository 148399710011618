import { useMediaQuery } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { AddImageIcon, Container, Description, Title } from './styles';
import { Props } from './types';

const DragFiles: FC<Props> = ({ compact = false, ...rest }) => {
  const isMouseInput = useMediaQuery('(pointer:fine)');

  return (
    <Container {...rest} $isMouseInput={isMouseInput}>
      <AddImageIcon />
      {!compact &&
        (isMouseInput ? (
          <>
            <Title>Drag and drop to upload</Title>
            <Description>or Browse to choose the file</Description>
          </>
        ) : (
          <Title>Browse to choose the file</Title>
        ))}
    </Container>
  );
};

export default memo(DragFiles);
