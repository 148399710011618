import { ZoomIcon as DefaultZoomIcon } from 'components/icons';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div<{ showThumbnails: boolean }>`
  margin-bottom: 1.875rem;
  position: relative;

  ${({ showThumbnails }) =>
    showThumbnails &&
    css`
      display: grid;
      grid-row-gap: ${({ theme }) => theme.spacing.md};
      grid-template-row: auto;
    `};

  ${from.tablet<{ showThumbnails: boolean }>`
    width: 100%;
    ${({ showThumbnails }) =>
      showThumbnails &&
      css`
        grid-column-gap: ${({ theme }) => theme.spacing.md};
        grid-template-columns: auto 2.75rem;
      `};
  `};
`;

export const ImageContainer = styled.div`
  height: fit-content;
  position: relative;
  ${from.tablet`
    :hover {
      > :first-child {
        background-color: ${({ theme }) => addAlpha(theme.colors.grey500, 0.2)};
        transition: background-color 0.2s;
      }
    }
  `};
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  border-radius: 0.25rem;
  display: block;
  object-fit: cover;
  width: 100%;
`;

export const Video = styled(Image).attrs({ as: 'video', controls: true })``;

export const ZoomLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
  ${from.tablet`
    :hover, :focus-visible {
      > :first-child{
        display: flex;
      }
    }
  `}
`;

export const ZoomIconBg = styled.div`
  align-items: center;
  display: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  bottom: ${({ theme }) => theme.spacing.md};
  height: 2.75rem;
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => theme.spacing.md};
  text-decoration: none;
  width: 2.75rem;
`;

export const ZoomIcon = styled(DefaultZoomIcon)`
  width: 70%;
`;

export const Thumbnails = styled.div`
  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
    margin-right: 0.5rem;
  }

  display: flex;
  flex-wrap: wrap;

  ${from.tablet`
    display: inline;
`}
`;
