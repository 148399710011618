import { PageTitle, SearchSections, SWDLogo } from 'components';
import { parse } from 'query-string';
import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useFirst } from 'utils/useFirst';
import connect from './connect';
import { isAnyFilterEnabled } from './logic';
import NoResults from './NoResults';
import Sections, {
  BlogsSection,
  BooksSection,
  ChallengesSection,
  ConversationsSection,
  ExercisesSection,
  FeedbackSection,
  PodcastsSection,
  VideosSection,
  VisualsSection,
} from './Sections';
import {
  CloseIcon,
  CommunityTitle,
  Container,
  Content,
  FeaturedLink,
  GoBackLink,
  ResourceContainer,
  SearchOptions,
  Section,
  Title,
} from './styles';
import { Props } from './types';

const Search: FC<Props> = ({
  availableSections,
  lastLocation,
  loading,
  location: { search: queryString },
  match: { path, url },
  search,
}) => {
  const firstLastLocation = useFirst(lastLocation);

  // Whenever the search is unmounted, reset the state.
  useEffect(() => {
    return () => {
      // Search with no filters at all to reset the state.
      search({ query: '' });
    };
  }, [search]);

  const anySectionAvailable =
    availableSections.blog ||
    availableSections.books ||
    availableSections.challenges ||
    availableSections.conversations ||
    availableSections.exercises ||
    availableSections.feedback ||
    availableSections.podcast ||
    availableSections.visuals ||
    availableSections.videos;

  const anySectionLoading =
    loading.blog ||
    loading.books ||
    loading.challengeResponse ||
    loading.challenges ||
    loading.conversations ||
    loading.exercises ||
    loading.feedback ||
    loading.podcast ||
    loading.visuals ||
    loading.videos;

  const shouldShowCta =
    !anySectionLoading && isAnyFilterEnabled(parse(queryString));

  return (
    <Container>
      <PageTitle title="Search" />
      <Content>
        <Section>
          <CommunityTitle to="/">
            <SWDLogo />
          </CommunityTitle>
          <GoBackLink to={firstLastLocation || '/'}>
            <CloseIcon />
          </GoBackLink>
        </Section>
        <Section>
          <Title>Let us connect you with the resources you need</Title>
        </Section>
        <Section>
          <SearchOptions
            contentType="search"
            searchPlaceholder="What can we help you find? Search for a topic, resource, type of visual, etc."
          />
        </Section>
        <Section>
          <ResourceContainer>
            <SearchSections
              available={availableSections}
              search={queryString}
              url={url}
            />
          </ResourceContainer>
        </Section>
      </Content>
      <Content>
        <Switch>
          <Route component={BlogsSection} path={`${path}/blog`} />
          <Route component={BooksSection} path={`${path}/books`} />
          <Route component={ChallengesSection} path={`${path}/challenges`} />
          <Route
            component={ConversationsSection}
            path={`${path}/conversations`}
          />
          <Route component={ExercisesSection} path={`${path}/exercises`} />
          <Route component={FeedbackSection} path={`${path}/feedback`} />
          <Route component={PodcastsSection} path={`${path}/podcast`} />
          <Route component={VisualsSection} path={`${path}/visuals`} />
          <Route component={VideosSection} path={`${path}/videos`} />
          <Route component={Sections} path={path} />
        </Switch>
      </Content>
      {shouldShowCta &&
        (anySectionAvailable ? (
          <Content>
            <FeaturedLink
              text="Start a conversation"
              title="can't find what you're looking for?"
              to={`/conversations#start-conversation`}
            >
              <p>Ask our community and find the answer!</p>
            </FeaturedLink>
          </Content>
        ) : (
          <NoResults />
        ))}
    </Container>
  );
};

export default connect(Search);
