import React, { FC, useCallback } from 'react';
import ClearIndicator from './ClearIndicator';
import Control from './Control';
import DropdownIndicator from './DropdownIndicator';
import IndicatorSeparator from './IndicatorSeparator';
import Menu from './Menu';
import MultiValue from './MultiValue';
import Option from './Option';
import { Container, Select } from './styles';
import { Props } from './types';

const selectComponents = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  IndicatorSeparator,
  Menu,
  MultiValue,
  Option,
};

const Selector: FC<Props> = ({
  className,
  isMulti = false,
  input: { value, onChange, onBlur, name },
  isDisabled,
  label,
  meta,
  options,
  placeholder,
  theme,
  ...rest
}) => {
  const onOptionChange = useCallback(
    (e) => {
      if (e) {
        if (Array.isArray(e)) {
          onChange(e.map(({ value }: { value: string }) => value));
        } else {
          onChange(e.value);
        }
      } else {
        onChange(e ? e.value : null);
      }
    },
    [onChange],
  );
  const onSelectBlur = useCallback(() => (onBlur as () => {})(), [onBlur]);

  const selectedValues = Array.isArray(value)
    ? options.filter((opt) => value.includes(opt.value))
    : options.find((opt) => opt.value === value);

  return (
    <Container className={className} label={label} meta={meta} name={name}>
      <Select
        closeMenuOnSelect={!isMulti}
        components={selectComponents}
        hideSelectedOptions={false}
        isMulti={isMulti}
        onBlur={onSelectBlur}
        onChange={onOptionChange}
        options={options}
        value={selectedValues || null}
        isDisabled={isDisabled}
        placeholder={placeholder ? placeholder : 'Select'}
        inputId={name}
        {...rest}
      />
    </Container>
  );
};

export default Selector;
