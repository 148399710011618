import { EditorInput, FormActions, Input } from 'components';
import VisualTagInputs from 'containers/Common/VisualTagInputs';
import { GalleryEditFormFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container } from './styles';
import { Props } from './types';

const GalleryEditForm: FC<Props> = ({
  editId,
  handleSubmit,
  invalid,
  isPristine,
  loading,
  onDelete,
  reset,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <Field
      component={Input}
      label="Title of your gallery entry"
      name={GalleryEditFormFieldNames.title}
      placeholder=""
      type="text"
      validate={required}
    />
    <Field
      component={EditorInput}
      label="Any commentary you'd like to share"
      name={GalleryEditFormFieldNames.context}
      placeholder=""
      validate={required}
    />
    <VisualTagInputs />
    <FormActions
      invalid={invalid}
      loading={loading}
      onDelete={onDelete}
      pristine={isPristine}
      reset={editId ? reset : undefined}
      submitFailed={submitFailed}
    />
  </Container>
);

export default connect(GalleryEditForm);
