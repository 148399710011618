import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
  closeModal,
  openConfirmation,
  openPremiumInterruption,
  openReport,
  openSignUp,
} from './actions';
import { ModalAction, ModalState, ModalType } from './types';

export const INITIAL_STATE: ModalState = {
  current: null,
};

const reducer: Reducer<ModalState, ModalAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(closeModal):
      return {
        current: null,
      };
    case getType(openConfirmation):
      return {
        ...state,
        current: ModalType.CONFIRMATION,
        data: action.payload,
      };
    case getType(openPremiumInterruption):
      return {
        ...state,
        current: ModalType.PREMIUM_INTERRUPTION,
      };
    case getType(openReport):
      return {
        ...state,
        current: ModalType.REPORT,
        data: action.payload,
      };
    case getType(openSignUp):
      return {
        ...state,
        current: ModalType.SIGN_UP,
      };
    default:
      return state;
  }
};

export default reducer;
