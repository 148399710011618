import styled from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  overflow: hidden;
  padding: 2.75rem 1.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: start;

  ${from.mobileXL`
    align-items: center;
    border-radius: 0.25rem;
    padding: 3.75rem 4.625rem;
    text-align: center;
  `};

  ${from.laptop`
    padding: 3.75rem 10.625rem;
  `};
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1.4;
  position: relative;
  white-space: pre-line;
`;

export const Subtitle = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.grey500};
  line-height: 1.4;
  position: relative;
`;

export const CTALeftImage = styled.img`
  bottom: -2rem;
  height: 50%;
  left: -5rem;
  position: absolute;

  ${from.mobileXL`
    height: 108%;
  `};
`;

export const CTARightImage = styled.img`
  height: 45%;
  position: absolute;
  right: -1rem;
  top: -1.5rem;

  ${from.mobileXL`
    height: 115%;
    top: -3.5rem;
  `};
`;
