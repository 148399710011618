import BaseCard from 'components/BaseCard';
import CardContent from 'components/CardContent';
import { Statuses } from 'model';
import React, { FC, memo } from 'react';
import { formatElapsedTime } from 'utils/dates';
import Info from './Info';
import Statistics from './Statistics';
import { Props } from './types';

const Card: FC<Props> = ({
  authorAvatar,
  authorName,
  authorType,
  lastCommentDate,
  tags,
  thumbnail,
  srcSet,
  title,
  comments,
  datapoints,
  statuses,
  views,
}) => (
  <BaseCard
    isRecap={false}
    outline={!!statuses && statuses.includes(Statuses.EXPIRES_SOON)}
    tags={tags}
    title={title}
    thumbnail={thumbnail}
    srcSet={srcSet}
    statuses={statuses}
  >
    <CardContent title={title} tags={tags}>
      {typeof authorName !== 'undefined' && (
        <Info
          authorAvatar={authorAvatar || undefined}
          authorName={authorName}
          lastCommentDate={
            typeof lastCommentDate === 'string'
              ? formatElapsedTime(lastCommentDate)
              : 'none yet'
          }
          authorType={authorType}
        />
      )}
      {typeof comments !== 'undefined' &&
        typeof datapoints !== 'undefined' &&
        typeof views !== 'undefined' && (
          <Statistics
            comments={comments}
            datapoints={datapoints}
            views={views}
          />
        )}
    </CardContent>
  </BaseCard>
);

export default memo(Card);
