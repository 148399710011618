import { LiveEventsIcon } from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${from.laptop`
    display: grid;
    grid-template-columns: 19.75rem auto;
    grid-template-rows: auto;
    column-gap: 3.75rem;
  `}
`;

export const EventsIcon = styled(LiveEventsIcon)`
  max-height: 100%;
  width: 80%;
  margin: 2rem 0 3rem;
  ${from.mobileXL`
  width: 55%;
  `}
  ${from.tablet`
    margin: 2.5rem 0 2rem;
  `}

  ${from.laptop`
    width: 100%;
    height: auto;
    margin: 4.7rem 0 3rem;
  `}
`;

export const InfoContainer = styled.div`
  color: ${({ theme }) => theme.colors.black};
  max-width: 33.4rem;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
`;

export const Title = styled.h1`
  margin-top: 0.875rem;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin: 0 0 ${({ theme }) => theme.spacing.lg};
`;

export const EventDetailsCard = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.25rem;
  flex-direction: column;
  padding: 1.5rem;
`;

export const CardInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
  justify-content: space-between;
  flex-direction: column;
  > :last-child {
    margin-top: 1rem;
    width: 100%;
  }

  ${from.mobileXL`
  align-items: flex-start;
`}

  ${from.tablet`
  flex-direction: row;
  >:last-child{
    margin-top: 0;
    width: auto;
  }
`}
`;

export const CardTitle = styled(Subtitle)``;

export const CardDate = styled.p`
  font-size: 1.1rem;
  margin: 0.5rem 0.5rem 0 0;

  span {
    :first-child {
      line-height: 22px;
    }
    :last-child {
      display: inline-block;
    }
    :not(:last-child) {
      margin-right: ${({ theme }) => theme.spacing.sm};
    }
  }
  strong {
    font-weight: ${({ theme }) => theme.weights.bold};
  }
`;

export const CardAction = styled.div`
  > :first-child {
    height: 2.25rem;
  }
`;

export const ReminderText = styled.p`
  margin-top: ${({ theme }) => theme.spacing.lg};
  font-size: 1.25rem;
  line-height: 1.875rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
`;

export const ReminderSetText = styled.p`
  margin-top: ${({ theme }) => theme.spacing.lg};
  font-size: 1rem;
  line-height: 1.875rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.accent};
`;

export const ActionZone = styled.div`
  display: flex;
  flex-direction: column;
  > :first-child {
    margin-bottom: 1rem;
  }

  ${from.mobile`
   flex-direction: row; 
   > :first-child {
    margin-bottom: 0;
    margin-right: 1.25rem;
    }
  `}
`;
