import { DeepReadonly } from 'utility-types';
import { Plan } from '.';

export const getPlanMonthlyPrice = (plan: DeepReadonly<Plan>) => {
  const amount = plan.amount / 100;

  if (plan.interval === 'month') {
    return amount;
  }

  return amount / 12;
};
