import { User } from 'model';
import { DeepReadonly } from 'utility-types';

export function getUserType(
  user: DeepReadonly<User>,
): 'swd' | 'premium' | 'default' {
  if (user.swdTeam) {
    return 'swd';
  }
  if (user.isPremium || user.isPremiumGroupMember || user.isPremiumGroupOwner) {
    return 'premium';
  }
  return 'default';
}
