import DefaultAvatar from 'components/Avatar';
import DefaultButton from 'components/Button';
import { FireIcon as DefaultFireIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { RANKING_STYLES } from './constants';
import { $HeaderProps } from './types';

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 2.25rem 1rem 0.75rem 1rem;
  border-radius: 8px;
  overflow: hidden;
  border: 1px 0 0 0;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  box-shadow: 0 2px 15px -3px ${({ theme }) => addAlpha(theme.colors.grey500, 0.2)};

  ${from.tablet`
    max-width: 18.875rem;
  `}
`;

export const Position = styled.span`
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.875rem;
  opacity: 0.5;
`;

export const Ordinal = styled.span`
  font-size: 1.5rem;
`;

export const Header = styled.div<$HeaderProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 0.5rem 1rem;
  gap: 0.625rem;
  flex: 1 0 0;
  justify-content: flex-end;

  ${({ theme, $position }) => css`
    background: ${theme.colors[RANKING_STYLES[$position]?.background]};
    color: ${theme.colors[RANKING_STYLES[$position]?.color]};
    opacity: ${RANKING_STYLES[$position]?.opacity ?? 1};
  `};
`;

export const Member = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const Summary = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: flex-end;
`;

export const Avatar = styled(DefaultAvatar)`
  border: 0px;
  height: 5rem;
  width: 5rem;
  margin: 0;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.125rem;
`;

export const Social = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
`;

export const FireButton = styled(DefaultButton)`
  padding: 2px 2px 2px 4px;
`;

export const FireIcon = styled(DefaultFireIcon)`
  width: 1rem;
  height: 1rem;
`;

export const Followers = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.grey400};
`;

export const Counters = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Counter = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Divider = styled.hr`
  width: 1px;
  height: auto;
  margin-block: 0;
  border: 0;
  background: ${({ theme }) => theme.colors.greyBlue};
`;

export const Count = styled.span`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.black};
`;

export const Type = styled.span`
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.grey400};
  text-transform: uppercase;
`;

export const Button = styled(DefaultButton)``;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;

  ${Button} {
    width: 100%;
    padding: 0.625rem 0.5rem;
  }
`;
