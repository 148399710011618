import { RootState } from 'redux/modules/types';
import { createSelector } from 'reselect';

const selectState = ({ router }: RootState) => router;

export const selectRouterAction = createSelector(
  selectState,
  (router) => router.action,
);

export const selectUserWentBack = createSelector(
  selectState,
  (router) =>
    // The user went back
    router.action === 'POP' &&
    // And this back action was performed immediately, instead of before a page
    // reload (in which case router.action still would be "POP")
    !!router.lastLocation,
);
