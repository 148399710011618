import splash11x from 'assets/images/invent/splash1_1x.jpg';
import splash12x from 'assets/images/invent/splash1_2x.jpg';
import splash13x from 'assets/images/invent/splash1_3x.jpg';
import splash21x from 'assets/images/invent/splash2_1x.jpg';
import splash22x from 'assets/images/invent/splash2_2x.jpg';
import splash23x from 'assets/images/invent/splash2_3x.jpg';
import splash31x from 'assets/images/invent/splash3_1x.jpg';
import splash32x from 'assets/images/invent/splash3_2x.jpg';
import splash33x from 'assets/images/invent/splash3_3x.jpg';
import user1 from 'assets/images/invent/user1.jpg';
import user2 from 'assets/images/invent/user2.jpg';
import user3 from 'assets/images/invent/user3.jpg';
import Card from 'components/Card';
import Pagination from 'components/Pagination';
import { getUserName } from 'model/User';
import React, { FC } from 'react';
import { BannerWhite, CardGrid, Container, Content } from './styles';
import { Props } from './types';

const cards = [
  {
    commentsCount: 49,
    hits: 124,
    id: 1,
    lastComment: 'today',
    tags: ['bars graph'],
    thumbnail: { file: splash11x, mediaType: 'IMAGE', thumbnail: splash11x },
    srcSet: `${splash11x} 370w, ${splash12x} 740w, ${splash13x} 1110w`,
    title: 'Let’s look at a makeover of this data',
    user: {
      avatar: user3,
      firstName: 'Mathilda',
      lastName: 'Evans',
      userType: 'default' as const,
    },
    votesCount: 452,
  },
  {
    commentsCount: 49,
    hits: 124,
    id: 2,
    lastComment: 'today',
    tags: ['bars graph'],
    thumbnail: { file: splash21x, mediaType: 'IMAGE', thumbnail: splash21x },
    srcSet: `${splash21x} 370w, ${splash22x} 740w, ${splash23x} 1110w`,
    title: 'Let’s look at a makeover of this data',
    user: {
      avatar: user2,
      firstName: 'Jafaris',
      lastName: 'Long',
      userType: 'default' as const,
    },
    votesCount: 452,
  },
  {
    commentsCount: 49,
    hits: 124,
    id: 3,
    lastComment: 'today',
    tags: ['bars graph'],
    thumbnail: { file: splash31x, mediaType: 'IMAGE', thumbnail: splash31x },
    srcSet: `${splash31x} 370w, ${splash32x} 740w, ${splash33x} 1110w`,
    title: 'Let’s look at a makeover of this data',
    user: {
      avatar: user1,
      firstName: 'Phạm',
      lastName: 'Quốc Hùng',
      userType: 'default' as const,
    },
    votesCount: 452,
  },
];

const PlaceholderSubmittedContent: FC<Props> = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <Content>
        <CardGrid>
          {cards.map(
            ({
              commentsCount,
              hits,
              id,
              lastComment,
              tags,
              thumbnail,
              title,
              user,
              votesCount,
              srcSet,
            }) => (
              <Card
                key={id}
                authorAvatar={user.avatar}
                authorType={user.userType}
                authorName={getUserName(user)}
                comments={commentsCount}
                datapoints={votesCount}
                lastCommentDate={lastComment}
                statuses={[]}
                tags={tags}
                srcSet={srcSet}
                thumbnail={thumbnail}
                title={title}
                views={hits}
              />
            ),
          )}
        </CardGrid>
        <Pagination current={1} total={6} url={''} />
      </Content>
      <BannerWhite
        title={
          'To view others’ solutions—including those from the SWD team—first take the time to practice and submit your own exercise solution!'
        }
      >
        {children}
      </BannerWhite>
    </Container>
  );
};

export default PlaceholderSubmittedContent;
