import { NoResults as BaseNoResults } from 'components';
import React, { FC, memo } from 'react';
import { Button, Description } from './styles';
import { Props } from './types';

const NoResults: FC<Props> = ({ ...rest }) => (
  <BaseNoResults {...rest}>
    <Description>Would you like to ask the SWD Community?</Description>
    <Button to="/conversations#start-conversation">Start a conversation</Button>
  </BaseNoResults>
);

export default memo(NoResults);
