import { useMediaQuery } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import Form from '../QuestionForm';
import connect from './connect';
import { handleDelete, handleSubmit } from './logic';
import { Container, Title } from './styles';
import { Props } from './types';

const Edit: FC<Props> = ({
  contentType,
  editComment,
  objectId,
  openConfirmationModal,
  path,
}) => {
  const { pathname, state = {} } = useLocation();
  const { replace } = useHistory();

  const match = matchPath<{
    commentId: string;
  }>(pathname, `${path}/comments/:commentId/edit`);

  const url = match?.url;
  const commentId = match?.params.commentId;

  const isFromTablet = useMediaQuery(from.tabletH.query);

  const onClose = useCallback(() => {
    if (url) {
      replace({
        pathname: up(url, 3),
        state: {
          ...state,
          scrollToTop: false,
        },
      });
    }
  }, [replace, state, url]);

  const onDelete = useCallback(
    handleDelete({
      commentId,
      openConfirmationModal,
    }),
    [commentId, openConfirmationModal],
  );

  const onSubmit = useCallback(
    handleSubmit({
      commentId,
      contentType,
      editComment,
      objectId,
    }),
    [commentId, contentType, editComment, objectId],
  );

  return (
    <Container
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!match}
      scroll="body"
    >
      <Title>Edit your comment</Title>
      {objectId && (
        <Form
          editId={commentId}
          id={objectId}
          onDelete={onDelete}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  );
};

export default connect(Edit);
