import { Action } from 'redux';
import { Observable, OperatorFunction } from 'rxjs';
import { filter, mapTo, mergeMap, shareReplay, take } from 'rxjs/operators';

type Waiter<T> = () => OperatorFunction<T, T>;

const createSingleActionWaiter = <InputT, ActionT extends Action<string>>(
  input$: Observable<ActionT>,
  types: readonly string[],
): Waiter<InputT> => {
  const waitedAction$ = input$.pipe(
    filter((it) => types.includes(it.type)),
    take(1),
    shareReplay(1),
  );

  // Start listening to the values immediately
  waitedAction$.toPromise().catch(() => {});

  return () => (paused$) =>
    paused$.pipe(mergeMap((val) => waitedAction$.pipe(mapTo(val))));
};

export const createActionWaiter = <InputT, ActionT extends Action<string>>(
  input$: Observable<ActionT>,
  ...types: ReadonlyArray<ReadonlyArray<string>>
): Waiter<InputT> => {
  const waiters = types.map((type) =>
    createSingleActionWaiter<InputT, ActionT>(input$, type),
  );

  return () => (paused$) => {
    let result$ = paused$;

    for (const waiter of waiters) {
      result$ = result$.pipe(waiter());
    }

    return result$;
  };
};
