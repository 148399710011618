import { Country } from 'utils/countries';
import { FormNameMap } from 'utils/forms';

export const PAYMENT_FORM_KEY = 'forms/PAYMENT';

export interface PaymentForm {
  addressLineOne: string;
  addressLineTwo: string;
  // This will not contain the card, only the errors. The card number is handled
  // by Stripe and never accessible to us.
  card: string;
  cardholderName: string;
  city: string;
  country: Country;
  zipCode: string;
  coupon: string;
}

export const PaymentFormNames: FormNameMap<PaymentForm> = {
  addressLineOne: 'addressLineOne',
  addressLineTwo: 'addressLineTwo',
  card: 'card',
  cardholderName: 'cardholderName',
  city: 'city',
  country: 'country',
  zipCode: 'zipCode',
  coupon: 'coupon',
};
