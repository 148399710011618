import AvatarEditor from 'components/AvatarEditor';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useAvatarUploader } from './logic';
import { Avatar, ChangeAvatar, Container, Loader } from './styles';
import { Props } from './types';

const AvatarInput: FC<Props> = ({
  input: { onBlur, onChange: _onChange, onFocus, value },
  onChangeLoading,
  userType,
  ...rest
}) => {
  const { progress, uploadAvatar } = useAvatarUploader({ onChange: _onChange });
  const [open, setOpen] = useState(false);

  const loading = progress < 100;

  useEffect(() => {
    if (onChangeLoading) {
      onChangeLoading(loading);
    }
  }, [loading, onChangeLoading]);

  const onChange = useCallback(
    (nextValue: string | null) => {
      if (nextValue) {
        uploadAvatar(nextValue);
        onBlur(value);
      } else {
        onBlur(null);
      }

      setOpen(false);
    },
    [onBlur, setOpen, uploadAvatar, value],
  );

  const onOpen = useCallback(() => {
    onFocus(value);
    setOpen(true);
  }, [onFocus, setOpen, value]);

  const onDiscard = useCallback(() => {
    onBlur(value);
    setOpen(false);
  }, [onBlur, setOpen, value]);

  return (
    <Container {...rest}>
      {loading ? (
        <Loader value={progress} variant="determinate" />
      ) : (
        <Avatar src={value} variant={userType} />
      )}
      <ChangeAvatar type="button" onClick={onOpen} variant="secondary">
        {value ? 'Change' : 'Add'} profile image
      </ChangeAvatar>
      <AvatarEditor
        avatar={value}
        onChange={onChange}
        onDiscard={onDiscard}
        open={open}
      />
    </Container>
  );
};

export default memo(AvatarInput);
