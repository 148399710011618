import { stringify } from 'query-string';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { copyToClipboard } from './logic';
import {
  Container,
  Content,
  ContentWrapper,
  CopyButton,
  HyperlinkIcon,
  Link,
  Row,
  ShareIcon,
  ShareIconContainer,
  TwitterIcon,
} from './styles';
import { Props } from './types';

const ShareButton: FC<Props> = ({ content, url, ...rest }) => {
  const TwitterInfo = `https://twitter.com/share?${stringify({
    url,
    text: content,
    hashtags: 'SWDCommunity',
  })}`;

  const rootRef = useRef<HTMLDivElement>(null);

  const [copyLink, setCopyLink] = useState(false);
  const toggleLabelCopyLink = useCallback(() => {
    setCopyLink(true);
    copyToClipboard(url, rootRef);
  }, [rootRef, url]);

  const labelCopyLink = copyLink
    ? 'Link copied to clipboard'
    : 'Copy link to clipboard';

  // Make the button to copy link reset after 1.5 seconds
  useEffect(() => {
    if (copyLink) {
      setTimeout(() => {
        setCopyLink(false);
      }, 1500);
    }
  }, [copyLink]);

  return (
    <Container ref={rootRef} {...rest}>
      <ShareIconContainer aria-label="Share">
        <ShareIcon />
      </ShareIconContainer>
      <ContentWrapper>
        <Content>
          <Row>
            <Link target="_blank" to={TwitterInfo}>
              <TwitterIcon />
              Share on twitter
            </Link>
          </Row>
          <Row>
            <CopyButton copyLink={copyLink} onClick={toggleLabelCopyLink}>
              <HyperlinkIcon />
              {labelCopyLink}
            </CopyButton>
          </Row>
        </Content>
      </ContentWrapper>
    </Container>
  );
};

export default memo(ShareButton);
