import { GalleryImage, Loader, PageTitle, Pagination } from 'components';
import DiscoverItem from 'containers/Common/DiscoverItem';
import { parse, stringify } from 'query-string';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Route } from 'react-router';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { randomId } from 'utils/randomId';
import connect from './connect';
import { handleAddVote, handleRemoveVote } from './logic';
import {
  ButtonPrimary,
  Container,
  Content,
  Gallery,
  Header,
  Paragraph,
  SearchOptions,
  Title,
} from './styles';
import { Props } from './types';

const DiscoverRoot: FC<Props> = ({
  addVote,
  getDiscoverItems,
  history: { push },
  items,
  loading,
  location: { search, state },
  match: { path, url },
  pageCount,
  removeVote,
}) => {
  const page = getPageFromQuery(search);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getDiscoverItems({ search });
  }, [getDiscoverItems, search]);

  const onAddVote = useCallback(handleAddVote({ addVote }), [addVote]);

  const onRemoveVote = useCallback(handleRemoveVote({ removeVote }), [
    removeVote,
  ]);

  const goBack = useCallback(() => {
    push({
      search,
      pathname: `/discover`,
      state: {
        scrollToTop: false,
      },
    });
  }, [push, search]);

  return (
    <Container>
      <PageTitle title="Discover" />
      <Header>
        <Title>discover</Title>
        <Paragraph>
          Browse visuals created by the SWD team and community. Each press of
          the SHUFFLE! button will serve up a new mix of inspiring creations to
          explore
        </Paragraph>
        <ButtonPrimary
          mode="big"
          to={{
            pathname: url,
            search: stringify({
              graphTypes: parse(search).graphTypes,
              seed: randomId(),
            }),
          }}
        >
          Shuffle!
        </ButtonPrimary>
      </Header>
      <Content ref={contentRef}>
        <SearchOptions />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Gallery>
              {items.map(({ created, id, image, isVoted, user, title }) => (
                <GalleryImage
                  avatar={user.avatar}
                  created={created}
                  id={id}
                  image={image}
                  imageAlt={title}
                  isVoted={isVoted}
                  key={id}
                  onAddVote={onAddVote}
                  onRemoveVote={onRemoveVote}
                  userType={getUserType(user)}
                  to={{
                    search,
                    pathname: `${url}/${id}`,
                    state: {
                      scrollToTop: false,
                    },
                  }}
                />
              ))}
            </Gallery>
            <Pagination
              contentRef={contentRef}
              current={Math.min(page, pageCount)}
              search={search}
              state={state}
              total={pageCount}
              url={url}
            />
          </>
        )}
      </Content>
      <Route path={`${path}/:discoverId`}>
        <DiscoverItem onClose={goBack} scroll={true} />
      </Route>
    </Container>
  );
};

export default connect(DiscoverRoot);
