import Play from 'components/icons/Play';
import Link from 'components/Link';
import Tags from 'components/Tags';
import React, { FC, memo, useMemo } from 'react';
import { formatDuration, formatElapsedTime } from 'utils/dates';
import excludeOtherTag from 'utils/excludeOtherTag';
import { isNotFalsy } from 'utils/NonFalsy';
import {
  Category,
  Container,
  Image,
  ImageContainer,
  Info,
  Overlay,
  PlayButton,
  Title,
} from './styles';
import { Props } from './types';

const Video: FC<Props> = ({
  category,
  createdAt,
  description,
  duration,
  image,
  link,
  presenterTags,
  exerciseTags,
  graphToolsTags,
  seriesTags,
  progress,
  title,
  views,
  solutionFor,
  contentType = 'videolesson',
  to,
  ...rest
}) => {
  const tags = useMemo(() => {
    return [exerciseTags, seriesTags, graphToolsTags].filter(isNotFalsy).flat();
  }, [seriesTags, exerciseTags, graphToolsTags]);

  const filteredTags = tags.filter(excludeOtherTag);

  return (
    <Container {...rest}>
      <ImageContainer percentage={progress}>
        <Image src={image} alt="" aria-hidden="true" />
        <Overlay />
        <Link to={to} aria-label={title}>
          <PlayButton
            style={{ x: '-50%', y: '-50%' }}
            whileHover={{ scale: 1.05 }}
          >
            <Play />
          </PlayButton>
        </Link>
        {category && <Category>{category}</Category>}
      </ImageContainer>
      <Title>{title}</Title>
      <Info>
        {formatDuration(duration)} · {formatElapsedTime(createdAt)}
        {presenterTags && ` · ${presenterTags.join(', ')}`}
        {views !== undefined && ` · ${views} views`}
      </Info>
      <Tags items={filteredTags} oneLine={true} />
    </Container>
  );
};

export default memo(Video);
