import BaseInput from 'components/BaseInput';
import { BaseMenu } from 'components/Select/Menu';
import styled from 'styled-components';

export const Container = styled(BaseInput)`
  position: relative;
`;

export const Menu = styled(BaseMenu)`
  padding: 0.25rem 0;
`;
