import { DiscoverSearchOptionsForm } from 'model';
import { parse, stringify } from 'query-string';
import { keysOf } from 'utils/keysOf';
import { randomId } from '../../../../../utils/randomId';
import { Props } from './types';

export const handleFormSubmitted = ({
  hash,
  pathname,
  replace,
  search,
}: {
  hash: Props['hash'];
  pathname: Props['pathname'];
  replace: Props['replace'];
  search: Props['search'];
}) => ({ directionIsAsc, ...values }: DiscoverSearchOptionsForm) => {
  const params = {
    ...(parse(search) as { page?: string; seed?: string }),
    ...values,
  };

  params.order =
    params.order === 'random' || params.order === undefined
      ? 'random'
      : `${directionIsAsc ? '' : '-'}${params.order}`;

  replace({
    hash,
    pathname,
    search: stringify(
      keysOf(params).reduce(
        (acc, key) => ({
          ...acc,
          [key]: (() => {
            // If the order is random, remove it
            if (key === 'order' && params[key].includes('random')) {
              return undefined;
            }

            // If the order isn't random, remove the seed
            if (key === 'seed' && !params.order.includes('random')) {
              return undefined;
            }

            // If the filters change, go back to the first page
            if (key === 'page') {
              return undefined;
            }

            // If the field is falsy, remove it
            return params[key] || undefined;
          })(),
        }),
        {
          seed:
            params.order === 'random' || params.order === '-random'
              ? randomId()
              : undefined,
        },
      ),
    ),
  });
};
