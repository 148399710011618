import { useMediaQuery } from '@material-ui/core';
import { Loader } from 'components';
import React, { FC, useCallback, useEffect } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import PreviewContent from './PreviewContent';
import { Modal } from './styles';
import { Props } from './types';

const Preview: FC<Props> = ({
  authUserIsSwdTeam,
  exerciseSlug,
  getExerciseResponse,
  loading,
  push,
  submission,
  submissionSlug,
  url,
}) => {
  const fullScreen = !useMediaQuery(from.tablet.query);

  const authuserSubmittedResponse = submission?.authUserSubmittedResponse;

  useEffect(() => {
    if (
      exerciseSlug &&
      submissionSlug &&
      (authuserSubmittedResponse || authUserIsSwdTeam)
    ) {
      getExerciseResponse({
        exerciseSlug,
        exerciseResponseSlug: submissionSlug,
      });
    }
  }, [
    authUserIsSwdTeam,
    exerciseSlug,
    getExerciseResponse,
    authuserSubmittedResponse,
    submissionSlug,
  ]);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url, 2),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  return (
    <Modal
      animation="fade"
      fullScreen={fullScreen}
      onClose={onClose}
      open={!!submissionSlug}
    >
      {submission && url ? (
        <PreviewContent
          authUserIsSwdTeam={authUserIsSwdTeam}
          submission={submission}
          submissionSlug={submissionSlug}
          exerciseSlug={exerciseSlug}
        />
      ) : (
        loading && <Loader />
      )}
    </Modal>
  );
};

export default connect(Preview);
