import DefaultSlider from '@material-ui/core/Slider';
import DefaultDragFiles from 'components/DragFiles';
import { AddImageIcon as DragFilesIcon } from 'components/DragFiles/styles';
import DefaultModal from 'components/Modal';
import { Content } from 'components/Modal/styles';
import styled, { css } from 'styled-components';
import { from, label } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import { randomId } from 'utils/randomId';

export const Modal = styled(DefaultModal)`
  margin: 0 auto;
  max-width: 55rem;

  .MuiPaper-root {
    padding: 1.875rem 1.875rem 1.875rem 1.875rem;
  }

  ${Content} {
    overflow: visible;
  }
`;

export const Title = styled.h3`
  font-size: 1.25rem;
  padding-bottom: ${({ theme }) => theme.spacing.xlg};
  margin: ${({ theme }) => theme.spacing.xlg} 0 0;

  ${from.tablet`
    margin: 0;
  `};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
  > :first-child {
    color: ${({ theme }) => theme.colors.accent};
    background-color: transparent;
    transition: color 0.5s ease;
      :hover:not(:disabled) {
      background-color: transparent;
      box-shadow: none;
      color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.sm};

  ${from.tablet`
    flex-direction: row;
    margin-left: auto;
    margin-top: 0;
  `};

  * + * {
    margin-top: ${({ theme }) => theme.spacing.sm};

    ${from.tablet`
      margin-left: 2.25rem;
      margin-top: 0;
    `};
  }
`;

const cropperId = randomId();
export const cropperContainerClassName = `container-${cropperId}`;
export const cropperCropAreaClassName = `cropArea-${cropperId}`;
export const cropperImageClassName = `image-${cropperId}`;

export const DropZone = styled.div<{ hasImage: boolean }>`
  align-items: center;
  border-radius: 4px;
  border: dashed 1px ${({ theme }) => theme.colors.accent};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 24rem;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  outline: none;
  position: relative;
  width: 100%;

  .${cropperContainerClassName} {
    margin: 3rem 0;
  }

  .${cropperCropAreaClassName} {
    color: rgba(255, 255, 255, 0.5);
  }

  ${({ hasImage, theme }) =>
    !hasImage &&
    css`
      cursor: pointer;

      :hover {
        background-color: ${addAlpha(theme.colors.accent, 0.1)};
        box-shadow: 0 6px 29px -10px ${addAlpha(theme.colors.grey400, 0.4)};
      }
    `};
`;

export const Tip = styled.span`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  padding: 0 ${({ theme }) => theme.spacing.md};
  text-align: center;
`;

export const Tweaks = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

export const Tweak = styled.div``;

export const TweakHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TweakLabel = styled.label`
  ${label};
  margin: 0;
`;

export const TweakValue = styled.span`
  ${label};
  color: ${({ theme }) => theme.colors.grey400};
  margin: 0;
  text-transform: initial;
`;

export const NoFiles = styled(DefaultDragFiles)`
  ${DragFilesIcon} {
    width: 4rem;
  }
`;

export const Slider = styled(DefaultSlider)`
  .MuiSlider-track {
    color: ${({ theme }) => theme.colors.accent};
    height: 3px;
  }

  .MuiSlider-rail {
    color: ${({ theme }) => theme.colors.greyBlue};
    height: 3px;
  }

  .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.white};
    border: solid 1px ${({ theme }) => theme.colors.grey300};
    box-shadow: -13px 13px 30px -23px rgba(112, 111, 111, 0.3);
    height: 20px;
    margin-top: -8px;
    width: 20px;
  }
`;
