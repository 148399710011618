import {
  Card,
  CardGrid,
  FormContainer,
  Loader,
  NoResults as DefaultNoResults,
  PageTitle,
  Pagination,
  SectionTitle,
} from 'components';
import RecapCard from 'components/RecapCard';
import AlreadySubmitted from 'containers/Common/AlreadySubmitted';
import SearchOptions from 'containers/Common/SearchOptions';
import { getUserName, isChallengeClosed } from 'model';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { formatDayAndMonthName, isExpiringSoon } from 'utils/dates';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { getChallengeResponseStatusList } from '../../ChallengeRoot/logic';
import AcceptChallengeForm from './ChallengeSubmissionForm';
import connect from './connect';
import { handleFormSubmitted } from './logic';
import NoResults from './NoResults';
import {
  Author,
  AuthorContainer,
  AuthorName,
  AuthorRow,
  Avatar,
  AvatarContainer,
  Challenge,
  ChallengeSection,
  Container,
  Content,
  Deadline,
  DeadlineDate,
  DeadlineInfoContainer,
  DeadlineText,
  DynamicSection,
  DynamicSections,
  Header,
  HeaderSection,
  Heading,
  HeadingRow,
  ImageContainer,
  ImageListViewer,
  LastSection,
  Link,
  Navigation,
  Section,
  SectionDescription,
  ShareButton,
  ShareTag,
  StatusBadge,
  SubmitButtonBottom,
  SubmitButtonTop,
  Title,
  TitleRow,
} from './styles';
import { Props } from './types';

const ChallengeDetailRoot: FC<Props> = ({
  challenge,
  createChallengeResponse,
  getResponses,
  loadingChallenge,
  loadingResponses,
  location: { hash, search, state },
  loggedIn,
  match: {
    params: { challengeSlug },
    url,
  },
  pageCount,
  pathname,
  responses,
  wentBack,
}) => {
  const page = getPageFromQuery(search);

  const submissionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getResponses({ challengeSlug, search, silent: wentBack });
  }, [challengeSlug, getResponses, search, wentBack]);

  const onFormSubmitted = useCallback(
    handleFormSubmitted({
      createChallengeResponse,
      challengeId: challenge?.id ?? '',
    }),
    [challenge, createChallengeResponse],
  );

  if (loadingChallenge && !challenge) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!challenge) {
    return null;
  }

  const closed = isChallengeClosed(challenge);
  const expiresSoon = isExpiringSoon(challenge.deadline);

  let topStatusBadge;
  if (challenge.submittedResponse) {
    topStatusBadge = <StatusBadge>Challenge accepted!</StatusBadge>;
  } else {
    if (!closed) {
      topStatusBadge = <SubmitButtonTop url={url} />;
    }
  }

  let bottomStatusBadge;
  if (!closed && !challenge.submittedResponse) {
    bottomStatusBadge = <SubmitButtonBottom url={url} />;
  }

  const currentUrl = url
    ? window.location.origin + url
    : window.location.origin + pathname;
  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${challenge.name}: `;

  return (
    <Container>
      <PageTitle title={challenge.name} />
      <Navigation />
      <HeaderSection>
        <Header>
          <TitleRow>
            <Title>{challenge.name}</Title>
            <ShareTag>
              {challenge.deadline && (
                <DeadlineInfoContainer>
                  <Deadline>
                    {closed ? (
                      <DeadlineText>
                        <span>closed · </span>Ended
                      </DeadlineText>
                    ) : expiresSoon ? (
                      <DeadlineText>
                        <span>ending soon · </span>Ends
                      </DeadlineText>
                    ) : (
                      <DeadlineText>Deadline</DeadlineText>
                    )}
                  </Deadline>
                  <DeadlineDate closed={closed}>
                    {formatDayAndMonthName(challenge.deadline)}
                  </DeadlineDate>
                </DeadlineInfoContainer>
              )}
              <ShareButton content={shareButtonMessage} url={currentUrl} />
            </ShareTag>
          </TitleRow>
          <AuthorRow>
            <AvatarContainer>
              <Avatar
                to={`/members/${challenge.user.id}`}
                src={challenge.user.avatar}
                variant={getUserType(challenge.user)}
                name={getUserName(challenge.user)}
              />
              <AuthorContainer>
                <Author>
                  Posted by{' '}
                  <AuthorName to={`/members/${challenge.user.id}`}>
                    {getUserName(challenge.user)}
                  </AuthorName>
                </Author>
              </AuthorContainer>
            </AvatarContainer>
            {topStatusBadge}
          </AuthorRow>
        </Header>
      </HeaderSection>
      <ChallengeSection>
        <Challenge>
          <ImageContainer>
            <ImageListViewer
              images={[challenge.image]}
              zoomUrl={{
                search,
                pathname: `${url}/images`,
              }}
            />
          </ImageContainer>
          <DynamicSections>
            {challenge.context && (
              <DynamicSection open={true} title="Context">
                <div
                  dangerouslySetInnerHTML={{
                    __html: challenge.context,
                  }}
                />
              </DynamicSection>
            )}
            {challenge.theChallenge && (
              <DynamicSection title="The challenge">
                <div
                  dangerouslySetInnerHTML={{
                    __html: challenge.theChallenge,
                  }}
                />
              </DynamicSection>
            )}
            {challenge.relatedResources && (
              <DynamicSection title="Related resources">
                <div
                  dangerouslySetInnerHTML={{
                    __html: challenge.relatedResources,
                  }}
                />
              </DynamicSection>
            )}
          </DynamicSections>
        </Challenge>
      </ChallengeSection>
      {challenge.submissionCount > 0 ? (
        <Section id="submissions" ref={submissionsRef}>
          <Content>
            <HeadingRow>
              <Heading>
                <SectionTitle>Challenge submissions</SectionTitle>
                <SectionDescription>
                  Browse entries from those who have participated so far this
                  month.
                </SectionDescription>
              </Heading>
              {bottomStatusBadge}
            </HeadingRow>
            <SearchOptions
              contentType="challengeresponse"
              searchPlaceholder="Search submissions to this challenge..."
            />
            {loadingResponses ? (
              <Loader />
            ) : responses.length > 0 ? (
              <>
                <CardGrid>
                  {responses.map((response) =>
                    response.link ? (
                      <Link
                        key={response.id}
                        to={response.link}
                        target="__blank"
                      >
                        <RecapCard
                          title={response.title}
                          authorAvatar={response.user.avatar}
                          authorName={getUserName(response.user)}
                          tags={response.tags}
                          description={response.description}
                          thumbnail={response.mediaList?.mainMedia}
                          authorType={getUserType(response.user)}
                        />
                      </Link>
                    ) : (
                      <Link key={response.id} to={`${url}/${response.slug}`}>
                        <Card
                          authorAvatar={response.user.avatar}
                          authorName={getUserName(response.user)}
                          comments={response.commentsCount}
                          datapoints={response.votesCount}
                          lastCommentDate={
                            response.lastComment
                              ? response.lastComment
                              : undefined
                          }
                          statuses={getChallengeResponseStatusList(response)}
                          authorType={getUserType(response.user)}
                          tags={response.tags}
                          thumbnail={response.mediaList?.mainMedia}
                          title={response.title}
                          views={response.hits}
                        />
                      </Link>
                    ),
                  )}
                </CardGrid>
                <Pagination
                  contentRef={submissionsRef}
                  current={Math.min(page, pageCount)}
                  hash={hash}
                  search={search}
                  state={state}
                  total={pageCount}
                  url={url}
                />
              </>
            ) : (
              <DefaultNoResults />
            )}
          </Content>
        </Section>
      ) : (
        <Section>
          <Content>
            <NoResults url={url} />
          </Content>
        </Section>
      )}
      {loggedIn && !closed && (
        <LastSection id="accept-challenge">
          <Content>
            {!challenge.submittedResponse ? (
              <FormContainer>
                <SectionTitle>
                  {responses.length > 0
                    ? 'Accept the challenge: submit your entry!'
                    : 'Be the first to accept the challenge: submit your entry!'}
                </SectionTitle>
                <AcceptChallengeForm onSubmit={onFormSubmitted} />
              </FormContainer>
            ) : (
              <AlreadySubmitted
                title="Thanks for submitting your entry to this challenge!"
                subtitle="Stay tuned for future challenges for more opportunities to participate."
              />
            )}
          </Content>
        </LastSection>
      )}
    </Container>
  );
};

export default connect(ChallengeDetailRoot);
