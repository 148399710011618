import { Select } from 'components';
import { Tag, VisualTagInputsFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container } from './styles';
import { Props } from './types';

const tagToOption = ({ id, name }: Tag) => ({
  label: name,
  value: id,
});

const VisualTagInputs: FC<Props> = ({
  industryTags,
  toolTags,
  topicTags,
  typeTags,
  ...rest
}) => (
  <Container {...rest}>
    <Field
      component={Select}
      isClearable={true}
      isMulti={false}
      label="Type of visual"
      name={VisualTagInputsFieldNames.graphType}
      options={typeTags.map(tagToOption)}
      validate={required}
    />
    <Field
      component={Select}
      isClearable={true}
      isMulti={true}
      label="Tools"
      name={VisualTagInputsFieldNames.tools}
      options={toolTags.map(tagToOption)}
      validate={required}
    />
    <Field
      component={Select}
      isClearable={true}
      isMulti={true}
      label="Topic"
      name={VisualTagInputsFieldNames.topic}
      options={topicTags
        .filter((tag) => tag.name.toLowerCase() !== 'recap')
        .map(tagToOption)}
      validate={required}
    />
    <Field
      component={Select}
      isClearable={true}
      isMulti={true}
      label="Department or Industry"
      name={VisualTagInputsFieldNames.industry}
      options={industryTags.map(tagToOption)}
    />
  </Container>
);

export default connect(VisualTagInputs);
