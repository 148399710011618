import { Link, Loader, Pagination } from 'components';
import React, { FC } from 'react';
import { ArrowLink, Results, ResultsHeader, ResultsTitle } from '../../styles';
import connect from './connect';
import { Gallery, Image, ImgContainer } from './styles';
import { Props } from './types';

const Visuals: FC<Props> = ({
  available,
  count,
  isDetail,
  items,
  loading,
  location: { search },
  match: { url },
  page,
  pageCount,
  setPage,
  ...rest
}) => {
  if (!available) {
    return null;
  }

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>visuals {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: visuals"
            to={{
              search,
              pathname: `${url}/visuals`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Gallery>
            {items.map(({ id, image, title }) => (
              <ImgContainer key={id}>
                <Link to={`/discover/${id}`} target="_blank">
                  <Image src={image} alt={title} />
                </Link>
              </ImgContainer>
            ))}
          </Gallery>
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Visuals);
