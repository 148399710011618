import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers, Reducer } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { addLastLocation } from 'utils/addLastLocation';
import { appReducers } from './appReducers';
import { RootAction, RootState } from './types';

const createRootReducer = (history: History): Reducer<RootState, RootAction> =>
  combineReducers({
    ...appReducers,
    form: formReducer,
    router: addLastLocation(connectRouter(history)),
  });

export default createRootReducer;
