import DefaultButton from 'components/Button';
import {
  AngleLeftIcon as DefaultAngleLeftIcon,
  AngleRightIcon as DefaultAngleRightIcon,
} from 'components/icons';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div``;

export const Content = styled.div`
  ${container};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  ${from.mobileXL`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`}
  ${from.tablet`
    padding: 0.688rem 0 0.688rem;
`}
`;

export const Button = styled(DefaultButton)`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.2125rem;
  color: ${({ theme }) => theme.colors.accent};
  background-color: transparent;
  min-height: 2.625rem;
  min-width: 2.625rem;
  border-radius: 4px;
  border: solid 0.5px ${({ theme }) => theme.colors.greyBlue};
  margin-right: 0.5rem;

  :hover:not(:disabled) {
    background-color: transparent;
    box-shadow: none;
  }
  ${from.laptop`
   min-height: auto;
   border-radius: 0;
   border: none;
   margin-right: 0;
   height: auto;
 `}
`;

export const ButtonText = styled.div`
  border-bottom: 1.5px solid transparent;
  display: none;
  text-align: center;

  :hover:not(:disabled) {
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.accent};
  }

  ${from.laptop`
    display: block;
`}
`;

export const ButtonGroup = styled.div`
  display: flex;

  > :last-child {
    margin-right: 0;
  }

  ${from.laptop`
    align-items: center;
    justify-content: center;   
`}
`;

export const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.grey300};
  width: 2px;
  height: 20px;
  margin: 0 0.4rem;
  display: none;
  flex-shrink: 0;

  ${from.laptop`
    display: block;
`}
`;

export const AngleLeftIcon = styled(DefaultAngleLeftIcon)`
  height: 1rem;
  flex-shrink: 0;
  ${from.laptop`
    height: 0.75rem;
    margin-right: ${({ theme }) => theme.spacing.sm};
    margin-bottom: 3px;
  `}
`;

export const AngleRightIcon = styled(DefaultAngleRightIcon)`
  height: 1rem;
  flex-shrink: 0;
  ${from.laptop`
    height: 0.75rem;
    margin-left: ${({ theme }) => theme.spacing.sm};
    margin-bottom: 3px;
  `}
`;
