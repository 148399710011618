import DefaultSnackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';

export const Snack = styled(DefaultSnackbar)`
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 0.25rem;
    min-width: 0;
    padding: 0.9rem 3rem;
    box-shadow: 0 2px 20px -3px ${({ theme }) => theme.colors.grey400};
    justify-content: center;
  }

  .MuiSnackbarContent-message {
    padding: 0;
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.875rem;
  font-family: 'Proxima Nova', sans-serif;
  font-weight: ${({ theme }) => theme.weights.bold};
  text-transform: uppercase;
  letter-spacing: 1px;
`;
