import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { getHomeItemsAuthenticated, getHomeItemsGuest } from './actions';
import { HomeAction, HomeState } from './types';

export const INITIAL_STATE: HomeState = {
  updatedAtTimestamp: 0,
  activeChallenge: undefined,
  activeExercise: undefined,
  conversations: [],
  feedback: [],
  openFeedback: undefined,
  visuals: [],
  users: [],
  banner: undefined,
  exerciseBank: [],
};

const reducer: Reducer<HomeState, HomeAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getHomeItemsGuest.success): {
      if (action.payload) {
        return {
          ...state,
          ...action.payload,
          updatedAtTimestamp: Date.now(),
        };
      }

      return state;
    }
    case getType(getHomeItemsAuthenticated.success): {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
