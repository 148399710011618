import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { createTransactionMiddleware } from 'utils/redux-transaction';
import {
  createRootReducer,
  epicDependencies,
  persistConfig,
  rootEpic,
} from '../modules';

const epicMiddleware = createEpicMiddleware({
  dependencies: epicDependencies,
});

export default function (history: History) {
  const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history),
  );

  const {
    transactionMiddleware,
    transactionReducer,
  } = createTransactionMiddleware(persistedReducer);

  const middlewares = applyMiddleware(
    epicMiddleware,
    routerMiddleware(history),
    transactionMiddleware, // Must always be last
  );

  const enhancer =
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools(middlewares)
      : middlewares;

  const store = createStore(transactionReducer, enhancer);
  const persistor = persistStore(store);

  const epic$ = new BehaviorSubject(rootEpic);

  /* tslint:disable:no-any */
  const hotReloadingEpic: any = (...args: any) =>
    epic$.pipe(switchMap((epic: any) => epic(...args)));
  /* tslint:enable:no-any */

  epicMiddleware.run(hotReloadingEpic);

  const hot = (module as NodeModule).hot;

  if (hot) {
    hot.accept(() => {
      const nextCreateRootReducer = require('../modules').createRootReducer;
      const nextRootEpic = require('../modules').rootEpic;
      store.replaceReducer(
        persistReducer(persistConfig, nextCreateRootReducer(history)),
      );
      epic$.next(nextRootEpic);
    });
  }

  return {
    history,
    store,
    persistor,
  };
}
