import React, { memo, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { isNotFalsy } from 'utils/NonFalsy';
import Achievements from './Achievements';
import Challenges from './Challenges';
import { useConnect } from './connect';
import Conversations from './Conversations';
import Exercises from './Exercises';
import Feedback from './Feedback';
import Liked from './Liked';
import { getFallbackUrl } from './logic';
import RecentActivity from './RecentActivity';
import {
  ActivityFilterButton,
  Container,
  Controls,
  NoContent,
  NoContentIcon,
  NoContentText,
  Tabs,
} from './styles';
import { Props } from './types';

const Contributions: React.FC<Props> = ({ user }) => {
  const { params, path, url } = useConnect();
  const contentRef = useRef<HTMLDivElement>(null);
  const isMe = !params.userSlug;
  const { challenges, exercises, feedbacks, liked, conversations } = user;
  const showRecentActivity = isMe;
  const showChallenges = !!challenges;
  const showExercises = !!exercises;
  const showConversations = !!conversations;
  const showLiked = isMe && !!liked;
  const showFeedback = !!feedbacks;
  const showAchievements = isMe;
  const noContent =
    !showChallenges &&
    !showExercises &&
    !showConversations &&
    !showLiked &&
    !showFeedback;

  const fallbackUrl = getFallbackUrl({
    path,
    recentActivity: showRecentActivity,
    challenges: showChallenges,
    liked: showLiked,
    feedbacks: showFeedback,
    exercises: showExercises,
    conversations: showConversations,
    achievements: showAchievements,
  });

  return (
    <Container>
      <Controls id="subcontent" ref={contentRef}>
        <Tabs
          variant="tertiary"
          forceDropdown={true}
          valuePrefix="Type: "
          baseUrl={url}
          items={[
            showRecentActivity && {
              label: 'Recent activity',
              url: {
                pathname: 'recent-activity',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
            showChallenges && {
              label: 'Challenges',
              url: {
                pathname: 'challenges',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
            showExercises && {
              label: 'Exercises',
              url: {
                pathname: 'exercises',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
            showFeedback && {
              label: 'Feedback requests',
              url: {
                pathname: 'feedback',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
            showConversations && {
              label: 'Conversations',
              url: {
                pathname: 'conversations',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
            showLiked && {
              label: 'Visuals I’ve liked',
              url: {
                pathname: 'liked',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
            showAchievements && {
              label: 'Achievements',
              url: {
                pathname: 'achievements',
                state: {
                  scrollToElement: '#subcontent',
                },
              },
            },
          ].filter(isNotFalsy)}
        />
        <Route path={`${path}/recent-activity`}>
          <ActivityFilterButton />
        </Route>
      </Controls>

      <Switch>
        {showRecentActivity && (
          <Route path={`${path}/recent-activity`}>
            <RecentActivity />
          </Route>
        )}

        {showChallenges && (
          <Route path={`${path}/challenges`}>
            <Challenges contentRef={contentRef} user={user} />
          </Route>
        )}

        {showExercises && (
          <Route path={`${path}/exercises`}>
            <Exercises contentRef={contentRef} user={user} />
          </Route>
        )}

        {showFeedback && (
          <Route component={Feedback} path={`${path}/feedback`}>
            <Feedback contentRef={contentRef} user={user} />
          </Route>
        )}

        {showConversations && (
          <Route path={`${path}/conversations`}>
            <Conversations contentRef={contentRef} user={user} />
          </Route>
        )}

        {showLiked && (
          <Route path={`${path}/liked`}>
            <Liked contentRef={contentRef} user={user} />
          </Route>
        )}

        {showAchievements && (
          <Route path={`${path}/achievements`}>
            <Achievements
              contentRef={contentRef}
              achievements={user.achievements}
            />
          </Route>
        )}

        {fallbackUrl && <Redirect to={fallbackUrl} exact={false} />}
      </Switch>

      {noContent && (
        <NoContent>
          <NoContentIcon />

          <NoContentText>
            This member hasn’t added any content yet.
          </NoContentText>
        </NoContent>
      )}
    </Container>
  );
};

export default memo(Contributions);
