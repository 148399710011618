import { connect } from 'react-redux';
import { addMembers } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';

const mapStateToProps = ({ auth, premium }: RootState) => {
  const user = auth.user;
  const companyName = user?.companyName;
  const numberSeats = premium.userInfo?.quantity;
  const currentSeats = premium.userInfo?.groupSubscription?.users.length;

  return {
    companyName,
    currentSeats,
    numberSeats,
  };
};

const mapDispatchToProps = {
  addMembers: addMembers.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
