import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';

import themes from 'styles/themes';
import createAdaptedView from 'utils/prosemirror-react-view-adapter';

import { applyTransactionToState, getCurrentReference } from './logic';
import Prompt from './Prompt';
import { Container } from './styles';
import type { PluginProps, Props, State } from './types';

const INITIAL_STATE: State = {
  options: [],
  selectedOptionIndex: 0,
};

const pluginKey = new PluginKey<State>('reference-selector');
export const getPluginState = (state: EditorState): State =>
  pluginKey.getState(state) ?? INITIAL_STATE;

const createSelector = ({
  getProvider,
  editorView,
}: {
  getProvider: PluginProps['getProvider'];
  editorView: EditorView;
}): FC<Props> => ({ state }) => {
  const reference = getCurrentReference(state);
  const pluginState = getPluginState(state);

  return (
    <Container>
      {reference && (
        <Prompt
          getProvider={getProvider}
          editorView={editorView}
          state={state}
          reference={reference}
          {...pluginState}
        />
      )}
    </Container>
  );
};

export const createReferenceSelectorPlugin = ({ getProvider }: PluginProps) => {
  const isReferenceSelectorOpen = (state: EditorState) =>
    !!getCurrentReference(state);

  const referenceSelectorPlugin = new Plugin<State>({
    key: pluginKey,

    state: {
      apply: applyTransactionToState,
      init: () => INITIAL_STATE,
    },
    view: (editorView) => {
      const Selector = createSelector({
        getProvider,
        editorView,
      });

      return createAdaptedView({
        editorView,
        Component: (props) => (
          <ThemeProvider theme={themes.light}>
            <Selector {...props} />
          </ThemeProvider>
        ),
        onRender: (dom) => {
          editorView.dom.parentNode?.insertBefore(dom, editorView.dom);
        },
      });
    },
  });

  return {
    isReferenceSelectorOpen,
    referenceSelectorPlugin,
  };
};
