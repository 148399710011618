import { Props } from './types';

export const handleBeginCommentIntent = ({
  beginIntent,
  url,
}: {
  beginIntent: Props['beginIntent'];
  url: Props['url'];
}) => () => {
  beginIntent({
    returnTo: `${url}#new-comment`,
  });
};
