import { ChangePasswordForm, EditProfileForm, SettingsForm } from 'model';
import insertHttp from 'utils/insertHttp';
import { useConnect } from './connect';

export const handleEditProfile = ({
  editProfile,
}: {
  editProfile: ReturnType<typeof useConnect>['editProfile'];
}) => (values: EditProfileForm) => {
  if (values.socialWeb) {
    values.socialWeb = insertHttp(values.socialWeb);
  }

  if (values.socialLinkedin) {
    values.socialLinkedin = insertHttp(values.socialLinkedin);
  }

  editProfile(values);
};

export const handleNotificationsFormSubmitted = ({
  editSettings,
}: {
  editSettings: ReturnType<typeof useConnect>['editSettings'];
}) => (settings: SettingsForm) => {
  editSettings({
    newBlog: settings.newBlog,
    newPodcast: settings.newPodcast,
    newWorkshop: settings.newWorkshop,
    newChallenge: settings.newChallenge,
    newExercise: settings.newExercise,
    newCommentsMyConversation: settings.newCommentsMyConversation,
    newCommentsMyFeedback: settings.newCommentsMyFeedback,
    newCommentsMyChallengeResponse: settings.newCommentsMyChallengeResponse,
    newCommentsMyExerciseResponse: settings.newCommentsMyExerciseResponse,
    newCommentsOnMyComment: settings.newCommentsOnMyComment,
    newMention: settings.newMention,
  });
};

export const handleChangePasswordFormSubmitted = ({
  changePassword,
}: {
  changePassword: ReturnType<typeof useConnect>['changePassword'];
}) => ({ oldPassword, password }: ChangePasswordForm) => {
  changePassword({
    oldPassword,
    newPassword: password,
  });
};
