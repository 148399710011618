import { stringify } from 'query-string';
import { merge, Subject, Subscriber, throwError } from 'rxjs';
import { ajax, AjaxRequest } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { getToken } from 'services/storage';
import { API_BASE_URL } from 'utils/config';
import { Rename } from 'utils/Rename';

export interface RequestOptions extends Rename<AjaxRequest, 'url', 'path'> {
  params?: {
    [key: string]: boolean | string | number | string[] | number[] | undefined;
  };
}

const getAuthorizationHeader = () => {
  const token = getToken();

  if (token) {
    return { Authorization: `Bearer ${token}` };
  }

  return {};
};

/*
 * If the body is FormData, don't insert the application/json header. The
 * correct multipart/form-data with the correct boundary will be created
 * by XMLHttpRequest. Otherwise, the header won't contain the boundary and
 * the server won't be able to parse the body.
 */
const getContentTypeHeader = (body: RequestOptions['body']) => {
  if (body instanceof FormData) {
    return {};
  }

  return {
    'Content-Type': 'application/json',
  };
};

export const fullRequest = <T extends object = object>({
  body,
  headers = {},
  params = {},
  path,
  ...options
}: RequestOptions) => {
  const qs = stringify(params);
  const url = `${API_BASE_URL}/${path}/${qs ? `?${qs}` : ''}`;

  return ajax({
    url,
    body,
    headers: {
      Accept: 'application/json',
      ...getContentTypeHeader(body),
      ...getAuthorizationHeader(),
      ...headers,
    },
    ...options,
  }).pipe(
    map(({ response, ...rest }) => ({
      response: response as T,
      ...rest,
    })),
  );
};

export const rawRequest = ajax;

export const rawRequestWithProgress = (
  options: Omit<AjaxRequest, 'progressSubscriber'>,
) => {
  const progressSubject = new Subject<ProgressEvent<XMLHttpRequest>>();

  return merge(
    ajax({
      ...options,
      progressSubscriber: (progressSubject as unknown) as Subscriber<
        ProgressEvent
      >,
    }),
    progressSubject,
  );
};

export const request = <T extends object = object>(options: RequestOptions) =>
  fullRequest<T>(options).pipe(
    map(({ response }) => response),
    catchError(({ response }) => throwError(response)),
  );
