import round from 'lodash/round';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Area, Point } from 'react-easy-crop/types';
import { merge } from 'rxjs';
import { useEventCallback } from 'rxjs-hooks';
import { map, mergeMap } from 'rxjs/operators';
import { fileToBase64 } from 'utils/fileToBase64';
import { Props } from './types';

export const handleRotate = ({
  setRotation,
}: {
  setRotation: Dispatch<SetStateAction<number>>;
}) => (_: React.ChangeEvent<{}>, rotation: number | number[]) => {
  if (typeof rotation === 'number') {
    setRotation(rotation);
  }
};

export const handleSave = ({
  area,
  avatar,
  onChange,
  rotation,
}: {
  area: Area | null;
  avatar: string | null;
  onChange: Props['onChange'];
  rotation: number;
}) => async () => {
  try {
    if (avatar) {
      if (area) {
        const result = await getCroppedImg(avatar, area, rotation);
        onChange(result);
      }
    } else {
      onChange(null);
    }
  } catch (e) {
    // TODO handle errors
    console.error(e);
  }
};

export const handleZoom = ({
  setZoom,
}: {
  setZoom: Dispatch<SetStateAction<number>>;
}) => (_: React.ChangeEvent<{}>, zoom: number | number[]) => {
  if (typeof zoom === 'number') {
    setZoom(zoom);
  }
};

/*
 * react-easy-crop will send us floating point values. This is bad, because we
 * want integer numbers, so we round them before saving them to the state.
 *
 * The reason we want integer numbers is because it makes pristine comparisons
 * less bug prone. For example, zooming in and zooming out with the mouse wheel
 * may result in a crop of "x: 0.005", thus the state not being pristine.
 */
const normalizeCrop = ({ x, y }: Point) => ({
  x: Math.round(x),
  y: Math.round(y),
});

export const useAvatar = ({
  initialAvatar,
}: {
  initialAvatar: Props['avatar'];
}) => {
  const [onChangeAvatar, avatar] = useEventCallback<
    File[] | Props['avatar'],
    Props['avatar'],
    [Props['avatar']]
  >(
    (event$, input$) =>
      merge(
        input$.pipe(map(([initialAvatar]) => initialAvatar)),
        event$.pipe(
          mergeMap((input) => {
            if (Array.isArray(input)) {
              if (input.length > 0) {
                return fileToBase64(input[0]);
              }

              return [null];
            }

            return [input];
          }),
        ),
      ),
    initialAvatar,
    [initialAvatar],
  ) as [(e: File[] | Props['avatar']) => void, Props['avatar']];

  return { avatar, onChangeAvatar };
};

export const useCrop = ({ avatar }: { avatar: string | null }) => {
  const initialAreaSet = useRef(false);

  const [initialArea, setInitialArea] = useState<Area | null>(null);
  const [area, _setArea] = useState<Area | null>(null);
  const [crop, _setCrop] = useState<Point>({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, _setZoom] = useState(1);

  /*
   * When we first load an image, react-easy-crop will invoke setArea with its
   * dimensions. So the true initial area is not actually "null", it's whatever
   * react-easy-crop sends us.
   *
   * We store this value in "initialArea" to be able to determine if the crop
   * state is pristine.
   *
   * We use the "initialAreaSet" reference to differentiate between the first
   * value that react-easy-crop sends us and subsequent values, result of user
   * interaction.
   */
  const setArea = useCallback(
    (input: SetStateAction<Area | null>) => {
      if (!initialAreaSet.current) {
        setInitialArea(input);
      }

      _setArea(input);
    },
    [_setArea, setInitialArea],
  );

  const setCrop = useCallback(
    (input: SetStateAction<Point>) => {
      if (typeof input === 'function') {
        _setCrop((current) => input(normalizeCrop(current)));
      } else {
        _setCrop(normalizeCrop(input));
      }
    },
    [_setCrop],
  );

  const setZoom = useCallback(
    (input: SetStateAction<number>) => {
      if (typeof input === 'function') {
        _setZoom((current) => round(input(current), 1));
      } else {
        _setZoom(round(input, 1));
      }
    },
    [_setZoom],
  );

  const resetSettings = useCallback(() => {
    setRotation(0);
    setZoom(1);
    setCrop({ x: 0, y: 0 });
  }, [setCrop, setRotation, setZoom]);

  const reset = useCallback(() => {
    _setArea(initialArea);
    resetSettings();
  }, [_setArea, initialArea, resetSettings]);

  useEffect(() => {
    initialAreaSet.current = false;
    resetSettings();
  }, [avatar, resetSettings]);

  const pristine =
    initialArea?.x === area?.x &&
    initialArea?.y === area?.y &&
    initialArea?.width === area?.width &&
    initialArea?.height === area?.height &&
    crop.x === 0 &&
    crop.y === 0 &&
    rotation === 0 &&
    zoom === 1;

  return {
    area,
    crop,
    setCrop,
    rotation,
    setRotation,
    zoom,
    setZoom,
    pristine,
    reset,
    setArea,
  };
};

const createImage = (url: string) =>
  new Promise<CanvasImageSource>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param imageSrc - Image File url
 * @param area - area Object provided by react-easy-crop
 * @param rotation - optional rotation parameter
 */
async function getCroppedImg(imageSrc: string, area: Area, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const safeArea = Math.max(image.width as number, image.height as number) * 2;

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  if (ctx) {
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - (image.width as number) * 0.5,
      safeArea / 2 - (image.height as number) * 0.5,
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = area.width;
    canvas.height = area.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + (image.width as number) * 0.5 - area.x,
      0 - safeArea / 2 + (image.height as number) * 0.5 - area.y,
    );
  }

  return canvas.toDataURL('image/jpeg');
}
