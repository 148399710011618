import ProtectedRoute from 'containers/Common/ProtectedRoute';
import { getUserName } from 'model';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch, useLocation } from 'react-router';
import AddToMyGallery from './AddToMyGallery';
import { useConnect } from './connect';
import { Props } from './types';
import UserDetail from './UserDetail';

const Users: FC<Props> = () => {
  const {
    getUser,
    params: { userSlug },
    path,
    url,
    user,
  } = useConnect();

  const name = user && userSlug ? getUserName(user) : 'My profile';

  const location = useLocation();

  useEffect(() => {
    getUser({ slug: userSlug ?? 'me' });
  }, [getUser, userSlug]);

  useEffect(() => {
    if (user && !userSlug && location.pathname === '/members/me/gallery') {
      getUser({ slug: 'me' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <BreadcrumbsItem to={url}>{name}</BreadcrumbsItem>
      <Switch>
        {!userSlug && (
          <ProtectedRoute path={`${path}/gallery/add`}>
            <AddToMyGallery />
          </ProtectedRoute>
        )}
        <Route path={path}>
          <UserDetail />
        </Route>
      </Switch>
    </>
  );
};

export default Users;
