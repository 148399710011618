import { SWDLogoIcon } from 'components/icons';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  ${container};
  color: ${({ theme }) => theme.colors.black};
  padding: 0.688rem ${({ theme }) => theme.wrapperPaddingH.mobile} 0;

  ${from.mobileXL`
    padding: 0.688rem ${({ theme }) => theme.wrapperPaddingH.desktop} 0;
  `};
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
`;

export const Content = styled.div`
  ${container};
  padding: 1rem 0 6rem;

  ${from.mobileXL`
    padding: 1rem 0 6rem;
  `};
`;

export const Controls = styled.div`
  display: block;
  margin-bottom: 2rem;

  ${from.tablet`
    display: flex;
    align-items: center;
    margin-bottom: 3.75rem;
  `};
`;

export const SWDLogo = styled(SWDLogoIcon)`
  margin-left: 0.625rem;
  padding: 0.25rem;
  width: 1.3rem;
  height: 1rem;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.basicGold};
  ${from.tablet`
    width: 2rem;
    height: 1.37rem;
    margin-top: -0.3rem;
  `}
`;
