import React, { FC, useEffect } from 'react';
import { useConnect } from './connect';
import {
  AchievementsBox,
  ActivityData,
  Container,
  Summary,
  UserProfileContent,
} from './styles';
import { Props } from './types';

const Activity: FC<Props> = ({ user }) => {
  const { getHeatMap, heatMapData } = useConnect({ user });
  const { challenges, exercises, feedbacks } = user;

  const id = user.id;
  const slug = user.slug;

  useEffect(() => {
    getHeatMap({ id, slug });
  }, [getHeatMap, id, slug]);

  return (
    <Container>
      <UserProfileContent id="user-content">
        <ActivityData>
          <Summary
            challenges={Number(challenges)}
            comments={Number(user.comments)}
            datapoints={user.votes}
            datapointsGiven={user.datapointsGiven}
            exercises={Number(exercises)}
            feedbackGiven={user.feedbacksComments}
            feedbackRequested={feedbacks}
            heatMapData={heatMapData}
            title="Summary"
          />

          {user.achievements.granted && (
            <AchievementsBox achievements={user.achievements.granted} />
          )}
        </ActivityData>
      </UserProfileContent>
    </Container>
  );
};

export default Activity;
