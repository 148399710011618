import React, { FC, useCallback, useEffect } from 'react';
import { up } from 'utils/Paths';
import BaseVideoModal from '../BaseVideoModal';
import connect from './connect';
import { requestVideo } from './logic';
import { Props } from './types';

const VideoModal: FC<Props> = ({
  match,
  history: { push },
  getVideo,
  getEvent,
  videoLoading,
  eventLoading,
  data,
  location: { pathname, search },
}) => {
  const closeVideoModal = useCallback(() => {
    match &&
      push({
        search,
        pathname: up(match.url),
        state: {
          scrollToTop: false,
        },
      });
  }, [match, push, search]);

  const videoSlug =
    pathname.includes('videos') || pathname.includes('search')
      ? match?.params.videoSlug
      : undefined;
  const eventSlug = pathname.includes('live-events')
    ? match?.params.eventSlug
    : undefined;

  useEffect(() => {
    requestVideo({
      videoSlug,
      eventSlug,
      getVideo,
      getEvent,
    });
  }, [getVideo, getEvent, pathname, eventSlug, videoSlug]);

  return (
    <BaseVideoModal
      url={match ? match.url : undefined}
      pathname={match ? match.path : undefined}
      data={data}
      loading={videoLoading || eventLoading}
      closeVideoModal={closeVideoModal}
    />
  );
};

export default connect(VideoModal);
