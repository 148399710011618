import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Marks, Outline } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Outline
        stroke="currentColor"
        d="M21 4.5l-1.812 17.209c-.1069303 1.0176555-.9647422 1.7904629-1.988 1.791H6.8c-1.02364028 0-1.88202959-.7729643-1.989-1.791L3 4.5M.5 4.5h23M7.5 4.5v-3c0-.55228475.44771525-1 1-1h7c.5522847 0 1 .44771525 1 1v3"
      />
      <Marks stroke="currentColor" d="M12 9v10.5M16.5 9L16 19.5M7.5 9L8 19.5" />
    </g>
  </svg>
));
