import { connect } from 'react-redux';
import { selectUserIsPremiumGroupOwner } from 'redux/modules/auth';
import { selectPlans } from 'redux/modules/configuration';
import {
  selectUserPremiumInfo,
  updatePlan,
  updatePlanInterval,
} from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { location: { state: routeState } }: OwnProps,
) => ({
  info: selectUserPremiumInfo(state),
  plans: selectPlans(state),
  isCurrentlyCorporate: selectUserIsPremiumGroupOwner(state),
  selectedPlanId: routeState?.selectedPlanId,
});

const mapDispatchToProps = {
  updatePlan: updatePlan.request,
  updatePlanInterval: updatePlanInterval.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
