import noop from 'lodash/noop';
import React, { FC, memo, useCallback } from 'react';
import { OrderSelect, VisuallyHiddenLabel } from './styles';
import { Props } from './types';

const SELECT_ITEMS = [
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Quarter',
    value: 'quarter',
  },
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'All',
    value: 'all',
  },
];

const PeriodSelector: FC<Props> = ({ baseUrl, push, search, value }) => {
  const filterSection = useCallback(
    (value: string) => {
      push({
        search,
        pathname: `${baseUrl}/${value}`,
        state: {
          scrollToTop: false,
        },
      });
    },
    [push, search, baseUrl],
  );

  return (
    <>
      <VisuallyHiddenLabel htmlFor="order-select">
        Time period
      </VisuallyHiddenLabel>
      <OrderSelect
        options={SELECT_ITEMS}
        input={{
          value,
          onBlur: noop,
          onChange: filterSection,
        }}
        meta={{}}
        inputId="order-select"
      />
    </>
  );
};

export default memo(PeriodSelector);
