import Images from 'containers/Common/Images';
import ProtectedRoute from 'containers/Common/ProtectedRoute';
import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import Settings from './Settings';
import { Props } from './types';
import UserDetailRoot from './UserDetailRoot';

const UserDetail: FC<Props> = () => {
  const { path } = useConnect();

  return (
    <Switch>
      <ProtectedRoute path={`${path}/settings`}>
        <Settings />
      </ProtectedRoute>
      <Route component={Images} path={`${path}/gallery/:discoverId/images`} />
      <Route component={Images} path={`${path}/liked/:discoverId/images`} />
      <Route component={UserDetailRoot} path={path} />
    </Switch>
  );
};

export default UserDetail;
