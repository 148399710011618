import {
  Card,
  CardGrid,
  ChallengeCard,
  ChallengeCardGrid,
  Countdown,
  Loader,
  NoResults,
  PageTitle,
  Pagination,
  RecapCard,
} from 'components';
import { handleNotifyClick } from 'containers/Common/AlreadySubmitted/logic';
import SearchOptions from 'containers/Common/ChallengeSearchOptions';
import parseISO from 'date-fns/parseISO';
import { getUserName, Statuses } from 'model';
import React, { FC, Fragment, useCallback, useEffect, useRef } from 'react';
import { dateHasPassed, isExpiringSoon } from 'utils/dates';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import connect from './connect';
import { getChallengeResponseStatusList } from './logic';
import {
  ArrowLink,
  Button,
  Challenge,
  ChallengeImage,
  ChallengeOverlay,
  Container,
  Content,
  CountdownContainer,
  CountdownTitle,
  Current,
  Details,
  Featured,
  FeaturedTitle,
  Fold,
  Help,
  HelpSection,
  HelpText,
  HelpTitle,
  Info,
  Label,
  LatestSubmissionsTitle,
  Link,
  Paragraph,
  Section,
  SectionReview,
  SeeDetailsLink,
  Submissions,
} from './styles';
import { Props } from './types';

const ChallengeRoot: FC<Props> = ({
  activeChallenge,
  editSettings,
  getChallenges,
  latestSubmissions,
  loading,
  location: { hash, search, state },
  match: { url },
  pageCount,
  pastChallenges,
  wentBack,
}) => {
  const page = getPageFromQuery(search);
  const pastChallengesRef = useRef<HTMLDivElement>(null);
  const title = 'challenges';
  const description = `Flex your data visualization skills by participating in the current challenge!
    Topics change monthly—browse participant creations from current or past challenges to be inspired.`;
  const comingSoon = `Next challenge coming soon!`;

  useEffect(() => {
    getChallenges({ search, silent: wentBack });
  }, [getChallenges, search, wentBack]);
  const onNotifyClick = useCallback(
    handleNotifyClick({ editSettings, variant: 'challenge' }),
    [editSettings],
  );
  const expiringSoon = isExpiringSoon(activeChallenge?.deadline);
  const submissionTitle = 'submissions';
  const submissionSubtitle = 'submission';

  return (
    <Container>
      <PageTitle title={title} />
      {loading ? (
        <>
          <Fold title={title} description={description} />
          <Loader />
        </>
      ) : activeChallenge ? (
        <>
          <Fold title={title} description={description} />
          <Current>
            <Content>
              <Challenge>
                <ChallengeImage
                  alt=""
                  aria-hidden="true"
                  src={activeChallenge.image}
                />
                <ChallengeOverlay />
                {expiringSoon ? (
                  <Label>ending soon</Label>
                ) : (
                  <Label>Open!</Label>
                )}
              </Challenge>
              <Section>
                <Featured>
                  <Info>
                    <FeaturedTitle>{activeChallenge.name}</FeaturedTitle>
                    <Paragraph>{activeChallenge.description}</Paragraph>
                    <Submissions
                      submissionTitle={`${submissionTitle} so far`}
                      submissionSubtitle={submissionSubtitle}
                      count={activeChallenge.submissionCount}
                      items={
                        activeChallenge.latestSubmissions?.map((item) => ({
                          id: item.id,
                          avatar: item.user.avatar,
                          userType: getUserType(item.user),
                          created: item.created,
                          firstName: item.user.firstName,
                          lastName: item.user.lastName,
                        })) ?? []
                      }
                    />
                  </Info>
                  <Details>
                    <CountdownTitle>Challenge ends in</CountdownTitle>
                    <CountdownContainer>
                      <Countdown to={parseISO(activeChallenge.deadline)} />
                    </CountdownContainer>
                    <SeeDetailsLink>
                      <Button
                        to={`${url}/${activeChallenge.slug}`}
                        variant="primary"
                        mode="big"
                      >
                        See challenge
                      </Button>
                    </SeeDetailsLink>
                  </Details>
                </Featured>
              </Section>
              {latestSubmissions.length > 0 && (
                <Section>
                  <LatestSubmissionsTitle>
                    Check out the latest submissions to the challenge:{' '}
                    {activeChallenge.name}
                  </LatestSubmissionsTitle>
                  <CardGrid>
                    {latestSubmissions.map((submission) => (
                      <Fragment key={submission.id}>
                        {submission.link ? (
                          <Link
                            key={submission.id}
                            to={submission.link}
                            target="__blank"
                          >
                            <RecapCard
                              title={submission.title}
                              authorAvatar={submission.user.avatar}
                              authorName={getUserName(submission.user)}
                              authorType={getUserType(submission.user)}
                              tags={submission.tags}
                              description={submission.description}
                              thumbnail={submission.mediaList?.mainMedia}
                            />
                          </Link>
                        ) : (
                          <Link
                            key={submission.id}
                            to={`${url}/${activeChallenge.slug}/${submission.slug}`}
                          >
                            <Card
                              authorAvatar={submission.user.avatar}
                              authorName={getUserName(submission.user)}
                              authorType={getUserType(submission.user)}
                              comments={submission.commentsCount}
                              datapoints={submission.votesCount}
                              lastCommentDate={
                                submission.lastComment
                                  ? submission.lastComment
                                  : undefined
                              }
                              statuses={getChallengeResponseStatusList(
                                submission,
                              )}
                              tags={submission.tags}
                              thumbnail={
                                submission.mediaList &&
                                submission.mediaList?.mainMedia
                              }
                              title={submission.title}
                              views={submission.hits}
                            />
                          </Link>
                        )}
                      </Fragment>
                    ))}
                  </CardGrid>
                  <ArrowLink
                    text="See more"
                    to={`${url}/${activeChallenge.id}#submissions`}
                  />
                </Section>
              )}
            </Content>
          </Current>
        </>
      ) : (
        <Fold title={title} description={description} label={comingSoon}>
          <Button onClick={onNotifyClick}>Notify me</Button>
        </Fold>
      )}

      <Content>
        <SectionReview ref={pastChallengesRef}>
          <LatestSubmissionsTitle>
            Review past challenges
          </LatestSubmissionsTitle>
          <SearchOptions
            contentType="challenge"
            searchPlaceholder="Search challenges..."
          />
          {loading ? (
            <Loader />
          ) : pastChallenges.length > 0 ? (
            <>
              <ChallengeCardGrid>
                {pastChallenges.map(
                  ({
                    deadline,
                    description,
                    id,
                    image,
                    latestSubmissions,
                    link,
                    name,
                    slug,
                    submissionCount,
                    submittedResponse,
                  }) => (
                    <ChallengeCard
                      description={description}
                      key={id}
                      endDate={deadline ? deadline : undefined}
                      link={link || `${url}/${slug}`}
                      linkText="See challenge"
                      thumbnail={image}
                      title={name}
                      status={[
                        ...(deadline && dateHasPassed(deadline)
                          ? [Statuses.CLOSED]
                          : []),
                        ...(deadline && isExpiringSoon(deadline)
                          ? [Statuses.EXPIRES_SOON]
                          : []),
                        ...(submittedResponse
                          ? [Statuses.ALREADY_SUBMITTED]
                          : []),
                      ]}
                    >
                      <Submissions
                        submissionTitle={submissionTitle}
                        submissionSubtitle={submissionSubtitle}
                        count={submissionCount}
                        items={
                          latestSubmissions?.map((submission) => ({
                            id: submission.id,
                            created: submission.created,
                            avatar: submission.user.avatar,
                            userType: getUserType(submission.user),
                            firstName: submission.user.firstName,
                          })) ?? []
                        }
                      />
                    </ChallengeCard>
                  ),
                )}
              </ChallengeCardGrid>
              <Pagination
                contentRef={pastChallengesRef}
                current={Math.min(page, pageCount)}
                hash={hash}
                search={search}
                state={state}
                total={pageCount}
                url={url}
              />
            </>
          ) : (
            <NoResults />
          )}
        </SectionReview>
      </Content>
      <HelpSection>
        <Help>
          <HelpTitle>what is a challenge?</HelpTitle>
          <HelpText>
            One of the best ways to learn is to do. This is a monthly challenge
            where you can practice and apply data visualization and storytelling
            skills. Think of this as a safe space to try something new: test out
            a new tool, technique, or approach. There’s no
            obligation—participate in one, a couple, or all. We encourage
            everyone to take part: all backgrounds, experience levels, and tools
            used are welcome.
          </HelpText>
          <HelpText>
            Each month has a different topic, announced at the beginning of the
            month. Participants have the given month to find data, create and
            share their visual and related commentary. The challenge closes at
            the end of the month. We frequently focus on different graph types,
            but sometimes change it up with a specific topic or approach. This
            is meant to be a fun reason to flex your skills, share your
            creations, and learn from others in a low-risk setting. Everyone can
            explore challenge submissions and lend feedback.
          </HelpText>
        </Help>
      </HelpSection>
    </Container>
  );
};

export default connect(ChallengeRoot);
