import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { EventState } from './types';

const selectState: Selector<EventState> = (state) => state.event;

export const selectEvent = createSelector(selectState, (state) => state.event);

export const selectEventLoading = createSelector(
  selectState,
  (state) => state.loading,
);
