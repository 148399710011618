import { push } from 'connected-react-router';
import { ConversationExpanded } from 'model';
import { connect } from 'react-redux';
import {
  createComment,
  selectObjectComments,
  toggleReplyFormOpen,
} from 'redux/modules/comment';
import { beginIntent } from 'redux/modules/intent';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const object = state.conversation.byId[ownProps.id] as
    | ConversationExpanded
    | undefined;
  const loggedIn = !!state.auth.user;
  const amaParticipants = object?.amaParticipants ?? [];
  return {
    loggedIn,
    amaParticipants,
    comments: selectObjectComments(state, ownProps),
    replyFormsOpen: state.comment.replyFormsOpen,
    nQuestions: object?.amaQuestions ?? 0,
    nAnswers: object?.amaAnswers ?? 0,
    closed: object?.closed,
  };
};

const mapDispatchToProps = {
  beginIntent,
  push,
  toggleReplyFormOpen,
  createComment: createComment.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
