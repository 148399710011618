import { connect } from 'react-redux';
import { addExtraSeats, selectUserPremiumInfo } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  info: selectUserPremiumInfo(state),
});

const mapDispatchToProps = {
  addExtraSeats: addExtraSeats.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
