import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';

export const Container = styled.div<{
  $left: number;
  $top: number;
  $haveOptions?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  left: ${({ $left }) => $left}px;
  min-width: 12rem;
  position: absolute;
  top: ${({ $top }) => $top}px;
  z-index: ${ZIndex.EDITOR_REFERENCE_SELECTOR};
  max-height: 16rem;
  overflow: auto;
  ${({ $haveOptions }) =>
    $haveOptions &&
    css`
      border: 1px solid #dfe6e8;
      box-shadow: 0 0.625rem 1.25rem rgba(219, 209, 189, 0.2);
    `};
`;

export const Option = styled.div<{ $selected: boolean }>`
  align-items: center;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.white};
    `};
`;

export const Image = styled.img`
  height: 1.5rem;
  width: 1.5rem;
  object-fit: cover;
  border-radius: 0.25rem;
`;

export const Label = styled.span`
  margin-left: 0.5rem;
`;
