import { useMediaQuery } from '@material-ui/core';
import { SearchMembersFieldNames } from 'model';
import React, { FC, Fragment } from 'react';
import { Field } from 'redux-form';
import { from } from 'styles/mixins';
import { randomId } from 'utils/randomId';
import connect from './connect';
import { formatActivityOrder, parseActivityOrder } from './logic';
import {
  Container,
  Fieldset,
  Label,
  Select,
  VisuallyHiddenInputRadio,
  VisuallyHiddenLegend,
} from './styles';
import {
  ActivityOrderEnum,
  ActivityOrderOption,
  InputRadioProps,
  Props,
} from './types';

const ORDERS: ActivityOrderOption[] = [
  {
    label: 'Overall activity',
    value: ActivityOrderEnum.OVERALL_ACTIVITY,
  },
  {
    label: 'Challenge streak',
    value: ActivityOrderEnum.CHALLENGE_STREAK,
  },
  {
    label: 'Comments',
    value: ActivityOrderEnum.COMMENTS,
  },
  {
    label: 'Uploads',
    value: ActivityOrderEnum.UPLOADS,
  },
  {
    label: 'Datapoints',
    value: ActivityOrderEnum.DATAPOINTS,
  },
];

const InputRadio = ({ id, input: { value, ...input } }: InputRadioProps) => {
  return <VisuallyHiddenInputRadio {...input} id={id} checked={value} />;
};

const Form: FC<Props> = ({ handleSubmit }) => {
  const isMobile = !useMediaQuery(from.tabletH.query);

  return (
    <Container onSubmit={handleSubmit}>
      <Field
        component={Select}
        name={SearchMembersFieldNames.activityOrder}
        options={ORDERS}
        tabIndex={isMobile ? 0 : -1}
      />

      <Fieldset>
        <VisuallyHiddenLegend>Sort by</VisuallyHiddenLegend>

        {ORDERS.map(({ label, value }, index) => {
          const id = `id_${randomId()}`;

          return (
            <Fragment key={index}>
              <Field
                component={InputRadio}
                name={SearchMembersFieldNames.activityOrder}
                format={formatActivityOrder(value)}
                parse={parseActivityOrder(value)}
                id={id}
              />

              <Label htmlFor={id}>{label}</Label>
            </Fragment>
          );
        })}
      </Fieldset>
    </Container>
  );
};

export default connect(Form);
