import React, { FC } from 'react';
import { Container } from './styles';
import { Props } from './types';

const Control: FC<Props> = ({
  children,
  className,
  innerProps,
  innerRef,
  isDisabled,
  isFocused,
}) => (
  <Container
    className={className}
    isFocused={isFocused}
    isDisabled={isDisabled}
    ref={innerRef}
    {...innerProps}
  >
    {children}
  </Container>
);

export default Control;
