import DefaultButton from 'components/Button';
import styled, { css } from 'styled-components';

export const Button = styled(DefaultButton)`
  width: 235px;
  margin-top: 1.375rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.white};
  padding: 0.875rem;
  box-sizing: border-box;
  text-align: center;
  transition: all 200ms ease;
  opacity: 1;

  :disabled {
    opacity: 0.5;
    cursor: default;
  }

  :hoover:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const Plan = styled.div`
  padding: 2.75rem 0rem 0rem;
  border: 1px solid ${({ theme }) => theme.colors.accent};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  margin-top: -0.0625rem;
  margin-left: -0.0625rem;
  margin-bottom: 0px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.accent};
`;

export const Container = styled.div<{ $disabled: boolean }>`
  position: relative;
  border: 2px solid ${({ theme }) => theme.colors.grey100};
  border-radius: 0.5rem;
  transition: height 0.2s ease;
  width: 17.688rem;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ $disabled, theme }) =>
    !$disabled &&
    css`
      :hover {
        border: 2px solid ${theme.colors.accent};
      }
    `}
`;

export const Title = styled.p`
  font-size: 1.75rem;
  line-height: 1.2;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.accent};
`;

export const Price = styled.span`
  font-size: 2rem;
  line-height: 1.2;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.black};
  margin: 1.25rem 0 0.5rem;
  letter-spacing: normal;
`;

export const Subtitle = styled.span`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 1.5;
  text-align: center;
  text-transform: lowercase;
  letter-spacing: normal;
`;

export const Tag = styled.span`
  position: absolute;
  top: -0.75rem;
  padding: 0.25rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.accent};
  font-size: 1.25rem;
  line-height: 1.1;
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.weights.regular};
  text-transform: initial;
  letter-spacing: normal;
`;
