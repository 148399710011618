import styled, { css } from 'styled-components';
import { Image } from '../styles';

export const Container = styled(Image)<{ active: boolean }>`
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  height: 2.75rem;
  width: 2.75rem;

  ${({ active, theme }) =>
    active &&
    css`
      border: solid 2px ${theme.colors.accent};
    `};
`;

export const ContainerVideo = styled(Image).attrs({
  as: 'video',
  controls: false,
})<{ active: boolean }>`
  box-sizing: border-box;
  cursor: pointer;
  object-fit: cover;
  height: 2.75rem;
  width: 2.75rem;

  ${({ active, theme }) =>
    active &&
    css`
      border: solid 2px ${theme.colors.accent};
    `};
`;
