import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ExerciseBankState } from './types';

const selectState: Selector<ExerciseBankState> = ({ exerciseBank }) =>
  exerciseBank;

export const selectExerciseBankItems = createSelector(
  selectState,
  ({ byId, ids }) =>
    ids
      .map((id) => byId[id])
      .filter((it): it is NonNullable<typeof it> => !!it),
);
