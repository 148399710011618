import React from 'react';
import { serialize as serializeElement } from 'react-serialize';
import { cancelSubscription } from 'redux/modules/premium';
import { serializeIntent } from 'utils/intent';
import { useConnect } from '../connect';

export const handleCancelSubscription = ({
  openConfirmation,
}: {
  openConfirmation: ReturnType<typeof useConnect>['openConfirmation'];
}) => () => {
  openConfirmation({
    onAcceptIntent: serializeIntent([cancelSubscription.request({})]),
    title: serializeElement(<span>Are you sure?</span>),
    description: serializeElement(
      <span>
        It’s not possible to switch to another subscription until you come to
        the end of your current one.
        <br />
        If you’d like, you can cancel your current subscription now so we won’t
        renew it, and then we’ll remind you when it expires so you can choose
        your new subscription.
      </span>,
    ),
    acceptButtonLabel: 'CANCEL PREMIUM',
    acceptButtonVariant: 'danger',
    cancelButtonLabel: 'NEVER MIND!',
  });
};
