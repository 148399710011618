import { Props } from './types';

export const handleReactivate = ({
  onClose,
  reactivateSubscription,
}: {
  onClose: () => void;
  reactivateSubscription: Props['reactivateSubscription'];
}) => () => {
  reactivateSubscription({});
  onClose();
};
