import { oneLine } from 'common-tags';
import { Loader, ModalRoute, PageTitle } from 'components';
import Navigation from 'components/Navigation';
import AmaSession from 'containers/Common/AmaSession';
import ConversationView from 'containers/Common/ConversationView';
import { getStatuses } from 'containers/Main/Conversations/ConversationsRoot';
import { getUserName } from 'model';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { getUserType } from 'utils/getUserType';
import { useConnect } from './connect';
import Edit from './Edit';
import { Comments, Container, Conversation } from './styles';
import { Props } from './types';

const ConversationDetail: FC<Props> = () => {
  const {
    conversation,
    getConversation,
    id,
    loading,
    path,
    tags,
    url,
    userIsAuthor,
  } = useConnect();

  useEffect(() => {
    getConversation({ id });
  }, [getConversation, id]);

  if (loading && !conversation) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!conversation) {
    return null;
  }

  return (
    <Container>
      <PageTitle title={conversation.title} />
      <BreadcrumbsItem to={url}>{conversation?.title}</BreadcrumbsItem>
      <Navigation />
      <Conversation hasImage={!!conversation.amaImage}>
        <ConversationView
          amaEndDate={conversation.amaEndDate ?? undefined}
          amaImage={conversation.amaImage ?? undefined}
          authorAvatar={conversation.user.avatar}
          authorId={conversation.user.id}
          authorType={getUserType(conversation.user)}
          authorName={getUserName(conversation.user)}
          authorSlug={conversation.user.slug}
          closed={conversation.closed}
          created={conversation.created}
          description={conversation.description}
          id={conversation.id}
          isAma={conversation.isAma}
          isMonthly={conversation.isMonthly}
          isSpotlight={conversation.isSpotlight}
          spotlightImage={conversation.spotlightImage}
          spotlightUsers={conversation.spotlightUsers}
          spotlightDate={conversation.spotlightDate}
          isReported={conversation.isReported}
          isVoted={conversation.isVoted}
          live={conversation.live}
          open={!!conversation.id}
          scroll={false}
          shareButtonMessage={oneLine`Thought this was interesting from the SWD
          community. Check it out! - ${conversation.title}: `}
          statuses={getStatuses(conversation, tags)}
          tags={conversation.tags}
          title={conversation.title}
          url={url}
          views={conversation.hits}
          votes={conversation.votesCount}
        />
      </Conversation>
      {conversation.isAma ? (
        <AmaSession
          contentType="conversation"
          id={id}
          amaAuthor={conversation.user.id}
          path={path}
          url={url}
        />
      ) : (
        <Comments
          contentType="conversation"
          spotlightAuthors={
            conversation.spotlightUsers &&
            conversation.spotlightUsers.map((item) => item.id)
          }
          id={id}
          path={path}
          url={url}
        />
      )}
      {userIsAuthor && <ModalRoute component={Edit} path={`${path}/edit`} />}
    </Container>
  );
};

export default ConversationDetail;
