import DefaultComments from 'containers/Common/Comments';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.mobile}`};

  ${from.mobileXL`
    padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.desktop}`};
  `};
`;

export const Conversation = styled.div<{ hasImage: boolean }>`
  ${container};
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  color: ${({ theme }) => theme.colors.black};

  ${({ hasImage }) =>
    !hasImage &&
    css`
      max-width: 57.5rem;
    `}
`;

export const Content = styled.div`
  display: contents;
  border-radius: 0.25rem;
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 3.75rem;
  ${from.tablet`
  display: block;
  `}
`;

export const Comments = styled(DefaultComments)`
  margin: 0 auto;
  padding-top: ${({ theme }) => theme.spacing.lg};
`;
