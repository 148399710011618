import { push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  ChallengeResponse,
  CHALLENGE_SUBMISSION_FORM_KEY,
  isRedirect,
  PaginatedResponse,
} from 'model';
import { parse } from 'query-string';
import { destroy, stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { CHALLENGE_RESPONSES_PER_PAGE } from 'utils/config';
import { _upsertChallengesById } from '../challenge';
import { addComments } from '../comment';
import { createFeedbackRequest } from '../feedback';
import { showSnackbar } from '../snackbar';
import {
  createChallengeResponse,
  deleteChallengeResponse,
  editChallengeResponse,
  getChallengeResponse,
  getChallengeResponses,
} from './actions';

export const createChallengeResponseEpic: Epic = (
  action$,
  _,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(createChallengeResponse.request)),
    mergeMap(({ payload: { challengeId, ...body } }) =>
      fullRequest<ChallengeResponse>({
        body,
        method: 'POST',
        path: `challenges/${challengeId}/responses`,
      }).pipe(
        mergeMap(({ response: challengeResponse }) => [
          createChallengeResponse.success(challengeResponse),
          push(`/challenges/${challengeId}/${challengeResponse.id}`),
          destroy(CHALLENGE_SUBMISSION_FORM_KEY()),
          _upsertChallengesById([
            {
              id: challengeId,
              submittedResponse: true,
            },
          ]),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              createChallengeResponse.failure(),
              stopSubmit(CHALLENGE_SUBMISSION_FORM_KEY(), err.response),
            ];
          }

          return [createFeedbackRequest.failure()];
        }),
      ),
    ),
  );

export const deleteChallengeResponseEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteChallengeResponse.request)),
    mergeMap(({ payload: { challengeId, responseId } }) =>
      request({
        method: 'DELETE',
        path: `challenges/${challengeId}/responses/${responseId}`,
      }).pipe(
        mergeMap(() => [
          deleteChallengeResponse.success({ id: responseId }),
          showSnackbar({
            message: 'Your submission was deleted!',
          }),
          replace(`/challenges/${challengeId}`),
        ]),
        catchError(() => [deleteChallengeResponse.failure()]),
      ),
    ),
  );

export const editChallengeResponseEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editChallengeResponse.request)),
    mergeMap(({ payload: { challengeId, responseId, ...body } }) =>
      fullRequest<ChallengeResponse>({
        body,
        method: 'PUT',
        path: `challenges/${challengeId}/responses/${responseId}`,
      }).pipe(
        mergeMap(({ response: challengeResponse }) => [
          editChallengeResponse.success(challengeResponse),
          showSnackbar({
            message: 'Challenge submission updated!',
          }),
          push(`/challenges/${challengeId}/${responseId}`),
          destroy(CHALLENGE_SUBMISSION_FORM_KEY(responseId)),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editChallengeResponse.failure(),
              stopSubmit(
                CHALLENGE_SUBMISSION_FORM_KEY(responseId),
                err.response,
              ),
            ];
          }

          return [editChallengeResponse.failure()];
        }),
      ),
    ),
  );

export const getChallengeResponseEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(getChallengeResponse.request)),
    mergeMap(({ payload: { challengeSlug, challengeResponseSlug } }) =>
      request<ChallengeResponse>({
        path: `challenges/${challengeSlug}/responses/${challengeResponseSlug}`,
      }).pipe(
        mergeMap((response) => {
          if (isRedirect(response)) {
            // We think we have slugs, but we actually have ids
            const challengeId = challengeSlug;
            const challengeResponseId = challengeResponseSlug;
            const location = state$.value.router.location;

            if (
              location.pathname ===
              `/challenges/${challengeId}/${challengeResponseId}`
            ) {
              return [
                replace({
                  ...location,
                  pathname: `/challenges/${response.mappings[challengeId]}/${response.mappings[challengeResponseId]}`,
                }),
              ];
            }

            return [];
          }

          return [
            getChallengeResponse.success(response),
            addComments(response.comments),
          ];
        }),
        catchError(() => [getChallengeResponse.failure()]),
      ),
    ),
  );

export const getChallengeResponsesEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getChallengeResponses.request)),
    mergeMap(({ payload: { challengeSlug, search } }) => {
      const query = search ? parse(search) : {};

      return request<PaginatedResponse<ChallengeResponse>>({
        path: `challenges/${challengeSlug}/responses`,
        params: {
          pageSize: CHALLENGE_RESPONSES_PER_PAGE,
          ...query,
        },
      }).pipe(
        mergeMap((response) => {
          if (isRedirect(response)) {
            // If this is a redirect, just do nothing. The exercise detail page
            // will follow the redirect of the exercise request.
            return [];
          }

          return [getChallengeResponses.success(response)];
        }),
        catchError(() => [getChallengeResponses.failure()]),
      );
    }),
  );

export default combineEpics(
  createChallengeResponseEpic,
  deleteChallengeResponseEpic,
  editChallengeResponseEpic,
  getChallengeResponseEpic,
  getChallengeResponsesEpic,
);
