import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';
import { randomId } from 'utils/randomId';

const active = `active-${randomId()}`;

const section = css`
  border-radius: 0.5rem;
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.7)};
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 1.25rem 0.5rem;
  position: relative;
`;

const sectionDisabled = css`
  border: none;
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.3)};
  cursor: default;
  pointer-events: none;
`;

const sectionAccent = css`
  background-color: ${({ theme }) => theme.colors.grey100};
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  outline: none;
`;

export const Container = styled(NavLink).attrs({
  activeClassName: active,
})<{ disabled?: boolean }>`
  ${section};
  text-decoration: none;

  &.${active} {
    ${sectionAccent};
  }

  ${({ disabled }) =>
    disabled
      ? sectionDisabled
      : css`
          :hover {
            background-color: ${({ theme }) => theme.colors.grey100};
            color: ${({ theme }) => theme.colors.accent};
          }
        `};
`;
