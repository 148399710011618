import { ModalRoute, Navigation, PageTitle } from 'components';
import Actions from 'containers/Common/PostActions';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Redirect } from 'react-router';
import { formatElapsedTime } from 'utils/dates';
import excludeOtherTag from 'utils/excludeOtherTag';
import { getUserType } from 'utils/getUserType';
import { pluralize } from 'utils/pluralize';
import { useConnect } from './connect';
import Edit from './Edit';
import {
  Avatar,
  AvatarContainer,
  Comments,
  Container,
  ContentInfo,
  Context,
  HeaderContainer,
  ImageListViewer,
  InfoFooter,
  InfoHeader,
  Post,
  PostWrapper,
  ShareButton,
  Tags,
  Title,
  UserLink,
  UserName,
} from './styles';
import { Props } from './types';

const GalleryDetail: FC<Props> = () => {
  const {
    discoverId,
    getDiscoverItem,
    item,
    path,
    search,
    url,
    userIsAuthor,
  } = useConnect();

  useEffect(() => {
    getDiscoverItem({ id: discoverId });
  }, [discoverId, getDiscoverItem]);

  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - : `;

  if (!item) {
    return null;
  }
  const totalHits = `${item.hits} ${pluralize(item.hits, 'view', 'views')}`;
  const elapsedTime = formatElapsedTime(item.created);

  if (item.url) {
    return <Redirect to={item.url} />;
  }

  const currentUrl = window.location.origin + path;

  const itemFilteredTags = item.tags?.filter(excludeOtherTag);

  return (
    <Container>
      <PageTitle title={item.title} />
      <BreadcrumbsItem to={url}>{item?.title}</BreadcrumbsItem>
      <Navigation />
      <PostWrapper>
        <Post>
          <Title>{item.title}</Title>
          <HeaderContainer>
            <InfoHeader>
              <AvatarContainer>
                <Avatar
                  to={`/members/${item.user.id}`}
                  src={item.user.avatar}
                  variant={getUserType(item.user)}
                  name={`${item.user.firstName} ${item.user.lastName}`}
                />
              </AvatarContainer>
              <UserName>
                <UserLink to={`/members/${item.user.id}`}>
                  {item.user.firstName} {item.user.lastName}
                </UserLink>{' '}
                · {elapsedTime} · {totalHits}
              </UserName>
              {itemFilteredTags && itemFilteredTags.length > 0 && (
                <Tags items={itemFilteredTags} />
              )}
            </InfoHeader>
            <ShareButton content={shareButtonMessage} url={currentUrl} />
          </HeaderContainer>
          <ContentInfo>
            <InfoFooter>
              {item.context && (
                <>
                  <strong>Commentary</strong>
                  <Context content={item.context} />
                </>
              )}
            </InfoFooter>
            {item.image && (
              <ImageListViewer
                images={[item.image]}
                zoomUrl={{
                  search,
                  pathname: `${url}/images`,
                }}
              />
            )}
          </ContentInfo>
          <Actions
            authorId={item.user.id}
            contentType="galleryentry"
            id={discoverId}
            isReported={item.isReported}
            isVoted={item.isVoted}
            url={url}
            votes={item.votesCount || 0}
          />
          {userIsAuthor && (
            <ModalRoute component={Edit} path={`${path}/edit`} />
          )}
        </Post>
      </PostWrapper>
      <Comments
        contentType="galleryentry"
        id={discoverId}
        path={path}
        url={url}
      />
    </Container>
  );
};

export default GalleryDetail;
