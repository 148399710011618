import { CaretDownIcon as DefaultCaretDownIcon } from 'components/icons';
import styled from 'styled-components';
import { menuLink } from 'styles/mixins';

export const Label = styled.span`
  ${menuLink};
`;

export const CaretDownIcon = styled(DefaultCaretDownIcon)`
  color: ${({ theme }) => theme.colors.grey400};
  width: 0.4rem;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.sm};
`;
