export function shouldCloseMenu(
  pathname: string | undefined,
  menuType: string,
) {
  if (!pathname) {
    return true;
  }
  switch (menuType) {
    case 'practice':
      switch (pathname) {
        case '/exercises':
        case '/challenges':
          return false;
        default: {
          return true;
        }
      }
    case 'profile':
      switch (pathname) {
        case '/members/me':
        case '/members/me/gallery':
        case '/activity':
        case '/members/me/settings':
          return false;
        default: {
          return true;
        }
      }
    default: {
      return true;
    }
  }
}
