import { Loader, Pagination } from 'components';
import { getStatuses } from 'containers/Main/Conversations/ConversationsRoot';
import { getUserName } from 'model';
import React, { FC } from 'react';
import { getUserType } from 'utils/getUserType';
import {
  ArrowLink,
  Link,
  Results,
  ResultsHeader,
  ResultsTitle,
} from '../../styles';
import connect from './connect';
import { Conversation, ConversationsContent } from './styles';
import { Props } from './types';

const Conversations: FC<Props> = ({
  available,
  count,
  isDetail,
  items,
  loading,
  location: { search },
  match: { url },
  page,
  pageCount,
  setPage,
  tags,
  ...rest
}) => {
  if (!available && !loading) {
    return null;
  }

  const getConversationStatuses = (index: number) =>
    getStatuses(items[index], tags);

  return (
    <Results {...rest}>
      <ResultsHeader>
        <ResultsTitle>conversations {loading ? '' : `(${count})`}</ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: conversations"
            to={{
              search,
              pathname: `${url}/conversations`,
            }}
          />
        )}
      </ResultsHeader>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ConversationsContent>
            {items.map(
              (
                {
                  created,
                  user,
                  description,
                  commentsCount,
                  hits,
                  id,
                  swdTeam,
                  tags,
                  title,
                  votesCount,
                  isAma,
                  isMonthly,
                  isSpotlight,
                  spotlightImage,
                  spotlightUsers,
                  spotlightDate,
                  amaParticipants,
                  amaStartDate,
                  amaEndDate,
                  closed,
                  live,
                  lastComments,
                  amaAnswers,
                  amaQuestions,
                },
                index,
              ) => (
                <Link key={id} to={`/conversations/${id}`} target="_blank">
                  <Conversation
                    amaAnswers={amaAnswers}
                    author={getUserName(user)}
                    authorAvatar={user.avatar!}
                    authorType={getUserType(user)}
                    commentsCount={isAma ? amaQuestions : commentsCount}
                    created={created}
                    datapoints={votesCount}
                    description={description}
                    statuses={getConversationStatuses(index)}
                    swdTeamChimedIn={swdTeam}
                    tags={tags}
                    title={title}
                    views={hits}
                    isAma={isAma}
                    isMonthly={isMonthly}
                    isSpotlight={isSpotlight}
                    spotlightImage={spotlightImage}
                    spotlightUsers={spotlightUsers}
                    spotlightDate={spotlightDate}
                    amaParticipants={amaParticipants}
                    amaStartDate={amaStartDate}
                    amaEndDate={amaEndDate}
                    closed={closed}
                    live={live}
                    lastComments={lastComments}
                    url={`/conversations/${id}`}
                  />
                </Link>
              ),
            )}
          </ConversationsContent>
          <Pagination
            current={Math.min(page, pageCount)}
            setPage={setPage}
            total={pageCount}
          />
        </>
      )}
    </Results>
  );
};

export default connect(Conversations);
