import type { Transaction } from 'prosemirror-state';

/**
 * Returns a virtual "dispatch" function that copies the steps of the dispatched
 * transaction into the given transaction. This is useful to run multiple
 * Prosemirror commands in a single transaction.
 */
export function dispatchToTransaction(tr: Transaction) {
  return function dispatch(_tr: Transaction) {
    _tr.steps.forEach((step) => {
      tr.step(step);
    });
  };
}
