import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import { useConnect } from './connect';
import ExerciseDetail from './ExerciseDetail';
import ExerciseRoot from './ExerciseRoot';
import { Props } from './types';

/*
 * ATTENTION!
 *
 * The whole Exercises container is a copy-paste from "Challenges", with some
 * minor modifications. It might be worth to identify common patterns and
 * abstract them. But I will refrain from that now, because we don't know yet
 * how "Challenges" and "Exercises" will evolve. Remember: duplication is far
 * cheaper than the wrong abstraction.
 */

const Exercises: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Exercises</BreadcrumbsItem>
      <Switch>
        <Route path={`${path}/:exerciseSlug`}>
          <ExerciseDetail />
        </Route>
        <Route component={ExerciseRoot} path={path} />
      </Switch>
    </>
  );
};

export default Exercises;
