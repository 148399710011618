import { Button as DefaultButton } from 'components';
import { LiveEventsIcon, OnDemandVideosIcon } from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const TopSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  ${from.tabletH`
    display: grid;
    align-items: center;
    grid-template-columns: 28rem auto;
    grid-template-rows: auto;
    row-gap: 0;
  `}
  ${from.laptop`
    column-gap: 8.563rem;
  `}
`;

export const Button = styled(DefaultButton)`
  margin-top: 1.5rem;
`;

export const PremiumIcon = styled(OnDemandVideosIcon)`
  max-height: 100%;
  width: 90%;
  margin-bottom: 3rem;
  margin-left: 0.5rem;
  ${from.mobileXL`
    width: 65%;
  `}
  ${from.tablet`
    max-width: 450px;
  `}
  ${from.tabletH`
    width: 80%;
    margin-left: 2rem;
  `}
  ${from.laptop`
    margin-left: 5rem;
    width: 90%;
  `}
`;

export const EventsIcon = styled(LiveEventsIcon)`
  max-height: 100%;
  width: 80%;
  margin: 2rem 0 3rem;
  ${from.mobileXL`
    width: 55%;
  `}
  ${from.tabletH`
    margin-left: 4rem;
    width: 80%;
  `}

  ${from.laptop`
    width: 75%;
    height: auto;
    margin-bottom: 3rem;
    margin-left: 7rem;
  `}
`;

export const InfoContainer = styled.div``;

export const InfoParagraph = styled.p`
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin: 0;
`;

export const InfoTitle = styled.h2`
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin: 0 0 0.5rem 0;
`;

export const Features = styled.ul`
  list-style: none;
  margin: 0.75rem 0 0 0;
  padding: 0;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 2.75rem;
  grid-row-gap: 0.5rem;
  ${from.tablet`
    grid-template-columns: repeat(2, auto);
    grid-template-rows: 1fr 1fr 1fr;
  `}
`;
