import React, { FC } from 'react';
import {
  Container,
  Description,
  Subtitle,
  Title,
  TitleContainer,
} from './styles';
import { Props } from './types';

const LandingSectionDescription: FC<Props> = ({ text, title, subtitle }) => (
  <Container>
    <TitleContainer>
      <Subtitle>{subtitle}</Subtitle>
      <Title>{title}</Title>
    </TitleContainer>
    {text && <Description>{text}</Description>}
  </Container>
);

export default LandingSectionDescription;
