import { Loader } from 'components';
import React, { FC, useEffect } from 'react';
import connect from './connect';
import { Container, Tag, Title } from './styles';
import { Props } from './types';

const FeaturedVideo: FC<Props> = ({
  getProminentVideo,
  prominentVideo,
  prominentVideoLoading,
  url,
}) => {
  useEffect(() => {
    getProminentVideo();
  }, [getProminentVideo]);

  if (prominentVideoLoading) {
    return <Loader />;
  }

  if (!prominentVideo) {
    return null;
  }

  return (
    <Container
      image={prominentVideo.thumbnail}
      to={{
        pathname: `${url}/${prominentVideo.slug}`,
        state: {
          scrollToTop: false,
        },
      }}
    >
      <Tag>Featured lesson</Tag>
      <Title>{prominentVideo.title}</Title>
    </Container>
  );
};

export default connect(FeaturedVideo);
