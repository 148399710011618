import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import { getLiveEvents } from 'redux/modules/liveEvents';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  liveEvents: state.liveEvents.events,
  loading: state.liveEvents.loading,
  isUserPremium: selectUserIsPremium(state),
});

const mapDispatchToProps = {
  getLiveEvents: getLiveEvents.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
