import { createFeedbackRequest } from 'redux/modules/feedback';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const actions = useActions({
    askFeedback: createFeedbackRequest.request,
  });

  return {
    ...actions,
  };
};
