import Breadcrumbs from 'components/Breadcrumbs';
import React, { FC } from 'react';
import {
  AngleLeftIcon,
  AngleRightIcon,
  Button,
  ButtonGroup,
  ButtonText,
  Container,
  Content,
  Separator,
} from './styles';
import { Props } from './types';

const Navigation: FC<Props> = ({
  nextHref,
  nextText,
  previousHref,
  previousText,
  ...rest
}) => (
  <Container {...rest}>
    <Content>
      <Breadcrumbs />
      <ButtonGroup>
        {previousHref && (
          <Button to={previousHref}>
            <AngleLeftIcon />
            <ButtonText>{previousText}</ButtonText>
          </Button>
        )}
        {previousHref && nextHref && <Separator />}
        {nextHref && (
          <Button to={nextHref}>
            <ButtonText>{nextText}</ButtonText>
            <AngleRightIcon />
          </Button>
        )}
      </ButtonGroup>
    </Content>
  </Container>
);

export default Navigation;
