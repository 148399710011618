import {
  BannerWhite,
  NoResults as DefaultNoResults,
  PlaceholderSubmittedContent,
} from 'components';
import ProtectedButton from 'containers/Common/ProtectedButton';
import React, { FC, memo } from 'react';
import { CTAProps, Props } from './types';

const CTA = ({ exerciseEnded, submittedResponse, url }: CTAProps) => {
  return (
    <ProtectedButton
      to={
        exerciseEnded && submittedResponse
          ? '/exercises'
          : { hash: 'submit-exercise', pathname: url }
      }
    >
      Let's Practice
    </ProtectedButton>
  );
};

const NoResults: FC<Props> = ({
  userIsPremium,
  exerciseEnded,
  submittedResponse,
  search,
  url,
}) => {
  if (search) {
    return <DefaultNoResults />;
  }

  const cta = (
    <CTA
      exerciseEnded={exerciseEnded}
      submittedResponse={submittedResponse}
      url={url}
    />
  );

  return userIsPremium ? (
    <BannerWhite title="No submissions yet. There are currently no submissions for this exercise. Be the first to submit your entry!">
      {cta}
    </BannerWhite>
  ) : (
    <PlaceholderSubmittedContent id="exercise-not-submitted">
      {cta}
    </PlaceholderSubmittedContent>
  );
};

export default memo(NoResults);
