import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { switchTheme } from './actions';
import { ThemeAction, ThemeState } from './types';

export const INITIAL_STATE: ThemeState = {
  current: 'light',
};

const reducer: Reducer<ThemeState, ThemeAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(switchTheme):
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
