import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Paper, Pencil } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="none"
    fillRule="evenodd"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    viewBox="0 0 24 24"
    {...props}
  >
    {children}
    <Paper
      d="M19.5 11.5v11a1 1 0 0 1-1 1h-17a1 1 0 0 1-1-1v-19a1 1 0 0 1 1-1h13"
      strokeWidth="1.5"
    />
    <Pencil d="M12.824 14.007l-4.949 2.12 2.121-4.95L20.674.5l2.828 2.828zM21.67 5.16l-2.83-2.82m-6.02 11.67L10 11.18" />
  </svg>
));
