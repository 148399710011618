import { Avatar as DefaultAvatar } from 'components';
import styled, { css } from 'styled-components';
import { from, visuallyHidden } from 'styles/mixins';
import DefaultMembersOrders from './MembersOrders';
import { $CellStylesProps } from './types';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
`;

export const MembersOrders = styled(DefaultMembersOrders)``;

export const TableContainer = styled.div`
  width: 100%;
  max-width: calc(100vw - 34px);
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 8px;

  ${from.tabletH`
    width: auto;
    max-width: none;
    border-radius: 0 0 8px 8px;
  `}
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead``;

export const TR = styled.tr``;

const CellStyles = css<$CellStylesProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};

  ${({ $textAlign }) =>
    $textAlign &&
    css`
      text-align: ${$textAlign};
    `}

  ${({ $hidden }) =>
    $hidden &&
    css`
      > * {
        ${visuallyHidden}
      }
    `}
`;

export const TH = styled.th`
  ${CellStyles}

  padding: 0.75rem 0.5rem;
`;

export const TD = styled.td`
  ${CellStyles}

  padding: 0.5rem 0.5rem;
`;

export const TBody = styled.tbody`
  > ${TR}:last-child > ${TD} {
    border-bottom: 0;
  }
`;

export const Position = styled.p`
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.accent};
`;

export const Avatar = styled(DefaultAvatar)`
  width: 3.25rem;
  height: 3.25rem;
  margin: 0 auto;
  border: 0px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

export const Name = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: 1.125rem;
`;

export const Details = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-size: 0.875rem;
`;
