import { IconRipple, Report, ThumbsUp } from 'components';
import React, { FC, useCallback } from 'react';
import connect from './connect';
import { handleVoteClicked } from './logic';
import {
  CommentButton,
  Container,
  EditIcon,
  EditLink,
  ReplyButton,
  ReplyIcon,
  ReportButton,
  Text,
  ThumbsUpButton,
} from './styles';
import { Props } from './types';

const PostActions: FC<Props> = ({
  addVote,
  allowEdit = true,
  beginIntent,
  contentType,
  id,
  isComment,
  isReported,
  isVoted,
  locationState,
  loggedIn,
  commentLocation,
  openReport,
  pathname,
  push,
  removeVote,
  replyOnClick,
  url,
  userIsAuthor,
  swdTeam = false,
  votes,
  ...rest
}) => {
  const onVoteClick = useCallback(
    handleVoteClicked({
      addVote,
      beginIntent,
      contentType,
      id,
      isComment,
      isVoted,
      loggedIn,
      removeVote,
      url,
    }),
    [
      addVote,
      beginIntent,
      contentType,
      id,
      isComment,
      isVoted,
      loggedIn,
      removeVote,
      url,
    ],
  );

  const onReportClick = useCallback(() => {
    openReport({
      contentType: isComment ? 'comment' : contentType,
      objectId: id,
    });
  }, [contentType, id, isComment, openReport]);

  return (
    <Container {...rest}>
      <ThumbsUpButton
        onClick={onVoteClick}
        aria-label={`Add like (${votes} likes)`}
      >
        <ThumbsUp isVoted={isVoted} />
        <Text>
          <strong>{votes}</strong>
        </Text>
      </ThumbsUpButton>
      {replyOnClick && (
        <ReplyButton onClick={replyOnClick} aria-label="Reply">
          <IconRipple show={false}>
            <ReplyIcon />
          </IconRipple>
          <Text>Reply</Text>
        </ReplyButton>
      )}
      {commentLocation && (
        <CommentButton to={commentLocation} aria-label="Comment button">
          <IconRipple show={false}>
            <ReplyIcon />
          </IconRipple>
          <Text>Comment</Text>
        </CommentButton>
      )}
      {userIsAuthor && allowEdit && (
        <EditLink
          aria-label="Edit button"
          replace={pathname === url}
          to={{
            pathname: isComment ? `${url}/comments/${id}/edit` : `${url}/edit`,
            state: {
              ...locationState,
              scrollToTop: false,
            },
          }}
        >
          <IconRipple show={false}>
            <EditIcon />
          </IconRipple>
          <Text>Edit</Text>
        </EditLink>
      )}
      {!userIsAuthor && loggedIn && !swdTeam && (
        <ReportButton
          aria-label="Report button"
          disabled={isReported}
          onClick={onReportClick}
        >
          <Report isReported={isReported} />
          <Text disabled={isReported}>
            {isReported ? 'Reported' : 'Report'}
          </Text>
        </ReportButton>
      )}
    </Container>
  );
};

export default connect(PostActions);
