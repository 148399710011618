import { connect } from 'react-redux';
import { selectUserIsPremium } from 'redux/modules/auth';
import {
  createExerciseResponse,
  getExerciseResponses,
  selectSubmissions,
} from 'redux/modules/exerciseResponse';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  exercise: state.exercise.bySlug[ownProps.match.params.exerciseSlug],
  loadingExercise: state.exercise.loading,
  loadingResponses: state.exerciseResponse.loading,
  loggedIn: !!state.auth.user,
  pageCount: state.exerciseResponse.pageCount,
  responses: selectSubmissions(state),
  wentBack: selectUserWentBack(state),
  userIsPremium: selectUserIsPremium(state),
});

const mapDispatchToProps = {
  createExerciseResponse: createExerciseResponse.request,
  getResponses: getExerciseResponses.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
