import { useMediaQuery } from '@material-ui/core';
import Form from 'containers/Main/Exercises/ExerciseDetail/ExerciseDetailRoot/ExerciseResponseForm';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import { handleDelete, handleSubmit } from './logic';
import { Container, Title } from './styles';
import { Props } from './types';

const Edit: FC<Props> = ({
  editExerciseResponse,
  exercise,
  history: { replace },
  location: { state = {} },
  match,
  openConfirmationModal,
  submission,
}) => {
  const url = match?.url;

  const isFromTablet = useMediaQuery(from.tabletH.query);

  const exerciseId = exercise?.id;
  const submissionId = submission?.id;

  const onClose = useCallback(() => {
    if (url) {
      replace({
        pathname: up(url),
        state: {
          ...state,
          scrollToTop: false,
        },
      });
    }
  }, [replace, state, url]);

  const onDelete = useCallback(
    handleDelete({
      exerciseId,
      openConfirmationModal,
      submissionId,
    }),
    [exerciseId, openConfirmationModal, submissionId],
  );

  const onSubmit = useCallback(
    handleSubmit({
      exerciseId,
      editExerciseResponse,
      submissionId,
    }),
    [exerciseId, editExerciseResponse, submissionId],
  );

  return (
    <Container
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!match}
      scroll="body"
    >
      <Title>Edit your submission</Title>
      {match && (
        <Form
          editSlug={match.params.submissionSlug}
          onDelete={onDelete}
          onSubmit={onSubmit}
          hidden={exercise ? exercise.hidden : false}
        />
      )}
    </Container>
  );
};

export default connect(Edit);
