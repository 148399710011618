import styled from 'styled-components';

export const Person = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Card1 = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Card2 = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Data = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;
