import { Pagination as DefaultPagination } from 'components';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 7.2rem;
`;

export const Pagination = styled(DefaultPagination)`
  margin-top: 3.75rem;
`;
