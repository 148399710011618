import Button from 'components/Button';
import styled from 'styled-components';

export const Container = styled(Button).attrs({
  variant: 'link',
})`
  display: inline-block;
  margin: 0;
  max-width: 10.4rem;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
