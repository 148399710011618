import { connect } from 'react-redux';
import {
  selectUserIsPremium,
  selectUserIsPremiumGroupOwner,
} from 'redux/modules/auth';
import { selectPlans } from 'redux/modules/configuration';
import {
  selectPremiumYearlySavings,
  selectUserPremiumInfo,
} from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { location: { state: routeState } }: OwnProps,
) => ({
  plans: selectPlans(state),
  yearlySavings: selectPremiumYearlySavings(state),
  info: selectUserPremiumInfo(state),
  isPremiumGroupOwner: selectUserIsPremiumGroupOwner(state),
  isPremium: selectUserIsPremium(state),
  forceCorporateTab: routeState?.forceCorporateTab,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
