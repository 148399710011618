import { push, replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import { Comment, COMMENT_FORM_KEY } from 'model';
import { destroy, reset, stopSubmit } from 'redux-form';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { up } from 'utils/Paths';
import { showSnackbar } from '../snackbar';
import {
  createComment,
  deleteComment,
  editComment,
  toggleReplyFormOpen,
} from './actions';

export const createCommentEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(createComment.request)),
    mergeMap(({ payload: { objectId, parent, ...body } }) =>
      request<Comment>({
        body: {
          objectId,
          parent,
          ...body,
        },
        path: 'comments',
        method: 'POST',
      }).pipe(
        mergeMap(({ contentType, objectId: _, ...rest }) => {
          const formActions = parent
            ? // If the comment is a child, close the inner form.
              [toggleReplyFormOpen(parent), destroy(COMMENT_FORM_KEY(parent))]
            : [reset(COMMENT_FORM_KEY(objectId))];

          return [
            createComment.success({ objectId, contentType, ...rest }),
            ...formActions,
          ];
        }),
        catchError(() => [createComment.failure()]),
      ),
    ),
  );

export const deleteCommentEpic: Epic = (action$, state$, { request }) =>
  action$.pipe(
    filter(isActionOf(deleteComment.request)),
    mergeMap(({ payload: { id } }) =>
      request({
        method: 'DELETE',
        path: `comments/${id}`,
      }).pipe(
        mergeMap(() => [
          deleteComment.success({ id }),
          showSnackbar({
            message: 'Your comment was deleted!',
          }),
          replace({
            pathname: up(state$.value.router.location.pathname, 3),
            state: {
              ...state$.value.router.location.state,
              scrollToTop: false,
            },
          }),
        ]),
        catchError(() => [deleteComment.failure()]),
      ),
    ),
  );

export const editCommentEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(editComment.request)),
    mergeMap(({ payload: { commentId, objectId, ...body } }) =>
      fullRequest<Comment>({
        body: {
          objectId,
          ...body,
        },
        method: 'PUT',
        path: `comments/${commentId}`,
      }).pipe(
        mergeMap(({ response: conversation }) => [
          editComment.success(conversation),
          showSnackbar({
            message: 'Comment updated!',
          }),
          push({
            pathname: up(state$.value.router.location.pathname, 3),
            state: {
              ...state$.value.router.location.state,
              scrollToTop: false,
            },
          }),
          destroy(COMMENT_FORM_KEY(objectId, commentId)),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.BAD_REQUEST) {
            return [
              editComment.failure(),
              stopSubmit(COMMENT_FORM_KEY(objectId, commentId), err.response),
            ];
          }

          return [editComment.failure()];
        }),
      ),
    ),
  );

export default combineEpics(
  createCommentEpic,
  deleteCommentEpic,
  editCommentEpic,
);
