import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="52"
    viewBox="0 0 51 52"
    fill="none"
    {...props}
  >
    {children}
    <rect width="49.524" height="52" x=".5" fill="#D7E4F1" rx="24.762" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.373 3.648a1.39 1.39 0 0 0-1.84 0l-4.476 3.954a1.39 1.39 0 0 1-1.052.341l-5.944-.567a1.39 1.39 0 0 0-1.489 1.082l-1.297 5.828a1.39 1.39 0 0 1-.65.895l-5.143 3.035a1.39 1.39 0 0 0-.569 1.75l2.377 5.479a1.39 1.39 0 0 1 0 1.106l-2.377 5.478a1.39 1.39 0 0 0 .569 1.75l5.143 3.035c.33.195.566.52.65.895l1.297 5.829a1.39 1.39 0 0 0 1.489 1.081l5.944-.567a1.39 1.39 0 0 1 1.052.342l4.476 3.953a1.39 1.39 0 0 0 1.84 0l4.476-3.953a1.39 1.39 0 0 1 1.052-.342l5.944.567a1.39 1.39 0 0 0 1.489-1.081l1.297-5.83c.084-.375.32-.699.65-.894l5.143-3.035a1.39 1.39 0 0 0 .569-1.75l-2.377-5.478a1.39 1.39 0 0 1 0-1.106l2.377-5.479a1.39 1.39 0 0 0-.57-1.75l-5.142-3.035a1.39 1.39 0 0 1-.65-.895l-1.297-5.828a1.39 1.39 0 0 0-1.489-1.082l-5.944.567a1.39 1.39 0 0 1-1.052-.341l-4.476-3.954ZM25.221 42.21c9.083 0 16.445-7.363 16.445-16.445S34.304 9.32 25.222 9.32c-9.083 0-16.445 7.363-16.445 16.445S16.139 42.21 25.22 42.21Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.343 39.894c7.803 0 14.128-6.326 14.128-14.129s-6.325-14.128-14.128-14.128-14.129 6.325-14.129 14.128 6.326 14.129 14.129 14.129Zm.537-22.455h5.04l-1.833 4.276-3.207-4.276Zm-3.817 4.276L20.23 17.44h5.04l-3.207 4.276Zm1.074.6 2.438-3.25 2.438 3.25h-4.876Zm7.02 0 1.778-4.15 2.478 4.15h-4.257Zm-13.458 0 2.516-4.15 1.779 4.15h-4.295Zm.038 1.219h4.674l3.834 10.971h-.02l-8.488-10.971Zm9.168 10.971 3.835-10.971h4.673l-8.487 10.971h-.02ZM22.78 23.534h5.592l-2.796 7.924-2.796-7.924Z"
      clipRule="evenodd"
    />
  </svg>
));
