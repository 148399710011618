import { connect } from 'react-redux';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => {
  return {
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps>;
