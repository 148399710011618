import Feature from 'components/Pricing/Feature';
import React, { FC } from 'react';
import ActionButton from './ActionButton';
import {
  Button,
  EventsIcon,
  Features,
  InfoContainer,
  InfoParagraph,
  InfoTitle,
  PremiumIcon,
  TopSectionContainer,
} from './styles';
import { Props } from './types';

const TopSectionPremiumUser: FC<Props> = ({
  isPremiumGroupOwner,
  corporateGroupLength,
  totalSeats,
  currentUrl,
  isLifetime,
}) => {
  return (
    <TopSectionContainer>
      {isPremiumGroupOwner ? <EventsIcon /> : <PremiumIcon />}
      <InfoContainer>
        <InfoTitle>
          {isPremiumGroupOwner
            ? 'Congrats, you now have a group account!'
            : 'Would you like to share premium with others?'}
        </InfoTitle>
        <InfoParagraph>
          {isPremiumGroupOwner
            ? 'Invite your team members so they can start enjoying the benefits of premium membership.'
            : 'Purchase multiple seats for your team or organization, then assign them to grant access to:'}
        </InfoParagraph>
        {!isPremiumGroupOwner && (
          <Features>
            <Feature>Live virtual events</Feature>
            <Feature>Office hours</Feature>
            <Feature>On-demand videos</Feature>
            <Feature>Exclusive content</Feature>
            <Feature>Early access</Feature>
            <Feature>Special discounts</Feature>
          </Features>
        )}
        {isLifetime ? (
          <Button
            to="https://www.storytellingwithdata.com/contact"
            variant="premiumSecondary"
          >
            Contact team
          </Button>
        ) : (
          <ActionButton
            isPremiumGroupOwner={isPremiumGroupOwner}
            corporateGroupLength={corporateGroupLength}
            totalSeats={totalSeats}
            currentUrl={currentUrl}
          />
        )}
      </InfoContainer>
    </TopSectionContainer>
  );
};

export default TopSectionPremiumUser;
