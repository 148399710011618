import { RootAction } from 'redux/modules/types';
import { merge, Observable, OperatorFunction } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

export const optimistic = <InputT, ResponseT, ErrorT>({
  onFailure,
  onSuccess,
  rollbackState,
  sendRequest,
  updateState,
}: {
  onFailure: (err: ErrorT) => RootAction[];
  onSuccess: (response: ResponseT) => RootAction[];
  rollbackState: (item: InputT) => RootAction[];
  sendRequest: (item: InputT) => Observable<ResponseT>;
  updateState: (item: InputT) => RootAction[];
}): OperatorFunction<InputT, RootAction> => (input$) =>
  input$.pipe(
    mergeMap((input) =>
      merge(
        updateState(input),
        sendRequest(input).pipe(
          mergeMap((response) => [...onSuccess(response)]),
          catchError((err) => [...onFailure(err), ...rollbackState(input)]),
        ),
      ),
    ),
  );
