import { EditorState } from 'components/Editor/types';
import { Media } from 'components/MediaInput';
import { VisualTagInputsFieldNames, VisualTagInputsForm } from 'model';
import { FormNameMap } from 'utils/forms';
import { UPLOAD_MEDIA_FIELD } from '../utils';

export const FEEDBACK_FORM_KEY = (editId?: string) =>
  `forms/FEEDBACK/${editId ? `edit/${editId}` : 'new'}`;

export interface FeedbackForm extends VisualTagInputsForm {
  [UPLOAD_MEDIA_FIELD]: readonly Media[];
  context: EditorState;
  addToMyGallery: boolean;
  expirationDate: Date | null;
  title: string;
}

export const FeedbackFieldNames: FormNameMap<FeedbackForm> = {
  ...VisualTagInputsFieldNames,
  [UPLOAD_MEDIA_FIELD]: UPLOAD_MEDIA_FIELD,
  addToMyGallery: 'addToMyGallery',
  context: 'context',
  expirationDate: 'expirationDate',
  title: 'title',
};
