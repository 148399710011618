import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectUserId } from 'redux/modules/auth';

export const useConnect = () => {
  const { pathname } = useLocation();

  const userId = useSelector(selectUserId);
  const loggedIn = !!userId;

  return { loggedIn, pathname };
};
