import { Loader, ModalRoute, Pagination } from 'components';
import { getStatuses } from 'containers/Main/Conversations/ConversationsRoot';
import { getUserName } from 'model';
import React, { FC, useEffect } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { Loading } from '../../styles';
import { useConnect } from './connect';
import Preview from './Preview';

import {
  Container,
  Conversation,
  Link,
  SearchOptionsConversations,
} from './styles';
import { Props } from './types';

const Conversations: FC<Props> = ({ contentRef, user }) => {
  const {
    getUserConversations,
    items,
    loading,
    pageCount,
    path,
    search,
    state,
    url,
    tags,
  } = useConnect({ user });

  const page = getPageFromQuery(search);

  const getConversationStatuses = (index: number) =>
    getStatuses(items[index], tags);

  const userSlug = user.slug;

  useEffect(() => {
    getUserConversations({ search, page, slug: userSlug });
  }, [getUserConversations, search, page, userSlug]);

  if (loading && !items.length) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  return (
    <Container>
      <SearchOptionsConversations
        contentType="conversation"
        searchPlaceholder="Search..."
      />
      {items.map(
        (
          {
            created,
            commentsCount,
            description,
            hits,
            id,
            swdTeam,
            tags,
            title,
            user,
            votesCount,
            isAma,
            isMonthly,
            isSpotlight,
            spotlightImage,
            spotlightUsers,
            spotlightDate,
            amaParticipants,
            amaStartDate,
            amaEndDate,
            closed,
            live,
            lastComments,
            amaAnswers,
            amaQuestions,
          },
          index,
        ) => (
          <Link
            key={id}
            to={{
              pathname: `${url}/${id}`,
              state: {
                scrollToTop: false,
              },
            }}
          >
            <Conversation
              amaAnswers={amaAnswers}
              author={getUserName(user)}
              authorAvatar={user.avatar!}
              authorType={getUserType(user)}
              commentsCount={isAma ? amaQuestions : commentsCount}
              datapoints={votesCount}
              description={description}
              created={created}
              statuses={getConversationStatuses(index)}
              authorIsSwdTeam={user.swdTeam}
              swdTeamChimedIn={swdTeam}
              tags={tags}
              title={title}
              views={hits}
              isAma={isAma}
              isMonthly={isMonthly}
              isSpotlight={isSpotlight}
              spotlightImage={spotlightImage}
              spotlightUsers={spotlightUsers}
              spotlightDate={spotlightDate}
              amaParticipants={amaParticipants}
              amaStartDate={amaStartDate}
              amaEndDate={amaEndDate}
              closed={closed}
              live={live}
              lastComments={lastComments}
              url={`/conversations/${id}`}
            />
          </Link>
        ),
      )}
      <Pagination
        contentRef={contentRef}
        current={Math.min(page, pageCount)}
        state={state}
        total={pageCount}
        url={url}
      />
      <ModalRoute component={Preview} path={`${path}/:id`} />
    </Container>
  );
};

export default Conversations;
