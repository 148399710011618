import React, { FC, memo } from 'react';
import { Button, SearchIcon, SearchLabel } from './styles';
import { Props } from './types';

const SearchButton: FC<Props> = () => (
  <Button to="/search">
    <SearchLabel>Search</SearchLabel>
    <SearchIcon />
  </Button>
);

export default memo(SearchButton);
