import { useRouteMatch } from 'react-router';
import { createDiscoverItem } from 'redux/modules/discover';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { url } = useRouteMatch();

  const actions = useActions({
    createDiscoverItem: createDiscoverItem.request,
  });

  return { ...actions, url };
};
