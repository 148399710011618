import { Input, Toggle } from 'components';
import { EditProfileFormNames } from 'model';
import React, { FC } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Field } from 'redux-form';
import SectionFooter from '../SectionFooter';
import connect from './connect';
import {
  BooleanInput,
  Container,
  Content,
  Info,
  Title,
  TitleSection,
} from './styles';
import { Props } from './types';

const ProfessionalInfo: FC<Props> = ({ handleSubmit, valid }) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();

  return (
    <Container onSubmit={handleSubmit}>
      <TitleSection>
        <Title>Professional info</Title>
        <Info>
          <Field
            CheckboxComponent={Toggle}
            component={BooleanInput}
            name={EditProfileFormNames.showProfessionalInfo}
            label="Show this info on my public profile"
          />
        </Info>
      </TitleSection>
      <Content>
        <Field
          component={Input}
          name={EditProfileFormNames.companyName}
          placeholder="The company where you work"
          label="COMPANY"
          type="text"
        />
        <Field
          component={Input}
          name={EditProfileFormNames.companyRole}
          placeholder="Your job title or area of responsibility"
          label="ROLE"
          type="text"
        />
      </Content>
      <SectionFooter
        baseUrl={url}
        canSubmit={valid}
        search={search}
        section="professional-info"
      />
    </Container>
  );
};

export default connect(ProfessionalInfo);
