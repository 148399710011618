import { AuthJumbotron, PageTitle } from 'components';
import SignUpFragment from 'containers/Common/SignUpFragment';
import React, { FC } from 'react';
import { Container, Content } from './styles';
import { Props } from './types';

const SignUp: FC<Props> = () => (
  <Container>
    <PageTitle title="Sign Up" />
    <AuthJumbotron>
      Enter your data to create your account and <br />
      join the Storytelling with Data community!
    </AuthJumbotron>
    <Content>
      <SignUpFragment />
    </Content>
  </Container>
);

export default SignUp;
