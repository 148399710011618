import { ScrollBox } from 'components';
import AddToCalendar from 'components/AddToCalendar';
import { getDateAndTime } from 'components/OfficeHourBook/logic';
import { BookingNotification } from 'model';
import React, { forwardRef, useCallback, useState } from 'react';
import { handleChangeReminderTime, handleSave } from './logic';
import {
  Actions,
  Avatar,
  Bottom,
  Button,
  CancelButton,
  Column,
  Container,
  Date,
  Description,
  DetailsContainer,
  Info,
  Label,
  Name,
  Option,
  Options,
  Position,
  PresenterContainer,
  PresenterInfo,
  Radio,
  Section,
  Separator,
  SetReminder,
  Title,
} from './styles';
import { Props } from './types';

const OfficeHourBooked = forwardRef<HTMLDivElement, Props>(
  (
    {
      endDate,
      loading,
      notification,
      onAddToCalendar,
      onClose,
      onCancel,
      onSaveReminder,
      presenters,
      startDate,
      ...rest
    },
    ref,
  ) => {
    const notificationSaved = notification !== BookingNotification.NONE;

    const [reminderTime, setReminderTime] = useState<BookingNotification>(
      notificationSaved ? notification : BookingNotification.ONE_DAY_BEFORE,
    );

    const pristine = notificationSaved && notification === reminderTime;

    const onChangeReminderTime = useCallback(
      handleChangeReminderTime({ setReminderTime }),
      [setReminderTime],
    );

    const onSave = useCallback(
      handleSave({
        onSaveReminder,
        reminderTime,
      }),
      [onSaveReminder, reminderTime],
    );

    const { date, time } = getDateAndTime({ endDate, startDate });

    const [isCancelConfirmation, setIsCancelConfirmation] = useState(false);

    const handleChangeToCancelConfirmation = useCallback(() => {
      setIsCancelConfirmation(true);
    }, [setIsCancelConfirmation]);

    return (
      <Container ref={ref} {...rest}>
        <ScrollBox scroll={true}>
          <Title>
            {isCancelConfirmation
              ? 'Please, confirm this action.'
              : "Great, we'll see you at the upcoming office hour!"}
          </Title>
          <Description>
            {isCancelConfirmation
              ? 'Are you sure you want to cancel this event? This action cannot be undone.'
              : 'Please ensure you have an internet connection and are able to connect via the Zoom meeting link provided in the emailed invite.'}
          </Description>
          <Info>
            <DetailsContainer>
              <Column>
                <Section>Office Hours Details</Section>
                <Date>
                  <span>
                    <strong>{date}</strong>
                  </span>
                  <Separator>|</Separator>
                  <span>{time}</span>
                </Date>
              </Column>
              <Column>
                {startDate && endDate && !isCancelConfirmation && (
                  <AddToCalendar
                    start={startDate}
                    end={endDate}
                    name={'SWD Office Hour'}
                    onAdd={onAddToCalendar}
                  />
                )}
              </Column>
            </DetailsContainer>
            {presenters ? (
              <Bottom>
                {presenters.map((presenter) => (
                  <PresenterContainer key={presenter.id}>
                    <Avatar variant="swd" src={presenter.avatar} />
                    <PresenterInfo>
                      <Name>{presenter.name}</Name>
                      <Position>SWD team</Position>
                    </PresenterInfo>
                  </PresenterContainer>
                ))}
              </Bottom>
            ) : null}
          </Info>
          {!isCancelConfirmation && (
            <>
              <SetReminder>Set reminder</SetReminder>
              <Description>
                We’ll send you an email reminder with all the information you
                need to join the data storyteller office hour.
              </Description>
              <Options>
                <Option
                  checked={reminderTime === BookingNotification.ONE_DAY_BEFORE}
                >
                  <Radio
                    checked={
                      reminderTime === BookingNotification.ONE_DAY_BEFORE
                    }
                    id="reminder-time-1-day"
                    name="reminder-time"
                    onChange={onChangeReminderTime}
                    readOnly={true}
                    value={BookingNotification.ONE_DAY_BEFORE}
                  />
                  <Label htmlFor="reminder-time-1-day">1 day before</Label>
                </Option>
                <Option
                  checked={reminderTime === BookingNotification.ONE_HOUR_BEFORE}
                >
                  <Radio
                    checked={
                      reminderTime === BookingNotification.ONE_HOUR_BEFORE
                    }
                    id="reminder-time-1-hour"
                    name="reminder-time"
                    onChange={onChangeReminderTime}
                    readOnly={true}
                    value={BookingNotification.ONE_HOUR_BEFORE}
                  />
                  <Label htmlFor="reminder-time-1-hour">1 hour before</Label>
                </Option>
              </Options>
            </>
          )}
          <Actions>
            {isCancelConfirmation ? (
              <Button disabled={loading} onClick={onCancel}>
                Yes, Cancel the event
              </Button>
            ) : (
              <Button disabled={loading || pristine} onClick={onSave}>
                {notificationSaved ? 'Update reminder' : 'Save reminder'}
              </Button>
            )}
            <Button onClick={onClose} variant="secondary">
              {notificationSaved && !isCancelConfirmation
                ? 'Close'
                : 'No, thanks'}
            </Button>
            {!isCancelConfirmation ? (
              <CancelButton
                onClick={handleChangeToCancelConfirmation}
                variant="danger"
              >
                Cancel event
              </CancelButton>
            ) : null}
          </Actions>
        </ScrollBox>
      </Container>
    );
  },
);

export default OfficeHourBooked;
