import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { selectUserId } from 'redux/modules/auth';
import { getDiscoverItem, selectDiscoverById } from 'redux/modules/discover';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { discoverId },
    path,
    url,
  } = useRouteMatch<{ discoverId: string }>();
  const { search } = useLocation();

  const userId = useSelector(selectUserId);
  const byId = useSelector(selectDiscoverById);

  const item = byId[discoverId];
  const userIsAuthor = userId === item?.user.id;

  const actions = useActions({
    getDiscoverItem: getDiscoverItem.request,
  });

  return {
    ...actions,
    discoverId,
    item,
    path,
    search,
    url,
    userId,
    userIsAuthor,
  };
};
