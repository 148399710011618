import React, { FC } from 'react';
import { pluralize } from 'utils/pluralize';
import { useCountdown } from './logic';
import {
  Container,
  Display,
  FlipCardBottom,
  FlipCardTop,
  Unit,
  Value,
} from './styles';
import { Props } from './types';

const Countdown: FC<Props> = ({ to, ...rest }) => {
  const { days, hours, minutes, seconds } = useCountdown(to);

  const showDays = days > 0;

  return (
    <Container {...rest}>
      {showDays && (
        <Display>
          <FlipCardTop />
          <FlipCardBottom />
          <Value>{days}</Value>
          <Unit>{pluralize(days, 'day', 'days')}</Unit>
        </Display>
      )}
      <Display>
        <FlipCardTop />
        <FlipCardBottom />
        <Value>{hours}</Value>
        <Unit>{pluralize(hours, 'hour', 'hours')}</Unit>
      </Display>
      <Display>
        <FlipCardTop />
        <FlipCardBottom />
        <Value>{minutes}</Value>
        <Unit>{pluralize(minutes, 'minute', 'minutes')}</Unit>
      </Display>
      {!showDays && (
        <Display>
          <FlipCardTop />
          <FlipCardBottom />
          <Value>{seconds}</Value>
          <Unit>{pluralize(seconds, 'second', 'seconds')}</Unit>
        </Display>
      )}
    </Container>
  );
};

export default Countdown;
