import { Button as DefaultButtonPrimary } from 'components';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { commonButtonStyles } from '../styles';

export const Container = styled.form`
  text-align: left;
`;

export const Row = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.primary};
  display: block;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0.57px;
  line-height: 1.23rem;
  margin-top: ${({ theme }) => theme.spacing.md};
  text-transform: uppercase;
`;

export const ButtonPrimary = styled(DefaultButtonPrimary)`
  ${commonButtonStyles};
`;
