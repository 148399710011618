import { Button } from 'components';
import { Container as LabelContainer } from 'components/BaseInput/styles';
import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  ${LabelContainer} {
    > :first-child {
      font-size: 1rem;
      line-height: 1.5rem;
      text-transform: capitalize;
      margin-left: 0;
    }
  }
`;

export const SubmitButton = styled(Button)`
  margin-right: 1.5rem;
`;
