import { serializeHTML } from 'components/Editor';
import { ExerciseResponseForm, UPLOAD_MEDIA_FIELD } from 'model';
import { SubmissionError } from 'redux-form';
import { isUploaded } from '../../../../../components/MediaInput';
import { Props } from './types';

export const getVideoSolutionUrl = (exercise: Props['exercise']) => {
  const solution = exercise?.videoSolution || exercise?.liveSolution;

  if (exercise?.liveSolution) {
    return `/live-events/${solution?.slug}`;
  }

  if (exercise?.videoSolution) {
    return `/videos/all/${solution?.slug}`;
  }

  return '#';
};

export const handleFormSubmitted = ({
  createExerciseResponse,
  exerciseId,
}: {
  createExerciseResponse: Props['createExerciseResponse'];
  exerciseId?: string;
}) => (values: ExerciseResponseForm) => {
  if (!exerciseId) {
    return;
  }

  const [mainMedia, ...extraMedia] = values[UPLOAD_MEDIA_FIELD].filter(
    isUploaded,
  );

  if (!mainMedia) {
    throw new SubmissionError({
      [UPLOAD_MEDIA_FIELD]: 'Please add at least one image!',
    });
  }

  createExerciseResponse({
    exerciseId,
    description: serializeHTML(values.description) as string,
    graphIndustry: values.industry,
    graphTools: values.tools,
    graphTopic: values.topic,
    graphTypes: values.graphType,
    mediaList: {
      mainMedia,
      extraMedia,
    },
    title: values.title,
  });
};
