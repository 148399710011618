import { editProfile } from 'redux/modules/auth';
import { openConfirmation } from 'redux/modules/modal';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const actions = useActions({
    openConfirmation,
    editProfile: editProfile.request,
  });

  return {
    ...actions,
  };
};
