import React, { FC, useCallback } from 'react';
import { handleClick } from './logic';
import { Container, DeleteBox, DeleteIcon } from './styles';
import { Props } from './types';

const Delete: FC<Props> = ({ file, isMouseInput, onDeleteFile, ...rest }) => {
  const onClick = useCallback(handleClick({ file, onDeleteFile }), [
    file,
    onDeleteFile,
  ]);

  return (
    <Container onClick={onClick} {...rest} isMouseInput={isMouseInput}>
      <DeleteBox>
        <DeleteIcon />
      </DeleteBox>
    </Container>
  );
};

export default Delete;
