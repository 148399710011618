import { AvailablePremiumSubscriptionsCard, PageTitle } from 'components';
import React, { FC, useCallback } from 'react';
import { Redirect } from 'react-router';
import { up } from 'utils/Paths';
import { useConnect } from '../connect';
import connect from './connect';
import { handleCancelSubscription } from './logic';
import {
  CloseIcon,
  CommunityTitle,
  Container,
  Content,
  Description,
  Feature,
  Features,
  GoBackButton,
  Info,
  Paragraph,
  SWDLogo,
  Title,
} from './styles';
import { Props } from './types';

const ChangeSubscription: FC<Props> = ({
  history: { push },
  info,
  isPremium,
  isPremiumGroupOwner,
  match,
  plans,
  yearlySavings,
  forceCorporateTab,
}) => {
  const url = match?.url;

  const { openConfirmation } = useConnect();

  const onCancelSubscription = useCallback(
    handleCancelSubscription({
      openConfirmation,
    }),
    [openConfirmation],
  );
  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  if (!(isPremium || isPremiumGroupOwner)) {
    return <Redirect to={`/premium`} />;
  }

  return (
    <Container>
      <PageTitle title="Search" />
      <Content>
        <GoBackButton onClick={onClose}>
          <CloseIcon />
        </GoBackButton>
        <CommunityTitle to="/">
          <SWDLogo />
        </CommunityTitle>
        <Title>Available premium subscriptions</Title>
        <Paragraph>
          We offer monthly and annual subscriptions. Get an individual
          subscription for yourself, or purchase multiple premium seats for your
          team or organization (for group subscriptions, after purchase you can
          assign and reassign individual seats as needed).
        </Paragraph>
        <AvailablePremiumSubscriptionsCard
          plans={plans.map((plan) => ({
            id: plan.id,
            period: plan.interval,
            price: plan.amount / 100,
            savings: plan.interval === 'year' ? yearlySavings : undefined,
            metadata: { disabled: plan.metadata.disabled ?? 'false' },
          }))}
          currentPlanInterval={info?.plan?.interval ?? 'lifetime'}
          isPremiumGroupOwner={isPremiumGroupOwner}
          onCancelSubscription={onCancelSubscription}
          forceCorporateTab={forceCorporateTab}
        />
      </Content>
      <Info>
        <Description>As a premium member, you will gain access to</Description>
        <Features>
          <Feature>Live virtual events</Feature>
          <Feature>Office hours</Feature>
          <Feature>On-demand videos</Feature>
          <Feature>Exclusive content</Feature>
          <Feature>Early access</Feature>
          <Feature>Special discounts</Feature>
        </Features>
      </Info>
    </Container>
  );
};

export default connect(ChangeSubscription);
