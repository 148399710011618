import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import { stringifyUrl } from 'query-string';
import { randomId } from 'utils/randomId';

export interface Event {
  name: string;
  begin: Date;
  end: Date;
}

export interface ICSConfig {
  name: string;
  events: Event[];
}

function formatDate(date: Date): string {
  // It seems weird that we convert the date from UTC to UTC, but this library
  // has a weird API: https://github.com/marnusw/date-fns-tz/issues/55
  return format(utcToZonedTime(date, 'Etc/UTC'), "yyyyMMdd'T'HHmmss");
}

export function generateGoogleCalendarLink(event: Event): string {
  return stringifyUrl({
    url: 'https://www.google.com/calendar/render',
    query: {
      action: 'TEMPLATE',
      text: event.name,
      dates: `${formatDate(event.begin)}Z/${formatDate(event.end)}Z`,
    },
  });
}

export function generateICS(config: ICSConfig): string {
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:-//storytellingwithdata.com//Storytelling With Data',
    `X-WR-CALNAME:${config.name}`,
    `NAME:${config.name}`,
    'CALSCALE:GREGORIAN',
    ...config.events
      .map((event) => [
        'BEGIN:VEVENT',
        `DTSTAMP:${formatDate(startOfDay(event.begin))}Z`,
        `UID:${randomId()}`,
        `DTSTART:${formatDate(event.begin)}Z`,
        `DTEND:${formatDate(event.end)}Z`,
        `SUMMARY:${event.name}`,
        'END:VEVENT',
      ])
      .reduce((a, b) => [...a, ...b], []),
    'END:VCALENDAR',
  ].join('\n');
}
