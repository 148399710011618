import { Navigation } from 'components';
import React, { FC, useCallback } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useConnect } from './connect';
import Form from './Form';
import { handleFormSubmitted } from './logic';
import { Container, Title } from './styles';
import { Props } from './types';

const AddToMyGallery: FC<Props> = () => {
  const { createDiscoverItem, url } = useConnect();

  const onFormSubmitted = useCallback(
    handleFormSubmitted({ createDiscoverItem }),
    [createDiscoverItem],
  );

  return (
    <Container>
      <BreadcrumbsItem to={url}>Add to my gallery</BreadcrumbsItem>
      <Navigation />
      <Title>Add image to my gallery</Title>
      <Form onSubmit={onFormSubmitted} />
    </Container>
  );
};

export default AddToMyGallery;
