import DefaultButton from 'components/Button';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  align-items: start;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.3;
  overflow: hidden;
  padding: 2.75rem 1.75rem;
  text-align: left;
  width: 100%;
  position: relative;

  ${from.mobileXL`
    align-items: center;
    line-height: normal;
    text-align: center;
    padding: 3.75rem 4.625rem;
  `};

  ${from.laptop`
    padding: 3.75rem 10.625rem;
  `};
`;

export const Title = styled.span`
  font-size: 2.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: normal;
  position: relative;
`;

export const Button = styled(DefaultButton)`
  line-height: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 1;

  ${from.tablet`
    line-height: normal;
  `};
`;

export const FeaturedContent = styled.div`
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.3;
  margin: 0.25rem 0 1.5625rem 0;
  font-size: 1.25rem;
  position: relative;
`;

export const CTALeftImage = styled.img`
  bottom: -4rem;
  height: 50%;
  left: -6rem;
  position: absolute;

  ${from.mobileXL`
    height: 80%;
  `};
`;

export const CTARightImage = styled.img`
  height: 50%;
  position: absolute;
  right: -1rem;
  top: -2.5rem;

  ${from.mobileXL`
    height: 90%;
  `};
`;
