import ProtectedButton from 'containers/Common/ProtectedButton';
import React, { FC, memo } from 'react';
import { Props } from './types';

const SubmitButton: FC<Props> = ({ loggedIn, url, ...rest }) => (
  <ProtectedButton to={{ hash: 'submit-exercise', pathname: url }} {...rest}>
    Let's practice
  </ProtectedButton>
);

export default memo(SubmitButton);
