import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Check, Hexagon } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 40 46" {...props}>
    {children}
    <g fill="currentColor" fillRule="evenodd">
      <Hexagon d="M20.81.468l18.299 10.564c.5.29.81.825.81 1.403v21.13a1.62 1.62 0 01-.81 1.403L20.81 45.532a1.62 1.62 0 01-1.62 0L.891 34.968a1.62 1.62 0 01-.81-1.403v-21.13c0-.578.31-1.113.81-1.403L19.19.468a1.62 1.62 0 011.62 0z" />
      <Check
        fillRule="nonzero"
        d="M17.83 31.627l-8.45-8.28a1.256 1.256 0 010-1.802l1.84-1.802a1.319 1.319 0 011.838 0l5.692 5.578 12.192-11.948a1.319 1.319 0 011.839 0l1.838 1.802a1.256 1.256 0 010 1.801l-14.95 14.65a1.319 1.319 0 01-1.838 0z"
      />
    </g>
  </svg>
));
