import { replace } from 'connected-react-router';
import { StatusCodes } from 'http-status-codes';
import {
  ChallengeResponse,
  Conversation,
  Discover,
  ExerciseResponse,
  FeedbackRequest,
  isRedirect,
  LaunchSoonForm,
  MonthData,
  PaginatedResponse,
  transformUser,
  User,
} from 'model';
import { parse } from 'query-string';
import { matchPath } from 'react-router-dom';
import { combineEpics } from 'redux-observable';
import { _upsertChallengeResponsesById } from 'redux/modules/challengeResponse';
import { _upsertConversationsById } from 'redux/modules/conversation';
import { _upsertDiscoverById } from 'redux/modules/discover';
import { _upsertExerciseResponsesById } from 'redux/modules/exerciseResponse';
import { _upsertFeedbackRequestsById } from 'redux/modules/feedback';
import { Epic } from 'redux/modules/types';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  CHALLENGE_RESPONSES_PER_PAGE,
  CONVERSATIONS_PER_PAGE,
  DISCOVER_PER_PAGE,
  EXERCISE_RESPONSES_PER_PAGE,
  FEEDBACK_PER_PAGE,
  USERS_PER_PAGE,
} from 'utils/config';
import {
  getHeatMap,
  getOutstandingUsers,
  getUser,
  getUserChallengeResponses,
  getUserConversations,
  getUserExerciseResponses,
  getUserFeedbackResponses,
  getUserGallery,
  getUserLiked,
  getUsers,
  notifyLaunch,
  sendActivationEmail,
} from './actions';

export const getUsersEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getUsers.request)),
    mergeMap(({ payload: { search } }) => {
      const query = search ? parse(search) : {};

      return request<PaginatedResponse<User>>({
        path: 'users',
        params: {
          ...query,
          pageSize: query.pageSize ?? USERS_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ results, ...rest }) => [
          getUsers.success({
            results: results.map(transformUser),
            ...rest,
          }),
        ]),
        catchError(() => [getUsers.failure()]),
      );
    }),
  );

export const getUserEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getUser.request)),
    mergeMap(({ payload: { slug } }) =>
      fullRequest<User>({
        path: `users/${slug}`,
      }).pipe(
        mergeMap(({ response }) => {
          if (isRedirect(response)) {
            // We think we have a slug, but we actually have an id
            const id = slug;
            const location = state$.value.router.location;

            if (matchPath(location.pathname, `/members/${id}`)) {
              return [
                replace({
                  ...location,
                  pathname: `/members/${response.mappings[id]}`,
                }),
              ];
            }

            return [];
          }

          return [getUser.success(transformUser(response))];
        }),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [getUser.failure(), replace('/404')];
          }
          return [getUser.failure()];
        }),
      ),
    ),
  );

export const getUserGalleryEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getUserGallery.request)),
    mergeMap(({ payload: { search, count, slug, page } }) =>
      fullRequest<PaginatedResponse<Discover>>({
        path: `users/${slug}/gallery`,
        params: {
          ...parse(search),
          page,
          pageSize: count ?? DISCOVER_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ response }) => [
          getUserGallery.success({ ...response, slug }),
          _upsertDiscoverById(response.results),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [
              getUserGallery.failure({ slug }),
              replace(state$.value.router.location.pathname),
            ];
          }
          return [getUserGallery.failure({ slug })];
        }),
      ),
    ),
  );

export const getUserFeedbackResponsesEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(getUserFeedbackResponses.request)),
    mergeMap(({ payload: { search, slug, page } }) =>
      fullRequest<PaginatedResponse<FeedbackRequest>>({
        path: `users/${slug}/feedback`,
        params: {
          ...parse(search),
          page,
          pageSize: FEEDBACK_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ response }) => [
          getUserFeedbackResponses.success({ ...response, slug }),
          _upsertFeedbackRequestsById(response.results),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [
              getUserFeedbackResponses.failure({ slug }),
              replace(state$.value.router.location.pathname),
            ];
          }
          return [getUserFeedbackResponses.failure({ slug })];
        }),
      ),
    ),
  );

export const getUserConversationsEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(getUserConversations.request)),
    mergeMap(({ payload: { search, slug, page } }) =>
      fullRequest<PaginatedResponse<Conversation>>({
        path: `users/${slug}/conversation`,
        params: {
          ...parse(search),
          page,
          pageSize: CONVERSATIONS_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ response }) => [
          getUserConversations.success({ ...response, slug }),
          _upsertConversationsById(response.results),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [
              getUserConversations.failure({ slug }),
              replace(state$.value.router.location.pathname),
            ];
          }
          return [getUserConversations.failure({ slug })];
        }),
      ),
    ),
  );

export const getUserExerciseResponsesEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(getUserExerciseResponses.request)),
    mergeMap(({ payload: { search, slug, page } }) =>
      fullRequest<PaginatedResponse<ExerciseResponse>>({
        path: `users/${slug}/exerciseresponse`,
        params: {
          ...parse(search),
          page,
          pageSize: EXERCISE_RESPONSES_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ response }) => [
          getUserExerciseResponses.success({ ...response, slug }),
          _upsertExerciseResponsesById(response.results),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [
              getUserExerciseResponses.failure({ slug }),
              replace(state$.value.router.location.pathname),
            ];
          }
          return [getUserExerciseResponses.failure({ slug })];
        }),
      ),
    ),
  );

export const getUserChallengeResponsesEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(getUserChallengeResponses.request)),
    mergeMap(({ payload: { search, slug, page } }) =>
      fullRequest<PaginatedResponse<ChallengeResponse>>({
        path: `users/${slug}/challengeresponse`,
        params: {
          ...parse(search),
          page,
          pageSize: CHALLENGE_RESPONSES_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ response }) => [
          getUserChallengeResponses.success({ ...response, slug }),
          _upsertChallengeResponsesById(response.results),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [
              getUserChallengeResponses.failure({ slug }),
              replace(state$.value.router.location.pathname),
            ];
          }
          return [getUserChallengeResponses.failure({ slug })];
        }),
      ),
    ),
  );

export const getUserLikedEpic: Epic = (action$, state$, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getUserLiked.request)),
    mergeMap(({ payload: { slug, page } }) =>
      fullRequest<PaginatedResponse<Discover>>({
        path: `users/${slug}/liked`,
        params: {
          page,
          pageSize: DISCOVER_PER_PAGE,
        },
      }).pipe(
        mergeMap(({ response }) => [
          getUserLiked.success({ ...response, slug }),
          _upsertDiscoverById(response.results),
        ]),
        catchError((err: AjaxError) => {
          if (err.status === StatusCodes.NOT_FOUND) {
            return [
              getUserLiked.failure({ slug }),
              replace(state$.value.router.location.pathname),
            ];
          }
          return [getUserLiked.failure({ slug })];
        }),
      ),
    ),
  );

export const notifyLaunchEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(notifyLaunch.request)),
    mergeMap(({ payload }) =>
      fullRequest<LaunchSoonForm>({
        path: '/users/notify-me-when-live/',
        body: payload,
        method: 'POST',
      }).pipe(
        mergeMap(() => [notifyLaunch.success(), replace('/?success=true')]),
        catchError(() => [notifyLaunch.failure(), replace('/?success=true')]),
      ),
    ),
  );

export const getHeatMapEpic: Epic = (action$, _, { fullRequest }) =>
  action$.pipe(
    filter(isActionOf(getHeatMap.request)),
    mergeMap(({ payload: { id, slug } }) =>
      fullRequest<MonthData[]>({
        path: `users/${id}/heat-map`,
      }).pipe(
        mergeMap(({ response }) => [
          getHeatMap.success({ slug, data: response }),
        ]),
      ),
    ),
  );

export const sendActivationEmailEpic: Epic = (
  action$,
  state$,
  { fullRequest },
) =>
  action$.pipe(
    filter(isActionOf(sendActivationEmail.request)),
    mergeMap(({ payload: { email } }) =>
      fullRequest({
        method: 'POST',
        path: `users/send-activation-email`,
        body: {
          email,
        },
      }).pipe(
        mergeMap(() => [
          sendActivationEmail.success(),
          replace({
            pathname: state$.value.router.location.pathname,
            state: {
              success: true,
            },
          }),
        ]),
        catchError(() => [
          sendActivationEmail.failure(),
          replace({
            pathname: state$.value.router.location.pathname,
            state: {
              success: false,
            },
          }),
        ]),
      ),
    ),
  );

export const getOutstandingUsersEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getOutstandingUsers.request)),
    mergeMap(({ payload: { period } }) => {
      return request<PaginatedResponse<User>>({
        path: 'users',
        params: {
          period,
          page: 1,
          pageSize: 3,
          activity: true,
          activityOrder: 'outstanding-members',
        },
      }).pipe(
        mergeMap(({ results, ...rest }) => [
          getOutstandingUsers.success({
            results: results.map(transformUser),
            ...rest,
          }),
        ]),
        catchError(() => [getOutstandingUsers.failure()]),
      );
    }),
  );

export default combineEpics(
  getUsersEpic,
  getUserEpic,
  getUserGalleryEpic,
  getUserLikedEpic,
  getUserFeedbackResponsesEpic,
  getUserConversationsEpic,
  getUserChallengeResponsesEpic,
  getUserExerciseResponsesEpic,
  notifyLaunchEpic,
  getHeatMapEpic,
  sendActivationEmailEpic,
  getOutstandingUsersEpic,
);
