import styled, { css } from 'styled-components';

export const Container = styled.img<{ variant: 'big' | 'small' }>`
  display: block;

  ${({ variant }) =>
    variant === 'big' &&
    css`
      width: 12.5rem;
    `};

  ${({ variant }) =>
    variant === 'small' &&
    css`
      width: 2.5rem;
    `};

  ${({ color }) =>
    color === 'grey' &&
    css`
      width: 2.5rem;
    `};
`;
