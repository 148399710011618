import React, { FC, useCallback } from 'react';
import { Route } from 'react-router-dom';
import connect from './connect';
import { getWrapper } from './logic';
import { Props } from './types';

const GuestRoute: FC<Props> = ({ component: Component, loggedIn, ...rest }) => {
  const Wrapper = useCallback(getWrapper({ Component, loggedIn }), [
    Component,
    loggedIn,
  ]);

  return <Route component={Wrapper} {...rest} />;
};

export default connect(GuestRoute);
