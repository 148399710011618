import { useMediaQuery } from '@material-ui/core';
import { Loader } from 'components';
import PageTitle from 'components/PageTitle';
import React, { FC, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { from } from 'styles/mixins';
import { getUserType } from 'utils/getUserType';
import { isNotFalsy } from 'utils/NonFalsy';
import About from './About';
import Activity from './Activity';
import connect from './connect';
import Contributions from './Contributions';
import Gallery from './Gallery';
import { getFallbackUrl } from './logic';
import {
  ActivitySection,
  Avatar,
  Container,
  ContentContainer,
  Controls,
  GoPremiumSection,
  Header,
  Loading,
  MainTabs,
  NoContent,
  NoContentIcon,
  NoContentText,
  SWDLogo,
  UserProfileDetailsContainer,
  UserProfileHeader,
  UserProfileHeaderContainer,
  WrapperContainer,
} from './styles';
import { Props } from './types';

const UserDetail: FC<Props> = ({
  match: { params, path, url },
  user,
  loading,
}) => {
  const isMobile = !useMediaQuery(from.tabletH.query);
  const contentRef = useRef<HTMLDivElement>(null);
  const isMe = !params.userSlug;

  if (loading && !user) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  if (!user) {
    return null;
  }

  const { gallery } = user;

  const showAbout = isMobile;
  const showGallery = isMe || !!gallery;
  const showContributions = true;
  const showActivity = true;
  const showGoPremium = !user.isPremium && isMe && !user.isPremiumGroupOwner;
  const noContent =
    !showAbout &&
    !showGallery &&
    !showContributions &&
    !showActivity &&
    !showGoPremium;

  const fallbackUrl = getFallbackUrl({
    path,
    about: showAbout,
    contributions: showContributions,
    activity: showActivity,
    goPremium: showGoPremium,
    gallery: showGallery,
  });

  return (
    <Container>
      <PageTitle title={`${user.firstName} ${user.lastName}`} />
      <UserProfileHeaderContainer>
        <Header>
          <WrapperContainer>
            {!isMobile ? (
              <UserProfileDetailsContainer>
                <Avatar
                  src={user.avatar}
                  profile={true}
                  variant={getUserType(user)}
                />

                <About user={user} />
              </UserProfileDetailsContainer>
            ) : null}

            <ContentContainer>
              <UserProfileHeader
                avatar={user.avatar}
                canEditProfile={isMe}
                companyName={!!user.companyName ? user.companyName : null}
                datapoints={user.votes}
                location={!!user.location ? user.location : null}
                name={`${user.firstName} ${user.lastName}`}
                companyRole={!!user.companyRole ? user.companyRole : null}
                url={url}
                userId={user.id}
                challengeStreak={user.challengeStreak}
                userType={getUserType(user)}
              />

              <ActivitySection>
                <Controls id="content" ref={contentRef}>
                  <MainTabs
                    variant="secondary"
                    baseUrl={url}
                    items={[
                      showAbout && {
                        label: 'About',
                        url: {
                          pathname: 'about',
                          state: {
                            scrollToElement: '#content',
                          },
                        },
                      },
                      showGallery && {
                        label: 'My gallery',
                        url: {
                          pathname: 'gallery',
                          state: {
                            scrollToElement: '#content',
                          },
                        },
                      },
                      showContributions && {
                        label: 'Contributions',
                        url: {
                          pathname: 'contributions',
                          state: {
                            scrollToElement: '#content',
                          },
                        },
                      },
                      showActivity && {
                        label: 'My activity',
                        url: {
                          pathname: 'activity',
                          state: {
                            scrollToElement: '#content',
                          },
                        },
                      },
                      showGoPremium && {
                        label: (
                          <>
                            Go premium! <SWDLogo />
                          </>
                        ),
                        url: {
                          pathname: 'go-premium',
                          state: {
                            scrollToElement: '#content',
                          },
                        },
                      },
                    ].filter(isNotFalsy)}
                  />
                </Controls>

                <>
                  <Switch>
                    {showAbout && (
                      <Route path={`${path}/about`}>
                        <About user={user} />
                      </Route>
                    )}

                    {showGallery && (
                      <Route path={`${path}/gallery`}>
                        <Gallery contentRef={contentRef} user={user} />
                      </Route>
                    )}

                    {showContributions && (
                      <Route path={`${path}/contributions`}>
                        <Contributions user={user} />
                      </Route>
                    )}

                    {showActivity && (
                      <Route path={`${path}/activity`}>
                        <Activity user={user} />
                      </Route>
                    )}

                    {showGoPremium && (
                      <Route path={`${path}/go-premium`}>
                        <GoPremiumSection />
                      </Route>
                    )}

                    {fallbackUrl && <Redirect to={fallbackUrl} exact={false} />}
                  </Switch>

                  {!loading && noContent && (
                    <NoContent>
                      <NoContentIcon />

                      <NoContentText>
                        This member hasn’t added any content yet.
                      </NoContentText>
                    </NoContent>
                  )}
                </>
              </ActivitySection>
            </ContentContainer>
          </WrapperContainer>
        </Header>
      </UserProfileHeaderContainer>
    </Container>
  );
};

export default connect(UserDetail);
