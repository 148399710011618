import { FormNameMap } from 'utils/forms';

export interface VisualTagInputsForm {
  graphType: string | null;
  industry: string[];
  tools: string[];
  topic: string[];
}

export const VisualTagInputsFieldNames: FormNameMap<VisualTagInputsForm> = {
  graphType: 'graphType',
  industry: 'industry',
  tools: 'tools',
  topic: 'topic',
};

export const visualTagInputsInitialValues: VisualTagInputsForm = {
  graphType: null,
  industry: [],
  tools: [],
  topic: [],
};
