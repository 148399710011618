import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import ChallengeDetail from './ChallengeDetail';
import ChallengeRoot from './ChallengeRoot';
import { useConnect } from './connect';
import { Props } from './types';

const Challenges: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Challenges</BreadcrumbsItem>
      <Switch>
        <Route path={`${path}/:challengeSlug`}>
          <ChallengeDetail />
        </Route>
        <Route component={ChallengeRoot} path={path} />
      </Switch>
    </>
  );
};

export default Challenges;
