import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import {
  changePassword,
  editProfile,
  logIn,
  logOut,
  requestResetPassword,
  resetPassword,
  saveToken,
  setGroupCode,
  signUp,
  support,
  verify,
} from './actions';
import { AuthAction, AuthState } from './types';

export const INITIAL_STATE: AuthState = {
  init: false,
  loading: false,
  user: null,
};

const reducer: Reducer<AuthState, AuthAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(setGroupCode.request):
    case getType(changePassword.request):
    case getType(requestResetPassword.request):
    case getType(resetPassword.request):
    case getType(editProfile.request):
    case getType(support.request):
      return {
        ...state,
        loading: true,
      };
    case getType(changePassword.failure):
    case getType(changePassword.success):
    case getType(requestResetPassword.failure):
    case getType(requestResetPassword.success):
    case getType(setGroupCode.success):
    case getType(setGroupCode.failure):
    case getType(support.success):
    case getType(support.failure):
    case getType(resetPassword.failure): {
      return {
        ...state,
        loading: false,
      };
    }
    case getType(editProfile.success):
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case getType(editProfile.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(logIn.request):
    case getType(saveToken.request):
      return {
        ...state,
        loading: true,
      };
    case getType(resetPassword.success):
    case getType(logIn.success):
    case getType(saveToken.success):
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case getType(logIn.failure):
    case getType(saveToken.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(logOut.request):
      return {
        ...state,
        loading: true,
        user: null,
      };
    case getType(logOut.success):
      return {
        ...state,
        loading: false,
      };
    case getType(logOut.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(signUp.request):
      return {
        ...state,
        loading: true,
      };
    case getType(signUp.success):
      return {
        ...state,
        loading: false,
      };
    case getType(signUp.failure):
      return {
        ...state,
        loading: false,
      };
    case getType(verify.success):
      return {
        ...state,
        init: true,
        user: action.payload,
      };
    case getType(verify.failure):
      return {
        ...state,
        init: true,
      };
    default:
      return state;
  }
};

export default reducer;
