import React, { FC, memo } from 'react';
import {
  AdditionalInfo,
  AuthorName,
  Avatar,
  Container,
  InfoRow,
  InfoSection,
} from './styles';
import { Props } from './types';

const Info: FC<Props> = ({
  authorAvatar,
  authorName,
  authorType,
  lastCommentDate,
  ...rest
}) => (
  <Container {...rest}>
    <Avatar variant={authorType} src={authorAvatar} />
    <InfoSection>
      <InfoRow>
        <AuthorName>{authorName}</AuthorName>
      </InfoRow>
      <InfoRow>
        <AdditionalInfo>
          Last comment: <strong>{lastCommentDate}</strong>
        </AdditionalInfo>
      </InfoRow>
      {}
    </InfoSection>
  </Container>
);

export default memo(Info);
