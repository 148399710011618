import { RefObject } from 'react';
import { interval } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import {
  filter,
  first,
  ignoreElements,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

const isDefined = <T>(it: T): it is NonNullable<typeof it> => !!it;

export function useRefEffect<T>(
  callback: (current: NonNullable<T>) => void,
  ref: RefObject<T>,
) {
  useObservable(
    (input$) =>
      input$.pipe(
        mergeMap(([, ref]) => {
          if (isDefined(ref.current)) {
            return [ref.current];
          }

          return interval(1).pipe(
            map(() => ref.current),
            filter(isDefined),
            first(),
          );
        }),
        withLatestFrom(input$),
        tap(([current, [callback]]) => callback(current)),
        ignoreElements(),
      ),
    null as never,
    [callback, ref],
  );
}
