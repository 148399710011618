import {
  ArrowLink as DefaultArrowLink,
  FeaturedLink as DefaultFeaturedLink,
  FullScreenContainer,
} from 'components';
import { TimesIcon } from 'components/icons';
import DefaultSearchOptions from 'containers/Common/SearchOptions';
import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { container, from, title } from 'styles/mixins';

export const Container = styled(FullScreenContainer)``;

export const Content = styled.div`
  ${container};
  display: flex;
  flex-direction: column;
  padding: 0.525rem 1rem;
`;

export const CommunityTitle = styled(DefaultLink)``;

export const CloseIcon = styled(TimesIcon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  height: 1.8125rem;
  width: 1.25rem;
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const GoBackLink = styled(DefaultLink)`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  margin-left: auto;
  padding: ${({ theme }) => theme.spacing.sm};

  :focus {
    outline: none;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h1`
  ${title};
`;

export const SearchOptions = styled(DefaultSearchOptions)`
  flex: 1;

  > :nth-child(2) {
    grid-column: -3/-1;
  }
`;

export const FeaturedLink = styled(DefaultFeaturedLink)`
  margin: 3.75rem auto;
  max-width: 73.13rem;
  span {
    font-size: 2.5rem;
  }
  a {
    font-size: 0.8rem;
  }
`;

export const Results = styled.div`
  margin-bottom: 3.75rem;
`;

export const ResultsTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;

export const ResultsHeader = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${from.mobileXL`
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
  `};
`;

export const ArrowLink = styled(DefaultArrowLink)`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.accent};
  text-transform: uppercase;
`;

const link = css`
  text-decoration: none;
`;

export const Link = styled(DefaultLink)`
  ${link};
`;

export const ExternalLink = styled.a`
  ${link};
`;

export const ResourceContainer = styled.div`
  flex-grow: 1;
`;
