import styled from 'styled-components';

export const BookContainer = styled.div`
  > *:not(:last-child) {
    margin-right: 1.8125rem;
  }
`;

export const BookCover = styled.img`
  max-width: 23.125rem;
  max-height: 28.75rem;
  width: 100%;
`;
