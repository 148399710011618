import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  flex-grow: 1;
  gap: 30px 30px;
  box-sizing: border-box;

  ${from.tablet`
    gap: 50px 30px;
  `}
`;
