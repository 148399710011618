import { Plugin } from 'prosemirror-state';
import { findChildrenByMark } from 'prosemirror-utils';
import { getPluginState } from '../ReferenceSelector';

import {
  ensureReferencesAreNotBroken,
  ensureReferencesAreValid,
  exitReferenceOnNoOption,
} from './logic';
import type { PluginProps } from './types';

/**
 * The referenceWatcherPlugin is a plugin that listen to state changes and
 * ensures that all references are valid after being modified.
 *
 * For example, if a reference is split in two, this plugin will discard the
 * reference that doesn't contain the @. Or, if the user types before the @, we
 * consider it an email and remove the reference.
 */
export const referenceWatcherPlugin = ({ getProvider, schema }: PluginProps) =>
  new Plugin({
    appendTransaction: (_, __, state) => {
      const tr = state.tr;
      const allReferences = findChildrenByMark(tr.doc, schema.marks.reference);
      /**
       * Looks if the current reference is valid
       * (starts with @,first character is not space)
       * and if not close it
       */

      ensureReferencesAreValid({
        allReferences,
        schema,
        tr,
      });

      /**
       * Looks if the current reference have no options and
       * detect if space is clicked,
       * if so remove refernceMark
       */
      const { options } = getPluginState(state);

      exitReferenceOnNoOption({
        allReferences,
        schema,
        tr,
        options,
      });

      const getReferencedEntity = getProvider(state)?.getReferencedEntity;

      // Takes care of checking that the reference that has already been made is not broken
      ensureReferencesAreNotBroken({
        allReferences,
        getReferencedEntity,
        schema,
        tr,
      });

      return tr.steps.length > 0 ? tr : undefined;
    },
  });
