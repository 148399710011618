import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div<{ isMouseInput: boolean }>`
  display: flex;
  flex-direction: column;

  > *:not(:first-child) {
    ${({ isMouseInput }) =>
      !isMouseInput &&
      css`
        ${from.tablet`
            margin-top: 1.9rem;
        `};
      `};
  }

  }

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Input = styled.input``;
