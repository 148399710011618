import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import { BookingNotification, OfficeHour } from 'model';
import { RootState } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { DeepReadonly } from 'utility-types';

export const normalizeOfficeHour = ({
  booking,
  startDatetime,
  endDatetime,
  ...rest
}: DeepReadonly<OfficeHour>) => ({
  booked: !!booking,
  endDatetime: parseISO(endDatetime),
  notification: booking?.sendNotification ?? BookingNotification.NONE,
  startDatetime: parseISO(startDatetime),
  booking: {
    id: booking?.id ?? '',
  },
  ...rest,
});

export const selectOfficeHoursState = (state: RootState) => state.officeHour;

export const selectUpcomingHours = createSelector(
  selectOfficeHoursState,
  ({ byId, ids }) =>
    ids
      .map((id) => byId[id]!)
      .filter((it): it is NonNullable<typeof it> => !!it)
      .map(normalizeOfficeHour)
      .filter((it) => isFuture(it.endDatetime))
      .slice(0, 4),
);
