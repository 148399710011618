import iconLock from 'assets/images/iconLock.svg';
import React, { FC } from 'react';
import { pluralize } from 'utils/pluralize';
import {
  Author,
  Content,
  Image,
  ImageIcon,
  Img,
  Info,
  Link,
  LockIcon,
  LockOverlay,
  PracticeCardContainer,
  Row,
  Solutions,
  Tag,
  Title,
} from './styles';
import { Props } from './types';

const PracticeCard: FC<Props> = ({
  title,
  solutions,
  image,
  link,
  author,
  hours,
  challengeId,
  exerciseId,
  submissionId,
  allowAccessSubmission,
}) => (
  <PracticeCardContainer>
    {title && solutions && (
      <Row>
        <Title>{title}</Title>
        <Tag>New!</Tag>
        <Solutions>
          {solutions}{' '}
          <span>{pluralize(solutions, 'Solution', 'Solutions')}</span>
        </Solutions>
      </Row>
    )}
    <Image>
      {exerciseId && !allowAccessSubmission && (
        <LockOverlay>
          <LockIcon src={iconLock} alt="" aria-hidden="true" />
        </LockOverlay>
      )}
      {image.length > 0 ? (
        <Img src={image} alt="" aria-hidden="true" />
      ) : (
        <ImageIcon />
      )}
    </Image>
    <Content>
      {challengeId && (
        <Link to={`challenges/${challengeId}/${submissionId}`}>{link}</Link>
      )}
      {exerciseId && (
        <Link
          to={
            allowAccessSubmission
              ? `exercises/${exerciseId}/${submissionId}`
              : {
                  pathname: `exercises/${exerciseId}`,
                  state: {
                    scrollToElement: '#submissions',
                  },
                }
          }
        >
          {link}
        </Link>
      )}
      <Info>
        By
        <Author>{author}</Author>
        {hours}
      </Info>
    </Content>
  </PracticeCardContainer>
);

export default PracticeCard;
