import styled from 'styled-components';

export const Microphone = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const MicrophoneDetail = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Head = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Headset = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const HeadsetSmallCircle = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const HeadsetBigCircle = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;
