import bowser from 'bowser';
import React, { FC, useEffect, useRef } from 'react';
import { Container } from './styles';
import { Props } from './types';

const browser = bowser.getParser(navigator.userAgent);

const useJavascriptLineClamp = browser.satisfies({
  ie: '>=1',
});

const LinesWrapper: FC<Props> = ({ children, maxLines, ...rest }) => {
  const container = useRef<
    HTMLDivElement | HTMLHeadingElement | HTMLParagraphElement | null
  >(null);

  const element = container.current;

  useEffect(() => {
    if (useJavascriptLineClamp && element && element.textContent) {
      const style = getComputedStyle(element);
      const lineHeight = style.lineHeight;

      const desiredHeight = (maxLines * parseFloat(lineHeight)) | 0;

      while (
        element.clientHeight -
          parseFloat(style.paddingTop) -
          parseFloat(style.paddingBottom) >
        desiredHeight
      ) {
        element.textContent = element.textContent.replace(/\W*\s(\S)*$/, '...');
      }
    }
  }, [element, maxLines]);

  return (
    <Container {...rest} ref={container} maxLines={maxLines}>
      {children}
    </Container>
  );
};

export default LinesWrapper;
