import { EditorState } from 'components/Editor/types';
import { FormNameMap } from 'utils/forms';

export const CONVERSATION_FORM_KEY = (editId?: string) =>
  `forms/CONVERSATION/${editId ? `edit/${editId}` : 'new'}`;

export interface ConversationForm {
  description: EditorState;
  tags: string[];
  title: string;
}

export const ConversationFieldNames: FormNameMap<ConversationForm> = {
  description: 'description',
  tags: 'tags',
  title: 'title',
};
