import { oneLine } from 'common-tags';
import { Loader, ModalRoute, Navigation, PageTitle } from 'components';
import ExerciseSubmissionView from 'containers/Common/ExerciseSubmissionView';
import { getUserName } from 'model';
import React, { FC, useEffect } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { getUserType } from 'utils/getUserType';
import { useConnect } from './connect';
import Edit from './Edit';
import { Comments, Container, Submission } from './styles';
import { Props } from './types';

const ExerciseSubmission: FC<Props> = () => {
  const {
    exerciseSlug,
    getSubmission,
    loading,
    path,
    submission,
    submissionSlug,
    url,
    userIsAuthor,
  } = useConnect();

  useEffect(() => {
    getSubmission({ exerciseSlug, exerciseResponseSlug: submissionSlug });
  }, [exerciseSlug, getSubmission, submissionSlug]);

  if (loading && !submission) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  if (!submission) {
    return null;
  }

  return (
    <Container>
      <PageTitle title={submission.title} />
      <BreadcrumbsItem to={url}>{submission?.title}</BreadcrumbsItem>
      <Navigation
        previousHref={
          submission.previousResponseSlug &&
          path
            .replace(':exerciseSlug', exerciseSlug)
            .replace(':submissionSlug', submission.previousResponseSlug)
        }
        previousText="Previous solution"
        nextHref={
          submission.nextResponseSlug &&
          path
            .replace(':exerciseSlug', exerciseSlug)
            .replace(':submissionSlug', submission.nextResponseSlug)
        }
        nextText="Next solution"
      />
      <Submission>
        <ExerciseSubmissionView
          authorAvatar={submission.user.avatar}
          authorId={submission.user.id}
          authorName={getUserName(submission.user)}
          authorType={getUserType(submission.user)}
          authorSlug={submission.user.slug}
          created={submission.created}
          description={submission.description}
          id={submission.id}
          media={
            submission.mediaList
              ? [
                  submission.mediaList.mainMedia,
                  ...submission.mediaList.extraMedia,
                ]
              : []
          }
          isReported={submission.isReported}
          isVoted={submission.isVoted}
          open={!!submission.id}
          scroll={false}
          shareButtonMessage={oneLine`Thought this was interesting from the SWD
          community. Check it out! - ${submission.title}: `}
          tags={submission.tags}
          title={submission.title}
          url={url}
          views={submission.hits}
          votes={submission.votesCount}
        />
      </Submission>
      <Comments
        contentType="exerciseresponse"
        id={submission.id}
        path={path}
        url={url}
      />
      {userIsAuthor && <ModalRoute component={Edit} path={`${path}/edit`} />}
    </Container>
  );
};

export default ExerciseSubmission;
