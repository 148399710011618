import { Modal } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(Modal)`
  & .MuiPaper-root {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 2rem;
    overflow: hidden;

    ${from.mobileXL`
      max-width: 29rem;
      min-height: unset;
      width: 90vw;
    `};
  }
`;

export const Title = styled.h2`
  margin: 0 0 0.5625rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  color: ${({ theme }) => theme.colors.primary};
`;

export const Paragraph = styled.p`
  margin: 0 0 2rem;
`;
