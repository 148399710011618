import last from 'lodash/last';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import type { EditorView } from 'prosemirror-view';
import React, { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import themes from 'styles/themes';
import createAdaptedView from 'utils/prosemirror-react-view-adapter';
import type { Schema } from '../schema';
import { openLinkPrompt, SetLinkPromptOpenStep } from './actions';
import Prompt from './Prompt';
import { Container } from './styles';
import type { Props, State } from './types';

const pluginKey = new PluginKey<State>('link-prompt');

export const isOpen = (state: EditorState<Schema>) =>
  !!pluginKey.getState(state)?.open;

const createLinkPrompt = ({
  editorView,
}: {
  editorView: EditorView<Schema>;
}): FC<Props> => ({ state }) => (
  <Container>
    {isOpen(state) && <Prompt editorView={editorView} state={state} />}
  </Container>
);

export const createLinkPromptPlugin = () => {
  const linkPromptPlugin = new Plugin<State>({
    key: pluginKey,
    filterTransaction: (transaction, state) => {
      // Prevent the selection from being modified if the prompt is open.
      return !(isOpen(state) && transaction.selectionSet);
    },
    props: {
      handleDOMEvents: {
        click: (view, event) => {
          if (event.target instanceof HTMLAnchorElement) {
            openLinkPrompt(view.state, view.dispatch);
          }

          // Don't prevent events from propagating.
          return true;
        },
      },
    },
    state: {
      init: () => ({
        open: false,
      }),
      apply(tr: Transaction<Schema>, value: State): State {
        // noinspection SuspiciousTypeOfGuard
        const setOpenStep = last(
          tr.steps.filter(
            (step): step is SetLinkPromptOpenStep =>
              step instanceof SetLinkPromptOpenStep,
          ),
        );

        return {
          ...value,
          open: setOpenStep?.open ?? value.open,
        };
      },
    },
    view(editorView) {
      const LinkPrompt = createLinkPrompt({ editorView });

      return createAdaptedView({
        editorView,
        Component: (props) => (
          <ThemeProvider theme={themes.light}>
            <LinkPrompt {...props} />
          </ThemeProvider>
        ),
        onRender: (dom) => {
          editorView.dom.parentNode?.insertBefore(dom, editorView.dom);
        },
      });
    },
  });

  return { linkPromptPlugin, isLinkPromptOpen: isOpen };
};
