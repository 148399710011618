import DefaultAvatar from 'components/Avatar';
import DefaultImageListViewer from 'components/ImageListViewer';
import DefaultLink from 'components/Link';
import DefaultShareButton from 'components/ShareButton';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import DefaultTags from 'components/Tags';
import styled, { css } from 'styled-components';
import { container, from } from 'styles/mixins';

export const Container = styled.div`
  ${container};
  border-radius: 0.5rem;
  padding: 1rem 1.25rem;
  ${from.tablet`
    padding: 1.5rem 2rem;
 `};
  ${from.laptop`
    padding: 2.5rem 3.75rem;
 `};
`;

export const Content = styled.div``;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  ${from.tablet`
    flex-direction: row;
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
  margin-top: ${({ theme }) => theme.spacing.xlg};
  ${from.tablet`
    margin-top: ${({ theme }) => theme.spacing.md};
  `};
`;

export const Title = styled(DefaultLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1.375rem;
  margin: 0;
  pointer-events: ${({ to }) => (to ? 'auto' : 'none')};

  ${({ to }) =>
    to &&
    css`
      color: ${({ theme }) => theme.colors.accent};
    `};
`;

export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const InfoHeader = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: grid;
  width: 100%;
  grid-template-columns: 3.6875rem auto 2.625rem;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => theme.spacing.md};
  row-gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: 2rem;
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  grid-row: 1;
`;

export const Avatar = styled(DefaultAvatar)`
  height: 3.75rem;
  width: 3.75rem;
  margin: 0;

  ${BadgeContainer} {
    height: 1.4375rem;
    width: 1.4375rem;
    bottom: -4px;
    left: 39px;
  }
`;

export const UserLink = styled(DefaultLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  margin-bottom: ${({ theme }) => theme.spacing.xsm};

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

export const UserName = styled.p`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.5rem;
  margin: 0;
  justify-content: flex-end;
`;

export const Tags = styled(DefaultTags)`
  grid-column: 1/4;
`;

export const ImageListViewer = styled(DefaultImageListViewer)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  overflow: hidden;
  ${from.tablet`
    width: 100%;
  `};
`;

export const ShareButton = styled(DefaultShareButton)`
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  margin-left: auto;
`;
