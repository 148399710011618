import { EditorState } from 'components/Editor/types';
import { Media } from 'components/MediaInput';
import { FormNameMap } from 'utils/forms';
import { UPLOAD_MEDIA_FIELD } from '../utils';

export const COMMENT_FORM_KEY = (parentId: string, editId?: string) =>
  `forms/COMMENT/${parentId}/${editId ?? 'new'}`;

export interface CommentForm {
  [UPLOAD_MEDIA_FIELD]: readonly Media[];
  comment: EditorState;
}

export const NewCommentFieldNames: FormNameMap<CommentForm> = {
  [UPLOAD_MEDIA_FIELD]: UPLOAD_MEDIA_FIELD,
  comment: 'comment',
};
