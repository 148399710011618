import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectUserIsPremium, selectUserIsSWD } from 'redux/modules/auth';
import {
  getUserInfo,
  selectMembers,
  selectPlanId,
} from 'redux/modules/premium';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { path } = useRouteMatch();
  const isSWDTeam = useSelector(selectUserIsSWD);
  const isPremium = useSelector(selectUserIsPremium);
  const wentBack = useSelector(selectUserWentBack);
  const members = useSelector(selectMembers);
  const planId = useSelector(selectPlanId);

  const actions = useActions({
    getPremiumInfo: getUserInfo.request,
  });

  return {
    ...actions,
    members,
    path,
    isSWDTeam,
    isPremium,
    planId,
    wentBack,
  };
};
