import { generateICS } from 'utils/calendar';
import { Props } from './types';

export const handleDownloadCalendar = ({
  closeDropdown,
  start,
  end,
  name,
}: {
  closeDropdown: Props['closeDropdown'];
  start: Props['start'];
  end: Props['end'];
  name: Props['name'];
}) => () => {
  const ics = generateICS({
    name,
    events: [
      {
        end,
        name,
        begin: start,
      },
    ],
  });

  const blob = new Blob([ics], {
    type: 'text/calendar',
  });

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${name}.ics`;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);

  closeDropdown();
};
