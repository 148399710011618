import { Banner } from 'model';
import { combineEpics } from 'redux-observable';
import { Epic } from 'redux/modules/types';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getActiveBannerItems } from './actions';

export const getActiveBannerItemsEpic: Epic = (action$, _, { request }) =>
  action$.pipe(
    filter(isActionOf(getActiveBannerItems.request)),
    mergeMap(() =>
      request<Banner>({
        path: 'banners/active',
      }).pipe(
        mergeMap((response) => {
          return [
            getActiveBannerItems.success({
              ...response,
              id: response.id,
              title: response.title,
              description: response.description,
              image: response.image,
              ctaLink: response.ctaLink,
              ctaText: response.ctaText,
            }),
          ];
        }),
        catchError(() => [getActiveBannerItems.failure()]),
      ),
    ),
  );

export default combineEpics(getActiveBannerItemsEpic);
