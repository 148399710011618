import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { ChallengeSubmissionForm, UPLOAD_MEDIA_FIELD } from 'model';
import { SubmissionError } from 'redux-form';
import { Props } from './types';

export const handleFormSubmitted = ({
  challengeId,
  createChallengeResponse,
}: {
  challengeId: string;
  createChallengeResponse: Props['createChallengeResponse'];
}) => (values: ChallengeSubmissionForm) => {
  const [mainMedia, ...extraMedia] = values[UPLOAD_MEDIA_FIELD].filter(
    isUploaded,
  );

  if (!mainMedia) {
    throw new SubmissionError({
      [UPLOAD_MEDIA_FIELD]: 'Please add at least one image!',
    });
  }
  let addToGalleryOnlyImage = values.addToMyGallery;
  if (mainMedia.mediaType === 'VIDEO') {
    addToGalleryOnlyImage = false;
  }

  createChallengeResponse({
    challengeId,
    description: serializeHTML(values.description),
    addToMyGallery: addToGalleryOnlyImage,
    graphIndustry: values.industry,
    graphTools: values.tools,
    graphTopic: values.topic,
    graphTypes: values.graphType,
    mediaList: {
      mainMedia,
      extraMedia,
    },
    title: values.title,
  });
};
