import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { FeedbackForm, UPLOAD_MEDIA_FIELD } from 'model';
import React from 'react';
import { SubmissionError } from 'redux-form';
import { deleteFeedbackRequest } from 'redux/modules/feedback';
import { Props } from './types';

export const handleDelete = ({
  id,
  openConfirmationModal,
}: {
  id?: string;
  openConfirmationModal: Props['openConfirmationModal'];
}) => () => {
  if (id) {
    openConfirmationModal({
      acceptButtonLabel: 'Delete',
      acceptButtonVariant: 'danger',
      cancelButtonLabel: 'Cancel',
      title: <span>Are you sure?</span>,
      description: (
        <span>
          <strong>Deleting this feedback request can’t be undone</strong>. This
          entry and its associated images, comments, datapoints... will be
          deleted in all the sections of this website.
        </span>
      ),
      onAccept: [deleteFeedbackRequest.request({ id })],
    });
  }
};

export const handleSubmit = ({
  editFeedbackRequest,
  id,
}: {
  editFeedbackRequest: Props['editFeedbackRequest'];
  id?: string;
}) => (values: FeedbackForm) => {
  if (id) {
    const [mainImage, ...extraImages] = values[UPLOAD_MEDIA_FIELD].filter(
      isUploaded,
    );

    if (!mainImage) {
      throw new SubmissionError({
        [UPLOAD_MEDIA_FIELD]: 'Please add at least one image!',
      });
    }

    editFeedbackRequest({
      id,
      addToMyGallery: values.addToMyGallery,
      context: serializeHTML(values.context),
      expiryDate: values.expirationDate?.toISOString() ?? null,
      graphIndustry: values.industry,
      graphTools: values.tools,
      graphTopic: values.topic,
      graphTypes: values.graphType,
      imageList: {
        mainImage: mainImage.file,
        extraImages: extraImages.map((it) => it.file),
      },
      title: values.title,
    });
  }
};
