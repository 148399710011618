import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { whiteBox } from 'styles/mixins';

export const ContentWrapper = styled.div`
  display: none;
  left: 0;
  padding: 1rem;
  position: absolute;
  top: 100%;
  transform: translateX(-1rem) translateY(-0.5rem);
  z-index: ${ZIndex.DROPDOWN_MENU};
`;

export const Container = styled.span<{ active: boolean }>`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey400};
  cursor: pointer;
  display: inline-flex;
  position: relative;

  :hover ${ContentWrapper} {
    display: block;
  }

  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.colors.accent};
    `};
`;

export const List = styled.div`
  ${whiteBox}
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 20px 0;

  a + a,
  a + button {
    margin-top: 19px;
  }
`;
