import DefaultEventImage from 'assets/images/event/cole_presenting_graph_in_background.png';
import { LiveTag, UpcomingLiveEvent } from 'components';
import { getDateAndTime } from 'components/OfficeHourBook/logic';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import DOMPurify from 'dompurify';
import React, { FC, memo, useMemo } from 'react';
import { useLiveDate } from 'utils/useLiveDate';

import { isBefore } from 'date-fns';
import {
  FeaturedVideo,
  FeaturedVideoClosed,
  FeaturedVideoDescription,
  FeaturedVideoTitle,
} from './styles';
import { Props } from './types';

const LiveEvent: FC<Props> = ({
  buttonText,
  buttonLink,
  description,
  endDate,
  image,
  isPremium,
  isVimeoEvent,
  link,
  slug,
  startDate,
  thumbnail,
  title,
  url,
}) => {
  const now = useLiveDate(1000);

  const isLiveNow = useMemo(() => {
    if (endDate && startDate) {
      const startDateEvent = parseISO(startDate);
      const endDateEvent = parseISO(endDate);
      return isBefore(now, endDateEvent) && isAfter(now, startDateEvent);
    }
  }, [startDate, endDate, now]);

  const isPast = useMemo(() => {
    if (endDate) {
      try {
        const endDateEvent = parseISO(endDate);
        return isAfter(now, endDateEvent);
      } catch {
        return false;
      }
    }
  }, [endDate, now]);

  const isUpcoming = useMemo(() => {
    if (startDate) {
      try {
        const date = parseISO(startDate);
        return isAfter(date, now);
      } catch {
        return true;
      }
    }
  }, [now, startDate]);

  const dateAndTime = useMemo(() => {
    if (startDate && endDate) {
      return getDateAndTime({
        endDate: parseISO(endDate),
        startDate: parseISO(startDate),
      });
    }
  }, [endDate, startDate]);

  const sanitizedDescription = useMemo(() => DOMPurify.sanitize(description), [
    description,
  ]);

  if (isUpcoming && !isLiveNow && !isPast) {
    return (
      <UpcomingLiveEvent
        title={title}
        description={sanitizedDescription}
        startDate={startDate ? parseISO(startDate) : undefined}
        isVimeoEvent={buttonLink === null}
        image={image}
        isPremium={isPremium}
        buttonText={buttonText}
        buttonLink={buttonLink}
        link={link}
        slug={slug}
      />
    );
  }

  return (
    <>
      {isVimeoEvent ? (
        <FeaturedVideo
          image={thumbnail ? thumbnail : DefaultEventImage}
          to={{
            pathname: `${url}/${slug}`,
            state: { scrollToTop: false },
          }}
        >
          {isPast ? <FeaturedVideoClosed name="Closed" /> : <LiveTag />}
          <FeaturedVideoTitle>
            {title}
            {!isPast && <span>{dateAndTime?.time}</span>}
          </FeaturedVideoTitle>
          <FeaturedVideoDescription
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
        </FeaturedVideo>
      ) : (
        <FeaturedVideo
          image={image ? image : DefaultEventImage}
          to={isPremium ? `${buttonLink}` : '/premium'}
          buttonTextZoom={buttonText}
          zoomEventClosed={isPast}
          isZoomEvent={true}
          isPremium={isPremium}
        >
          {isLiveNow ? <LiveTag /> : <FeaturedVideoClosed name="Closed" />}
          <FeaturedVideoTitle>
            {title}
            {isPast && <span>{dateAndTime?.time}</span>}
          </FeaturedVideoTitle>
          <FeaturedVideoDescription
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          />
        </FeaturedVideo>
      )}
    </>
  );
};

export default memo(LiveEvent);
