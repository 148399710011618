import { ConnectedProps } from './connect';

export const useSkipOnboarding = ({
  editProfile,
}: {
  editProfile: ConnectedProps['editProfile'];
}) => {
  const onClick = () => {
    editProfile({
      firstLogin: false,
    });
  };

  return {
    onClick,
  };
};
