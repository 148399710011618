import { CheckIcon } from 'components/icons';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { randomId } from 'utils/randomId';

export const Container = styled.div<{ numberOfSteps: number }>`
  position: relative;
  width: 90%;

  ${from.tablet<{ numberOfSteps: number }>`
    width: ${({ numberOfSteps }) => 8.275 * numberOfSteps}rem;
  `};
`;

export const Line = styled.span`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.grey200};
  height: 1px;
  width: 100%;
  bottom: 50%;
  z-index: -1;
`;

export const Steps = styled.div`
  display: flex;
  justify-content: space-between;
`;

const stepActive = `stepActive-${randomId()}`;

export const Step = styled(NavLink).attrs({
  activeClassName: stepActive,
})<{ reached: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  color: ${({ theme }) => theme.colors.grey400};
  display: flex;
  height: 2.75rem;
  justify-content: center;
  text-decoration: none;
  width: 2.75rem;

  &.${stepActive} {
    background-color: ${({ theme }) => theme.colors.accent};
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ reached, theme }) =>
    reached &&
    css`
      border-color: ${theme.colors.accent};
    `};
`;

export const StepNumber = styled.span`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1rem;
`;

export const Check = styled(CheckIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 1rem;
`;
