import React, { FC, useCallback } from 'react';
import connect from './connect';
import Form from './Form';
import { handleFormSubmitted } from './logic';
import { Modal } from './styles';
import { Props } from './types';

const ReportModal: FC<Props> = ({
  closeModal,
  contentType,
  objectId,
  report,
}) => {
  const onSubmit = useCallback(
    handleFormSubmitted({
      closeModal,
      contentType,
      objectId,
      report,
    }),
    [closeModal, contentType, objectId, report],
  );

  return (
    <Modal open={!!objectId} onClose={closeModal}>
      <Form id={objectId || ''} onSubmit={onSubmit} />
    </Modal>
  );
};

export default connect(ReportModal);
