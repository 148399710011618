export interface RedirectResponse {
  type: 'redirect';
  mappings: {
    [key: string]: string;
  };
}

export function isRedirect(input: unknown): input is RedirectResponse {
  return (
    typeof input === 'object' &&
    input !== null &&
    'type' in input &&
    (input as { type: unknown }).type === 'redirect'
  );
}
