import { FormNameMap } from 'utils/forms';

export const SIGN_UP_FORM_KEY = 'forms/SIGN_UP';

export interface SignUpForm {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  passwordConfirm: string;
}

export const SignUpFieldNames: FormNameMap<SignUpForm> = {
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  password: 'password',
  passwordConfirm: 'passwordConfirm',
};

export * from './logic';
