import {
  Modal,
  OfficeHourBook as DefaultOfficeHourBook,
  OfficeHourBooked as DefaultOfficeHourBooked,
} from 'components';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(Modal)<{ $booked: boolean }>`
  color: ${({ theme }) => theme.colors.black};

  & .MuiPaper-root {
    transition: width 300ms;

    ${({ $booked }) =>
      $booked
        ? css`
            ${from.laptop`
              width: 47.5rem;   
            `};
          `
        : css`
            ${from.laptop`
              width: 56.25rem;   
            `};
          `}
  }
`;

export const Content = styled.div`
  padding: 2.5rem 1rem 1rem;

  ${from.tablet`
    padding: 3.75rem;
  `};
`;

const animation = {
  animate: 'shown',
  initial: 'hidden',
  exit: 'hidden',
  variants: {
    hidden: { opacity: 0 },
    shown: { opacity: 1 },
  },
};

export const OfficeHourBook = styled(
  motion.custom(DefaultOfficeHourBook),
).attrs(animation)``;

export const OfficeHourBooked = styled(
  motion.custom(DefaultOfficeHourBooked),
).attrs(animation)``;
