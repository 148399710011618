import DefaultError from 'components/Error';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  width: 100%;
`;

export const NumberSeats = styled.div<{ $moreThanSubscription: boolean }>`
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: ${({ theme }) => theme.colors.grey400};
  ${({ $moreThanSubscription }) =>
    $moreThanSubscription &&
    css`
      color: ${({ theme }) => theme.colors.error};
    `};
`;

export const Error = styled(DefaultError)`
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const Instructions = styled.h3`
  margin: 1rem 0 0.5rem 0;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 0.875rem;
  letter-spacing: 0.0375rem;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`;

export const ActionZone = styled.div`
  margin-top: 3.75rem;
  > :not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }
  ${from.mobileXL`
  > :not(:last-child) {
    margin-bottom: 0;
  }
`};
`;
