import * as Sentry from '@sentry/browser';
import 'assets/fonts/ProximaNova/index.css';
import { createBrowserHistory } from 'history';
import 'normalize.css';
import React from 'react';
import { render } from 'react-dom';
import configureStore from 'redux/store';
import { isProd } from 'utils/config';
import App from './App';
import './index.css';
import './modernizr';

if (isProd) {
  Sentry.init({
    dsn: 'https://485b2394fc1c4bf0b09dc9966eac75fe@sentry.io/1774066',
  });
}

const appProps = configureStore(createBrowserHistory());
const rootElement = document.getElementById('root');

render(<App {...appProps} />, rootElement);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(<NextApp {...appProps} />, rootElement);
  });
}
