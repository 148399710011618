import Images from 'containers/Common/Images';
import ProtectedRoute from 'containers/Common/ProtectedRoute';
import React, { FC } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Route, Switch } from 'react-router';
import AskFeedback from './AskFeedback';
import { useConnect } from './connect';
import FeedbackDetail from './FeedbackDetail';
import FeedbackRoot from './FeedbackRoot';
import { Props } from './types';

const Feedback: FC<Props> = () => {
  const { path, url } = useConnect();

  return (
    <>
      <BreadcrumbsItem to={url}>Feedback</BreadcrumbsItem>
      <Switch>
        <ProtectedRoute path={`${path}/ask`}>
          <AskFeedback />
        </ProtectedRoute>
        <Route component={Images} path={`${path}/:feedbackId/images`} />
        <Route
          component={Images}
          path={`${path}/:feedbackId/:commentId/comment-image`}
        />
        <Route path={`${path}/:id`}>
          <FeedbackDetail />
        </Route>
        <Route component={FeedbackRoot} path={path} />
      </Switch>
    </>
  );
};

export default Feedback;
