import { Input } from 'components';
import { ChangePlanNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import {
  Container,
  CorporateSection,
  Section,
  SectionDescription,
  SectionTitle,
  SubmitButton,
} from './styles';
import { Props } from './types';

const Form: FC<Props> = ({
  isCurrentlyCorporate,
  isCorporate,
  handleSubmit,
  loading,
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      {!isCurrentlyCorporate && isCorporate && (
        <CorporateSection>
          <Section>
            <SectionTitle>Company name</SectionTitle>
            <Field
              component={Input}
              name={ChangePlanNames.companyName}
              placeholder="Company name"
              required={true}
            />
          </Section>
          <Section>
            <SectionTitle>
              How many members will you add to your team?
            </SectionTitle>
            <SectionDescription>
              You can add or remove members anytime
            </SectionDescription>
            <Field
              component={Input}
              name={ChangePlanNames.quantity}
              type="number"
              min="1"
              step="1"
            />
          </Section>
        </CorporateSection>
      )}
      <SubmitButton type="submit" $disabled={loading}>
        {loading ? 'Please wait…' : 'Confirm Change'}
      </SubmitButton>
    </Container>
  );
};

export default connect(Form);
