import { Selector } from 'redux/modules/types';
import { createSelector } from 'reselect';
import { ActivityState } from './types';

const selectState: Selector<ActivityState> = ({ activity }) => activity;

export const selectActivity = createSelector(selectState, ({ byId, ids }) =>
  ids.map((id) => byId[id]!).filter((it): it is NonNullable<typeof it> => !!it),
);

export const selectActivityPageCount = createSelector(
  selectState,
  (state) => state.pageCount,
);
