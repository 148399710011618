import {
  Button as DefaultButton,
  ShareButton as DefaultShareButton,
  StatusBadge as DefaultStatusBadge,
  Tags as DefaultTags,
} from 'components';
import DefaultAvatar from 'components/Avatar';
import DefaultImageListViewer from 'components/ImageListViewer';
import DefaultNavigation from 'components/Navigation';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import SubmitButton from './SubmitButton';

const IMAGE_OVERLAP = '1.5rem';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
`;

export const HeaderSection = styled.div`
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Header = styled.div`
  ${container};
  padding-bottom: ${IMAGE_OVERLAP};
`;

export const Navigation = styled(DefaultNavigation)`
  padding-right: ${({ theme }) => theme.wrapperPaddingH.mobile};
  padding-left: ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding-right: ${({ theme }) => theme.wrapperPaddingH.desktop};
    padding-left: ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
  > :first-child {
    padding: 1rem 0;
  }
`;

export const AuthorRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  ${from.tablet`
    flex-direction:row;
  `};
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  line-height: 2.5rem;
  ${from.mobileXL`
    font-size: 2.5rem;
  `};
`;

export const Avatar = styled(DefaultAvatar)`
  margin-right: 0.8rem;
  height: 2.75rem;
  width: 2.75rem;
`;

export const AvatarContainer = styled.div`
  display: flex;
`;

export const Author = styled.span`
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.grey400};
`;

export const AuthorName = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};
  text-decoration: none;

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

export const AuthorContainer = styled.div`
  margin-top: -0.25rem;
`;

export const Tags = styled(DefaultTags)`
  margin-top: ${({ theme }) => theme.spacing.xsm};
`;

export const ShareStatusContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  min-width: max-content;
  > :last-child {
    margin-left: 0;
  }
  ${from.tablet`
    flex-direction: row;
    >:last-child {
      margin-left: ${({ theme }) => theme.spacing.md};
    }
  `};
`;

export const ChallengeSection = styled.div`
  padding: 1rem ${({ theme }) => theme.wrapperPaddingH.mobile} 0;

  ${from.mobileXL`
    padding: 1rem ${({ theme }) => theme.wrapperPaddingH.desktop} 0;
  `};
`;

export const Challenge = styled.div`
  ${container};
  padding-bottom: 3.75rem;
`;

export const ImageListViewer = styled(DefaultImageListViewer)`
  width: 100%;
  object-fit: cover;
  margin-top: -${IMAGE_OVERLAP};
  border-radius: 0.25rem;
  height: auto;

  img {
    height: fit-content;
    max-height: 24.63rem;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const InstructionSection = styled.div`
  margin: 2.5rem auto 0 auto;
  max-width: 48rem;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }
`;

export const InstructionTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-size: 1.125rem;
`;

export const Section = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 5rem ${({ theme }) => theme.wrapperPaddingH.mobile};

  ${from.mobileXL`
    padding: 5rem ${({ theme }) => theme.wrapperPaddingH.desktop};
  `};
`;

export const Content = styled.div`
  ${container};
`;

export const LastSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.grey100};
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile} 5rem;

  ${from.mobileXL`
    padding: 0 ${({ theme }) => theme.wrapperPaddingH.desktop} 10rem;
  `};
`;

export const HeadingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  justify-content: space-between;
`;

export const Heading = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.laptop`
    margin-bottom: 0;
  `};
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const SubmittedContent = styled.div``;

export const StatusBadge = styled(DefaultStatusBadge)`
  margin-left: 1.5rem;
  position: relative;
  padding: 12px 14px;
  pointer-events: none;
`;

export const ShareButton = styled(DefaultShareButton)`
  margin-left: ${({ theme }) => theme.spacing.md};
  div ~ div {
    left: -2.75rem;
    right: inherit;
    ${from.tablet`
    left: inherit;
    right: 0;
  `};
  }
`;

export const ShareTag = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

export const SubmitButtonTop = styled(SubmitButton)`
  margin-left: ${({ theme }) => theme.spacing.lg};
`;

export const BannerButton = styled(DefaultButton)`
  font-size: 0.8rem;
  line-height: 1.4;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  position: relative;

  ${from.mobileXL`
    font-size: 0.875rem;
    line-height: normal;
    padding-left: 1rem;
    padding-right: 1rem;
    
  `};
`;

export const FeaturedVideoTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  line-height: 2.125rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0 0 0.5rem 0;
`;

export const PremiumTagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.625rem;
  padding: 0.313rem 0.5rem;
  background-color: ${({ theme }) => theme.colors.basicGold};
  border-radius: 0.125rem;
`;

export const PremiumTag = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.036rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.judge};
  margin: 0;
`;
