export const parseOrder = (input: string) => {
  const matches = input.match(/(-)?(.+)/);

  if (!matches) {
    return {
      directionIsAsc: false,
      order: undefined,
    };
  }

  const [, negative, order] = matches;

  return {
    order,
    directionIsAsc: negative !== '-',
  };
};
