import { Conversation as DefaultConversation } from 'components';
import SearchOptions from 'containers/Common/SearchOptions';
import { FirstGroupFields } from 'containers/Common/SearchOptions/Form/styles';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Conversation = styled(DefaultConversation)``;
export const Container = styled.div``;
export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const SearchOptionsConversations = styled(SearchOptions)`
  ${FirstGroupFields} {
    ${from.laptop`
    grid-column: 1/2;
    `}
  }
`;
