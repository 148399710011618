import { styles as input } from 'components/RawInput/styles';
import styled, { css } from 'styled-components';

export const HTMLTextArea = styled.textarea<{ error: boolean }>`
  ${input};
  resize: none;
  ${({ error }) =>
    error &&
    css`
      border: solid 1px ${({ theme }) => theme.colors.error};
    `}
`;
