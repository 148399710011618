import React, { FC, memo } from 'react';
import { Container, Input, SearchIcon } from './styles';
import { Props } from './types';

const SearchInput: FC<Props> = ({
  className,
  entity,
  input: { name, ...input },
  meta,
  ...rest
}) => (
  <Container className={className} name={name} meta={meta}>
    <Input
      name={name}
      placeholder={`Search ${entity}...`}
      {...input}
      {...rest}
    />
    <SearchIcon />
  </Container>
);

export default memo(SearchInput);
