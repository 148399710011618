import React, { FC, Fragment, memo, useCallback, useState } from 'react';
import FeaturePromotion from './FeaturePromotion';
import PlanPromotion from './PlanPromotion';
import {
  Container,
  Features,
  Link,
  LongTermFeatures,
  Plans,
  PremiumTabCorporate,
  PremiumTabIndividual,
  PremiumTypeTabs,
  PromotionInfo,
  SpecialOfferBadge,
  Subtitle,
  Subtitle2,
  TabText,
  Title,
  Title2,
  Wrapper,
} from './styles';
import { Props } from './types';

const MonthlyFeatures = () => (
  <Features>
    <FeaturePromotion>Live virtual events</FeaturePromotion>
    <FeaturePromotion>Office hours</FeaturePromotion>
    <FeaturePromotion>On-demand videos</FeaturePromotion>
    <FeaturePromotion>Exclusive content</FeaturePromotion>
    <FeaturePromotion>Early access</FeaturePromotion>
    <FeaturePromotion>Special discounts</FeaturePromotion>
  </Features>
);

const AnnualFeatures = () => (
  <Fragment>
    <Features>
      <FeaturePromotion>All monthly premium benefits</FeaturePromotion>
    </Features>
    <LongTermFeatures>
      <SpecialOfferBadge>Special offer!</SpecialOfferBadge>
      <PromotionInfo>
        <span>Choose ONE</span>
        <span>of the following</span>
      </PromotionInfo>
      <FeaturePromotion>
        <span>
          50% discount on registration to a workshop in 2023 (choose from a{' '}
          <Link
            href="http://www.storytellingwithdata.com/public-workshops"
            target="_blank"
          >
            variety of options
          </Link>
          )
        </span>
      </FeaturePromotion>
      <FeaturePromotion>
        <span>
          Signed copies of best-selling books{' '}
          <i>storytelling with you, storytelling with data</i>, and{' '}
          <i>let’s practice!</i>
        </span>
      </FeaturePromotion>
      <FeaturePromotion>
        <span>Dedicated 30-minute 1:1 virtual chat with Cole</span>
      </FeaturePromotion>
    </LongTermFeatures>
  </Fragment>
);

const LifeTimeFeatures = () => (
  <Fragment>
    <Features>
      <FeaturePromotion>All monthly premium benefits</FeaturePromotion>
    </Features>
    <LongTermFeatures>
      <SpecialOfferBadge>Special offer!</SpecialOfferBadge>
      <PromotionInfo>
        <span>Receive ALL </span>
        <span>of the following</span>
      </PromotionInfo>
      <FeaturePromotion>
        <span>
          50% discount on registration to a workshop in 2023 (choose from a{' '}
          <Link
            href="http://www.storytellingwithdata.com/public-workshops"
            target="_blank"
          >
            variety of options
          </Link>
          )
        </span>
      </FeaturePromotion>
      <FeaturePromotion>
        <span>
          Signed copies of best-selling books{' '}
          <i>storytelling with you, storytelling with data</i>, and{' '}
          <i>let’s practice!</i>
        </span>
      </FeaturePromotion>
      <FeaturePromotion>
        <span>Dedicated 30-minute 1:1 virtual chat with Cole</span>
      </FeaturePromotion>
    </LongTermFeatures>
  </Fragment>
);

const PricingPromotion: FC<Props> = ({ isAlreadyPremium, plans, ...rest }) => {
  const [isCorporate, setIsCorporate] = useState(false);

  const setCorporateValueTrue = useCallback(() => setIsCorporate(true), [
    setIsCorporate,
  ]);
  const setCorporateValueFalse = useCallback(() => setIsCorporate(false), [
    setIsCorporate,
  ]);

  const getPlanPeriod = useCallback((period: string) => {
    switch (period) {
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Annual';
      default:
        return 'Lifetime';
    }
  }, []);

  const getPlanFeatures = useCallback(
    (period: string) => {
      switch (period) {
        case 'month':
          return MonthlyFeatures();
        case 'year':
          return !isCorporate ? AnnualFeatures() : MonthlyFeatures();
        default:
          return LifeTimeFeatures();
      }
    },
    [isCorporate],
  );

  return (
    <Container {...rest}>
      <Wrapper>
        <Title>Choose a plan</Title>
        <Subtitle>
          Become a premium member and get unlimited access to resources you
          won’t find anywhere else.
        </Subtitle>
        <PremiumTypeTabs>
          <PremiumTabIndividual
            $activeTab={!isCorporate}
            onClick={setCorporateValueFalse}
          >
            <TabText $activeTab={!isCorporate}>Individual Plan</TabText>
          </PremiumTabIndividual>
          <PremiumTabCorporate
            $activeTab={isCorporate}
            onClick={setCorporateValueTrue}
          >
            <TabText $activeTab={isCorporate}>Group Plan</TabText>
          </PremiumTabCorporate>
        </PremiumTypeTabs>
        {!isCorporate && (
          <Fragment>
            <Title2>Exclusive sign up offer!</Title2>
            <Subtitle2>Available through January 31st</Subtitle2>
          </Fragment>
        )}
        <Plans>
          {plans
            .filter((plan) => plan.metadata?.disabled === 'false')
            .map(
              (plan, i) =>
                !(!plan.period && isCorporate) && (
                  <PlanPromotion
                    disabled={isAlreadyPremium}
                    id={plan.id}
                    index={i}
                    isCorporate={isCorporate}
                    key={plan.id}
                    period={getPlanPeriod(plan.period)}
                    price={plan.price}
                    savings={plan.savings}
                    subtitle={
                      isCorporate
                        ? `per seat per ${plan.period}`
                        : plan.period
                        ? `per ${plan.period}`
                        : 'one time'
                    }
                    features={getPlanFeatures(plan.period)}
                  />
                ),
            )}
        </Plans>
      </Wrapper>
    </Container>
  );
};

export default memo(PricingPromotion);
