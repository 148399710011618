import { FormNameMap } from 'utils/forms';

export const SEARCH_OPTIONS_FORM_KEY = 'forms/SearchOptions';

export interface SearchOptionsForm {
  aboutExpire: boolean;
  author: string | null;
  conversationTopic: string | null;
  directionIsAsc: boolean;
  exerciseTags: string | null;
  graphIndustry: string | null;
  graphTools: string | null;
  graphTopic: string | null;
  graphTypes: string | null;
  isUnsolved: boolean;
  order: string;
  query: string | null;
  solved: string | null;
  swdTeam: boolean;
  videoSeries: string | null;
  videoPresenters: string | null;
}

export const SearchOptionsFieldNames: FormNameMap<SearchOptionsForm> = {
  aboutExpire: 'aboutToExpire',
  author: 'author',
  conversationTopic: 'conversationTopic',
  directionIsAsc: 'directionIsAsc',
  exerciseTags: 'exerciseTags',
  graphIndustry: 'graphIndustry',
  graphTools: 'graphTools',
  graphTopic: 'graphTopic',
  graphTypes: 'graphTypes',
  isUnsolved: 'isUnsolved',
  order: 'order',
  query: 'query',
  solved: 'solved',
  swdTeam: 'swdTeam',
  videoSeries: 'videoSeries',
  videoPresenters: 'videoPresenters',
};
