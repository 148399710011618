import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectUserId } from 'redux/modules/auth';
import {
  getChallengeResponse,
  selectChallengeLoading,
  selectChallengeResponseBySlug,
} from 'redux/modules/challengeResponse';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { challengeSlug, submissionSlug },
    path,
    url,
  } = useRouteMatch<{ challengeSlug: string; submissionSlug: string }>();

  const bySlug = useSelector(selectChallengeResponseBySlug);
  const loading = useSelector(selectChallengeLoading);
  const userId = useSelector(selectUserId);

  const actions = useActions({
    getSubmission: getChallengeResponse.request,
  });

  const submission = bySlug[submissionSlug];

  return {
    ...actions,
    challengeSlug,
    loading,
    path,
    submission,
    submissionSlug,
    url,
    userIsAuthor: userId === submission?.user.id,
  };
};
