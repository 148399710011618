import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ActionCreatorsMapObject, bindActionCreators } from 'redux';

export function useActions<A, M extends ActionCreatorsMapObject<A>>(
  actions: M,
) {
  const dispatch = useDispatch();

  // We expect actions to never change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => bindActionCreators(actions, dispatch), [dispatch]);
}
