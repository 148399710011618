import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { selectUserId } from 'redux/modules/auth';
import { selectConversationTags } from 'redux/modules/configuration';
import {
  getConversation,
  selectConversationsById,
  selectConversationsLoading,
} from 'redux/modules/conversation';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const {
    params: { id },
    path,
    url,
  } = useRouteMatch<{ id: string }>();

  const byId = useSelector(selectConversationsById);
  const loading = useSelector(selectConversationsLoading);
  const tags = useSelector(selectConversationTags);
  const userId = useSelector(selectUserId);

  const actions = useActions({
    getConversation: getConversation.request,
  });

  const conversation = byId[id];

  return {
    ...actions,
    conversation,
    id,
    loading,
    path,
    tags,
    url,
    userIsAuthor: userId === conversation?.user.id,
  };
};
