import { useMediaQuery } from '@material-ui/core';
import { Loader } from 'components';
import FeedbackRequestView from 'containers/Common/FeedbackRequestView';
import {
  getFeedbackRequestStatusList,
  getUserName,
  isFeedbackRequestClosed,
} from 'model';
import React, { FC, useCallback, useEffect } from 'react';
import { from } from 'styles/mixins';
import { getUserType } from 'utils/getUserType';
import { up } from 'utils/Paths';
import connect from './connect';
import { Modal } from './styles';
import { Props } from './types';

const Preview: FC<Props> = ({
  feedbackRequest,
  getFeedbackRequest,
  id,
  loading,
  push,
  url,
}) => {
  const fullScreen = !useMediaQuery(from.tablet.query);

  useEffect(() => {
    if (id) {
      getFeedbackRequest({ id });
    }
  }, [id, getFeedbackRequest]);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  const shareButtonMessage = `Thought this was interesting from the SWD community. Check it out! - ${feedbackRequest?.title}: `;

  return (
    <Modal
      open={!!id}
      animation="fade"
      onClose={onClose}
      scroll={fullScreen ? undefined : 'body'}
      fullScreen={fullScreen}
    >
      {feedbackRequest && url ? (
        <FeedbackRequestView
          authorAvatar={feedbackRequest.user.avatar}
          authorId={feedbackRequest.user.id}
          authorName={getUserName(feedbackRequest.user)}
          authorType={getUserType(feedbackRequest.user)}
          authorSlug={feedbackRequest.user.slug}
          closed={isFeedbackRequestClosed(feedbackRequest)}
          comments={feedbackRequest.commentsCount}
          context={feedbackRequest.context}
          date={feedbackRequest.created}
          expiryDate={feedbackRequest.expiryDate}
          id={feedbackRequest.id}
          images={
            feedbackRequest.imageList
              ? [
                  feedbackRequest.imageList.mainImage,
                  ...feedbackRequest.imageList.extraImages,
                ]
              : undefined
          }
          isReported={feedbackRequest.isReported}
          isVoted={feedbackRequest.isVoted}
          open={!!id}
          scroll={true}
          shareButtonMessage={shareButtonMessage}
          statusList={getFeedbackRequestStatusList(feedbackRequest)}
          tags={feedbackRequest.tags}
          title={feedbackRequest.title}
          titleClickable={true}
          url={`/feedback/${feedbackRequest.id}`}
          views={feedbackRequest.hits}
          votes={feedbackRequest.votesCount}
        />
      ) : (
        loading && <Loader />
      )}
    </Modal>
  );
};

export default connect(Preview);
