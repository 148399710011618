import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { getHeatMap, selectHeatMapsBySlug } from 'redux/modules/users';
import { useActions } from 'utils/useActions';
import { Props } from './types';

export const useConnect = ({ user }: { user: Props['user'] }) => {
  const { params, path, url } = useRouteMatch<{ userSlug?: string }>();

  const heatMapsBySlug = useSelector(selectHeatMapsBySlug);

  const actions = useActions({
    getHeatMap: getHeatMap.request,
  });

  const heatMapData = heatMapsBySlug[user.slug];

  return {
    heatMapData,
    params,
    path,
    url,
    ...actions,
  };
};
