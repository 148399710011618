import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';

export default accessibleSvg(({ children, ...props }) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="39"
    height="37"
    viewBox="0 0 39 37"
  >
    <defs>
      <rect id="italic-b" width="29" height="27" x="0" y="0" rx="4" />
      <filter
        id="italic-a"
        width="158.6%"
        height="163%"
        x="-29.3%"
        y="-24.1%"
        filterUnits="objectBoundingBox"
      >
        <feMorphology
          in="SourceAlpha"
          radius="1.5"
          result="shadowSpreadOuter1"
        />
        <feOffset dy="2" in="shadowSpreadOuter1" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="4"
        />
        <feComposite
          in="shadowBlurOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0.28627451   0 0 0 0 0.31372549   0 0 0 0 0.341176471  0 0 0 0.400841346 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5 3)">
      <use fill="#000" filter="url(#italic-a)" xlinkHref="#italic-b" />
      <rect
        width="28"
        height="26"
        x=".5"
        y=".5"
        fill="#FFF"
        stroke="#297FD5"
        strokeLinejoin="inherit"
        rx="4"
      />
      <path
        fill="#297FD5"
        fillRule="nonzero"
        d="M13.598338,8.85463151 C13.6168053,8.79017322 13.6306555,8.73157565 13.6398892,8.67883705 C13.6491229,8.62609845 13.6537396,8.57629052 13.6537396,8.52941176 C13.6537396,8.36533612 13.5967996,8.23935135 13.4829178,8.15145368 C13.3690361,8.06355602 13.1920603,8.01081821 12.9519852,7.99323867 L11.8716528,7.92292089 L12.0840259,7 L19,7 L18.8522622,7.92292089 L17.790397,8.01081812 C17.5811008,8.02839765 17.424131,8.08406534 17.3194829,8.17782285 C17.2148348,8.27158037 17.1194218,8.46788221 17.033241,8.76673428 L14.4293629,18.1453685 C14.4108956,18.2039669 14.3970453,18.2625645 14.3878116,18.3211629 C14.378578,18.3797614 14.3739612,18.4354291 14.3739612,18.4881677 C14.3739612,18.6522433 14.4262845,18.7752982 14.5309326,18.857336 C14.6355807,18.9393739 14.8079397,18.9891818 15.0480148,19.0067613 L16.2391505,19.0858688 L16.0914127,20 L9,20 L9.16620499,19.0858688 L10.2188366,18.9891819 C10.4158212,18.9774622 10.5681743,18.9232594 10.6759003,18.826572 C10.7836263,18.7298846 10.8805782,18.5321178 10.966759,18.2332657 L13.598338,8.85463151 Z"
      />
    </g>
  </svg>
));
