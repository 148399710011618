import { differenceInSeconds } from 'date-fns';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import isAfter from 'date-fns/isAfter';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';

const now = Date.now();

const formatter = (fn: (date: Date) => string) => (date: string | Date) => {
  try {
    return fn(typeof date === 'string' ? parseISO(date) : date);
  } catch (e) {
    return 'Invalid date';
  }
};

export const amaDate = (amaDate: string) => {
  try {
    const date = format(parseISO(amaDate), 'LLLL do');
    return `${date}`;
  } catch (e) {
    return 'Invalid Date';
  }
};

export const amaTime = (start: string, end: string) => {
  try {
    const newStart = format(parseISO(start), 'hh a');
    const newEnd = format(parseISO(end), 'hh a');
    return `${newStart} - ${newEnd}`;
  } catch (e) {
    return 'Invalid Time';
  }
};

export const formatDate = formatter((date) => format(date, 'MM/dd/yy'));

export const formatDateAndTime = formatter((date) =>
  format(date, 'MM/dd/yy · hh:mm a'),
);

export const formatDayAndMonthName = formatter((date) => format(date, 'd MMM'));

export const formatMonthName = formatter((date) => format(date, 'MMM'));

export const formatElapsedTime = formatter((date) =>
  isToday(date) ? 'today' : formatDistanceToNow(date, { addSuffix: true }),
);

export const formatMonthNameAndDay = formatter((date) =>
  format(date, 'LLLL d'),
);

export const formatMonthNameDayandYear = formatter((date) =>
  format(date, 'LLLL d, yyyy'),
);

export const formatMonthNameAndYear = formatter((date) =>
  format(date, 'LLLL yyyy'),
);

export const formatDayOfWeek = formatter((date) => format(date, 'EEEE'));

export const formatHour = formatter((date) => format(date, 'h:mm a'));

export function dateHasPassed(date: string): boolean {
  return isAfter(now, parseISO(date));
}

export function isExpiringSoon(date?: string): boolean {
  if (!date) {
    return false;
  }
  const difference = differenceInSeconds(parseISO(date), now);
  return difference <= 259200 && difference > 0;
}

export function closedOrExpiring(date: string) {
  let tag = '';
  if (dateHasPassed(date)) {
    tag = 'Closed';
  } else if (isExpiringSoon(date)) {
    tag = 'Ending soon!';
  } else {
    return tag;
  }
}

export function formatDuration(totalSeconds: number) {
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60) % 60;
  const hours = Math.floor(totalSeconds / 3600) % 24;
  const parts = [seconds, minutes];
  if (hours > 0) parts.push(hours);
  return parts
    .reverse()
    .map((value) => value.toString().padStart(2, '0'))
    .join(':');
}

export async function getTimeZoneName() {
  if ('Intl' in window) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return import('moment-timezone')
    .then((moment) => moment.tz.guess())
    .catch(() => 'UTC');
}
