import { Activity } from '.';
import { userMock } from '../User';

export const activityMock: Activity = {
  slug: 'test',
  created: new Date().toISOString(),
  id: 'test',
  user: userMock,
  message: 'Test message',
  targetObject: {
    title: 'Title test',
    id: 'test id',
    model: 'challengeresponse',
    url: 'https://www.google.es',
  },
};
