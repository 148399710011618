import { Checkbox, FormActions } from 'components';
import { SettingsFormNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import connect from './connect';
import { BooleanInput, Container, Description, Grid } from './styles';
import { Props } from './types';

const Form: FC<Props> = ({ handleSubmit, invalid, pristine, submitFailed }) => (
  <Container onSubmit={handleSubmit}>
    <Description>Send me an email when there is a...</Description>
    <Grid>
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newBlog}
        label="New blog post"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newPodcast}
        label="New podcast episode"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newChallenge}
        label="New challenge"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newExercise}
        label="New exercise"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newWorkshop}
        label="Workshop in my city"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newCommentsMyFeedback}
        label="New comment on my feedback request"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newCommentsMyChallengeResponse}
        label="New comment on my challenge"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newCommentsMyExerciseResponse}
        label="New comment on my exercise"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newCommentsMyConversation}
        label="New comment on my conversation"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newCommentsOnMyComment}
        label="Response to my comments"
      />
      <Field
        CheckboxComponent={Checkbox}
        component={BooleanInput}
        name={SettingsFormNames.newMention}
        label="New mention"
      />
    </Grid>
    <FormActions
      invalid={invalid}
      pristine={pristine}
      submitFailed={submitFailed}
      submitLabel="Save changes"
    />
  </Container>
);

export default connect(Form);
