import { createEditorState } from 'components/Editor';
import { isLoading } from 'components/MediaInput';
import {
  AddToGalleryForm,
  ADD_TO_GALLERY_FORM_KEY,
  UPLOAD_MEDIA_FIELD,
  visualTagInputsInitialValues,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const { selectIsPristine, selectValues } = makeFormSelectors<AddToGalleryForm>(
  ADD_TO_GALLERY_FORM_KEY,
);

const mapStateToProps = (state: RootState) => {
  const values = selectValues(state);

  return {
    industryTags: state.configuration.graphIndustryTags,
    isPristine: selectIsPristine(state),
    loading: state.feedback.loading || isLoading(values?.[UPLOAD_MEDIA_FIELD]),
    toolTags: state.configuration.graphTopicTags,
    topicTags: state.configuration.graphTopicTags,
    typeTags: state.configuration.graphTypeTags,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<AddToGalleryForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<AddToGalleryForm>({
    form: ADD_TO_GALLERY_FORM_KEY,
    initialValues: {
      ...visualTagInputsInitialValues,
      [UPLOAD_MEDIA_FIELD]: [],
      context: createEditorState(),
      title: '',
    },
  }),
) as FormDecorator<AddToGalleryForm, OwnProps, ConnectedProps>;
