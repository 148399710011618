import {
  ImageAndText,
  ImageListViewer,
  RichText,
  ScrollBox,
  ShareButton,
} from 'components';
import Actions from 'containers/Common/PostActions';
import { Statuses } from 'model';
import React, { FC } from 'react';
import { formatDayAndMonthName } from 'utils/dates';
import {
  Container,
  DateClosed,
  FeaturedWord,
  ImageWrapper,
  PostAuthor,
  SectionTitle,
  ShareContainer,
  Title,
  TitleLink,
  TitleRow,
} from './styles';
import { Props } from './types';

const FeedbackRequestView: FC<Props> = ({
  authorAvatar,
  authorId,
  authorType,
  authorName,
  authorSlug,
  closed,
  comments,
  context,
  date,
  expiryDate,
  id,
  images,
  isReported,
  isVoted,
  open,
  scroll,
  shareButtonMessage,
  statusList,
  tags,
  title,
  titleClickable = false,
  url,
  views,
  votes,
  ...rest
}) => (
  <Container {...rest}>
    <TitleRow>
      {titleClickable ? (
        <TitleLink to={url}>
          <Title>{title}</Title>
        </TitleLink>
      ) : (
        <Title>{title}</Title>
      )}
      <ShareContainer>
        {expiryDate && (
          <>
            {closed && (
              <DateClosed>
                <FeaturedWord>Closed ·</FeaturedWord> ended{' '}
                {formatDayAndMonthName(expiryDate)}
              </DateClosed>
            )}
            {statusList.includes(Statuses.EXPIRES_SOON) && (
              <DateClosed>
                <FeaturedWord>Ending soon ·</FeaturedWord> deadline{' '}
                {formatDayAndMonthName(expiryDate)}
              </DateClosed>
            )}
            {!closed && !statusList.includes(Statuses.EXPIRES_SOON) && (
              <DateClosed>
                Deadline
                <FeaturedWord>{formatDayAndMonthName(expiryDate)}</FeaturedWord>
              </DateClosed>
            )}
          </>
        )}
        <ShareButton
          content={shareButtonMessage}
          url={`${window.location.origin}${url}`}
        />
      </ShareContainer>
    </TitleRow>
    <ScrollBox scroll={scroll}>
      <PostAuthor
        avatar={authorAvatar}
        comments={comments}
        date={date}
        id={authorId}
        name={authorName}
        slug={authorSlug}
        authorType={authorType}
        tags={tags}
        views={views}
      />
      <ImageAndText>
        <ImageWrapper>
          {images && (
            <ImageListViewer images={images} zoomUrl={`${url}/images`} />
          )}
        </ImageWrapper>
        {context && (
          <>
            <SectionTitle>What feedback would be useful?</SectionTitle>
            <RichText content={context} />
          </>
        )}
      </ImageAndText>
      <Actions
        authorId={authorId}
        contentType="feedback"
        id={id}
        isReported={isReported}
        isVoted={isVoted}
        url={url}
        votes={votes}
      />
    </ScrollBox>
  </Container>
);

export default FeedbackRequestView;
