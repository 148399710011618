import styled, { css } from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div<{
  show: boolean;
  iconWidth: string;
  rippleReach: string;
}>`
  ${({ iconWidth, rippleReach }) => css`
    border-radius: 50%;
    height: calc(${iconWidth} + ${rippleReach} * 2);
    margin-left: -${rippleReach};
    width: calc(${iconWidth} + ${rippleReach} * 2);
    display: flex;
    align-items: center;
    justify-content: center;
  `};

  ${({ show, theme }) =>
    show &&
    css`
      background-color: ${addAlpha(theme.colors.grey300, 0.2)};
    `};
`;
