import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from 'components/Button';
import React, { FC, useState } from 'react';
import { useSelectionAnchor } from 'utils/prosemirror';

import {
  useHandleClickAway,
  useHandleDelete,
  useHandleSubmit,
  useOnChangeHref,
} from './logic';
import { CheckIcon, Container, CrossIcon, Form, Title } from './styles';
import type { Props } from './types';

const Prompt: FC<Props> = ({ editorView, state }) => {
  const existingMark = state.selection.$from
    .marks()
    .find((mark) => mark.type === state.schema.marks.link);

  const [href, setHref] = useState(existingMark?.attrs.href ?? '');

  const onChangeHref = useOnChangeHref({ setHref });
  const onClickAway = useHandleClickAway({ editorView });
  const { onSubmit, saveLink } = useHandleSubmit({ editorView, href });
  const onDelete = useHandleDelete({ saveLink });

  const { left, top } = useSelectionAnchor({ editorView, state });

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Container $left={left} $top={top}>
        <Title>{existingMark ? 'Edit' : 'Add'} link</Title>
        <Form onSubmit={onSubmit}>
          <input
            autoFocus={true}
            name="href"
            onChange={onChangeHref}
            placeholder="https://"
            type="text"
            value={href}
          />
          <Button type="submit">
            <CheckIcon />
          </Button>
          {existingMark && (
            <Button onClick={onDelete} type="button">
              <CrossIcon />
            </Button>
          )}
        </Form>
      </Container>
    </ClickAwayListener>
  );
};

export default Prompt;
