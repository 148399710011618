import { Input } from 'components';
import { ChangeEmailFieldNames } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { normalizeEmail } from 'utils/normalizeEmail';
import { required } from 'utils/validations';
import connect from './connect';
import {
  Container,
  CurrentEmailInput,
  FormActions,
  InputGroup,
  SecondaryEmailInputContainer,
} from './styles';
import { Props } from './types';

const Form: FC<Props> = ({
  currentEmail,
  handleSubmit,
  invalid,
  loading,
  pristine,
  secondaryEmail,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <InputGroup>
      <Field
        component={CurrentEmailInput}
        label="CURRENT EMAIL"
        name="Current email"
        placeholder={currentEmail}
        type="email"
        disabled={true}
      />
      <Field
        component={Input}
        label="NEW EMAIL"
        name={ChangeEmailFieldNames.email}
        placeholder="New email"
        type="email"
        validate={required}
        normalize={normalizeEmail}
      />
      <Field
        component={Input}
        label="CONFIRM NEW EMAIL"
        name={ChangeEmailFieldNames.emailConfirm}
        placeholder="Confirm new email"
        type="email"
        validate={required}
        normalize={normalizeEmail}
      />
    </InputGroup>
    {secondaryEmail && (
      <SecondaryEmailInputContainer>
        <Field
          component={CurrentEmailInput}
          label="SECONDARY EMAIL"
          name="Secondary email"
          placeholder={secondaryEmail}
          type="email"
          disabled={true}
        />
      </SecondaryEmailInputContainer>
    )}

    <FormActions
      invalid={invalid}
      loading={loading}
      pristine={pristine}
      submitFailed={submitFailed}
    />
  </Container>
);

export default connect(Form);
