import b64toBlob from 'b64-to-blob';
import { randomId } from 'utils/randomId';

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new TypeError());
      }
    };
    reader.onerror = (error) => reject(error);
  });

export const base64toFile = (base64: string) => {
  const blob = b64toBlob(
    base64.replace('data:image/jpeg;base64,', ''),
    'image/jpeg',
  );
  return new File([blob], `${randomId()}.jpg`, {
    type: 'image/jpeg',
  });
};
