import { SupportForm } from 'model';
import { Props } from './types';

export const handleFormSupportSubmitted = ({
  supportRequest,
}: {
  supportRequest: Props['supportRequest'];
}) => (values: SupportForm) => {
  supportRequest({
    inquiryType: values.inquiryType,
    issueDescription: values.issueDescription,
  });
};
