import ThreeBarsIcon from 'components/icons/ThreeBarsIcon';
import Link from 'components/Link';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const AchievementsContainer = styled.aside<{ row: boolean }>`
  min-width: 16.875rem;
  display: flex;
  flex-direction: column;

  ${({ row }) =>
    row &&
    css`
      ${from.tablet`
        flex-direction: row;
        > :not(:last-child) {
          margin-right: 4rem;
        }
      `}
    `}
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 2.5rem;
  margin: 0 0 ${({ theme }) => theme.spacing.sm};
`;

export const List = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;

  > :not(:last-child) {
    margin-bottom: 0.625rem;
  }
`;

export const BarsIcon = styled(ThreeBarsIcon)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.grey300};
  height: 1rem;
  width: 1rem;
  margin-right: 0.75rem;
  flex-shrink: 0;
`;

export const Item = styled(Link)<{ $done?: boolean; $isLinkable: boolean }>`
  border: solid 0.5px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.938rem ${({ theme }) => theme.spacing.lg} 0.938rem;
  transition: opacity 200ms ease;
  ${from.tablet`
    flex-direction: row;
  `}
  ${({ $done }) =>
    $done &&
    css`
      background-color: ${({ theme }) => theme.colors.grey100};
      border: none;

      ${BarsIcon} {
        color: ${({ theme }) => theme.colors.accent};
        background-color: ${({ theme }) => theme.colors.grey100};
      }
    `}
  ${({ $isLinkable }) =>
    $isLinkable
      ? css`
          :hover {
            opacity: 0.75;
          }
        `
      : css`
          pointer-events: none;
        `}
`;

export const ItemHeader = styled.div`
  width: 11.2rem;
  display: flex;
  align-items: center;
`;

export const ItemTitle = styled.h3`
  margin: 0;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  color: ${({ theme }) => theme.colors.black};
  line-height: normal;
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const Done = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
`;

export const ToDo = styled.div``;
