import { User } from '.';

const nestedSlugs = {
  pageCount: 2,
  slugs: ['1', '2'],
  loading: false,
};

const achievementsData = {
  granted: [
    {
      text: '3-challenge streak',
      type: 'challenge',
      date: '2019-09-02T14:00:00.000Z',
    },
    {
      text: 'first datapoint given!',
      type: 'datapoint',
      date: '2019-09-02T14:00:00.000Z',
    },
  ],
  next: [
    {
      text: '5-challenge streak',
      type: 'challenge',
      date: '2019-09-02T14:00:00.000Z',
    },
    {
      text: 'ten datapoints given!',
      type: 'datapoint',
      date: '2019-09-02T14:00:00.000Z',
    },
  ],
};

export const userMock: User = {
  slug: 'bertha-santiago',
  avatar: 'https://i.pravatar.cc/80?u=BerthaSantiago',
  email: 'bertha.santiago@example.com',
  firstName: 'Bertha',
  id: '903ee503-925c-426a-801f-e478acc06c35',
  lastName: 'Santiago',
  location: 'Moderdonia',
  bio:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, provident suscipit minima quis quidem veniam, error aliquam asperiores temporibus velit excepturi? Tempora explicabo alias repudiandae? Nulla ipsa numquam necessitatibus tempore!',
  goals:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora, provident suscipit minima quis quidem veniam, error aliquam asperiores temporibus velit excepturi? Tempora explicabo alias repudiandae? Nulla ipsa numquam necessitatibus tempore!',
  nBooks: 3,
  podcastFollower: true,
  wAttended: true,
  challenges: 3,
  datapointsGiven: 3,
  exercises: 1,
  feedbacks: 5,
  feedbacksComments: 15,
  comments: 4,
  votes: 21,
  socialWeb: 'https://example.com',
  instagramURL: 'https://instagram.com/instagram/',
  twitterURL: 'https://twitter.com/twitter',
  secondaryEmail: null,
  socialInstagram: 'instagram',
  socialLinkedin: 'https://linkedin.com/blahblah',
  socialTwitter: 'twitter',
  companyName: 'Z1',
  companyRole: 'CEO',
  isPremium: false,
  isPremiumGroupMember: false,
  isPremiumGroupOwner: false,
  gallery: 2,
  liked: 2,
  conversations: 3,
  gallerySlugs: nestedSlugs,
  feedbackResponseSlugs: nestedSlugs,
  exerciseResponseSlugs: nestedSlugs,
  challengeResponseSlugs: nestedSlugs,
  conversationSlugs: nestedSlugs,
  likedSlugs: nestedSlugs,
  spotlight: undefined,
  swdTeam: false,
  showActivityInfo: true,
  showProfessionalInfo: true,
  showSocialInfo: true,
  achievements: achievementsData,
  challengeStreak: 3,
  firstLogin: false,
  dateJoined: '1990-06-01',
  ranking: 1,
  dataPoints: 190,
  uploads: 4,
  followers: 52,
  courses: [],
};

export const newUserMock: User = {
  ...userMock,
  firstLogin: true,
};
