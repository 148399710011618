import Feature from 'components/Pricing/Feature';
import React, { FC } from 'react';
import { Redirect } from 'react-router';
import { up } from 'utils/Paths';
import { useConnect } from './connect';
import {
  ArrowLink,
  ButtonsContainer,
  Container,
  Features,
  InfoContainer,
  InfoParagraph,
  InfoTitle,
  PremiumButtonsContainer,
  PremiumIcon,
  UpgradeButton,
} from './styles';
import { Props } from './types';

const Form: FC<Props> = ({ ...rest }) => {
  const { isPremium, isPremiumGroupMember, url } = useConnect();

  if (isPremium || isPremiumGroupMember) {
    return <Redirect to={`${up(url)}/subscription`} />;
  }

  // On InfoParagraph tag: to show real best pay per month, use {lowestPrice} (import to useConnect)

  return (
    <Container {...rest}>
      <PremiumIcon />
      <InfoContainer>
        <InfoTitle>Upgrade to keep learning with SWD</InfoTitle>
        <InfoParagraph>
          For less than $35 a month, you will gain access to:
        </InfoParagraph>
        <Features>
          <Feature>Live virtual events</Feature>
          <Feature>Office hours</Feature>
          <Feature>On-demand videos</Feature>
          <Feature>Exclusive content</Feature>
          <Feature>Early access</Feature>
          <Feature>Special discounts</Feature>
        </Features>
        <ButtonsContainer>
          <ArrowLink text="Learn More" to="/premium" />
          <PremiumButtonsContainer>
            <UpgradeButton variant={'premiumAccent'} to="/subscribe">
              Go premium!
            </UpgradeButton>
            <UpgradeButton
              variant={'premiumSecondary'}
              to="/subscribe/corporate"
            >
              Create a group account
            </UpgradeButton>
          </PremiumButtonsContainer>
        </ButtonsContainer>
      </InfoContainer>
    </Container>
  );
};

export default Form;
