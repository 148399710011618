import { FormActions as DefaultFormActions, Input } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  display: grid;
  ${from.laptop`
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
    grid-column: span 2;
  `}
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SecondaryEmailInputContainer = styled.div`
  grid-row: 1;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
  margin-bottom: 34px;
  ${from.laptop`
    grid-row: auto;
    border-bottom: none;
    margin-bottom: 0;
  `}
`;

export const CurrentEmailInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.grey100};
  border: solid 1px ${({ theme }) => theme.colors.grey100};
  :hover {
    box-shadow: none;
  }
`;

export const FormActions = styled(DefaultFormActions)`
  grid-row: auto;
  ${from.laptop`
    grid-row: 2;
  `}
`;
