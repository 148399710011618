import { PageTitle } from 'components';
import React, { FC, useCallback } from 'react';
import { useConnect } from './connect';
import Form from './Form';
import { handleFormSubmitted } from './logic';
import { Container, Header, Paragraph, Title } from './styles';
import { Props } from './types';

const AskFeedback: FC<Props> = () => {
  const { askFeedback } = useConnect();

  const onFormSubmitted = useCallback(handleFormSubmitted({ askFeedback }), [
    askFeedback,
  ]);

  return (
    <Container>
      <PageTitle title="Ask for feedback" />
      <Header>
        <Title>ask for feedback</Title>
        <Paragraph>
          Getting input from others can help us understand what is working
          well—and where further iteration may be needed. Submit a request for
          feedback to get input from the community to refine your visual from
          good to great!
        </Paragraph>
      </Header>
      <Form onSubmit={onFormSubmitted} />
    </Container>
  );
};

export default AskFeedback;
