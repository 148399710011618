import defaultAvatar from 'assets/images/defaultAvatar.png';
import Link from 'components/Link';
import React, { FC, memo } from 'react';
import { Container, Picture, SWDOverlayBadge } from './styles';
import { Props } from './types';

const Avatar: FC<Props> = ({
  profile,
  name = '',
  src,
  variant = 'default',
  to,
  ...props
}) => {
  const srcUrl = src ? src : defaultAvatar;
  const variantAlt =
    variant === 'swd'
      ? 'SWD team'
      : variant === 'premium'
      ? 'Premium member'
      : 'member';
  return (
    <Container
      swdTeamOrPremium={variant === 'swd' || variant === 'premium'}
      {...props}
    >
      {to ? (
        <Link to={to}>
          <Picture src={srcUrl} alt={`${name} ${variantAlt}`} />
        </Link>
      ) : (
        <Picture src={srcUrl} alt={`${name} ${variantAlt}`} />
      )}
      {variant !== 'default' && (
        <SWDOverlayBadge big={!!profile} variant={variant} />
      )}
    </Container>
  );
};

export default memo(Avatar);
