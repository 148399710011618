// TODO replace these icons with our own
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdLink,
} from 'react-icons/md';
import styled, { css } from 'styled-components';

export const Container = styled.div``;

const iconSize = css`
  height: 1.5rem;
  width: 1.5rem;
`;

export const StrongIcon = styled(MdFormatBold)`
  ${iconSize};
`;

export const EmphasisIcon = styled(MdFormatItalic)`
  ${iconSize};
`;

export const LinkIcon = styled(MdLink)`
  ${iconSize};
`;

export const QuoteIcon = styled(MdFormatQuote)`
  ${iconSize};
`;

export const BulletListIcon = styled(MdFormatListBulleted)`
  ${iconSize};
`;

export const OrderedListIcon = styled(MdFormatListNumbered)`
  ${iconSize};
`;
