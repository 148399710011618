import produce from 'immer';
import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import { VIDEOS_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import {
  getProminentVideo,
  getVideo,
  getVideos,
  updateVideoProgress,
} from './actions';
import { VideoAction, VideoState } from './types';

export const INITIAL_STATE: VideoState = {
  ...defaultStandardState,
  prominentVideo: undefined,
  prominentVideoLoading: false,
  video: undefined,
  videoLoading: false,
};

const reducer: Reducer<VideoState, VideoAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getProminentVideo.request):
      return {
        ...state,
        prominentVideoLoading: true,
      };
    case getType(getProminentVideo.success):
      return {
        ...state,
        prominentVideo: action.payload,
        prominentVideoLoading: false,
      };
    case getType(getProminentVideo.failure):
      return {
        ...state,
        prominentVideoLoading: false,
      };
    case getType(getVideo.request):
      return {
        ...state,
        videoLoading: true,
      };
    case getType(getVideo.success):
      return {
        ...state,
        video: action.payload,
        videoLoading: false,
      };
    case getType(getVideo.failure):
      return {
        ...state,
        videoLoading: false,
      };
    case getType(updateVideoProgress):
      const { id, progress } = action.payload;
      return produce(state, (next) => {
        const video = next.byId[id];
        if (!video) return;
        video.progress = progress;
      });
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getVideos.request),
      success: isActionOf(getVideos.success),
      failure: isActionOf(getVideos.failure),
    },
  },
  contractItem: ({ id, slug }) => ({ id, slug }),
  initialState: INITIAL_STATE,
  perPage: VIDEOS_PER_PAGE,
})(reducer);
