import { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { RootState } from 'redux/modules/types';
import {
  SEARCH_CHALLENGE_RESPONSE_PER_PAGE_DETAIL,
  SEARCH_CHALLENGE_RESPONSE_PER_PAGE_MASTER,
} from 'utils/config';
import { getPageCount } from 'utils/getPageCount';
import { setSearchSectionPage } from '../../../../../../redux/modules/search';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const isDetail = matchPath(
    ownProps.location.pathname,
    '/search/challenges/responses',
  );

  return {
    count: state.search.count.challengeResponse,
    items: state.search.ids.challengeResponse
      .map((id) => state.search.byId.challengeResponse[id])
      .filter((it): it is NonNullable<typeof it> => !!it),
    loading: state.search.loading.challengeResponse,
    page: state.search.page.challengeResponse,
    pageCount: getPageCount(
      state.search.count.challengeResponse,
      isDetail
        ? SEARCH_CHALLENGE_RESPONSE_PER_PAGE_DETAIL
        : SEARCH_CHALLENGE_RESPONSE_PER_PAGE_MASTER,
    ),
  };
};

const mapDispatchToProps = {
  setPage: (page: SetStateAction<number>) =>
    setSearchSectionPage({
      page,
      section: 'challengeResponse',
    }),
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
