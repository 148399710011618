import styled from 'styled-components';

export const Container = styled.form`
  padding: 2.3125rem 1.875rem 1.875rem 1.875rem;
`;

export const Title = styled.h1`
  font-size: 1.25rem;
  margin-bottom: 2.375rem;
`;

export const CheckContainer = styled.div`
  > div:not(:last-child) {
    margin-bottom: 1rem;
  }

  > div:last-child {
    margin-bottom: 1.5rem;
  }
`;

export const CommentsContainer = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-left: 0;
  width: fit-content;

  * + * {
    margin-bottom: 1rem;
    margin-left: 0;
  }

  @media (min-width: 390px) {
    flex-direction: row;
    margin-left: auto;

    * + * {
      margin-bottom: 0;
      margin-left: 2.25rem;
    }
  }
`;
