import styled from 'styled-components';

export const Background = styled.path`
  color: ${({ theme }) => theme.colors.grey100};
`;

export const BodyPart = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;

export const Bubble = styled.path`
  color: ${({ theme }) => theme.colors.grey300};
`;

export const BubbleBackground = styled.path`
  color: ${({ theme }) => theme.colors.white};
`;

export const QuestionMark = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;
