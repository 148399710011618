import ArrowLink from 'components/ArrowLink';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  align-items: center;
  display: grid;
  grid-template-rows: auto auto;
  grid-row-gap: 2.375rem;

  :not(:last-child) {
    margin-bottom: 5rem;
  }
  ${from.tablet`
    grid-template-columns: auto 16.875rem;
    grid-template-rows: auto;
    grid-column-gap: 4.375rem;
  `}
`;

export const Info = styled.div`
  grid-row: 2;
  grid-column: 1;
  ${from.tablet`
    grid-row: auto;
  `}
`;

export const Supertitle = styled.span`
  font-size: 0.875rem;
  letter-spacing: 0.44px;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  margin-bottom: 1.25rem;
  margin-top: ${({ theme }) => theme.spacing.xsm};
  ${from.tablet`
    font-size: 1.75rem;
  `}
`;

export const Summary = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  ${from.tablet`
    font-size: 1.25rem;
  `}
`;

export const Description = styled(Summary)`
  color: ${({ theme }) => theme.colors.grey400};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const Link = styled(ArrowLink)`
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0;
  margin: 0.5rem 0 0;
  text-transform: none;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.accent};
  display: inline-flex;

  span {
    font-weight: ${({ theme }) => theme.weights.semiBold};
  }
`;

export const ImageContainer = styled.div`
  width: 75%;
  margin: 0 auto;
  ${from.mobileXL`
    width: 55%;
  `}
  ${from.tablet`
    width: 100%;
    margin: 0;
  `}
`;
