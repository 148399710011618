import {
  LiveEventDetails as DefaultLiveEventDetails,
  LiveEventReminder as DefaultLiveEventReminder,
  Modal,
} from 'components';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(({ booked, ...props }) => (
  <Modal {...props} />
))<{ booked: boolean }>`
  & .MuiPaper-root {
    transition: width 300ms;

    ${({ booked }) =>
      booked
        ? css`
            ${from.laptop`
              width: 47.5rem;   
            `};
          `
        : css`
            ${from.laptop`
              width: 64.5rem;   
            `};
          `}
  }
`;

export const Content = styled.div`
  padding: ${({ theme }) => theme.wrapperPaddingH.mobile};
  ${from.tablet`
    padding: 3.75rem;
  `}
`;

const animation = {
  animate: 'shown',
  initial: 'hidden',
  exit: 'hidden',
  variants: {
    hidden: { opacity: 0 },
    shown: { opacity: 1 },
  },
};

export const LiveEventDetails = styled(
  motion.custom(DefaultLiveEventDetails),
).attrs(animation)``;

export const LiveEventReminder = styled(
  motion.custom(DefaultLiveEventReminder),
).attrs(animation)``;
