import { PageTitle } from 'components';
import { ONBOARDING_SECTIONS } from 'model';
import React, { FC, useCallback } from 'react';
import { matchPath } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import ActivityInfo from './ActivityInfo';
import BasicInfo from './BasicInfo';
import { useConnect } from './connect';
import GroupCode from './GroupCode';
import { handleSubmit } from './logic';
import Navigation from './Navigation';
import ProfessionalInfo from './ProfessionalInfo';
import SocialInfo from './SocialInfo';
import { Container, Description, Section, Title } from './styles';
import { Props } from './types';

const Onboarding: FC<Props> = () => {
  const {
    editProfile,
    path,
    pathname,
    push,
    search,
    setGroupCode,
    state,
    url,
  } = useConnect();

  const match = matchPath<{ section: string }>(pathname, `${path}/:section`);
  const section = match?.params?.section;

  const onSubmit = useCallback(
    handleSubmit({ setGroupCode, editProfile, push, search, section, url }),
    [setGroupCode, editProfile, push, search, section, url],
  );

  if (!state?.onboardingAllowed) {
    return <Redirect to="/404" />;
  }

  return (
    <Container>
      <PageTitle title="Welcome to the SWD community" />
      <Title>Welcome to the SWD community</Title>
      <Description>
        Take 2 minutes to fill in your info so we can get to know you!
      </Description>
      <Route component={Navigation} path={`${path}/:section`} />
      <Section
        isGroupCode={section === 'group-code'}
        isSWDInfo={section === 'activity-info'}
      >
        <Switch>
          <Route path={`${path}/${ONBOARDING_SECTIONS[0]}`}>
            <GroupCode onSubmit={onSubmit} />
          </Route>
          <Route path={`${path}/${ONBOARDING_SECTIONS[1]}`}>
            <BasicInfo onSubmit={onSubmit} />
          </Route>
          <Route path={`${path}/${ONBOARDING_SECTIONS[2]}`}>
            <ProfessionalInfo onSubmit={onSubmit} />
          </Route>
          <Route path={`${path}/${ONBOARDING_SECTIONS[3]}`}>
            <SocialInfo onSubmit={onSubmit} />
          </Route>
          <Route path={`${path}/${ONBOARDING_SECTIONS[4]}`}>
            <ActivityInfo onSubmit={onSubmit} />
          </Route>
          <Redirect
            to={{
              search,
              state,
              pathname: `${path}/${ONBOARDING_SECTIONS[0]}`,
            }}
          />
        </Switch>
      </Section>
    </Container>
  );
};

export default Onboarding;
