import { oneLine } from 'common-tags';
import { PlaceholderSubmittedContent } from 'components';
import ExerciseSubmissionView from 'containers/Common/ExerciseSubmissionView';
import ProtectedButton from 'containers/Common/ProtectedButton';
import { getUserName } from 'model';
import React, { FC, memo } from 'react';
import { getUserType } from 'utils/getUserType';

import { Props } from './types';

const Preview: FC<Props> = ({
  authUserIsSwdTeam,
  submission,
  submissionSlug,
  exerciseSlug,
}) => {
  return submission.authUserSubmittedResponse || authUserIsSwdTeam ? (
    <ExerciseSubmissionView
      authorAvatar={submission.user.avatar}
      authorId={submission.user.id}
      authorType={getUserType(submission.user)}
      authorName={getUserName(submission.user)}
      authorSlug={submission.user.slug}
      comments={submission.commentsCount}
      created={submission.created}
      description={submission.description}
      id={submission.id}
      media={
        submission.mediaList
          ? [submission.mediaList.mainMedia, ...submission.mediaList.extraMedia]
          : []
      }
      isReported={submission.isReported}
      isVoted={submission.isVoted}
      open={!!submissionSlug}
      scroll={true}
      shareButtonMessage={oneLine`Thought this was interesting from the SWD
          community. Check it out! - ${submission.title}: `}
      tags={submission.tags}
      title={submission.title}
      titleClickable={true}
      url={`/exercises/${exerciseSlug}/${submission.slug}`}
      views={submission.hits}
      votes={submission.votesCount}
    />
  ) : (
    <PlaceholderSubmittedContent id="exercise-not-submitted">
      <ProtectedButton to={`/exercises/${exerciseSlug}`}>
        Let's Practice
      </ProtectedButton>
    </PlaceholderSubmittedContent>
  );
};

export default memo(Preview);
