import { oneLine } from 'common-tags';
import { EditorInput, FormActions } from 'components';
import { NewCommentFieldNames, UPLOAD_MEDIA_FIELD } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container, MediaInput, Row } from './styles';
import { Props } from './types';

const CommentForm: FC<Props> = ({
  editId,
  filesLoading,
  handleSubmit,
  invalid,
  isCommentReply = false,
  isPristine,
  loading,
  onClose,
  onDelete,
  reset,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <Row small={isCommentReply}>
      <Field
        component={EditorInput}
        name={NewCommentFieldNames.comment}
        placeholder={oneLine`Type your comment or question here.`}
        validate={required}
      />
      <Field
        component={MediaInput}
        compact={isCommentReply}
        label={!isCommentReply && 'Upload image (optional)'}
        onlyImage={true}
        maxFiles={1}
        name={NewCommentFieldNames[UPLOAD_MEDIA_FIELD]}
      />
    </Row>
    <FormActions
      invalid={invalid}
      loading={loading || filesLoading}
      loadingLabel={filesLoading ? 'Loading...' : 'Posting...'}
      onClose={onClose}
      onDelete={onDelete}
      pristine={isPristine}
      reset={editId ? reset : undefined}
      submitFailed={submitFailed}
      submitLabel={editId ? 'Update comment' : 'Post comment'}
    />
  </Container>
);

export default connect(CommentForm);
