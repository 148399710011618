import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { randomId } from 'utils/randomId';
import {
  hideSnackbar,
  pushFromQueue,
  showSnackbar,
  SnackbarAction,
  SnackbarState,
} from './';

export const INITIAL_STATE: SnackbarState = {
  queue: [],
  active: undefined,
};

const reducer: Reducer<SnackbarState, SnackbarAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(showSnackbar):
      const { active, queue } = state;
      const newSnackbar = { id: randomId(), ...action.payload };
      if (active) {
        return {
          ...state,
          queue: [...queue, newSnackbar],
        };
      }
      return {
        ...state,
        active: newSnackbar,
      };
    case getType(hideSnackbar):
      return {
        ...state,
        active: undefined,
      };
    case getType(pushFromQueue):
      const newActive = !!state.queue.length ? state.queue[0] : null;
      if (!newActive) {
        return state;
      }

      return {
        active: newActive,
        queue: state.queue.filter(({ id }) => id !== newActive.id),
      };

    default:
      return state;
  }
};

export default reducer;
