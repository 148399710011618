import {
  AddMembersCorporatePremiumForm,
  ADD_MEMBERS_CORPORATE_PREMIUM_FORM_KEY,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  ConfigProps,
  FormErrors,
  getFormSubmitErrors,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import { addMembers } from 'redux/modules/premium';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const selectErrors = getFormSubmitErrors(
  ADD_MEMBERS_CORPORATE_PREMIUM_FORM_KEY,
) as (state: RootState) => FormErrors<AddMembersCorporatePremiumForm>;

const mapStateToProps = (state: RootState) => {
  const initialValues: ConfigProps<
    AddMembersCorporatePremiumForm
  >['initialValues'] = {
    emails: '',
  };
  return {
    initialValues,
    emailError: selectErrors(state)?.emails,
  };
};

const mapDispatchToProps = {
  addMembers: addMembers.request,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<AddMembersCorporatePremiumForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<AddMembersCorporatePremiumForm>({
    form: ADD_MEMBERS_CORPORATE_PREMIUM_FORM_KEY,
  }),
) as FormDecorator<AddMembersCorporatePremiumForm, OwnProps, ConnectedProps>;
