export const isDegrade = ({
  currentPlanInterval,
  period,
  isCorporateCard,
  currentPlanType,
}: {
  currentPlanInterval?: 'month' | 'year' | 'lifetime';
  period: string;
  isCorporateCard: boolean;
  currentPlanType: 'individual' | 'corporate';
}) => {
  // We don't allow degrade (annually to monthly) except in the case individual-annually to corporate-monthly
  if (
    currentPlanInterval === 'year' &&
    period === 'month' &&
    isCorporateCard &&
    currentPlanType === 'individual'
  ) {
    return false;
  }
  return (
    (currentPlanInterval === 'year' && period === 'month') ||
    (currentPlanType === 'corporate' && !isCorporateCard)
  );
};
