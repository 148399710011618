import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ArrowLink, Results, ResultsHeader, ResultsTitle } from '../../styles';
import connect from './connect';
import Proposals from './Proposals';
import Responses from './Responses';
import {
  ResultsSubtitle,
  Separator,
  SubNavigation,
  SubNavLink,
} from './styles';
import { Props } from './types';

const Challenges: FC<Props> = ({
  available,
  challengeCount,
  challengeResponseCount,
  challengeResponsesLoading,
  challengesLoading,
  isDetail,
  showChallengeResponses,
  showChallenges,
  ...rest
}) => {
  const {
    location: { search },
    match: { path, url },
  } = rest;

  if (!available && !challengesLoading && !challengeResponsesLoading) {
    return null;
  }

  return (
    <Results>
      <ResultsHeader>
        <ResultsTitle>
          Challenges{' '}
          {challengesLoading && challengeResponsesLoading
            ? ''
            : `(${challengeCount + challengeResponseCount})`}
        </ResultsTitle>
        {!isDetail && (
          <ArrowLink
            text="See all the results: challenges"
            to={{
              search,
              pathname: `${url}/challenges`,
            }}
          />
        )}
      </ResultsHeader>
      {isDetail ? (
        <>
          <SubNavigation>
            {showChallenges && (
              <SubNavLink to={{ search, pathname: `${url}/proposals` }}>
                Challenge proposals{' '}
                {challengesLoading ? '' : `(${challengeCount})`}
              </SubNavLink>
            )}
            {showChallenges && showChallengeResponses && (
              <Separator aria-hidden="true" />
            )}
            {showChallengeResponses && (
              <SubNavLink to={{ search, pathname: `${url}/responses` }}>
                Challenge responses{' '}
                {challengeResponsesLoading ? '' : `(${challengeResponseCount})`}
              </SubNavLink>
            )}
          </SubNavigation>
          <Switch>
            {showChallenges && (
              <Route component={Proposals} path={`${path}/proposals`} />
            )}
            {showChallengeResponses && (
              <Route component={Responses} path={`${path}/responses`} />
            )}
            {showChallenges && (
              <Redirect to={{ search, pathname: `${path}/proposals` }} />
            )}
            {showChallengeResponses && (
              <Redirect to={{ search, pathname: `${path}/responses` }} />
            )}
          </Switch>
        </>
      ) : (
        <>
          {showChallenges && (
            <>
              <ResultsSubtitle>
                Challenge proposals{' '}
                {challengesLoading ? '' : `(${challengeCount})`}
              </ResultsSubtitle>
              <Proposals {...rest} />
            </>
          )}
          {showChallengeResponses && (
            <>
              <ResultsSubtitle>
                Challenge responses{' '}
                {challengeResponsesLoading ? '' : `(${challengeResponseCount})`}
              </ResultsSubtitle>
              <Responses {...rest} />
            </>
          )}
        </>
      )}
    </Results>
  );
};

export default connect(Challenges);
