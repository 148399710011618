import { FormActions as DefaultFormActions } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  margin: 0 auto;

  ${from.laptop`
    margin:0;
  `}
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 1rem;
  margin-bottom: 3rem;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 26rem;
`;

export const FormActions = styled(DefaultFormActions)`
  max-width: 26rem;
`;
