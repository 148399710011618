import React, { FC, memo } from 'react';
import { Container, Counter, CounterRectangle, Icon, Label } from './styles';
import { Props } from './types';

const FiltersButton: FC<Props> = ({ count, ...rest }) => (
  <Container type="button" {...rest}>
    <Label>Filters</Label>
    {count > 0 ? (
      <CounterRectangle>
        <Counter>{count}</Counter>
      </CounterRectangle>
    ) : (
      <Icon />
    )}
  </Container>
);

export default memo(FiltersButton);
