import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { selectVideoTypesTags } from 'redux/modules/configuration';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import {
  getVideos,
  selectVideoLoading,
  selectVideoPageCount,
  selectVideos,
} from 'redux/modules/video';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { hash, search, state } = useLocation();
  const { url } = useRouteMatch();

  const loading = useSelector(selectVideoLoading);
  const pageCount = useSelector(selectVideoPageCount);
  const videos = useSelector(selectVideos);
  const videoTypes = useSelector(selectVideoTypesTags);
  const wentBack = useSelector(selectUserWentBack);

  const actions = useActions({
    getVideos: getVideos.request,
  });

  return {
    ...actions,
    hash,
    loading,
    pageCount,
    search,
    state,
    url,
    videos,
    videoTypes,
    wentBack,
  };
};
