import {
  Pagination as DefaultPagination,
  Tabs as DefaultTabs,
} from 'components';
import { Tab } from 'components/Tabs/styles';
import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { from, title, viewContainer } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';
import DefaultMembersTable from './MembersTable';
import DefaultOutstandingMembers from './OutstandingMembers';
import DefaultSearchOptions from './SearchOptions';

export const Container = styled.div``;

export const HeaderShadow = styled.div`
  box-shadow: -13px 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
`;

export const Header = styled.div`
  ${viewContainer}
  position: relative;
  padding: 24px 16px 0;
  margin-bottom: 0;
  margin-top: 0;

  ${from.laptop`
    padding: 0 ${({ theme }) => theme.spacing.lg};
    margin-top: 2rem;
  `};
`;

export const Heading = styled.div`
  margin: 0 auto;
  max-width: 560px;
  text-align: center;
`;

export const Title = styled.h1`
  ${title};
  margin: 0;
`;

export const Paragraph = styled.p`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.weights.regular};
  line-height: 30px;
  text-align: center;
  margin: 1.25rem 0 1rem;

  ${from.laptop`
    margin: 1.25rem 0 2.5rem;
  `};
`;

export const Tabs = styled(DefaultTabs)`
  order: -1;
  width: 100%;

  ${from.tablet`
    width: auto;
    order: 0;
    justify-self: center;
    margin-bottom: 2rem;

    ${Tab} {
      width: 100%;

      span {
        width: 100%;
        justify-content: center;
      }
    }
  `};

  ${from.laptop`
    margin-top: -10px;
    margin-bottom: 0;
  `};
`;

export const Content = styled.div`
  ${viewContainer}
  padding: 0 ${({ theme }) => theme.wrapperPaddingH.mobile} 0;
  margin-bottom: 6.875rem;
  margin-top: 1.25rem;

  ${from.laptop`
    padding: 0 ${({ theme }) => theme.spacing.lg};
  `};
`;

export const OutstandingMembers = styled(DefaultOutstandingMembers)``;

export const Options = styled.div``;

export const SearchOptions = styled(DefaultSearchOptions)``;

export const MembersTable = styled(DefaultMembersTable)``;

export const MemberGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  margin-top: 2rem;
  grid-row-gap: 1rem;
  grid-column-gap: 0.75rem;

  ${from.mobileXL`
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));                                                
    grid-column-gap: 2rem;
    grid-row-gap: 1.25rem;
  `}
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const Pagination = styled(DefaultPagination)`
  flex-wrap: wrap;
  row-gap: 0.3rem;
`;
