import styled from 'styled-components';

export const Container = styled.label`
  color: ${({ theme }) => theme.colors.black};
  display: block;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0.57px;
  line-height: 1.23rem;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  margin-left: ${({ theme }) => theme.spacing.xsm};
  text-transform: uppercase;
`;
