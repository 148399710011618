import React, { forwardRef, memo } from 'react';
import { Container } from './styles';
import { Props } from './types';

const ScrollBox = forwardRef<HTMLDivElement, Props>(
  ({ children, scroll = false, ...rest }, ref) => (
    <Container ref={ref} scroll={scroll} {...rest}>
      {children}
    </Container>
  ),
);

export default memo(ScrollBox);
