import { MenuItem } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { Column } from '../../SubscriptionRoot/styles';
import {
  Avatar,
  ButtonContainer,
  Content,
  Dot,
  DropdownContainer,
  Header,
  MoreInfoButton,
  Name,
  RemoveButton,
  ResendButton,
  Row,
  StateTag,
  StatusColumn,
} from './styles';
import { Props } from './types';

const SubscriptionSeatRow: FC<Props> = ({
  email,
  avatar,
  name,
  status,
  onDelete,
  resendInvite,
  openDropdown,
  closeDropdown,
  anchorEl,
  id,
}) => {
  const handleDelete = useCallback(() => {
    onDelete && onDelete(id);
  }, [onDelete, id]);

  const handleResendInvite = useCallback(() => {
    resendInvite && resendInvite(email);
    closeDropdown();
  }, [closeDropdown, resendInvite, email]);

  return (
    <Row>
      <Header>
        <Avatar src={avatar} variant="default" />
        <Name>{name}</Name>
      </Header>
      <Content>
        <Column>{email}</Column>
        <StatusColumn>
          <StateTag $isActive={status === 'Active'}>{status}</StateTag>
        </StatusColumn>
      </Content>
      <ButtonContainer>
        <MoreInfoButton
          onClick={openDropdown}
          id={`more-info-${id}`}
          aria-controls={`more-info-${id}`}
          aria-haspopup="true"
        >
          <Dot />
          <Dot />
          <Dot />
        </MoreInfoButton>
        <DropdownContainer
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && anchorEl?.id === `more-info-${id}`}
          onClose={closeDropdown}
          transformOrigin={{ vertical: -50, horizontal: 115 }}
          keepMounted={true}
        >
          <MenuItem disableRipple={true}>
            <ResendButton
              variant="link"
              onClick={handleResendInvite}
              disabled={status === 'Active'}
              $isDisabled={status === 'Active'}
            >
              Resend invite
            </ResendButton>
          </MenuItem>
          <MenuItem disableRipple={true}>
            <RemoveButton variant="danger" onClick={handleDelete}>
              Remove
            </RemoveButton>
          </MenuItem>
        </DropdownContainer>
      </ButtonContainer>
    </Row>
  );
};

export default SubscriptionSeatRow;
