/**
 * @param obj An object
 * @param key A key of that object
 * @return A copy of the object without property identified by the specified key
 */
export function without<T extends object, Key extends keyof T>(
  obj: T,
  key: Key,
): Omit<T, Key> {
  const newObj = { ...obj };
  delete newObj[key];
  return (newObj as unknown) as Omit<T, Key>;
}
