import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.xlg};
  }
`;

export const Display = styled.div`
  align-items: center;
  border-radius: 0.325rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const FlipCardTop = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.325rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  height: 2rem;
  width: 4rem;
`;

export const FlipCardBottom = styled.div`
  background-color: ${({ theme }) => theme.colors.grey100};
  border-radius: 0.325rem;
  height: 2rem;
  width: 4rem;
`;

export const Value = styled.span`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  position: absolute;
  top: 1rem;
`;

export const Unit = styled.span`
  font-size: 1.1375rem;
  letter-spacing: 0;
  margin-top: 0.375rem;
  position: absolute;
  top: 100%;
`;
