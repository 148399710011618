import type { Node as ProsemirrorNode } from 'prosemirror-model';
import { Step, StepResult } from 'prosemirror-transform';

export class SetPlaceholderStep extends Step {
  public readonly placeholder: string;

  constructor(placeholder: string) {
    super();
    this.placeholder = placeholder;
  }

  apply(doc: ProsemirrorNode) {
    return StepResult.ok(doc);
  }

  invert() {
    return this;
  }

  map() {
    return this;
  }

  toJSON() {
    return {};
  }
}
