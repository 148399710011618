import { SecondaryEmailForm, SECONDARY_EMAIL_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = ({ auth }: RootState) => ({
  loading: auth.loading,
});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<SecondaryEmailForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<SecondaryEmailForm>({
    form: SECONDARY_EMAIL_FORM_KEY,
    initialValues: {
      email: '',
      password: '',
    },
  }),
) as FormDecorator<SecondaryEmailForm, OwnProps, ConnectedProps>;
