import { Select, Toggle } from 'components';
import {
  subscribedToPodcastOptions,
  swdBooksReadOptions,
  workshopAttendedOptions,
} from 'containers/Main/Members/Profile/UserDetail/Settings/FormEditProfile';
import { EditProfileFormNames } from 'model';
import React, { FC } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Field } from 'redux-form';
import SectionFooter from '../SectionFooter';
import connect from './connect';
import {
  BooleanInput,
  Container,
  Content,
  Info,
  Title,
  TitleSection,
} from './styles';
import { Props } from './types';

const ActivityInfo: FC<Props> = ({ handleSubmit, valid }) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();

  return (
    <Container onSubmit={handleSubmit}>
      <TitleSection>
        <Title>SWD info</Title>
        <Info>
          <Field
            CheckboxComponent={Toggle}
            component={BooleanInput}
            name={EditProfileFormNames.showActivityInfo}
            label="Show this info on my public profile"
          />
        </Info>
      </TitleSection>
      <Content>
        <Field
          component={Select}
          name={EditProfileFormNames.nBooks}
          options={swdBooksReadOptions}
          label="How many SWD books have you read?"
        />
        <Field
          component={Select}
          name={EditProfileFormNames.podcastFollower}
          options={subscribedToPodcastOptions}
          label="Are you subscribed to the SWD podcast?"
        />
        <Field
          component={Select}
          name={EditProfileFormNames.wAttended}
          options={workshopAttendedOptions}
          label="Have you attended any SWD workshops?"
        />
      </Content>
      <SectionFooter
        baseUrl={url}
        canSubmit={valid}
        search={search}
        section="activity-info"
      />
    </Container>
  );
};

export default connect(ActivityInfo);
