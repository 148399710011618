import memoize from 'lodash/memoize';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { useCallback, useEffect, useMemo } from 'react';
import { ReferenceProvider } from '../../types';
import { setOptions, setSelectedIndexByNumber } from '../actions';
import { getCurrentReferenceNode } from '../logic';
import type { Props } from './types';

export const useFetchOptions = ({
  editorView,
  getProvider,
  state,
}: {
  editorView: Props['editorView'];
  getProvider: Props['getProvider'];
  state: Props['state'];
}) => {
  /*
   * We memoize to avoid requesting the same information twice. We memoize
   * inside the prompt component rather that at the plugin top level because we
   * want the cache to be cleared as soon as the the prompt is unmounted (if we
   * open the prompt five minutes later, we don't want stale results).
   *
   * We DO NOT debounce here. It is the responsibility of the referenceProvider
   * to debounce, not ours. The referenceProvider might forward our requests to
   * the API, or it might forward them to the cache layer. In the latter case
   * we want to update the UI immediately.
   */
  const getOptions = useMemo(
    () =>
      // By default, memoize will use the first argument as the cache key, so
      // we don't need to worry about serializing the provider.
      memoize((query: string, provider: ReferenceProvider) =>
        provider.getOptions(query),
      ),
    [],
  );

  const text = useMemo(() => {
    const result = getCurrentReferenceNode(state);

    if (!result) {
      return '';
    }

    const { node } = result;
    return node.textContent.substr(1); // Remove the @
  }, [state]);

  useEffect(() => {
    const provider = getProvider(editorView.state);

    if (provider) {
      getOptions(text, provider)
        .then((options) => {
          setOptions(options)(editorView.state, editorView.dispatch);
        })
        .catch(console.error);
    }
  }, [editorView, getOptions, getProvider, text]);
};

export const useChangeIndexByHover = ({
  editorView,
  state,
}: {
  editorView: EditorView;
  state: EditorState;
}) =>
  useCallback(
    (index) => () => {
      setSelectedIndexByNumber(index)(state, editorView.dispatch);
    },
    [editorView, state],
  );
