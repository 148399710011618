import { oneLine } from 'common-tags';
import { Button, ModalRoute, OfficeHour } from 'components';
import PageTitle from 'components/PageTitle';
import Feature from 'components/Pricing/Feature';
import React, { FC, useEffect } from 'react';
import connect from './connect';
import Hour from './Hour';
import {
  Container,
  Content,
  ContentWrapper,
  CTA,
  CTATitle,
  Description,
  Features,
  Fold,
  Hours,
  Title,
} from './styles';
import { Props } from './types';

const OfficeHours: FC<Props> = ({
  getOfficeHours,
  hours,
  isPremium,
  match: { path, url },
  openPremiumInterruption,
}) => {
  const webpSupported = !!Modernizr.webp;

  useEffect(() => {
    getOfficeHours();
  }, [getOfficeHours]);

  const oneIsAlreadyBooked = hours.some((hour) => hour.booked);

  return (
    <Container>
      <PageTitle title="Office hours" />
      <Fold
        isPremium={isPremium}
        title="office hours"
        description={oneLine`Need a little personalized help? Join an upcoming office hour with an SWD data storyteller! Come prepared with specific questions and ready to share your work.`}
      />
      <ContentWrapper>
        <Content>
          {!isPremium && (
            <CTA webpSupported={webpSupported}>
              <CTATitle>
                Upgrade to premium membership for more ways to learn with SWD
              </CTATitle>
              <Features>
                <Feature>Live virtual events</Feature>
                <Feature>Office hours</Feature>
                <Feature>On-demand videos</Feature>
                <Feature>Exclusive content</Feature>
                <Feature>Early access</Feature>
                <Feature>Special discounts</Feature>
              </Features>
              <Button variant="premiumAccent" to="/premium">
                Go premium!
              </Button>
            </CTA>
          )}
          <Title>Join an upcoming office hour</Title>
          <Description>
            We use{' '}
            <a
              href="https://zoom.us/"
              rel="noreferrer noopener"
              target="_blank"
            >
              Zoom
            </a>{' '}
            for our office hours. Premium members can join any upcoming session.
            Click to register and receive a calendar invite.
          </Description>
          <Description>
            Whether you&apos;re interested in private feedback or eager to
            discuss your work in an open, collaborative environment—we&apos;ve
            got you covered. We structure each office hour to accommodate the
            needs and preferences of that session&apos;s attendees.
          </Description>
          <Hours>
            {hours.map(
              ({ booked, endDatetime, id, startDatetime, presenters }) => (
                <OfficeHour
                  booked={booked}
                  endDate={endDatetime}
                  key={id}
                  startDate={startDatetime}
                  disabled={oneIsAlreadyBooked && !booked}
                  {...(isPremium
                    ? {
                        to: `${url}/${id}`,
                      }
                    : { onClick: openPremiumInterruption })}
                  presenters={presenters}
                />
              ),
            )}
          </Hours>
        </Content>
      </ContentWrapper>
      <ModalRoute component={Hour} path={`${path}/:hourId`} />
    </Container>
  );
};

export default connect(OfficeHours);
