import { Footer as DefaultFooter } from 'components';
import { SWDLogoIcon as DefaultLogoBreadCrumb } from 'components/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const HomeLogo = styled(DefaultLogoBreadCrumb)`
  width: 20px;
  padding: 1px;
`;

export const Footer = styled(DefaultFooter)`
  margin-top: auto;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;

  > *:first-child {
    flex: 1;
  }
`;
