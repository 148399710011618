import styled from 'styled-components';

export const Pole = styled.path`
  color: ${({ theme }) => theme.colors.accent};
`;

export const Flag = Pole;

export const ExclamationMark = styled.path`
  color: ${({ theme }) => theme.colors.primary};
`;
