import {
  SearchMembersForm,
  SEARCH_MEMBERS_FORM_DEFAULT_VALUES,
  SEARCH_MEMBERS_FORM_KEY,
} from 'model';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConfigProps, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { execAll } from 'utils/execAll';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { submitOnChange } from 'utils/submitOnChange';
import { OwnProps } from './types';

const { selectIsPristine, selectValues } = makeFormSelectors<SearchMembersForm>(
  SEARCH_MEMBERS_FORM_KEY,
);

const defaultValues: SearchMembersForm = SEARCH_MEMBERS_FORM_DEFAULT_VALUES;

const mapStateToProps = (state: RootState) => {
  const location = state.router.location;
  const search = parse(location.search);

  const initialValues: ConfigProps<SearchMembersForm>['initialValues'] = {
    ...defaultValues,
    ...search,
  };

  const isPristine = selectIsPristine(state);

  const formValues = selectValues(state);

  return {
    initialValues,
    isPristine,
    formValues,
    location,
    loading: state.users.loading,
  };
};

const mapDispatchToProps = {
  submit,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<SearchMembersForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<SearchMembersForm>({
    enableReinitialize: true,
    form: SEARCH_MEMBERS_FORM_KEY,
    destroyOnUnmount: false,
    onChange: execAll(submitOnChange<SearchMembersForm>('activityOrder')),
  }),
) as FormDecorator<SearchMembersForm, OwnProps, ConnectedProps>;
