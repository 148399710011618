import { ChallengeResponse } from '.';
import { mediaListMock } from '../MediaList';
import { userMock } from '../User';

export const challengeResponseMock: ChallengeResponse = {
  slug: 'test',
  addToMyGallery: false,
  challenge: '26c19345-6e66-460f-8d8e-2822e5d2bd41',
  challengeSlug: 'test',
  challengeName: 'Hello',
  commentsCount: 0,
  created: '2019-09-02T14:00:00.000Z',
  description: '',
  expiryDate: undefined,
  hits: 0,
  id: '75ffc4a7-7e8d-4127-9085-b6a31207b6ea',
  mediaList: mediaListMock,
  isAboutToExpire: false,
  isReported: false,
  isSelectedBySwdTeam: false,
  isVoted: false,
  lastComment: '2019-09-02T14:00:00.000Z',
  link: 'http://www.storytellingwithdata.com/blog',
  modified: '2019-09-02T14:00:00.000Z',
  status: 'Pending',
  swdTeam: false,
  tags: [],
  title: 'Test',
  user: userMock,
  votesCount: 45,
  comments: [],
  swdSolution: false,
  previousResponse: '7f9d9cb9-5201-4118-bdf6-a4cf83387d10',
  nextResponse: '7f9d9cb9-5201-4118-bdf6-a4cf83387d10',
};
