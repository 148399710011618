import styled from 'styled-components';

export const FeedbackCommentContainer = styled.div`
  color: ${({ theme }) => theme.colors.grey400};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const Image = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  height: 2.25rem;
  min-width: 2.25rem;
  flex-basis: 2.25rem;
  overflow: hidden;
`;

export const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  border-left: solid 1px ${({ theme }) => theme.colors.grey200};
  margin-left: ${({ theme }) => theme.spacing.sm};
  padding-left: ${({ theme }) => theme.spacing.sm};
`;

export const Info = styled.p`
  font-size: 0.875rem;
  margin: 0 0 ${({ theme }) => theme.spacing.xsm};
`;

export const Author = styled.span`
  color: ${({ theme }) => theme.colors.black};
  margin-right: ${({ theme }) => theme.spacing.xsm};
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  height: 48px;
  overflow: hidden;
`;
