import { PageTitle } from 'components';
import React, { FC } from 'react';
import {
  Button,
  Container,
  Content,
  ErrorImage,
  Link,
  Paragraph,
  Subtitle,
  Title,
} from './styles';

const NotFound: FC = () => (
  <>
    <Container>
      <PageTitle title="Page not found" />
      <Content>
        <ErrorImage />
        <Title>404 Error</Title>
        <Subtitle>
          Oops! We can’t find the page that you’re looking for.{' '}
        </Subtitle>
        <Paragraph>
          We’re very sorry for the inconvenience. It looks like you’re trying to
          access a page that either has been deleted or never even existed.
        </Paragraph>
        <Link to="/">
          <Button>Back to community</Button>
        </Link>
      </Content>
    </Container>
  </>
);

export default NotFound;
