import DefaultAvatar from 'components/Avatar';
import Button from 'components/Button';
import DefaultLoader from 'components/Loader';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.xlg};
`;

export const preview = css`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  height: 16.375rem;
  width: 16.375rem;
`;

export const Avatar = styled(DefaultAvatar)`
  ${preview};
  margin: 0;
  overflow: hidden;
  position: relative;

  img {
    border-radius: 0;
  }
`;

export const Loader = styled(DefaultLoader)`
  ${preview};
  flex: none;
  padding: 0;
`;

export const ChangeAvatar = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.lg};
  text-transform: uppercase;
  width: auto;
`;
