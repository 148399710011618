import ClickDropdown from 'components/ClickDropdown';
import styled from 'styled-components';

export const Container = styled(ClickDropdown)`
  > :first-child {
    padding: 0.35rem 0 0.1rem 0.5rem;
  }
  svg {
    margin: 0rem 0.75rem 0.15rem auto;
  }
`;

export const DropdownHeader = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.7875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.9px;
  line-height: 1;
  margin-right: 0.5rem;
  white-space: nowrap;
  text-transform: uppercase;
`;
