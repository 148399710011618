import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { connect } from 'react-redux';
import { saveToken } from 'redux/modules/auth';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  saveToken: saveToken.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const useConnect = ({ saveToken }: ConnectedProps) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');

  useEffect(() => {
    if (token) {
      saveToken({ token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return {
    token,
  };
};
