import React, { FC, memo, useRef } from 'react';
import { pluralize } from 'utils/pluralize';
import { useElementDimensions } from 'utils/useElementDimensions';
import { Container, Statistic, StatisticTitle, StatisticValue } from './styles';
import { Props } from './types';

const Statistics: FC<Props> = ({ comments, datapoints, views, ...rest }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { offsetWidth } = useElementDimensions(contentRef);

  return (
    <Container {...rest} ref={contentRef} offsetWidth={offsetWidth}>
      <Statistic>
        <StatisticValue>{datapoints}</StatisticValue>
        <StatisticTitle>
          {pluralize(datapoints, 'Datapoint', 'Datapoints')}
        </StatisticTitle>
      </Statistic>
      <Statistic>
        <StatisticValue>{comments}</StatisticValue>
        <StatisticTitle>
          {pluralize(comments, 'Comment', 'Comments')}
        </StatisticTitle>
      </Statistic>
      <Statistic>
        <StatisticValue>{views}</StatisticValue>
        <StatisticTitle>{pluralize(views, 'View', 'Views')}</StatisticTitle>
      </Statistic>
    </Container>
  );
};

export default memo(Statistics);
