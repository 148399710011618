import { createEditorState, parseHTML } from 'components/Editor';
import { ConversationForm, CONVERSATION_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { editId }: OwnProps) => {
  const form = CONVERSATION_FORM_KEY(editId);

  const { selectIsPristine } = makeFormSelectors<ConversationForm>(form);

  const conversationToEdit = state.conversation.byId[editId ?? ''];
  const tags = state.configuration.conversationTags.filter(
    (tag) => !tag.readOnly,
  );

  return {
    form,
    tags,
    initialValues: {
      description: createEditorState(),
      tags: [],
      title: '',
      ...(conversationToEdit
        ? {
            description: createEditorState(
              parseHTML(conversationToEdit.description),
            ),
            tags: conversationToEdit.conversationTopic,
            title: conversationToEdit.title,
          }
        : {}),
    },
    isPristine: selectIsPristine(state),
    loading: state.conversation.loading,
  };
};

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ConversationForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ConversationForm>({
    destroyOnUnmount: false,
  }),
) as FormDecorator<ConversationForm, OwnProps, ConnectedProps>;
