import React, { FC, useCallback } from 'react';
import connect from './connect';
import Form from './Form';
import { Props } from './types';

const MembersOrders: FC<Props> = ({
  hash,
  pathname,
  replace,
  search,
  handleFormSubmitted,
  ...rest
}) => {
  const onSubmit = useCallback(
    handleFormSubmitted({
      hash,
      pathname,
      replace,
      search,
    }),
    [hash, pathname, replace, search],
  );

  return <Form onSubmit={onSubmit} {...rest} />;
};

export default connect(MembersOrders);
