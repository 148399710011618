import { MediaFile } from 'components/MediaSelector/types';
import { DeepReadonly } from 'utility-types';

export interface MediaList {
  mainMedia: MediaFile;
  extraMedia: MediaFile[];
}

export * from './mock';

export function normalizeMediaList(mediaList: DeepReadonly<MediaList>) {
  function normalizeMedia(media: MediaFile) {
    return {
      file: media.file,
      status: 'uploaded' as const,
      thumbnail: media.thumbnail,
      mediaType: media.mediaType,
    };
  }

  return [
    normalizeMedia(mediaList.mainMedia),
    ...mediaList.extraMedia.map(normalizeMedia),
  ];
}
