import { Conversation } from 'model/Conversation';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { selectConversationTags } from 'redux/modules/configuration';
import { selectConversationsBySlug } from 'redux/modules/conversation';
import { getUserConversations } from 'redux/modules/users';
import { useActions } from 'utils/useActions';
import { Props } from './types';

export const useConnect = ({ user }: { user: Props['user'] }) => {
  const { search, state } = useLocation();
  const { path, url } = useRouteMatch<{ userSlug?: string }>();

  const conversationBySlug = useSelector(selectConversationsBySlug);
  const tags = useSelector(selectConversationTags);

  const actions = useActions({
    getUserConversations: getUserConversations.request,
  });

  return {
    ...actions,
    path,
    search,
    state,
    url,
    tags,
    loading: user ? user.conversationSlugs.loading : false,
    pageCount: user ? user.conversationSlugs.pageCount : 0,
    items: user
      ? (user.conversationSlugs.slugs
          .map((slug) => conversationBySlug[slug])
          .filter((it): it is NonNullable<typeof it> => !!it) as Conversation[])
      : [],
  };
};
