import styled from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  grid-column: 1/-1;
  padding-right: ${({ theme }) => theme.spacing.xlg};

  @media (min-width: 1200px) {
    grid-column: 1/1;
  }
`;

export const TitleContainer = styled.h2`
  border-left: 0.25rem solid ${({ theme }) => theme.colors.grey300};
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  margin-top: 0;
  flex-direction: column;
`;

export const Subtitle = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.8)};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  line-height: 0.88;
  margin-left: 0.8125rem;
  text-transform: uppercase;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 3.125rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0;
  margin-left: 0.8125rem;
  text-transform: lowercase;
`;

export const Description = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.black, 0.65)};
  font-size: 1.25rem;
  letter-spacing: 0;
  line-height: 1.5;
`;
