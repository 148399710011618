import { startOfDay } from 'date-fns';
import differenceInMonths from 'date-fns/differenceInMonths';
import parseISO from 'date-fns/parseISO';
import { RootState } from 'redux/modules/types';
import { createSelector } from 'reselect';

export const selectPremiumState = (state: RootState) => state.premium;

export const selectPremiumLoading = createSelector(
  selectPremiumState,
  (state) => state.loading,
);

export const selectMembers = createSelector(
  selectPremiumState,
  (state) => state.userInfo?.groupSubscription?.users,
);

export const selectMembersQuantity = createSelector(
  selectPremiumState,
  (state) => state.userInfo?.quantity,
);

export const selectPlanId = createSelector(
  selectPremiumState,
  (state) => state.userInfo?.planId,
);

export const selectUpdatePaymentMethodLoading = createSelector(
  selectPremiumState,
  (state) => state.updatePaymentMethodLoading,
);

export const selectInvoices = createSelector(
  selectPremiumState,
  ({ invoices }) =>
    invoices.map(({ periodStart, periodEnd, ...invoice }) => ({
      interval: (() => {
        const start = startOfDay(parseISO(periodStart));
        const end = startOfDay(parseISO(periodEnd));

        const diff = differenceInMonths(end, start);

        if (diff === 12) {
          return 'year';
        }

        return 'month';
      })(),
      ...invoice,
    })),
);

export const selectCouponInfo = createSelector(
  selectPremiumState,
  (state) => state.couponInfo,
);
