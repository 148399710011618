import BaseInput from 'components/BaseInput';
import { SearchIcon as DefaultSearchIcon } from 'components/icons';
import RawInput from 'components/RawInput';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(BaseInput)`
  display: inline;
  flex-grow: 1;
  position: relative;
  margin-bottom: 0;

  ${from.tablet`
    margin-bottom: 2.125rem;
`}
`;

export const Input = styled(RawInput)`
  box-sizing: border-box;
  padding-right: ${({ theme }) => theme.spacing.xlg};
`;

export const SearchIcon = styled(DefaultSearchIcon)`
  color: ${({ theme }) => theme.colors.accent};
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
`;
