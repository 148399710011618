import React, { FC } from 'react';
import { Check, Container } from './styles';
import { Props } from './types';

const Feature: FC<Props> = ({ children, ...rest }) => (
  <Container {...rest}>
    <Check />
    {children}
  </Container>
);

export default Feature;
