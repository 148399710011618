import styled from 'styled-components';

export const Input = styled.input`
  appearance: none;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  border: solid 1px ${({ theme }) => theme.colors.grey300};
  box-sizing: border-box;
  height: 1rem;
  margin-right: ${({ theme }) => theme.spacing.md};
  outline: none;
  position: relative;
  width: 1rem;

  :checked {
    border-color: ${({ theme }) => theme.colors.accent};
    background-color: ${({ theme }) => theme.colors.accent};

    :after {
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      content: '';
      height: 50%;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(-50%) translateY(50%);
      width: 50%;
    }
  }
`;
