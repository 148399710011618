import { MediaInput as DefaultMediaInput } from 'components';
import { DropZone } from 'components/MediaSelector/SingleFileVariant/styles';
import { Container as ImageSelectorContainer } from 'components/MediaSelector/styles';
import styled from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.form`
  display: flex;
`;

export const MediaInput = styled(DefaultMediaInput)`
  flex: 1;
  margin-bottom: 0;

  ${ImageSelectorContainer} {
    flex: 1;

    ${DropZone} {
      min-height: 6rem;
    }
  }

  &:focus-within {
    ${DropZone} {
      background-color: ${({ theme }) => addAlpha(theme.colors.accent, 0.1)};
      box-shadow: 0 6px 29px -10px ${({ theme }) => addAlpha(theme.colors.grey400, 0.4)};
    }
  }
`;
