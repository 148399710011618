import styled from 'styled-components';

import DefaultCheckIcon from 'components/icons/Check';
import DefaultTimesIcon from 'components/icons/Times';
import { ZIndex } from 'styles/metrics';

export const Container = styled.div<{
  $left: number;
  $top: number;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid #dfe6e8;
  box-shadow: 0 0.625rem 1.25rem rgba(219, 209, 189, 0.2);
  left: ${({ $left }) => $left}px;
  padding: 0.5rem;
  position: absolute;
  top: ${({ $top }) => $top}px;
  transform: translateX(-50%);
  z-index: ${ZIndex.EDITOR_LINK_PROMPT};
`;

export const Title = styled.h2`
  color: #211c12;
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.3;
  margin-block-end: 0.75rem;
  margin-block-start: 0;
`;

export const Form = styled.form`
  display: flex;

  * + * {
    margin-left: 0.5rem;
  }
`;

export const CheckIcon = styled(DefaultCheckIcon)`
  height: 1rem;
  width: 1rem;
`;

export const CrossIcon = styled(DefaultTimesIcon)`
  height: 1rem;
  width: 1rem;
`;
