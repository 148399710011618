import { Button } from 'components';
import {
  Actions,
  Label,
  Option,
  Options,
  Radio,
} from 'components/OfficeHourBooked/styles';
import { BookingNotification } from 'model';
import React, { forwardRef, memo, useCallback, useState } from 'react';
import { Container, Description, Title } from './styles';
import { Props } from './types';

const LiveEventReminder = forwardRef<HTMLDivElement, Props>(
  ({ onClose, onSaveReminder, ...rest }, ref) => {
    const [reminder, setReminder] = useState(
      BookingNotification.ONE_DAY_BEFORE,
    );

    const onChangeReminderTime = useCallback(
      (e) => {
        setReminder(e.target.value);
      },
      [setReminder],
    );

    const handleSaveReminder = useCallback(() => {
      onSaveReminder(reminder);
    }, [onSaveReminder, reminder]);

    return (
      <Container ref={ref} {...rest}>
        <Title>It's a date! Join us live.</Title>
        <Description>
          We’ll send you an email reminder with all the information you need to
          join the live event.
        </Description>
        <Options>
          <Option checked={reminder === BookingNotification.ONE_DAY_BEFORE}>
            <Radio
              checked={reminder === BookingNotification.ONE_DAY_BEFORE}
              id="reminder-time-1-day"
              name="reminder-time"
              onChange={onChangeReminderTime}
              readOnly={true}
              value={BookingNotification.ONE_DAY_BEFORE}
            />
            <Label htmlFor="reminder-time-1-day">1 day before</Label>
          </Option>
          <Option checked={reminder === BookingNotification.ONE_HOUR_BEFORE}>
            <Radio
              checked={reminder === BookingNotification.ONE_HOUR_BEFORE}
              id="reminder-time-1-hour"
              name="reminder-time"
              onChange={onChangeReminderTime}
              readOnly={true}
              value={BookingNotification.ONE_HOUR_BEFORE}
            />
            <Label htmlFor="reminder-time-1-hour">1 hour before</Label>
          </Option>
        </Options>
        <Actions>
          <Button onClick={handleSaveReminder}>Save reminder</Button>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
        </Actions>
      </Container>
    );
  },
);

export default memo(LiveEventReminder);
