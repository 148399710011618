import {
  ChangePasswordForm,
  CHANGE_PASSWORD_FORM_KEY,
  validateChangePasswordForm,
} from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState) => ({
  loading: state.auth.loading,
});

const mapDispatchToProps = {};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ChangePasswordForm>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ChangePasswordForm>({
    form: CHANGE_PASSWORD_FORM_KEY,
    initialValues: {
      oldPassword: '',
      password: '',
      passwordConfirm: '',
    },
    validate: validateChangePasswordForm,
  }),
) as FormDecorator<ChangePasswordForm, OwnProps, ConnectedProps>;
