import {
  BooleanInput,
  EditorInput,
  FormActions,
  Input,
  MediaInput,
  Toggle,
} from 'components';
import { Media } from 'components/MediaSelector/types';
import VisualTagInputs from 'containers/Common/VisualTagInputs';
import {
  ChallengeSubmissionFormFieldNames,
  FeedbackFieldNames,
  UPLOAD_MEDIA_FIELD,
} from 'model';
import React, { FC, useState } from 'react';
import { Field, GenericFieldHTMLAttributes } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container } from './styles';
import { Props } from './types';

const ChallengeSubmissionForm: FC<Props> = (props) => {
  const {
    editSlug,
    handleSubmit,
    invalid,
    isPristine,
    loading,
    onDelete,
    reset,
    submitFailed,
  } = props;

  const [isValidToMyGallery, setIsValidToMyGallery] = useState(
    editSlug
      ? props.initialValues.upload_images.length > 0 &&
          props.initialValues.upload_images[0] &&
          props.initialValues.upload_images[0].mediaType !== 'VIDEO'
      : true,
  );

  const onChangeMediaInput = (data: GenericFieldHTMLAttributes) => {
    const mediaList = data as Media[];
    if (mediaList.length > 0) {
      setIsValidToMyGallery(mediaList[0].mediaType !== 'VIDEO');
    } else {
      setIsValidToMyGallery(true);
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Field
        component={Input}
        label="Title of your challenge submission"
        name={ChallengeSubmissionFormFieldNames.title}
        placeholder=""
        type="text"
        validate={required}
      />
      <Field
        component={EditorInput}
        label="Any commentary you'd like to share"
        name={ChallengeSubmissionFormFieldNames.description}
        placeholder=""
        validate={required}
      />
      <Field
        component={MediaInput}
        label="Upload images"
        name={FeedbackFieldNames[UPLOAD_MEDIA_FIELD]}
        onChange={onChangeMediaInput}
      />
      {isValidToMyGallery && (
        <Field
          CheckboxComponent={Toggle}
          component={BooleanInput}
          name={ChallengeSubmissionFormFieldNames.addToMyGallery}
          label="Add main image to my gallery"
        />
      )}
      <VisualTagInputs />
      <FormActions
        invalid={invalid}
        loading={loading}
        onDelete={onDelete}
        pristine={isPristine}
        reset={editSlug ? reset : undefined}
        submitFailed={submitFailed}
      />
    </Container>
  );
};

export default connect(ChallengeSubmissionForm);
