import { connect } from 'react-redux';
import {
  createChallengeResponse,
  getChallengeResponses,
  selectSubmissions,
} from 'redux/modules/challengeResponse';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  challenge: state.challenge.bySlug[ownProps.match.params.challengeSlug],
  loadingChallenge: state.challenge.loading,
  loadingResponses: state.challengeResponse.loading,
  loggedIn: !!state.auth.user,
  pageCount: state.challengeResponse.pageCount,
  responses: selectSubmissions(state),
  wentBack: selectUserWentBack(state),
});

const mapDispatchToProps = {
  createChallengeResponse: createChallengeResponse.request,
  getResponses: getChallengeResponses.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
