import { Group, Plan, Tag } from 'model';
import { createAsyncAction } from 'typesafe-actions';
import { DeepReadonly } from 'utility-types';

export const getConfiguration = createAsyncAction(
  'configuration/GET_CONFIGURATION_REQUEST',
  'configuration/GET_CONFIGURATION_SUCCESS',
  'configuration/GET_CONFIGURATION_FAILURE',
)<
  undefined,
  | DeepReadonly<{
      conversationTags: Tag[];
      exerciseTags: Tag[];
      graphIndustryTags: Tag[];
      graphToolTags: Tag[];
      graphTopicTags: Tag[];
      graphTypeTags: Tag[];
      groups: Group[];
      plans: Plan[];
      reportOptions: Tag[];
      videoPresenters: Tag[];
      videoSeries: Tag[];
      videoType: Tag[];
    }>
  | undefined,
  undefined
>();
