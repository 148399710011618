import React, { FC, memo, useCallback, useState } from 'react';
import Plan from './Plan';
import {
  Container,
  PeriodTabCorporate,
  PeriodTabIndividual,
  PeriodTypeTabs,
  Plans,
  TabText,
} from './styles';
import { Props } from './types';

const AvailablePremiumSubscriptionsCard: FC<Props> = ({
  currentPlanInterval,
  plans,
  isPremiumGroupOwner,
  forceCorporateTab,
  onCancelSubscription,
  ...rest
}) => {
  const [isCorporateTab, setIsCorporateTab] = useState(
    forceCorporateTab ? forceCorporateTab : isPremiumGroupOwner,
  );

  const setCorporateTabValueTrue = useCallback(
    () => setIsCorporateTab(true),
    [],
  );
  const setCorporateTabValueFalse = useCallback(
    () => setIsCorporateTab(false),
    [],
  );

  return (
    <Container {...rest}>
      <PeriodTypeTabs>
        <PeriodTabIndividual
          $activeTab={!isCorporateTab}
          onClick={setCorporateTabValueFalse}
        >
          <TabText $activeTab={!isCorporateTab}>Individual</TabText>
        </PeriodTabIndividual>
        <PeriodTabCorporate
          $activeTab={isCorporateTab}
          onClick={setCorporateTabValueTrue}
        >
          <TabText $activeTab={isCorporateTab}>Group</TabText>
        </PeriodTabCorporate>
      </PeriodTypeTabs>
      <Plans>
        {plans
          .filter((plan) => plan.metadata?.disabled === 'false')
          .map(
            (plan, i) =>
              !(!plan.period && isCorporateTab) && (
                <Plan
                  key={plan.id}
                  id={plan.id}
                  index={i}
                  isCorporateCard={isCorporateTab}
                  currentPlanType={
                    isPremiumGroupOwner ? 'corporate' : 'individual'
                  }
                  isLifetimeCard={!isCorporateTab && !plan.period}
                  period={plan.period}
                  currentPlanInterval={currentPlanInterval}
                  price={plan.price}
                  onCancelSubscription={onCancelSubscription}
                  subtitle={
                    plan.savings
                      ? isCorporateTab
                        ? ` $${Math.round(plan.savings)} per seat`
                        : ` $${Math.round(plan.savings)}`
                      : undefined
                  }
                />
              ),
          )}
      </Plans>
    </Container>
  );
};

export default memo(AvailablePremiumSubscriptionsCard);
