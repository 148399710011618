import {
  BooleanInput,
  OrderDirection as DefaultOrderDirection,
  Select,
} from 'components';
import styled from 'styled-components';
import { from, visuallyHidden } from 'styles/mixins';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.xlg};
  ${from.tablet`
    flex-direction: row;
  `};
`;

export const GraphTypesSelect = styled(Select)`
  flex: 1;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    flex: initial;
    margin: 0;
    width: 12rem;
  `};
`;

export const Order = styled.div`
  display: flex;
  grid-column: auto;
  justify-content: flex-start;
  width: 100%;

  ${from.tablet`
    justify-content: flex-end;
    margin-left: auto;
    width: fit-content;
  `};
`;

export const OrderSelect = styled(Select)`
  border-radius: 0.25rem;
  margin-bottom: 0;
  flex: 1;

  ${from.tablet`
    width: 9rem;
  `};

  > * > * {
    :hover {
      box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
    }
  }
`;

export const OrderBooleanInput = styled(BooleanInput)`
  height: 2.75rem;
  margin-bottom: 0;
  margin-left: ${({ theme }) => theme.spacing.sm};
`;

export const OrderDirection = styled(DefaultOrderDirection)`
  height: 2.75rem;

  :hover {
    box-shadow: 0 2px 8px -3px rgba(73, 80, 87, 0.4);
  }
`;

export const VisuallyHiddenLabel = styled.label`
  ${visuallyHidden};
`;
