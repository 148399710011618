import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import {
  Head,
  Headset,
  HeadsetBigCircle,
  HeadsetSmallCircle,
  Microphone,
  MicrophoneDetail,
} from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Microphone d="M6.417 15.583a2.5 2.5 0 01-5 0v-5a2.5 2.5 0 015 0v5z" />
      <MicrophoneDetail d="M1.417 11.417h1.666m-1.666 1.666h1.666M1.417 14.75h1.666m3.334-3.333H4.75m1.667 1.666H4.75m1.667 1.667H4.75" />
      <Head d="M3.917 18.083v2.5m-1.667 0h3.333M19.607 9.06a3.333 3.333 0 11-4.714 4.714 3.333 3.333 0 014.714-4.714" />
      <Headset d="M17.25 6.417v1.666m1.178 2.155a1.667 1.667 0 11-2.357 2.357 1.667 1.667 0 012.357-2.357" />
      <HeadsetBigCircle d="M15.583 4.063c-2.65.534-5.112 2.5-5.112 5.584 0 .583-1.633 2.577-1.55 3.741.027.369.036.658.505.679.469.02 1.103-.005 1.103-.005s-.028 2.75.623 3.397c.509.51 1.316.614 2.765.607v2.517m6.666-15.715a6.957 6.957 0 00-1.666-.7" />
      <HeadsetSmallCircle d="M18.917 4.75a1.667 1.667 0 11-3.334 0v-2.5c0-.46.373-.833.834-.833h1.666c.46 0 .834.373.834.833v2.5z" />
    </g>
  </svg>
));
