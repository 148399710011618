import type { Node as ProsemirrorNode } from 'prosemirror-model';
import { Step, StepResult } from 'prosemirror-transform';
import { ReferenceProvider } from '../types';

export class SetReferenceProviderStep extends Step {
  public readonly referenceProvider: ReferenceProvider;

  constructor(referenceProvider: ReferenceProvider) {
    super();
    this.referenceProvider = referenceProvider;
  }

  apply(doc: ProsemirrorNode) {
    return StepResult.ok(doc);
  }

  invert() {
    return this;
  }

  map() {
    return this;
  }

  toJSON() {
    return {};
  }
}
