import React, { FC, memo } from 'react';
import { Button, ButtonGroup, Close, Container } from './styles';
import { Props } from './types';

const FormActions: FC<Props> = ({
  invalid,
  loading = false,
  loadingLabel = 'Loading...',
  onClose,
  onDelete,
  pristine,
  reset,
  submitFailed = false,
  submitLabel = 'Submit',
  ...rest
}) => (
  <Container $deletable={!!onDelete} $isComment={!!onClose} {...rest}>
    {onDelete && (
      <ButtonGroup>
        <Button
          disabled={loading}
          onClick={onDelete}
          type="button"
          variant="danger"
        >
          Delete
        </Button>
      </ButtonGroup>
    )}
    <ButtonGroup>
      {reset && (
        <Button
          disabled={loading || pristine}
          onClick={reset}
          type="button"
          variant="secondary"
        >
          Reset
        </Button>
      )}
      <Button
        disabled={loading || pristine || (submitFailed && invalid)}
        type="submit"
      >
        {loading ? loadingLabel : submitLabel}
      </Button>
      {onClose && (
        <Close onClick={onClose} type="button" variant="secondary">
          Close
        </Close>
      )}
    </ButtonGroup>
  </Container>
);

export default memo(FormActions);
