import { AddImageIcon as DefaultAddImageIcon } from 'components/icons';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div<{ $isMouseInput: boolean }>`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    flex-direction: column;
  `};

  ${({ $isMouseInput }) =>
    $isMouseInput &&
    css`
      flex-direction: column;
    `}
`;

export const AddImageIcon = styled(DefaultAddImageIcon)`
  width: 2rem;
  margin-right: ${({ theme }) => theme.spacing.sm};

  ${from.tablet`
    width: 1rem;
    margin-right: 0;
    margin-bottom: ${({ theme }) => theme.spacing.md};
  `};
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.0625rem;
  line-height: 0.875rem;
  text-transform: uppercase;
  text-align: center;
`;

export const Description = styled.p`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.black};
  margin: ${({ theme }) => theme.spacing.xsm};
  font-weight: ${({ theme }) => theme.weights.regular};
  letter-spacing: normal;
  line-height: 0.875rem;
`;
