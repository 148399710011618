import { useMediaQuery } from '@material-ui/core';
import { UpdateCompanyDetailsCorporatePremiumForm } from 'model/forms/UpdateCompanyDetailsCorporatePremium';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { up } from 'utils/Paths';
import connect from './connect';
import Form from './Form';
import { Container, Content, Title } from './styles';
import { Props } from './types';

const UpdateCompanyNameModal: FC<Props> = ({
  history: { push },
  match,
  updateCompanyName,
}) => {
  const url = match?.url;
  const isFromTablet = useMediaQuery(from.mobileXL.query);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  const onSubmit = useCallback(
    (value: UpdateCompanyDetailsCorporatePremiumForm) => {
      const companyName = value.companyName;
      updateCompanyName({ companyName });
    },
    [updateCompanyName],
  );

  return (
    <Container
      animation="slideUp"
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!url}
      scroll="body"
    >
      <Content>
        <Title>Edit Company Details</Title>
        <Form onClose={onClose} onSubmit={onSubmit} />
      </Content>
    </Container>
  );
};

export default connect(UpdateCompanyNameModal);
