import { ReportForm, REPORT_FORM_KEY } from 'model';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { closeModal } from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';
import { FormDecorator } from 'utils/forms';
import { makeFormSelectors } from 'utils/makeFormSelectors';
import { OwnProps } from './types';

const mapStateToProps = (state: RootState, { id }: OwnProps) => {
  let reason;
  const { selectValues } = makeFormSelectors<ReportForm>(
    `${REPORT_FORM_KEY}/${id}`,
  );
  reason = selectValues(state)?.reason;

  return {
    reason,
    otherReason: state.configuration.reportOptions[2].id,
    form: `${REPORT_FORM_KEY}/${id}`,
    initialValues: {
      reason: state.configuration.reportOptions[0].id,
    },
    reportOptions: state.configuration.reportOptions,
  };
};

const mapDispatchToProps = {
  closeModal,
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  InjectedFormProps<ReportForm, OwnProps>;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<ReportForm>({}),
) as FormDecorator<ReportForm, OwnProps, ConnectedProps>;
