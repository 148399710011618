import {
  Avatar as AvatarComponent,
  FormActions as DefaultFormActions,
} from 'components';
import {
  InstagramIcon as DefaultInstagramIcon,
  LinkedInIcon as DefaultLinkedInIcon,
  TwitterIcon as DefaultTwitterIcon,
  WebsiteIcon as DefaultWebsiteIcon,
} from 'components/icons';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  ${from.tabletH`
    flex-direction: row;
  `};
`;

export const Avatar = styled(AvatarComponent)`
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  height: 16.375rem;
  margin: 0;
  overflow: hidden;
  position: relative;
  width: 16.375rem;

  img {
    border-radius: 0;
  }
`;

export const InfoSection = styled.div`
  margin-left: 0;
  flex: 1;
  > * + * {
    margin-top: 1.875rem;
  }
  > *:last-child {
    margin-top: 2.5rem;
  }

  ${from.tabletH`
    margin-left: 6.25rem;
  `};
`;

export const Section = styled.section`
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  padding: 1rem 1.75rem 0;
`;

export const SectionHeader = styled.div`
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey100};
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  padding-bottom: ${({ theme }) => theme.spacing.md};

  > * {
    margin: 0;
  }

  ${from.tablet`
    align-items: center;
    flex-direction: row;
  `};
`;

export const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: ${({ theme }) => theme.spacing.md};

  ${from.tablet`
    margin-bottom: 0;
  `};
`;

export const Info = styled.span`
  font-size: 0.875rem;

  ${from.tablet`
    margin-left: auto;
  `};

  > * {
    flex-direction: row-reverse;
    margin-bottom: 0;

    > *:not(:last-child) {
      margin-left: 0.625rem;
      margin-right: 0;
    }
  }
`;

export const SectionInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2.5rem;

  ${from.mobileXL`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

export const Row = styled.div`
  display: flex;

  > * {
    flex: 1;

    :not(:last-child) {
      margin-right: 2.5rem;
    }
  }

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }

  svg {
    fill: #495057;
  }
`;

export const WebsiteIcon = styled(DefaultWebsiteIcon)`
  color: ${({ theme }) => theme.colors.grey400};
  height: 1.5rem;
  width: 1.5rem;
`;

export const TwitterIcon = styled(DefaultTwitterIcon)`
  color: ${({ theme }) => theme.colors.grey400};
  height: 1.5rem;
  width: 1.5rem;
`;

export const LinkedInIcon = styled(DefaultLinkedInIcon)`
  color: ${({ theme }) => theme.colors.grey400};
  height: 1.5rem;
  width: 1.5rem;
`;

export const InstagramIcon = styled(DefaultInstagramIcon)`
  color: ${({ theme }) => theme.colors.grey400};
  height: 1.5rem;
  width: 1.5rem;
`;

export const FormActions = styled(DefaultFormActions)`
  > :first-child {
    margin-right: auto;
  }
`;
