import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { FeedbackForm, UPLOAD_MEDIA_FIELD } from 'model';
import { SubmissionError } from 'redux-form';
import { useConnect } from './connect';

export const handleFormSubmitted = ({
  askFeedback,
}: {
  askFeedback: ReturnType<typeof useConnect>['askFeedback'];
}) => (values: FeedbackForm) => {
  const [mainImage, ...extraImages] = values[UPLOAD_MEDIA_FIELD].filter(
    isUploaded,
  );

  if (!mainImage) {
    throw new SubmissionError({
      [UPLOAD_MEDIA_FIELD]: 'Please add at least one image!',
    });
  }

  askFeedback({
    context: serializeHTML(values.context),
    addToMyGallery: values.addToMyGallery,
    expiryDate: values.expirationDate?.toISOString() ?? null,
    graphIndustry: values.industry,
    graphTools: values.tools,
    graphTopic: values.topic,
    graphTypes: values.graphType,
    imageList: {
      mainImage: mainImage.file,
      extraImages: extraImages.map((it) => it.file),
    },
    title: values.title,
  });
};
