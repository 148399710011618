import produce from 'immer';
import {
  ChallengeResponse,
  Conversation,
  Discover,
  ExerciseResponse,
  FeedbackRequest,
  User,
} from 'model';
import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import {
  CHALLENGE_RESPONSES_PER_PAGE,
  CONVERSATIONS_PER_PAGE,
  DISCOVER_PER_PAGE,
  EXERCISE_RESPONSES_PER_PAGE,
  FEEDBACK_PER_PAGE,
  USERS_PER_PAGE,
} from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { getPageCount } from 'utils/getPageCount';
import {
  getHeatMap,
  getOutstandingUsers,
  getUser,
  getUserChallengeResponses,
  getUserConversations,
  getUserExerciseResponses,
  getUserFeedbackResponses,
  getUserGallery,
  getUserLiked,
  getUsers,
  notifyLaunch,
  _upsertUsersById,
} from './actions';
import { UsersAction, UsersState } from './types';

export const INITIAL_STATE: UsersState = {
  ...defaultStandardState,
  heatMapsBySlug: {},
  loadingOutstandingUsers: false,
  outstandingUsers: [],
};

const handleNestedSlugSuccess = ({
  slug,
  results,
  count,
  property,
  state,
  perPage,
}: {
  slug: string;
  results: (
    | FeedbackRequest
    | Conversation
    | ExerciseResponse
    | ChallengeResponse
    | Discover
  )[];
  count: number;
  perPage: number;
  property: keyof User;
  state: UsersState;
}) => {
  const selectedItem = state.bySlug[slug];

  if (!selectedItem) {
    return state;
  }

  return {
    ...state,
    bySlug: {
      ...state.bySlug,
      [slug]: {
        ...selectedItem,
        [property]: {
          slugs: results.map((x) => x.slug),
          pageCount: getPageCount(count, perPage),
          loading: false,
        },
      },
    },
  };
};

const handleNestedSlugRequestAndFailure = ({
  slug,
  property,
  state,
  loading,
}: {
  slug: string;
  property: keyof User;
  state: UsersState;
  loading: boolean;
}) => {
  const selectedItem = state.bySlug[slug];

  if (!selectedItem) {
    return state;
  }

  const propertyValue = selectedItem[property] || {};

  return {
    ...state,
    bySlug: {
      ...state.bySlug,
      [slug]: {
        ...selectedItem,
        [property]: {
          ...propertyValue,
          loading,
        },
      },
    },
  };
};

const reducer: Reducer<UsersState, UsersAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    // Nested Feedback
    case getType(getUserFeedbackResponses.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'feedbackResponseSlugs',
        loading: false,
      });
    }
    case getType(getUserFeedbackResponses.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'feedbackResponseSlugs',
        loading: true,
      });
    }
    case getType(getUserFeedbackResponses.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: FEEDBACK_PER_PAGE,
        property: 'feedbackResponseSlugs',
      });
    }

    // Nested Conversations
    case getType(getUserConversations.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'conversationSlugs',
        loading: false,
      });
    }
    case getType(getUserConversations.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'conversationSlugs',
        loading: true,
      });
    }
    case getType(getUserConversations.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: CONVERSATIONS_PER_PAGE,
        property: 'conversationSlugs',
      });
    }

    // Nested ExerciseResponse
    case getType(getUserExerciseResponses.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'exerciseResponseSlugs',
        loading: true,
      });
    }
    case getType(getUserExerciseResponses.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'exerciseResponseSlugs',
        loading: false,
      });
    }
    case getType(getUserExerciseResponses.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: EXERCISE_RESPONSES_PER_PAGE,
        property: 'exerciseResponseSlugs',
      });
    }

    // Nested ChallengeResponse
    case getType(getUserChallengeResponses.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'challengeResponseSlugs',
        loading: true,
      });
    }
    case getType(getUserChallengeResponses.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'challengeResponseSlugs',
        loading: false,
      });
    }
    case getType(getUserChallengeResponses.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: CHALLENGE_RESPONSES_PER_PAGE,
        property: 'challengeResponseSlugs',
      });
    }

    // Nested Gallery
    case getType(getUserGallery.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'gallerySlugs',
        loading: true,
      });
    }
    case getType(getUserGallery.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'gallerySlugs',
        loading: false,
      });
    }
    case getType(getUserGallery.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: DISCOVER_PER_PAGE,
        property: 'gallerySlugs',
      });
    }

    // Nested Liked
    case getType(getUserLiked.request): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'likedSlugs',
        loading: true,
      });
    }
    case getType(getUserLiked.failure): {
      return handleNestedSlugRequestAndFailure({
        ...action.payload,
        state,
        property: 'likedSlugs',
        loading: false,
      });
    }
    case getType(getUserLiked.success): {
      return handleNestedSlugSuccess({
        ...action.payload,
        state,
        perPage: DISCOVER_PER_PAGE,
        property: 'likedSlugs',
      });
    }

    case getType(notifyLaunch.request): {
      return {
        ...state,
        loading: true,
      };
    }
    case getType(notifyLaunch.failure):
    case getType(notifyLaunch.success): {
      return {
        ...state,
        loading: false,
      };
    }

    case getType(getHeatMap.request):
    case getType(getHeatMap.failure):
      return {
        ...state,
        loading: true,
      };
    case getType(getHeatMap.success): {
      const { data, slug } = action.payload;

      return produce(state, (next) => {
        next.heatMapsBySlug[slug] = data;
        next.loading = false;
      });
    }
    case getType(getOutstandingUsers.failure):
      return {
        ...state,
        loadingOutstandingUsers: false,
        outstandingUsers: [],
      };
    case getType(getOutstandingUsers.request):
      return {
        ...state,
        loadingOutstandingUsers: true,
      };
    case getType(getOutstandingUsers.success):
      return {
        ...state,
        loadingOutstandingUsers: false,
        outstandingUsers: action.payload.results,
      };

    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getUsers.request),
      success: isActionOf(getUsers.success),
      failure: isActionOf(getUsers.failure),
    },
    getOne: {
      request: isActionOf(getUser.request),
      success: isActionOf(getUser.success),
      failure: isActionOf(getUser.failure),
    },
    _upsertById: isActionOf(_upsertUsersById),
  },
  contractItem: (payload) => payload,
  initialState: INITIAL_STATE,
  perPage: USERS_PER_PAGE,
})(reducer);
