import { FormErrorMap, FormValidator } from 'utils/forms';
import { SignUpForm } from '.';

export const validateSignUpForm: FormValidator<SignUpForm> = ({
  password,
  passwordConfirm,
}) => {
  const errors: FormErrorMap<SignUpForm> = {};

  if (password && passwordConfirm && password !== passwordConfirm) {
    errors.passwordConfirm = 'Passwords do not match';
  }

  return errors;
};
