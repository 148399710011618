import { connect } from 'react-redux';
import { verify } from 'redux/modules/auth';
import { RootState } from 'redux/modules/types';

const mapStateToProps = ({ auth, theme }: RootState) => ({
  init: auth.init,
  theme: theme.current,
});

const mapDispatchToProps = {
  verify: verify.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
