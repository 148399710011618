import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { closeModal, selectSignUpModalOpen } from 'redux/modules/modal';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  location: state.router.location,
  open: selectSignUpModalOpen(state),
});

const mapDispatchToProps = {
  closeModal,
  replace,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
