import Button from 'components/Button';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

export const Content = styled(motion.div).attrs({
  variants: {
    expanded: {
      height: 'auto',
    },
    collapsed: {
      height: '4.5rem',
    },
  },
  transition: { type: 'tween' },
})`
  margin: 24px 0 32px 0;
  overflow: hidden;
  position: relative;
  will-change: height;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 15px;
    background-image: linear-gradient(
      to bottom,
      transparent,
      ${({ theme }) => theme.colors.white}
    );
    opacity: ${({ animate }) => (animate === 'expanded' ? 0 : 1)};
  }
`;

export const Toggle = styled(Button)`
  padding: 0;
  font-size: 14px;
  margin: 0;
`;
