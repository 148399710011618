import { FormNameMap } from 'utils/forms';

export const SEARCH_MEMBERS_FORM_KEY = 'forms/SearchMembers';

export interface SearchMembersForm {
  query: string | null;
  activityOrder: string | null;
  order: string | null;
  directionIsAsc: boolean;
  course: string | null;
}

export const SearchMembersFieldNames: FormNameMap<SearchMembersForm> = {
  query: 'query',
  activityOrder: 'activityOrder',
  order: 'order',
  directionIsAsc: 'directionIsAsc',
  course: 'course',
};

export const SEARCH_MEMBERS_FORM_DEFAULT_VALUES: SearchMembersForm = {
  query: null,
  activityOrder: '',
  order: '',
  directionIsAsc: false,
  course: '',
};
