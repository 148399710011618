export enum ZIndex {
  MAIN = 0,
  DROPDOWN_MENU = 1,
  RICH_INPUT_PLACEHOLDER = 2,
  CARD_STATUS = 3,
  ORDER_BY_DROPDOWN = 4,
  SEARCH_FILTERS = 5,
  SELECT_MENU = 6,
  MOBILE_MENU = 7,
  MODAL_CLOSE_BUTTON = 8,
  EDITOR_LINK_PROMPT = 9,
  EDITOR_REFERENCE_SELECTOR = 10,
  MODAL = 1300,
  FULL_SCREEN_IMAGE_VIEWER = 1301,
  BREAKPOINT_DEBUGGER = 9999,
}
