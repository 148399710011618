import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { InsideShape, OutsideShape } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <OutsideShape d="M20.254 13.5H22.5a1 1 0 0 0 1-1v-1a1 1 0 0 0-1-1h-2.247a11.8 11.8 0 0 0-1-2.922l1.589-1.588a1 1 0 0 0 0-1.414l-1.418-1.414a1 1 0 0 0-1.414 0l-1.59 1.589a11.769 11.769 0 0 0-2.92-1V1.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1 1v2.249c-1.013.204-1.995.54-2.921 1l-1.59-1.587a1 1 0 0 0-1.414 0L3.16 4.577a1 1 0 0 0 0 1.414L4.75 7.58c-.46.926-.796 1.908-1 2.921H1.5a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h2.246c.204 1.013.54 1.995 1 2.921l-1.59 1.59a1 1 0 0 0 0 1.414l1.419 1.414a1 1 0 0 0 1.414 0l1.589-1.59a11.8 11.8 0 0 0 2.922 1v2.25a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1v-2.245a11.8 11.8 0 0 0 2.92-1l1.591 1.589a1 1 0 0 0 1.414 0l1.414-1.414a1 1 0 0 0 0-1.414l-1.589-1.59a11.82 11.82 0 0 0 1.004-2.926z" />
      <InsideShape d="M15.182 8.819a4.5 4.5 0 1 1-6.364 6.364 4.5 4.5 0 0 1 6.364-6.364" />
    </g>
  </svg>
));
