import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import {
  getActivityItems,
  selectActivity,
  selectActivityPageCount,
} from 'redux/modules/activity';
import { useActions } from 'utils/useActions';

export const useConnect = () => {
  const { url } = useRouteMatch();
  const { search } = useLocation();

  const activity = useSelector(selectActivity);
  const pageCount = useSelector(selectActivityPageCount);

  const actions = useActions({
    getActivity: getActivityItems.request,
  });

  return {
    ...actions,
    activity,
    pageCount,
    search,
    url,
  };
};
