import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import {
  ContinentPaths,
  LocationPin,
  LocationPinDot,
  Path1,
  Path2,
  Path3,
} from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Path1 d="M14.333 19.975a9.583 9.583 0 01-12.318-5.652A9.583 9.583 0 017.667 2.005a9.583 9.583 0 0112.88 9.826" />
      <ContinentPaths d="M1.56 9.32h5.07a2.5 2.5 0 011.77.732l.933.935a2.5 2.5 0 01.101 3.429l-1.136 1.277a2.5 2.5 0 00-.631 1.661v2.621M17.884 4.32h-4.833a1.875 1.875 0 000 3.75h.36a2.5 2.5 0 012.083 1.113l.944 1.417" />
      <LocationPin d="M20.583 15.57c0 2.084-3.333 5-3.333 5s-3.333-2.916-3.333-5a3.333 3.333 0 116.666 0z" />
      <Path2 d="M17.25 15.363a.207.207 0 01.208.208" />
      <Path3 d="M17.042 15.57a.208.208 0 01.208-.208" />
      <LocationPinDot d="M17.25 15.78a.208.208 0 01-.208-.21m.416 0a.208.208 0 01-.208.21" />
    </g>
  </svg>
));
