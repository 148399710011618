import React, { FC } from 'react';
import { matchPath, Route, useHistory, useLocation } from 'react-router';
import { Props } from './types';

const Child: FC<Props> = ({ component: Component, ...routeProps }) => {
  const location = useLocation();
  const history = useHistory();

  const match = matchPath(location.pathname, routeProps);

  return <Component history={history} location={location} match={match} />;
};

/*
 * A ModalRoute is a Route that is always rendered, regardless of whether or not
 * its path is matched. The responsibility of rendering content is passed onto
 * its child component.
 *
 * This is very useful for modals, as we don't want to render them when they
 * are opened, but rather, we want them to be rendered as soon as possible, and
 * react to the route match to show themselves.
 */
const ModalRoute: FC<Props> = (props) => (
  <Route path="/">
    <Child {...props} />
  </Route>
);

export default ModalRoute;
