import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing.xlg} 0;
`;

export const PeriodTypeTabs = styled.div`
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  max-width: 23.75rem;
  cursor: pointer;
  padding: 0.25rem;
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const TabText = styled.h5<{ $activeTab: boolean }>`
  margin: 0;
  padding: 10px;
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  text-align: center;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.black};
  ${from.tablet`
  font-size: 1.25rem;
`}
  ${({ $activeTab }) =>
    $activeTab &&
    css`
      color: ${({ theme }) => theme.colors.white};
      font-weight: ${({ theme }) => theme.weights.bold};
    `}
`;

const PeriodTab = styled.div<{ $activeTab: boolean }>`
  display: inline-block;
  width: 50%;
  border-radius: 0.5rem;

  ${({ $activeTab }) =>
    $activeTab &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};
    `}
`;

export const PeriodTabIndividual = styled(PeriodTab)``;

export const PeriodTabCorporate = styled(PeriodTab)``;

export const Wrapper = styled.div`
  display: contents;
`;

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > :not(:last-child) {
    margin-bottom: 1.375rem;
  }
  ${from.laptop`
  flex-direction: row;
  > :not(:last-child) {
    margin-bottom: 0;
    margin-right: 1.375rem;
   }
  `}
`;
