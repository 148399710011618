import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { ExerciseResponseForm, UPLOAD_MEDIA_FIELD } from 'model';
import React from 'react';
import { SubmissionError } from 'redux-form';
import { deleteExerciseResponse } from 'redux/modules/exerciseResponse';
import { Props } from './types';

export const handleDelete = ({
  exerciseId,
  openConfirmationModal,
  submissionId: responseId,
}: {
  exerciseId?: string;
  openConfirmationModal: Props['openConfirmationModal'];
  submissionId?: string;
}) => () => {
  if (exerciseId && responseId) {
    openConfirmationModal({
      acceptButtonLabel: 'Delete',
      acceptButtonVariant: 'danger',
      cancelButtonLabel: 'Cancel',
      title: <span>Are you sure?</span>,
      description: (
        <span>
          <strong>Deleting this submission can’t be undone</strong>. This entry
          and its associated images, comments, datapoints... will be deleted in
          all the sections of this website.
        </span>
      ),
      onAccept: [deleteExerciseResponse.request({ exerciseId, responseId })],
    });
  }
};

export const handleSubmit = ({
  editExerciseResponse,
  exerciseId,
  submissionId: responseId,
}: {
  editExerciseResponse: Props['editExerciseResponse'];
  exerciseId?: string;
  submissionId?: string;
}) => (values: ExerciseResponseForm) => {
  if (exerciseId && responseId) {
    const [mainMedia, ...extraMedia] = values[UPLOAD_MEDIA_FIELD].filter(
      isUploaded,
    );

    if (!mainMedia) {
      throw new SubmissionError({
        [UPLOAD_MEDIA_FIELD]: 'Please add at least one image!',
      });
    }

    editExerciseResponse({
      exerciseId,
      responseId,
      description: serializeHTML(values.description),
      graphIndustry: values.industry,
      graphTools: values.tools,
      graphTopic: values.topic,
      graphTypes: values.graphType,
      mediaList: {
        mainMedia,
        extraMedia,
      },
      title: values.title,
    });
  }
};
