import { useMediaQuery } from '@material-ui/core';
import React, { forwardRef, useCallback, useState } from 'react';
import { from } from 'styles/mixins';
import Delete from './Delete';
import ImageEditor from './ImageEditor';
import { useUploadImage } from './logic';
import {
  Container,
  Error,
  Modal,
  Progress,
  Thumbnail,
  VideoThumbnail,
} from './styles';
import { Props } from './types';

const Media = forwardRef<HTMLDivElement, Props>(
  (
    { isMouseInput, media, onDeleteFile, onEdited, onUploaded, ...rest },
    ref,
  ) => {
    const { progress } = useUploadImage({ media, onUploaded });

    const isMobile = !useMediaQuery(from.mobileXL.query);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);

    const closeEditModal = useCallback(() => setShowEditModal(false), []);

    const handleSave = useCallback(
      (newFile: File) => {
        if (media.status === 'uploaded') {
          onEdited(media.file, newFile);
        }
      },
      [media, onEdited],
    );

    return (
      <Container ref={ref} {...rest}>
        {media.status === 'uploading' ? (
          <>
            {/*
              When the media is uploading, the thumbnail is the File's object
              url, so we display the VideoThumbnail for videos.
            */}
            {media.mediaType === 'IMAGE' && (
              <Thumbnail
                $dim={true}
                src={media.thumbnail}
                alt=""
                aria-hidden="true"
              />
            )}
            {media.mediaType === 'VIDEO' && (
              <VideoThumbnail
                $dim={true}
                src={media.thumbnail}
                alt=""
                aria-hidden="true"
              />
            )}
          </>
        ) : (
          <>
            {media.thumbnail ? (
              <>
                {/*
                  When the media is uploaded, it might have a Thumbnail if it is
                  sent by the back-end (the only case is when we're editing a
                  post and the form is pre-populated).

                  In that case, display the thumbnail image regardless of
                  whether it's a video or an image.
                */}
                {media.mediaType === 'IMAGE' && (
                  <Thumbnail
                    $dim={media.status === 'error'}
                    src={media.thumbnail}
                    alt=""
                    aria-hidden="true"
                  />
                )}
                {media.mediaType === 'VIDEO' && (
                  <VideoThumbnail
                    $dim={media.status === 'error'}
                    src={media.file}
                    alt=""
                    aria-hidden="true"
                  />
                )}
              </>
            ) : (
              <>
                {/*
                  When the media doesn't have a thumbnail it's because it's a
                  new media that we just uploaded and the back-end hasn't yet
                  processed the thumbnail. In this case we display the
                  VideoThumbnail for videos.
                */}
                {media.mediaType === 'IMAGE' && (
                  <Thumbnail
                    $dim={media.status === 'error'}
                    src={media.file}
                    alt=""
                    aria-hidden="true"
                  />
                )}
                {media.mediaType === 'VIDEO' && (
                  <VideoThumbnail
                    $dim={media.status === 'error'}
                    src={media.file}
                    alt=""
                    aria-hidden="true"
                  />
                )}
              </>
            )}
          </>
        )}
        {media.status !== 'uploading' && (
          <>
            <Delete
              file={media.file}
              isMouseInput={isMouseInput}
              onDeleteFile={onDeleteFile}
            />
          </>
        )}
        {media.status === 'uploading' && <Progress value={progress} max={1} />}
        {media.status === 'error' && <Error>Error</Error>}
        {showEditModal &&
          media.status === 'uploaded' &&
          media.mediaType === 'IMAGE' && (
            <Modal
              open={showEditModal}
              onClose={closeEditModal}
              fullScreen={isMobile}
            >
              <ImageEditor file={media.file} onSave={handleSave} />
            </Modal>
          )}
      </Container>
    );
  },
);

export default Media;
