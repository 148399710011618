import { InputWithIcon, Toggle } from 'components';
import { EditProfileFormNames } from 'model';
import React, { FC } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Field } from 'redux-form';
import { isLinkedinUrl } from 'utils/validations';
import SectionFooter from '../SectionFooter';
import connect from './connect';
import {
  BooleanInput,
  Container,
  Content,
  Info,
  InstagramIcon,
  LinkedInIcon,
  Title,
  TitleSection,
  TwitterIcon,
  WebsiteIcon,
} from './styles';
import { Props } from './types';

const SocialInfo: FC<Props> = ({ handleSubmit, valid }) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();

  return (
    <Container onSubmit={handleSubmit}>
      <TitleSection>
        <Title>Social info</Title>
        <Info>
          <Field
            CheckboxComponent={Toggle}
            component={BooleanInput}
            name={EditProfileFormNames.showSocialInfo}
            label="Show this info on my public profile"
          />
        </Info>
      </TitleSection>
      <Content>
        <Field
          component={InputWithIcon}
          Icon={WebsiteIcon}
          name={EditProfileFormNames.socialWeb}
          placeholder="Link to your home page, blog, or company site"
          label="WEBSITE"
          type="text"
        />
        <Field
          component={InputWithIcon}
          Icon={TwitterIcon}
          name={EditProfileFormNames.socialTwitter}
          placeholder="Twitter user account"
          label="TWITTER PROFILE"
          type="text"
        />
        <Field
          component={InputWithIcon}
          Icon={LinkedInIcon}
          name={EditProfileFormNames.socialLinkedin}
          placeholder="Link to your Linkedin profile"
          label="LINKEDIN PROFILE"
          type="text"
          validate={isLinkedinUrl}
        />
        <Field
          component={InputWithIcon}
          Icon={InstagramIcon}
          name={EditProfileFormNames.socialInstagram}
          placeholder="Instagram user account"
          label="INSTAGRAM PROFILE"
          type="text"
        />
      </Content>
      <SectionFooter
        baseUrl={url}
        canSubmit={valid}
        search={search}
        section="social-info"
      />
    </Container>
  );
};

export default connect(SocialInfo);
