import React, { FC, memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { UploadNewImage } from '../index';
import Media from './Media';
import { Container } from './styles';
import { Props } from './types';
import UploadImageButton from './UploadImageButton';

const FileList: FC<Props> = ({
  anyUploading,
  droppablePlaceholder,
  files,
  getDropZoneProps,
  isDraggingOver,
  isMouseInput,
  onDeleteFile,
  onUploaded,
  onEdited,
  open,
  ...rest
}) => (
  <Container {...rest}>
    {files.map((media, i) => {
      if (typeof media === 'symbol') {
        if (media === UploadNewImage) {
          return (
            !isDraggingOver && (
              <UploadImageButton
                key="upload-new-file"
                open={open}
                {...getDropZoneProps()}
              />
            )
          );
        }

        return null;
      }

      const id = media.status === 'uploading' ? media.thumbnail : media.file;

      return (
        <Draggable
          draggableId={id}
          isDragDisabled={anyUploading}
          index={i}
          key={id}
        >
          {(provided) => (
            <Media
              isMouseInput={isMouseInput}
              media={media}
              onDeleteFile={onDeleteFile}
              onEdited={onEdited}
              onUploaded={onUploaded}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            />
          )}
        </Draggable>
      );
    })}
    {droppablePlaceholder}
  </Container>
);

export default memo(FileList);
