import React from 'react';
import accessibleSvg from 'utils/accessibleSvg';
import { Stars, Thumb } from './styles';

export default accessibleSvg(({ children, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    {children}
    <g
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <Thumb d="M.5 12.5h2a7.949 7.949 0 0 0 7.5-8c0-1.581 3-1.781 3 1.219a31.593 31.593 0 0 1-1 5.781h8a1.5 1.5 0 0 1 0 3h-1a1.5 1.5 0 0 1 0 3h-1m0 0a1.5 1.5 0 0 1 0 3h-1a1.5 1.5 0 0 1 0 3h-6.5c-4 0-2-2-10-2" />
      <Stars d="M3.5 7.5v-2m-1 1h2m3-4v-2m-1 1h2m9 3v-2m-1 1h2m4 6v-2m-1 1h2" />
    </g>
  </svg>
));
