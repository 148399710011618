import DefaultComments from 'containers/Common/Comments';
import styled from 'styled-components';
import { container, from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.black};
  padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.mobile}`};

  ${from.mobileXL`
    padding: ${({ theme }) => `0 ${theme.wrapperPaddingH.desktop}`};
  `};
`;

export const Submission = styled.div`
  ${container};
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyBlue};

  ${from.tablet`
    border-radius: 0.25rem;
    border: solid 1px ${({ theme }) => theme.colors.greyBlue};
    box-shadow: 0 13px 30px -23px ${({ theme }) =>
      addAlpha(theme.colors.grey400, 0.3)};
  `};
`;

export const Comments = styled(DefaultComments)`
  margin: 0 auto;
  padding-top: 2.5rem;

  ${from.tablet`
    padding-top: 3.75rem;
  `}
`;
