import { connect } from 'react-redux';
import { getConversation } from 'redux/modules/conversation';
import { RootState } from 'redux/modules/types';
import { OwnProps } from './types';

const mapStateToProps = (
  state: RootState,
  { match, history: { push } }: OwnProps,
) => {
  const id = match?.params?.id;

  return {
    id,
    push,
    conversation: state.conversation.byId[id ?? ''],
    loading: state.conversation.loading,
    tags: state.configuration.conversationTags,
    url: match?.url,
  };
};

const mapDispatchToProps = {
  getConversation: getConversation.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
