import { InfoIcon as DefaultInfoIcon } from 'components/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  height: 0.8rem;
  color: ${({ theme }) => theme.colors.error};
  margin-right: ${({ theme }) => theme.spacing.xsm};
  vertical-align: middle;
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.75rem;
`;
