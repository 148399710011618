import { Textarea } from 'components';
import { GroupCodeFormNames } from 'model/forms/GroupCode';
import React, { FC } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { Field } from 'redux-form';
import { groupCode } from 'utils/validations';
import SectionFooter from '../SectionFooter';
import connect from './connect';
import {
  Container,
  Content,
  Input,
  Text,
  TextHeader,
  Title,
  TitleSection,
} from './styles';
import { Props } from './types';

const GroupCode: FC<Props> = ({ handleSubmit, valid }) => {
  const { search } = useLocation();
  const { url } = useRouteMatch();

  return (
    <Container onSubmit={handleSubmit}>
      <TitleSection>
        <Title>Group code</Title>
      </TitleSection>
      <Content>
        <TextHeader>Do you have a group code?</TextHeader>
        <Text>Enter it below.</Text>
        <Input>
          <Field
            component={Textarea}
            name={GroupCodeFormNames.groupCode}
            placeholder="000-XXX-X00"
            rows="1"
            validate={groupCode}
          />
        </Input>
        <TextHeader>No group code?</TextHeader>
        <Text>Simply hit Next Step.</Text>
      </Content>
      <SectionFooter
        baseUrl={url}
        canSubmit={valid}
        search={search}
        section="group-code"
      />
    </Container>
  );
};

export default connect(GroupCode);
