import { useMediaQuery } from '@material-ui/core';
import { Button } from 'components';
import React, { FC, useCallback } from 'react';
import { from } from 'styles/mixins';
import { formatDate } from 'utils/dates';
import { up } from 'utils/Paths';
import connect from './connect';
import { handleReactivate } from './logic';
import { Container, Content, Paragraph } from './styles';
import { Props } from './types';

const ReactivateModal: FC<Props> = ({
  info,
  history: { push },
  match,
  reactivateSubscription,
}) => {
  const url = match?.url;
  const isFromTablet = useMediaQuery(from.mobileXL.query);

  const onClose = useCallback(() => {
    if (url) {
      push({
        pathname: up(url),
        state: {
          scrollToTop: false,
        },
      });
    }
  }, [push, url]);

  const onReactivate = useCallback(
    handleReactivate({ onClose, reactivateSubscription }),
    [onClose, reactivateSubscription],
  );

  return (
    <Container
      animation="slideUp"
      fullScreen={!isFromTablet}
      onClose={onClose}
      open={!!url}
      scroll="body"
    >
      {info && (
        <Content>
          <Paragraph>
            Your premium membership will expire on{' '}
            <strong>{formatDate(info.currentPeriodEnd)}</strong> and will not
            renew automatically.
          </Paragraph>
          <Paragraph>
            Click "Reactivate" to continue with your subscription. You won't be
            charged until the current cycle ends on{' '}
            {formatDate(info.currentPeriodEnd)}.
          </Paragraph>
          <Button onClick={onReactivate}>Reactivate</Button>
        </Content>
      )}
    </Container>
  );
};

export default connect(ReactivateModal);
