import { stringify } from 'query-string';
import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useConnect } from './connect';
import { Props } from './types';

const ProtectedRoute: FC<Props> = ({ children, ...rest }) => {
  const { loggedIn, pathname } = useConnect();

  return (
    <Route {...rest}>
      {loggedIn ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            search: stringify({
              returnTo: pathname,
            }),
          }}
        />
      )}
    </Route>
  );
};

export default ProtectedRoute;
