import { Reducer } from 'redux';
import { getType, isActionOf } from 'typesafe-actions';
import { EXERCISES_PER_PAGE } from 'utils/config';
import {
  createStandardReducer,
  defaultStandardState,
} from 'utils/createStandardReducer';
import { getExercise, getExercises, _upsertExercisesById } from './actions';
import { ExerciseAction, ExerciseState } from './types';

export const INITIAL_STATE: ExerciseState = {
  ...defaultStandardState,
  active: null,
};

const reducer: Reducer<ExerciseState, ExerciseAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(getExercises.success):
      return {
        ...state,
        active: action.payload.active,
      };
    default:
      return state;
  }
};

export default createStandardReducer({
  actions: {
    getMany: {
      request: isActionOf(getExercises.request),
      success: isActionOf(getExercises.success),
      failure: isActionOf(getExercises.failure),
    },
    getOne: {
      request: isActionOf(getExercise.request),
      success: isActionOf(getExercise.success),
      failure: isActionOf(getExercise.failure),
    },
    _upsertById: isActionOf(_upsertExercisesById),
  },
  contractItem: (exercise) => exercise,
  initialState: INITIAL_STATE,
  perPage: EXERCISES_PER_PAGE,
})(reducer);
