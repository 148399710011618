import { Modal } from 'components';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled(Modal)`
  .MuiPaper-root {
    padding: ${({ theme }) => theme.spacing.md};
    width: initial;

    ${from.tabletH`
      min-width: 51rem;
      min-height: 70vh;
      padding: 3.75rem;
      width: 51rem;
    `};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.weights.bold};
`;
