import { Avatar as AvatarComponent, ImageListViewer } from 'components';
import DefaultLink from 'components/Link';
import { Image } from 'components/MediaListViewer/styles';
import RichText from 'components/RichText';
import { Ama } from 'components/Statuses/styles';
import { BadgeContainer } from 'components/SWDTeamOrPremiumBadge/styles';
import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.li`
  color: ${({ theme }) => theme.colors.black};
`;

export const Body = styled.div`
  display: grid;
  grid-column-gap: 0.75rem;
  grid-template-columns: 2.5rem auto;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Avatar = styled(AvatarComponent)`
  box-sizing: border-box;
  display: block;
  height: 2.5rem;
  width: 2.5rem;
  margin-right: 0;

  ${BadgeContainer} {
    bottom: -0.175rem;
  }
`;

export const Name = styled(DefaultLink)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.semiBold};

  :hover:not(:disabled) {
    text-decoration: underline;
  }
`;

export const Info = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.grey400, 0.5)};
`;

export const DotSeparator = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.grey400, 0.5)};
  margin: 0 ${({ theme }) => theme.spacing.sm};
`;

export const SidebarColumn = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentColumn = styled.div``;

export const ContentRow = styled.div`
  display: block;
  padding: 1rem 0;

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.md};
    margin-right: 0;
  }

  ${from.mobileXL`

    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: ${({ theme }) => theme.spacing.lg};
    }
  `};
`;

export const Content = styled(RichText)`
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colors.black};

  h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 0;
  }

  p:first-of-type {
    margin-top: 0.5rem;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
`;

export const Replies = styled.div`
  margin-top: ${({ theme }) => theme.spacing.xlg};

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.xlg};
  }

  grid-column: 2/3;
`;

export const CommentData = styled.div<{
  isAuthorAmaAuthor: boolean;
  hasAuthorReplied: boolean;
}>`
  border-left: 0.125rem solid ${({ theme }) => theme.colors.grey200};
  display: flex;
  flex-direction: column;
  padding-left: 1rem;

  ${({ isAuthorAmaAuthor, hasAuthorReplied }) =>
    !hasAuthorReplied &&
    !isAuthorAmaAuthor &&
    css`
      border-left: 0.125rem solid ${({ theme }) => theme.colors.accent};
    `}
`;

export const CommentImage = styled(ImageListViewer)`
  min-width: 18rem;
  float: left;
  border-radius: 0.25rem;
  border: solid 1px ${({ theme }) => addAlpha(theme.colors.grey400, 0.7)};
  max-width: 100%;
  object-fit: cover;
  width: 18rem;
  ${Image} {
    max-height: 12rem;
  }
`;

export const AnsweredTag = styled(Ama)`
  margin-left: ${({ theme }) => theme.spacing.md};
`;
