import styled from 'styled-components';

export const Container = styled.div``;

// ToDo: Copy & paste from "Discover". We will probaby replace this with a custom ''Gallery' component
export const Images = styled.div`
  display: grid;
  grid-auto-rows: 10rem;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  grid-gap: ${({ theme }) => theme.spacing.md};
  margin-top: 4.375rem;

  div:nth-child(1) {
    grid-row-end: span 1;
  }
  div:nth-child(2) {
    grid-row-end: span 3;
  }
  div:nth-child(3) {
    grid-row-end: span 2;
  }
  div:nth-child(4) {
    grid-row-end: span 2;
  }
  div:nth-child(5) {
    grid-row-end: span 1;
  }
  div:nth-child(6) {
    grid-row-end: span 3;
  }
  div:nth-child(7) {
    grid-row-end: span 2;
  }
  div:nth-child(8) {
    grid-row-end: span 1;
  }
  div:nth-child(8) {
    grid-row-end: span 3;
  }
`;
