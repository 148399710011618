import type { Command } from 'prosemirror-commands';
import type { Node as ProsemirrorNode } from 'prosemirror-model';
import { Step, StepResult } from 'prosemirror-transform';
import { isOpen } from '.';
import type { Schema } from '../schema';

export class SetLinkPromptOpenStep extends Step {
  public readonly open: boolean;

  constructor(open: boolean) {
    super();
    this.open = open;
  }

  apply(doc: ProsemirrorNode<Schema>) {
    return StepResult.ok(doc);
  }

  invert() {
    return new SetLinkPromptOpenStep(!this.open);
  }

  map() {
    return this;
  }

  toJSON() {
    return {};
  }
}

export const closeLinkPrompt: Command<Schema> = (state, dispatch) => {
  if (dispatch) {
    dispatch(state.tr.step(new SetLinkPromptOpenStep(false)));
  }

  return true;
};

export const openLinkPrompt: Command<Schema> = (state, dispatch) => {
  const selectionPointingToLink = state.schema.marks.link.isInSet(
    state.selection.$from.marks(),
  );

  if (state.selection.empty && !selectionPointingToLink) {
    return false;
  }

  if (isOpen(state)) {
    return false;
  }

  if (dispatch) {
    dispatch(state.tr.step(new SetLinkPromptOpenStep(true)));
  }

  return true;
};
