import { Card, CardGrid, Loader, ModalRoute, Pagination } from 'components';
import { getFeedbackRequestStatusList } from 'model';
import { getUserName } from 'model/User';
import React, { FC, useEffect } from 'react';
import { getPageFromQuery } from 'utils/getPageFromQuery';
import { getUserType } from 'utils/getUserType';
import { imageToMedia } from 'utils/imageToMedia';
import { Loading } from '../../styles';
import { useConnect } from './connect';
import Preview from './Preview';
import { Container, Link, SearchOptionsFeedback } from './styles';
import { Props } from './types';

const Feedback: FC<Props> = ({ contentRef, user }) => {
  const {
    pageCount,
    path,
    getUserFeedbackResponses,
    items,
    loading,
    url,
    search,
  } = useConnect({ user });

  const page = getPageFromQuery(search);

  const userSlug = user.slug;

  useEffect(() => {
    getUserFeedbackResponses({ search, page, slug: userSlug });
  }, [getUserFeedbackResponses, search, page, userSlug]);

  if (loading && !items.length) {
    return (
      <Loading>
        <Loader />
      </Loading>
    );
  }

  return (
    <Container>
      <SearchOptionsFeedback
        contentType="feedback"
        searchPlaceholder="Search..."
      />
      <CardGrid>
        {items.map((feedbackRequest) => (
          <Link
            key={feedbackRequest.id}
            to={{
              search,
              pathname: `${url}/${feedbackRequest.id}`,
              state: {
                scrollToTop: false,
              },
            }}
          >
            <Card
              authorAvatar={feedbackRequest.user.avatar}
              authorName={getUserName(feedbackRequest.user)}
              authorType={getUserType(feedbackRequest.user)}
              comments={feedbackRequest.commentsCount}
              datapoints={feedbackRequest.votesCount}
              lastCommentDate={
                feedbackRequest.lastComment
                  ? feedbackRequest.lastComment
                  : undefined
              }
              statuses={getFeedbackRequestStatusList(feedbackRequest)}
              tags={feedbackRequest.tags}
              thumbnail={
                feedbackRequest.imageList
                  ? imageToMedia(feedbackRequest.imageList?.mainImage)
                  : feedbackRequest.imageList
              }
              title={feedbackRequest.title}
              views={feedbackRequest.hits}
            />
          </Link>
        ))}
      </CardGrid>
      <Pagination
        contentRef={contentRef}
        current={Math.min(page, pageCount)}
        total={pageCount}
        url={url}
      />
      <ModalRoute component={Preview} path={`${path}/:id`} />
    </Container>
  );
};

export default Feedback;
