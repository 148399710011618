import { useRouteMatch } from 'react-router';

export const useConnect = () => {
  const { params, path, url } = useRouteMatch<{ userSlug?: string }>();

  return {
    params,
    path,
    url,
  };
};
