import { connect } from 'react-redux';
import { sendActivationEmail } from 'redux/modules/users';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  sendActivationEmailRequest: sendActivationEmail.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
