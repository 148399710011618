import { ImageIcon as DefaultImageIcon } from 'components/icons';
import SWDTeamOrPremiumBadge from 'components/SWDTeamOrPremiumBadge';
import DefaultTags from 'components/Tags';
import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { from } from 'styles/mixins';
import { addAlpha } from 'utils/addAlpha';

export const Container = styled.div<{ outline: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  box-shadow: 0 13px 30px -23px ${({ theme }) => addAlpha(theme.colors.grey400, 0.3)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  &:hover {
    box-shadow: 0 2px 20px -7px ${({ theme }) => addAlpha(theme.colors.grey500, 0.7)};
    border-color: ${({ theme }) => theme.colors.grey300};
  }

  ${({ outline }) =>
    outline &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.greyBlue};
      border-radius: 0.25rem;
    `};
`;

export const ImageIcon = styled(DefaultImageIcon)`
  color: ${({ theme }) => theme.colors.accent};
  width: 5rem;
`;

const thumbnailStyles = css`
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 14.31rem;
  width: 100%;
`;

export const NoThumbnail = styled.div`
  ${thumbnailStyles};
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey300};
  display: flex;
  justify-content: center;
`;

export const Overlay = styled.div`
  position: absolute;
  background-color: ${({ theme }) => addAlpha(theme.colors.bookLight, 0.3)};
  top: 0;
  left: 0;
  height: 14.31rem;
  width: 100%;
`;

export const Status = styled.span<{
  swdTeam: boolean;
  solvedByTeam: boolean;
  expiresSoon: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.grey400};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  height: 20px;
  line-height: 22px;
  letter-spacing: 0.57px;
  padding: 0 0.5rem 0;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  z-index: ${ZIndex.CARD_STATUS};

  :before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 11px 10px 0;
    border-color: transparent ${({ theme }) => theme.colors.grey400} transparent
      transparent;
    left: -10px;
    position: absolute;
    bottom: 0;
  }

  :not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }

  ${({ swdTeam }) =>
    swdTeam &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};

      :before {
        border-color: transparent ${({ theme }) => theme.colors.primary}
          transparent transparent;
      }
    `};

  ${({ solvedByTeam }) =>
    solvedByTeam &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      display: flex;
      :before {
        border-color: transparent ${({ theme }) => theme.colors.primary}
          transparent transparent;
      }
    `};

  ${({ expiresSoon }) =>
    expiresSoon &&
    css`
      background-color: ${({ theme }) => theme.colors.accent};

      :before {
        border-color: transparent ${({ theme }) => theme.colors.accent}
          transparent transparent;
      }
    `};
`;

export const StatusList = styled.div`
  position: relative;
  right: 1.25rem;
  top: 1.25rem;
  > * + * {
    margin-top: 1.875rem;
  }
`;

export const SWDOverlayBadge = styled(SWDTeamOrPremiumBadge)`
  margin-right: 0.5rem;
  > * {
    position: static;
  }
`;

export const Thumbnail = styled.img`
  ${thumbnailStyles};
  display: block;
  object-fit: cover;
`;

export const Icon = styled.div<{ solvedByTeam: boolean }>`
  display: none;
  ${({ solvedByTeam }) =>
    solvedByTeam &&
    css`
      display: flex;
      align-items: center;
    `};
`;

export const Content = styled.div<{ offsetWidth: number }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.75rem 1.25rem ${({ theme }) => theme.spacing.lg};

  ${from.mobile`
  padding: 1.75rem ${({ theme }) => theme.spacing.lg}
      ${({ theme }) => theme.spacing.lg};
  `}

  ${from.mobileXL<{ offsetWidth: number }>`
  ${({ offsetWidth }) =>
    offsetWidth &&
    offsetWidth < 320 &&
    css`
      padding: 1.75rem 1rem ${({ theme }) => theme.spacing.lg};
    `};
`}
`;

export const Tags = styled(DefaultTags)`
  display: flex;
  flex-wrap: wrap;
  min-height: 1.5rem;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;
