import { connect } from 'react-redux';
import {
  getFeedbackRequests,
  selectFeedbackRequests,
} from 'redux/modules/feedback';
import { selectUserWentBack } from 'redux/modules/router/selectors';
import { RootState } from 'redux/modules/types';

const mapStateToProps = (state: RootState) => ({
  feedbackRequests: selectFeedbackRequests(state),
  loading: state.feedback.loading,
  pageCount: state.feedback.pageCount,
  wentBack: selectUserWentBack(state),
});

const mapDispatchToProps = {
  getFeedbackRequests: getFeedbackRequests.request,
};

export default connect(mapStateToProps, mapDispatchToProps);

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;
