import CheckIcon from 'components/icons/Check';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const Container = styled.li`
  font-size: 1rem;
  display: flex;
  align-items: flex-start;

  :not(:last-child) {
    margin-bottom: 1.25rem;
  }
  ${from.mobileXL`
    display: block;
  `}
  ${from.tablet`
    font-size: 1.25rem;
  `}
`;

export const Check = styled(CheckIcon)`
  color: ${({ theme }) => theme.colors.basicGold};
  margin-right: 1rem;
  width: 1rem;
  margin-top: 0.7rem;
  ${from.mobileXL`
    margin-top: 0;
  `}
`;
