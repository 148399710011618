import { Button } from 'components';
import { ONBOARDING_SECTIONS } from 'model';
import React, { FC } from 'react';
import { up } from 'utils/Paths';
import connect from './connect';
import { useSkipOnboarding } from './logic';
import { Container } from './styles';
import { Props } from './types';

const SectionFooter: FC<Props> = ({
  baseUrl,
  canSubmit,
  search,
  section,
  editProfile,
}) => {
  const { onClick: onClickDoItLater } = useSkipOnboarding({ editProfile });

  const sectionIndex = ONBOARDING_SECTIONS.indexOf(section);

  if (sectionIndex < 0) {
    return null;
  }

  const prevSection = ONBOARDING_SECTIONS[sectionIndex - 1];
  const nextSection = ONBOARDING_SECTIONS[sectionIndex + 1];

  return (
    <Container
      notFirstOrLast={
        sectionIndex > 0 && sectionIndex < ONBOARDING_SECTIONS.length - 1
      }
    >
      {prevSection && (
        <>
          {nextSection && (
            <Button
              to={{
                search,
                pathname: '/members/me',
                state: {
                  onboardingAllowed: false,
                },
              }}
              variant={'link'}
              onClick={onClickDoItLater}
            >
              I'LL DO IT LATER
            </Button>
          )}
          <Button
            to={{
              search,
              pathname: `${up(baseUrl)}/${prevSection}`,
              state: {
                onboardingAllowed: true,
                scrollToTop: false,
              },
            }}
            variant="secondary"
          >
            Previous step
          </Button>
        </>
      )}

      <Button disabled={!canSubmit} type="submit" variant="primary">
        {nextSection ? 'Next step' : 'Save profile'}
      </Button>
    </Container>
  );
};

export default connect(SectionFooter);
