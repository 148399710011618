import styled, { css } from 'styled-components';
import { from } from 'styles/mixins';

export const FileGroup = styled.div<{ isOtherImages: boolean }>`
  > *:not(:first-child) {
    margin-top: 0.75rem;
  }

  ${({ isOtherImages }) =>
    isOtherImages &&
    css`
      ${from.tablet`
          width: 75%;
       `}

      ${from.tabletH`
          width: 80%;
       `}
      
       ${from.laptop`
          min-width: 19.75rem;
       `}
    `};
`;

export const Files = styled.div<{
  isMouseInput: boolean;
  oneFile: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${from.tablet<{ oneFile: boolean }>`
    flex-direction: row;
     > *:not(:first-child) {
        margin-left: 3.75rem;
      }
      
  ${({ oneFile }) =>
    oneFile &&
    css`
      width: auto;
    `};
  `};

  ${({ isMouseInput, oneFile }) =>
    isMouseInput &&
    oneFile &&
    css`
      width: auto;
      margin-right: ${({ theme }) => theme.spacing.xlg};
    `};
`;

export const FileZone = styled.div<{ isDraggingOver: boolean }>`
  > *:not(last-child) {
    margin-bottom: 1rem;
  }

  ${({ isDraggingOver, theme }) =>
    isDraggingOver &&
    css`
      background-color: ${theme.colors.grey200};
      border-radius: 0.25rem;
      transition: background-color 200ms;
    `};
`;

export const FileGroupLabel = styled.span`
  font-size: 0.875rem;
`;
