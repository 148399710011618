import { serializeHTML } from 'components/Editor';
import { isUploaded } from 'components/MediaInput';
import { AddToGalleryForm, UPLOAD_MEDIA_FIELD } from 'model';
import { SubmissionError } from 'redux-form';
import { useConnect } from './connect';

export const handleFormSubmitted = ({
  createDiscoverItem,
}: {
  createDiscoverItem: ReturnType<typeof useConnect>['createDiscoverItem'];
}) => (values: AddToGalleryForm) => {
  const [image] = values[UPLOAD_MEDIA_FIELD].filter(isUploaded);

  if (!image) {
    throw new SubmissionError({
      [UPLOAD_MEDIA_FIELD]: 'Please upload your image!',
    });
  }

  createDiscoverItem({
    context: serializeHTML(values.context),
    graphIndustry: values.industry,
    graphTools: values.tools,
    graphTopic: values.topic,
    graphTypes: values.graphType,
    image: image?.file,
    title: values.title,
  });
};
