import { isAnyFilterEnabled } from 'containers/Main/Search/logic';
import { Entity } from 'model';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { keysOf } from 'utils/keysOf';
import {
  resetSearchSectionPages,
  search,
  setSearchSectionPage,
} from './actions';
import { SearchAction, SearchState } from './types';

export const INITIAL_STATE: SearchState = {
  available: {
    blog: false,
    books: false,
    challenges: false,
    conversations: false,
    exercises: false,
    feedback: false,
    podcast: false,
    visuals: false,
    videos: false,
  },
  byId: {
    blog: {},
    books: {},
    challengeResponse: {},
    challenges: {},
    conversations: {},
    exercises: {},
    feedback: {},
    podcast: {},
    visuals: {},
    videos: {},
  },
  count: {
    blog: 0,
    books: 0,
    challengeResponse: 0,
    challenges: 0,
    conversations: 0,
    exercises: 0,
    feedback: 0,
    podcast: 0,
    visuals: 0,
    videos: 0,
  },
  ids: {
    blog: [],
    books: [],
    challengeResponse: [],
    challenges: [],
    conversations: [],
    exercises: [],
    feedback: [],
    podcast: [],
    visuals: [],
    videos: [],
  },
  loading: {
    blog: false,
    books: false,
    challengeResponse: false,
    challenges: false,
    conversations: false,
    exercises: false,
    feedback: false,
    podcast: false,
    visuals: false,
    videos: false,
  },
  page: {
    blog: 1,
    books: 1,
    challengeResponse: 1,
    challenges: 1,
    conversations: 1,
    exercises: 1,
    feedback: 1,
    podcast: 1,
    visuals: 1,
    videos: 1,
  },
};

const reducer: Reducer<SearchState, SearchAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case getType(resetSearchSectionPages):
      return {
        ...state,
        page: INITIAL_STATE.page,
      };
    case getType(search.request):
      if (!isAnyFilterEnabled(action.payload)) {
        return INITIAL_STATE;
      }

      return {
        ...state,
        loading: action.payload.singleSection
          ? {
              ...state.loading,
              [action.payload.singleSection]: true,
            }
          : {
              blog: true,
              books: true,
              challengeResponse: true,
              challenges: true,
              conversations: true,
              exercises: true,
              feedback: true,
              podcast: true,
              visuals: true,
              videos: true,
            },
      };
    case getType(search.success): {
      const { payload } = action;

      return keysOf(payload).reduce(
        (state, key) => {
          const value = action.payload[key];

          if (!value) {
            return state;
          }

          const { count, results } = value;

          return {
            ...state,
            byId: {
              ...state.byId,
              // tslint:disable-next-line:no-any
              [key]: (results as any[]).reduce(
                (byId, result) => ({
                  ...byId,
                  [result.id]: result,
                }),
                state.byId[key],
              ),
            },
            count: {
              ...state.count,
              [key]: count,
            },
            ids: {
              ...state.ids,
              [key]: (results as Entity[]).map(({ id }) => id),
            },
            loading: {
              ...state.loading,
              [key]: false,
            },
          };
        },
        {
          ...state,
          available: {
            blog: payload.blog ? payload.blog.count > 0 : state.available.blog,
            books: payload.books
              ? payload.books.count > 0
              : state.available.books,
            conversations: payload.conversations
              ? payload.conversations.count > 0
              : state.available.conversations,
            feedback: payload.feedback
              ? payload.feedback.count > 0
              : state.available.feedback,
            podcast: payload.podcast
              ? payload.podcast.count > 0
              : state.available.podcast,
            challenges: (() => {
              if (payload.challenges) {
                if (payload.challenges.count > 0) {
                  return true;
                }

                if (state.count.challengeResponse === 0) {
                  return false;
                }
              }

              if (payload.challengeResponse) {
                if (payload.challengeResponse.count > 0) {
                  return true;
                }

                if (state.count.challenges === 0) {
                  return false;
                }
              }

              return state.available.challenges;
            })(),
            exercises: payload.exercises
              ? payload.exercises.count > 0
              : state.available.exercises,
            visuals: payload.visuals
              ? payload.visuals.count > 0
              : state.available.visuals,
            videos: payload.videos
              ? payload.videos.count > 0
              : state.available.videos,
          },
        },
      );
    }
    case getType(search.failure):
      return {
        ...state,
        loading: INITIAL_STATE.loading,
      };
    case getType(setSearchSectionPage): {
      const { page, section } = action.payload;

      return {
        ...state,
        page: {
          ...state.page,
          [section]:
            typeof page === 'number' ? page : page(state.page[section]),
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
