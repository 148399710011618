import React, { FC, memo, useCallback, useMemo } from 'react';
import { isDegrade } from './logic';
import {
  CardButton,
  Container,
  FakeButton,
  Price,
  Subtitle,
  Tag,
  Title,
} from './styles';
import { Props } from './types';

const Plan: FC<Props> = ({
  currentPlanInterval,
  id,
  index,
  isLifetimeCard,
  isCorporateCard,
  currentPlanType,
  period,
  price,
  subtitle,
  onCancelSubscription,
  ...rest
}) => {
  const getPriceSubtitle = useCallback(() => {
    if (isCorporateCard) {
      return `per seat per ${period}`;
    }
    if (isLifetimeCard) {
      return 'one time';
    }
    return `per ${period}`;
  }, [isCorporateCard, period, isLifetimeCard]);

  const getPlanTitle = useMemo(() => {
    switch (period) {
      case 'year':
        return 'Annual';
      case 'month':
        return 'Monthly';
      default:
        return 'Lifetime';
    }
  }, [period]);

  const isCurrentPlan = useMemo(() => {
    if (isLifetimeCard && currentPlanInterval === 'lifetime') {
      return true;
    }
    return (
      (isCorporateCard &&
        currentPlanType === 'corporate' &&
        currentPlanInterval === period) ||
      (currentPlanType === 'individual' &&
        !isCorporateCard &&
        currentPlanInterval === period)
    );
  }, [
    isLifetimeCard,
    currentPlanInterval,
    isCorporateCard,
    currentPlanType,
    period,
  ]);

  const isDegradePlan = isDegrade({
    currentPlanInterval,
    period,
    isCorporateCard,
    currentPlanType,
  });
  return (
    <Container {...rest} $disabled={isCurrentPlan}>
      <CardButton
        disabled={isCurrentPlan}
        onClick={isDegradePlan ? onCancelSubscription : undefined}
        to={
          isDegradePlan
            ? undefined
            : {
                pathname: isCorporateCard
                  ? 'change-subscription/change-plan/corporate'
                  : 'change-subscription/change-plan',
                state: {
                  selectedPlanId: id,
                },
              }
        }
      >
        <Title>{getPlanTitle}</Title>
        <Price>${price} US</Price>
        <Subtitle>{getPriceSubtitle()}</Subtitle>
        <FakeButton $currentSubscription={isCurrentPlan}>
          {isCurrentPlan ? 'Your current subscription' : 'Choose subscription'}
        </FakeButton>
        {period === 'year' && subtitle ? <Tag>save {subtitle}</Tag> : null}
      </CardButton>
    </Container>
  );
};

export default memo(Plan);
