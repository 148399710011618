import { DropzoneOptions } from 'react-dropzone';
import { Media, MediaType, Props } from './types';

function getFileType(file: File): MediaType {
  if (file.type.includes('video/')) {
    return 'VIDEO';
  }

  return 'IMAGE';
}

function fileToMedia(file: File): Media {
  return {
    file,
    status: 'uploading',
    thumbnail: URL.createObjectURL(file),
    mediaType: getFileType(file),
  };
}

function urlToMedia(url: string, mediaType: MediaType): Media {
  return {
    mediaType,
    file: url,
    status: 'uploaded',
    thumbnail: undefined,
  };
}

export const handleFileUploaded = ({
  onChange,
  value,
}: {
  onChange: Props['onChange'];
  value: Props['value'];
}) => (media: Media, url: string) => {
  const newValue = [...value];
  const index = newValue.indexOf(media);

  if (index < 0 || media.status !== 'uploading') {
    return;
  }

  newValue[index] = urlToMedia(url, getFileType(media.file));
  onChange(newValue);
};

export const handleDelete = ({
  onChange,
  value,
}: {
  onChange: Props['onChange'];
  value: Props['value'];
}) => (fileToDelete: string) => {
  onChange(value.filter((media) => media.file !== fileToDelete));
};

export const handleDrop = ({
  maxFiles,
  onChange,
  value,
}: {
  maxFiles: number;
  onChange: Props['onChange'];
  value: Props['value'];
}): NonNullable<DropzoneOptions['onDrop']> => (files: File[]) => {
  if (maxFiles === 1) {
    onChange([fileToMedia(files[0])]);
  } else {
    onChange([...value, ...files.map(fileToMedia)]);
  }
};

export const handleFileEdited = ({
  maxFiles,
  onChange,
  value,
}: {
  maxFiles: Props['maxFiles'];
  onChange: Props['onChange'];
  value: Props['value'];
}) => (currentUrl: string, newFile: File) => {
  if (maxFiles === 1) {
    onChange([fileToMedia(newFile)]);
  } else {
    const newValue = [...value];
    const index = newValue.findIndex((media) => media.file === currentUrl);

    if (index < 0) {
      return;
    }

    newValue[index] = fileToMedia(newFile);
    onChange(newValue);
  }
};
