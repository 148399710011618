import { Button as DefaultButtonPrimary, Link } from 'components';
import styled from 'styled-components';

export const Container = styled.form`
  text-align: left;
  max-width: 28.75rem;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing.md};
  }
`;

export const ButtonPrimary = styled(DefaultButtonPrimary)`
  margin-top: 0;
  min-width: 7.7rem;
`;

export const ResetPassword = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  display: block;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  letter-spacing: 0.67px;
  margin: auto 0;
  text-align: center;
  text-decoration: none;
`;

export const SignUpText = styled.p`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin: ${({ theme }) => theme.spacing.xlg} 0 0 0;
`;

export const SignUpLink = styled(Link)`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: ${({ theme }) => theme.weights.bold};
`;
