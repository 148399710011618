import { Loader } from 'components';
import capitalize from 'lodash/capitalize';
import React, { FC, useCallback } from 'react';
import { Redirect } from 'react-router';
import { formatDate, formatElapsedTime } from 'utils/dates';
import { up } from 'utils/Paths';
import { useConnect } from './connect';
import { handleCancelSubscription } from './logic';

import {
  BrandCard,
  Button,
  Column,
  Content,
  CreditCard,
  DownloadButton,
  DownloadIcon,
  FeaturedColumn,
  Header,
  NumberCard,
  Price,
  Row,
  Section,
  SubscriptionContainer,
  SWDMemberContainer,
  SWDMemberIcon,
  SWDNotification,
  Table,
  Tag,
  Title,
  Total,
} from './styles';
import TopSectionPremiumUser from './TopSectionPremiumUser';
import { Props } from './types';

const Form: FC<Props> = () => {
  const {
    corporateGroupLength,
    info,
    invoices,
    isPremium,
    isSWDTeam,
    isForcedPremium,
    isPremiumGroupMember,
    isPremiumGroupOwner,
    loading,
    openConfirmation,
    url,
  } = useConnect();

  const onCancelSubscription = useCallback(
    handleCancelSubscription({
      openConfirmation,
    }),
    [openConfirmation],
  );

  if (isSWDTeam) {
    return (
      <SWDMemberContainer>
        <SWDMemberIcon />
        <SWDNotification>
          As you are a SWD Team member we granted you a free member
          subscription.
        </SWDNotification>
      </SWDMemberContainer>
    );
  }

  if (
    (isForcedPremium && !isPremiumGroupOwner) ||
    (isPremiumGroupMember && !isPremiumGroupOwner)
  ) {
    return (
      <SWDMemberContainer>
        <SWDMemberIcon />
        <SWDNotification>
          SWD has provided you with a premium subscription.
        </SWDNotification>
      </SWDMemberContainer>
    );
  }

  if (!isPremium && !isPremiumGroupMember && !isPremiumGroupOwner) {
    return <Redirect to={`${up(url)}/go-premium`} />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <SubscriptionContainer>
      <TopSectionPremiumUser
        isPremiumGroupOwner={isPremiumGroupOwner}
        corporateGroupLength={corporateGroupLength}
        currentUrl={url}
        totalSeats={info?.quantity}
        isLifetime={!info?.plan?.interval}
      />
      {info?.cancelAtPeriodEnd && (
        <Section>
          <Title>Reactivate subscription</Title>
          <Table>
            <Row>
              <Content>
                <FeaturedColumn>
                  Your subscription is inactive and will expire on{' '}
                  <strong>
                    {formatDate(info.currentPeriodEnd)} (
                    {formatElapsedTime(info.currentPeriodEnd)})
                  </strong>
                  .
                  <br /> Reactivate your subscription now to keep your premium
                  benefits!
                </FeaturedColumn>
                <Button to={`${url}/reactivate`} variant="primary">
                  Reactivate subscription
                </Button>
              </Content>
            </Row>
          </Table>
        </Section>
      )}
      <Section>
        <Title>Subscription</Title>
        <Table>
          <Row>
            <Header>Plan</Header>
            <Content>
              <Column>
                Premium -
                {isPremiumGroupMember || isPremiumGroupOwner
                  ? ' Group '
                  : ' Individual '}
                -{' '}
                {info?.plan?.interval
                  ? info?.plan.interval === 'month'
                    ? 'Monthly'
                    : 'Annual'
                  : 'Lifetime'}
              </Column>
              {info?.plan?.interval && (
                <Button
                  to={{
                    pathname: `${url}/change-subscription`,
                    state: {
                      scrollToTop: false,
                    },
                  }}
                  variant="primary"
                >
                  Change subscription
                </Button>
              )}
            </Content>
          </Row>
          <Row>
            <Header>Price</Header>
            <Content>
              <Column>${info?.amount} US</Column>
            </Content>
          </Row>
          {!info?.cancelAtPeriodEnd && info?.plan?.interval && (
            <Row>
              <Header>Next payment</Header>
              <Content>
                <Column>
                  {formatDate(info?.currentPeriodEnd ?? '')} (
                  {formatElapsedTime(info?.currentPeriodEnd ?? '')})
                </Column>
              </Content>
            </Row>
          )}
          {isPremiumGroupOwner && (
            <Row>
              <Header>Subscription seats</Header>
              <Content>
                <Column>
                  {corporateGroupLength} / {info?.quantity}
                </Column>
                <Button
                  to={{
                    pathname: `${url}/subscription-seats`,
                    state: {
                      scrollToTop: false,
                    },
                  }}
                  variant="secondary"
                >
                  Manage Members
                </Button>
              </Content>
            </Row>
          )}
        </Table>
      </Section>
      {isPremiumGroupOwner && (
        <Section>
          <Title>Company Details</Title>
          <Table>
            <Row>
              <Header>Company name</Header>
              <Content>
                <Column>{info?.groupSubscription?.companyName}</Column>
                <Button
                  to={{
                    pathname: `${url}/update-company-name`,
                    state: {
                      scrollToTop: false,
                    },
                  }}
                  variant="secondary"
                >
                  Edit company details
                </Button>
              </Content>
            </Row>
            {/*
            <Row>
              <Header>Admin</Header>
              <Content>
                <Column>admin name</Column>
                <Button
                  to={{
                    pathname: `${url}/update-admin`,
                    state: {
                      scrollToTop: false,
                    },
                  }}
                  variant="secondary"
                >
                  Change admin
                </Button>
              </Content>
            </Row>
            */}
          </Table>
        </Section>
      )}
      <Section>
        <Title>Billing history</Title>
        <Table>
          {invoices.map((invoice) => (
            <Row key={invoice.id}>
              <Header>{formatDate(invoice.date)}</Header>
              <Content>
                <Column>
                  Premium subscription -{' '}
                  {invoice?.total !== '999.00'
                    ? invoice.interval === 'year'
                      ? 'annual'
                      : 'monthly'
                    : 'lifetime'}
                </Column>
                <Total>
                  {invoice.paid && <Tag>Paid</Tag>}
                  <Price>${invoice.total} US</Price>
                </Total>
                <DownloadButton to={invoice.pdfUrl} variant="secondary">
                  <DownloadIcon />
                </DownloadButton>
              </Content>
            </Row>
          ))}
        </Table>
      </Section>
      <Section>
        <Title>Payment Details</Title>
        <Table>
          <Row>
            <Header>Credit card</Header>
            <Content>
              <CreditCard>
                <NumberCard>**** **** **** {info?.cardLast4}</NumberCard>
                <BrandCard>{capitalize(info?.cardKind)}</BrandCard>
              </CreditCard>
              {info?.plan?.interval ? (
                <Button
                  to={{
                    pathname: `${url}/update-payment-method`,
                    state: {
                      scrollToTop: false,
                    },
                  }}
                  variant="secondary"
                >
                  Update payment method
                </Button>
              ) : null}
            </Content>
          </Row>
          <Row>
            <Header>Expiration date</Header>
            <Content>
              <Column>
                {info?.plan?.interval
                  ? `${info?.cardExpMonth.padStart(2, '0')}/${
                      info?.cardExpYear
                    }`
                  : 'Never'}
              </Column>
            </Content>
          </Row>
        </Table>
      </Section>
      {!info?.cancelAtPeriodEnd && info?.plan?.interval && (
        <Section>
          <Title>Cancel subscription</Title>
          <Table>
            <Row>
              <Content>
                <FeaturedColumn>
                  You can cancel your subscription anytime.
                </FeaturedColumn>
                <Button onClick={onCancelSubscription} variant="danger">
                  Cancel
                  {isPremiumGroupMember || isPremiumGroupOwner
                    ? ' Group '
                    : ' Individual '}
                  subscription
                </Button>
              </Content>
            </Row>
          </Table>
        </Section>
      )}
    </SubscriptionContainer>
  );
};

export default Form;
