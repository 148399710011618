import { Button as DefaultButton } from 'components';
import { OnDemandVideosIcon } from 'components/icons';
import Download from 'components/icons/Download';
import styled from 'styled-components';
import { from } from 'styles/mixins';

export const SubscriptionContainer = styled.div`
  > :not(:last-child) {
    margin-bottom: 3.75rem;
  }
`;

export const Section = styled.section``;

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.25rem;
  margin: 0 0 1.625rem;
`;

export const Table = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.greyBlue};
  border-radius: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.875rem;

  > :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey100};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  ${from.tablet`
    flex-direction: row;
  `};
`;

export const Header = styled.h4`
  box-sizing: border-box;
  font-weight: ${({ theme }) => theme.weights.regular};
  flex-shrink: 0;
  margin: 0;
  padding: ${({ theme }) => `1.25rem ${theme.spacing.lg} 0`};
  white-space: nowrap;

  ${from.tablet`
    border-right: 1px solid ${({ theme }) => theme.colors.grey200};
    flex-basis: 13.1rem;
    padding: ${({ theme }) => `1.25rem ${theme.spacing.lg}`};
  `};
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.grey400};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.lg};

  ${from.tablet`
    flex-direction: row;
    align-items: center;
    padding: 0.938rem 2.25rem 0.938rem 0;
  `};
`;

export const Column = styled.p`
  margin: 0;
  padding: 0.313rem 0;

  ${from.tablet`
    padding: 0.313rem ${({ theme }) => theme.spacing.lg}
  `};
`;

export const Button = styled(DefaultButton)`
  flex-shrink: 0;
  margin-top: ${({ theme }) => theme.spacing.md};
  min-width: 15rem;
  box-sizing: border-box;

  ${from.tablet`
    margin: 0;
  `};
`;

export const FeaturedColumn = styled(Column)`
  color: ${({ theme }) => theme.colors.black};

  ${from.tablet`
    padding-left: 2.25rem;
  `};
`;

export const CreditCard = styled(Column)``;

export const NumberCard = styled.span`
  margin-right: 1.875rem;
`;

export const BrandCard = styled.span``;

export const Total = styled(Column)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Tag = styled.span`
  background-color: ${({ theme }) => theme.colors.sky};
  border-radius: 0.125rem;
  color: ${({ theme }) => theme.colors.accent};
  font-size: 0.75rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  letter-spacing: 0.57px;
  margin-right: ${({ theme }) => theme.spacing.sm};
  padding: 0.313rem 0.519rem 0.188rem;
  text-transform: uppercase;
  height: 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const Price = styled.span``;

export const DownloadButton = styled(Button)`
  padding: 0.563rem;

  ${from.tablet`
    margin: auto 0;
  `};
`;

export const DownloadIcon = styled(Download)``;

export const SWDMemberIcon = styled(OnDemandVideosIcon)`
  width: 15.625rem;
`;

export const SWDNotification = styled.h2`
  font-size: 1.75rem;
  margin-top: 2rem;
  text-align: center;
  max-width: 40rem;
`;

export const SWDMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
