import React, { FC } from 'react';
import { Container, Label, SWDOverlayBadge } from './styles';
import { Props } from './types';

const SolvedBySwdTag: FC<Props> = ({ children, ...rest }) => (
  <Container {...rest}>
    <SWDOverlayBadge variant="swd" />
    <Label>SWD Solution</Label>
  </Container>
);

export default SolvedBySwdTag;
