import { ClickAwayListener } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import {
  Container,
  Content,
  ContentWrapper,
  DropdownIcon,
  Header,
} from './styles';
import { Props } from './types';

const ClickDropdown = ({ children, header, ...rest }: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const closeDropdown = useCallback(() => setShowDropdown(false), [
    setShowDropdown,
  ]);

  const toggleVisibility = useCallback(
    () => setShowDropdown((value) => !value),
    [setShowDropdown],
  );

  return (
    <Container {...rest}>
      <Header showDropdown={showDropdown} onClick={toggleVisibility}>
        {header}
        <DropdownIcon />
      </Header>
      {showDropdown && (
        <ClickAwayListener onClickAway={toggleVisibility}>
          <ContentWrapper>
            <Content>{children({ closeDropdown })}</Content>
          </ContentWrapper>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default ClickDropdown;
