import { oneLine } from 'common-tags';
import { EditorInput, FormActions, Input, MediaInput } from 'components';
import VisualTagInputs from 'containers/Common/VisualTagInputs';
import { ExerciseResponseFormFieldNames, UPLOAD_MEDIA_FIELD } from 'model';
import React, { FC } from 'react';
import { Field } from 'redux-form';
import { required } from 'utils/validations';
import connect from './connect';
import { Container } from './styles';
import { Props } from './types';

const ExerciseResponseForm: FC<Props> = ({
  editSlug,
  handleSubmit,
  invalid,
  isPristine,
  loading,
  onDelete,
  hidden,
  reset,
  submitFailed,
}) => (
  <Container onSubmit={handleSubmit}>
    <Field
      component={Input}
      label="Title of your submission"
      name={ExerciseResponseFormFieldNames.title}
      placeholder="Choose a representative title for your exercise submission"
      type="text"
      validate={required}
    />
    <Field
      component={EditorInput}
      label="Related commentary"
      name={ExerciseResponseFormFieldNames.description}
      placeholder={oneLine`Tell us about your approach for solving this exercise`}
      validate={required}
    />
    <Field
      accept={['IMAGE', 'VIDEO']}
      component={MediaInput}
      label="Upload media"
      name={ExerciseResponseFormFieldNames[UPLOAD_MEDIA_FIELD]}
    />
    {!hidden && <VisualTagInputs />}
    <FormActions
      invalid={invalid}
      loading={loading}
      onDelete={onDelete}
      pristine={isPristine}
      reset={editSlug ? reset : undefined}
      submitFailed={submitFailed}
    />
  </Container>
);

export default connect(ExerciseResponseForm);
